var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('CommentModalTemplate', {
    attrs: {
      "create-comment-types": _vm.createCommentTypes,
      "read-only": _vm.readOnly
    },
    on: {
      "modal-close": _vm.emitModalClose
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function () {
        return [_c('ButtonCreate', {
          staticClass: "ml-0",
          attrs: {
            "disabled": _vm.shouldDisableCreateCommentButton
          },
          on: {
            "click": _vm.onClickOfCreate
          }
        }, [_vm._v(" Create Comment ")]), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.formData,
      callback: function ($$v) {
        _vm.formData = $$v;
      },
      expression: "formData"
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };