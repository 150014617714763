import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Watch } from 'vue-property-decorator';
import BaseActivityInformationForm from '~/nasa_ui/base/BaseActivityInformationForm';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { AnalysisResult } from '~/nasa_ui/types/enums';
import { ActivityTypeDisplay, AnalysisResultDisplay } from '~/nasa_ui/types/enums/activity';
let ActivityBaseForm = class ActivityBaseForm extends Mixins(BaseActivityInformationForm) {
    AnalysisResultDisplay = AnalysisResultDisplay;
    AnalysisResult = AnalysisResult;
    get activityTypeDisplay() {
        return this.activityType && ActivityTypeDisplay.has(this.activityType)
            ? ActivityTypeDisplay.get(this.activityType)
            : this.activityType || '';
    }
    get crewTimeDisplay() {
        return this.$intervalDisplay(this.formData?.crewTime);
    }
    get failureChangeSetId() {
        return this.formData?.changeSet?.id;
    }
    get operationTimeDisplay() {
        return this.$intervalDisplay(this.formData?.operationTime);
    }
    get nameDisplay() {
        return this.formData?.name || DEFAULT_DASH;
    }
    get pressurizedTimeDisplay() {
        return this.$intervalDisplay(this.formData?.pressurizedTime);
    }
    get startDateDisplay() {
        return this.formData?.startDateTime ? this.$dateDisplay(this.formData.startDateTime) : DEFAULT_DASH;
    }
    get staticTimeDisplay() {
        return this.$intervalDisplay(this.formData?.staticTime);
    }
    missingFormForType() {
        console.log(`Loaded Base Activity Form: TabActivityInformation missing type: ${this.activityType}`);
    }
};
__decorate([
    Watch('formData'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ActivityBaseForm.prototype, "missingFormForType", null);
ActivityBaseForm = __decorate([
    Component
], ActivityBaseForm);
export default ActivityBaseForm;
