var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "chart-container"
  }, [_vm.parentData ? _c('BarChart', {
    attrs: {
      "options": _vm.chartOptions,
      "data": _vm.chartData
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };