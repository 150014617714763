var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.pill ? _c('div', {
    staticClass: "pill",
    style: _vm.computedBorderColor
  }, [_c('div', {
    staticClass: "left_side",
    class: _vm.computedLeftSideCssClasses
  }, [_vm.computedIcon && _vm.pill.leftSideIsIcon ? _c('c-icon-fa', {
    attrs: {
      "icon": _vm.computedIcon,
      "icon-color": "var(--cosmic-text)",
      "icon-size": "18px"
    }
  }) : _c('span', [_vm._v(_vm._s(_vm.pill.leftSide))])], 1), _c('div', {
    staticClass: "right_side",
    class: _vm.computedRightSideCssClasses
  }, [_vm.computedIcon && _vm.pill.rightSideIsIcon ? _c('c-icon-fa', {
    attrs: {
      "icon": _vm.computedIcon,
      "icon-color": "var(--cosmic-text)",
      "icon-size": "18px"
    }
  }) : _c('span', [_vm._v(_vm._s(_vm.pill.rightSide))])], 1)]) : _c('div', {
    staticClass: "pill"
  }, [_c('div', {
    staticClass: "left_side",
    class: _vm.computedLeftSideCssClasses
  }, [_vm._t("left_side")], 2), _c('div', {
    staticClass: "right_side",
    class: _vm.computedRightSideCssClasses
  }, [_vm._t("right_side")], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };