var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';
import { DocumentType, StepOverviewFragment } from '~/db_types';
import BaseVue from '~/nasa_ui/base/BaseVue';
import StepMixin from '~/nasa_ui/mixins/StepMixin';
let WorkOrderStepCardEdit = class WorkOrderStepCardEdit extends Mixins(StepMixin, BaseVue) {
    DocumentType = DocumentType;
    form = {};
    openPanel = null;
    step;
    isSaving;
    documentType;
    get shouldShowTextOnly() {
        return this.docTypesUsingTasks.includes(this.documentType);
    }
    get isRca() {
        return this.documentType === DocumentType.C_RCA;
    }
    get isWorkflow() {
        return this.documentType === DocumentType.C_WORKFLOW;
    }
    get formIsValid() {
        const { action, warning, caution, notes } = this.form;
        return Boolean(action || warning || caution || notes);
    }
    created() {
        this.form = {
            action: this.step.action,
            assigneeContactId: this.step.assigneeContactId,
            warning: this.step.warning,
            caution: this.step.caution,
            notes: this.step.notes,
            title: this.step.title,
            projectedDate: this.step.projectedDate,
            overrideStartDate: this.step.overrideStartDate,
            riskScoreAtCompletion: this.step.riskScoreAtCompletion
        };
    }
    onClickOfSave() {
        if (!this.formIsValid) {
            return;
        }
        this.emitSubmit();
    }
    emitSubmit() {
        return this.form;
    }
    cancel() { }
};
__decorate([
    Prop({
        required: true,
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof StepOverviewFragment !== "undefined" && StepOverviewFragment) === "function" ? _a : Object)
], WorkOrderStepCardEdit.prototype, "step", void 0);
__decorate([
    Prop({
        required: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], WorkOrderStepCardEdit.prototype, "isSaving", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_b = typeof DocumentType !== "undefined" && DocumentType) === "function" ? _b : Object)
], WorkOrderStepCardEdit.prototype, "documentType", void 0);
__decorate([
    Emit('submit'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], WorkOrderStepCardEdit.prototype, "emitSubmit", null);
__decorate([
    Emit(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], WorkOrderStepCardEdit.prototype, "cancel", null);
WorkOrderStepCardEdit = __decorate([
    Component
], WorkOrderStepCardEdit);
export default WorkOrderStepCardEdit;
