var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Debounce } from 'lodash-decorators';
import { Component, Emit } from 'vue-property-decorator';
import { OrganizationType } from '~/db_types';
import BaseSearch from '~/nasa_ui/base/BaseSearch';
import { EntityType } from '~/nasa_ui/types';
import { OrganizationTypeDisplay } from '~/nasa_ui/types/enums/organization';
import { isNullOrUndefined } from '~/nasa_ui/utils/helpers/isNullOrUndefined';
import { OrganizationTableHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
import { OrganizationResponseDisplay } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let SearchOrganization = class SearchOrganization extends BaseSearch {
    placeholder = 'search for organizations';
    hint = 'organization code or name';
    OrganizationType = OrganizationType;
    OrganizationTypeDisplay = OrganizationTypeDisplay;
    entityType = EntityType.ORGANIZATION;
    searchFilters = {
        query: '',
        includeInactive: false,
        subType: null
    };
    get computedTableHeaders() {
        return [...OrganizationTableHeaders];
    }
    get chipsFromFilters() {
        if (!this.pureSearchFilters || Object.keys(this.pureSearchFilters).length === 0) {
            return [];
        }
        let appChips = [];
        appChips = appChips.concat(this.buildAppChipForProperty('subType', 'organizations', OrganizationTypeDisplay));
        return appChips.filter((key) => key !== undefined);
    }
    async performSearch() {
        try {
            this.isLoading = true;
            const params = {
                includeInactive: this.gqlSearchQueryVariables.includeInactive,
                query: this.gqlSearchQueryVariables.query || undefined,
                skip: 0,
                subType: this.gqlSearchQueryVariables.subType || undefined,
                take: isNullOrUndefined(this.userSelectedMaxResults) ? -1 : this.userSelectedMaxResults
            };
            const resp = await this.$http.get(`/${EntityType.ORGANIZATION}`, {
                params
            });
            this.isLoading = false;
            this.rawSearchResponse = resp.data.results ? resp.data.results : [];
            this.emitSearchPerformed();
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
    }
    onClickOfSearchResult(selectedItem) {
        this.reset();
        return this.isOutputModeString ? selectedItem.code : selectedItem;
    }
    onMetaClickOfSearchResult(selectedItem) {
        return this.isOutputModeString ? selectedItem.code : selectedItem;
    }
};
__decorate([
    Debounce(200),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], SearchOrganization.prototype, "performSearch", null);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof OrganizationResponseDisplay !== "undefined" && OrganizationResponseDisplay) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], SearchOrganization.prototype, "onClickOfSearchResult", null);
__decorate([
    Emit('metaRowClicked'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof OrganizationResponseDisplay !== "undefined" && OrganizationResponseDisplay) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], SearchOrganization.prototype, "onMetaClickOfSearchResult", null);
SearchOrganization = __decorate([
    Component
], SearchOrganization);
export default SearchOrganization;
