var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.ACTIVITY,
      "width": _vm.modalWidth,
      "persistent": "",
      "hide-title-section": ""
    },
    on: {
      "input": _vm.emitModalClose,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.emitModalClose.apply(null, arguments);
      },
      "modal-close": _vm.emitModalClose
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_vm._t("content"), _vm.value ? _c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs6": "",
            "pr-4": ""
          }
        }, [_c('AppSelectEnum', {
          attrs: {
            "label": "Calibration type",
            "enum": _vm.CalibrationType,
            "existingValuesToBeRemoved": _vm.existingCalibrationTypes,
            "display": _vm.CalibrationTypeDisplay,
            "chips": false,
            "disabled": !_vm.canEditCalibrationRequirements || _vm.isEdit,
            "required": ""
          },
          model: {
            value: _vm.value.subType,
            callback: function ($$v) {
              _vm.$set(_vm.value, "subType", $$v);
            },
            expression: "value.subType"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs6": "",
            "pr-4": ""
          }
        }, [_c('c-text', {
          attrs: {
            "label": "Number (Maint. Id)",
            "c-value": _vm.value.number,
            "disabled": !_vm.canEditCalibrationRequirements
          },
          on: {
            "c-input": function ($event) {
              _vm.value.number = $event.detail;
            }
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs12": "",
            "pr-4": ""
          }
        }, [_c('c-text', {
          attrs: {
            "label": "Description",
            "c-value": _vm.value.description,
            "disabled": !_vm.canEditCalibrationRequirements
          },
          on: {
            "c-input": function ($event) {
              _vm.value.description = $event.detail;
            }
          }
        })], 1)], 1) : _vm._e(), _vm.value ? _c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs3": "",
            "pr-4": ""
          }
        }, [_c('c-text', {
          attrs: {
            "label": "Range type",
            "c-value": _vm.value.rangeType,
            "disabled": !_vm.canEditCalibrationRequirements
          },
          on: {
            "c-input": function ($event) {
              _vm.value.rangeType = $event.detail;
            }
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs3": "",
            "pr-4": ""
          }
        }, [_c('AppNumber', {
          ref: "rangValue",
          attrs: {
            "label": "Range value",
            "disabled": !_vm.canEditCalibrationRequirements
          },
          on: {
            "error": _vm.onError
          },
          model: {
            value: _vm.value.rangeValue,
            callback: function ($$v) {
              _vm.$set(_vm.value, "rangeValue", $$v);
            },
            expression: "value.rangeValue"
          }
        })], 1)], 1) : _vm._e(), _vm.value ? _c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs6": "",
            "pr-4": ""
          }
        }, [_c('label', [_vm._v(" Due Every "), _c('v-chip', {
          staticClass: "hidden-sm-and-down font-weight-bold",
          attrs: {
            "color": _vm.AlertType.INFO,
            "text-color": "white"
          }
        }, [_c('v-avatar', [_c('c-icon-fa', {
          attrs: {
            "icon": "fad fa-info-circle"
          }
        })], 1), _vm._v("Calculated from Operational Start ")], 1)], 1), _c('AppInterval', {
          attrs: {
            "disabled": !_vm.canEditCalibrationRequirements,
            "required": ""
          },
          model: {
            value: _vm.value.value,
            callback: function ($$v) {
              _vm.$set(_vm.value, "value", $$v);
            },
            expression: "value.value"
          }
        })], 1)], 1) : _vm._e()];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_vm._t("actions")];
      },
      proxy: true
    }], null, true)
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };