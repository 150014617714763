import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { ChangeSetAuthorityType } from '~/db_types';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { UserRepoMixin } from '~/nasa_ui/DAL/user';
import ReadOnlyMixin from '~/nasa_ui/mixins/ReadOnlyMixin';
import { objectArraysMatchOrder } from '~/nasa_ui/utils/helpers/objectArraysMatchOrder';
import { transformUserResponses } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let AppAuthorityOverrideList = class AppAuthorityOverrideList extends Mixins(BaseCosmic, ReadOnlyMixin, UserRepoMixin) {
    valueLocal = [];
    value;
    created() {
        if (this.value?.length) {
            this.valueLocal = [...this.value];
        }
    }
    // called from ui
    computedGroupName(authorityOverride) {
        if (!authorityOverride.userGroup) {
            // as a fallback, return the group code
            return authorityOverride.groupCode || DEFAULT_DASH;
        }
        // if the user group has a name, use it
        if (authorityOverride.userGroup?.name) {
            return authorityOverride.userGroup.name;
        }
        else if ('delegateGroupForUser' in authorityOverride.userGroup) {
            // if the user group is a delegate group, return the user's full name
            const user = transformUserResponses([authorityOverride.userGroup.delegateGroupForUser])[0];
            return user._fullName;
        }
        else if ('delegatesForUser' in authorityOverride.userGroup) {
            // if the user group is a delegate group, return the user's full name
            const user = transformUserResponses([authorityOverride.userGroup.delegatesForUser])[0];
            return user._fullName;
        }
        // as a fallback, return the group code
        return authorityOverride.groupCode || DEFAULT_DASH;
    }
    // called from ui
    isGmip(index) {
        return this.valueLocal[index].authorityType === ChangeSetAuthorityType.GOVERNMENT_MANUAL_INSPECTION_POINT;
    }
    mipExplanationValue(index) {
        return this.valueLocal[index].manualInspectionPointExplanation || null;
    }
    mipTypeValue(index) {
        return this.valueLocal[index].manualInspectionPointType || null;
    }
    // called from ui
    onClickOfRemoveItem(index) {
        this.valueLocal = this.valueLocal.filter((val, i) => i !== index);
    }
    // called from ui
    shouldShowChips(index) {
        return this.valueLocal[index].authorityType !== ChangeSetAuthorityType.DEFAULT;
    }
    onChangeOfPanels(authorityOverrides) {
        return authorityOverrides;
    }
    onChangeOfValueLength(authorityOverrides) {
        // if the order or length is different than the order or length of the local
        // value, reassign the local value
        if (!objectArraysMatchOrder(authorityOverrides, this.valueLocal, 'groupCode')) {
            this.valueLocal = authorityOverrides;
        }
    }
};
__decorate([
    Prop({
        type: Array,
        required: true
    }),
    __metadata("design:type", Array)
], AppAuthorityOverrideList.prototype, "value", void 0);
__decorate([
    Watch('valueLocal', { deep: true }),
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], AppAuthorityOverrideList.prototype, "onChangeOfPanels", null);
__decorate([
    Watch('value'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], AppAuthorityOverrideList.prototype, "onChangeOfValueLength", null);
AppAuthorityOverrideList = __decorate([
    Component
], AppAuthorityOverrideList);
export default AppAuthorityOverrideList;
