var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop } from 'vue-property-decorator';
import { AppDynamicWrapperType } from '~/nasa_ui/components/AppDynamicWrapper/AppDynamicWrapper';
import { ApprovalRoutingTemplateRepoMixin } from '~/nasa_ui/DAL/approvalRoutingTemplate';
import { EntityType, Maybe } from '~/nasa_ui/types';
let ModalApprovalRoutingTemplateCreate = class ModalApprovalRoutingTemplateCreate extends ApprovalRoutingTemplateRepoMixin {
    AppDynamicWrapperType = AppDynamicWrapperType;
    createArtMutationVariables = null;
    EntityType = EntityType;
    isFormValid = false;
    isSaving = false;
    userGroups;
    get shouldDisableCreateButton() {
        return !this.isFormValid || this.isSaving;
    }
    async onClickOfCreate() {
        if (!this.createArtMutationVariables) {
            return console.error('Missing input to createApprovalRoutingTemplate method.');
        }
        this.isSaving = true;
        const resp = await this.createApprovalRoutingTemplate(this.createArtMutationVariables, {
            success: 'Succesfully created Approval Routing Template.',
            error: 'Error: Could not create Approval routing template.'
        });
        const id = resp?.createApprovalRoutingTemplate?.approvalRoutingTemplate?.id;
        if (id) {
            this.emitCreated(id);
            this.emitModalClose();
            return;
        }
        this.isSaving = false;
    }
    emitCreated(id) { }
    emitModalClose() { }
};
__decorate([
    Prop({
        type: Array
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], ModalApprovalRoutingTemplateCreate.prototype, "userGroups", void 0);
__decorate([
    Emit('created'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], ModalApprovalRoutingTemplateCreate.prototype, "emitCreated", null);
__decorate([
    Emit('modal-close'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalApprovalRoutingTemplateCreate.prototype, "emitModalClose", null);
ModalApprovalRoutingTemplateCreate = __decorate([
    Component
], ModalApprovalRoutingTemplateCreate);
export default ModalApprovalRoutingTemplateCreate;
