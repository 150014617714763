var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.shouldShowModalInventoryQuota ? _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.ITEM_MASTER,
      "hide-title-section": ""
    },
    on: {
      "modal-close": _vm.hideModal
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs12": "",
            "sm4": "",
            "mb-2": ""
          }
        }, [_vm.isEditing ? _c('AppSelect', {
          attrs: {
            "label": "Class",
            "chips": false,
            "disabled": true,
            "selectOptions": _vm.editItemClassFixedOption
          },
          model: {
            value: _vm.formDataCopy.itemClass,
            callback: function ($$v) {
              _vm.$set(_vm.formDataCopy, "itemClass", $$v);
            },
            expression: "formDataCopy.itemClass"
          }
        }) : _vm._e(), !_vm.isEditing && _vm.filteredItemClasses.length > 0 ? _c('AppSelect', {
          attrs: {
            "label": "Class",
            "chips": false,
            "selectOptions": _vm.filteredItemClasses,
            "monospace-selections": ""
          },
          model: {
            value: _vm.formDataCopy.itemClass,
            callback: function ($$v) {
              _vm.$set(_vm.formDataCopy, "itemClass", $$v);
            },
            expression: "formDataCopy.itemClass"
          }
        }) : _vm._e()], 1)], 1), _c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          class: {
            'pr-2': !_vm.$vuetify.breakpoint.xs
          },
          attrs: {
            "xs12": "",
            "sm3": "",
            "mb-2": ""
          }
        }, [_c('AppNumber', {
          attrs: {
            "label": "Min Stock",
            "error-messages": _vm.minStockErrorText,
            "min": 0
          },
          model: {
            value: _vm.formDataCopy._minStockNumber,
            callback: function ($$v) {
              _vm.$set(_vm.formDataCopy, "_minStockNumber", $$v);
            },
            expression: "formDataCopy._minStockNumber"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs12": "",
            "sm3": ""
          }
        }, [_c('AppNumber', {
          attrs: {
            "label": "Max Stock",
            "error-messages": _vm.maxStockErrorText,
            "min": 0
          },
          model: {
            value: _vm.formDataCopy._maxStockNumber,
            callback: function ($$v) {
              _vm.$set(_vm.formDataCopy, "_maxStockNumber", $$v);
            },
            expression: "formDataCopy._maxStockNumber"
          }
        })], 1)], 1)];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonSave', {
          attrs: {
            "disabled": !_vm.isFormValid
          },
          on: {
            "click": _vm.onClickOfSave
          }
        }), _c('ButtonCancel', {
          on: {
            "click": _vm.onClickOfCancel
          }
        }), _c('v-spacer'), _vm.isEditing ? _c('ButtonRemove', {
          on: {
            "click": _vm.onClickOfRemove
          }
        }, [_vm._v("Delete Quota")]) : _vm._e()];
      },
      proxy: true
    }], null, false, 2892302590)
  }) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };