import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import BaseButton from '~/nasa_ui/base/BaseButton';
let ButtonEdit = class ButtonEdit extends BaseButton {
    get computedColor() {
        return this.color || '--cosmic-gray-lighten-4';
    }
    get computedIcon() {
        return this.icon || this.$icons.edit;
    }
    get computedTextColor() {
        return this.textColor || '--cosmic-text';
    }
};
ButtonEdit = __decorate([
    Component
], ButtonEdit);
export default ButtonEdit;
