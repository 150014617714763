var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-layout', {
    attrs: {
      "column": ""
    }
  }, [_vm.isLoading ? _c('AppLoader', {
    attrs: {
      "center": true,
      "type": "linear"
    }
  }) : _c('div', [_c('WorkOrderStepsActionBar', {
    attrs: {
      "read-only": _vm.isReadOnly,
      "steps": _vm.steps
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('div', [_c('div', {
          staticClass: "action-icon"
        }, [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function ({
              on
            }) {
              return [_c('div', _vm._g({}, on), [_c('ButtonMiniActionCreate', {
                attrs: {
                  "disabled": _vm.isReadOnly || _vm.pendingInitialization || _vm.isInitialized
                },
                on: {
                  "click": function ($event) {
                    _vm.showNewStepModal = true;
                  }
                }
              })], 1)];
            }
          }])
        }, [!_vm.isReadOnly && !_vm.pendingInitialization ? _c('span', [_vm._v("Add a new step.")]) : !_vm.isReadOnly && _vm.pendingInitialization ? _c('span', [_vm._v("Document is not open for editing.")]) : _vm._e()])], 1), _c('div', {
          staticClass: "action-icon"
        }, [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function ({
              on
            }) {
              return [_c('div', _vm._g({}, on), [_c('ButtonMiniAction', {
                attrs: {
                  "disabled": _vm.steps.length < 1 || _vm.allStepsComplete || !_vm.currentUserCanApproveAnySteps || !_vm.isInitialized
                },
                on: {
                  "click": _vm.onClickOfCompleteAllSteps
                }
              }, [[_c('c-icon-fa', {
                staticClass: "mr-1",
                attrs: {
                  "icon": "fad fa-thumbs-up",
                  "icon-color": "var(--v-success-base)"
                }
              }), _c('div', [_c('i', {
                staticClass: "success--text stacked_fa_icon",
                class: _vm.$icons.circle_check
              }), _c('i', {
                staticClass: "success--text stacked_fa_icon",
                class: _vm.$icons.circle_check
              }), _c('i', {
                staticClass: "success--text stacked_fa_icon",
                class: _vm.$icons.circle_check
              })])]], 2)], 1)];
            }
          }])
        }, [!_vm.allStepsComplete && _vm.isInitialized && _vm.currentUserCanApproveAnySteps ? _c('span', [_vm._v(" Approve multiple steps. ")]) : _vm.steps.length < 1 ? _c('span', [_vm._v("Document has no steps.")]) : !_vm.pendingInitialization ? _c('span', [_vm._v("Document is not open for editing.")]) : _vm.allStepsComplete && !_vm.isReadOnly ? _c('span', [_vm._v("All steps complete.")]) : _vm.isReadOnly ? _c('span', [_vm._v("Document must be approved to approve steps.")]) : _vm._e()])], 1), _c('div', {
          staticClass: "action-icon"
        }, [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function ({
              on
            }) {
              return [_c('div', _vm._g({}, on), [_c('ButtonMiniAction', {
                attrs: {
                  "disabled": _vm.isReadOnly || _vm.pendingInitialization || _vm.isInitialized,
                  "icon": "fad fa-file-excel",
                  "icon-color": "var(--v-success-base)"
                },
                on: {
                  "click": function ($event) {
                    _vm.showImportSpreadsheetModal = true;
                  }
                }
              })], 1)];
            }
          }])
        }, [!_vm.isReadOnly && !_vm.pendingInitialization ? _c('span', [_vm._v("Import from spreadsheet.")]) : !_vm.isReadOnly && _vm.pendingInitialization ? _c('span', [_vm._v("Document is not open for editing.")]) : _vm._e()])], 1), _c('ButtonMiniAction', {
          attrs: {
            "tooltip": "Export to PDF",
            "icon": _vm.$icons['pdf']
          },
          on: {
            "click": _vm.onClickOfExportToPdfButton
          }
        })], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.stepIndex,
      callback: function ($$v) {
        _vm.stepIndex = $$v;
      },
      expression: "stepIndex"
    }
  }), !_vm.steps.length && !_vm.isLoading ? _c('Alert', {
    staticClass: "mt-3",
    attrs: {
      "type": _vm.AlertType.INFO
    }
  }, [_vm._v(" This Document currently does not have any steps. Add a new step or import from spreadsheet above to continue. ")]) : _vm._e(), _vm.document && _vm.steps.length ? _c('WorkOrderStepsOverview', {
    attrs: {
      "current-step": _vm.stepIndex,
      "steps": _vm.steps,
      "document-id": _vm.id,
      "document-type": _vm.document.subType,
      "read-only": _vm.isReadOnly,
      "pending-initialization": _vm.pendingInitialization,
      "is-initialized": _vm.isInitialized
    },
    on: {
      "procedure-updated": _vm.onProcedureUpdated,
      "step-deleted": _vm.fetchSteps,
      "step-updated": _vm.fetchSteps
    }
  }) : _vm._e()], 1), _c('div', [_vm.showNewStepModal ? _c('ModalWorkOrderStepCreateAndEdit', {
    attrs: {
      "document-id": _vm.id,
      "default-step-number": _vm.steps.length + 1,
      "max-step-number": _vm.steps.length + 1,
      "read-only": _vm.isReadOnly
    },
    on: {
      "modal-close": function ($event) {
        _vm.showNewStepModal = false;
      },
      "step-saved": _vm.fetchSteps
    }
  }) : _vm._e(), _vm.document && _vm.showImportSpreadsheetModal ? _c('ModalWorkOrderStepImportSpreadsheet', {
    attrs: {
      "document-id": _vm.id,
      "document-type": _vm.document.subType,
      "starting-step": _vm.steps.length + 1
    },
    on: {
      "modal-close": function ($event) {
        _vm.showImportSpreadsheetModal = false;
      },
      "created": _vm.fetchSteps
    }
  }) : _vm._e(), _vm.showCompleteStepModal ? _c('ModalWorkOrderStepComplete', {
    attrs: {
      "document-id": _vm.id,
      "change-set-ids": _vm.changeSetIdsToApprove,
      "should-require-comment": false
    },
    on: {
      "modal-close": function ($event) {
        _vm.showCompleteStepModal = false;
      },
      "saved": _vm.fetchSteps
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };