var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.ITEM_INSTANCE,
      "hide-title-section": ""
    },
    on: {
      "modal-close": _vm.emitModalClose
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('div', {
          staticClass: "container grid-list-lg fluid pa-2"
        }, [_c('v-layout', {
          attrs: {
            "row": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('AppPageDescription', {
          attrs: {
            "color": _vm.isMissingRequiredFields ? _vm.AlertType.WARNING : _vm.EntityType.ITEM_INSTANCE,
            "remove-margin-bottom": "",
            "remove-margin-left": ""
          }
        }, [_vm._v(" You provided the following hardware information. "), _vm.isMissingRequiredFields ? _c('span', [_vm._v("To actualize hardware, the Drawing number, AsBuilt, and Class are required.")]) : _vm._e()])], 1)], 1), _vm.isFetching ? _c('AppLoader', {
          attrs: {
            "color": _vm.EntityType.INVENTORY,
            "type": "linear"
          }
        }) : _vm._e(), _c('v-layout', {
          attrs: {
            "row": "",
            "wrap": "",
            "mb-2": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs3": ""
          }
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Drawing number"
          }
        }, [_c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(" " + _vm._s(_vm.hardwareListAssembly._computedDrawingNumber) + " ")])])], 1), _c('v-flex', {
          attrs: {
            "xs2": ""
          }
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "AsBuilt"
          }
        }, [_c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(" " + _vm._s(_vm.hardwareListAssembly._computedAsBuiltNumber) + " ")])])], 1), _c('v-flex', {
          attrs: {
            "xs6": ""
          }
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Description"
          }
        }, [_vm._v(_vm._s(_vm.hardwareListAssembly._computedDescription))])], 1), _c('v-flex', {
          attrs: {
            "xs2": ""
          }
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Serial"
          }
        }, [_c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(" " + _vm._s(_vm.hardwareListAssembly._computedSerialNumber) + " ")])])], 1), _c('v-flex', {
          attrs: {
            "xs2": ""
          }
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Lot"
          }
        }, [_c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(" " + _vm._s(_vm.hardwareListAssembly._computedLotNumber) + " ")])])], 1), _c('v-flex', {
          attrs: {
            "xs1": _vm.hardwareListAssembly.externalClass,
            "xs3": !_vm.hardwareListAssembly.externalClass
          }
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Class"
          }
        }, [_c('div', {
          staticClass: "monospace_font"
        }, [_vm._v(" " + _vm._s(_vm.hardwareListAssembly._computedSubType) + " ")]), !_vm.hardwareListAssembly.externalClass ? _c('small', [_vm._v("("), _c('i', [_vm._v("UNKNOWN")]), _vm._v(")")]) : _vm._e()])], 1), _c('v-flex', {
          attrs: {
            "xs2": ""
          }
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "CEI number"
          }
        }, [_vm._v(_vm._s(_vm.hardwareListAssembly._computedContractEndingItemNumber))])], 1)], 1), _c('v-layout', {
          attrs: {
            "row": "",
            "wrap": "",
            "mb-2": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs3": ""
          }
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Birth"
          }
        }, [_c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(" " + _vm._s(_vm.hardwareListAssembly._computedBirthDate) + " ")])])], 1), _c('v-flex', {
          attrs: {
            "xs3": ""
          }
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Calibration"
          }
        }, [_c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(" " + _vm._s(_vm.hardwareListAssembly._computedCalibrationDate) + " ")])])], 1), _c('v-flex', {
          attrs: {
            "xs3": ""
          }
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Usage life expiry"
          }
        }, [_c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(" " + _vm._s(_vm.hardwareListAssembly._computedUsageExpiryDate) + " ")])])], 1), _c('v-flex', {
          attrs: {
            "xs3": ""
          }
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Shelf life expiry"
          }
        }, [_c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(" " + _vm._s(_vm.hardwareListAssembly._computedShelfLifeExpiryDate) + " ")])])], 1)], 1), _c('v-layout', {
          attrs: {
            "row": "",
            "wrap": "",
            "mb-2": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs3": ""
          }
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Location"
          }
        }, [_vm._v(_vm._s(_vm.hardwareListAssembly._computedLocation))])], 1)], 1), _c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs6": ""
          }
        }, [_c('AppSelectLocationByBin', {
          attrs: {
            "chips": false,
            "multiple": false,
            "required": "",
            "label": "Hardware Location"
          },
          model: {
            value: _vm.selectedLocation,
            callback: function ($$v) {
              _vm.selectedLocation = $$v;
            },
            expression: "selectedLocation"
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonCreate', {
          attrs: {
            "disabled": !_vm.gqlCreateHardwareInput || !_vm.canCreateHardware,
            "is-saving": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfCreate
          }
        }, [_vm._v(" Create Hardware ")]), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        })];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };