var _a;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { uniq } from 'lodash';
import { Component, Emit, Mixins, Watch } from 'vue-property-decorator';
import { ChangeSetStatus, ChangeSetType, UserGroupType } from '~/db_types';
import BaseWorkflow from '~/nasa_ui/base/BaseWorkflow';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { AUTHORIZED_BY_COL, AUTHORIZED_DATE_COL, BASE_NUMBER_COL, BASE_TEXT_COL, REVISION_NUMBER_COL, STATUS_ICON_COL, SUB_TYPE_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import { CosmicChangeSetAuthoritySlimFragment } from '~/nasa_ui/DAL/changeSet/slim';
import { CosmicContactSlimFragment } from '~/nasa_ui/DAL/contact/slim';
import { CosmicDocumentSlimFragment } from '~/nasa_ui/DAL/document/slim';
import { CosmicStepSlimFragment } from '~/nasa_ui/DAL/document/WorkOrderStep';
import { EmailNotificationRepoMixin } from '~/nasa_ui/DAL/email';
import { CosmicUserGroupSlimFragment as CosmicUserGroupSlimFragmentOverride, CosmicUserSlimFragment } from '~/nasa_ui/DAL/user/slim';
import { Maybe } from '~/nasa_ui/types';
import { composeTableHeader } from '~/nasa_ui/utils/helpers/composeTableHeader';
import { sortObjectNumericallyBy } from '~/nasa_ui/utils/helpers/sortObjectNumericallyBy';
import { EmailNotificationTableHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
import { transformChangeSetAuthorities, transformDocumentWorkflows, transformEmailNotifications } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let TabWorkflowHistory = class TabWorkflowHistory extends Mixins(BaseWorkflow, EmailNotificationRepoMixin) {
    allReleventUserDelegateGroups = [];
    disableStartDocumentInitializationButton = false;
    document = null;
    emailHistories = [];
    emailHistoryTableHeaders = [...EmailNotificationTableHeaders];
    hasHistoricalApprovals = false;
    rejectionComment = null;
    showHistorical = false;
    showRejectionModal = false;
    showReviseDocumentModal = false;
    // get all authorities from all change sets. only count the first rejected CSA per change set.
    get allAuthorities() {
        return this.changeSets
            .reduce((authorities, changeSet) => {
            if (!changeSet.changeSetAuthorities.nodes.length) {
                return authorities;
            }
            // add a calculated revision number to each CSA
            let authoritiesOnChangeSet = changeSet.changeSetAuthorities.nodes.map((csa) => {
                return {
                    ...csa,
                    revisionNumber: changeSet.computedDocumentRevisionNumber || null,
                    stepNumber: changeSet.workOrderStep?.stepNumber || null
                };
            });
            let hasRejectedAuthority = false;
            authoritiesOnChangeSet = authoritiesOnChangeSet.filter((csa) => {
                if (hasRejectedAuthority) {
                    return false;
                }
                if (csa?.status === ChangeSetStatus.REJECTED && !csa.isHistorical) {
                    hasRejectedAuthority = true;
                }
                return true;
            });
            return authorities.concat(authoritiesOnChangeSet);
        }, [])
            .sort((a, b) => new Date(b.authorizedDate).getTime() - new Date(a.authorizedDate).getTime());
    }
    get allAuthoritiesUserIds() {
        return uniq(this.allAuthorities.map((authority) => authority?.userGroup?.delegateGroupForUserId)).filter((id) => id);
    }
    get approvalHistoryTableItems() {
        const approvalsSansTasks = transformChangeSetAuthorities(this.allAuthorities.filter((authority) => authority.subType !== ChangeSetType.WORK_ORDER_STEP));
        return approvalsSansTasks
            .map((authority) => {
            return {
                ...authority,
                _revisionNumber: authority.revisionNumber ? `${authority.revisionNumber}` : DEFAULT_DASH
            };
        })
            .sort(sortObjectNumericallyBy('revisionNumber', true));
    }
    get approvalsTableHeaders() {
        return [
            composeTableHeader(REVISION_NUMBER_COL, {
                align: 'right',
                monospacedFont: true,
                sortable: false,
                width: '50px'
            }),
            composeTableHeader(BASE_TEXT_COL, {
                text: 'Group',
                value: '__findGroupName',
                width: '275px'
            }),
            composeTableHeader(SUB_TYPE_COL, {
                text: 'Approval<br/>type',
                width: '150px'
            }),
            STATUS_ICON_COL,
            AUTHORIZED_BY_COL,
            composeTableHeader(AUTHORIZED_DATE_COL, {
                value: '_authorizedDate'
            })
        ];
    }
    get changeSets() {
        return this.document?.changeSets.nodes || [];
    }
    get cosmicDocumentInfo() {
        return this.document?.cosmicDocumentWorkflow;
    }
    get emailHistoryTableItems() {
        return this.emailHistories ? transformEmailNotifications([...this.emailHistories]) : [];
    }
    // since ApprovalsTab gets an entity prop, get the id from that rather than the id prop.
    get entityId() {
        return this.entity?.id || null;
    }
    get hasTasks() {
        const entity = this.entity;
        return Boolean(entity?.workOrderSteps?.nodes?.length);
    }
    get shouldShowApprovalHistoryTable() {
        return this.isInitialized && this.computedRevisionNumber && this.computedRevisionNumber > 1;
    }
    get shouldShowApprovalsTablePanel() {
        return true;
    }
    get shouldShowGeneralInfoPanel() {
        return Boolean(this.cosmicDocumentInfo);
    }
    get taskApprovalTableHeaders() {
        return [
            composeTableHeader(BASE_NUMBER_COL, {
                text: 'Step',
                value: '_stepNumber',
                width: '50px'
            }),
            composeTableHeader(BASE_TEXT_COL, {
                text: 'Group',
                value: '__findGroupName',
                width: '275px'
            }),
            composeTableHeader(STATUS_ICON_COL, {
                text: 'Approval<br/>status'
            }),
            AUTHORIZED_BY_COL,
            composeTableHeader(AUTHORIZED_DATE_COL, {
                value: '_authorizedDate'
            }),
            composeTableHeader(BASE_TEXT_COL, {
                text: 'Authority comment',
                value: '_computedCommentText',
                noEscape: true,
                sortable: true
            })
        ];
    }
    get taskApprovalTableItems() {
        const taskApprovals = this.allAuthorities
            .filter((authority) => authority.subType === ChangeSetType.WORK_ORDER_STEP &&
            authority.status !== ChangeSetStatus.OPEN &&
            !authority.isHistorical)
            .map((authority) => {
            return {
                ...authority,
                _stepNumber: authority.stepNumber ? `${authority.stepNumber}` : null
            };
        });
        return transformChangeSetAuthorities(taskApprovals);
    }
    get transformedWorkflow() {
        return this.cosmicDocumentInfo ? transformDocumentWorkflows([this.cosmicDocumentInfo])[0] : null;
    }
    async fetchEntityById(id) {
        try {
            const resp = await this.fetchChangeSetsQuery(id);
            if (resp.data.documentById) {
                this.document = resp.data.documentById;
            }
        }
        catch (err) {
            this.$errorUtility({ err });
        }
    }
    onChangeOfEntity(entity) {
        if (!entity?.id) {
            return;
        }
        this.fetchEntityById(entity.id);
        this.fetchEmailHistory();
    }
    async onAllAuthoritiesUserIdsChange() {
        if (this.allAuthoritiesUserIds.length > 0) {
            try {
                const promises = this.allAuthoritiesUserIds.map((userId) => this.$http.get(`/user-groups/delegate/${userId}`));
                const resp = await Promise.all(promises);
                this.allReleventUserDelegateGroups = resp.map((response) => response.data);
            }
            catch (err) {
                if (err instanceof Error) {
                    this.$errorUtility({
                        err
                    });
                }
                else {
                    console.log(`Unexpected error: ${err}`);
                }
            }
        }
    }
    // refetches at the manage page level, which is where our `entity` prop comes from
    emitRefetchEntity() {
        this.$message('reload_change_set_status_display');
        this.$message('reload_mini');
    }
    // fetch all the existing change sets and change set authorities
    fetchChangeSetsQuery(uuid) {
        return this.$apollo.query({
            query: gql `
        ${CosmicContactSlimFragment}
        ${CosmicStepSlimFragment}
        ${CosmicUserSlimFragment}
        ${CosmicUserGroupSlimFragmentOverride}
        ${CosmicChangeSetAuthoritySlimFragment}
        ${CosmicDocumentSlimFragment}

        fragment AuthorityOnWorkflowApprovalsTab on ChangeSetAuthority {
          ...CosmicChangeSetAuthoritySlim

          authorizedByUser {
            ...CosmicUserSlim
          }

          userGroup {
            ...CosmicUserGroupSlim
          }
        }

        fragment ChangeSetOnWorkflowApprovalsTab on ChangeSet {
          computedDocumentRevisionNumber
          createdDateTime
          id
          nodeId
          subType

          computedLastComment {
            comment
          }

          changeSetAuthorities {
            nodes {
              ...AuthorityOnWorkflowApprovalsTab
            }
          }

          workOrderStep {
            ...CosmicStepSlim
          }
        }

        query GetChangeSetsOnWorkflowApprovalsTab($uuid: UUID!) {
          documentById(id: $uuid) {
            ...CosmicDocumentSlim

            initiatorContact {
              ...CosmicContactSlim
            }

            cosmicDocumentWorkflow {
              overrideRevisionNumber
              computedRevisionNumber
              computedStatus
              createdDateTime
              id
              isEditable
              initiatorContactId
              nodeId

              createdByUser {
                ...CosmicUserSlim
              }

              initiatorContact {
                ...CosmicContactSlim
              }
            }

            changeSets(orderBy: CREATED_DATE_TIME_DESC) {
              nodes {
                ...ChangeSetOnWorkflowApprovalsTab
              }
            }
          }
        }
      `,
            variables: { uuid }
        });
    }
    findDisplayNameFromDelegateGroup(delegateUserGroup) {
        // return the group name if the group isn't of type user delegate
        if (delegateUserGroup?.userGroup?.subType !== UserGroupType.USER_DELEGATE) {
            return `${delegateUserGroup._userGroupName}`;
        }
        else {
            const match = this.allReleventUserDelegateGroups?.find((group) => group.delegateGroupForUserId === delegateUserGroup.userGroup?.delegateGroupForUserId);
            return match ? match.displayName : DEFAULT_DASH;
        }
    }
    async fetchEmailHistory() {
        try {
            const resp = await this.getAllDocumentRelatedEmailNotifications();
            const allEmailHistories = (resp?.notifications?.nodes || []);
            this.emailHistories = allEmailHistories.filter((emailHistory) => emailHistory?.attributes.documentId === this.entity?.id);
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
};
__decorate([
    Watch('entity', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], TabWorkflowHistory.prototype, "onChangeOfEntity", null);
__decorate([
    Watch('allAuthoritiesUserIds'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], TabWorkflowHistory.prototype, "onAllAuthoritiesUserIdsChange", null);
__decorate([
    Emit('refetch-entity'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], TabWorkflowHistory.prototype, "emitRefetchEntity", null);
TabWorkflowHistory = __decorate([
    Component
], TabWorkflowHistory);
export default TabWorkflowHistory;
