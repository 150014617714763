import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { cloneDeep } from 'lodash';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { DocumentLineItemsRepoMixin } from '~/nasa_ui/DAL/document/LineItems';
import { CosmicUserGroupSlimFragment, CosmicUserSlimFragment } from '~/nasa_ui/DAL/user';
import { EntityType } from '~/nasa_ui/types';
let ModalWorkOrderStepApprovalManage = class ModalWorkOrderStepApprovalManage extends Mixins(BaseModal, DocumentLineItemsRepoMixin) {
    EntityType = EntityType;
    // Keep copy of original authorities to check for changes
    originalAuthoritiesOverride = [];
    authoritiesOverrideFormData = [];
    isShowingMipForm = false;
    changeSetId = null;
    docId;
    hideMip;
    stepId;
    async created() {
        const query = gql `
      ${CosmicUserGroupSlimFragment}
      ${CosmicUserSlimFragment}

      query StepAuthorityDetails($stepId: Int!) {
        workOrderStepById(id: $stepId) {
          id
          nodeId
          changeSet {
            id
            nodeId
            changeSetAuthorities(condition: { isHistorical: false }) {
              nodes {
                authorityType
                isHistorical
                manualInspectionPointExplanation
                manualInspectionPointType
                nodeId
                requiresComment
                groupCode
                requiresQualityControlStamp
                userGroup {
                  ...CosmicUserGroupSlim
                  delegatesForUser {
                    ...CosmicUserSlim
                  }
                }
              }
            }
          }
        }
      }
    `;
        const variables = { stepId: this.stepId };
        this.isFetching = true;
        try {
            const res = await this.$apollo.query({
                query,
                variables
            });
            this.originalAuthoritiesOverride = res.data.workOrderStepById?.changeSet?.changeSetAuthorities
                .nodes;
            this.authoritiesOverrideFormData = cloneDeep(this.originalAuthoritiesOverride);
            this.changeSetId = res.data.workOrderStepById?.changeSet?.id;
        }
        catch (err) {
            this.$errorUtility({ err, backupErrorText: 'Error retrieving approval data' });
            throw err;
        }
        finally {
            this.isFetching = false;
        }
    }
    async onClickOfSave() {
        if (!this.changeSetId) {
            throw Error('Attempted to save without a changeSetId');
        }
        if (!this.authoritiesHaveChanged()) {
            return this.closeDialog();
        }
        const authorityOverrides = this.authoritiesOverrideFormData.map((authority) => {
            return {
                authorityType: authority.authorityType,
                groupCode: authority.userGroup.code || '',
                manualInspectionPointExplanation: authority.manualInspectionPointExplanation,
                manualInspectionPointType: authority.manualInspectionPointType,
                requiresComment: true
            };
        });
        this.isSaving = true;
        await this.reopenChangeSetsForDocuments({
            changeSetIds: [this.changeSetId],
            authoritiesOverride: authorityOverrides
        }, { success: 'Authorities updated.' });
        this.isSaving = false;
        return this.$emit('saved');
    }
    authoritiesHaveChanged() {
        if (this.authoritiesOverrideFormData.length !== this.originalAuthoritiesOverride.length) {
            return true;
        }
        return this.authoritiesOverrideFormData.some((newVal, i) => {
            const oldVal = this.originalAuthoritiesOverride[i];
            return (newVal.groupCode !== oldVal.groupCode ||
                newVal.authorityType !== oldVal.authorityType ||
                newVal.manualInspectionPointType !== oldVal.manualInspectionPointType ||
                newVal.manualInspectionPointExplanation !== oldVal.manualInspectionPointExplanation);
        });
    }
};
__decorate([
    Prop({
        required: true,
        type: String
    }),
    __metadata("design:type", Object)
], ModalWorkOrderStepApprovalManage.prototype, "docId", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], ModalWorkOrderStepApprovalManage.prototype, "hideMip", void 0);
__decorate([
    Prop({
        required: true,
        type: Number
    }),
    __metadata("design:type", Number)
], ModalWorkOrderStepApprovalManage.prototype, "stepId", void 0);
ModalWorkOrderStepApprovalManage = __decorate([
    Component
], ModalWorkOrderStepApprovalManage);
export default ModalWorkOrderStepApprovalManage;
