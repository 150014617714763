import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';
import { CommentType } from '~/db_types/swagger_types';
import BaseModal from '~/nasa_ui/base/BaseModal';
let ModalRiskUpdateStatus = class ModalRiskUpdateStatus extends Mixins(BaseModal) {
    formData = {
        comment: null,
        subType: CommentType.STATUS
    };
    riskId;
    get isFormValid() {
        return Boolean(this.formData.comment);
    }
    async onClickOfCreate() {
        if (!this.isFormValid) {
            return;
        }
        try {
            const reqBody = {
                comment: this.formData.comment,
                subType: this.formData.subType
            };
            const resp = await this.$http.post('/comments', reqBody, {
                params: {
                    riskId: this.riskId
                }
            });
            this.$notification.add({
                type: this.AlertType.SUCCESS,
                text: 'Status updated'
            });
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
};
__decorate([
    Prop({
        required: true,
        type: String
    }),
    __metadata("design:type", String)
], ModalRiskUpdateStatus.prototype, "riskId", void 0);
__decorate([
    Emit('entityCreated'),
    Emit('modal-close'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], ModalRiskUpdateStatus.prototype, "onClickOfCreate", null);
ModalRiskUpdateStatus = __decorate([
    Component
], ModalRiskUpdateStatus);
export default ModalRiskUpdateStatus;
