import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { cloneDeep, maxBy } from 'lodash';
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { ChangeSetAuthorityType, ChangeSetType, DocumentType } from '~/db_types';
import BaseModalWithUser from '~/nasa_ui/base/BaseModalWithUser';
import { cDrBuyoffGroupCode, cTpsBuyoffGroupCode, DEFAULT_DASH } from '~/nasa_ui/constants';
import { IS_EXTERNAL_ICON_COL, SHELF_LIFE_EXP_DATE_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import { ChangeSetsRepoMixin } from '~/nasa_ui/DAL/changeSet';
import { CosmicChangeSetAuthoritySlimFragment } from '~/nasa_ui/DAL/changeSet/slim';
import { DocumentHardwareAssemblyFragment as DocumentHardwareAssemblyFragmentOverride } from '~/nasa_ui/DAL/document/HardwareLists';
import { GetDocumentByIdWithLineItems } from '~/nasa_ui/DAL/document/LineItems';
import { WorkOrderStepRepoMixin } from '~/nasa_ui/DAL/document/WorkOrderStep';
import { HardwareListRepo } from '~/nasa_ui/DAL/hardwareList';
import { CosmicUserGroupFragment } from '~/nasa_ui/DAL/user';
import ReadOnlyMixin from '~/nasa_ui/mixins/ReadOnlyMixin';
import StepMixin from '~/nasa_ui/mixins/StepMixin';
import { objectArraysMatchOrder } from '~/nasa_ui/utils/helpers/objectArraysMatchOrder';
import { STANDARD_HARDWARE_COLUMNS } from '~/nasa_ui/utils/helpers/tableDefinitions';
import { transformCombinedHardwareListAssemblies, transformHardwareListAssemblies, transformUserGroups, transformUserGroupsResponse } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let ModalWorkOrderStepCreateAndEdit = class ModalWorkOrderStepCreateAndEdit extends Mixins(BaseModalWithUser, ChangeSetsRepoMixin, HardwareListRepo, ReadOnlyMixin, WorkOrderStepRepoMixin, StepMixin) {
    currentSelectorHardwareMode = 'INVENTORY';
    currentSelectorToolsMode = 'INVENTORY';
    entity = null;
    hardwareSelectionMode = 0;
    isCriteriaPanelOpen = false;
    isShowingMipForm = false;
    originalAuthoritiesOverride = [];
    showAssigneeInput = false;
    showMarkdownHelper = false;
    snackbar = false;
    snackbarMessage = '';
    toolsSelectionMode = 0;
    computedHardwarePickListTableHeaders = [
        ...STANDARD_HARDWARE_COLUMNS,
        IS_EXTERNAL_ICON_COL,
        SHELF_LIFE_EXP_DATE_COL
    ];
    computedToolPickListTableHeaders = [
        ...STANDARD_HARDWARE_COLUMNS,
        IS_EXTERNAL_ICON_COL,
        {
            text: 'Calibration',
            value: '_externalCalibrationDate'
        },
        {
            text: 'Calibration number',
            value: '_externalCalibrationNumber'
        }
    ];
    formData = {
        action: null,
        authoritiesOverride: [],
        assigneeContactId: null,
        caution: null,
        projectedDate: null,
        notes: null,
        overrideStartDate: null,
        riskScoreAtCompletion: null,
        selectedCalloutHardware: [],
        selectedCalloutTools: [],
        stepNumber: 1,
        title: null,
        warning: null
    };
    workOrderStep = {
        action: '',
        assigneeContactId: null,
        caution: '',
        computedStartDate: null,
        documentId: null,
        id: null,
        isEditable: null,
        nodeId: '',
        notes: null,
        overrideStartDate: null,
        warning: ''
    };
    documentId;
    workOrderStepToEditId;
    maxStepNumber;
    defaultStepNumber;
    isDuplication;
    get computedChangeSetSubType() {
        // Generic change set type for WORK_LIST
        //    if you do not want any specific action taken upon completion (GENERIC)
        //    if you would like INSTALL/REMOVE etc... you'd pass that type here
        return ChangeSetType.WORK_ORDER_STEP;
    }
    get computedHardwarePickListItems() {
        const combinedHardwareList = (this.entity?.cosmicDocumentDr?.combinedHardwareList.nodes ||
            this.entity?.cosmicDocumentTps?.combinedHardwareList.nodes ||
            []);
        const transformedItems = transformCombinedHardwareListAssemblies(combinedHardwareList);
        const unpickedItems = transformedItems.filter((item) => {
            return !this.formData.selectedCalloutHardware?.find((selectedItem) => selectedItem.id === item.id);
        });
        return unpickedItems;
    }
    get computedToolsPickListItems() {
        const combinedToolsList = (this.entity?.cosmicDocumentDr?.combinedToolsList.nodes ||
            this.entity?.cosmicDocumentTps?.combinedToolsList.nodes ||
            []);
        const transformedItems = transformCombinedHardwareListAssemblies(combinedToolsList);
        const unpickedItems = transformedItems.filter((item) => {
            return !this.formData.selectedCalloutTools?.find((selectedItem) => {
                const hasSameId = selectedItem.id === item.id;
                const hasSameExternalDetails = selectedItem.isExternal &&
                    item._description === selectedItem._description &&
                    item._serialNumber === selectedItem._serialNumber &&
                    item._itemNumber === selectedItem.drawingNumber;
                return hasSameId || hasSameExternalDetails;
            });
        });
        return unpickedItems;
    }
    get documentType() {
        return this.entity?.subType;
    }
    get isHardwareSelectionModeInventory() {
        return this.hardwareSelectionMode === 1 || this.hardwareSelectionMode === 2;
    }
    get isToolsSelectionModeInventory() {
        return this.toolsSelectionMode === 1 || this.toolsSelectionMode === 2;
    }
    get isWorkflow() {
        return this.documentType === DocumentType.C_WORKFLOW;
    }
    get overrideHardwareSelectorViewToManualMode() {
        return this.hardwareSelectionMode === 2;
    }
    get overrideToolsSelectorViewToManualMode() {
        return this.toolsSelectionMode === 2;
    }
    get selectedCalloutHardwareTableItems() {
        return transformHardwareListAssemblies(this.formData.selectedCalloutHardware ?? []);
    }
    get selectedCalloutToolsTableItems() {
        return transformHardwareListAssemblies(this.formData.selectedCalloutTools ?? []).map((hlaNode) => {
            hlaNode._externalCalibrationDate = hlaNode.externalCalibrationDate || DEFAULT_DASH;
            hlaNode._externalCalibrationNumber = hlaNode.externalCalibrationNumber || DEFAULT_DASH;
            return hlaNode;
        });
    }
    get existingUserGroupSearchValues() {
        return (this.formData.authoritiesOverride || []).map((authority) => {
            return authority?.userGroup?.code;
        });
    }
    get existingCalloutHardwareValues() {
        if (!this.formData.selectedCalloutHardware) {
            return null;
        }
        return this.formData.selectedCalloutHardware.filter((hw) => Boolean(hw.id)).map((inv) => inv.id);
    }
    get existingCalloutToolValues() {
        if (!this.formData.selectedCalloutTools) {
            return null;
        }
        return this.formData.selectedCalloutTools.filter((t) => Boolean(t.id)).map((inv) => inv.id);
    }
    get hasAuthoritiesOverride() {
        return (this.formData.authoritiesOverride.length || 0) > 0;
    }
    get isFormValid() {
        // TODO: maybe add s'more validation?
        return Boolean(this.formData.stepNumber >= 0 && this.hasAuthoritiesOverride);
    }
    get shouldDisableCreateOrEditButton() {
        return !this.isFormValid || (this.isShowingMipForm && this.isCriteriaPanelOpen);
    }
    get shouldHideMip() {
        if (!this.documentType) {
            return null;
        }
        return this.usesTasks(this.documentType);
    }
    get shouldShowProcedureTextOnly() {
        if (!this.documentType) {
            return null;
        }
        return this.usesTasks(this.documentType);
    }
    // TODO: This could use a large refactor on this modal so that we're not hiding/showing huge chunks of
    // form fields depending on docType. Instead I think it'd be nicer if we used more scoped slots and each document's manage
    // page would define specifically what input fields it needs
    get isRca() {
        return this.documentType === DocumentType.C_RCA;
    }
    get stepDisplayText() {
        if (!this.documentType) {
            return null;
        }
        return this.usesTasks(this.documentType) ? 'Task' : 'Step';
    }
    buildCreateStepInputPayload() {
        if (!this.isFormValid) {
            return null;
        }
        const stepInputToReturn = {
            action: this.formData.action,
            assigneeContactId: this.formData.assigneeContactId,
            authoritiesOverride: this.formData.authoritiesOverride
                ? this.formData.authoritiesOverride.map((authority) => {
                    return {
                        authorityType: authority?.authorityType,
                        groupCode: authority?.groupCode,
                        manualInspectionPointExplanation: authority?.manualInspectionPointExplanation,
                        manualInspectionPointType: authority?.manualInspectionPointType,
                        requiresComment: true
                    };
                })
                : null,
            caution: this.formData.caution,
            documentId: this.documentId,
            projectedDate: this.formData.projectedDate,
            hardwareListAssemblies: (this.formData.selectedCalloutHardware || []).map((inv) => {
                return {
                    externalCalibrationDate: inv.externalCalibrationDate,
                    externalCalibrationNumber: inv.externalCalibrationNumber,
                    externalClass: inv.externalClass,
                    externalDescription: inv.externalDescription,
                    externalDrawingNumber: inv.externalDrawingNumber,
                    externalIsTool: inv.externalIsTool,
                    externalLotNumber: inv.externalLotNumber,
                    externalSerialNumber: inv.externalSerialNumber,
                    externalShelfLifeDate: inv.externalShelfLifeDate,
                    isExternal: inv.isExternal,
                    inventoryId: inv.isExternal ? null : inv.inventory?.id || inv.id,
                    quantity: inv.quantity ?? inv.inventory?.quantity ?? 1
                };
            }),
            toolsListAssemblies: (this.formData.selectedCalloutTools || []).map((inv) => {
                return {
                    externalCalibrationDate: inv.externalCalibrationDate,
                    externalCalibrationNumber: inv.externalCalibrationNumber,
                    externalClass: inv.externalClass,
                    externalDescription: inv.externalDescription,
                    externalDrawingNumber: inv.externalDrawingNumber,
                    externalIsTool: inv.externalIsTool,
                    externalLotNumber: inv.externalLotNumber,
                    externalSerialNumber: inv.externalSerialNumber,
                    externalShelfLifeDate: inv.externalShelfLifeDate,
                    isExternal: inv.isExternal,
                    inventoryId: inv.isExternal ? null : inv.inventory?.id || inv.id,
                    quantity: inv.quantity ?? inv.inventory?.quantity ?? 1
                };
            }),
            openedDate: this.$dateDisplay(new Date()),
            notes: this.formData.notes,
            startDate: this.formData.overrideStartDate,
            stepNumber: this.formData.stepNumber,
            subType: this.computedChangeSetSubType,
            title: this.formData.title,
            riskScoreAtCompletion: this.formData.riskScoreAtCompletion,
            warning: this.formData.warning
        };
        return stepInputToReturn;
    }
    buildUpdateStepInputPayload() {
        if (!this.isFormValid) {
            return null;
        }
        const stepInputToReturn = {
            action: this.formData.action,
            caution: this.formData.caution,
            documentId: this.documentId,
            notes: this.formData.notes,
            projectedDate: this.formData.projectedDate,
            overrideStartDate: this.formData.overrideStartDate,
            riskScoreAtCompletion: this.formData.riskScoreAtCompletion,
            stepNumber: this.formData.stepNumber,
            subType: this.computedChangeSetSubType,
            title: this.formData.title,
            warning: this.formData.warning
        };
        return stepInputToReturn;
    }
    showSnackbar(message) {
        this.snackbar = true;
        this.snackbarMessage = message;
    }
    onFocusOfMarkdownControl() {
        this.showMarkdownHelper = true;
    }
    onClickOfHardwarePickListItem(item) {
        if (this.formData.selectedCalloutHardware) {
            this.formData.selectedCalloutHardware.push(item);
        }
        else {
            this.formData.selectedCalloutHardware = [item];
        }
        this.showSnackbar('Hardware added');
    }
    onClickOfToolsPickListItem(item) {
        if (this.formData.selectedCalloutTools) {
            this.formData.selectedCalloutTools.push(item);
        }
        else {
            this.formData.selectedCalloutTools = [item];
        }
        this.showSnackbar('Tool added');
    }
    onClickOfRemoveHardwarePickListItem(item) {
        if (item.isExternal && !item.id) {
            this.formData.selectedCalloutHardware =
                this.formData.selectedCalloutHardware?.filter((i) => {
                    return (i._description !== item._description &&
                        i.drawingNumber !== item.drawingNumber &&
                        i.serialNumber !== item.serialNumber);
                }) ?? [];
        }
        else {
            this.formData.selectedCalloutHardware =
                this.formData.selectedCalloutHardware?.filter((i) => {
                    return i.id !== item.id;
                }) ?? [];
        }
    }
    onClickOfRemoveToolsPickListItem(item) {
        if (item.isExternal && !item.id) {
            this.formData.selectedCalloutTools =
                this.formData.selectedCalloutTools?.filter((i) => {
                    return (i._description !== item._description &&
                        i.drawingNumber !== item.drawingNumber &&
                        i.serialNumber !== item.serialNumber);
                }) ?? [];
        }
        else {
            this.formData.selectedCalloutTools =
                this.formData.selectedCalloutTools?.filter((i) => {
                    return i.id !== item.id;
                }) ?? [];
        }
    }
    onHardwareViewModeChange(mode) {
        if (this.isHardwareSelectionModeInventory) {
            if (mode === 'MANUAL') {
                this.hardwareSelectionMode = 2;
            }
            else if (mode === 'INVENTORY') {
                this.hardwareSelectionMode = 1;
            }
        }
    }
    onToolsViewModeChange(mode) {
        if (this.isToolsSelectionModeInventory) {
            if (mode === 'MANUAL') {
                this.toolsSelectionMode = 2;
            }
            else if (mode === 'INVENTORY') {
                this.toolsSelectionMode = 1;
            }
        }
    }
    removeAuthorityOverride(authorityOverride) {
        if (!this.formData.authoritiesOverride) {
            return;
        }
        this.formData.authoritiesOverride = this.formData.authoritiesOverride.filter((ao) => ao.userGroup?.code !== authorityOverride.userGroup?.code);
    }
    async updateWorkOrderStep() {
        const patch = this.buildUpdateStepInputPayload();
        if (!patch) {
            return;
        }
        const authoritiesAreUpdated = !objectArraysMatchOrder(this.originalAuthoritiesOverride || [], this.formData.authoritiesOverride || [], 'groupCode');
        if (!this.workOrderStep?.isEditable || authoritiesAreUpdated) {
            await this.reopenWorkOrderStep();
        }
        await Promise.all([
            this.updateWorkOrderStepById({ input: { patch, id: this.workOrderStepToEditId } }, { success: `${this.stepDisplayText} updated.` }),
            this.updateHardwareListAssemblies(),
            this.updateToolsListAssemblies()
        ]);
        this.emitStepSaved(this.workOrderStepToEditId);
    }
    async onClickOfCreateOrUpdateWorkOrderStep() {
        try {
            this.isSaving = true;
            // if we are editing an existing step
            if (this.workOrderStepToEditId) {
                await this.updateWorkOrderStep();
            }
            else {
                const payload = this.buildCreateStepInputPayload();
                if (!payload) {
                    return;
                }
                const data = await this.createWorkOrderStep({ step: payload }, { success: `${this.stepDisplayText} created.` });
                if (data) {
                    this.$message('workorder-steps-updated');
                    this.emitStepSaved(data.createWorkOrderStep?.workOrderStep?.id);
                }
            }
        }
        catch (err) {
            this.$errorUtility({
                err
            });
        }
        this.isSaving = false;
    }
    async onClickOfSaveDuplicate() {
        const patch = this.buildUpdateStepInputPayload();
        if (!patch) {
            return;
        }
        const authoritiesAreUpdated = !objectArraysMatchOrder(this.originalAuthoritiesOverride || [], this.formData.authoritiesOverride || [], 'groupCode');
        if ((!this.workOrderStep?.isEditable || authoritiesAreUpdated) && !this.isDuplication) {
            await this.reopenWorkOrderStep();
        }
        const payload = this.buildCreateStepInputPayload();
        if (!payload) {
            return;
        }
        const data = await this.createWorkOrderStep({ step: payload }, { success: `${this.stepDisplayText} created.` });
        if (data) {
            this.emitStepSaved(data.createWorkOrderStep?.workOrderStep?.id);
        }
    }
    reopenWorkOrderStep() {
        const authoritiesOverride = this.formData?.authoritiesOverride?.map((authority) => {
            return {
                authorityType: authority.authorityType,
                groupCode: authority.userGroup.code,
                manualInspectionPointExplanation: authority.manualInspectionPointExplanation,
                manualInspectionPointType: authority.manualInspectionPointType,
                requiresComment: true
            };
        });
        return this.reopenChangeSets({
            changeSetIds: [this.workOrderStepToEditId],
            authoritiesOverride: authoritiesOverride
        });
    }
    fetchDocumentInformationQuery(documentId) {
        return this.$apollo.query({
            query: GetDocumentByIdWithLineItems,
            variables: { uuid: documentId }
        });
    }
    fetchWorkOrderStepInformation(stepId) {
        return this.$apollo.query({
            query: gql `
        ${CosmicChangeSetAuthoritySlimFragment}
        ${CosmicUserGroupFragment}
        ${DocumentHardwareAssemblyFragmentOverride}

        fragment WorkOrderStepOnCreateAndEdit on WorkOrderStep {
          action
          assigneeContactId
          caution
          computedStartDate
          documentId
          id
          isEditable
          nodeId
          notes
          overrideStartDate
          projectedDate
          riskScoreAtCompletion
          stepNumber
          title
          warning
        }

        query FetchStepInformation($stepId: Int!) {
          workOrderStepById(id: $stepId) {
            ...WorkOrderStepOnCreateAndEdit

            changeSet {
              id
              nodeId
              changeSetAuthorities {
                nodes {
                  ...CosmicChangeSetAuthoritySlim

                  userGroup {
                    ...CosmicUserGroup
                  }
                }
              }
            }

            hardwareList {
              id
              nodeId
              hardwareListAssemblies {
                nodes {
                  ...DocumentHardwareAssembly
                }
              }
            }
            toolsList {
              id
              nodeId
              hardwareListAssemblies {
                nodes {
                  ...DocumentHardwareAssembly
                }
              }
            }
          }
        }
      `,
            variables: { stepId }
        });
    }
    buildCreateAssemblyPayload(hardwareListId, calloutItem, currentAssemblyList, sequenceAddOn = 1) {
        const nextSequence = (maxBy(currentAssemblyList, (a) => a.sequence)?.sequence ?? 0) + sequenceAddOn;
        return {
            hardwareListAssembly: {
                hardwareListId,
                externalCalibrationDate: calloutItem.externalCalibrationDate,
                externalCalibrationNumber: calloutItem.externalCalibrationNumber,
                externalClass: calloutItem.externalClass,
                externalDescription: calloutItem.externalDescription,
                externalDrawingNumber: calloutItem.externalDrawingNumber,
                externalIsTool: calloutItem.externalIsTool,
                externalLotNumber: calloutItem.externalLotNumber,
                externalSerialNumber: calloutItem.externalSerialNumber,
                externalShelfLifeDate: calloutItem.externalShelfLifeDate,
                isExternal: calloutItem.isExternal,
                inventoryId: calloutItem.isExternal ? null : calloutItem.inventory?.id || calloutItem.id,
                quantity: calloutItem.quantity ?? calloutItem.inventory?.quantity ?? 1,
                sequence: nextSequence
            }
        };
    }
    async updateHardwareListAssemblies() {
        if (!this.workOrderStep?.hardwareList?.id) {
            return Promise.resolve([]);
        }
        const { hardwareList } = this.workOrderStep;
        const assemblies = hardwareList.hardwareListAssemblies.nodes;
        const itemsToAdd = this.formData.selectedCalloutHardware?.filter((formItem) => {
            return !hardwareList.hardwareListAssemblies.nodes.find((existingItem) => existingItem?.id === formItem.id);
        });
        const itemsToDelete = hardwareList.hardwareListAssemblies.nodes.filter((existingItem) => {
            return !this.formData.selectedCalloutHardware?.find((formItem) => existingItem?.id === formItem.id);
        });
        const addRequests = itemsToAdd?.map((item, i) => {
            const sequenceAddOn = i + 1;
            return this.createHardwareListAssembly({
                input: this.buildCreateAssemblyPayload(hardwareList.id, item, assemblies, sequenceAddOn)
            });
        }) ?? [];
        const deleteRequests = itemsToDelete.map((item) => {
            return this.deleteHardwareListAssemblyById({ id: item?.id });
        }) ?? [];
        const responses = await Promise.all([...deleteRequests, ...addRequests]);
        return responses;
    }
    async updateToolsListAssemblies() {
        if (!this.workOrderStep?.toolsList?.id) {
            return Promise.resolve([]);
        }
        const { toolsList } = this.workOrderStep;
        const assemblies = toolsList.hardwareListAssemblies.nodes;
        const itemsToAdd = this.formData.selectedCalloutTools?.filter((formItem) => {
            return !toolsList.hardwareListAssemblies.nodes.find((existingItem) => existingItem?.id === formItem.id);
        });
        const itemsToDelete = toolsList.hardwareListAssemblies.nodes.filter((existingItem) => {
            return !this.formData.selectedCalloutTools?.find((formItem) => existingItem?.id === formItem.id);
        });
        const addRequests = itemsToAdd?.map((item, i) => {
            const sequenceAddOn = i + 1;
            return this.createHardwareListAssembly({
                input: this.buildCreateAssemblyPayload(toolsList.id, item, assemblies, sequenceAddOn)
            });
        }) ?? [];
        const deleteRequests = itemsToDelete.map((item) => {
            return this.deleteHardwareListAssemblyById({ id: item?.id });
        }) ?? [];
        const responses = await Promise.all([...deleteRequests, ...addRequests]);
        return responses;
    }
    async populateStepsForm() {
        if (this.workOrderStepToEditId) {
            try {
                const resp = await this.fetchWorkOrderStepInformation(this.workOrderStepToEditId);
                if (resp.data.workOrderStepById) {
                    const { workOrderStepById } = resp.data;
                    this.workOrderStep = workOrderStepById;
                    this.formData.title = workOrderStepById.title;
                    this.formData.action = workOrderStepById.action;
                    this.formData.caution = workOrderStepById.caution;
                    this.formData.projectedDate = workOrderStepById.projectedDate;
                    this.formData.overrideStartDate = workOrderStepById.overrideStartDate;
                    this.formData.riskScoreAtCompletion = workOrderStepById.riskScoreAtCompletion;
                    this.formData.notes = workOrderStepById.notes;
                    this.formData.stepNumber = workOrderStepById.stepNumber;
                    this.formData.warning = workOrderStepById.warning;
                    const authoritiesOverride = workOrderStepById.changeSet?.changeSetAuthorities.nodes
                        .map((csa) => {
                        if (!csa?.userGroup || csa.isHistorical) {
                            return null;
                        }
                        const userGroup = transformUserGroups([csa.userGroup])[0];
                        return {
                            authorityType: csa.authorityType || ChangeSetAuthorityType.DEFAULT,
                            groupCode: userGroup.code,
                            userGroup,
                            manualInspectionPointExplanation: csa.manualInspectionPointExplanation,
                            manualInspectionPointType: csa.manualInspectionPointType
                        };
                    })
                        .filter((_) => _);
                    this.formData.authoritiesOverride = this.isDuplication ? [] : authoritiesOverride;
                    this.originalAuthoritiesOverride = this.isDuplication ? [] : cloneDeep(authoritiesOverride);
                    this.formData.selectedCalloutHardware = transformHardwareListAssemblies(workOrderStepById.hardwareList?.hardwareListAssemblies.nodes ?? []);
                    this.formData.selectedCalloutTools = transformHardwareListAssemblies(workOrderStepById.toolsList?.hardwareListAssemblies.nodes ?? []);
                }
            }
            catch (err) {
                this.$errorUtility({
                    err
                });
            }
        }
    }
    async onDocumentIdChange() {
        try {
            const resp = await this.fetchDocumentInformationQuery(this.documentId);
            if (resp.data.documentById) {
                this.entity = resp.data.documentById;
            }
        }
        catch (err) {
            this.$errorUtility({
                err
            });
        }
    }
    async fetchDefaultCosmicUserGroupWithAssignedUsers() {
        // if we already have authority overrides, we don't need a default
        if (this.formData.authoritiesOverride?.length) {
            return;
        }
        const docSubType = this.entity?.subType;
        const defaultGroupCode = docSubType === DocumentType.C_DISCREPANCY_REPORT
            ? cDrBuyoffGroupCode
            : docSubType === DocumentType.C_TPS
                ? cTpsBuyoffGroupCode
                : null;
        if (!defaultGroupCode) {
            return;
        }
        const resp = await this.$http.get(`/user-groups/${defaultGroupCode}`);
        if (!resp.data) {
            return;
        }
        const defaultGroup = transformUserGroupsResponse([resp.data])[0];
        this.formData.authoritiesOverride = [
            {
                authorityType: ChangeSetAuthorityType.DEFAULT,
                groupCode: defaultGroup.code,
                manualInspectionPointExplanation: null,
                manualInspectionPointType: null,
                userGroup: defaultGroup
            }
        ];
    }
    onDefaultStepNumberChange() {
        this.formData.stepNumber = this.defaultStepNumber;
    }
    emitStepSaved(stepId) {
        return stepId;
    }
};
__decorate([
    Prop({
        type: String,
        required: true
    }),
    __metadata("design:type", Object)
], ModalWorkOrderStepCreateAndEdit.prototype, "documentId", void 0);
__decorate([
    Prop({
        type: Number
    }),
    __metadata("design:type", Number)
], ModalWorkOrderStepCreateAndEdit.prototype, "workOrderStepToEditId", void 0);
__decorate([
    Prop({
        type: Number,
        default: 0
    }),
    __metadata("design:type", Number)
], ModalWorkOrderStepCreateAndEdit.prototype, "maxStepNumber", void 0);
__decorate([
    Prop({
        type: Number,
        default: 1
    }),
    __metadata("design:type", Number)
], ModalWorkOrderStepCreateAndEdit.prototype, "defaultStepNumber", void 0);
__decorate([
    Prop({
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], ModalWorkOrderStepCreateAndEdit.prototype, "isDuplication", void 0);
__decorate([
    Watch('workOrderStepToEditId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], ModalWorkOrderStepCreateAndEdit.prototype, "populateStepsForm", null);
__decorate([
    Watch('documentId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], ModalWorkOrderStepCreateAndEdit.prototype, "onDocumentIdChange", null);
__decorate([
    Watch('entity', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], ModalWorkOrderStepCreateAndEdit.prototype, "fetchDefaultCosmicUserGroupWithAssignedUsers", null);
__decorate([
    Watch('defaultStepNumber', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalWorkOrderStepCreateAndEdit.prototype, "onDefaultStepNumberChange", null);
__decorate([
    Emit('step-saved'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], ModalWorkOrderStepCreateAndEdit.prototype, "emitStepSaved", null);
ModalWorkOrderStepCreateAndEdit = __decorate([
    Component
], ModalWorkOrderStepCreateAndEdit);
export default ModalWorkOrderStepCreateAndEdit;
