var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": "primary",
      "hide-title-section": ""
    },
    on: {
      "modal-close": _vm.closeDialog
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_vm.userHasPermission ? [_c('Alert', {
          attrs: {
            "type": "info"
          }
        }, [_c('p', [_vm._v("Enter the type and number of the Authority on which you logged this Activity.")])]), _c('AppAuthority', {
          attrs: {
            "required": "",
            "hide-header": "",
            "show-border": false,
            "comment-title": "New Authority Comment"
          },
          model: {
            value: _vm.formData,
            callback: function ($$v) {
              _vm.formData = $$v;
            },
            expression: "formData"
          }
        })] : _vm._e(), !_vm.userHasPermission ? [_c('Alert', {
          attrs: {
            "type": "error"
          }
        }, [_c('p', [_vm._v(" You do not have permission "), _c('code', [_vm._v(_vm._s(_vm.userPermissionRequired))])]), _c('p', [_vm._v("Contact a User Admin to be granted this permission.")]), _c('p', {
          staticClass: "mb-0"
        }, [_vm._v(" Find a list of "), _c('a', {
          attrs: {
            "href": "/reporting/user_admin_listing",
            "target": "_blank"
          }
        }, [_vm._v("User Admins here")]), _vm._v(". ")])])] : _vm._e()];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonSave', {
          staticClass: "mr-2",
          attrs: {
            "disabled": _vm.shouldDisableSave,
            "is-saving": _vm.isSaving
          },
          on: {
            "click": _vm.onSave
          }
        }), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        })];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };