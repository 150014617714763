import { __decorate, __metadata } from "tslib";
import chunk from 'lodash/chunk';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { WorkOrderStepRepoMixin } from '~/nasa_ui/DAL/document/WorkOrderStep';
import CurrentUserMixin from '~/nasa_ui/mixins/CurrentUserMixin';
import GenericDocumentMixin from '~/nasa_ui/mixins/GenericDocumentMixin';
import LoaderMixin from '~/nasa_ui/mixins/LoaderMixin';
import PdfExportMixin from '~/nasa_ui/mixins/PdfExport';
import ReadOnlyMixin from '~/nasa_ui/mixins/ReadOnlyMixin';
import { DocumentStatus } from '~/nasa_ui/utils/DocumentStatus';
import { StepsStatus } from '~/nasa_ui/utils/StepsStatus';
let TabWorkOrderStepsOverview = class TabWorkOrderStepsOverview extends Mixins(ReadOnlyMixin, GenericDocumentMixin, LoaderMixin, PdfExportMixin, CurrentUserMixin, WorkOrderStepRepoMixin) {
    document = null;
    steps = [];
    stepIndex = 0;
    showNewStepModal = false;
    showImportSpreadsheetModal = false;
    showCompleteStepModal = false;
    changeSetIdsToApprove = [];
    id;
    get stepStatus() {
        if (!this.document?.workOrderSteps?.nodes) {
            return null;
        }
        return new StepsStatus(this.document.workOrderSteps.nodes, this.sourceDoc);
    }
    get documentStatus() {
        if (!this.sourceDoc) {
            return null;
        }
        return new DocumentStatus(this.sourceDoc, this.getCorrespondingAdminGroup(this.document.subType));
    }
    get currentUserCanApproveAnySteps() {
        return Boolean(this.stepStatus?.currentUserCanApproveAnySteps);
    }
    get sourceDoc() {
        return this.getSourceDoc(this.document);
    }
    get isInitialized() {
        return Boolean(this.documentStatus?.isInitialized);
    }
    get pendingInitialization() {
        return Boolean(this.documentStatus?.isPending);
    }
    get allStepsComplete() {
        return Boolean(this.stepStatus?.allStepsComplete);
    }
    onProcedureUpdated(updates) {
        // We could just refresh the data, but for the sake of speed
        // I'm just updating the data in-place on a successful update
        const { action, caution, computedStartDate, id, notes, overrideStartDate, projectedDate, riskScoreAtCompletion, title, warning } = updates;
        if (!id) {
            throw Error('No step ID was emitted from the procedure-updated event');
        }
        const step = this.steps?.find((s) => s?.id === id);
        if (step) {
            step.action = action;
            step.caution = caution;
            step.computedStartDate = computedStartDate;
            step.projectedDate = projectedDate;
            step.notes = notes;
            step.overrideStartDate = overrideStartDate;
            step.riskScoreAtCompletion = riskScoreAtCompletion;
            step.title = title;
            step.warning = warning;
        }
    }
    async onClickOfExportToPdfButton() {
        const getLatestDocument = await this.queryForPDFExport(this.id);
        if (getLatestDocument && this.currentUserActiveSupportContext) {
            const { default: DocumentExport } = await import('~/nasa_ui/utils/DocumentExport');
            new DocumentExport({
                type: this.document.subType,
                context: this.currentUserActiveSupportContext,
                data: getLatestDocument
            });
        }
    }
    onClickOfCompleteAllSteps() {
        this.showCompleteStepModal = true;
        this.changeSetIdsToApprove = this.steps.map((step) => step.changeSet.id);
    }
    async fetchSteps() {
        this.closeModals();
        this.isLoading = true;
        const data = await this.getWorkOrderStepsOverview({ id: this.id });
        this.document = data?.documentById;
        this.setSteps(data?.documentById?.workOrderSteps.nodes);
        this.isLoading = false;
    }
    closeModals() {
        this.showCompleteStepModal = false;
        this.showImportSpreadsheetModal = false;
        this.showImportSpreadsheetModal = false;
        this.showNewStepModal = false;
    }
    // Maybe we want to do this differently in the future BUT...
    // While fetching the step data isn't taking very long, the render in the v-for is taking a while
    // This is a little hack to chunk the steps into groups of 5 and render them progressively
    setSteps(steps) {
        const [firstChunk, ...rest] = chunk(steps, 5);
        this.steps = firstChunk ?? [];
        rest.forEach((chunk, i) => {
            setTimeout(() => {
                this.steps = [...this.steps, ...chunk];
            }, i * 500);
        });
    }
};
__decorate([
    Prop({
        required: true,
        type: String
    }),
    __metadata("design:type", Object)
], TabWorkOrderStepsOverview.prototype, "id", void 0);
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], TabWorkOrderStepsOverview.prototype, "fetchSteps", null);
TabWorkOrderStepsOverview = __decorate([
    Component({
        components: {
            ModalWorkOrderStepImportSpreadsheet: () => import('~/nasa_ui/components/ModalWorkOrderStepImportSpreadsheet/ModalWorkOrderStepImportSpreadsheet.vue')
        }
    })
], TabWorkOrderStepsOverview);
export default TabWorkOrderStepsOverview;
