var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';
import { DocumentType } from '~/db_types';
import ReadOnlyMixin from '~/nasa_ui/mixins/ReadOnlyMixin';
let WorkOrderStepsGettingStarted = class WorkOrderStepsGettingStarted extends Mixins(ReadOnlyMixin) {
    showImportModal = false;
    showNewWorkOrderStepModal = false;
    documentId;
    documentType;
    get stepDisplayText() {
        return this.documentType === DocumentType.C_WORKFLOW ? 'Task' : 'Step';
    }
    onImportComplete(steps) {
        this.showImportModal = false;
        this.showNewWorkOrderStepModal = false;
        const [stepOne] = steps;
        return stepOne.createWorkOrderStep?.workOrderStep?.id;
    }
    onNewStepComplete(id) {
        return id;
    }
};
__decorate([
    Prop({
        required: true,
        type: String
    }),
    __metadata("design:type", Object)
], WorkOrderStepsGettingStarted.prototype, "documentId", void 0);
__decorate([
    Prop({
        required: true,
        type: String
    }),
    __metadata("design:type", typeof (_a = typeof DocumentType !== "undefined" && DocumentType) === "function" ? _a : Object)
], WorkOrderStepsGettingStarted.prototype, "documentType", void 0);
__decorate([
    Emit('created'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], WorkOrderStepsGettingStarted.prototype, "onImportComplete", null);
__decorate([
    Emit('created'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], WorkOrderStepsGettingStarted.prototype, "onNewStepComplete", null);
WorkOrderStepsGettingStarted = __decorate([
    Component({
        components: {
            ModalWorkOrderStepImportSpreadsheet: () => import('~/nasa_ui/components/ModalWorkOrderStepImportSpreadsheet/ModalWorkOrderStepImportSpreadsheet.vue')
        }
    })
], WorkOrderStepsGettingStarted);
export default WorkOrderStepsGettingStarted;
