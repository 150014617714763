var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "title": _vm.computedTitle,
      "hide-title-section": ""
    },
    on: {
      "modal-close": _vm.closeDialog
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_vm.isFetching ? _c('AppLoader', {
          attrs: {
            "center": "",
            "type": "linear"
          }
        }) : _c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs6": "",
            "pr-3": ""
          }
        }, [_c('AppDate', {
          staticClass: "mb-2",
          attrs: {
            "label": "Start",
            "required": ""
          },
          model: {
            value: _vm.formData.startDate,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "startDate", $$v);
            },
            expression: "formData.startDate"
          }
        }), _c('c-text', {
          staticClass: "time_input",
          attrs: {
            "label": "Time",
            "outline": "",
            "required": "",
            "type": "time",
            "c-value": _vm.formData.startTime
          },
          on: {
            "c-input": function ($event) {
              _vm.formData.startTime = $event.detail;
            }
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs6": "",
            "pl-3": ""
          }
        }, [_c('AppDate', {
          staticClass: "mb-2",
          attrs: {
            "label": "End",
            "required": ""
          },
          model: {
            value: _vm.formData.endDate,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "endDate", $$v);
            },
            expression: "formData.endDate"
          }
        }), _c('c-text', {
          staticClass: "time_input",
          attrs: {
            "label": "Time",
            "outline": "",
            "required": "",
            "type": "time",
            "c-value": _vm.formData.endTime
          },
          on: {
            "c-input": function ($event) {
              _vm.formData.endTime = $event.detail;
            }
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs6": "",
            "pr-3": ""
          }
        }, [_c('AppSelectEnum', {
          attrs: {
            "chips": false,
            "display": _vm.OutageReasonDisplay,
            "enum": _vm.OutageReason,
            "label": "Reason",
            "required": ""
          },
          model: {
            value: _vm.formData.reason,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "reason", $$v);
            },
            expression: "formData.reason"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs6": "",
            "pl-3": ""
          }
        }, [_c('AppCheckbox', {
          attrs: {
            "label": "Is Active?"
          },
          model: {
            value: _vm.formData.isActive,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "isActive", $$v);
            },
            expression: "formData.isActive"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('AppMarkdown', {
          attrs: {
            "label": "User-facing message",
            "required": ""
          },
          model: {
            value: _vm.formData.userFacingMessage,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "userFacingMessage", $$v);
            },
            expression: "formData.userFacingMessage"
          }
        })], 1)], 1)];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_vm.isEditing ? _c('ButtonSave', {
          attrs: {
            "disabled": _vm.shouldDisableSaveButton,
            "is-saving": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfSaveButton
          }
        }) : _c('ButtonCreate', {
          attrs: {
            "disabled": _vm.shouldDisableSaveButton,
            "is-saving": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfSaveButton
          }
        }), _c('ButtonCancel', {
          on: {
            "click": _vm.closeDialog
          }
        }, [_vm._v("Cancel")])];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };