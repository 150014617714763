import { __decorate, __metadata } from "tslib";
import { Component, Prop, Watch } from 'vue-property-decorator';
import { ChangeSetStatus } from '~/db_types';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
let WorkOrderStepComments = class WorkOrderStepComments extends BaseCosmic {
    ChangeSetStatus = ChangeSetStatus;
    comments = [];
    authorities;
    onAuthoritiesChange() {
        this.comments = this.authorities
            .filter((a) => a.status === ChangeSetStatus.APPROVED || a.status === ChangeSetStatus.REJECTED)
            .map((a) => ({
            status: a.status,
            authorizedDate: this.$dateDisplay(a.authorizedDate),
            authorizedBy: this.$contactDisplay(a.authorizedByUser),
            qualityControlStamp: a.qualityControlStamp ?? null,
            text: a.computedCommentText ?? null,
            key: a.id,
            shown: false
        }));
    }
};
__decorate([
    Prop({
        required: true,
        type: Array
    }),
    __metadata("design:type", Array)
], WorkOrderStepComments.prototype, "authorities", void 0);
__decorate([
    Watch('authorities', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], WorkOrderStepComments.prototype, "onAuthoritiesChange", null);
WorkOrderStepComments = __decorate([
    Component
], WorkOrderStepComments);
export default WorkOrderStepComments;
