import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CosmicUserFragment as CosmicUserFragmentQuery } from '~/nasa_ui/DAL/user';
let ButtonEmail = class ButtonEmail extends Vue {
    color;
    disabled;
    organizationCode;
    allUsers = [];
    get emailAddresses() {
        // remove spaces and join emails with commas
        return this.$flatten(this.filteredUsers?.map((user) => user?.email)).join(', ');
    }
    get filteredUsers() {
        // filter out users based on organizationCode
        return this.organizationCode
            ? this.allUsers?.filter((user) => {
                return Boolean(user?.supports.nodes.find((user) => user?.organizationCode === this.organizationCode));
            })
            : this.allUsers;
    }
    get mailToHref() {
        return `mailto:${this.emailAddresses}`;
    }
    onClick() {
        this.$emit('click');
    }
    created() {
        this.fetchAllUsersEmails();
    }
    async fetchAllUsersEmails() {
        try {
            const { data } = await this.$apollo.query({
                query: gql `
          ${CosmicUserFragmentQuery}

          query UsersForEmail {
            users {
              nodes {
                ...CosmicUser
              }
            }
          }
        `
            });
            this.allUsers = data.users?.nodes || [];
        }
        catch (err) {
            this.$errorUtility({ err });
        }
    }
};
__decorate([
    Prop({
        default: 'primary',
        type: String
    }),
    __metadata("design:type", String)
], ButtonEmail.prototype, "color", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], ButtonEmail.prototype, "disabled", void 0);
__decorate([
    Prop({
        default: '',
        type: String
    }),
    __metadata("design:type", String)
], ButtonEmail.prototype, "organizationCode", void 0);
ButtonEmail = __decorate([
    Component
], ButtonEmail);
export default ButtonEmail;
