var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.ASSEMBLY_TEMPLATE,
      "hide-title-section": "",
      "modal-width": "75vw"
    },
    on: {
      "modal-close": _vm.emitModalClose
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('ButtonCreate', {
          attrs: {
            "disabled": _vm.shouldDisableCreateButton,
            "is-saving": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfCreate
          }
        }), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        })];
      },
      proxy: true
    }])
  }, [_c('template', {
    slot: "content"
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('Alert', {
    attrs: {
      "dense": ""
    }
  }, [_c('c-icon-fa', {
    attrs: {
      "slot": "icon",
      "icon": _vm.$icons['CHICKLET']
    },
    slot: "icon"
  }), _vm._v(" This will create a new \"top-level\" or \"root\" assembly on which you can build out your assembly structure. ")], 1)], 1), _c('v-flex', {
    attrs: {
      "xs6": "",
      "pr-3": ""
    }
  }, [_c('c-text', {
    attrs: {
      "autofocus": "",
      "label": "Name",
      "c-value": _vm.formData.name,
      "tooltip": "This will be the display of the node and serve as it's alias",
      "tooltip-position": "top"
    },
    on: {
      "c-input": function ($event) {
        _vm.formData.name = $event.detail;
      }
    }
  })], 1), _c('v-divider', {
    staticClass: "my-2"
  }), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_vm.selectedHardwareList ? _c('AppPageDescription', {
    attrs: {
      "remove-margin-left": "",
      "color": "success"
    }
  }, [_vm._v(" Attaching new assembly template to this list ")]) : _c('AppPageDescription', {
    attrs: {
      "remove-margin-left": "",
      "remove-margin-bottom": "",
      "color": "info"
    }
  }, [_vm._v(" Attach to a Hardware List that is marked as \"Critical\" "), _c('span', {
    attrs: {
      "tooltip": "This helps COSMIC provide feedback on valid installations."
    }
  }, [_c('c-icon-fa', {
    attrs: {
      "icon-size": "16px",
      "icon": _vm.$icons.help
    }
  })], 1)]), _c('v-slide-y-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.selectedHardwareList ? _c('MiniHardwareList', {
    attrs: {
      "id": _vm.selectedHardwareList.id,
      "read-only": false,
      "show-manage-link": false,
      "inline-clear": ""
    },
    on: {
      "inlineClear": function ($event) {
        _vm.selectedHardwareList = null;
      }
    }
  }) : _vm._e()], 1), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [!_vm.selectedHardwareList ? _c('SearchHardwareList', {
    attrs: {
      "output-mode": "object",
      "filters": _vm.filterForOnlyCriticalHardwareLists,
      "autosearch": ""
    },
    model: {
      value: _vm.selectedHardwareList,
      callback: function ($$v) {
        _vm.selectedHardwareList = $$v;
      },
      expression: "selectedHardwareList"
    }
  }) : _vm._e()], 1)], 1)], 1)], 1)], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };