import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { DocumentRelationType } from '~/db_types';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import { CLOSED_DATE_COL, CREATED_BY_COL, NUMBER_NO_UNDERSCORE_COL, OPENED_DATE_COL, SUB_TYPE_COL, TITLE_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import { CosmicRelatedDocumentFragment } from '~/nasa_ui/DAL/document/Information';
import { setRelatedDocumentsMutation } from '~/nasa_ui/DAL/document/relatedDocuments';
import ReadOnlyMixin from '~/nasa_ui/mixins/ReadOnlyMixin';
import { AlertType, DocumentTypeDisplay } from '~/nasa_ui/types';
import { transformDocuments } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
export const DocumentRelationTypeDisplay = new Map([
    [DocumentRelationType.FRACAS_DISCREPANCY_REPORT, 'cFRACAS Discrepancy Report'],
    [DocumentRelationType.REFERENCE_DOCUMENT, 'Reference Document'],
    [DocumentRelationType.SOURCE_DOCUMENT, 'Source Document']
]);
let AppRelatedDocuments = class AppRelatedDocuments extends Mixins(ReadOnlyMixin, BaseCosmic) {
    relatedDocuments = [];
    selectedRowDocumentNumber = null;
    showCreateModal = false;
    showEditModal = false;
    showDeleteConfirmation = false;
    id;
    get relatedDocumentsTableHeaders() {
        return [
            NUMBER_NO_UNDERSCORE_COL,
            {
                text: 'Relation<br/>type',
                value: '_relationType',
                sortable: true
            },
            SUB_TYPE_COL,
            CREATED_BY_COL,
            OPENED_DATE_COL,
            CLOSED_DATE_COL,
            TITLE_COL
        ];
    }
    get relatedDocumentsTableItems() {
        const documents = this.relatedDocuments.map((relatedDoc) => {
            const documentNumber = relatedDoc.documentNumber;
            const document = relatedDoc.relatedDocument;
            const relationType = relatedDoc.relationType;
            // if you have a document number and no id, then this was a manually
            // entered document number
            if (documentNumber && !relatedDoc.documentId) {
                return {
                    _relationType: DocumentRelationTypeDisplay.get(relationType),
                    number: documentNumber,
                    subType: undefined // needed for display to show a dash
                };
            }
            return {
                ...document,
                _relationType: DocumentRelationTypeDisplay.get(relationType)
            };
        });
        return transformDocuments(documents);
    }
    get selectedRelatedDocument() {
        return this.selectedRowDocumentNumber
            ? this.relatedDocuments.find((relatedDoc) => relatedDoc.documentNumber === this.selectedRowDocumentNumber)
            : null;
    }
    buildDocumentUrl(row) {
        return `/${DocumentTypeDisplay.get(row.subType)}/manage/${row.id}#information`;
    }
    async deleteRelation(row) {
        if (this.isReadOnly) {
            return;
        }
        if (!this.selectedRelatedDocument) {
            return;
        }
        try {
            // clone existing documents
            const existingDocuments = this.relatedDocuments.map((doc) => {
                return {
                    documentId: doc.documentId,
                    documentNumber: doc.documentNumber,
                    relationType: doc.relationType
                };
            });
            // remove the document in question
            const relatedDocuments = existingDocuments.filter((doc) => doc.documentNumber !== this.selectedRelatedDocument.documentNumber);
            await setRelatedDocumentsMutation({
                forDocumentId: this.id,
                relatedDocuments
            });
            this.$notification.add({
                text: 'Removed related Document.',
                type: AlertType.SUCCESS
            });
            this.reset();
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    // rather than store the display version, I want to store the original version
    onClickOfRelatedDocument(row) {
        if (this.isReadOnly) {
            return;
        }
        this.selectedRowDocumentNumber = row.number;
        this.showEditModal = true;
    }
    onClickOfDeleteRelatedDocument(row) {
        this.showDeleteConfirmation = true;
        this.selectedRowDocumentNumber = row.number;
    }
    onCloseOfEditModal() {
        this.reset();
    }
    onRefreshOfCreateModal() {
        this.fetchRelatedDocuments(this.id);
    }
    reset() {
        this.selectedRowDocumentNumber = null;
        this.showCreateModal = false;
        this.showEditModal = false;
        this.showDeleteConfirmation = false;
        this.fetchRelatedDocuments(this.id);
    }
    onChangeOfId(id) {
        this.fetchRelatedDocuments(id);
    }
    async fetchRelatedDocuments(id) {
        if (!id) {
            return null;
        }
        try {
            const resp = await this.fetchRelatedDocumentsQuery(id);
            if (resp?.data.documentById?.relatedDocuments.nodes) {
                this.relatedDocuments = resp.data.documentById.relatedDocuments.nodes;
                // if there are related documents, let the consumer know
                if (this.relatedDocuments.length) {
                    this.emitHasRelatedDocuments();
                }
            }
        }
        catch (err) {
            this.$errorUtility({ err });
        }
    }
    fetchRelatedDocumentsQuery(id) {
        if (!id) {
            return null;
        }
        return this.$apollo.query({
            query: gql `
        ${CosmicRelatedDocumentFragment}

        query GetRelatedDocuments($id: UUID!) {
          documentById(id: $id) {
            nodeId

            relatedDocuments {
              nodes {
                ...CosmicRelatedDocument
              }
            }
          }
        }
      `,
            variables: { id }
        });
    }
    emitHasRelatedDocuments() { }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], AppRelatedDocuments.prototype, "id", void 0);
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], AppRelatedDocuments.prototype, "onChangeOfId", null);
__decorate([
    Emit('hasRelatedDocuments'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppRelatedDocuments.prototype, "emitHasRelatedDocuments", null);
AppRelatedDocuments = __decorate([
    Component
], AppRelatedDocuments);
export default AppRelatedDocuments;
