var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('ButtonGeneric', _vm._g(_vm._b({
    attrs: {
      "color": _vm.computedColor,
      "disabled": _vm.computedDisabled,
      "icon": _vm.computedIcon,
      "loading": _vm.isSearching,
      "text-color": _vm.computedTextColor
    }
  }, 'ButtonGeneric', {
    ..._vm.$attrs,
    ..._vm.$props
  }, false), _vm.$listeners), [_vm._v(" Run Report ")]);
};
var staticRenderFns = [];
export { render, staticRenderFns };