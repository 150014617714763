/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./AppManageTabs.vue?vue&type=template&id=0371db6c&scoped=true"
import script from "./AppManageTabs.ts?vue&type=script&lang=ts&external"
export * from "./AppManageTabs.ts?vue&type=script&lang=ts&external"
import style0 from "./AppManageTabs.vue?vue&type=style&index=0&id=0371db6c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0371db6c",
  null
  
)

export default component.exports