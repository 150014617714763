var _a, _b, _c, _d, _e, _f;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
import ModalCommentBase from '../ModalCommentBase/ModalCommentBase';
import CommentModalTemplate from '../ModalCommentBase/_commentModalTemplate.vue';
let ModalCommentCreate = class ModalCommentCreate extends ModalCommentBase {
    activityId;
    changeSetAuthorityId;
    changeSetId;
    commentTypeOverride;
    deliveryMatrixItemId;
    documentId;
    eventId;
    hardwareListId;
    itemDrawingNodeId;
    itemMasterNodeId;
    itemInstanceNodeId;
    organizationCode;
    physicalInventoryAuditNodeId;
    riskId;
    riskMitigationId;
    readOnly;
    replyToText;
    get hasAtleastOneNodeIdOrUniversalUniqueId() {
        return Boolean(this.activityId ||
            this.changeSetId ||
            this.changeSetAuthorityId ||
            this.deliveryMatrixItemId ||
            this.documentId ||
            this.eventId ||
            this.hardwareListId ||
            this.itemDrawingNodeId ||
            this.itemMasterNodeId ||
            this.itemInstanceNodeId ||
            this.organizationCode ||
            this.physicalInventoryAuditNodeId ||
            this.riskId);
    }
    get commentPayload() {
        if (!this.formData?.subType || !this.hasAtleastOneNodeIdOrUniversalUniqueId || !this.formData.comment) {
            return null;
        }
        return {
            activityId: this.activityId,
            changeSetId: this.changeSetId,
            changeSetAuthorityId: this.changeSetAuthorityId,
            comment: this.formData.comment || '',
            deliveryMatrixItemId: this.deliveryMatrixItemId,
            documentId: this.documentId,
            eventId: this.eventId,
            hardwareListId: this.hardwareListId,
            itemDrawing: this.itemDrawingNodeId,
            itemInstance: this.itemInstanceNodeId,
            itemMaster: this.itemMasterNodeId,
            organizationCode: this.organizationCode,
            physicalInventoryAudit: this.physicalInventoryAuditNodeId,
            riskId: this.riskId,
            riskMitigationId: this.riskMitigationId,
            subType: this.formData.subType
        };
    }
    get shouldDisableCreateCommentButton() {
        return !this.commentPayload;
    }
    created() {
        if (this.replyToText) {
            const lines = this.replyToText.split('\n');
            const newLines = lines.reduce((acc, curr) => {
                if (curr.startsWith('>')) {
                    const indentation = curr.split(' ')[0].length;
                    return { lines: [...acc.lines, curr], maxIndentation: indentation };
                }
                const newlyIndentedLine = `${'>'.repeat(acc.maxIndentation + 1)} ${curr}`;
                return { lines: [...acc.lines, newlyIndentedLine], maxIndentation: acc.maxIndentation };
            }, { lines: [], maxIndentation: 0 });
            this.formData.comment = newLines.lines.join('\n');
        }
    }
    emitCommentCreated() { }
    async onClickOfCreate() {
        await this.createEntity(this.commentPayload);
        this.emitModalClose();
        this.emitCommentCreated();
    }
    onChangeOfCommentTypeOverride(val) {
        if (val && this.formData) {
            this.formData.subType = val;
        }
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], ModalCommentCreate.prototype, "activityId", void 0);
__decorate([
    Prop({
        type: Number
    }),
    __metadata("design:type", Object)
], ModalCommentCreate.prototype, "changeSetAuthorityId", void 0);
__decorate([
    Prop({
        type: Number
    }),
    __metadata("design:type", Number)
], ModalCommentCreate.prototype, "changeSetId", void 0);
__decorate([
    Prop({
        type: String,
        default: null
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], ModalCommentCreate.prototype, "commentTypeOverride", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object)
], ModalCommentCreate.prototype, "deliveryMatrixItemId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_c = typeof Maybe !== "undefined" && Maybe) === "function" ? _c : Object)
], ModalCommentCreate.prototype, "documentId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_d = typeof Maybe !== "undefined" && Maybe) === "function" ? _d : Object)
], ModalCommentCreate.prototype, "eventId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], ModalCommentCreate.prototype, "hardwareListId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], ModalCommentCreate.prototype, "itemDrawingNodeId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], ModalCommentCreate.prototype, "itemMasterNodeId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], ModalCommentCreate.prototype, "itemInstanceNodeId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], ModalCommentCreate.prototype, "organizationCode", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], ModalCommentCreate.prototype, "physicalInventoryAuditNodeId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], ModalCommentCreate.prototype, "riskId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], ModalCommentCreate.prototype, "riskMitigationId", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true
    }),
    __metadata("design:type", Boolean)
], ModalCommentCreate.prototype, "readOnly", void 0);
__decorate([
    Prop({
        type: String,
        required: false,
        default: null
    }),
    __metadata("design:type", typeof (_e = typeof Maybe !== "undefined" && Maybe) === "function" ? _e : Object)
], ModalCommentCreate.prototype, "replyToText", void 0);
__decorate([
    Emit('commentCreated'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalCommentCreate.prototype, "emitCommentCreated", null);
__decorate([
    Watch('commentTypeOverride', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_f = typeof Maybe !== "undefined" && Maybe) === "function" ? _f : Object]),
    __metadata("design:returntype", void 0)
], ModalCommentCreate.prototype, "onChangeOfCommentTypeOverride", null);
ModalCommentCreate = __decorate([
    Component({
        components: {
            CommentModalTemplate
        }
    })
], ModalCommentCreate);
export default ModalCommentCreate;
