import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { COSMIC_APPLICATION } from '~/application.config';
import BaseTab from '~/nasa_ui/base/BaseTab';
import { UserRepoMixin } from '~/nasa_ui/DAL/user';
import { EntityType, EsocApplication } from '~/nasa_ui/types';
import { isNullOrUndefined } from '~/nasa_ui/utils/helpers/isNullOrUndefined';
import UserFavorites from '../UserFavorites/UserFavorites.vue';
let TabUserSettings = class TabUserSettings extends Mixins(BaseTab, UserRepoMixin) {
    user = null;
    formData = {
        showCalibrationsLink: true,
        showFavoritesLink: true,
        showLimitedLifeLink: true,
        showMyApprovals: true,
        showNewBrowserWindowIcon: true,
        showUserSupport: true
    };
    get existingNavbarSettings() {
        return (this.currentUserActiveSupportContext &&
            this.currentUser?.attributes?.navbar?.[this.currentUserActiveSupportContext]);
    }
    get isFormDirty() {
        return (this.formData.showCalibrationsLink !== this.existingNavbarSettings?.showCalibrationsLink ||
            this.formData.showFavoritesLink !== this.existingNavbarSettings?.showFavoritesLink ||
            this.formData.showLimitedLifeLink !== this.existingNavbarSettings?.showLimitedLifeLink ||
            this.formData.showMyApprovals !== this.existingNavbarSettings?.showMyApprovals ||
            this.formData.showNewBrowserWindowIcon !== this.existingNavbarSettings?.showNewBrowserWindowIcon ||
            this.formData.showUserSupport !== this.existingNavbarSettings?.showUserSupport);
    }
    get userId() {
        return this.currentUser?.id;
    }
    get shouldShowLimitedLifeLink() {
        return COSMIC_APPLICATION !== EsocApplication.CDOCS;
    }
    get shouldShowCalibrationsLink() {
        return COSMIC_APPLICATION !== EsocApplication.CDOCS;
    }
    created() {
        this.initForm(this.currentUser);
    }
    highlightNavbarItem(itemRefName) {
        this.$message('navbar_item_highlighted', itemRefName);
    }
    initForm(user) {
        if (user) {
            Object.keys(this.formData).forEach((key) => {
                const navbarSettings = this.currentUserActiveSupportContext && user?.attributes?.navbar
                    ? user?.attributes?.navbar[this.currentUserActiveSupportContext]
                    : {};
                this.formData[key] =
                    !navbarSettings || navbarSettings === {} || isNullOrUndefined(navbarSettings[key])
                        ? true
                        : navbarSettings[key];
            });
        }
    }
    async onFormChange() {
        if (!this.isFormDirty) {
            return;
        }
        try {
            this.isSaving = true;
            const resp = await this.updateUserAttributes();
            const user = Object.assign(this.currentUser, { attributes: resp?.data.attributes });
            this.$message('user_updated', user);
            this.$notification.add({
                type: this.AlertType.SUCCESS,
                text: 'Navbar settings saved.'
            });
            this.isSaving = false;
        }
        catch (error) {
            this.$errorUtility({ err: error });
            this.$notification.add({
                text: 'An error happened during the save.',
                type: this.AlertType.ERROR
            });
            this.isSaving = false;
        }
    }
    async updateUserAttributes() {
        if (!this.currentUserActiveSupportContext) {
            return;
        }
        const navbar = Object.assign({}, this.currentUser?.attributes.navbar, {
            [this.currentUserActiveSupportContext]: this.formData
        });
        const payload = Object.assign({}, this.user?.attributes, { navbar });
        return this.$http.put(`/${EntityType.USER}/current/attributes`, payload);
    }
};
__decorate([
    Watch('formData', { deep: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], TabUserSettings.prototype, "onFormChange", null);
TabUserSettings = __decorate([
    Component({
        components: {
            UserFavorites
        }
    })
], TabUserSettings);
export default TabUserSettings;
