import Vue from 'vue';
import VueRouter from 'vue-router';
import { NonnominalSharedRoutes, OrganizationSharedRoutes, UserSharedRoutes } from '~/nasa_ui/router';
import { AdminSharedRoutes } from '~/nasa_ui/router/admin';
import { EntityType } from '~/nasa_ui/types';
import { RouterLogUtility } from '~/utils/RouterLogUtility';
Vue.use(VueRouter);
export const ReportingRoutes = [
    {
        path: `/reporting`,
        name: 'ReportingSearchView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/ReportingSearchView/ReportingSearchView.vue'),
        meta: {
            displayName: 'Reporting search',
            description: 'Listing of every available Risk report.',
            doNotList: true,
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/top_risks`,
        name: 'ReportingTopRisks',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/ReportingTopRisks/ReportingTopRisks.vue'),
        meta: {
            description: 'Listing of top open Risks.',
            displayName: 'Top Risks',
            entityRelationships: [EntityType.RISK],
            permissions: [],
            supportContexts: [],
            userRoles: [],
            wikiUrl: '/reporting'
        }
    },
    {
        path: `/reporting/archived_risks`,
        name: 'ReportingArchivedRisks',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/ReportingArchivedRisks/ReportingArchivedRisks.vue'),
        meta: {
            displayName: 'Archived Risks',
            description: 'Listing of all archived Risks.',
            entityRelationships: [EntityType.RISK],
            permissions: [],
            supportContexts: [],
            userRoles: [],
            wikiUrl: '/reporting'
        }
    }
];
const ALL_ROUTES = [
    {
        path: `/`,
        name: 'HomeView',
        component: () => import(/* webpackChunkName: "HomeView" */ '../views/HomeView/HomeView.vue'),
        meta: {
            displayName: 'Risk Home',
            description: 'COSMIC Risk home',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/risks`,
        name: 'Risks',
        component: () => import(/* webpackChunkName: "RisksView" */ '../views/RisksSearchView/RisksSearchView.vue'),
        meta: {
            displayName: 'Risks',
            description: 'Search all Risks in COSMIC Risks',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/risks/create`,
        name: 'RisksCreate',
        component: () => import(/* webpackChunkName: "RisksView" */ '../views/RisksCreateView/RisksCreateView.vue'),
        meta: {
            displayName: 'Risks creation',
            description: 'Create a Risk to be tracked',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/risks/search`,
        name: 'Risks',
        component: () => import(/* webpackChunkName: "RisksView" */ '../views/RisksSearchView/RisksSearchView.vue'),
        meta: {
            displayName: 'Risks search',
            description: 'Search all Risks in COSMIC Risks',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/orb_notes`,
        name: 'OrbNotesView',
        component: () => import(/* webpackChunkName: "RisksView" */ '../views/OrbNotesView/OrbNotesView.vue'),
        meta: {
            displayName: 'ORB notes',
            description: 'View/Update ORB notes',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/risks/manage/:id`,
        name: 'RiskManageView',
        component: () => import(/* webpackChunkName: "RisksView" */ '../views/RiskManageView/RiskManageView.vue'),
        meta: {
            displayName: 'Risk Manage',
            description: 'Manage a Risk',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    ...AdminSharedRoutes,
    ...ReportingRoutes,
    ...OrganizationSharedRoutes,
    ...UserSharedRoutes,
    ...NonnominalSharedRoutes // needs to be last
];
export default new RouterLogUtility(VueRouter, ALL_ROUTES);
