var _a, _b, _c;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';
import { ChangeSetStatus, DocumentType, StepOverviewFragment } from '~/db_types';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { DESCRIPTION_COL, FILE_NAME_COL, FILE_TYPE_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import { getAS2DownloadUrl } from '~/nasa_ui/DAL/attachments';
import { DocumentLineItemsRepoMixin } from '~/nasa_ui/DAL/document/LineItems';
import { WorkOrderStepRepoMixin } from '~/nasa_ui/DAL/document/WorkOrderStep';
import CurrentUserMixin from '~/nasa_ui/mixins/CurrentUserMixin';
import GenericDocumentMixin from '~/nasa_ui/mixins/GenericDocumentMixin';
import ReadOnlyMixin from '~/nasa_ui/mixins/ReadOnlyMixin';
import StepMixin from '~/nasa_ui/mixins/StepMixin';
import { EntityType } from '~/nasa_ui/types/enums';
import { isBeforeInclusive } from '~/nasa_ui/utils/dates/isBeforeInclusive';
import { composeTableHeader } from '~/nasa_ui/utils/helpers/composeTableHeader';
import { BaseApprovalTableHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
import { transformAttachments, transformChangeSetAuthorities, transformWorkOrderSteps } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let WorkOrderStepCard = class WorkOrderStepCard extends Mixins(ReadOnlyMixin, DocumentLineItemsRepoMixin, WorkOrderStepRepoMixin, CurrentUserMixin, StepMixin, GenericDocumentMixin) {
    DocumentType = DocumentType;
    EntityType = EntityType;
    isCardMaximized = false;
    isDuplication = false;
    showApprovalsModal = false;
    showAttachmentsModal = false;
    showDeleteConfirmation = false;
    showHardwareModal = false;
    showReopenConfirmation = false;
    showStepApprovalModal = false;
    showStepRejectionModal = false;
    showToolsModal = false;
    showWorkOrderStepModal = false;
    approvalTableHeaders = [...BaseApprovalTableHeaders];
    attachmentTableHeaders = [DESCRIPTION_COL, FILE_NAME_COL, composeTableHeader(FILE_TYPE_COL, { align: 'center' })];
    isBeforeTodayInclusive = (date) => {
        return isBeforeInclusive(new Date(date), new Date());
    };
    step;
    docId;
    documentType;
    hideGoToStep;
    isInitialized;
    pendingInitialization;
    get approvalTableItems() {
        return transformChangeSetAuthorities(this.step?.changeSet?.changeSetAuthorities?.nodes || []);
    }
    get attachmentTableItems() {
        const items = (this.step?.attachments?.nodes || []);
        return items ? transformAttachments(items) : [];
    }
    get approvalsCanBeReset() {
        const adminGroup = this.getCorrespondingAdminGroup(this.documentType);
        const isAdmin = this.currentUserHasPermission(adminGroup);
        // the computed value for `isReopenable` only tells us if we can commit the reopen mutation without error
        // but we want to hide the button if there aren't any approvals otherwise you'd click it
        // and it wouldn't go away
        const hasAnyApprovals = this.step.changeSet?.changeSetAuthorities.nodes.some((a) => a?.status !== ChangeSetStatus.OPEN);
        return this.step.isReopenable && hasAnyApprovals && isAdmin;
    }
    get dueDateDisplay() {
        return this.$dateDisplay(this.step.projectedDate);
    }
    get canApprove() {
        const nextAuthority = this.step.changeSet?.status?.nextAuthority?.groupCode;
        return this.isCurrentUserInGroup(nextAuthority);
    }
    get canReject() {
        const nextAuthority = this.step.changeSet?.status?.nextAuthority?.groupCode;
        const userInNextAuthority = this.isCurrentUserInGroup(nextAuthority);
        return userInNextAuthority && this.supportsStepRejection(this.documentType);
    }
    get countApprovals() {
        return this.step?.changeSet?.changeSetAuthorities?.totalCount ?? 0;
    }
    get countHardwareNonTools() {
        return this.step?.hardwareList?.hardwareListAssemblies?.totalCount ?? 0;
    }
    get countTools() {
        return this.step?.toolsList?.hardwareListAssemblies.totalCount ?? 0;
    }
    get countAttachments() {
        return this.step?.attachments?.totalCount ?? 0;
    }
    get hideHardwareAndTools() {
        return this.docTypesUsingTasks.includes(this.documentType);
    }
    get isStepApproved() {
        return this.step.changeSet?.computedStatus === ChangeSetStatus.APPROVED;
    }
    get isStepRejected() {
        return this.step?.changeSet?.computedStatus === ChangeSetStatus.REJECTED;
    }
    get shouldHideMip() {
        return this.docTypesUsingTasks.includes(this.documentType);
    }
    get shouldShowDueDate() {
        return Boolean(this.step.projectedDate && !this.isStepApproved && !this.isStepRejected);
    }
    get stepDisplayText() {
        let text = '';
        switch (this.documentType) {
            case DocumentType.C_WORKFLOW:
                text = 'Task';
                break;
            case DocumentType.C_RCA:
                text = 'CAP task';
                break;
            default:
                text = 'Step';
                break;
        }
        return text;
    }
    get transformedWorkOrderStep() {
        return this.step ? transformWorkOrderSteps([this.step])[0] : null;
    }
    created() {
        this.isCardMaximized = !this.isStepApproved && !this.isStepRejected;
    }
    onStepSave(workOrderStepId) {
        this.showWorkOrderStepModal = false;
        this.$message('workorder-steps-updated');
    }
    async reopenStep() {
        const authoritiesOverride = this.step.changeSet?.changeSetAuthorities.nodes.map((authority) => {
            return {
                groupCode: authority.groupCode,
                requiresQualityControlStamp: authority.requiresQualityControlStamp,
                requiresComment: authority.requiresComment
            };
        });
        await this.reopenChangeSetsForDocuments({
            changeSetIds: [this.step.changeSet.id],
            authoritiesOverride
        }, { success: `${this.stepDisplayText} approvals were reset.` });
        this.emitStepUpdated();
        this.$message('workorder-steps-updated');
        this.$message('reload_user_alerts');
    }
    emitProcedureUpdated(updates) {
        return { ...this.step, ...updates };
    }
    emitStepDeleted() {
        return this.step;
    }
    emitStepUpdated() {
        return this.step;
    }
    async getRelatedHardware(id, type) {
        const query = gql `
      query GetRelatedHardwareOnStepOverview($hardwareListId: UUID!) {
        hardwareListById(id: $hardwareListId) {
          id
          nodeId
          hardwareListAssemblies {
            nodes {
              id
              nodeId
              inventory {
                id
                nodeId
                itemDrawingDescription
              }
              externalDescription
              externalDrawingNumber
            }
          }
        }
      }
    `;
        const resp = await this.$apollo.query({ query, variables: { hardwareListId: id } });
        return (resp.data.hardwareListById?.hardwareListAssemblies.nodes.map((assy) => ({
            type,
            id: assy?.id,
            name: assy?.inventory?.itemDrawingDescription ||
                assy?.externalDescription ||
                assy?.externalDrawingNumber ||
                DEFAULT_DASH
        })) ?? []);
    }
    async getRelatedAttachments() {
        const query = gql `
      query GetRelatedAttachmentsOnStepOverview($id: Int!) {
        workOrderStepById(id: $id) {
          id
          nodeId
          attachments {
            nodes {
              id
              nodeId
              fileName
              description
              contentType
              key
            }
          }
        }
      }
    `;
        const res = await this.$apollo.query({ query, variables: { id: this.step.id } });
        return (res.data.workOrderStepById?.attachments.nodes.map((attachment) => ({
            id: attachment.id,
            name: attachment.description || '',
            link: getAS2DownloadUrl(attachment?.id || '', attachment?.fileName || ''),
            type: 'attachments'
        })) ?? []);
    }
    async deleteStep() {
        await this.deleteWorkOrderStepById({ id: this.step.id }, { success: 'Step deleted.' });
        this.$message('workorder-steps-updated');
        this.emitStepDeleted();
    }
    resetAndEmitStepUpdated() {
        this.reset();
        this.emitStepUpdated();
    }
    onClickOfGoToStep() {
        this.$router.push({
            query: { step: `${this.step.stepNumber}` },
            hash: this.hideHardwareAndTools ? 'tasks' : 'steps'
        });
    }
    onTitleDblClick() {
        // if step isnt approved || rejected then disallow collapsing
        if (!this.isStepApproved && !this.isStepRejected) {
            return;
        }
        this.isCardMaximized = !this.isCardMaximized;
    }
    onDueDateDblClick() {
        if (!this.isInitialized) {
            this.showWorkOrderStepModal = true;
        }
    }
    reset() {
        this.showApprovalsModal = false;
        this.showAttachmentsModal = false;
        this.showDeleteConfirmation = false;
        this.showHardwareModal = false;
        this.showReopenConfirmation = false;
        this.showStepApprovalModal = false;
        this.showStepRejectionModal = false;
        this.showToolsModal = false;
        this.showWorkOrderStepModal = false;
    }
};
__decorate([
    Prop({
        required: true,
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof StepOverviewFragment !== "undefined" && StepOverviewFragment) === "function" ? _a : Object)
], WorkOrderStepCard.prototype, "step", void 0);
__decorate([
    Prop({
        required: true,
        type: String
    }),
    __metadata("design:type", Object)
], WorkOrderStepCard.prototype, "docId", void 0);
__decorate([
    Prop({
        required: false,
        type: String
    }),
    __metadata("design:type", typeof (_b = typeof DocumentType !== "undefined" && DocumentType) === "function" ? _b : Object)
], WorkOrderStepCard.prototype, "documentType", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], WorkOrderStepCard.prototype, "hideGoToStep", void 0);
__decorate([
    Prop({
        type: Boolean,
        required: false
    }),
    __metadata("design:type", Boolean)
], WorkOrderStepCard.prototype, "isInitialized", void 0);
__decorate([
    Prop({
        type: Boolean,
        required: false
    }),
    __metadata("design:type", Boolean)
], WorkOrderStepCard.prototype, "pendingInitialization", void 0);
__decorate([
    Emit('procedure-updated'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_c = typeof Partial !== "undefined" && Partial) === "function" ? _c : Object]),
    __metadata("design:returntype", void 0)
], WorkOrderStepCard.prototype, "emitProcedureUpdated", null);
__decorate([
    Emit('step-deleted'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], WorkOrderStepCard.prototype, "emitStepDeleted", null);
__decorate([
    Emit('step-updated'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], WorkOrderStepCard.prototype, "emitStepUpdated", null);
WorkOrderStepCard = __decorate([
    Component
], WorkOrderStepCard);
export default WorkOrderStepCard;
