var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "container"
  }, [_vm.title ? _c('AppHeader', {
    attrs: {
      "show-horizontal-rule": false,
      "uppercase": false,
      "size": "manage"
    }
  }, [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _vm._t("mini"), _vm._t("content"), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_c('AppLoader', {
    key: "entity-loader",
    attrs: {
      "color": _vm.iconColor,
      "show": _vm.fetching,
      "type": "linear",
      "center": ""
    }
  })], 1), _vm.entityNotFound && !_vm.fetching ? _c('Alert', {
    attrs: {
      "type": _vm.AlertType.ERROR
    }
  }, [_c('p', [_vm._v("There has been an error fetching data for " + _vm._s(_vm.title ? `the ${_vm.title}` : 'this Manage') + " page.")]), _c('p', [_vm._v(" This could be a problem accessing something in a context you do not have access to or an issue with the ID itself. ")])]) : _vm._e()], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };