var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(_vm.isButton ? 'button' : 'div', {
    ref: "dropzone",
    tag: "component",
    staticClass: "dropzone",
    class: {
      active: _vm.dragOver
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.emitDropzoneClicked,
      "dragleave": _vm.onDragLeave,
      "dragover": function ($event) {
        $event.preventDefault();
        return _vm.onDragOver.apply(null, arguments);
      },
      "drop": function ($event) {
        $event.preventDefault();
        return _vm.onDrop.apply(null, arguments);
      }
    }
  }, [_vm._t("dropzone-inner", function () {
    return [_c('div', {
      staticClass: "dropzone-inner"
    }, [_vm._t("default", function () {
      return [_c('c-icon-fa', {
        attrs: {
          "icon": "fal fa-file-upload"
        }
      }), _c('p', {
        staticClass: "text-xs-center grey--text text--darken-2"
      }, [_vm._v(_vm._s(_vm.label))])];
    })], 2)];
  })], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };