import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let AppMiniContainer = class AppMiniContainer extends Vue {
    maxHeight;
    title;
    get computedTitle() {
        return !this.hasTitleSlot ? this.title : '';
    }
    get hasTitleSlot() {
        return this.hasSlot('title');
    }
    hasSlot(name) {
        return !!this.$slots[name] || !!this.$scopedSlots[name];
    }
};
__decorate([
    Prop({
        type: String,
        default: '275px'
    }),
    __metadata("design:type", String)
], AppMiniContainer.prototype, "maxHeight", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], AppMiniContainer.prototype, "title", void 0);
AppMiniContainer = __decorate([
    Component
], AppMiniContainer);
export default AppMiniContainer;
