import { __decorate, __metadata } from "tslib";
import { Component, Emit } from 'vue-property-decorator';
import BaseSearch from '~/nasa_ui/base/BaseSearch';
import { CREATED_BY_COL, CREATED_DATE_COL, DESCRIPTION_COL, NAME_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import { SearchApprovalRoutingTemplates } from '~/nasa_ui/DAL/approvalRoutingTemplate';
import { EntityType } from '~/nasa_ui/types';
/**
 * AVAILABLE PROPS:
 *  autofocus - false - should focus onto the search
 *  autosearch - false - should this fire the search query right away?
 *  filters - object containing prefilters you want applied to the search
 *  keep-search-results-open - false - after selection keep the results open
 *  existing-values - nodeId[] - this is for filtering out any already selected entities (not for normal use)
 *  locked-filters - string[] - values are keys of filters that you want to not be changed
 */
let SearchApprovalRoutingTemplate = class SearchApprovalRoutingTemplate extends BaseSearch {
    placeholder = 'search for approval routing templates';
    hint = 'name';
    entityType = EntityType.APPROVAL_ROUTING_TEMPLATE;
    SEARCH_QUERY = SearchApprovalRoutingTemplates;
    get chipsFromFilters() {
        return [];
    }
    get computedTableHeaders() {
        return [NAME_COL, DESCRIPTION_COL, CREATED_DATE_COL, CREATED_BY_COL];
    }
    get entityTypeDisplay() {
        return this.$typeDisplay(EntityType.APPROVAL_ROUTING_TEMPLATE);
    }
    onClickOfSearchResult(selectedItem) {
        this.reset();
        return this.isOutputModeString ? selectedItem.id : selectedItem;
    }
};
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], SearchApprovalRoutingTemplate.prototype, "onClickOfSearchResult", null);
SearchApprovalRoutingTemplate = __decorate([
    Component
], SearchApprovalRoutingTemplate);
export default SearchApprovalRoutingTemplate;
