var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "cosmic-user-select"
  }, [_c('div', [_c('AppSelect', _vm._b({
    attrs: {
      "autocomplete": "",
      "clearable": "",
      "hide-no-data": !_vm.searchTerm,
      "color": _vm.EntityType.USER,
      "chips": false,
      "error-messages": _vm.errorMessages,
      "select-options": _vm.userOptions,
      "label": _vm.label,
      "loading": _vm.isLoading,
      "value": _vm.value,
      "outline": "",
      "placeholder": "Search Users"
    },
    on: {
      "update:search-input": function ($event) {
        _vm.searchTerm = $event;
      },
      "input": _vm.onSelectedUser
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (item) {
        return [_c('span', {
          staticClass: "px-2"
        }, [_vm._v(_vm._s(item.displayText))]), _vm._v(" ("), _c('small', [_vm._v(_vm._s(item.emailDisplay))]), _vm._v(") ")];
      }
    }, {
      key: "selection",
      fn: function (item) {
        return [_c('span', {
          staticClass: "px-2"
        }, [_vm._v(_vm._s(item.displayText))]), _vm._v(" ("), _c('small', [_vm._v(_vm._s(item.emailDisplay))]), _vm._v(") ")];
      }
    }, {
      key: "no-data",
      fn: function () {
        return [_c('div', [_vm.searchHasMinimumCharacters ? _c('div', {
          staticClass: "pa-3"
        }, [_vm.isLoading ? _c('span', [_vm._v("Loading...")]) : _vm.showNoUsersFound ? _c('span', [_vm._v("No Users Found")]) : _vm._e()]) : _vm._e()])];
      },
      proxy: true
    }, {
      key: "after-input",
      fn: function () {
        return [_c('div', {
          staticClass: "after_input"
        }, [_c('v-fade-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [_vm.shouldShowAddYourselfIcon ? _c('ButtonMiniAction', {
          attrs: {
            "icon-size": "24px",
            "disabled": _vm.$attrs.disabled,
            "icon": _vm.$icons[_vm.EntityType.USER],
            "icon-color": "var(--v-users-base)",
            "tooltip": "Add yourself",
            "tooltip-position": "left"
          },
          on: {
            "click": _vm.onClickOfYourself
          }
        }) : _vm._e()], 1)], 1)];
      },
      proxy: true
    }])
  }, 'AppSelect', {
    ..._vm.$attrs,
    ..._vm.$props
  }, false)), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.computedSelectedUser ? _c('v-tooltip', {
    attrs: {
      "content-class": "user-card",
      "color": _vm.EntityType.USER,
      "bottom": "",
      "value": _vm.showUserInfo
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({}) {
        return [_c('div', {
          staticClass: "button_wrap user-info"
        }, [_c('ButtonMiniAction', {
          attrs: {
            "icon-size": "14",
            "tooltip": "View User information",
            "tooltip-position": "left",
            "icon": "fad fa-address-card",
            "icon-color": "var(--v-users-base)"
          },
          on: {
            "click": function ($event) {
              _vm.showUserInfo = !_vm.showUserInfo;
            }
          }
        })], 1)];
      }
    }], null, false, 1510504322)
  }, [_c('v-layout', {
    staticClass: "px-2 pt-2 pb-0",
    attrs: {
      "row": "",
      "wrap": "",
      "align-center": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": "",
      "pr-3": ""
    }
  }, [_c('AppContactDisplay', {
    attrs: {
      "contact": _vm.computedSelectedUser
    }
  })], 1)], 1), _c('ButtonCancel', {
    attrs: {
      "small": ""
    },
    on: {
      "click": function ($event) {
        _vm.showUserInfo = false;
      }
    }
  }, [_vm._v("Close")])], 1) : _vm._e()], 1)], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };