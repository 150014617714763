import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { COSMIC_APPLICATION, COSMIC_ENVIRONMENT, COSMIC_SYSTEM } from '~/application.config';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import pkg from '../../../../package.json';
let TabAdminApplicationInformation = class TabAdminApplicationInformation extends Vue {
    appVersion = pkg.version;
    COSMIC_SYSTEM = COSMIC_SYSTEM;
    COSMIC_APPLICATION = COSMIC_APPLICATION;
    COSMIC_ENVIRONMENT = COSMIC_ENVIRONMENT;
    isApiHealthy = false;
    apiStatus = null;
    created() {
        this.fetchCosmicApiInfo();
    }
    get apiVersion() {
        return this.apiStatus?.results.CurrentVersion.description || DEFAULT_DASH;
    }
    async fetchCosmicApiInfo() {
        try {
            const resp = await this.$http.get(`/healthy`);
            this.apiStatus = resp.data;
            this.isApiHealthy = true;
        }
        catch (err) {
            this.isApiHealthy = false;
        }
    }
};
TabAdminApplicationInformation = __decorate([
    Component
], TabAdminApplicationInformation);
export default TabAdminApplicationInformation;
