var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('v-card', {
    attrs: {
      "color": "error",
      "dark": ""
    }
  }, [_c('v-card-text', [_c('AppHeader', {
    staticClass: "mb-2",
    attrs: {
      "uppercase": false,
      "show-horizontal-rule": false
    }
  }, [_vm._v("Errors")]), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs1": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Total",
      "bold": ""
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.$numberDisplay(_vm.mappedErrorLogs.length)))])])], 1), _c('v-flex', {
    attrs: {
      "xs1": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Last day",
      "bold": ""
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.$numberDisplay(_vm.errorLogCounts.day)))])])], 1), _c('v-flex', {
    attrs: {
      "xs1": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Last week",
      "bold": ""
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.$numberDisplay(_vm.errorLogCounts.week)))])])], 1), _c('v-flex', {
    attrs: {
      "xs1": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Last month",
      "bold": ""
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.$numberDisplay(_vm.errorLogCounts.month)))])])], 1)], 1)], 1)], 1), _c('AppTableExpandable', {
    key: "errorLogsTable",
    attrs: {
      "headers": _vm.computedErrorLogHeaders,
      "items": _vm.mappedErrorLogs,
      "loading": _vm.isLoading,
      "rows-per-page-items": _vm.rowsPerPageItems,
      "user-defined-pagination": _vm.paginationSettings,
      "color": "error",
      "show-search-input": ""
    },
    on: {
      "expandableRowClick": _vm.onChildRowClick
    },
    scopedSlots: _vm._u([{
      key: "url",
      fn: function ({
        item
      }) {
        return [_c('dl', {
          staticClass: "from_to"
        }, [_c('dt', [_vm._v("From")]), _c('dd', {
          staticClass: "pl-2"
        }, [_c('a', {
          attrs: {
            "href": item.fromUrl
          }
        }, [_vm._v(_vm._s(item.fromUrl))])])])];
      }
    }, {
      key: "browser",
      fn: function ({
        item
      }) {
        return [item.attributes && item.attributes.browser ? _c('div', {
          staticClass: "no-wrap d-flex align-center"
        }, [_c('c-icon-fa', {
          staticClass: "mr-2",
          attrs: {
            "icon": _vm.browserToIcon(item.attributes.browser.type)
          }
        }), _vm._v(" v"), _c('span', {
          staticClass: "font-weight-bold monospace_font fa-lg"
        }, [_vm._v(_vm._s(item.attributes.browser.version))])], 1) : _vm._e()];
      }
    }]),
    model: {
      value: _vm.selectedErrorLog,
      callback: function ($$v) {
        _vm.selectedErrorLog = $$v;
      },
      expression: "selectedErrorLog"
    }
  }, [_c('template', {
    slot: "expanded-row"
  }, [_c('div', {
    staticStyle: {
      "max-width": "1500px"
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs8": ""
    }
  }, [_c('v-card', {
    staticClass: "pa-2",
    attrs: {
      "dark": ""
    }
  }, [_c('vue-json-pretty', {
    attrs: {
      "path": 'res',
      "data": _vm.selectedErrorLog
    }
  })], 1)], 1), _c('v-flex', {
    attrs: {
      "xs8": ""
    }
  }, [_vm.selectedErrorLog && _vm.selectedErrorLog.createdByUserId ? _c('MiniUser', {
    attrs: {
      "id": _vm.selectedErrorLog.createdByUserId
    }
  }) : _vm._e()], 1)], 1)], 1)])], 2)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };