var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [!_vm.useIcon ? _c('ButtonGeneric', {
    attrs: {
      "icon": _vm.$icons.mail,
      "disabled": _vm.$props.disabled
    },
    on: {
      "click": _vm.onClickOfEmailPopupActivator
    }
  }, [[_vm._v("Email")]], 2) : _c('ButtonMiniAction', {
    attrs: {
      "disabled": _vm.disabled,
      "icon": _vm.$icons.mail,
      "tooltip": "Send Email",
      "tooltip-position": "left",
      "icon-color": "var(--v-primary-base)"
    },
    on: {
      "click": _vm.onClickOfEmailPopupActivator
    }
  }), _vm.showModal ? _c('AppModal', {
    attrs: {
      "color": "primary",
      "hide-title-section": ""
    },
    on: {
      "modal-close": _vm.onModalClose
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('ButtonGeneric', {
          attrs: {
            "disabled": !_vm.isEmailFormValid,
            "icon": _vm.$icons.mail
          },
          on: {
            "click": _vm.sendEmail
          }
        }, [[_vm._v("Email " + _vm._s(_vm.uniqueEmailList.length) + " Contacts")]], 2), !_vm.isSaving ? _c('ButtonCancel', {
          on: {
            "click": _vm.onModalClose
          }
        }) : _vm._e()];
      },
      proxy: true
    }], null, false, 1342329443)
  }, [_c('template', {
    slot: "content"
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs8": ""
    }
  }, [_c('AppPageDescription', [_vm._v("Select email recipients")])], 1), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs4": ""
    }
  }, [_c('AppPageDescription', [_vm._v(" Unique list of email addresses ("), _c('small', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.formData.recipients.length))]), _vm._v(") ")])], 1)], 1), _c('v-flex', {
    attrs: {
      "xs8": ""
    }
  }, [_c('AppTableSelectable', {
    attrs: {
      "color": _vm.EntityType.CONTACT,
      "headers": _vm.recipientsTableHeaders,
      "items": _vm.documentStakeholdersAll,
      "item-key": "_contact"
    },
    model: {
      value: _vm.formData.recipients,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "recipients", $$v);
      },
      expression: "formData.recipients"
    }
  }), _c('div', {
    staticClass: "d-flex"
  }, [_c('ButtonCreate', {
    staticClass: "mr-0",
    on: {
      "click": function ($event) {
        _vm.showCosmicUsersControl = !_vm.showCosmicUsersControl;
      }
    }
  }, [_vm._v("Add other Users to recipient list")])], 1)], 1), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_c('v-flex', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.uniqueEmailList.length > 0,
      expression: "uniqueEmailList.length > 0"
    }],
    staticClass: "pl-5",
    attrs: {
      "xs4": ""
    }
  }, [_c('ul', {
    staticClass: "list-style-none pa-0 list_o_emails"
  }, _vm._l(_vm.uniqueEmailList, function (emailAddy, index) {
    return _c('li', {
      key: index,
      staticClass: "py-2"
    }, [_c('a', {
      staticClass: "font-weight-bold user-email monospace_font",
      attrs: {
        "href": `mailto:${emailAddy}`
      }
    }, [_vm._v(_vm._s(emailAddy))])]);
  }), 0)])], 1), _c('v-flex', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showCosmicUsersControl,
      expression: "showCosmicUsersControl"
    }],
    attrs: {
      "xs12": ""
    }
  }, [_c('AppCosmicUsers', {
    attrs: {
      "display-type": "FORM_WRAPPER",
      "read-only": false
    },
    model: {
      value: _vm.formData.additionalUsers,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "additionalUsers", $$v);
      },
      expression: "formData.additionalUsers"
    }
  }, [_c('template', {
    slot: "no_items"
  }, [_c('Alert', {
    attrs: {
      "type": "info"
    }
  }, [_c('p', [_vm._v("No additional Users selected at this time.")])])], 1)], 2)], 1)], 1), _c('v-layout', {
    staticClass: "mt-2 ml-2",
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppPageDescription', [_vm._v(" By default, " + _vm._s(_vm.currentSystemName) + " will send a base level of informative data."), _c('br'), _vm._v(" Below lists the optional data you can select. ")])], 1), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppCheckbox', {
    attrs: {
      "disabled": !_vm.comments.length,
      "color": _vm.EntityType.DOCUMENT,
      "label": _vm.commentToggleLabel
    },
    model: {
      value: _vm.formData.sendComments,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "sendComments", $$v);
      },
      expression: "formData.sendComments"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppCheckbox', {
    attrs: {
      "color": _vm.EntityType.DOCUMENT,
      "label": "Task / Step information?"
    },
    model: {
      value: _vm.formData.sendStepInfo,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "sendStepInfo", $$v);
      },
      expression: "formData.sendStepInfo"
    }
  })], 1)], 1), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppTextarea', {
    attrs: {
      "label": "Provide your own custom message"
    },
    model: {
      value: _vm.formData.extraMessage,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "extraMessage", $$v);
      },
      expression: "formData.extraMessage"
    }
  })], 1)], 1)], 1)], 2) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };