var _a;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import uniqBy from 'lodash/uniqBy';
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
import BaseVue from '~/nasa_ui/base/BaseVue';
import { BASE_TEXT_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import { GetUsersById } from '~/nasa_ui/DAL/user';
import CurrentUserMixin from '~/nasa_ui/mixins/CurrentUserMixin';
import ReadOnlyMixin from '~/nasa_ui/mixins/ReadOnlyMixin';
import { sortObjectBy } from '~/nasa_ui/utils';
import { composeTableHeader } from '~/nasa_ui/utils/helpers/composeTableHeader';
import { transformUserResponses } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
import { AppCosmicWrapperOption } from './AppCosmicWrapperOption';
/**
 *
 * <template v-slot:results="{ items }">
 *   <ul>
 *     <li v-for="item in items" :key="item.id">
 *       {{ item.id }}
 *     </li>
 *   </ul>
 * </template>
 *
 */
let AppCosmicUsers = class AppCosmicUsers extends Mixins(ReadOnlyMixin, CurrentUserMixin, BaseVue) {
    snackbar = false;
    snackbarMessage = '';
    showUserSearch = false;
    showUserGroupSearch = false;
    tableHeaders = [
        composeTableHeader(BASE_TEXT_COL, {
            text: 'Full name',
            value: '_fullName',
            sortable: true
        })
    ];
    storage = {
        cache: {
            userGroups: [],
            users: []
        },
        toFetch: {
            userGroups: [],
            users: []
        }
    };
    formData = {
        userIds: [],
        userGroupCodes: []
    };
    displayType;
    showSnackbar;
    size;
    value;
    get computedFormWrapperSize() {
        return this.size;
    }
    get computedTableItems() {
        return transformUserResponses(this.uniqueSetOfUsersWithAdditionalAccess);
    }
    get existingUsers() {
        return this.uniqueSetOfUsersWithAdditionalAccess.map((user) => user.id);
    }
    get isDisplayTypeExpansionPanel() {
        return this.displayType === AppCosmicWrapperOption.EXPANSION_PANEL;
    }
    get isDisplayTypeFormWrapper() {
        return this.displayType === AppCosmicWrapperOption.FORM_WRAPPER;
    }
    get isDisplayTypeNecked() {
        return this.displayType === AppCosmicWrapperOption.NECKED;
    }
    get hasAppPageDescription_above_SearchUser__Slot() {
        return this.hasSlot('AppPageDescription_above_SearchUser');
    }
    get hasAppPageDescription_above_results__Slot() {
        return this.hasSlot('AppPageDescription_above_results');
    }
    get hasAppHeader__Slot() {
        return this.hasSlot('AppHeader');
    }
    get listContainsYourself() {
        return Boolean(this.storage.cache.users.find((user) => user.id === this.currentUser?.id));
    }
    get uniqueSetOfUsersWithAdditionalAccess() {
        return uniqBy(this.storage.cache.users, 'id').sort(sortObjectBy('_displayName'));
    }
    onClickOfCancelAdd() {
        this.showUserGroupSearch = false;
        this.showUserSearch = false;
    }
    onClickOfAddUser() {
        if (this.showUserSearch) {
            this.onClickOfCancelAdd();
            return;
        }
        this.showUserGroupSearch = false;
        this.showUserSearch = true;
    }
    onClickOfAddUserGroup() {
        if (this.showUserGroupSearch) {
            this.onClickOfCancelAdd();
            return;
        }
        this.showUserGroupSearch = true;
        this.showUserSearch = false;
    }
    onClickOfAddYourself() {
        if (this.isReadOnly || !this.currentUser) {
            return;
        }
        this.storage.cache.users.push(this.currentUser);
    }
    onClickOfCurrentUsersTableRow(userToRemove) {
        if (this.isReadOnly) {
            return;
        }
        this.snackbarMessage = 'User removed';
        this.snackbar = true;
        this.storage.cache.users = this.storage.cache.users.filter((cacheUser) => cacheUser.id !== userToRemove.id);
    }
    onClickOfClearAll() {
        this.formData.userIds = [];
        this.storage.cache.users = [];
        this.storage.cache.userGroups = [];
    }
    emitInput(value) {
        return value;
    }
    onUserIdsUpdate() {
        if (this.formData.userIds.length) {
            this.formData.userIds.forEach((userId) => {
                if (this.isUserInCacheById(userId)) {
                    return;
                }
                this.storage.toFetch.users.push(userId);
            });
        }
    }
    onUserGroupCodesUpdate() {
        if (this.formData.userGroupCodes) {
            this.formData.userGroupCodes.forEach((code) => {
                if (this.isUserGroupInCacheByCode(code)) {
                    const cachedUserGroup = this.storage.cache.userGroups.find((cug) => cug.code === code);
                    if (cachedUserGroup) {
                        const usersInThatGroup = cachedUserGroup.users?.map((user) => user) || [];
                        this.storage.cache.users = this.storage.cache.users.concat(usersInThatGroup);
                    }
                    return;
                }
                this.storage.toFetch.userGroups.push(code);
            });
        }
    }
    async onToFetchUserUpdate() {
        if (this.storage.toFetch.users) {
            try {
                const resp = await this.fetchUsersById(this.storage.toFetch.users);
                if (resp.data.users?.nodes) {
                    this.storage.cache.users = this.storage.cache.users.concat(transformUserResponses(resp.data.users?.nodes || []));
                }
            }
            catch (err) {
                this.$errorUtility({
                    err
                });
            }
        }
    }
    onUniqueSetOfUsersWithAdditionalAccess() {
        this.emitInput(this.uniqueSetOfUsersWithAdditionalAccess.map((user) => {
            return {
                userId: user.id,
                user
            };
        }));
    }
    onValueUpdate() {
        this.updateFormData(this.value);
    }
    isUserInCacheById(userId) {
        return Boolean(this.storage.cache.users.find((user) => user.id === userId));
    }
    isUserGroupInCacheByCode(userGroupCode) {
        return Boolean(this.storage.cache.userGroups.find((userGroup) => userGroup.code === userGroupCode));
    }
    updateFormData(values) {
        if (values) {
            this.formData.userIds = values.map((user) => user.userId) || [];
        }
    }
    updateWithUser(user) {
        this.formData.userIds.push(user.id);
        if (this.isUserInCacheById(user.id)) {
            return;
        }
        this.snackbarMessage = 'User added';
        this.snackbar = true;
        this.storage.cache.users.push(user);
    }
    updateWithUserGroup(userGroup) {
        this.formData.userGroupCodes.push(userGroup.code);
        if (this.isUserGroupInCacheByCode(userGroup.code)) {
            return;
        }
        this.snackbar = true;
        this.snackbarMessage = 'User group added';
        this.storage.cache.userGroups.push(userGroup);
    }
    fetchUsersById(userIds) {
        return this.$apollo.query({
            query: gql `
        ${GetUsersById}
      `,
            variables: {
                userIds
            }
        });
    }
};
__decorate([
    Prop({
        type: String,
        default: 'FORM_WRAPPER'
    }),
    __metadata("design:type", String)
], AppCosmicUsers.prototype, "displayType", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true
    }),
    __metadata("design:type", Boolean)
], AppCosmicUsers.prototype, "showSnackbar", void 0);
__decorate([
    Prop({
        type: String,
        default: '1'
    }),
    __metadata("design:type", String)
], AppCosmicUsers.prototype, "size", void 0);
__decorate([
    Prop({
        type: Array
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], AppCosmicUsers.prototype, "value", void 0);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], AppCosmicUsers.prototype, "emitInput", null);
__decorate([
    Watch('formData.userIds', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppCosmicUsers.prototype, "onUserIdsUpdate", null);
__decorate([
    Watch('formData.userGroupCodes'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppCosmicUsers.prototype, "onUserGroupCodesUpdate", null);
__decorate([
    Watch('storage.toFetch.users'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], AppCosmicUsers.prototype, "onToFetchUserUpdate", null);
__decorate([
    Watch('uniqueSetOfUsersWithAdditionalAccess'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppCosmicUsers.prototype, "onUniqueSetOfUsersWithAdditionalAccess", null);
__decorate([
    Watch('value', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppCosmicUsers.prototype, "onValueUpdate", null);
AppCosmicUsers = __decorate([
    Component
], AppCosmicUsers);
export default AppCosmicUsers;
