var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "container pa-1 mt-2"
  }, [_c('SearchInputContainer', {
    attrs: {
      "autofocus": _vm.autofocus,
      "entity-type": _vm.EntityType.USER_GROUP,
      "hint": _vm.hint,
      "is-loading": _vm.isLoading,
      "placeholder": _vm.placeholder
    },
    on: {
      "searchButtonClicked": _vm.onClickOfSearchButton,
      "enterPressed": _vm.onClickOfSearchButton
    },
    model: {
      value: _vm.searchInput,
      callback: function ($$v) {
        _vm.searchInput = $$v;
      },
      expression: "searchInput"
    }
  }), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_c('AppLoader', {
    staticClass: "ma-0",
    attrs: {
      "color": _vm.EntityType.USER_GROUP,
      "show": _vm.isLoading,
      "type": "linear"
    }
  })], 1), _c('v-slide-y-transition', {
    attrs: {
      "tag": "table",
      "hide-on-leave": ""
    }
  }, [_vm.shouldShowTable ? _c('AppTableExpandable', {
    key: "userGroupSearchResults",
    attrs: {
      "color": _vm.EntityType.USER_GROUP,
      "headers": _vm.computedTableHeaders,
      "items": _vm.searchResults,
      "show-count": false,
      "title": _vm.resultTableTitle,
      "disable-initial-sort": "",
      "item-key": "code",
      "dense-rows": "",
      "sticky": ""
    },
    on: {
      "rowClicked": _vm.onClickOfSearchResult
    },
    scopedSlots: _vm._u([{
      key: "_totalCount",
      fn: function ({
        item
      }) {
        return [_c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(_vm._s(item._totalCount))])];
      }
    }, {
      key: "expanded-row",
      fn: function () {
        var _vm$userGroupWithMemb;
        return [_c('v-fade-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [_vm.isLoading ? _c('AppLoader', {
          attrs: {
            "color": _vm.EntityType.USER,
            "type": "linear",
            "center": ""
          }
        }) : _vm._e()], 1), _c('v-fade-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [!_vm.isLoading ? _c('div', [_c('ButtonGeneric', {
          staticClass: "ml-0",
          attrs: {
            "color": "primary",
            "icon": _vm.$icons['square_check']
          },
          on: {
            "click": function ($event) {
              return _vm.onClickOfSearchResult(_vm.selectedUserGroup);
            }
          }
        }, [_vm._v(" Select this Group ")]), _c('MiniUserGroup', {
          staticClass: "mb-1",
          attrs: {
            "entity": _vm.userGroupWithMembers,
            "show-manage-link": false
          }
        }), _c('div', {
          staticClass: "pl-4 user_groups lighten-3"
        }, _vm._l((_vm$userGroupWithMemb = _vm.userGroupWithMembers) === null || _vm$userGroupWithMemb === void 0 ? void 0 : _vm$userGroupWithMemb.users, function (user) {
          return _c('MiniUser', {
            key: user.id,
            staticClass: "pb-1",
            attrs: {
              "entity": user,
              "show-manage-link": false
            }
          });
        }), 1)], 1) : _vm._e()])];
      },
      proxy: true
    }], null, false, 3230819564),
    model: {
      value: _vm.selectedUserGroup,
      callback: function ($$v) {
        _vm.selectedUserGroup = $$v;
      },
      expression: "selectedUserGroup"
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };