import { __decorate } from "tslib";
import gql from 'graphql-tag';
import { Component, Vue } from 'vue-property-decorator';
import colors from 'vuetify/es5/util/colors';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { sortObjectBy } from '~/nasa_ui/utils';
let StatsPerBrowser = class StatsPerBrowser extends Vue {
    isFetching = false;
    resp = null;
    selectedBrowser = null;
    showSettings = false;
    startDate = '';
    subType = `TELEMETRY`;
    formData = {
        subType: `TELEMETRY`,
        startDate: ''
    };
    subTypeOptions = [
        { displayText: `Telemetry`, value: `TELEMETRY` },
        { displayText: 'Errors', value: `ERROR` }
    ];
    get chartColors() {
        const colorList = Object.values(colors)
            .flatMap((color) => [color.base, color.accent1])
            .filter((_) => _ !== undefined);
        return this.subType === `TELEMETRY` ? colorList.slice(4) : colorList;
    }
    get chartData() {
        if (this.selectedBrowser) {
            return {
                labels: this.selectedBrowser.versions.map((item) => item.browser),
                datasets: [
                    {
                        label: 'Total',
                        data: this.selectedBrowser.versions.map((item) => item.totalCount),
                        backgroundColor: this.chartColors
                    }
                ]
            };
        }
        else {
            return {
                labels: this.perBrowserItems.map((item) => item.browser),
                datasets: [
                    {
                        label: 'Total',
                        data: this.perBrowserItems.map((item) => item.totalCount),
                        backgroundColor: this.chartColors
                    }
                ]
            };
        }
    }
    get chartOptions() {
        return {
            responsive: true,
            aspectRatio: 4,
            plugins: {
                legend: {
                    display: this.selectedBrowser ? false : true
                }
            }
        };
    }
    get groupedByBrowser() {
        return this.resp?.reduce((output, item) => {
            const browser = item?.browser?.split(DEFAULT_DASH)[0] || '';
            if (browser && !output.hasOwnProperty(browser)) {
                output[browser] = {
                    browser: item?.browser,
                    _totalCount: '0',
                    totalCount: 0,
                    versions: []
                };
            }
            output[browser].totalCount = output[browser].totalCount + item?.totalCount;
            output[browser]['versions'].push({
                ...item,
                _totalCount: item?.totalCount?.toLocaleString('en-US'),
                _version: item?.browser?.split('-v')[1] || '?'
            });
            return output;
        }, {});
    }
    get perBrowserHeaders() {
        return [
            {
                text: 'Browser',
                value: 'browser'
            },
            {
                text: 'Count',
                value: '_totalCount'
            }
        ];
    }
    get perBrowserItems() {
        return this.groupedByBrowser
            ? Object.keys(this.groupedByBrowser).map((browser) => {
                return {
                    browser,
                    totalCount: this.groupedByBrowser[browser].totalCount || 0,
                    _totalCount: (this.groupedByBrowser[browser].totalCount || 0).toLocaleString('en-US'),
                    versions: this.groupedByBrowser[browser].versions
                };
            })
            : [];
    }
    get subTypeDisplay() {
        return this.subType === `TELEMETRY` ? `Telemetry` : this.subType === `ERROR` ? `Errors` : `?`;
    }
    get queryVariables() {
        const variables = {
            subType: this.subType
        };
        if (this.startDate) {
            variables['dateRange'] = {
                start: {
                    value: this.startDate,
                    inclusive: true
                }
            };
        }
        return variables;
    }
    get versionItems() {
        return this.selectedBrowser ? this.selectedBrowser.versions?.sort(sortObjectBy('totalCount', true)) : [];
    }
    created() {
        this.fetchData();
    }
    async fetchData() {
        try {
            this.isFetching = true;
            const resp = await this.$apollo.query({
                query: gql `
          query PerBrowserStats($subType: String, $dateRange: DatetimeRangeInput) {
            metricReport(subType: $subType, dateRange: $dateRange, groupBrowser: true) {
              nodes {
                browser
                totalCount
              }
            }
          }
        `,
                variables: this.queryVariables,
                fetchPolicy: 'network-only'
            });
            this.resp = resp.data?.metricReport?.nodes || null;
            this.isFetching = false;
        }
        catch (err) {
            this.$errorUtility({
                err,
                backupErrorText: 'Could not fetch telemetry per browser.'
            });
            this.isFetching = false;
        }
    }
    updateResults() {
        this.subType = this.formData.subType;
        this.startDate = this.formData.startDate;
        this.showSettings = false;
        this.fetchData();
    }
};
StatsPerBrowser = __decorate([
    Component
], StatsPerBrowser);
export default StatsPerBrowser;
