var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "modal-width": "50vw",
      "persistent": "",
      "color": _vm.EntityType.COMMENT,
      "hide-title-section": ""
    },
    on: {
      "input": _vm.emitModalClose,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.emitModalClose.apply(null, arguments);
      },
      "modal-close": _vm.emitModalClose
    }
  }, [_c('template', {
    slot: "content"
  }, [_vm._t("content"), !_vm.isCommentImmutable ? _c('v-layout', {
    attrs: {
      "column": ""
    }
  }, [_vm.shouldShowCommentTypeSelect ? _c('v-flex', [_c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": "",
      "mb-3": ""
    }
  }, [_c('AppSelect', {
    attrs: {
      "required": "",
      "chips": false,
      "disabled": _vm.readOnly,
      "selectOptions": _vm.commentTypeOptions,
      "display": _vm.CommentTypeDisplay,
      "label": "Comment type"
    },
    model: {
      value: _vm.value.subType,
      callback: function ($$v) {
        _vm.$set(_vm.value, "subType", $$v);
      },
      expression: "value.subType"
    }
  })], 1)], 1)], 1) : _vm._e(), _c('v-flex', [_c('AppMarkdown', {
    attrs: {
      "required": "",
      "label": "Comment",
      "read-only": _vm.readOnly
    },
    model: {
      value: _vm.value.comment,
      callback: function ($$v) {
        _vm.$set(_vm.value, "comment", $$v);
      },
      expression: "value.comment"
    }
  })], 1)], 1) : _vm._e()], 2), _c('template', {
    slot: "actions"
  }, [_vm._t("action", function () {
    return [_vm._v("This is where action buttons go")];
  })], 2)], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };