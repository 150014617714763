var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.DOCUMENT,
      "hide-title-section": ""
    },
    on: {
      "modal-close": _vm.emitModalClose
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [!_vm.isFracasDr ? _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('AppBoolean', {
          attrs: {
            "hide-unknown": "",
            "label": "Is this Document a Source or a Reference Document?",
            "true-text": "Source",
            "false-text": "Reference"
          },
          model: {
            value: _vm.isSourceDocument,
            callback: function ($$v) {
              _vm.isSourceDocument = $$v;
            },
            expression: "isSourceDocument"
          }
        })], 1) : _vm._e(), _vm.isCosmicDocument ? _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_vm.relatedDocumentEntity ? _c('MiniDocument', {
          attrs: {
            "entity": _vm.relatedDocumentEntity,
            "title": "Related Document",
            "show-manage-link": false
          }
        }) : _vm._e()], 1) : _c('v-flex', {
          attrs: {
            "xs12": "",
            "md4": ""
          }
        }, [_c('c-text', {
          attrs: {
            "error-messages": _vm.duplicateDocumentNumbersError,
            "label": "Document number",
            "c-value": _vm.documentNumber
          },
          on: {
            "c-input": function ($event) {
              _vm.documentNumber = $event.detail;
            }
          }
        })], 1)], 1)];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonSave', {
          attrs: {
            "disabled": _vm.shouldDisableSaveButton,
            "loading": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfSave
          }
        }), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        })];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };