var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppFormWrapper', {
    attrs: {
      "color": "hardware_lists"
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_vm.showRefreshAlert ? _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('Alert', {
    attrs: {
      "type": _vm.AlertType.WARNING
    }
  }, [_c('p', [_vm._v("Please refresh the search query to see any Hardware Lists that were just created.")]), _c('p', [_c('ButtonGeneric', {
    attrs: {
      "color": "hardware_lists",
      "dark": "",
      "icon": _vm.$icons.reload
    },
    on: {
      "click": _vm.refreshSearchQuery
    }
  }, [_vm._v(" Refresh search query ")])], 1)])], 1) : _vm._e(), _c('v-flex', {
    class: {
      md8: _vm.showCreateButton,
      lg9: _vm.showCreateButton,
      md12: !_vm.showCreateButton
    }
  }, [_vm.shouldShowHLSearch ? _c('SearchHardwareList', _vm._b({
    ref: "SearchHardwareList",
    attrs: {
      "disable-search": _vm.disableSearch
    },
    on: {
      "input": _vm.emitInput,
      "results": _vm.emitResults,
      "searchButtonClicked": _vm.emitSearchButtonClicked,
      "termSearched": _vm.onTermSearched
    }
  }, 'SearchHardwareList', _vm.$props, false)) : _vm._e()], 1), _vm.showCreateButton ? _c('v-flex', {
    attrs: {
      "md3": "",
      "mt-4": ""
    }
  }, [_vm.shouldShowHLSearch ? _c('ButtonCreate', {
    attrs: {
      "href": _vm.createHardwareListUrl,
      "rel": "noopener",
      "target": "_blank"
    },
    on: {
      "click": function ($event) {
        _vm.showRefreshAlert = true;
      }
    }
  }, [_vm._v(" Create a new Hardware List ")]) : _vm._e()], 1) : _vm._e()], 1), _vm.hasSelectedHardwareLists ? _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_vm._l(_vm.selectedHardwareListsIds, function (id, index) {
    return _c('v-flex', {
      key: index,
      staticClass: "mb-2",
      attrs: {
        "xs12": ""
      }
    }, [_c('v-fade-transition', {
      attrs: {
        "hide-on-leave": ""
      }
    }, [_c('MiniHardwareList', {
      attrs: {
        "id": id,
        "inline-clear": ""
      },
      on: {
        "inlineClear": _vm.removeSelectedHardwareList
      }
    })], 1)], 1);
  }), _c('v-flex', [_c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.shouldShowClearSelectionButton ? _c('ButtonClearSelection', {
    on: {
      "click": _vm.clearSelectedId
    }
  }) : _vm._e()], 1)], 1)], 2) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };