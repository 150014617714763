var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_vm.isFetching && !_vm.selectedEntity ? _c('AppLoader', {
    attrs: {
      "type": "linear"
    }
  }) : _vm._e(), _vm.selectedEntity ? _c('v-card', {
    staticClass: "pa-2 border__item_instances"
  }, [_c('Alert', {
    staticClass: "shake",
    class: {
      on: _vm.isDuplicate,
      off: !_vm.isDuplicate
    },
    attrs: {
      "type": _vm.duplicateAlertType
    }
  }, [_vm._v(" Hardware List must be unique by "), _c('strong', [_vm._v("Number")]), _vm._v(" and "), _c('strong', [_vm._v("Type")]), _vm._v(". ")]), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs4": "",
      "pr-2": ""
    }
  }, [_c('c-text', {
    attrs: {
      "label": "List name",
      "c-value": _vm.formData.name
    },
    on: {
      "c-input": function ($event) {
        _vm.formData.name = $event.detail;
      }
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs2": "",
      "pr-2": ""
    }
  }, [_c('c-select', {
    attrs: {
      "chips": false,
      "label": "List type",
      "required": ""
    },
    domProps: {
      "display": _vm.HardwareListTypeDisplay,
      "enum": _vm.HardwareListType,
      "cValue": _vm.formData.subType
    },
    on: {
      "c-input": function ($event) {
        _vm.formData.subType = $event.detail;
      }
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs3": ""
    }
  }, [_c('c-text', {
    attrs: {
      "label": "List number",
      "c-value": _vm.formData.number,
      "required": ""
    },
    on: {
      "c-input": function ($event) {
        _vm.formData.number = $event.detail;
      }
    }
  })], 1)], 1), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs2": "",
      "mt-4": "",
      "pr-2": ""
    }
  }, [_c('c-text', {
    attrs: {
      "label": "Revision",
      "c-value": _vm.formData.revision
    },
    on: {
      "c-input": function ($event) {
        _vm.formData.revision = $event.detail;
      }
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs3": "",
      "mt-4": "",
      "pr-2": ""
    }
  }, [_c('AppNumber', {
    attrs: {
      "label": "Addendum number"
    },
    model: {
      value: _vm.formData.addendumNumber,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "addendumNumber", $$v);
      },
      expression: "formData.addendumNumber"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs3": "",
      "mr-5": ""
    }
  }, [_c('AppDate', {
    attrs: {
      "label": "Addendum"
    },
    model: {
      value: _vm.formData.addendumDate,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "addendumDate", $$v);
      },
      expression: "formData.addendumDate"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs5": ""
    }
  }, [_c('c-text', {
    attrs: {
      "label": "Authorizer",
      "c-value": _vm.formData.authorizer
    },
    on: {
      "c-input": function ($event) {
        _vm.formData.authorizer = $event.detail;
      }
    }
  })], 1)], 1)], 1), !_vm.isReadOnly ? _c('v-flex', {
    staticClass: "mt-2",
    attrs: {
      "xs6": ""
    }
  }, [_c('ButtonSave', {
    attrs: {
      "disabled": !_vm.isFormValid,
      "is-saving": _vm.isSaving
    },
    on: {
      "click": _vm.onClickOfSaveHardwareList
    }
  }), _vm.selectedEntity && _vm.childrenToTheSelectedHardwareList.length ? _c('ButtonCreate', {
    staticClass: "ml-3",
    attrs: {
      "to": `/hardware/list_templates/create?hardwareListId=${_vm.selectedEntity.id}`
    }
  }, [_vm._v(" Create a Template from this List ")]) : _vm._e()], 1) : _vm._e(), !_vm.isReadOnly && !_vm.isCosmicDocumentHardwareList ? _c('v-flex', {
    staticClass: "mt-2",
    attrs: {
      "xs6": "",
      "text-xs-right": ""
    }
  }, [_c('DeleteHardwareList', {
    attrs: {
      "id": _vm.id
    },
    on: {
      "deletionComplete": _vm.onDeleted
    }
  })], 1) : _vm._e()], 1)], 1) : _vm._e(), _vm.selectedEntity ? _c('v-card', {
    staticClass: "pa-2 mt-3 border__item_instances"
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_vm.hardwareListHasTemplate ? _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "no-wrap": "",
      "align-center": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs2": ""
    }
  }, [_c('v-switch', {
    staticClass: "mt-0 pt-0",
    attrs: {
      "color": _vm.EntityType.HARDWARE_LIST_TEMPLATE,
      "label": "Show template"
    },
    model: {
      value: _vm.showHardwareListTemplateWithList,
      callback: function ($$v) {
        _vm.showHardwareListTemplateWithList = $$v;
      },
      expression: "showHardwareListTemplateWithList"
    }
  })], 1)], 1)], 1) : _vm._e(), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppHardwareListAssemblies', {
    attrs: {
      "read-only": _vm.isReadOnly,
      "hardware-list-id": _vm.id,
      "show-additional-metadata": ""
    }
  })], 1)], 1)], 1) : _vm._e(), _vm.selectedEntity ? _c('v-card', {
    staticClass: "pa-2 mt-3 border__events"
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [!_vm.isReadOnly ? _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('ButtonGeneric', {
    attrs: {
      "color": _vm.EntityType.EVENT,
      "icon": _vm.$icons[_vm.EntityType.EVENT]
    },
    on: {
      "click": function ($event) {
        _vm.showAssignToEventModal = true;
      }
    }
  }, [_vm.hardwareListHasEvent ? [_vm._v("Reassign to an Event")] : [_vm._v("Assign an Event")]], 2)], 1) : _vm._e(), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_vm.hardwareListHasEvent ? _c('MiniEvent', {
    attrs: {
      "id": _vm.eventId,
      "title": "Currently assigned to Event"
    }
  }) : _vm._e(), !_vm.hardwareListHasEvent ? _c('Alert', [_vm._v("No Event is currently assigned to this Hardware List.")]) : _vm._e()], 1)], 1)], 1) : _vm._e(), _vm.selectedEntity ? _c('v-card', {
    staticClass: "pa-2 mt-3 border__hardware_list_templates"
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [!_vm.isReadOnly ? _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('ButtonGeneric', {
    attrs: {
      "color": _vm.EntityType.HARDWARE_LIST,
      "icon": _vm.$icons[_vm.EntityType.HARDWARE_LIST_TEMPLATE]
    },
    on: {
      "click": function ($event) {
        _vm.showAssignTemplateModal = true;
      }
    }
  }, [_vm.hardwareListHasTemplate ? [_vm._v("Reassign a template")] : [_vm._v("Assign template")]], 2)], 1) : _vm._e(), _vm.hardwareListHasTemplate ? _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('MiniHardwareListTemplate', {
    attrs: {
      "allow-child-creation": !_vm.isReadOnly,
      "allow-edit": _vm.hasHardwareListTemplateAdminPermission && !_vm.isReadOnly,
      "id": _vm.hardwareListTemplateId,
      "show-entity-type-icon": false,
      "show-slots-initially": false,
      "transformed-hardware-items": _vm.transformedHardwareItems,
      "title": "Currently assigned template"
    }
  })], 1) : _vm._e(), !_vm.hardwareListHasTemplate ? _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('Alert', [_vm._v(" No template is currently assigned to this Hardware List. ")])], 1) : _vm._e()], 1)], 1) : _vm._e(), _vm.showAssignTemplateModal ? _c('ModalHardwareListTemplateAssign', {
    attrs: {
      "hardware-list-has-template": _vm.hardwareListHasTemplate,
      "existing-selected-template": _vm.selectedTemplate
    },
    on: {
      "modal-close": _vm.onCloseOfAssignTemplateModal,
      "assign-hardware-list-template": _vm.onClickOfAssignTemplateSaveButton
    }
  }) : _vm._e(), _vm.showAssignToEventModal ? _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.EVENT,
      "hide-title-section": "",
      "title": "Search for an Event to assign"
    },
    on: {
      "modal-close": _vm.reset
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('SelectorEvent', {
          attrs: {
            "existing-values": _vm.selectedEventIdArray,
            "single-mode": "",
            "size": "0"
          },
          on: {
            "input": _vm.onSelectorEventInput
          }
        })];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('v-layout', [_c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_vm.shouldShowEventSaveButtonWarning ? _c('Alert', {
          attrs: {
            "type": _vm.AlertType.WARNING
          }
        }, [_vm._v("Saving now will remove the currently asigned Event.")]) : _vm._e(), _c('ButtonSave', {
          attrs: {
            "ml-0": ""
          },
          on: {
            "click": _vm.onClickOfAsignToEventSaveButton
          }
        }, [_vm.hardwareListHasEvent ? [_vm._v("Reassign to Event")] : [_vm._v("Assign to Event")]], 2), _c('ButtonCancel', {
          on: {
            "click": _vm.reset
          }
        })], 1)], 1)];
      },
      proxy: true
    }], null, false, 27965945)
  }) : _vm._e(), _vm.showEditHardwareListTemplateSlotModal && _vm.selectedTemplateSlot && _vm.selectedTemplateSlot.id ? _c('ModalHardwareListTemplateSlotEdit', {
    attrs: {
      "hardware-list-template-slot-id": _vm.selectedTemplateSlot.id
    },
    on: {
      "modal-close": _vm.reset
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };