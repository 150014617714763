import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import BaseButton from '~/nasa_ui/base/BaseButton';
let ButtonBackToFilters = class ButtonBackToFilters extends BaseButton {
    get computedColor() {
        return this.color || '--cosmic-gray-lighten-4';
    }
    get computedIcon() {
        return this.icon || this.$icons.arrow_left;
    }
    get computedTextColor() {
        return this.textColor || '--cosmic-text';
    }
};
ButtonBackToFilters = __decorate([
    Component
], ButtonBackToFilters);
export default ButtonBackToFilters;
