var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.riskReportabilityIsAlways ? _c('c-icon-fa', {
    attrs: {
      "icon": "fa-solid fa-chart-line",
      "icon-color": "var(--cosmic-text)",
      "tooltip": "Reportability set to ALWAYS",
      "tooltip-position": "left"
    }
  }) : _vm.riskReportabilityIsDefault ? _c('c-icon-fa', {
    attrs: {
      "icon": "fa-thin fa-chart-line",
      "icon-color": "var(--cosmic-text)",
      "tooltip": "Reportability set to DEFAULT",
      "tooltip-position": "left"
    }
  }) : _vm.riskReportabilityIsNever ? _c('c-icon-fa', {
    attrs: {
      "icon": "fa-thin fa-ban",
      "icon-color": "var(--cosmic-text)",
      "tooltip": "Reportability set to NEVER",
      "tooltip-position": "left"
    }
  }) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };