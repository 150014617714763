var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-navigation-drawer', {
    staticClass: "the_side_nav",
    class: {
      'outage-error': _vm.outageAlertState === _vm.AlertType.ERROR,
      'outage-warning': _vm.outageAlertState === _vm.AlertType.WARNING
    },
    attrs: {
      "value": _vm.value,
      "app": "",
      "aria-orientation": "vertical",
      "disable-resize-watcher": ""
    },
    on: {
      "input": _vm.emitInput
    }
  }, [_c('v-list', {
    staticClass: "py-0"
  }, [_c('v-list-tile', {
    staticClass: "reporting_menu_selection",
    attrs: {
      "active-class": "",
      "exact": "",
      "to": "/reporting"
    },
    on: {
      "click": _vm.closeSideNav,
      "focus": _vm.focusEvent
    }
  }, [_c('v-list-tile-action', [_c('c-icon-fa', {
    staticClass: "nav_icon",
    attrs: {
      "icon": "fad fa-chart-line",
      "icon-size": "21px",
      "icon-color": _vm.$route.path.startsWith('/reporting') ? 'white' : 'black'
    }
  })], 1), _c('v-list-tile-content', [_c('v-list-tile-title', [_vm._v("Reporting")])], 1)], 1), _c('v-list-tile', {
    attrs: {
      "active-class": "",
      "exact": "",
      "to": "/risks?autosearch"
    },
    on: {
      "click": _vm.closeSideNav
    }
  }, [_c('v-list-tile-action', [_c('c-icon-fa', {
    staticClass: "nav_icon",
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.RISK],
      "icon-size": "21px",
      "icon-color": _vm.$route.path.startsWith('/risks') && !_vm.$route.query.category ? 'white' : 'black'
    }
  })], 1), _c('v-list-tile-content', [_c('v-list-tile-title', [_vm._v("Open Risks")])], 1)], 1), _c('v-list-tile', {
    attrs: {
      "active-class": "",
      "exact": "",
      "to": "/risks?category=SUPPORTABILITY&autosearch"
    },
    on: {
      "click": _vm.closeSideNav
    }
  }, [_c('v-list-tile-action', [_c('c-icon-fa', {
    staticClass: "nav_icon",
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.RISK],
      "icon-size": "21px",
      "icon-color": _vm.$route.path.startsWith('/risks') && _vm.$route.query.category ? 'white' : 'black'
    }
  })], 1), _c('v-list-tile-content', [_c('v-list-tile-title', [_vm._v("Open supportability Risks")])], 1)], 1), _c('v-list-tile', {
    attrs: {
      "active-class": "",
      "exact-active-class": "",
      "to": "/orb_notes"
    },
    on: {
      "click": _vm.closeSideNav
    }
  }, [_c('v-list-tile-action', [_c('c-icon-fa', {
    staticClass: "nav_icon",
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.COMMENT],
      "icon-size": "21px",
      "icon-color": _vm.$route.path.startsWith('/orb_notes') ? 'white' : 'black'
    }
  })], 1), _c('v-list-tile-content', [_c('v-list-tile-title', [_vm._v("ORB notes")])], 1)], 1), _c('v-list-tile', {
    attrs: {
      "active-class": "",
      "exact-active-class": "",
      "to": "/organizations"
    },
    on: {
      "click": _vm.closeSideNav
    }
  }, [_c('v-list-tile-action', [_c('c-icon-fa', {
    staticClass: "nav_icon",
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.ORGANIZATION],
      "icon-size": "21px",
      "icon-color": _vm.$route.path.startsWith('/organizations') ? 'white' : 'black'
    }
  })], 1), _c('v-list-tile-content', [_c('v-list-tile-title', [_vm._v("Organizations")])], 1)], 1), _c('v-list', _vm._l(_vm.filteredTheSideNavigationMenu, function (item) {
    return _c('v-list-group', {
      key: item.displayName,
      ref: item.displayName.toLowerCase(),
      refInFor: true,
      attrs: {
        "prepend-icon": item.icon,
        "no-action": "",
        "dark": ""
      }
    }, [_c('v-list-tile', {
      attrs: {
        "slot": "activator",
        "active-class": ""
      },
      slot: "activator"
    }, [_c('v-list-tile-content', [_c('v-list-tile-title', [_vm._v(_vm._s(item.displayName))])], 1)], 1), _vm._l(item.children, function (subItem) {
      return _c('v-list-tile', {
        key: subItem.displayName,
        attrs: {
          "to": subItem.to,
          "active-class": "",
          "exact-active-class": ""
        },
        on: {
          "click": _vm.closeSideNav
        }
      }, [_c('v-list-tile-content', [_c('v-list-tile-title', [_vm._v(_vm._s(subItem.displayName))])], 1), _c('v-list-tile-action', [_c('v-list-tile-action', [_c('c-icon-fa', {
        staticClass: "subgroup_item_icon",
        attrs: {
          "icon": subItem.icon,
          "icon-size": "21px",
          "icon-color": _vm.$route.path.startsWith(`${subItem.to}`) ? 'white' : `${subItem.textColor}`
        }
      })], 1)], 1)], 1);
    })], 2);
  }), 1), _c('v-list-tile', {
    staticClass: "nav_item change_log",
    attrs: {
      "tag": "button"
    },
    on: {
      "click": _vm.onClickOfShowChangeLog
    }
  }, [_c('v-list-tile-action', [_c('c-icon-fa', {
    staticClass: "nav_icon",
    attrs: {
      "icon": "fa-thin fa-wand-magic-sparkles"
    }
  })], 1), _c('v-list-tile-content', [_c('v-list-tile-title', [_vm._v("View app updates")])], 1), _c('v-list-tile-content', [_c('v-list-tile-title', {
    staticClass: "text-xs-center monospace_font primary white--text font-weight-bold app_version"
  }, [_vm._v("v" + _vm._s(_vm.appVersion))])], 1)], 1)], 1), _c('hr'), _vm.showChangeLog ? _c('ModalChangeLog', {
    on: {
      "modal-close": function ($event) {
        _vm.showChangeLog = !_vm.showChangeLog;
      }
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };