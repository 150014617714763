var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { StepsOnStepsTabFragment } from '~/db_types';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { IS_EXTERNAL_ICON_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import { DocumentHardwareListRepo } from '~/nasa_ui/DAL/document/HardwareLists';
import ReadOnlyMixin from '~/nasa_ui/mixins/ReadOnlyMixin';
import { EntityType } from '~/nasa_ui/types/enums';
import { STANDARD_HARDWARE_COLUMNS } from '~/nasa_ui/utils/helpers/tableDefinitions';
import { transformHardwareListAssemblies } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let WorkOrderStepTools = class WorkOrderStepTools extends Mixins(ReadOnlyMixin, DocumentHardwareListRepo) {
    EntityType = EntityType;
    tableHeaders = [
        ...STANDARD_HARDWARE_COLUMNS,
        IS_EXTERNAL_ICON_COL,
        {
            text: 'Calibration',
            value: '_externalCalibrationDate'
        },
        {
            text: 'Calibration number',
            value: '_externalCalibrationNumber'
        }
    ];
    document;
    step;
    get sourceDoc() {
        return this.document?.cosmicDocumentDr || this.document?.cosmicDocumentTps;
    }
    get hardwareTableItems() {
        if (!this.step?.toolsList?.hardwareListAssemblies.nodes) {
            return [];
        }
        return transformHardwareListAssemblies(this.step?.toolsList?.hardwareListAssemblies.nodes).map((hlaNode) => {
            hlaNode._externalCalibrationDate = hlaNode.externalCalibrationDate || DEFAULT_DASH;
            hlaNode._externalCalibrationNumber = hlaNode.externalCalibrationNumber || DEFAULT_DASH;
            return hlaNode;
        });
    }
};
__decorate([
    Prop({
        required: true,
        type: Object
    }),
    __metadata("design:type", Object)
], WorkOrderStepTools.prototype, "document", void 0);
__decorate([
    Prop({
        required: true,
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof StepsOnStepsTabFragment !== "undefined" && StepsOnStepsTabFragment) === "function" ? _a : Object)
], WorkOrderStepTools.prototype, "step", void 0);
WorkOrderStepTools = __decorate([
    Component
], WorkOrderStepTools);
export default WorkOrderStepTools;
