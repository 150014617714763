/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./AppUserGroupList.vue?vue&type=template&id=74d7c60c&scoped=true"
import script from "./AppUserGroupList.ts?vue&type=script&lang=ts&external"
export * from "./AppUserGroupList.ts?vue&type=script&lang=ts&external"
import style0 from "./AppUserGroupList.vue?vue&type=style&index=0&id=74d7c60c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74d7c60c",
  null
  
)

export default component.exports