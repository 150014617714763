import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { CommentType } from '~/db_types';
import { CommentsRepoMixin } from '~/nasa_ui/DAL/comment';
let LastCommentInfoPanel = class LastCommentInfoPanel extends Mixins(CommentsRepoMixin) {
    commentTypeOverride = CommentType.GENERAL;
    response = null;
    isReply = false;
    showNewCommentModal = false;
    documentId;
    get lastComment() {
        return this.response?.comments?.nodes[0] ?? null;
    }
    get replyToText() {
        return this.isReply ? this.lastComment?.comment : null;
    }
    launchNewCommentModal(isReply) {
        this.showNewCommentModal = true;
        this.isReply = isReply;
    }
    async fetchLastComment() {
        this.response = await this.getLastComment({
            documentId: this.documentId,
            commentType: CommentType.GENERAL
        });
    }
};
__decorate([
    Prop({
        required: true,
        type: String
    }),
    __metadata("design:type", Object)
], LastCommentInfoPanel.prototype, "documentId", void 0);
__decorate([
    Watch('documentId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], LastCommentInfoPanel.prototype, "fetchLastComment", null);
LastCommentInfoPanel = __decorate([
    Component
], LastCommentInfoPanel);
export default LastCommentInfoPanel;
