/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./AppFilePreview.vue?vue&type=template&id=44c456d7&scoped=true"
import script from "./AppFilePreview.ts?vue&type=script&lang=ts&external"
export * from "./AppFilePreview.ts?vue&type=script&lang=ts&external"
import style0 from "./AppFilePreview.vue?vue&type=style&index=0&id=44c456d7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44c456d7",
  null
  
)

export default component.exports