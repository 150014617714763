import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { UserGroupType } from '~/db_types';
import BaseTab from '~/nasa_ui/base/BaseTab';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { DEFAULT_ROWS_PER_PAGE_ITEMS } from '~/nasa_ui/constants/tablePagination';
import { UserRepoMixin } from '~/nasa_ui/DAL/user';
import HttpMixin from '~/nasa_ui/mixins/HttpMixin';
import { sortObjectBy } from '~/nasa_ui/utils';
let TabUserGroups = class TabUserGroups extends Mixins(BaseTab, HttpMixin, UserRepoMixin) {
    assignedGroups = [];
    previouslyAssignedGroupCodes = [];
    availableGroups = [];
    rowsPerPageItems = DEFAULT_ROWS_PER_PAGE_ITEMS;
    user = null;
    get allAvailableGroups() {
        return this.availableGroups
            ? this.availableGroups
                .filter((group) => group?.subType !== UserGroupType.USER_DELEGATE)
                .map((group) => {
                return {
                    value: group?.code || '',
                    name: group?.name || ''
                };
            })
                .sort(sortObjectBy('value'))
            : [];
    }
    get availableGroupsHeaders() {
        return [
            {
                text: 'Name',
                value: 'name'
            },
            {
                text: 'Code',
                value: 'value'
            }
        ];
    }
    // No idea what this is intended to be but making it the same for now
    get headersForReadOnly() {
        return this.availableGroupsHeaders;
    }
    get isEditingYourself() {
        return this.userId === this.$route.params.id;
    }
    get isRoleAdmin() {
        return this.currentUserIsAdmin;
    }
    get selectedUserGroupsTableItems() {
        return this.assignedGroups ? this.assignedGroups : [];
    }
    get shouldShowInputs() {
        return !this.isReadOnly || this.isRoleAdmin;
    }
    get selectedGroupCodes() {
        return this.selectedUserGroupsTableItems.map((group) => group.value);
    }
    get userGroupChanges() {
        const added = this.selectedGroupCodes.filter((code) => !this.previouslyAssignedGroupCodes.includes(code));
        const removed = this.previouslyAssignedGroupCodes.filter((code) => !this.selectedGroupCodes.includes(code));
        return {
            added,
            removed
        };
    }
    get userId() {
        return this.currentUser?.id;
    }
    async fetchEntity(id) {
        const resp = await this.$http.get(`/users/${id}`);
        this.user = resp.data;
        if (!this.user?.userGroups?.length) {
            return;
        }
        const activeGroups = this.user.userGroups
            .filter((item) => {
            return item?.subType !== UserGroupType.USER_DELEGATE;
        })
            .map((item) => {
            return {
                value: item.code || '',
                name: item.name || ''
            };
        });
        this.previouslyAssignedGroupCodes = activeGroups.map((group) => group.value);
        this.assignedGroups = activeGroups;
    }
    async fetchGroups() {
        const resp = await this.$http.get(`/user-groups?subType=${UserGroupType.SYSTEM}&subType=${UserGroupType.USER_MANAGED}&take=-1`);
        // Set the raw response in case we need that
        this.availableGroups =
            resp.data?.results?.map((group) => {
                group.name = group.name || DEFAULT_DASH;
                return group;
            }) || [];
    }
    async mutateUserGroups() {
        if (this.userGroupChanges.added?.length) {
            // add user to each group
            const addUserToGroups = this.userGroupChanges.added.map((code) => {
                return this.$http.put(`/user-groups/${encodeURIComponent(code)}/users`, [this.id]);
            });
            await Promise.all(addUserToGroups);
        }
        if (this.userGroupChanges.removed?.length) {
            // remove user from each group
            const removeUserFromGroups = this.userGroupChanges.removed.map((code) => {
                return this.$http.delete(`/user-groups/${encodeURIComponent(code)}/users/${this.id}`);
            });
            await Promise.all(removeUserFromGroups);
        }
    }
    async onClickOfSave() {
        try {
            await this.mutateUserGroups();
            this.$message('reload', true);
            this.scrollToTop();
            this.$notification.add({
                text: 'User groups updated.',
                type: this.AlertType.SUCCESS
            });
            this.fetchEntity(this.id);
        }
        catch (error) {
            this.$errorUtility({ err: error });
            this.$notification.add({
                text: 'An error happened during the save.',
                type: this.AlertType.ERROR
            });
        }
    }
    onIdChange(id) {
        if (id) {
            this.fetchEntity(id);
            this.fetchGroups();
        }
    }
};
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], TabUserGroups.prototype, "onIdChange", null);
TabUserGroups = __decorate([
    Component
], TabUserGroups);
export default TabUserGroups;
