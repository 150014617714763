var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "container pa-1 mt-2"
  }, [_c('SearchInputContainer', {
    attrs: {
      "autofocus": _vm.autofocus,
      "disable-search": !_vm.searchInput,
      "entity-type": _vm.entityType,
      "hint": _vm.hint,
      "is-loading": _vm.isLoading,
      "placeholder": _vm.placeholder
    },
    on: {
      "searchButtonClicked": _vm.onClickOfSearchButton,
      "enterPressed": _vm.onClickOfSearchButton
    },
    model: {
      value: _vm.searchInput,
      callback: function ($$v) {
        _vm.searchInput = $$v;
      },
      expression: "searchInput"
    }
  }), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_c('AppLoader', {
    staticClass: "ma-0",
    attrs: {
      "color": _vm.entityType,
      "show": _vm.isLoading,
      "type": "linear"
    }
  })], 1), _c('v-slide-y-transition', {
    attrs: {
      "tag": "table",
      "hide-on-leave": ""
    }
  }, [_vm.shouldShowTable ? _c('AppTableExpandable', {
    key: "searchResults",
    attrs: {
      "color": _vm.entityType,
      "headers": _vm.computedTableHeaders,
      "icon": _vm.$icons[_vm.EntityType.APPROVAL_ROUTING_TEMPLATE],
      "items": _vm.searchResults,
      "show-count": false,
      "title": _vm.resultTableTitle,
      "dense-rows": "",
      "selectable-rows": ""
    },
    on: {
      "rowClicked": _vm.onClickOfSearchResult
    },
    scopedSlots: _vm._u([{
      key: "expanded-row",
      fn: function ({
        item
      }) {
        return [_c('ButtonGeneric', {
          staticClass: "my-3 ml-0",
          attrs: {
            "color": "primary",
            "icon": _vm.$icons['square_check']
          },
          on: {
            "click": function ($event) {
              return _vm.onClickOfSearchResult(item);
            }
          }
        }, [_vm._v(" Select this Template ")]), _c('v-flex', {
          attrs: {
            "xs12": "",
            "md8": ""
          }
        }, [_c('AppHeader', {
          attrs: {
            "size": "mini"
          }
        }, [_vm._v("User Groups")]), _c('AppUserGroupList', {
          attrs: {
            "group-codes": item.groupCodes
          }
        })], 1)];
      }
    }], null, false, 3116985078)
  }, [_c('template', {
    slot: "title"
  }, [_vm._v(" Approval Routing Templates ( "), _vm.searchResults ? _c('small', {
    staticClass: "monospace_font"
  }, [_c('strong', [_vm._v(_vm._s(_vm.searchResults.length))])]) : _vm._e(), _vm._v(" ) ")])], 2) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };