import { __decorate } from "tslib";
import gql from 'graphql-tag';
import { Component, Mixins } from 'vue-property-decorator';
import LoaderMixin from '~/nasa_ui/mixins/LoaderMixin';
import { CosmicIntervalFragment } from '../base/interval';
import { queryFactory } from '../base/mixinFactories';
import { CosmicChangeSetSlimFragment } from '../changeSet/slim';
import { CosmicCommentSlimFragment } from '../comment/slim';
import { CosmicItemInstanceSlimFragment } from '../itemInstance/slim';
import { CosmicOrganizationSlimFragment } from '../organization/slim';
import { CosmicUserSlimFragment } from '../user/slim';
import { CosmicActivitySlimFragment } from './slim';
export const CosmicActivityFragment = gql `
  ${CosmicActivitySlimFragment}
  ${CosmicChangeSetSlimFragment}
  ${CosmicCommentSlimFragment}
  ${CosmicIntervalFragment}
  ${CosmicItemInstanceSlimFragment}
  ${CosmicOrganizationSlimFragment}
  ${CosmicUserSlimFragment}

  fragment CosmicActivity on Activity {
    ...CosmicActivitySlim

    changeSet {
      ...CosmicChangeSetSlim
    }
    comments {
      nodes {
        ...CosmicCommentSlim
      }
    }
    createdByUser {
      ...CosmicUserSlim
    }
    crewTime {
      ...CosmicInterval
    }
    itemInstance {
      ...CosmicItemInstanceSlim
    }
    operationTime {
      ...CosmicInterval
    }
    organization {
      ...CosmicOrganizationSlim
    }
    pressurizedTime {
      ...CosmicInterval
    }
    staticTime {
      ...CosmicInterval
    }
  }
`;
export const GetActivityById = gql `
  ${CosmicActivityFragment}

  query GetActivityById($id: UUID!) {
    activityById(id: $id) {
      ...CosmicActivity
    }
  }
`;
export const GetSlimActivityById = gql `
  ${CosmicActivitySlimFragment}

  query GetSlimActivityById($id: UUID!) {
    activityById(id: $id) {
      ...CosmicActivitySlim
    }
  }
`;
let ActivityRepoMixin = class ActivityRepoMixin extends Mixins(LoaderMixin) {
    getActivityById = queryFactory(this, GetActivityById);
    getSlimActivityById = queryFactory(this, GetSlimActivityById);
};
ActivityRepoMixin = __decorate([
    Component
], ActivityRepoMixin);
export { ActivityRepoMixin };
