var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_vm.isArchived ? _c('ButtonRemove', {
    staticClass: "mr-0",
    attrs: {
      "disabled": _vm.shouldDisableArchiveButton,
      "is-removing": _vm.isSaving,
      "tooltip": `Unarchiving this Location allows it to be selected.`,
      "tooltip-position": "left"
    },
    on: {
      "click": _vm.onClickOfUnarchiveButton
    }
  }, [_c('span', {
    attrs: {
      "slot": "icon"
    },
    slot: "icon"
  }, [_c('c-icon-fa', {
    staticClass: "mr-2",
    attrs: {
      "icon": _vm.$icons.unarchive
    }
  })], 1), _c('span', [_vm._v("Unarchive")]), _c('template', {
    slot: "removing"
  }, [_vm._v("Unarchiving...")])], 2) : _c('ButtonRemove', {
    staticClass: "mr-0",
    attrs: {
      "disabled": _vm.shouldDisableArchiveButton,
      "is-removing": _vm.isSaving,
      "tooltip": `Archiving this Location removes it's selectability throughout the system.`,
      "tooltip-position": "left",
      "icon": _vm.$icons.archived
    },
    on: {
      "click": _vm.onClickOfArchiveButton
    }
  }, [_c('span', [_vm._v("Archive")]), _c('template', {
    slot: "removing"
  }, [_vm._v("Archiving...")])], 2), _vm.showDialog ? _c('ModalConfirmationDelete', {
    on: {
      "affirmativeClicked": _vm.onAffirmativeClicked,
      "negativeClicked": _vm.onNegativeClicked
    },
    model: {
      value: _vm.showDialog,
      callback: function ($$v) {
        _vm.showDialog = $$v;
      },
      expression: "showDialog"
    }
  }, [_c('template', {
    slot: "alert-text"
  }, [_c('p', [_c('i', [_vm._v("Archiving")]), _vm._v(" will make the Location unable to be selected moving forward.")]), _c('p', [_c('i', [_vm._v("Unarchiving")]), _vm._v(" allows Users to select this Location when receiving Inventory for example.")])]), _c('template', {
    slot: "remove-button-text"
  }, [_vm._v("Yes, I'm sure.")])], 2) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };