var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Watch } from 'vue-property-decorator';
import { RiskCategory, RiskHardwareType, RiskReportability, RiskResponseWithRefs } from '~/db_types/swagger_types';
import BaseModalWithUser from '~/nasa_ui/base/BaseModalWithUser';
import { OutputMode } from '~/nasa_ui/types';
import { RiskHardwareTypeDisplay } from '~/nasa_ui/types/enums/risks';
import { RiskCategoryDisplay, WorkStatus, WorkStatusTypeDisplay } from '~/nasa_ui/types/models/risk';
import { getRiskColor, isNullOrUndefined } from '~/nasa_ui/utils';
let ModalRiskCreate = class ModalRiskCreate extends Mixins(BaseModalWithUser) {
    consequenceIsValid = true;
    isCosmicManagedNumber = true;
    likelihoodIsValid = true;
    OutputMode = OutputMode;
    riskHardwareType = null;
    RiskCategory = RiskCategory;
    RiskCategoryDisplay = RiskCategoryDisplay;
    RiskHardwareType = RiskHardwareType;
    RiskHardwareTypeDisplay = RiskHardwareTypeDisplay;
    WorkStatus = WorkStatus;
    WorkStatusTypeDisplay = WorkStatusTypeDisplay;
    formData = {
        category: null,
        cause: null,
        consequence: null,
        effect: null,
        hardwareType: null,
        showOnReports: RiskReportability.DEFAULT,
        isSupplier: false,
        likelihood: null,
        numberOverride: null,
        organizationCode: null,
        ownerContactId: null,
        title: null
    };
    get isFormValid() {
        const hasRequiredFields = Boolean(this.formData.cause &&
            this.formData.effect &&
            this.formData.title &&
            ((!this.isCosmicManagedNumber && this.formData.numberOverride) || this.isCosmicManagedNumber) &&
            this.formData.organizationCode &&
            this.formData.category &&
            this.formData.hardwareType &&
            this.formData.ownerContactId);
        return this.riskScoreIsValid && hasRequiredFields;
    }
    get riskScore() {
        const total = (this.formData.likelihood || 1) * (this.formData.consequence || 1);
        return this.likelihoodIsValid && this.consequenceIsValid ? total : '-';
    }
    get riskScoreClass() {
        if (!this.likelihoodIsValid || !this.consequenceIsValid) {
            return '';
        }
        const riskColor = this.likelihoodIsValid && this.consequenceIsValid
            ? getRiskColor(this.formData.likelihood || 1, this.formData.consequence || 1)
            : '';
        return `${riskColor}--text text--darken-2`;
    }
    get riskScoreIsValid() {
        return (this.likelihoodIsValid &&
            this.consequenceIsValid &&
            !isNullOrUndefined(this.formData.likelihood) &&
            !isNullOrUndefined(this.formData.consequence));
    }
    emitRiskCreated(val) { }
    async onClickOfCreate() {
        try {
            const resp = await this.$http.post('/risks', this.formData);
            this.emitModalClose();
            this.$notification.add({
                type: this.AlertType.SUCCESS,
                text: 'Risk created.'
            });
            this.emitRiskCreated(resp.data);
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    onConsequenceError(hasError) {
        this.consequenceIsValid = !hasError;
    }
    onLikelihoodError(hasError) {
        this.likelihoodIsValid = !hasError;
    }
    onIsCosmicManagedNumberChange() {
        if (this.isCosmicManagedNumber) {
            this.formData.numberOverride = null;
        }
    }
    onRiskHardwareTypeChange() {
        if (this.riskHardwareType) {
            this.formData.hardwareType = RiskHardwareType.EMU;
        }
        else {
            this.formData.hardwareType = RiskHardwareType.TOOLS;
        }
    }
};
__decorate([
    Emit('risk-created'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof RiskResponseWithRefs !== "undefined" && RiskResponseWithRefs) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], ModalRiskCreate.prototype, "emitRiskCreated", null);
__decorate([
    Watch('isCosmicManagedNumber'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalRiskCreate.prototype, "onIsCosmicManagedNumberChange", null);
__decorate([
    Watch('riskHardwareType'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalRiskCreate.prototype, "onRiskHardwareTypeChange", null);
ModalRiskCreate = __decorate([
    Component
], ModalRiskCreate);
export default ModalRiskCreate;
