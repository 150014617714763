var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.HARDWARE_LIST_TEMPLATE,
      "title": "Search for Hardware List Templates",
      "hide-title-section": ""
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('SelectorHardwareListTemplate', {
          attrs: {
            "title": "Currently assigned Template",
            "existing-values": _vm.selectedTemplateArray,
            "output-mode": "object",
            "single-mode": "",
            "size": "0"
          },
          on: {
            "input": _vm.onSelectorHardwareListTemplateInput
          }
        })];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('v-layout', [_c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_vm.shouldShowTemplateSaveButtonWarning ? _c('Alert', {
          attrs: {
            "type": _vm.AlertType.WARNING
          }
        }, [_vm._v("Saving now will remove the currently asigned Hardware List Template.")]) : _vm._e(), _c('ButtonSave', {
          attrs: {
            "disabled": _vm.shouldDisableAssignButton,
            "ml-0": ""
          },
          on: {
            "click": _vm.onClickOfAssignTemplateSaveButton
          }
        }, [_vm.hardwareListHasTemplate ? [_vm._v("Reassign Hardware List Template")] : [_vm._v("Assign Hardware List Template")]], 2), _c('ButtonCancel', {
          on: {
            "click": _vm.closeDialog
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };