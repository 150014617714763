import { __decorate, __metadata } from "tslib";
import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseInput from '~/nasa_ui/base/BaseInput';
/**
 * AVAILABLE PROPS:
 *
 * autofocus
 * clearable = doesnt make sense in this control
 * disabled - greys out the field
 * falseText
 * hideUnknown
 * label
 * placeholder
 * required
 * rules - array of functions
 * trueText
 * type
 * unknownText
 * value
 */
let AppBoolean = class AppBoolean extends BaseInput {
    trueText;
    falseText;
    unknownText;
    hideUnknown;
    horizontal;
    selectedText = null;
    // Override the base
    defaultRules = {
        required: (value) => {
            if (value === null || value === undefined) {
                return 'REQUIRED';
            }
            return false;
        }
    };
    setValue(value) {
        this.selectedText = this.value === undefined || this.value === '' ? null : this.value;
        //this.value = this.hideUnknown && (value === null || value === undefined) ? false : this.value;
    }
};
__decorate([
    Prop({ default: 'Yes', type: String }),
    __metadata("design:type", String)
], AppBoolean.prototype, "trueText", void 0);
__decorate([
    Prop({ default: 'No', type: String }),
    __metadata("design:type", String)
], AppBoolean.prototype, "falseText", void 0);
__decorate([
    Prop({ default: 'Unknown', type: String }),
    __metadata("design:type", String)
], AppBoolean.prototype, "unknownText", void 0);
__decorate([
    Prop({ default: false, type: Boolean }),
    __metadata("design:type", Boolean)
], AppBoolean.prototype, "hideUnknown", void 0);
__decorate([
    Prop({ default: true, type: Boolean }),
    __metadata("design:type", Boolean)
], AppBoolean.prototype, "horizontal", void 0);
__decorate([
    Watch('value', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], AppBoolean.prototype, "setValue", null);
AppBoolean = __decorate([
    Component
], AppBoolean);
export default AppBoolean;
