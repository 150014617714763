var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { sortObjectBy } from '~/nasa_ui/utils';
import { UserRepoMixin } from '../../DAL/user';
// Props
// groupNodeId: Uses this to search for groups
// linkToManagePage: if you want to link to user manage page on click of user
// mailToUser: if you want to contact user by email on click
let UserGroupUsersMenu = class UserGroupUsersMenu extends Mixins(UserRepoMixin) {
    displayedGroupMembers = [];
    groupName = '';
    code;
    linkToManagePage;
    mailToUser;
    handleClickOfUser(member) {
        if (this.linkToManagePage) {
            return this.$router.push(`/users/manage/${member.id}`);
        }
        if (this.mailToUser) {
            return window.open(`mailto:${member?.email?.[0]}`);
        }
        else
            return;
    }
    async onClickOfShowGroupMembers(code) {
        this.isLoading = true;
        const resp = await this.httpGet(`/user-groups/${this.code}`);
        const users = resp.data?.users || [];
        this.groupName = resp.data?.name;
        this.displayedGroupMembers = users.sort(sortObjectBy('firstName'));
        this.isLoading = false;
    }
};
__decorate([
    Prop({
        default: '',
        type: String
    }),
    __metadata("design:type", String)
], UserGroupUsersMenu.prototype, "code", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", typeof (_a = typeof Boolean !== "undefined" && Boolean) === "function" ? _a : Object)
], UserGroupUsersMenu.prototype, "linkToManagePage", void 0);
__decorate([
    Prop({
        default: true,
        type: Boolean
    }),
    __metadata("design:type", typeof (_b = typeof Boolean !== "undefined" && Boolean) === "function" ? _b : Object)
], UserGroupUsersMenu.prototype, "mailToUser", void 0);
UserGroupUsersMenu = __decorate([
    Component
], UserGroupUsersMenu);
export default UserGroupUsersMenu;
