import "core-js/modules/es.array.push.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.entity ? _c('v-container', {
    attrs: {
      "fluid": "",
      "grid-list-lg": "",
      "pa-0": "",
      "mt-1": ""
    }
  }, [_vm.isLoading ? _c('AppLoader', {
    attrs: {
      "color": _vm.EntityType.DOCUMENT,
      "type": "linear"
    }
  }) : _vm._e(), !_vm.currentUserHasAccess ? _c('Alert', {
    attrs: {
      "type": _vm.AlertType.WARNING
    }
  }, [_c('span', {
    directives: [{
      name: "safe-html",
      rawName: "v-safe-html",
      value: _vm.accessControlAlertText,
      expression: "accessControlAlertText"
    }]
  })]) : _vm._e(), _vm.pendingInitializationChangeSetId ? _c('div', [_c('Alert', {
    attrs: {
      "type": _vm.AlertType.WARNING
    }
  }, [_c('span', [_vm._v("Document Initialization is pending. No changes can be made until Initialization is approved.")]), _c('ButtonLink', {
    staticClass: "ml-3",
    attrs: {
      "color": "primary",
      "to": _vm.linkToApprovals
    }
  }, [_vm._v("View Approvals")])], 1)], 1) : _c('div', [_vm.isLoading ? _c('v-flex', {
    staticClass: "d-flex justify-center"
  }, [_c('AppLoader', {
    attrs: {
      "color": _vm.EntityType.CHANGE_SET
    }
  })], 1) : _vm._e(), _vm.isInitialized && !_vm.isWorkflowStepRejected ? _c('v-layout', {
    attrs: {
      "justify-center": "",
      "my-2": ""
    }
  }, [_c('AppRadialProgress', {
    attrs: {
      "completed": _vm.completedSteps,
      "total": _vm.totalSteps
    }
  })], 1) : _vm._e(), _vm.isWorkflowStepRejected ? _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('Alert', {
    attrs: {
      "type": "error"
    }
  }, [_vm._v("There has been a task rejection. The initiator, " + _vm._s(_vm.$contactDisplay(_vm.entity.initiatorContact)) + ", has been notified via email.")])], 1)], 1) : _vm._e(), _c('InitializationStartStop', {
    attrs: {
      "current-user-has-access": _vm.currentUserHasAccess,
      "doc-id": _vm.entity.id,
      "hide-start-stop-button": !_vm.hasTasks,
      "is-closed": _vm.isDocumentClosed,
      "is-initialized": _vm.isInitialized,
      "is-open-for-edits": _vm.isOpenForEdits,
      "is-rejected": _vm.isRejected,
      "type": _vm.DocumentType.C_WORKFLOW
    },
    on: {
      "saved": _vm.onSaveOfStartStop
    },
    scopedSlots: _vm._u([{
      key: "message-append",
      fn: function () {
        return [_c('ul', {
          staticClass: "startStopRequirements"
        }, [!_vm.hasTasks ? _c('li', [_vm._v("At least "), _c('a', {
          attrs: {
            "href": "#tasks_overview"
          }
        }, [_vm._v("1 Task")]), _vm._v(" is required to start.")]) : _vm._e()])];
      },
      proxy: true
    }], null, false, 4099998019)
  })], 1), _c('LastCommentInfoPanel', {
    attrs: {
      "document-id": _vm.id
    }
  }), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', [_c('ButtonExport', {
    staticClass: "mr-2",
    on: {
      "click": _vm.onClickOfExportPdf
    }
  }, [_vm._v("View PDF")]), _c('ButtonClone', {
    staticClass: "mr-2",
    attrs: {
      "to": _vm.cloneUrl
    }
  }, [_vm._v("Clone Workflow")]), _c('CosmicDocumentEmail', {
    ref: "emailButton",
    staticClass: "inline-block",
    attrs: {
      "disabled": false,
      "document-id": _vm.id
    }
  })], 1)], 1), _vm.currentUserHasAccess ? _c('c-card', {
    staticClass: "mb-2",
    attrs: {
      "border-color": "var(--v-documents-base)"
    }
  }, [_c('div', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('c-icon-fa', {
    staticClass: "pr-2",
    attrs: {
      "icon": "fad fa-info-circle",
      "icon-color": "var(--v-documents-base)"
    }
  }), _c('span', [_vm._v("Base")])], 1), _c('WorkflowManageBaseDetails', {
    attrs: {
      "id": _vm.id,
      "read-only": _vm.isReadOnly
    }
  })], 1) : _vm._e(), _c('c-card', {
    attrs: {
      "border-color": "var(--v-documents-base)"
    }
  }, [_c('div', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('c-icon-fa', {
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.DOCUMENT],
      "icon-color": "var(--v-documents-base)"
    }
  }), _c('c-icon-fa', {
    attrs: {
      "icon": "fal fa-arrows-alt-h",
      "icon-size": "14px",
      "icon-color": "black"
    }
  }), _c('c-icon-fa', {
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.DOCUMENT],
      "icon-color": "var(--v-documents-base)"
    }
  }), _c('span', [_vm._v("Related Documents")])], 1), _c('AppRelatedDocuments', {
    attrs: {
      "id": _vm.id,
      "read-only": false
    },
    on: {
      "hasRelatedDocuments": _vm.onHasRelatedDocuments
    }
  })], 1), _c('DocumentAdditionalUserAccess', {
    attrs: {
      "id": _vm.id,
      "read-only": _vm.isReadOnly
    }
  }), _c('v-layout', {
    attrs: {
      "row": "",
      "no-wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "text-xs-right": ""
    }
  }, [_c('DeleteCosmicDocument', {
    attrs: {
      "id": _vm.entity.id,
      "cosmic-document-sub-type": _vm.DocumentType.C_WORKFLOW,
      "read-only": _vm.isReadOnly
    },
    on: {
      "deletionComplete": function ($event) {
        return _vm.$router.push(`/search?documentSubType=C_WORKFLOW`);
      }
    }
  }, [_vm._v(" Delete workflow ")])], 1)], 1), _vm.showReviseDocumentModal ? _c('AreYouSureDialog', {
    attrs: {
      "modal-width": "600px"
    },
    on: {
      "negativeClicked": _vm.onCloseOfReviseDocument,
      "affirmativeClicked": _vm.reopenDocumentForEdits
    }
  }, [_c('p', {
    attrs: {
      "slot": "text"
    },
    slot: "text"
  }, [_c('strong', [_vm._v("WARNING:")]), _vm._v(" This will stop the Workflow; making tasks editable and Users cannot make Approvals during this time. ")])]) : _vm._e()], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };