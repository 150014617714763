var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.APPROVAL_ROUTING_TEMPLATE
    },
    on: {
      "modal-close": _vm.emitModalClose
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._t("title", function () {
          return [_vm._v("Create Approval Routing Template")];
        })];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c('FormApprovalRoutingTemplateCreate', {
          attrs: {
            "display-type": _vm.AppDynamicWrapperType.NONE,
            "user-groups": _vm.userGroups
          },
          on: {
            "is_form_valid": function ($event) {
              _vm.isFormValid = $event;
            },
            "mutation_variables": function ($event) {
              _vm.createArtMutationVariables = $event;
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonCreate', {
          attrs: {
            "disabled": _vm.shouldDisableCreateButton,
            "is-saving": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfCreate
          }
        }, [_vm._v(" Create Approval Routing Template ")])];
      },
      proxy: true
    }], null, true)
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };