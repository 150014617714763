import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let AppViewHeader = class AppViewHeader extends Vue {
    iconColor;
    icon;
    pills;
    title;
    hasSlot(name = 'default') {
        return !!this.$slots[name] || !!this.$scopedSlots[name];
    }
};
__decorate([
    Prop({
        default: 'var(--cosmic-text)',
        required: false,
        type: String
    }),
    __metadata("design:type", String)
], AppViewHeader.prototype, "iconColor", void 0);
__decorate([
    Prop({
        required: false,
        type: String
    }),
    __metadata("design:type", Object)
], AppViewHeader.prototype, "icon", void 0);
__decorate([
    Prop({
        required: false,
        type: Array,
        default: () => []
    }),
    __metadata("design:type", Array)
], AppViewHeader.prototype, "pills", void 0);
__decorate([
    Prop({
        required: false,
        type: String
    }),
    __metadata("design:type", String)
], AppViewHeader.prototype, "title", void 0);
AppViewHeader = __decorate([
    Component
], AppViewHeader);
export default AppViewHeader;
