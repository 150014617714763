var _a, _b;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Prop, Vue } from 'vue-property-decorator';
import colors from 'vuetify/es5/util/colors';
import { HeaderSortOptions } from '~/nasa_ui/types';
import { LogType } from '~/nasa_ui/types/enums/log';
import { sortObjectBy } from '~/nasa_ui/utils';
let StatsPerUser = class StatsPerUser extends Vue {
    endDate = '';
    isFetching = false;
    pagination = { descending: true, page: 1, rowsPerPage: 10 };
    resp = null;
    selectedUser = null;
    showSettings = false;
    startDate = '';
    formData = {
        endDate: '',
        startDate: ''
    };
    startingPagination = {
        descending: true,
        page: 1,
        rowsPerPage: 10
    };
    subType;
    get allUsersTelemetry() {
        return this.resp
            ? this.resp.map((item) => {
                const hasFirstOrLastName = item?.createdByUser?.firstName || item?.createdByUser?.lastName;
                return {
                    id: item?.createdByUserId,
                    userName: hasFirstOrLastName
                        ? this.$contactDisplay(item?.createdByUser)
                        : item?.createdByUser?.email?.[0] || '-',
                    totalCount: item?.totalCount || 0,
                    _totalCount: (item?.totalCount || 0).toLocaleString('en-US')
                };
            })
            : null;
    }
    get chartColors() {
        return this.subType === LogType.ERROR
            ? Object.keys(colors)
                .flatMap((color) => [colors[color].base, colors[color].accent1])
                .filter((_) => _ !== undefined)
            : // lighten, darken, accent, base
                Object.keys(colors)
                    .map((color) => colors[color].lighten2)
                    .reverse()
                    .slice(4);
    }
    get chartData() {
        return {
            labels: this.currentItems?.map((user) => user.userName),
            datasets: [
                {
                    label: 'Total',
                    data: this.currentItems?.map((user) => user.totalCount),
                    backgroundColor: this.chartColors
                }
            ]
        };
    }
    get chartOptions() {
        return {
            responsive: true,
            indexAxis: 'y',
            plugins: {
                legend: {
                    display: false
                }
            }
        };
    }
    get chartTypeDisplay() {
        return this.subType === LogType.TELEMETRY ? `Telemetry` : this.subType === LogType.ERROR ? `Errors` : `?`;
    }
    get currentItems() {
        const sortByField = this.pagination.sortBy && this.pagination.sortBy === '_totalCount'
            ? 'totalCount'
            : this.pagination.sortBy || 'totalCount';
        const page = this.pagination.page || 1;
        const rowsPerPage = this.pagination.rowsPerPage || 10;
        const start = (page - 1) * rowsPerPage;
        const end = page * rowsPerPage;
        return (this.allUsersTelemetry?.sort(sortObjectBy(sortByField, Boolean(this.pagination.descending))).slice(start, end) ||
            []);
    }
    get description() {
        return this.pagination.descending && this.pagination.page === 1 && this.pagination.rowsPerPage === 10
            ? `Showing the top 10 Users with the most ${this.chartTypeDisplay.toLowerCase()}`
            : `Showing users ${this.chartTypeDisplay.toLowerCase()}`;
    }
    get tableHeaders() {
        return [
            {
                text: 'User',
                value: 'userName'
            },
            {
                text: 'Count',
                value: '_totalCount',
                headerSortType: HeaderSortOptions.NUMBER
            }
        ];
    }
    get queryVariables() {
        const dateRange = {};
        if (this.formData.startDate) {
            dateRange.start = {
                value: this.formData.startDate,
                inclusive: true
            };
        }
        if (this.formData.endDate) {
            dateRange.end = {
                value: this.formData.endDate,
                inclusive: true
            };
        }
        return {
            dateRange: Object.keys(dateRange).length ? dateRange : null,
            subType: this.subType
        };
    }
    created() {
        this.fetchData();
    }
    async fetchData() {
        try {
            this.isFetching = true;
            const resp = await this.$apollo.query({
                query: gql `
          fragment StatsPerUser on MetricReportResult {
            createdByUserId
            createdByUser {
              email
              firstName
              id
              lastName
            }
            totalCount
          }
          query StatsPerUser($dateRange: DatetimeRangeInput, $subType: String) {
            metricReport(subType: $subType, dateRange: $dateRange, groupUser: true, first: 1000) {
              nodes {
                ...StatsPerUser
              }
            }
          }
        `,
                fetchPolicy: 'network-only',
                variables: this.queryVariables
            });
            this.resp = resp.data?.metricReport?.nodes || null;
            this.isFetching = false;
        }
        catch (err) {
            this.$errorUtility({
                err,
                backupErrorText: `Could not fetch ${this.chartTypeDisplay} per user.`
            });
            this.isFetching = false;
        }
    }
    updatePagination(pagination) {
        this.pagination = pagination;
    }
    updateResults() {
        this.startDate = this.formData.startDate;
        this.endDate = this.formData.endDate;
        this.showSettings = false;
        this.fetchData();
    }
};
__decorate([
    Prop({
        type: String,
        default: LogType.TELEMETRY
    }),
    __metadata("design:type", Object)
], StatsPerUser.prototype, "subType", void 0);
StatsPerUser = __decorate([
    Component
], StatsPerUser);
export default StatsPerUser;
