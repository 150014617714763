var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop } from 'vue-property-decorator';
import { CosmicEventWithLocationFragment, EventType } from '~/db_types';
import BaseSearch from '~/nasa_ui/base/BaseSearch';
import { SearchEventsQuery } from '~/nasa_ui/DAL/event';
import { EntityType } from '~/nasa_ui/types';
import { EventTypeDisplay } from '~/nasa_ui/types/enums/event';
import { EventTableHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
let SearchEvent = class SearchEvent extends BaseSearch {
    disableSearch;
    entityType = EntityType.EVENT;
    EventType = EventType;
    EventTypeDisplay = EventTypeDisplay;
    hint = 'Name';
    placeholder = 'search for events';
    userSelectedMaxResults = 1000;
    SEARCH_QUERY = SearchEventsQuery;
    searchFilters = {
        subType: null
    };
    get chipsFromFilters() {
        if (!this.pureSearchFilters || Object.keys(this.pureSearchFilters).length === 0) {
            return [];
        }
        let appChips = [];
        appChips = appChips.concat(this.buildAppChipForProperty('subType', 'events', EventTypeDisplay));
        return appChips.filter((key) => key !== undefined);
    }
    get computedTableHeaders() {
        return [...EventTableHeaders];
    }
    onClickOfSearchResult(selectedItem) {
        this.reset();
        return this.isOutputModeString ? selectedItem.id : selectedItem;
    }
};
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], SearchEvent.prototype, "disableSearch", void 0);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof CosmicEventWithLocationFragment !== "undefined" && CosmicEventWithLocationFragment) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], SearchEvent.prototype, "onClickOfSearchResult", null);
SearchEvent = __decorate([
    Component
], SearchEvent);
export default SearchEvent;
