var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-card', {
    staticClass: "tab_admin_application_information"
  }, [_c('v-list', {
    attrs: {
      "dense": "",
      "subheader": ""
    }
  }, [_c('v-list-tile', {
    staticClass: "stat"
  }, [_c('v-list-tile-content', [_c('span', [_vm._v("COSMIC_SYSTEM")])]), _c('v-list-tile-content', {
    staticClass: "align-end data_item"
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.COSMIC_SYSTEM) + " ")])])], 1), _c('v-list-tile', {
    staticClass: "stat"
  }, [_c('v-list-tile-content', [_c('span', [_vm._v("COSMIC_APPLICATION")])]), _c('v-list-tile-content', {
    staticClass: "align-end data_item"
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.COSMIC_APPLICATION) + " ")])])], 1), _c('v-list-tile', {
    staticClass: "stat"
  }, [_c('v-list-tile-content', [_c('span', [_vm._v("Application version")])]), _c('v-list-tile-content', {
    staticClass: "align-end data_item"
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.appVersion) + " ")])])], 1), _c('v-list-tile', {
    staticClass: "stat api_status",
    class: {
      healthy: _vm.isApiHealthy,
      not_healthy: !_vm.isApiHealthy
    }
  }, [_c('v-list-tile-content', [_c('span', [_vm._v("API2 status")])]), _vm.isApiHealthy ? _c('v-list-tile-content', {
    staticClass: "align-end data_item"
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.apiVersion) + " ")])]) : _c('v-list-tile-content', {
    staticClass: "align-end data_item"
  }, [_c('strong', [_vm._v("NOT HEALTHY")])])], 1), _c('v-list-tile', {
    staticClass: "stat"
  }, [_c('v-list-tile-content', [_c('span', [_vm._v("COSMIC_ENVIRONMENT")])]), _c('v-list-tile-content', {
    staticClass: "align-end data_item"
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.COSMIC_ENVIRONMENT) + " ")])])], 1), _c('v-list-tile', {
    staticClass: "stat"
  }, [_c('v-list-tile-content', [_c('span', [_vm._v("Valid user support contexts")])]), _c('v-list-tile-content', {
    staticClass: "align-end data_item"
  }, [_c('strong', [_vm._v(_vm._s(_vm.$validUserSupportContexts.join(', ')))])])], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };