var _a, _b, _c;
import { __decorate, __metadata } from "tslib";
import { Debounce } from 'lodash-decorators/debounce';
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { CosmicUserGroupWithUserCountFragment } from '~/db_types';
import { UserRepoMixin } from '~/nasa_ui/DAL/user';
import { Maybe } from '~/nasa_ui/types';
import { sortObjectBy } from '~/nasa_ui/utils';
// This component can search for users or groups
// Under the hood it always is querying for user groups
// When using the 'Search for User' option, it is specifically looking for delegate user groups
let UserSearchPanel = class UserSearchPanel extends Mixins(UserRepoMixin) {
    searchType = 'user';
    searchTerm = '';
    groupOptions = [];
    displayedGroupMembers = [];
    showNoResultsMessage = false;
    viewingMembersOfGroupId = null;
    searchTypeOptions = [
        { displayText: 'User', value: 'user' },
        { displayText: 'Group', value: 'group' }
    ];
    customFilter;
    get isGroupSelected() {
        return this.searchType === 'group';
    }
    get isUserSelected() {
        return this.searchType === 'user';
    }
    get filteredGroupOptions() {
        return this.customFilter ? this.groupOptions.filter(this.customFilter) : this.groupOptions;
    }
    async search(searchTerm) {
        this.showNoResultsMessage = false;
        this.isLoading = true;
        if (this.searchType === 'user') {
            await this.searchUsers(searchTerm);
        }
        else {
            await this.searchGroups(searchTerm);
        }
        if (!this.groupOptions.length) {
            this.showNoResultsMessage = true;
        }
        this.isLoading = false;
    }
    async searchUsers(q) {
        if (!q) {
            return this.onSearchInputClear();
        }
        const data = await this.searchUserDelegateGroups({ q });
        this.groupOptions = data?.searchUserGroups?.nodes;
    }
    async searchGroups(q) {
        const data = await this.searchNonDelegateUserGroups({ q });
        this.groupOptions = data?.searchUserGroups?.nodes;
    }
    onSearchInputClear() {
        this.groupOptions = [];
    }
    async onClickOfShowGroupMembers(code) {
        this.isLoading = true;
        const resp = await this.httpGet(`/user-groups/${code}`);
        const users = resp.data?.users || [];
        this.displayedGroupMembers = users.sort(sortObjectBy('firstName'));
        this.isLoading = false;
    }
    onSearchTypeChange() {
        if (this.searchTerm) {
            this.search(this.searchTerm);
        }
    }
    selectGroup(group) {
        return group;
    }
};
__decorate([
    Prop({
        required: false,
        type: Function
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], UserSearchPanel.prototype, "customFilter", void 0);
__decorate([
    Debounce(300),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object]),
    __metadata("design:returntype", Promise)
], UserSearchPanel.prototype, "search", null);
__decorate([
    Watch('searchType'),
    Watch('searchTerm'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], UserSearchPanel.prototype, "onSearchTypeChange", null);
__decorate([
    Emit('select'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_c = typeof CosmicUserGroupWithUserCountFragment !== "undefined" && CosmicUserGroupWithUserCountFragment) === "function" ? _c : Object]),
    __metadata("design:returntype", void 0)
], UserSearchPanel.prototype, "selectGroup", null);
UserSearchPanel = __decorate([
    Component
], UserSearchPanel);
export default UserSearchPanel;
