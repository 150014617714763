var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppFormWrapper', {
    attrs: {
      "color": _vm.EntityType.ITEM_DRAWING,
      "size": _vm.size
    }
  }, [!_vm.selectedNodeId ? _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_vm.showRefreshAlert ? _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('Alert', {
    attrs: {
      "type": _vm.AlertType.WARNING
    }
  }, [_c('p', [_vm._v("Please refresh the search query to see any Item Drawings that were just created.")]), _c('p', [_c('ButtonGeneric', {
    attrs: {
      "color": _vm.EntityType.ITEM_DRAWING,
      "dark": "",
      "icon": _vm.$icons.reload
    },
    on: {
      "click": _vm.refreshSearchQuery
    }
  }, [_vm._v(" Refresh search query ")])], 1)])], 1) : _vm._e(), _c('v-flex', {
    attrs: {
      "md9": ""
    }
  }, [_c('SearchItemDrawing', _vm._b({
    ref: "SearchItemDrawing",
    on: {
      "input": _vm.emitInput,
      "results": _vm.emitResults,
      "searchButtonClicked": _vm.emitSearchButtonClicked,
      "termSearched": _vm.onTermSearched
    }
  }, 'SearchItemDrawing', _vm.$props, false))], 1), _c('v-flex', {
    attrs: {
      "md3": "",
      "mt-4": ""
    }
  }, [_c('ButtonCreate', {
    attrs: {
      "href": _vm.createItemMasterUrl,
      "rel": "noopener",
      "target": "_blank"
    },
    on: {
      "click": function ($event) {
        _vm.showRefreshAlert = true;
      }
    }
  }, [_vm._v(" Create a new Item Master ")])], 1)], 1) : _vm._e(), _vm.selectedNodeId ? _c('v-layout', [_c('v-flex', [_vm.hideSelectedTitle ? _c('AppHardwareDisplay', {
    attrs: {
      "node-id": _vm.selectedNodeId
    }
  }) : _c('AppHardwareDisplay', {
    attrs: {
      "node-id": _vm.selectedNodeId,
      "title": "Selected Item Drawing"
    }
  }), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.shouldShowClearSelectionButton ? _c('ButtonClearSelection', {
    staticClass: "mt-2",
    on: {
      "click": _vm.clearSelectedNodeId
    }
  }) : _vm._e()], 1)], 1)], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };