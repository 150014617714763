var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RiskResponseDisplay } from '~/nasa_ui/utils/helpers/transforms/risks';
let AppRiskScoreDisplay = class AppRiskScoreDisplay extends Vue {
    activeScoreIndex = 1;
    scoreTypes = ['initial', 'current', 'proj'];
    lockToCurrent;
    lockToInitial;
    lockToProjected;
    risk;
    size;
    tooltipPosition;
    get activeScoreType() {
        return this.scoreTypes[this.activeScoreIndex];
    }
    get nextScoreType() {
        return this.scoreTypes[this.activeScoreIndex + 1] || this.scoreTypes[0];
    }
    get criticalityToDisplayBasedOnActiveScoreIndex() {
        switch (this.activeScoreIndex) {
            case 0:
                return this.risk._initialCriticality;
            case 1:
                return this.risk._currentCriticality;
            case 2:
                return this.risk._projectedCriticality;
            default:
                return this.risk._initialCriticality;
        }
    }
    get riskScoreToDisplayBasedOnActiveScoreIndex() {
        switch (this.activeScoreIndex) {
            case 0:
                return this.initialRiskScore;
            case 1:
                return this.currentRiskScore;
            case 2:
                return this.risk._projectedRiskScore;
            default:
                return this.initialRiskScore;
        }
    }
    get currentConsequence() {
        return this.risk.consequence;
    }
    get currentRiskScore() {
        return this.risk.score;
    }
    get currentLikelihood() {
        return this.risk.likelihood;
    }
    get hasLock() {
        return this.lockToInitial || this.lockToCurrent || this.lockToProjected;
    }
    get initialConsequence() {
        return this.risk.initialConsequence;
    }
    get initialLikelihood() {
        return this.risk.initialLikelihood;
    }
    get initialRiskScore() {
        return this.risk.initialScore;
    }
    get riskColor() {
        return this.risk._currentRiskColor;
    }
    incrementActiveScoreIndex() {
        if (this.activeScoreIndex === this.scoreTypes.length - 1) {
            this.activeScoreIndex = 0;
        }
        else {
            this.activeScoreIndex++;
        }
    }
    viewRiskDetails() {
        console.log('view ');
    }
};
__decorate([
    Prop({
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], AppRiskScoreDisplay.prototype, "lockToCurrent", void 0);
__decorate([
    Prop({
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], AppRiskScoreDisplay.prototype, "lockToInitial", void 0);
__decorate([
    Prop({
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], AppRiskScoreDisplay.prototype, "lockToProjected", void 0);
__decorate([
    Prop({
        type: Object,
        required: true
    }),
    __metadata("design:type", typeof (_a = typeof RiskResponseDisplay !== "undefined" && RiskResponseDisplay) === "function" ? _a : Object)
], AppRiskScoreDisplay.prototype, "risk", void 0);
__decorate([
    Prop({
        type: String,
        default: '50px'
    }),
    __metadata("design:type", String)
], AppRiskScoreDisplay.prototype, "size", void 0);
__decorate([
    Prop({
        default: 'right',
        type: String
    }),
    __metadata("design:type", String)
], AppRiskScoreDisplay.prototype, "tooltipPosition", void 0);
AppRiskScoreDisplay = __decorate([
    Component
], AppRiskScoreDisplay);
export default AppRiskScoreDisplay;
