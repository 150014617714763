import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { CosmicAttachmentFragment as CosmicAttachmentFragmentOverride } from '~/nasa_ui/DAL/attachments';
import ReadOnlyMixin from '~/nasa_ui/mixins/ReadOnlyMixin';
import StepMixin from '~/nasa_ui/mixins/StepMixin';
import { AlertType } from '~/nasa_ui/types';
import { FileAttachmentTableHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
import { transformAttachments } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let DocumentWithStepAttachments = class DocumentWithStepAttachments extends Mixins(ReadOnlyMixin, StepMixin) {
    attachmentToDeleteId = null;
    isLoading = false;
    isSaving = false;
    shouldShowForm = false;
    document = null;
    selectedAttachment = null;
    showAreYouSure = false;
    documentId;
    get attachments() {
        const docAttachments = (this.document?.attachments.nodes || []);
        const steps = this.document?.workOrderSteps.nodes || [];
        return docAttachments.map((docAttachment) => {
            const relatedSteps = steps.filter((s) => {
                return s?.attachments.nodes.some((stepAttachment) => stepAttachment?.id === docAttachment?.id);
            });
            return {
                ...docAttachment,
                relatedSteps,
                _relatedStepsText: this.buildRelatedStepsText(relatedSteps)
            };
        });
    }
    get attachmentIds() {
        return this.attachments.map((a) => a.id);
    }
    get computedTableItems() {
        return transformAttachments(this.attachments);
    }
    get shouldDisableDeleteButton() {
        return this.isSaving || this.isReadOnly;
    }
    get shouldShowAreYouSure() {
        return this.showAreYouSure;
    }
    get stepOrTaskText() {
        if (!this.document) {
            return '';
        }
        return this.usesSteps(this.document.subType) ? 'Step' : 'Task';
    }
    get tableHeaders() {
        const headers = [...FileAttachmentTableHeaders];
        headers.splice(headers.length - 1, 0, {
            text: `Related ${this.stepOrTaskText}<br/>(count)`,
            value: '_relatedStepsText',
            noEscape: true
        });
        return headers;
    }
    buildRelatedStepsTitle(relatedSteps) {
        if (!relatedSteps.length) {
            return null;
        }
        const text = relatedSteps.map((s) => s.stepNumber).join(', ');
        return `Related Steps Numbers:\n ${text}`;
    }
    buildRelatedStepsText(relatedSteps) {
        const count = relatedSteps.length;
        if (!count) {
            return DEFAULT_DASH;
        }
        const titleText = this.buildRelatedStepsTitle(relatedSteps);
        return `<span title="${titleText}">${count} ${this.stepOrTaskText}${count > 1 ? 's' : ''}</span>`;
    }
    async deleteAttachment() {
        this.isSaving = true;
        try {
            await this.deleteAttachmentMutation(this.attachmentToDeleteId);
            this.$notification.add({ text: 'File attachment deleted.', type: AlertType.SUCCESS });
        }
        catch (err) {
            this.$errorUtility({ err });
        }
        this.attachmentToDeleteId = null;
        this.fetchAttachments(this.document?.id);
        this.isSaving = false;
    }
    deleteAttachmentMutation(attachmentId) {
        return this.$apollo.mutate({
            mutation: gql `
        mutation DeleteAttachmentsToDocumentByAttachmentIdAndDocumentId($documentId: UUID!, $attachmentId: UUID!) {
          deleteAttachmentsToDocumentByAttachmentIdAndDocumentId(
            input: { documentId: $documentId, attachmentId: $attachmentId }
          ) {
            clientMutationId
          }
        }
      `,
            variables: {
                documentId: this.document?.id,
                attachmentId: attachmentId
            }
        });
    }
    async fetchAttachmentsQuery() {
        const query = gql `
      ${CosmicAttachmentFragmentOverride}

      query AttachmentsForDocumentWithSteps($id: UUID!) {
        documentById(id: $id) {
          id
          subType
          nodeId
          attachments {
            nodes {
              ...CosmicAttachment
            }
          }
          workOrderSteps {
            nodes {
              stepNumber
              attachments {
                nodes {
                  ...CosmicAttachment
                }
              }
            }
          }
        }
      }
    `;
        return this.$apollo.query({
            query,
            variables: { id: this.documentId }
        });
    }
    onAffirmativeClicked() {
        this.showAreYouSure = false;
        this.deleteAttachment();
    }
    onClickOfRemoveButton(attachmentId) {
        this.attachmentToDeleteId = attachmentId;
        this.showAreYouSure = true;
    }
    onClickOfTableRow(row) {
        this.selectedAttachment = row;
        this.shouldShowForm = true;
    }
    onNegativeClicked() {
        this.attachmentToDeleteId = null;
        this.showAreYouSure = false;
    }
    onModalClose() {
        this.shouldShowForm = false;
        this.selectedAttachment = null;
    }
    async fetchAttachments(val) {
        this.isLoading = true;
        try {
            const res = await this.fetchAttachmentsQuery();
            this.document = res.data.documentById;
        }
        catch (err) {
            this.$errorUtility({ err });
        }
        this.isLoading = false;
    }
};
__decorate([
    Prop({
        required: true,
        type: String
    }),
    __metadata("design:type", Object)
], DocumentWithStepAttachments.prototype, "documentId", void 0);
__decorate([
    Watch('documentId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Promise)
], DocumentWithStepAttachments.prototype, "fetchAttachments", null);
DocumentWithStepAttachments = __decorate([
    Component
], DocumentWithStepAttachments);
export default DocumentWithStepAttachments;
