import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import find from 'lodash/find';
import { Component, Emit, Watch } from 'vue-property-decorator';
import { Side } from '~/db_types';
import BaseSearch from '~/nasa_ui/base/BaseSearch';
import { AS_BUILT_COL, BUILDING_BIN_STOCK_COL, CLASS_COL, DESCRIPTION_COL, DRAWING_NUMBER_COL, INVENTORY_QTY_COL, IS_INSTALLED_COL, LOT_COL, PROJECT_CODE_COL, SERIAL_COL, SIDE_COL, SIZE_COL, UNIT_COST_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import { CosmicItemInstanceSlimFragment } from '~/nasa_ui/DAL/itemInstance/slim';
import { CosmicLocationSlimFragment } from '~/nasa_ui/DAL/location/slim';
import { EntityType, HeaderSortOptions } from '~/nasa_ui/types';
import { HandDominanceDisplay } from '~/nasa_ui/types/enums/side';
import { composeTableHeader } from '~/nasa_ui/utils/helpers/composeTableHeader';
import { addFilterOutSelected } from '~/nasa_ui/utils/helpers/displayMappers';
import { numberDisplay } from '~/nasa_ui/utils/helpers/displays';
import { transformInventories } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let SearchAssembly = class SearchAssembly extends BaseSearch {
    entityType = EntityType.ASSEMBLY;
    hint = `asBuilt bin building drawingNumber projectCode room serial lot side`;
    placeholder = 'search for assembly parents';
    userSelectedMaxResults = 50;
    HandDominanceDisplay = HandDominanceDisplay;
    Side = Side;
    searchFilters = {
        asBuiltNumber: null,
        drawingNumber: null,
        lotNumber: null,
        query: '',
        side: null
    };
    get searchAssemblyHeaders() {
        return [
            composeTableHeader(DRAWING_NUMBER_COL, {
                value: 'itemInstance.drawingNumber'
            }),
            AS_BUILT_COL,
            SIDE_COL,
            SIZE_COL,
            SERIAL_COL,
            LOT_COL,
            DESCRIPTION_COL,
            CLASS_COL,
            PROJECT_CODE_COL,
            IS_INSTALLED_COL,
            INVENTORY_QTY_COL,
            UNIT_COST_COL,
            BUILDING_BIN_STOCK_COL,
            {
                text: 'Direct<br/>Children',
                value: '_directChildren',
                sortable: true,
                align: 'center',
                headerSortType: HeaderSortOptions.NUMBER
            }
        ];
    }
    get chipsFromFilters() {
        if (!this.pureSearchFilters || Object.keys(this.pureSearchFilters).length === 0) {
            return [];
        }
        let appChips = [];
        appChips = appChips.concat(this.asBuiltFilterDisplay, this.buildAppChipForProperty('drawingNumber', 'item_drawings'), this.buildAppChipForProperty('serialNumber', 'item_instances'), this.buildAppChipForProperty('lotNumber', 'item_instances'), this.buildAppChipForProperty('side', 'item_instances', HandDominanceDisplay));
        return appChips.filter((key) => key !== undefined);
    }
    get asBuiltFilterDisplay() {
        const asBuiltSearchFilterValue = (this.$get(this, 'pureSearchFilters.asBuiltNumber') || []);
        const hasAsBuiltFilter = asBuiltSearchFilterValue && asBuiltSearchFilterValue.length !== 0;
        if (!hasAsBuiltFilter) {
            return [];
        }
        return asBuiltSearchFilterValue.map((s) => {
            const asBuiltFilterDisplay = {
                close: true,
                color: 'item_instances',
                outline: true,
                text: `-${s}`,
                value: 'asBuiltNumber'
            };
            return asBuiltFilterDisplay;
        });
    }
    get disableSearch() {
        return !this.anyFiltersCurrentlyActive && this.searchInput?.length < 2;
    }
    get gqlSearchQueryVariables() {
        const base = {
            firstResults: this.userSelectedMaxResults
        };
        const query = {};
        if (this.searchInput.trim().length > 0) {
            query.query = this.searchInput;
        }
        const filters = this.pureSearchFilters;
        const variables = Object.assign({}, base, filters, query);
        return variables;
    }
    get resultTableTitle() {
        if (!this.searchResults) {
            return '';
        }
        return `Found <span class="monospace_font font-weight-bold">${this.searchResults.length}</span> parent nodes`;
    }
    SEARCH_QUERY = gql `
    ${CosmicLocationSlimFragment}
    ${CosmicItemInstanceSlimFragment}

    fragment SearchAssemblyInventory on Inventory {
      id
      isInstalled
      nodeId
      projectCode
      quantity
      status
      subType
      unitCost

      itemInstance {
        ...CosmicItemInstanceSlim
      }

      location {
        ...CosmicLocationSlim
      }

      assemblyChildren {
        totalCount
      }

      workingAssembly {
        id
        nodeId

        inventory {
          id
          nodeId
        }
      }
    }

    query searchAssemblies(
      $asBuiltNumber: [String]
      $bin: [String]
      $building: [String]
      $drawingNumber: [String]
      $filter: InventoryFilter
      $firstResults: Int
      $lotNumber: [String]
      $projectCode: [String]
      $query: String
      $room: [String]
      $serialNumber: [String]
      $side: [Side]
      $stock: [String]
    ) {
      searchInventory(
        asBuiltNumber: $asBuiltNumber
        bin: $bin
        building: $building
        drawingNumber: $drawingNumber
        filter: $filter
        first: $firstResults
        hasChildren: true
        isInstalled: false
        lotNumber: $lotNumber
        projectCode: $projectCode
        query: $query
        room: $room
        serialNumber: $serialNumber
        side: $side
        stock: $stock
      ) {
        nodes {
          ...SearchAssemblyInventory
        }
      }
    }
  `;
    onChangeOfRawResponse(val) {
        if (!val) {
            this.$set(this, 'searchResults', []);
            return;
        }
        // No matter the response type get the obj that contains 'nodes'
        let data = find(val, 'nodes');
        // add __SELECTED and pull up nodes a level
        data = this.addSelectedPropIfSelected(data.nodes || []);
        data = data.filter(addFilterOutSelected);
        data = this.formatForAssemblyTable(data);
        this.$set(this, 'searchResults', data);
    }
    formatForAssemblyTable(val) {
        return val.map((assySearchFrag) => {
            if (!assySearchFrag.itemInstance || !assySearchFrag.location) {
                return {};
            }
            return Object.assign({}, transformInventories([assySearchFrag])[0], {
                _directChildren: numberDisplay(assySearchFrag.assemblyChildren.totalCount)
            }, assySearchFrag);
        });
    }
    onClickOfSearchResult(selectedItem) {
        this.reset();
        return this.isOutputModeString ? selectedItem.workingAssembly?.id : selectedItem.workingAssembly;
    }
    onMetaClickOfSearchResult(selectedItem) {
        return this.isOutputModeString ? selectedItem.workingAssembly?.id : selectedItem.workingAssembly;
    }
};
__decorate([
    Watch('rawSearchResponse', { deep: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], SearchAssembly.prototype, "onChangeOfRawResponse", null);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], SearchAssembly.prototype, "onClickOfSearchResult", null);
__decorate([
    Emit('metaRowClicked'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], SearchAssembly.prototype, "onMetaClickOfSearchResult", null);
SearchAssembly = __decorate([
    Component
], SearchAssembly);
export default SearchAssembly;
