import { __decorate } from "tslib";
import gql from 'graphql-tag';
import { Component, Mixins } from 'vue-property-decorator';
import LoaderMixin from '~/nasa_ui/mixins/LoaderMixin';
import { mutationFactory, queryFactory } from '../base/mixinFactories';
import { CosmicContactSlimFragment } from '../contact/slim';
import { CosmicUserSlimFragment } from '../user';
import { CosmicDocumentSlimFragment } from './slim';
export const DocumentInformationFragment = gql `
  ${CosmicDocumentSlimFragment}
  ${CosmicContactSlimFragment}
  ${CosmicUserSlimFragment}

  fragment DocumentInformation on Document {
    ...CosmicDocumentSlim

    attributes
    computedRevisionNumber
    drawingNumber
    finalDispositionDefectCode
    lastReviewDate
    overrideCloseDate
    overrideOpenDate
    releaseDate
    releaseStatus
    reviewRequiredDays
    updatedDateTime

    createdByUser {
      ...CosmicUserSlim
    }

    itemDrawing {
      nodeId
    }

    initiatorContact {
      ...CosmicContactSlim
    }
  }
`;
export const GetDocumentById = gql `
  ${DocumentInformationFragment}

  query GetDocumentById($id: UUID!) {
    documentById(id: $id) {
      ...DocumentInformation

      changeSets {
        totalCount
      }

      comments {
        totalCount
      }

      links {
        totalCount
      }
    }
  }
`;
export const UpdateDocumentInformation = gql `
  mutation UpdateDocumentInformation($id: UUID!, $patch: DocumentPatch!) {
    updateDocumentById(input: { id: $id, patch: $patch }) {
      document {
        id
      }
    }
  }
`;
export const CosmicRelatedDocumentFragment = gql `
  ${CosmicDocumentSlimFragment}
  ${CosmicUserSlimFragment}

  fragment CosmicRelatedDocument on RelatedDocument {
    relationType
    documentId
    documentNumber
    relatedDocument {
      ...CosmicDocumentSlim
      createdByUser {
        ...CosmicUserSlim
      }
    }
  }
`;
let DocumentInformationRepoMixin = class DocumentInformationRepoMixin extends Mixins(LoaderMixin) {
    getDocumentById = queryFactory(this, GetDocumentById);
    updateDocumentInformation = mutationFactory(this, UpdateDocumentInformation);
};
DocumentInformationRepoMixin = __decorate([
    Component
], DocumentInformationRepoMixin);
export { DocumentInformationRepoMixin };
