var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('AppDynamicWrapper', _vm._b({
    attrs: {
      "color": _vm.EntityType.USER_GROUP,
      "display-type": _vm.displayType,
      "size": _vm.computedFormWrapperSize,
      "is-open": ""
    }
  }, 'AppDynamicWrapper', {
    ..._vm.$attrs,
    ..._vm.$props
  }, false), [_c('div', {
    class: {
      'pt-3': _vm.required
    }
  }, [_vm.isDisplayTypeExpansionPanel ? _c('div', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm._t("AppExpansionPanel__title"), !_vm.$slots.AppExpansionPanel__title ? _c('div', [_c('c-icon-fa', {
    staticClass: "mr-2",
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.USER_GROUP]
    }
  }), _c('span', {
    staticClass: "title"
  }, [_vm._v("User Groups")])], 1) : _vm._e()], 2) : _vm._e(), _vm.isDisplayTypeFormWrapper ? _vm._t("messages") : _vm._e(), _vm._t("AppHeader", function () {
    return [_c('AppHeader', [_vm._v("User Groups")])];
  }), _vm.isReadOnly && _vm.selectedUserGroups ? _c('AppUserGroupList', {
    attrs: {
      "read-only": _vm.isReadOnly
    },
    model: {
      value: _vm.selectedUserGroups,
      callback: function ($$v) {
        _vm.selectedUserGroups = $$v;
      },
      expression: "selectedUserGroups"
    }
  }) : _vm._e(), !_vm.readOnly ? _c('div', {
    staticClass: "cosmic_user_access_action_container mt-1 mb-3 ml-2"
  }, [_c('ButtonMiniAction', {
    attrs: {
      "disabled": _vm.disableToolbar,
      "tooltip": "Add a User delegate group",
      "tooltip-position": "right"
    },
    on: {
      "click": _vm.onClickOfAddUser
    }
  }, [[_c('c-icon-fa-stacked', [_c('c-icon-fa', {
    attrs: {
      "slot": "main",
      "icon-color": "var(--v-users-base)",
      "icon": _vm.$icons[_vm.EntityType.USER]
    },
    slot: "main"
  }), _c('div', {
    attrs: {
      "slot": "stack_right"
    },
    slot: "stack_right"
  }, [_c('c-icon-fa', {
    attrs: {
      "icon-size": "12px",
      "icon": _vm.$icons.search
    }
  })], 1)], 1)]], 2), _c('ButtonMiniAction', {
    attrs: {
      "disabled": _vm.disableToolbar,
      "tooltip": "Add a User Group",
      "tooltip-position": "right"
    },
    on: {
      "click": _vm.onClickOfAddUserGroup
    }
  }, [[_c('c-icon-fa-stacked', [_c('c-icon-fa', {
    attrs: {
      "slot": "main",
      "icon-color": "var(--v-user_groups-base)",
      "icon": _vm.$icons[_vm.EntityType.USER_GROUP]
    },
    slot: "main"
  }), _c('div', {
    attrs: {
      "slot": "stack_right"
    },
    slot: "stack_right"
  }, [_c('c-icon-fa', {
    attrs: {
      "icon-size": "12px",
      "icon": _vm.$icons.search
    }
  })], 1)], 1)]], 2), _c('ButtonMiniAction', {
    attrs: {
      "disabled": _vm.disableToolbar,
      "tooltip": "Add all User Groups from an Approval Routing Template",
      "tooltip-position": "right"
    },
    on: {
      "click": _vm.onClickOfAddApprovalRoutingTemplate
    }
  }, [[_c('c-icon-fa-stacked', [_c('c-icon-fa', {
    attrs: {
      "slot": "main",
      "icon-color": "var(--v-users-base)",
      "icon": _vm.$icons[_vm.EntityType.APPROVAL_ROUTING_TEMPLATE]
    },
    slot: "main"
  }), _c('div', {
    attrs: {
      "slot": "stack_right"
    },
    slot: "stack_right"
  }, [_c('c-icon-fa', {
    attrs: {
      "icon-size": "12px",
      "icon": _vm.$icons.search
    }
  })], 1)], 1)]], 2), _c('ButtonMiniAction', {
    attrs: {
      "disabled": _vm.listContainsYourself || _vm.disableToolbar,
      "tooltip": "Add yourself",
      "tooltip-position": "right"
    },
    on: {
      "click": _vm.onClickOfAddYourself
    }
  }, [[_c('c-icon-fa-stacked', [_c('c-icon-fa', {
    attrs: {
      "slot": "main",
      "icon-color": "var(--v-users-base)",
      "icon": _vm.$icons[_vm.EntityType.USER]
    },
    slot: "main"
  }), _c('div', {
    attrs: {
      "slot": "stack_right"
    },
    slot: "stack_right"
  }, [_c('c-icon-fa', {
    attrs: {
      "icon-size": "12px",
      "icon": _vm.$icons.plus
    }
  })], 1)], 1)]], 2), _c('ButtonMiniAction', {
    attrs: {
      "disabled": _vm.shouldDisableCancelAddButton,
      "tooltip": "Hide all active searches",
      "tooltip-position": "right"
    },
    on: {
      "click": _vm.onClickOfCancelAdd
    }
  }, [[_c('c-icon-fa-stacked', [_c('c-icon-fa', {
    attrs: {
      "slot": "main",
      "icon": _vm.$icons.search
    },
    slot: "main"
  }), _c('div', {
    attrs: {
      "slot": "stack_right"
    },
    slot: "stack_right"
  }, [_c('c-icon-fa', {
    attrs: {
      "icon-size": "12px",
      "icon": _vm.$icons.close
    }
  })], 1)], 1)]], 2), !_vm.hideSaveAsArtButton ? _c('ButtonMiniAction', {
    attrs: {
      "disabled": !_vm.value || _vm.value.length === 0 || _vm.disableToolbar,
      "tooltip": "Save current selection as an Approval Routing Template",
      "tooltip-position": "right"
    },
    on: {
      "click": _vm.onClickOfSaveAsApprovalRoutingTemplate
    }
  }, [[_c('c-icon-fa-stacked', [_c('c-icon-fa', {
    attrs: {
      "slot": "main",
      "icon-color": "var(--v-users-base)",
      "icon": _vm.$icons[_vm.EntityType.APPROVAL_ROUTING_TEMPLATE]
    },
    slot: "main"
  }), _c('div', {
    attrs: {
      "slot": "stack_right"
    },
    slot: "stack_right"
  }, [_c('c-icon-fa', {
    attrs: {
      "icon-size": "12px",
      "icon": _vm.$icons.create_fat
    }
  })], 1)], 1)]], 2) : _vm._e(), _vm._t("before_remove_button"), _c('ButtonMiniActionRemoveFromList', {
    attrs: {
      "disabled": !_vm.value || _vm.value.length === 0 || _vm.disableToolbar,
      "tooltip": "Remove all User Groups",
      "tooltip-position": "right"
    },
    on: {
      "click": _vm.onClickOfClearAll
    }
  })], 2) : _vm._e(), !_vm.readOnly ? _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.selectedUserGroups.length === 0 ? _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_vm._t("no_items", function () {
    return [_c('Alert', {
      attrs: {
        "type": "info"
      }
    }, [_c('p', [_vm._v("No User Groups selected.")])])];
  })], 2) : _vm._e()], 1), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.selectedUserGroups.length && _vm.has_above_results__Slot ? _c('v-flex', {
    attrs: {
      "xs12": "",
      "pb-0": ""
    }
  }, [_vm._t("above_results")], 2) : _vm._e()], 1), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.selectedUserGroups.length ? _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_vm._t("results", function () {
    return [_c('v-flex', {
      attrs: {
        "xs12": ""
      }
    }, [_vm.matchingApprovalRoutingTemplates.length ? _c('Alert', {
      attrs: {
        "type": _vm.AlertType.INFO
      }
    }, [_c('p', [_vm._v(" The current configuration of User Groups matches that of the following Approval Routing Templates: ")]), _c('ul', _vm._l(_vm.matchingApprovalRoutingTemplates, function (art) {
      return _c('li', {
        key: art
      }, [_c('strong', [_vm._v(_vm._s(art))])]);
    }), 0)]) : _vm._e(), _c('AppPageDescription', {
      staticClass: "mt-3",
      attrs: {
        "color": _vm.EntityType.APPROVAL_ROUTING_TEMPLATE,
        "remove-margin-left": ""
      }
    }, [_vm._v(" Group approval order. Drag items to change their approval order. ")])], 1), _vm._t("group_list", function () {
      return [_c('v-flex', {
        attrs: {
          "xs8": "",
          "pt-0": "",
          "mt-2": ""
        }
      }, [_c('AppUserGroupList', {
        attrs: {
          "read-only": _vm.isReadOnly
        },
        model: {
          value: _vm.selectedUserGroups,
          callback: function ($$v) {
            _vm.selectedUserGroups = $$v;
          },
          expression: "selectedUserGroups"
        }
      })], 1)];
    })];
  }, {
    "items": _vm.selectedUserGroups
  })], 2) : _vm._e()], 1)], 1) : _vm._e(), !_vm.readOnly ? _c('div', [_vm.hasAppPageDescription_above_SearchUser__Slot && (_vm.showUserGroupSearch || _vm.showUserSearch) ? _c('AppPageDescription', {
    staticClass: "mt-3",
    attrs: {
      "color": "info"
    }
  }, [_vm._t("AppPageDescription_above_SearchUser")], 2) : _vm._e(), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.showUserSearch ? _c('SearchUser', {
    attrs: {
      "existing-values": _vm.existingUsers,
      "keep-search-results-open": "",
      "output-mode": "object",
      "selectable-rows": ""
    },
    on: {
      "input": _vm.onClickOfUserResult
    }
  }) : _vm._e()], 1), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.showUserGroupSearch ? _c('div', [_c('SearchUserGroup', {
    attrs: {
      "existing-values": _vm.existingUserGroups,
      "keep-search-results-open": "",
      "output-mode": "object",
      "selectable-rows": ""
    },
    on: {
      "input": _vm.onClickOfUserGroupResult
    }
  })], 1) : _vm._e()]), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.showApprovalRoutingTemplateSearch ? _c('SearchApprovalRoutingTemplate', {
    attrs: {
      "keep-search-results-open": "",
      "output-mode": "object",
      "selectable-rows": ""
    },
    on: {
      "input": _vm.onClickOfApprovalRoutingTemplateResult
    }
  }) : _vm._e()], 1)], 1) : _vm._e()], 2)]), _vm.showApprovalRoutingTemplateModal ? _c('ModalApprovalRoutingTemplateCreate', {
    attrs: {
      "user-groups": _vm.selectedUserGroups
    },
    on: {
      "modal-close": function ($event) {
        _vm.showApprovalRoutingTemplateModal = false;
      }
    }
  }) : _vm._e(), _c('v-snackbar', {
    staticClass: "user_group_added_snackbar",
    attrs: {
      "timeout": 2000,
      "bottom": "",
      "color": "info",
      "right": ""
    },
    model: {
      value: _vm.snackbar,
      callback: function ($$v) {
        _vm.snackbar = $$v;
      },
      expression: "snackbar"
    }
  }, [_c('div', {
    staticClass: "layout align-center justify-center"
  }, [_c('c-icon-fa', {
    staticClass: "pr-2",
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.USER]
    }
  }), _c('strong', [_vm._v(_vm._s(_vm.snackbarMessage))])], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };