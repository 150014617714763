import gql from 'graphql-tag';
export const CosmicActivitySlimFragment = gql `
  fragment CosmicActivitySlim on Activity {
    attributes
    authorityCode
    authorityNumber
    calibrationType
    changeSetId
    computedCommentText
    createdByUserId
    createdDateTime
    cycles
    endDateTime
    id
    isManned
    isPreventativeMaintenance
    itemInstanceId
    name
    nodeId
    organizationCode
    pressurizationRange
    startDateTime
    subType
    updatedDateTime
  }
`;
