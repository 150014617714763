var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "authority-pipeline-wrapper"
  }, [_c('div', {
    staticClass: "authority-pipeline pl-2 pt-1 pr-2 pb-2"
  }, [_vm._t("title", function () {
    return [_c('p', {
      staticClass: "authority-pipeline-label"
    }, [_vm._v("Approval Pipeline")])];
  }), _c('div', {
    staticClass: "d-flex"
  }, _vm._l(_vm.filteredPipelineDisplay, function (csAuth, index) {
    return _c('span', {
      key: index,
      staticClass: "d-flex align-center justify-center pb-2",
      class: {
        'solo-pipeline': _vm.filteredPipelineDisplay.length === 1,
        'mx-1': _vm.unordered,
        historical: csAuth.isHistorical
      }
    }, [_c('v-tooltip', {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function ({
          on
        }) {
          return [_c('c-icon-fa', _vm._g({
            class: _vm.getAuthorityPipelineClass(csAuth),
            attrs: {
              "icon": _vm.$icons[csAuth.icon]
            }
          }, on))];
        }
      }], null, true)
    }, [_c('div', [csAuth.groupName ? _c('p', {
      staticClass: "mb-0"
    }, [_vm._v("Group: " + _vm._s(csAuth.groupName))]) : _vm._e(), _c('p', {
      staticClass: "mb-0"
    }, [_vm._v("Status: " + _vm._s(csAuth.statusDisplay))]), _vm.shouldShowApprovalRequirements(csAuth) ? _c('ul', {
      staticClass: "mt-1"
    }, _vm._l(csAuth.requirements, function (req) {
      return _c('li', {
        key: req
      }, [_vm._v("Requires " + _vm._s(req))]);
    }), 0) : _vm._e()])]), !_vm.unordered && index !== _vm.filteredPipelineDisplay.length - 1 ? _c('c-icon-fa', {
      staticClass: "connector mx-3",
      attrs: {
        "icon": _vm.$icons.pipeline_connector
      }
    }) : _vm._e()], 1);
  }), 0)], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };