var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
import BaseSelector from '~/nasa_ui/base/BaseSelector';
import { EntityType } from '~/nasa_ui/types';
import { OutputMode } from '~/nasa_ui/types/enums/general';
let SelectorEvent = class SelectorEvent extends BaseSelector {
    selectedEvents = [];
    disableSearch;
    get createEventUrl() {
        const baseUrl = `/${EntityType.EVENT}/create`;
        if (!this.searchTerm || this.searchTerm.includes(' ')) {
            return baseUrl;
        }
        return `/${baseUrl}?name=${this.searchTerm}`;
    }
    get hasSelectedEvents() {
        return Boolean(this.selectedEvents?.length);
    }
    get selectedEventsIds() {
        return this.selectedEvents.map((event) => event.id);
    }
    get shouldShowSearchEvent() {
        if (!this.singleMode) {
            return true;
        }
        return !(this.hasSelectedEvents && this.singleMode);
    }
    get shouldShowClearSelectionButton() {
        return this.clearable && this.hasSelectedEvents;
    }
    created() {
        if (this.existingValues?.length) {
            this.selectedEvents = [...this.existingValues].map((id) => ({ id }));
        }
    }
    refreshSearchQuery() {
        const SearchEvent = this.$refs.SearchEvent;
        if (SearchEvent) {
            SearchEvent.onClickOfSearchButton();
        }
        this.showRefreshAlert = false;
    }
    clearSelectedId() {
        this.selectedEvents = [];
        return null;
    }
    emitInput(selectedEvent) {
        if (!selectedEvent) {
            return null;
        }
        else if (!this.selectedEvents.includes(selectedEvent)) {
            this.selectedEvents.push(selectedEvent);
        }
        return this.outputMode === OutputMode.string ? this.selectedEvents.map((event) => event.id) : this.selectedEvents;
    }
    removeSelectedEvent(event) {
        this.selectedEvents = this.selectedEvents.filter((list) => list.id !== event);
        return this.hasSelectedEvents
            ? this.outputMode === OutputMode.string
                ? this.selectedEvents.map((event) => event.id)
                : this.selectedEvents
            : null;
    }
};
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], SelectorEvent.prototype, "disableSearch", void 0);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], SelectorEvent.prototype, "clearSelectedId", null);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], SelectorEvent.prototype, "emitInput", null);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], SelectorEvent.prototype, "removeSelectedEvent", null);
SelectorEvent = __decorate([
    Component
], SelectorEvent);
export default SelectorEvent;
