var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { OrganizationResponse } from '~/db_types/swagger_types';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import { OrganizationRepoMixin } from '~/nasa_ui/DAL/organization';
import { EntityType } from '~/nasa_ui/types';
import { OutputMode } from '~/nasa_ui/types/enums/general';
let SelectorOrganization = class SelectorOrganization extends Mixins(BaseCosmic, OrganizationRepoMixin) {
    selectedOrganizationCode = null;
    showRefreshAlert = false;
    hideBorder;
    clearable;
    organizationCode;
    // props from BaseSearch (needed for `v-bind="$props"` on the SearchOrganization)
    autofocus;
    autosearch;
    existingValues;
    filters;
    keepSearchResultsOpen;
    keepSearchValueAfterSelection;
    lockedFilters;
    outputMode;
    showFilterButton;
    showMaxResults;
    top;
    right;
    left;
    bottom;
    get createOrganizationUrl() {
        return `${EntityType.ORGANIZATION}/create`;
    }
    get shouldShowClearSelectionButton() {
        return this.clearable && Boolean(this.selectedOrganizationCode);
    }
    async fetchOrganization(code) {
        try {
            if (!code) {
                this.$errorUtility({
                    err: 'Organization code is required.'
                });
                return null;
            }
            const resp = await this.$http.get(`/${EntityType.ORGANIZATION}/code`);
            return resp.data;
        }
        catch (err) {
            this.$errorUtility({ err });
            return null;
        }
    }
    refreshSearchQuery() {
        const SearchOrganization = this.$refs.SearchOrganization;
        if (SearchOrganization) {
            SearchOrganization.onClickOfSearchButton();
        }
        this.showRefreshAlert = false;
    }
    clearSelectedOrganizationode() {
        this.selectedOrganizationCode = null;
        return null;
    }
    emitInput(selectedItem) {
        if (!selectedItem) {
            return null;
        }
        this.selectedOrganizationCode = typeof selectedItem === 'string' ? selectedItem : selectedItem.code;
        return selectedItem;
    }
    emitResults() { }
    emitSearchButtonClicked() { }
    async syncNodeId(code) {
        if (code) {
            this.selectedOrganizationCode = code;
            const organization = await this.fetchOrganization(code);
            if (organization) {
                this.emitInput(organization);
            }
        }
    }
};
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], SelectorOrganization.prototype, "hideBorder", void 0);
__decorate([
    Prop({
        default: true,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], SelectorOrganization.prototype, "clearable", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], SelectorOrganization.prototype, "organizationCode", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], SelectorOrganization.prototype, "autofocus", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], SelectorOrganization.prototype, "autosearch", void 0);
__decorate([
    Prop({
        type: Array
    }),
    __metadata("design:type", Array)
], SelectorOrganization.prototype, "existingValues", void 0);
__decorate([
    Prop({ default: () => { } }),
    __metadata("design:type", Object)
], SelectorOrganization.prototype, "filters", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], SelectorOrganization.prototype, "keepSearchResultsOpen", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], SelectorOrganization.prototype, "keepSearchValueAfterSelection", void 0);
__decorate([
    Prop({ default: () => [], type: Array }),
    __metadata("design:type", Array)
], SelectorOrganization.prototype, "lockedFilters", void 0);
__decorate([
    Prop({ default: OutputMode.string, type: [String, String] }),
    __metadata("design:type", Object)
], SelectorOrganization.prototype, "outputMode", void 0);
__decorate([
    Prop({
        default: true,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], SelectorOrganization.prototype, "showFilterButton", void 0);
__decorate([
    Prop({
        default: true,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], SelectorOrganization.prototype, "showMaxResults", void 0);
__decorate([
    Prop({
        default: '2'
    }),
    __metadata("design:type", String)
], SelectorOrganization.prototype, "top", void 0);
__decorate([
    Prop({
        default: '2'
    }),
    __metadata("design:type", String)
], SelectorOrganization.prototype, "right", void 0);
__decorate([
    Prop({
        default: '2'
    }),
    __metadata("design:type", String)
], SelectorOrganization.prototype, "left", void 0);
__decorate([
    Prop({
        default: '2'
    }),
    __metadata("design:type", String)
], SelectorOrganization.prototype, "bottom", void 0);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], SelectorOrganization.prototype, "clearSelectedOrganizationode", null);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof OrganizationResponse !== "undefined" && OrganizationResponse) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], SelectorOrganization.prototype, "emitInput", null);
__decorate([
    Emit('results'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], SelectorOrganization.prototype, "emitResults", null);
__decorate([
    Emit('searchButtonClicked'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], SelectorOrganization.prototype, "emitSearchButtonClicked", null);
__decorate([
    Watch('organizationCode', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", Promise)
], SelectorOrganization.prototype, "syncNodeId", null);
SelectorOrganization = __decorate([
    Component
], SelectorOrganization);
export default SelectorOrganization;
