var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-card', {
    staticClass: "pa-3"
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": "",
      "mb-4": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": "",
      "mb-2": ""
    }
  }, [_c('AppHeader', {
    attrs: {
      "uppercase": false
    }
  }, [_vm._v("Email Test")])], 1), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs4": "",
      "mb-3": ""
    }
  }, [_c('c-text', {
    attrs: {
      "label": "Subject",
      "c-value": _vm.emailFormData.subjectTemplate,
      "required": ""
    },
    on: {
      "c-input": function ($event) {
        _vm.emailFormData.subjectTemplate = $event.detail;
      }
    }
  })], 1)], 1)], 1), _c('v-flex', {
    attrs: {
      "xs3": ""
    }
  }, [_c('AppTextarea', {
    attrs: {
      "label": "HTML Template",
      "required": ""
    },
    model: {
      value: _vm.emailFormData.messageHtmlTemplate,
      callback: function ($$v) {
        _vm.$set(_vm.emailFormData, "messageHtmlTemplate", $$v);
      },
      expression: "emailFormData.messageHtmlTemplate"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs4": "",
      "mb-3": ""
    }
  }, [_c('AppContactMultiple', {
    attrs: {
      "label": "Whos getting the email?"
    },
    model: {
      value: _vm.emailFormData.recipientContactId,
      callback: function ($$v) {
        _vm.$set(_vm.emailFormData, "recipientContactId", $$v);
      },
      expression: "emailFormData.recipientContactId"
    }
  })], 1)], 1)], 1), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('ButtonEmail', {
    attrs: {
      "disabled": _vm.disableEmailTestButton
    },
    on: {
      "click": _vm.sendEmail
    }
  }, [_c('span', {
    staticClass: "ml-2"
  }, [_vm._v("Test the email")])])], 1)], 1), _vm.hasAvailableContexts ? _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": "",
      "mb-4": ""
    }
  }, [_c('AppHeader', {
    staticClass: "mb-2",
    attrs: {
      "uppercase": false
    }
  }, [_vm._v("Email Users")]), _c('ButtonEmail', {
    staticClass: "pr-2"
  }), _vm._l(_vm.availableContexts, function (orgCode, index) {
    return _c('ButtonEmail', {
      key: index,
      staticClass: "pr-2",
      attrs: {
        "organizationCode": orgCode.organizationCode
      }
    });
  })], 2) : _vm._e(), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": "",
      "mb-4": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppHeader', {
    staticClass: "mb-2",
    attrs: {
      "uppercase": false
    }
  }, [_vm._v("Bulk upload Users")]), _c('AppPageDescription', {
    attrs: {
      "color": "info"
    }
  }, [_vm._v(" Assumes: "), _c('ul', [_c('li', [_vm._v(" the order of the csv is "), _c('code', [_vm._v("[auid, email, lastName, firstName]")])]), _c('li', [_vm._v("Delimiter is "), _c('code', [_vm._v(",")])])])]), _c('ButtonGeneric', {
    attrs: {
      "color": _vm.EntityType.USER
    },
    on: {
      "click": function ($event) {
        _vm.showImportUserSpreadsheetModal = true;
      }
    }
  }, [[_c('c-icon-fa', {
    attrs: {
      "icon": "fad fa-file-excel"
    }
  })], [_c('span', {
    staticClass: "ml-2"
  }, [_vm._v("Upload Users")])]], 2)], 1)], 1), _vm.showImportUserSpreadsheetModal ? _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.USER,
      "hide-title-section": ""
    },
    on: {
      "modal-close": function ($event) {
        _vm.showImportUserSpreadsheetModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('v-fade-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [_vm.fileAccepted ? _c('div', [_c('div', {
          staticClass: "title mb-4"
        }, [_c('Alert', {
          attrs: {
            "type": "success"
          }
        }, [_c('p', {
          staticClass: "title"
        }, [_vm._v("File accepted.")])])], 1), _c('AppPageDescription', {
          attrs: {
            "remove-margin-left": ""
          }
        }, [_vm._v(" Clicking "), _c('strong', [_vm._v("IMPORT")]), _vm._v(" below will "), _c('i', {
          staticClass: "pr-1"
        }, [_vm._v("CREATE")]), _c('span', {
          staticClass: "success--text font-weight-bold monospace_font"
        }, [_vm._v(_vm._s(_vm.usersToCreate.length))]), _vm._v(" Users/Contacts ")]), _c('AppPageDescription', {
          attrs: {
            "color": _vm.EntityType.USER,
            "remove-margin-left": ""
          }
        }, [_vm._v(" Imported Users will be granted the support context of "), _c('u', [_vm._v(_vm._s(_vm.currentUserActiveSupportContext))])]), _c('AppTableSelectable', {
          attrs: {
            "item-key": "index",
            "items": _vm.allParsedUsers,
            "headers": _vm.importUsersTableHeader,
            "color": _vm.EntityType.USER
          },
          model: {
            value: _vm.usersToCreate,
            callback: function ($$v) {
              _vm.usersToCreate = $$v;
            },
            expression: "usersToCreate"
          }
        })], 1) : _vm._e()]), _c('v-fade-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [!_vm.fileAccepted ? _c('div', [_c('AppPageDescription', [_vm._v("Import the CSV file to begin")]), _c('AppFileUpload', {
          on: {
            "input": _vm.onUserImportFileSelected
          }
        })], 1) : _vm._e()])];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonCreate', {
          attrs: {
            "loading": _vm.isSaving,
            "disabled": _vm.isSaving || _vm.usersToCreate.length === 0
          },
          on: {
            "click": _vm.createUsers
          }
        }, [_vm._v(" Import " + _vm._s(_vm.usersToCreate.length) + " " + _vm._s(_vm.usersToCreate.length === 1 ? 'user' : 'users') + " ")]), _c('ButtonCancel', {
          on: {
            "click": function ($event) {
              _vm.showImportUserSpreadsheetModal = false;
            }
          }
        })];
      },
      proxy: true
    }], null, false, 2743626365)
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };