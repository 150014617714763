var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';
import BaseTable from '~/nasa_ui/base/BaseTable';
import { Maybe } from '~/nasa_ui/types';
let AppTablePaginated = class AppTablePaginated extends Mixins(BaseTable) {
    searchFilter = null;
    descending;
    rowsPerPage;
    skip;
    sortBy;
    searchText;
    showAllOption;
    get computedColor() {
        return this.color || 'grey lighten-2';
    }
    get computedShowSearchInput() {
        return this.showSearchInput;
    }
    get page() {
        return Math.floor(this.skip / this.rowsPerPage) + 1;
    }
    get localPagination() {
        return {
            descending: this.descending,
            page: this.page,
            rowsPerPage: this.rowsPerPage ?? 0,
            sortBy: this.sortBy || undefined,
            totalItems: this.totalItems ?? 0
        };
    }
    get rowsPerPageOptions() {
        const defaults = new Set([5, 10, 20, this.rowsPerPage]);
        const sortedDefaults = [...defaults].sort((a, b) => a - b);
        return this.showAllOption
            ? [
                ...sortedDefaults,
                {
                    text: '$vuetify.dataIterator.rowsPerPageAll',
                    value: -1
                }
            ]
            : sortedDefaults;
    }
    clearSearchFilter() {
        this.emitSearchFilterInput('');
    }
    onClickOfRow(val, event = null) {
        if (!event?.metaKey && !event?.ctrlKey) {
            this.$emit('rowClicked', val);
            this.$emit('input', val);
        }
        else {
            this.$emit('metaRowClicked', val);
        }
    }
    onPaginationUpdate(val) {
        const hasChanged = val.page !== this.localPagination.page ||
            val.rowsPerPage !== this.localPagination.rowsPerPage ||
            val.sortBy !== this.localPagination.sortBy ||
            val.descending !== this.localPagination.descending;
        if (hasChanged) {
            this.$emit('pagination-update', {
                ...val,
                skip: ((val.page || 1) - 1) * (val.rowsPerPage || 0)
            });
        }
    }
    emitSearchFilterInput(val) {
        this.searchFilter = val;
        return val;
    }
};
__decorate([
    Prop({ required: false, type: Boolean, default: false }),
    __metadata("design:type", Boolean)
], AppTablePaginated.prototype, "descending", void 0);
__decorate([
    Prop({ required: true, type: Number }),
    __metadata("design:type", Number)
], AppTablePaginated.prototype, "rowsPerPage", void 0);
__decorate([
    Prop({ required: true, type: Number }),
    __metadata("design:type", Number)
], AppTablePaginated.prototype, "skip", void 0);
__decorate([
    Prop({ required: false, type: String }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], AppTablePaginated.prototype, "sortBy", void 0);
__decorate([
    Prop({ required: false, type: String }),
    __metadata("design:type", typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object)
], AppTablePaginated.prototype, "searchText", void 0);
__decorate([
    Prop({ default: false, type: Boolean }),
    __metadata("design:type", Boolean)
], AppTablePaginated.prototype, "showAllOption", void 0);
__decorate([
    Emit('searchFilterInput'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], AppTablePaginated.prototype, "emitSearchFilterInput", null);
AppTablePaginated = __decorate([
    Component
], AppTablePaginated);
export default AppTablePaginated;
