import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { DocumentType } from '~/db_types';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import { AppDynamicWrapperType } from '~/nasa_ui/components/AppDynamicWrapper/AppDynamicWrapper';
import { CosmicContactSlimFragment } from '~/nasa_ui/DAL/contact/slim';
import AutoTagWatchFormDataMixin from '~/nasa_ui/mixins/AutoTagWatchFormDataMixin';
import ReadOnlyMixin from '~/nasa_ui/mixins/ReadOnlyMixin';
import { AlertType } from '~/nasa_ui/types';
import { DocumentTypeDisplay } from '~/nasa_ui/types/enums/document';
let WorkflowManageBaseDetails = class WorkflowManageBaseDetails extends Mixins(AutoTagWatchFormDataMixin, BaseCosmic, ReadOnlyMixin) {
    AppDynamicWrapperType = AppDynamicWrapperType;
    entity = null;
    isLoading = false;
    isSaving = false;
    formData = {
        description: null,
        dueDate: null,
        initiatorContactId: null,
        tags: [],
        title: null,
        workflowDocumentNumber: null,
        workflowLetterNumber: null,
        workflowNotes: null,
        workflowReceivedDate: null
    };
    id;
    get isFormValid() {
        return Boolean(this.formData.initiatorContactId);
    }
    get contactDisplay() {
        return this.$contactDisplay(this.entity?.initiatorContact);
    }
    async fetchEntity() {
        this.isLoading = true;
        try {
            const { data } = await this.fetchEntityQuery();
            this.entity = data.cosmicDocumentWorkflowById;
            this.mapToFormData(this.entity);
            this.isLoading = false;
        }
        catch (err) {
            this.$errorUtility({ err });
            this.isLoading = false;
        }
    }
    async onClickOfSave() {
        this.isSaving = true;
        try {
            await this.saveEntityQuery();
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: `${DocumentTypeDisplay.get(DocumentType.C_WORKFLOW)} saved.`
            });
            this.$message('reload_mini');
            this.isSaving = false;
        }
        catch (err) {
            this.$errorUtility({ err });
            this.isSaving = false;
        }
    }
    fetchEntityQuery() {
        const query = gql `
      ${CosmicContactSlimFragment}

      fragment WorkflowDocumentBase on CosmicDocumentWorkflow {
        computedStatus
        description
        dueDate
        id
        initiatorContactId
        nodeId
        tags
        title
        workflowDocumentNumber
        workflowLetterNumber
        workflowNotes
        workflowReceivedDate

        initiatorContact {
          ...CosmicContactSlim
        }
      }

      query FetchWorkflowDocumentBase($id: UUID!) {
        cosmicDocumentWorkflowById(id: $id) {
          ...WorkflowDocumentBase
        }
      }
    `;
        const variables = { id: this.id };
        return this.$apollo.query({ query, variables });
    }
    async saveEntityQuery() {
        const mutation = gql `
      mutation UpdateWorkflowDocument($id: UUID!, $patch: CosmicDocumentWorkflowPatch!) {
        updateCosmicDocumentWorkflowById(input: { id: $id, patch: $patch }) {
          clientMutationId
        }
      }
    `;
        const variables = {
            id: this.id,
            patch: this.formData
        };
        return this.$apollo.mutate({ mutation, variables });
    }
    mapToFormData(entity) {
        this.formData = {
            description: entity.description,
            dueDate: entity.dueDate,
            initiatorContactId: entity.initiatorContactId,
            tags: (entity.tags || []),
            title: entity.title,
            workflowDocumentNumber: entity.workflowDocumentNumber,
            workflowLetterNumber: entity.workflowLetterNumber,
            workflowNotes: entity.workflowNotes,
            workflowReceivedDate: entity.workflowReceivedDate
        };
    }
};
__decorate([
    Prop({
        required: true,
        type: String
    }),
    __metadata("design:type", Object)
], WorkflowManageBaseDetails.prototype, "id", void 0);
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], WorkflowManageBaseDetails.prototype, "fetchEntity", null);
WorkflowManageBaseDetails = __decorate([
    Component
], WorkflowManageBaseDetails);
export default WorkflowManageBaseDetails;
