var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.HARDWARE_LIST,
      "modal-width": _vm.computedModalWidth,
      "hide-title-section": ""
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.emitModalClose.apply(null, arguments);
      },
      "modal-close": _vm.emitModalClose
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [!_vm.hardwareListAssembly.inventoryId ? _c('Alert', {
          attrs: {
            "type": "error"
          }
        }, [_vm._v(" You must first \"actualize\" this piece of hardware before assigning it to a Critical Inventory list. ")]) : _c('div', {
          staticClass: "container grid-list-lg fluid pa-2"
        }, [!_vm.hasCriticalInventoryTrackingHardwareLists ? _c('Alert', {
          attrs: {
            "type": "error"
          }
        }, [_vm._v(" There doesn't appear to be any Hardware Lists of type \"Critical Inventory\". "), _c('div', [_c('ButtonGeneric', {
          attrs: {
            "to": "/hardware/lists/create",
            "icon": _vm.$icons[_vm.EntityType.HARDWARE_LIST],
            "color": _vm.EntityType.HARDWARE_LIST
          }
        }, [_vm._v(" Create one? ")])], 1)]) : _c('div', [_c('AppPageDescription', {
          attrs: {
            "color": "info"
          }
        }, [_vm._v(" Here you can select which Critical Inventory list you would like to copy this hardware to. ")]), _c('AppSelect', {
          staticClass: "hardware-grouping-selector",
          attrs: {
            "chips": false,
            "select-options": _vm.criticalInventorySelectOptions,
            "label": "Critical Inventory lists",
            "clearable": ""
          },
          model: {
            value: _vm.selectedCriticalInventoryHardwareListId,
            callback: function ($$v) {
              _vm.selectedCriticalInventoryHardwareListId = $$v;
            },
            expression: "selectedCriticalInventoryHardwareListId"
          }
        }), _c('v-slide-x-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [_vm.selectedCriticalInventoryHardwareListId ? _c('AppHardwareListAssemblies', {
          attrs: {
            "hardware-list-id": _vm.selectedCriticalInventoryHardwareListId,
            "read-only": ""
          }
        }) : _vm._e()], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonCreate', {
          attrs: {
            "disabled": !_vm.selectedCriticalInventoryHardwareListId,
            "is-saving": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfCopyToCriticalInventory
          }
        }, [_vm._v(" Copy to Critical inventory ")]), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        })];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };