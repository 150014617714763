var _a, _b, _c;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
import BaseModalWithUser from '~/nasa_ui/base/BaseModalWithUser';
import { AlertType, EntityType } from '~/nasa_ui/types';
import { AlternateType } from '~/nasa_ui/types/enums/item-master';
import { UserPermission } from '~/nasa_ui/types/enums/user';
let ModalInventoryAlternate = class ModalInventoryAlternate extends BaseModalWithUser {
    allAlternates;
    shouldShowModalInventoryAlternate;
    queryResult;
    selectedAlternate;
    AlternateType = AlternateType;
    altType = AlternateType.PREFERRED;
    isSaving = false;
    isRemoving = false;
    shouldShowConfirmRemoveAlternateDialog = false;
    selectedAlternateCopy = null;
    selectedAlternateType = null;
    get isSelectedAlternateAlreadyInAllAlternates() {
        return Boolean(this.allAlternates?.find((alternate) => {
            return (alternate.drawingNumber === this.selectedAlternateCopy?.drawingNumber &&
                alternate.asBuiltNumber === this.selectedAlternateCopy?.asBuiltNumber &&
                alternate.type === this.selectedAlternateType);
        }));
    }
    get canEditAlternates() {
        return this.currentUserHasPermission(UserPermission.ITEM_MASTER_ALTERNATES);
    }
    get existingValuesForItemMasterAlternateSearch() {
        return this.queryResult?.itemMaster ? [this.queryResult.itemMaster] : [];
    }
    get isEditingAlternate() {
        return Boolean(this.selectedAlternate?.nodeId);
    }
    get isFormValid() {
        return Boolean(this.selectedAlternateCopy);
    }
    get modalTitle() {
        return this.isEditingAlternate ? 'Edit Alternate' : 'Create Alternate';
    }
    get shouldDisableSaveButton() {
        return (!this.selectedAlternateType ||
            !this.selectedAlternateCopy?.nodeId ||
            this.isSelectedAlternateAlreadyInAllAlternates ||
            this.isSaving ||
            this.isRemoving);
    }
    EntityType = EntityType;
    hideModal() {
        this.$emit('update:shouldShowModalInventoryAlternate', false);
    }
    resetData() {
        this.selectedAlternateCopy = null;
        this.selectedAlternateType = null;
    }
    async removeAlternate() {
        try {
            this.isRemoving = true;
            const resp = await this.$apollo.mutate({
                mutation: gql `
          mutation deleteItemMastersAlternativeById($input: DeleteItemMastersAlternativeByIdInput!) {
            deleteItemMastersAlternativeById(input: $input) {
              clientMutationId
            }
          }
        `,
                variables: {
                    input: { id: this.selectedAlternateCopy?.id }
                }
            });
            if (resp.errors && !resp.data) {
                throw resp.errors;
            }
            this.isRemoving = false;
            this.selectedAlternateCopy = null;
            this.$emit('update:shouldShowModalInventoryAlternate', false);
            this.$emit('submit-alternate-change');
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Inventory alternate deleted.'
            });
        }
        catch (err) {
            this.isRemoving = false;
            this.$errorUtility({ err });
        }
        this.shouldShowConfirmRemoveAlternateDialog = false;
    }
    async saveAlternate() {
        if (!this.queryResult || !this.selectedAlternateCopy?.nodeId || !this.queryResult.itemMaster) {
            return;
        }
        const input = {
            itemMastersAlternative: {
                isForTraining: this.selectedAlternateType === AlternateType.TRAINING_PREFERRED ||
                    this.selectedAlternateType === AlternateType.TRAINING_ALTERNATE,
                parentItemMaster: this.selectedAlternateType === AlternateType.ALTERNATE ||
                    this.selectedAlternateType === AlternateType.TRAINING_ALTERNATE
                    ? this.queryResult.itemMaster.nodeId
                    : this.selectedAlternateCopy.nodeId,
                itemMaster: this.selectedAlternateType === AlternateType.PREFERRED ||
                    this.selectedAlternateType === AlternateType.TRAINING_PREFERRED
                    ? this.queryResult.itemMaster.nodeId
                    : this.selectedAlternateCopy.nodeId
            }
        };
        try {
            this.isSaving = true;
            const resp = await this.$apollo.mutate({
                mutation: gql `
          mutation createItemMastersAlternative($input: CreateItemMastersAlternativeInput!) {
            createItemMastersAlternative(input: $input) {
              itemMastersAlternative {
                id
                isForTraining
                side
                parentItemMaster {
                  nodeId
                  drawingNumber
                  asBuiltNumber
                  side
                  initializationStatus
                  inventoryQuantity
                  installedQuantity
                  availableQuantity
                  inventoryQuantityCrossContext {
                    supportOrganizationCode
                    quantity
                  }
                }
                itemMaster {
                  nodeId
                  drawingNumber
                  asBuiltNumber
                  side
                  initializationStatus
                  inventoryQuantity
                  installedQuantity
                  availableQuantity
                  inventoryQuantityCrossContext {
                    supportOrganizationCode
                    quantity
                  }
                }
              }
            }
          }
        `,
                variables: {
                    input
                }
            });
            if (resp?.data?.createItemMastersAlternative?.itemMastersAlternative) {
                this.isSaving = false;
                this.resetData();
                this.$emit('update:shouldShowModalInventoryAlternate', false);
                this.$emit('submit-alternate-change');
                this.$notification.add({
                    type: AlertType.SUCCESS,
                    text: 'Inventory alternate created.'
                });
                return;
            }
        }
        catch (err) {
            this.isSaving = false;
            this.$emit('update:shouldShowModalInventoryAlternate', false);
            this.$errorUtility({ err });
        }
    }
    async updateAlternate() {
        if (!this.queryResult || !this.selectedAlternate?.nodeId || !this.queryResult.itemMaster) {
            return;
        }
        const input = {
            patch: {
                isForTraining: this.selectedAlternateType === AlternateType.TRAINING_PREFERRED ||
                    this.selectedAlternateType === AlternateType.TRAINING_ALTERNATE,
                parentItemMaster: this.selectedAlternateType === AlternateType.ALTERNATE ||
                    this.selectedAlternateType === AlternateType.TRAINING_ALTERNATE
                    ? this.queryResult.itemMaster.nodeId
                    : this.selectedAlternate?.nodeId,
                itemMaster: this.selectedAlternateType === AlternateType.PREFERRED ||
                    this.selectedAlternateType === AlternateType.TRAINING_PREFERRED
                    ? this.queryResult.itemMaster.nodeId
                    : this.selectedAlternate?.nodeId
            },
            // using the selectedAlternate id in case user inline clears mini and resets the copy
            // then searches for new IM
            id: this.selectedAlternate?.id
        };
        try {
            this.isSaving = true;
            const resp = await this.$apollo.mutate({
                mutation: gql `
          mutation UpdateItemMastersAlternativeByIdOnTabItemMasterInventory(
            $input: UpdateItemMastersAlternativeByIdInput!
          ) {
            updateItemMastersAlternativeById(input: $input) {
              itemMastersAlternative {
                id
                isForTraining
                side
                parentItemMaster {
                  nodeId
                  drawingNumber
                  asBuiltNumber
                  side
                  initializationStatus
                  inventoryQuantity
                  installedQuantity
                  availableQuantity
                  inventoryQuantityCrossContext {
                    supportOrganizationCode
                    quantity
                  }
                }
                itemMaster {
                  nodeId
                  drawingNumber
                  asBuiltNumber
                  side
                  initializationStatus
                  inventoryQuantity
                  installedQuantity
                  availableQuantity
                  inventoryQuantityCrossContext {
                    supportOrganizationCode
                    quantity
                  }
                }
              }
            }
          }
        `,
                variables: {
                    input
                }
            });
            if (resp?.data?.updateItemMastersAlternativeById?.itemMastersAlternative) {
                this.isSaving = false;
                this.$emit('update:shouldShowModalInventoryAlternate', false);
                this.$emit('submit-alternate-change');
                this.$notification.add({
                    type: AlertType.SUCCESS,
                    text: 'Inventory alternate updated.'
                });
                return;
            }
            this.isSaving = false;
            this.resetData();
            this.$emit('update:shouldShowModalInventoryAlternate', false);
        }
        catch (err) {
            this.$errorUtility({ err });
        }
    }
    onClickOfCancelEdit() {
        this.$emit('update:shouldShowModalInventoryAlternate', false);
        this.resetData();
    }
    onClickOfRemoveAlternate() {
        this.shouldShowConfirmRemoveAlternateDialog = true;
    }
    onClickOfSaveAlternate() {
        if (this.isEditingAlternate) {
            this.updateAlternate();
        }
        else {
            this.saveAlternate();
        }
    }
    onConfirmRemoveAlternate() {
        this.removeAlternate();
    }
    onShouldShowModalInventoryAlternateChanged(newValue, oldValue) {
        this.selectedAlternateCopy = JSON.parse(JSON.stringify(this.selectedAlternate));
    }
};
__decorate([
    Prop({
        type: Array,
        default: null
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], ModalInventoryAlternate.prototype, "allAlternates", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], ModalInventoryAlternate.prototype, "shouldShowModalInventoryAlternate", void 0);
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object)
], ModalInventoryAlternate.prototype, "queryResult", void 0);
__decorate([
    Prop({
        type: Object,
        default: null
    }),
    __metadata("design:type", typeof (_c = typeof Maybe !== "undefined" && Maybe) === "function" ? _c : Object)
], ModalInventoryAlternate.prototype, "selectedAlternate", void 0);
__decorate([
    Watch('shouldShowModalInventoryAlternate', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String, String]),
    __metadata("design:returntype", void 0)
], ModalInventoryAlternate.prototype, "onShouldShowModalInventoryAlternateChanged", null);
ModalInventoryAlternate = __decorate([
    Component
], ModalInventoryAlternate);
export default ModalInventoryAlternate;
