var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.HARDWARE_LIST,
      "hide-title-section": ""
    },
    on: {
      "modal-close": _vm.emitModalClose
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('AppPageDescription', {
          attrs: {
            "color": "info"
          }
        }, [_c('p', [_vm._v(" A Hardware List is a general collection of Inventory. Each item in the Hardware List can represent a single piece of hardware. Here, you are creating a \"bucket\" to place your hardware into. ")]), _c('p', [_vm._v("Hardware Lists must be unique by "), _c('strong', [_vm._v("Number")]), _vm._v(" and "), _c('strong', [_vm._v("Type")]), _vm._v(".")])])], 1), _vm.eventId ? _c('v-flex', {
          attrs: {
            "xs12": "",
            "mb-3": ""
          }
        }, [_c('MiniEvent', {
          staticClass: "my-3",
          attrs: {
            "id": _vm.eventId,
            "title": "Assigning to Event"
          }
        })], 1) : _vm._e(), _c('v-flex', {
          attrs: {
            "xs3": "",
            "pr-3": ""
          }
        }, [_c('AppSelect', {
          attrs: {
            "autocomplete": "",
            "chips": false,
            "select-options": _vm.filteredHardwareListTypes,
            "autofocus": "",
            "label": "List type",
            "required": ""
          },
          model: {
            value: _vm.formData.subType,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "subType", $$v);
            },
            expression: "formData.subType"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs3": "",
            "pr-3": ""
          }
        }, [_c('c-text', {
          attrs: {
            "label": "List name",
            "c-value": _vm.formData.name
          },
          on: {
            "c-input": function ($event) {
              _vm.formData.name = $event.detail;
            }
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs3": "",
            "pr-3": ""
          }
        }, [_c('c-text', {
          attrs: {
            "error-messages": _vm.hardwareListNumberIsUniqueErrorMessage,
            "prefix": _vm.numberPrefix,
            "label": "List number",
            "required": "",
            "c-value": _vm.formData.number
          },
          on: {
            "c-input": function ($event) {
              _vm.formData.number = $event.detail;
            }
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs3": ""
          }
        }, [_c('AppCheckbox', {
          attrs: {
            "label": "Enforce naming standard"
          },
          model: {
            value: _vm.enforceNamingStandard,
            callback: function ($$v) {
              _vm.enforceNamingStandard = $$v;
            },
            expression: "enforceNamingStandard"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs3": ""
          }
        }, [_c('c-text', {
          attrs: {
            "label": "Authorizer",
            "c-value": _vm.formData.authorizer
          },
          on: {
            "c-input": function ($event) {
              _vm.formData.authorizer = $event.detail;
            }
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs3": ""
          }
        }, [_c('AppCheckbox', {
          attrs: {
            "label": "Will this list contain Hardware that is considered 'critical'?",
            "tooltip": "This allows the system to track this Hardware List in a special manner.",
            "tooltip-position": "right"
          },
          model: {
            value: _vm.formData.isCritical,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "isCritical", $$v);
            },
            expression: "formData.isCritical"
          }
        }), _c('v-slide-x-transition', {
          attrs: {
            "group": ""
          }
        }, [_vm.formData.isCritical ? _c('c-icon-fa', {
          key: "iscritical_icon",
          attrs: {
            "icon": _vm.$icons.CRITICAL_INVENTORY_TRACKING,
            "icon-color": "var(--v-hardware_lists-base)"
          }
        }) : _vm._e(), _vm.formData.isCritical ? _c('small', {
          key: "iscritical_word",
          staticClass: "ml-2 font-weight-bold"
        }, [_vm._v("CRITICAL")]) : _vm._e()], 1)], 1)], 1)], 1)], 1), _vm.hardwareListTemplateId ? _c('v-layout', {
          attrs: {
            "row": "",
            "wrap": "",
            "mb-3": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('MiniHardwareListTemplate', {
          attrs: {
            "title": "Creating Hardware List from this Template",
            "id": _vm.hardwareListTemplateId,
            "inlineClear": ""
          },
          on: {
            "inlineClear": function ($event) {
              _vm.selectedHardwareListTemplate = null;
            }
          }
        })], 1)], 1) : _vm._e()];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonCreate', {
          attrs: {
            "disabled": !_vm.isFormValid,
            "is-saving": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfCreate
          }
        }), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        })];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };