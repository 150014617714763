import { __decorate, __metadata } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
let TabAdminErrors = class TabAdminErrors extends Vue {
    onShowingLogsTab(hash) {
        if (hash.includes('Errors')) {
            this.$message('fetch_error_log');
        }
    }
};
__decorate([
    Watch('$route.hash', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], TabAdminErrors.prototype, "onShowingLogsTab", null);
TabAdminErrors = __decorate([
    Component
], TabAdminErrors);
export default TabAdminErrors;
