import { __decorate } from "tslib";
import gql from 'graphql-tag';
import { Component, Mixins } from 'vue-property-decorator';
import LoaderMixin from '~/nasa_ui/mixins/LoaderMixin';
import { mutationFactory, queryFactory } from '../base/mixinFactories';
import { CosmicCommentSlimFragment } from '../comment/slim';
import { CosmicContactSlimFragment } from '../contact/slim';
import { CosmicStepSlimFragment } from '../document/WorkOrderStep';
import { CosmicLocationSlimFragment } from '../location/slim';
import { CosmicUserNoSupportNoPermsFragment } from '../user';
import { CosmicDocumentSlimFragment } from './slim';
export const CosmicFracasFragment = gql `
  ${CosmicLocationSlimFragment}
  ${CosmicContactSlimFragment}

  fragment CosmicFracas on CosmicDocumentFracas {
    computedStatus
    correctiveActionContactId
    correctiveActionDescription
    correctiveActionEffectivityDate
    description
    failureEnvironment
    failureLocationId
    failureOccurredOnOrbit
    failurePhase
    id
    initiatorContactId
    isTrending
    nodeId
    overrideDiscrepancyReportIncidentDate
    overrideFailureLocation
    scoring
    softwareVersion
    subSystem
    tags
    title

    correctiveActionContact {
      ...CosmicContactSlim
    }

    initiatorContact {
      ...CosmicContactSlim
    }

    failureLocation {
      ...CosmicLocationSlim
    }

    technicianContact {
      ...CosmicContactSlim
    }
  }
`;
export const CosmicDocumentWorkflowNoStepsFragment = gql `
  ${CosmicContactSlimFragment}
  ${CosmicUserNoSupportNoPermsFragment}

  fragment CosmicDocumentWorkflowNoSteps on CosmicDocumentWorkflow {
    attributes
    computedCloseDate
    computedStartDate
    computedStatus
    createdByUserId
    createdDateTime
    description
    dueDate
    id
    initializationStatus
    initiatorContactId
    nodeId
    number
    subType
    title
    workflowDocumentNumber
    workflowLetterNumber
    workflowNotes
    workflowReceivedDate

    createdByUser {
      ...CosmicUserNoSupportNoPerms
    }

    initiatorContact {
      ...CosmicContactSlim
    }
  }
`;
export const CosmicDocumentWorkflowFragment = gql `
  ${CosmicContactSlimFragment}
  ${CosmicDocumentSlimFragment}
  ${CosmicStepSlimFragment}
  ${CosmicUserNoSupportNoPermsFragment}

  fragment CosmicDocumentWorkflow on CosmicDocumentWorkflow {
    attributes
    computedCloseDate
    computedStartDate
    computedStatus
    createdByUserId
    createdDateTime
    description
    dueDate
    id
    initializationStatus
    initiatorContactId
    isEditable
    isExportControlled
    isProprietary
    nodeId
    number
    subType
    tags
    title
    workflowDocumentNumber
    workflowLetterNumber
    workflowNotes
    workflowReceivedDate

    createdByUser {
      ...CosmicUserNoSupportNoPerms
    }

    document {
      ...CosmicDocumentSlim

      changeSets {
        nodes {
          id
          subType
          status {
            subType
            status
          }
        }
      }
    }

    initiatorContact {
      ...CosmicContactSlim
    }

    workOrderSteps(orderBy: STEP_NUMBER_ASC) {
      nodes {
        ...CosmicStepSlim
      }
    }
  }
`;
export const CosmicDocumentRcaNoStepsFragment = gql `
  ${CosmicContactSlimFragment}
  ${CosmicUserNoSupportNoPermsFragment}

  fragment CosmicDocumentRcaNoSteps on CosmicDocumentRca {
    attributes
    awardFee
    awardFeeWriteUpRequired
    computedCloseDate
    computedStartDate
    computedStatus
    containment
    createdByUserId
    createdDateTime
    description
    id
    initializationStatus
    initiatedByNasa
    initiatorContactId
    nodeId
    number
    processEscape
    rcaOwnerContactId
    requiresNasaAwareness
    rootCause
    rootCauseCategory
    subType
    technicalPocContactId
    title
    verification

    createdByUser {
      ...CosmicUserNoSupportNoPerms
    }

    initiatorContact {
      ...CosmicContactSlim
    }

    nasaPocContact {
      ...CosmicContactSlim
    }

    rcaOwnerContact {
      ...CosmicContactSlim
    }

    technicalPocContact {
      ...CosmicContactSlim
    }
  }
`;
export const CosmicDocumentRcaFragment = gql `
  ${CosmicCommentSlimFragment}
  ${CosmicContactSlimFragment}
  ${CosmicDocumentSlimFragment}
  ${CosmicUserNoSupportNoPermsFragment}

  fragment CosmicDocumentRca on CosmicDocumentRca {
    attributes
    awardFee
    awardFeeWriteUpRequired
    baseLocationOrganizationCode
    computedCloseDate
    computedStartDate
    computedStatus
    containment
    createdByUserId
    createdDateTime
    description
    id
    initializationStatus
    initiatedByNasa
    initiatorContactId
    isExportControlled
    isEditable
    isProprietary
    iptAssignedGroupCode
    nasaPocContactId
    nodeId
    number
    overrideCloseDate
    overrideOpenDate
    processEscape
    rcaOwnerContactId
    requiresNasaAwareness
    riskScenario
    riskInitialLikelihood
    riskInitialConsequence
    riskFinalLikelihood
    riskFinalConsequence
    rootCause
    rootCauseCategory
    subType
    tags
    technicalPocContactId
    title
    verification

    createdByUser {
      ...CosmicUserNoSupportNoPerms
    }

    document {
      ...CosmicDocumentSlim
      comments {
        nodes {
          ...CosmicCommentSlim
        }
      }
    }

    initiatorContact {
      ...CosmicContactSlim
    }

    nasaPocContact {
      ...CosmicContactSlim
    }

    rcaOwnerContact {
      ...CosmicContactSlim
    }

    technicalPocContact {
      ...CosmicContactSlim
    }

    workOrderSteps {
      nodes {
        id
      }
    }
  }
`;
export const GetWorkflowById = gql `
  ${CosmicDocumentWorkflowFragment}

  query GetWorkflowById($id: UUID!) {
    cosmicDocumentWorkflowById(id: $id) {
      ...CosmicDocumentWorkflow
    }
  }
`;
export const DeleteActionById = gql `
  mutation DeleteActionById($input: DeleteCosmicActionByIdInput!) {
    deleteCosmicActionById(input: $input) {
      clientMutationId
    }
  }
`;
let CosmicManagedDocumentRepoMixin = class CosmicManagedDocumentRepoMixin extends Mixins(LoaderMixin) {
    getWorkflowById = queryFactory(this, GetWorkflowById);
    deleteActionById = mutationFactory(this, DeleteActionById);
};
CosmicManagedDocumentRepoMixin = __decorate([
    Component
], CosmicManagedDocumentRepoMixin);
export { CosmicManagedDocumentRepoMixin };
