/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./AppRiskReportabilityDisplay.vue?vue&type=template&id=585d4821&scoped=true"
import script from "./AppRiskReportabilityDisplay.ts?vue&type=script&lang=ts&external"
export * from "./AppRiskReportabilityDisplay.ts?vue&type=script&lang=ts&external"
import style0 from "./AppRiskReportabilityDisplay.vue?vue&type=style&index=0&id=585d4821&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "585d4821",
  null
  
)

export default component.exports