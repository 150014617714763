import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop } from 'vue-property-decorator';
import ModalCalibrationRequirementBase from '../ModalCalibrationRequirementBase/ModalCalibrationRequirementBase';
import CalibrationRequirementModalTemplate from '../ModalCalibrationRequirementBase/_calibrationRequirementModalTemplate.vue';
let ModalCalibrationRequirementEdit = class ModalCalibrationRequirementEdit extends ModalCalibrationRequirementBase {
    itemMasterNodeId;
    get calibrationRequirementPatch() {
        return {
            itemMaster: this.itemMasterNodeId,
            subType: this.value?.subType,
            number: this.value?.number,
            value: this.value?.value,
            description: this.value?.description,
            rangeType: this.value?.rangeType,
            rangeValue: this.value?.rangeValue
        };
    }
    emitCalibrationRequirementDeleted() { }
    emitCalibrationRequirementEdited() { }
    async onClickOfDelete() {
        if (this.value?.id) {
            this.isRemoving = true;
            await this.deleteEntity(this.value.id);
            this.isRemoving = false;
            this.emitModalClose();
            this.emitCalibrationRequirementDeleted();
        }
    }
    async onClickOfEdit() {
        if (this.calibrationRequirementPatch) {
            await this.editEntity(this.calibrationRequirementPatch);
            this.emitModalClose();
            this.emitCalibrationRequirementEdited();
        }
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], ModalCalibrationRequirementEdit.prototype, "itemMasterNodeId", void 0);
__decorate([
    Emit('calibrationRequirementDeleted'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalCalibrationRequirementEdit.prototype, "emitCalibrationRequirementDeleted", null);
__decorate([
    Emit('calibrationRequirementEdited'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalCalibrationRequirementEdit.prototype, "emitCalibrationRequirementEdited", null);
ModalCalibrationRequirementEdit = __decorate([
    Component({
        components: {
            CalibrationRequirementModalTemplate
        }
    })
], ModalCalibrationRequirementEdit);
export default ModalCalibrationRequirementEdit;
