var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.COMMENT,
      "hide-title-section": ""
    },
    on: {
      "modal-close": _vm.emitModalClose
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('AppFileAttachmentForm', {
          attrs: {
            "attachment": _vm.entity,
            "is-edit": "",
            "read-only": _vm.isReadOnly,
            "hide-upload": ""
          },
          model: {
            value: _vm.formData,
            callback: function ($$v) {
              _vm.formData = $$v;
            },
            expression: "formData"
          }
        })];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonSave', {
          attrs: {
            "disabled": _vm.shouldDisableSaveButton
          },
          on: {
            "click": _vm.onClickOfUpdate
          }
        }), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        }), _vm.shouldShowPreviewButton ? _c('ButtonLink', {
          staticClass: "grey lighten-2 ml-2",
          attrs: {
            "disabled": !_vm.previewHref,
            "href": _vm.previewHref,
            "loading": _vm.isLoadingPreview,
            "icon": "fad fa-file-search",
            "target": "_blank"
          }
        }, [_vm._v(" View in a new tab ")]) : _vm._e()];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };