var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_vm.hasAnyApprovalsOrRejections ? _c('div', {
    staticClass: "current-approvals"
  }, [_c('div', {
    staticClass: "mb-3"
  }, [_c('strong', [_vm._v(_vm._s(_vm.approvalRejectionHeaderText))])]), _c('WorkOrderStepComments', {
    attrs: {
      "authorities": _vm.activeAuthorities
    }
  })], 1) : _vm._e(), _vm.remainingAuthorities.length ? _c('div', {
    staticClass: "upcoming-approvals"
  }, [_vm._m(0), _vm._l(_vm.remainingAuthorities, function (authority) {
    return _c('div', {
      key: authority.id,
      staticClass: "my-2"
    }, [_c('c-icon-fa', {
      attrs: {
        "icon": "fad fa-user"
      }
    }), authority._userGroupName ? _c('span', {
      staticClass: "ml-3"
    }, [_vm._v(_vm._s(authority._userGroupName))]) : _vm._e()], 1);
  })], 2) : _vm._e(), _c('div', {
    staticClass: "mt-4"
  }, [_vm._t("actions")], 2)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('p', [_c('strong', [_vm._v("Remaining Approvals:")])]);
}];
export { render, staticRenderFns };