import { __decorate, __metadata } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import BaseVue from '~/nasa_ui/base/BaseVue';
let AppManageTabs = class AppManageTabs extends BaseVue {
    sliderColor;
};
__decorate([
    Prop({
        type: String,
        default: 'orange'
    }),
    __metadata("design:type", String)
], AppManageTabs.prototype, "sliderColor", void 0);
AppManageTabs = __decorate([
    Component
], AppManageTabs);
export default AppManageTabs;
