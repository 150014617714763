import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop } from 'vue-property-decorator';
import ModalCalibrationRequirementBase from '~/nasa_ui/components/ModalCalibrationRequirementBase/ModalCalibrationRequirementBase';
import CalibrationRequirementModalTemplate from '../ModalCalibrationRequirementBase/_calibrationRequirementModalTemplate.vue';
let ModalCalibrationRequirementCreate = class ModalCalibrationRequirementCreate extends ModalCalibrationRequirementBase {
    itemMasterNodeId;
    itemInstanceNodeId;
    get calibrationRequirementPayload() {
        if (!this.isFormValid) {
            return null;
        }
        return {
            calibrationQuota: {
                itemMaster: this.itemMasterNodeId,
                itemInstance: this.itemInstanceNodeId,
                subType: this.formData.subType,
                number: this.formData.number,
                value: this.formData.value,
                description: this.formData.description,
                rangeType: this.formData.rangeType,
                rangeValue: this.formData.rangeValue
            }
        };
    }
    emitCalibrationRequirementCreated() { }
    async onClickOfCreate() {
        await this.createEntity(this.calibrationRequirementPayload);
        this.emitModalClose();
        this.emitCalibrationRequirementCreated();
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], ModalCalibrationRequirementCreate.prototype, "itemMasterNodeId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], ModalCalibrationRequirementCreate.prototype, "itemInstanceNodeId", void 0);
__decorate([
    Emit('calibrationRequirementCreated'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalCalibrationRequirementCreate.prototype, "emitCalibrationRequirementCreated", null);
ModalCalibrationRequirementCreate = __decorate([
    Component({
        components: {
            CalibrationRequirementModalTemplate
        }
    })
], ModalCalibrationRequirementCreate);
export default ModalCalibrationRequirementCreate;
