var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": "",
      "sm6": "",
      "md4": "",
      "mb-3": ""
    }
  }, [_c('c-text', {
    attrs: {
      "color": _vm.EntityType.APPROVAL_ROUTING_TEMPLATE,
      "messages": _vm.nameFieldMessage,
      "label": "Name",
      "required": "",
      "c-value": _vm.formData.name
    },
    on: {
      "c-input": function ($event) {
        _vm.formData.name = $event.detail;
      }
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppMarkdown', {
    attrs: {
      "color": _vm.EntityType.APPROVAL_ROUTING_TEMPLATE,
      "label": "Description"
    },
    model: {
      value: _vm.formData.description,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "description", $$v);
      },
      expression: "formData.description"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "x12": ""
    }
  }, [_c('AppCosmicUserGroups', {
    attrs: {
      "read-only": false,
      "hide-save-as-art-button": ""
    },
    model: {
      value: _vm.formData.selectedGroups,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "selectedGroups", $$v);
      },
      expression: "formData.selectedGroups"
    }
  })], 1), _vm.$slots.actions ? _c('v-flex', {
    attrs: {
      "x12": ""
    }
  }, [_vm._t("actions")], 2) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };