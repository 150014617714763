var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.fromValue || _vm.toValue ? _c('div', {
    staticClass: "string_diff_viewer d-flex justify-center align-center"
  }, [_c('AppBlockquote', {
    attrs: {
      "remove-margin-bottom": "",
      "align": "center",
      "title": `From ${_vm.title}`
    }
  }, [!_vm.fromValue ? _c('span', [_vm._v(_vm._s(_vm.DEFAULT_DASH))]) : _c('span', [_vm._v(_vm._s(_vm.fromValue))])]), _c('c-icon-fa', {
    staticClass: "mx-2 text-xs-center arrow_icon",
    attrs: {
      "icon": _vm.$icons.arrow_right
    }
  }), _c('AppBlockquote', {
    attrs: {
      "remove-margin-bottom": "",
      "title": `To ${_vm.title}`,
      "align": "center"
    }
  }, [!_vm.toValue ? _c('span', [_vm._v(_vm._s(_vm.DEFAULT_DASH))]) : _c('span', [_vm._v(_vm._s(_vm.toValue))])])], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };