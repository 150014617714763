var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.formData ? _c('div', [_c('v-card', {
    staticClass: "pa-3 mb-2"
  }, [_c('AppActivityAuthority', {
    attrs: {
      "authority-data": _vm.authorityData,
      "id": _vm.id,
      "read-only": _vm.isReadOnly
    },
    on: {
      "entityEdited": _vm.fetchEntity
    }
  })], 1), _c('v-card', {
    staticClass: "pa-3"
  }, [_c('div', {
    staticClass: "ml-2"
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs6": "",
      "pr-3": ""
    }
  }, [!_vm.isReadOnly ? _c('c-text', {
    attrs: {
      "label": "Name",
      "c-value": _vm.formData.name,
      "required": ""
    },
    on: {
      "c-input": function ($event) {
        _vm.formData.name = $event.detail;
      }
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "Name",
      "value": _vm.nameDisplay
    }
  })], 1)], 1), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": "",
      "my-2": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": "",
      "pr-4": ""
    }
  }, [!_vm.isReadOnly ? _c('AppDate', {
    attrs: {
      "label": "Activity date",
      "required": ""
    },
    model: {
      value: _vm.formData.startDateTime,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "startDateTime", $$v);
      },
      expression: "formData.startDateTime"
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "Activity",
      "value": _vm.startDateDisplay
    }
  })], 1)], 1), _c('v-layout', {
    staticClass: "intervals my-2",
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md4": "",
      "pr-4": ""
    }
  }, [!_vm.isReadOnly ? _c('AppHourMinute', {
    attrs: {
      "label": "Operation time"
    },
    on: {
      "error": _vm.onFormError
    },
    model: {
      value: _vm.formData.operationTime,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "operationTime", $$v);
      },
      expression: "formData.operationTime"
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "Operation time",
      "value": _vm.operationTimeDisplay
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md4": ""
    }
  }, [!_vm.isReadOnly ? _c('AppHourMinute', {
    attrs: {
      "label": "Static time"
    },
    on: {
      "error": _vm.onFormError
    },
    model: {
      value: _vm.formData.staticTime,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "staticTime", $$v);
      },
      expression: "formData.staticTime"
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "Static time",
      "value": _vm.staticTimeDisplay
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md4": "",
      "pr-4": ""
    }
  }, [!_vm.isReadOnly ? _c('AppHourMinute', {
    attrs: {
      "label": "Crew time"
    },
    on: {
      "error": _vm.onFormError
    },
    model: {
      value: _vm.formData.crewTime,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "crewTime", $$v);
      },
      expression: "formData.crewTime"
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "Crew time",
      "value": _vm.crewTimeDisplay
    }
  })], 1)], 1), _c('v-layout', {
    attrs: {
      "my-2": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md4": "",
      "pr-4": ""
    }
  }, [!_vm.isReadOnly ? _c('AppHourMinute', {
    attrs: {
      "label": "Pressurized time"
    },
    on: {
      "error": _vm.onFormError
    },
    model: {
      value: _vm.formData.pressurizedTime,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "pressurizedTime", $$v);
      },
      expression: "formData.pressurizedTime"
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "Pressurized time",
      "value": _vm.pressurizedTimeDisplay
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md3": "",
      "pr-3": "",
      "pt-4": ""
    }
  }, [!_vm.isReadOnly ? _c('AppSelectEnum', {
    attrs: {
      "label": "Pressurization range",
      "color": _vm.EntityType.ACTIVITY,
      "enum": _vm.PressurizationRange,
      "display": _vm.PressurizationRangeDisplay,
      "chips": false
    },
    model: {
      value: _vm.formData.pressurizationRange,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "pressurizationRange", $$v);
      },
      expression: "formData.pressurizationRange"
    }
  }) : _vm._e(), _vm.formData.pressurizedTime && _vm.isReadOnly ? _c('AppBlockquote', {
    attrs: {
      "title": "Pressurization range"
    }
  }, [_vm._v(" " + _vm._s(_vm.formData.pressurizationRange ? _vm.PressurizationRangeDisplay.get(_vm.formData.pressurizationRange) || '-' : '-') + " ")]) : _vm._e()], 1)], 1), _c('v-layout', {
    attrs: {
      "my-2": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs6": "",
      "pr-4": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "no-wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": "",
      "sm6": "",
      "md3": ""
    }
  }, [!_vm.isReadOnly ? _c('AppNumber', {
    attrs: {
      "label": "Cycles"
    },
    on: {
      "error": _vm.onFormError
    },
    model: {
      value: _vm.formData.cycles,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "cycles", $$v);
      },
      expression: "formData.cycles"
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "Cycles",
      "value": _vm.formData.cycles || '-'
    }
  })], 1)], 1)], 1)], 1), _c('v-layout', {
    attrs: {
      "my-2": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs4": ""
    }
  }, [!_vm.isReadOnly ? _c('AppBoolean', {
    staticClass: "mt-0",
    attrs: {
      "label": "Is preventative maintenance?",
      "false-text": "No / Unknown",
      "hideUnknown": ""
    },
    model: {
      value: _vm.formData.isPreventativeMaintenance,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "isPreventativeMaintenance", $$v);
      },
      expression: "formData.isPreventativeMaintenance"
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "Is preventative maintenance?"
    }
  }, [_c('AppBooleanDisplay', {
    attrs: {
      "value": _vm.formData.isPreventativeMaintenance
    }
  })], 1)], 1), _c('v-flex', {
    attrs: {
      "xs4": ""
    }
  }, [!_vm.isReadOnly ? _c('AppBoolean', {
    staticClass: "mt-0",
    attrs: {
      "label": "Is manned?",
      "false-text": "No / Unknown",
      "hide-unknown": ""
    },
    model: {
      value: _vm.formData.isManned,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "isManned", $$v);
      },
      expression: "formData.isManned"
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "Is manned?"
    }
  }, [_c('AppBooleanDisplay', {
    attrs: {
      "value": _vm.formData.isManned
    }
  })], 1)], 1)], 1), _vm.failureChangeSetId ? _c('v-layout', {
    attrs: {
      "mb-3": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('MiniFailure', {
    attrs: {
      "id": _vm.failureChangeSetId,
      "title": "Associated Failure"
    }
  })], 1)], 1) : _vm._e(), !_vm.isReadOnly ? _c('v-layout', [_c('v-flex', [_c('ButtonSave', {
    staticClass: "ml-0",
    attrs: {
      "disabled": _vm.shouldDisableInformationSaveButton,
      "isSaving": _vm.isSaving
    },
    on: {
      "click": _vm.onClickOfSaveInformation
    }
  })], 1), _c('v-flex', {
    attrs: {
      "text-xs-right": ""
    }
  }, [_c('DeleteActivity', {
    attrs: {
      "id": _vm.id
    },
    on: {
      "deletionComplete": _vm.onDeletionComplete
    }
  })], 1)], 1) : _vm._e()], 1)])], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };