import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { UsState } from '~/db_types';
import BaseTab from '~/nasa_ui/base/BaseTab';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { LocationRepoMixin } from '~/nasa_ui/DAL/location';
import { EntityType } from '~/nasa_ui/types';
import { UsStateDisplay } from '~/nasa_ui/types/enums/us-states';
import { transformContactResponses } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let TabLocationsInformation = class TabLocationsInformation extends Mixins(BaseTab, LocationRepoMixin) {
    formData = null;
    ogFormData = null;
    UsState = UsState;
    UsStateDisplay = UsStateDisplay;
    selectedEntity = null;
    get contactInformation() {
        const transformedContact = this.selectedEntity && this.selectedEntity.contactId
            ? transformContactResponses([this.selectedEntity.contact])[0]
            : null;
        return transformedContact ? transformedContact._displayName : DEFAULT_DASH;
    }
    get contractDisplay() {
        return this.selectedEntity?.contractNumber || DEFAULT_DASH;
    }
    get hasContract() {
        return Boolean(this.formData?.contractNumber);
    }
    get locationContainsInventory() {
        // TODO: this should be provided by the api https://gitlab.com/mri-technologies/cosmic/api/-/issues/342
        // return Boolean(this.selectedEntity?.inventories?.length);
        return true;
    }
    get hasRequiredFields() {
        const requiredFields = ['bin', 'building'];
        return requiredFields.every((field) => {
            if (!this.formData) {
                return false;
            }
            return !!this.formData[field];
        });
    }
    get isFormValid() {
        return this.hasRequiredFields;
    }
    get isLocationArchived() {
        return Boolean(this.selectedEntity?.isArchived);
    }
    get isLocationManaged() {
        return Boolean(this.selectedEntity?.contractNumber);
    }
    // get openPiaNodeIds() {
    //   return this.$uniq(
    //     this.selectedEntity?.physicalInventoryAuditItems.nodes
    //       .map((item) => item?.physicalInventoryAudit)
    //       .filter((item) => item?.status === 'OPEN')
    //       .map((item) => item?.nodeId)
    //   );
    // }
    get shouldDisableSaveButton() {
        return !this.isFormValid || JSON.stringify(this.formData) === JSON.stringify(this.ogFormData);
    }
    created() {
        this.$listen('reload', this.fetchEntityById);
    }
    async onClickOfSave() {
        if (this.isFormValid) {
            try {
                await this.$http.put(`/locations/${this.id}`, {
                    ...this.formData
                });
                this.ogFormData = { ...this.formData };
                this.$message('reload', true);
            }
            catch (err) {
                if (err instanceof Error) {
                    this.$errorUtility({
                        err
                    });
                }
                else {
                    console.log(`Unexpected error: ${err}`);
                }
            }
        }
    }
    onContractRemoval() {
        if (!this.formData) {
            return;
        }
        this.formData.contractNumber = null;
    }
    async fetchEntityById() {
        if (!this.$route.params.id) {
            return;
        }
        this.isFetching = true;
        const resp = await this.$http.get(`/locations/${this.$route.params.id}`);
        this.selectedEntity = resp?.data;
        this.isFetching = false;
    }
    onSelectedEntityChange() {
        if (this.selectedEntity) {
            this.formData = {
                ...this.selectedEntity
            };
            this.ogFormData = { ...this.formData };
        }
    }
    // called from ui
    onLocationDeleted() {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        setTimeout(() => this.$router.push(`/${EntityType.LOCATION}`), 2000);
    }
};
__decorate([
    Watch('selectedEntity', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], TabLocationsInformation.prototype, "onSelectedEntityChange", null);
TabLocationsInformation = __decorate([
    Component
], TabLocationsInformation);
export default TabLocationsInformation;
