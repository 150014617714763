var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "container pa-1 mt-2"
  }, [_c('SearchInputContainer', {
    attrs: {
      "autofocus": "",
      "entity-type": _vm.EntityType.HARDWARE_LIST_TEMPLATE,
      "hint": "hardware list name",
      "is-loading": _vm.isLoading,
      "placeholder": "search for hardware list templates"
    },
    on: {
      "searchButtonClicked": _vm.onClickOfSearchButton,
      "enterPressed": _vm.onClickOfSearchButton
    },
    model: {
      value: _vm.searchInput,
      callback: function ($$v) {
        _vm.searchInput = $$v;
      },
      expression: "searchInput"
    }
  }), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_c('AppLoader', {
    staticClass: "ma-0",
    attrs: {
      "color": "hardware_lists",
      "show": _vm.isLoading,
      "type": "linear"
    }
  })], 1), _vm.expandable ? _c('v-slide-y-transition', {
    attrs: {
      "tag": "table",
      "hide-on-leave": ""
    }
  }, [_vm.shouldShowTable ? _c('AppTableExpandable', {
    key: "searchResults",
    attrs: {
      "color": _vm.EntityType.HARDWARE_LIST_TEMPLATE,
      "headers": _vm.searchResultsTableHeaders,
      "items": _vm.computedSearchResults,
      "selectable-rows": ""
    },
    on: {
      "expandableRowClick": _vm.onClickOfHardwareListTemplate
    },
    scopedSlots: _vm._u([_vm.selectedHardwareListTemplate ? {
      key: "expanded-row",
      fn: function (props) {
        return [_c('ButtonGeneric', {
          staticClass: "my-3 ml-0",
          attrs: {
            "color": "primary",
            "icon": _vm.$icons['square_check']
          },
          on: {
            "click": function ($event) {
              return _vm.onClickOfSearchResult(_vm.selectedHardwareListTemplate);
            }
          }
        }, [_vm._v(" Select this Template ")]), props.item.id ? _c('MiniHardwareListTemplate', {
          attrs: {
            "id": props.item.id
          }
        }) : _vm._e()];
      }
    } : null], null, true)
  }, [_c('template', {
    slot: "title"
  }, [_vm._v(" Hardware List Templates ( "), _vm.searchResults ? _c('small', {
    staticClass: "monospace_font"
  }, [_c('strong', [_vm._v(_vm._s(_vm.searchResults.length))])]) : _vm._e(), _vm._v(" ) ")])], 2) : _vm._e()], 1) : _c('v-slide-y-transition', [_vm.shouldShowTable ? _c('AppTable', {
    key: "searchResults",
    attrs: {
      "color": _vm.EntityType.HARDWARE_LIST_TEMPLATE,
      "headers": _vm.searchResultsTableHeaders,
      "items": _vm.searchResults,
      "show-count": false,
      "title": _vm.resultTableTitle,
      "dense-rows": "",
      "selectable-rows": ""
    },
    on: {
      "rowClicked": _vm.onClickOfSearchResult
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };