import { LinkType } from '~/db_types';
import { CommentType } from './db_types/swagger_types';
import { EntityType, getEnvironment, UserPermission } from './nasa_ui/types';
import { EsocApplication } from './nasa_ui/types/cosmic/paths';
import { CosmicSystem, CosmicUserSupportContext } from './nasa_ui/types/cosmic/system';
// Defining system config which is used for example by routing
export const COSMIC_SYSTEM = CosmicSystem.ESOC;
export const COSMIC_APPLICATION = EsocApplication.RISK;
export const COSMIC_ENVIRONMENT = getEnvironment();
// Every valid support context for this application
export var UserSupportContext;
(function (UserSupportContext) {
    UserSupportContext["ESOC"] = "ESOC";
})(UserSupportContext || (UserSupportContext = {}));
// "Tracking" here means we are not the source of truth
const allTrackingDocumentTypes = [];
// Listing out, per UserSupportContext, which are valid DocumentTypes
export const UserSupportContextToValidDocumentTypes = new Map([
    [UserSupportContext.ESOC, []]
]);
// Listing out, per UserSupportContext, which are valid EntityTypes
export const UserSupportContextToValidEntityTypes = new Map([
    [
        CosmicUserSupportContext.ESOC,
        [EntityType.DOCUMENT, EntityType.ORGANIZATION, EntityType.USER_GROUP, EntityType.USER]
    ]
]);
// Listing out, per UserSupportContext, which are valid LinkTypes
export const UserSupportContextToValidLinkTypes = new Map([
    [CosmicUserSupportContext.ESOC, [LinkType.OTHER]]
]);
// Listing out, per UserSupportContext, which are valid CommentTypes
export const UserSupportContextToValidCommentTypes = new Map([
    [CosmicUserSupportContext.ESOC, [CommentType.GENERAL, CommentType.NOTE, CommentType.ORB_NOTE]]
]);
// per UserSupportContext, valid UserPermissions
const RISK_PERMISSIONS = [
    UserPermission.RISK_USER,
    UserPermission.RISK_ADMIN,
    UserPermission.ORGANIZATION_ADMIN,
    UserPermission.USER_ADMIN,
    UserPermission.USER_GROUPS_ADMIN,
    UserPermission.USER_PERMISSIONS
];
export const UserSupportContextToValidPermissions = new Map([
    [CosmicUserSupportContext.ESOC, RISK_PERMISSIONS]
]);
// export installation method for Vue so its on the Vue prototype
export default {
    install(Vue) {
        Object.defineProperties(Vue.prototype, {
            $cosmicEnvironment: { value: COSMIC_ENVIRONMENT },
            $cosmicSystem: { value: COSMIC_SYSTEM },
            $validUserSupportContexts: { value: Object.values(UserSupportContext) }
        });
    }
};
