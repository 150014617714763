/**
 * Generate a unique tempId for use in referencing items in frontend code that
 * don't otherwise have a unique identifier.
 */
export const getUniqueTempId = (list) => {
    let tempId = Math.random().toString(36).substring(2);
    while (list.find((item) => item.tempId === tempId)) {
        tempId = Math.random().toString(36).substring(2);
    }
    return tempId;
};
