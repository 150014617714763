var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppSelect', _vm._b({
    attrs: {
      "chips": _vm.multiple,
      "color": _vm.EntityType.LOCATION,
      "disabled": _vm.disabled,
      "label": _vm.label,
      "multiple": _vm.multiple,
      "select-options": _vm.binSelectOptions,
      "cache-items": "",
      "lazy-load": ""
    },
    scopedSlots: _vm._u([{
      key: "select-option",
      fn: function ({
        option
      }) {
        return [_c('span', {
          staticClass: "select_option"
        }, [_vm._v(" " + _vm._s(_vm.$locationDisplay(option.location, true, true)) + " "), option.location.contractNumber ? _c('AppPill', {
          staticStyle: {
            "width": "max-content"
          },
          attrs: {
            "left-side": _vm.$icons[_vm.EntityType.CONTRACT],
            "primary-color": _vm.EntityType.CONTRACT,
            "left-side-is-icon": ""
          },
          scopedSlots: _vm._u([{
            key: "left_side",
            fn: function () {
              return [_c('c-icon-fa', {
                attrs: {
                  "icon": _vm.$icons[_vm.EntityType.CONTRACT],
                  "icon-color": "white",
                  "icon-size": "16px"
                }
              })];
            },
            proxy: true
          }, {
            key: "right_side",
            fn: function () {
              return [_vm._v(" " + _vm._s(option.location.contractNumber) + " ")];
            },
            proxy: true
          }], null, true)
        }) : _vm._e()], 1)];
      }
    }]),
    model: {
      value: _vm.locationIds,
      callback: function ($$v) {
        _vm.locationIds = $$v;
      },
      expression: "locationIds"
    }
  }, 'AppSelect', _vm.$attrs, false));
};
var staticRenderFns = [];
export { render, staticRenderFns };