var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { RiskMitigationResponse, RiskMitigationTeam, RiskResponseWithRefs } from '~/db_types/swagger_types';
import BaseModalWithUser from '~/nasa_ui/base/BaseModalWithUser';
import { MitigationTeamDisplay } from '~/nasa_ui/types/models/risk';
import { getRiskColor } from '~/nasa_ui/utils';
let ModalMitigationStepEdit = class ModalMitigationStepEdit extends Mixins(BaseModalWithUser) {
    MitigationTeamDisplay = MitigationTeamDisplay;
    RiskMitigationTeam = RiskMitigationTeam;
    likelihoodIsValid = true;
    consequenceIsValid = true;
    formData = {
        closedDate: null,
        comment: null,
        description: null,
        exitCriteria: null,
        impact: null,
        isCompleted: false,
        projectedCloseDate: null,
        projectedConsequence: 1,
        projectedLikelihood: 1,
        riskConsequence: null,
        riskLikelihood: null,
        sequence: 1,
        startDate: null,
        team: null
    };
    riskId;
    mitigationStep;
    get isFormValid() {
        return this.formData.isCompleted ? Boolean(this.formData.closedDate) && Boolean(this.formData.exitCriteria) : true;
    }
    get riskScore() {
        const total = (this.formData.projectedLikelihood || 1) * (this.formData.projectedConsequence || 1);
        return this.likelihoodIsValid && this.consequenceIsValid ? total : '-';
    }
    get riskScoreClass() {
        if (!this.likelihoodIsValid || !this.consequenceIsValid) {
            return 'risk-score monospace_font';
        }
        const riskColor = this.likelihoodIsValid && this.consequenceIsValid
            ? getRiskColor(this.formData.projectedLikelihood || 1, this.formData.projectedConsequence || 1)
            : '';
        return `${riskColor}--text text--darken-2 risk-score monospace_font`;
    }
    emitMitigationStepEdited(val) {
        return val;
    }
    async onClickOfEdit() {
        try {
            const mitigationPayload = {
                closedDate: this.formData.closedDate,
                comment: this.formData.comment,
                description: this.formData.description,
                exitCriteria: this.formData.exitCriteria,
                impact: this.formData.impact,
                isCompleted: this.formData.isCompleted,
                projectedCloseDate: this.formData.projectedCloseDate,
                projectedConsequence: this.formData.projectedConsequence,
                projectedLikelihood: this.formData.projectedLikelihood,
                riskConsequence: this.formData.isCompleted ? this.formData.projectedConsequence : null,
                riskLikelihood: this.formData.isCompleted ? this.formData.projectedLikelihood : null,
                sequence: this.formData.sequence,
                startDate: this.formData.startDate,
                team: this.formData.team
            };
            if (this.mitigationStep.isCompleted) {
                mitigationPayload.riskConsequence = null;
                mitigationPayload.riskLikelihood = null;
            }
            const resp = await this.$http.put(`/risks/${this.riskId}/mitigations/${this.mitigationStep.id}`, mitigationPayload);
            this.emitModalClose();
            this.$notification.add({
                type: this.AlertType.SUCCESS,
                text: 'Updated.'
            });
            this.emitMitigationStepEdited(resp);
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    onConsequenceError(hasError) {
        this.consequenceIsValid = !hasError;
    }
    onLikelihoodError(hasError) {
        this.likelihoodIsValid = !hasError;
    }
    // set formdata when we get a mitigation step
    onChangeOfMitigationStep() {
        this.formData = {
            comment: null,
            closedDate: this.mitigationStep.closedDate,
            description: this.mitigationStep.description || '',
            exitCriteria: this.mitigationStep.exitCriteria,
            impact: this.mitigationStep.impact,
            isCompleted: this.mitigationStep.isCompleted,
            projectedCloseDate: this.mitigationStep.projectedCloseDate,
            projectedConsequence: this.mitigationStep.projectedConsequence || 1,
            projectedLikelihood: this.mitigationStep.projectedLikelihood || 1,
            riskConsequence: this.formData.riskConsequence,
            riskLikelihood: this.formData.riskLikelihood,
            sequence: this.mitigationStep.sequence || 1,
            startDate: this.mitigationStep.startDate,
            team: this.mitigationStep.team
        };
    }
    onStatusChange() {
        if (this.formData.isCompleted) {
            this.formData.riskConsequence = this.mitigationStep.projectedConsequence;
            this.formData.riskLikelihood = this.mitigationStep.projectedLikelihood;
        }
        else {
            this.formData.riskConsequence = null;
            this.formData.riskLikelihood = null;
        }
    }
};
__decorate([
    Prop({
        type: String,
        required: true
    }),
    __metadata("design:type", String)
], ModalMitigationStepEdit.prototype, "riskId", void 0);
__decorate([
    Prop({
        type: Object,
        required: true
    }),
    __metadata("design:type", typeof (_a = typeof RiskMitigationResponse !== "undefined" && RiskMitigationResponse) === "function" ? _a : Object)
], ModalMitigationStepEdit.prototype, "mitigationStep", void 0);
__decorate([
    Emit('mitigation-step-edited'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof RiskResponseWithRefs !== "undefined" && RiskResponseWithRefs) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], ModalMitigationStepEdit.prototype, "emitMitigationStepEdited", null);
__decorate([
    Watch('mitigationStep', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalMitigationStepEdit.prototype, "onChangeOfMitigationStep", null);
__decorate([
    Watch('formData.status'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalMitigationStepEdit.prototype, "onStatusChange", null);
ModalMitigationStepEdit = __decorate([
    Component
], ModalMitigationStepEdit);
export default ModalMitigationStepEdit;
