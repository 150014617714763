import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
let RelatedDocumentsMixin = class RelatedDocumentsMixin extends Vue {
    hasRelatedDocuments = false;
    onHasRelatedDocuments() {
        this.hasRelatedDocuments = true;
    }
};
RelatedDocumentsMixin = __decorate([
    Component
], RelatedDocumentsMixin);
export default RelatedDocumentsMixin;
