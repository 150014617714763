var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-layout', {
    attrs: {
      "wrap": "",
      "row": ""
    }
  }, [_c('v-expansion-panel', {
    attrs: {
      "value": _vm.shouldShowTextOnly ? 0 : undefined,
      "popout": ""
    }
  }, [!_vm.shouldShowTextOnly ? _c('WorkOrderStepEditPanel', {
    attrs: {
      "header": "Warning",
      "icon": _vm.$icons.exclamation,
      "icon-color": "var(--v-warning-base)"
    }
  }, [_c('AppTextarea', {
    staticClass: "pa-3",
    attrs: {
      "color": "documents",
      "label": "Warning",
      "messages": "Used for any operation that could result in injury/death to personnel if not followed."
    },
    model: {
      value: _vm.form.warning,
      callback: function ($$v) {
        _vm.$set(_vm.form, "warning", $$v);
      },
      expression: "form.warning"
    }
  })], 1) : _vm._e(), !_vm.shouldShowTextOnly ? _c('WorkOrderStepEditPanel', {
    attrs: {
      "header": "Caution",
      "icon": _vm.$icons.warning,
      "icon-color": "var(--v-warning-darken2)"
    }
  }, [_c('AppTextarea', {
    staticClass: "pa-3",
    attrs: {
      "label": "Caution",
      "messages": "Used for any operation that could result in damage to equipment if not followed.",
      "color": "documents"
    },
    model: {
      value: _vm.form.caution,
      callback: function ($$v) {
        _vm.$set(_vm.form, "caution", $$v);
      },
      expression: "form.caution"
    }
  })], 1) : _vm._e(), !_vm.shouldShowTextOnly ? _c('WorkOrderStepEditPanel', {
    attrs: {
      "header": "Notes",
      "icon": "fad fa-info-circle"
    }
  }, [_c('AppTextarea', {
    staticClass: "pa-3",
    attrs: {
      "color": "documents",
      "label": "Notes",
      "messages": "Used to call attention to useful information that is not a specific procedural instruction."
    },
    model: {
      value: _vm.form.notes,
      callback: function ($$v) {
        _vm.$set(_vm.form, "notes", $$v);
      },
      expression: "form.notes"
    }
  })], 1) : _vm._e(), _vm.isRca ? _c('WorkOrderStepEditPanel', {
    attrs: {
      "header": "Issue",
      "icon": _vm.$icons.warning
    }
  }, [_c('c-text', {
    staticClass: "pt-2 px-4",
    attrs: {
      "color": "documents",
      "c-value": _vm.form.title
    },
    on: {
      "c-input": function ($event) {
        _vm.form.title = $event.detail;
      }
    }
  })], 1) : _vm._e(), _c('WorkOrderStepEditPanel', {
    attrs: {
      "header": _vm.isRca ? 'Solution' : 'Action',
      "icon": _vm.$icons.checklist
    }
  }, [_c('AppMarkdown', {
    staticClass: "pt-2 px-4",
    attrs: {
      "color": "documents",
      "read-only": false
    },
    model: {
      value: _vm.form.action,
      callback: function ($$v) {
        _vm.$set(_vm.form, "action", $$v);
      },
      expression: "form.action"
    }
  })], 1), _vm.isRca ? _c('WorkOrderStepEditPanel', {
    attrs: {
      "header": "Start",
      "icon": _vm.$icons.calendar
    }
  }, [_c('AppDate', {
    staticClass: "pt-2 px-4",
    attrs: {
      "color": "documents",
      "label": ""
    },
    model: {
      value: _vm.form.overrideStartDate,
      callback: function ($$v) {
        _vm.$set(_vm.form, "overrideStartDate", $$v);
      },
      expression: "form.overrideStartDate"
    }
  })], 1) : _vm._e(), _vm.isRca ? _c('WorkOrderStepEditPanel', {
    attrs: {
      "header": "Estimated completion",
      "icon": _vm.$icons.calendar
    }
  }, [_c('AppDate', {
    staticClass: "pt-2 px-4",
    attrs: {
      "color": "documents",
      "label": ""
    },
    model: {
      value: _vm.form.projectedDate,
      callback: function ($$v) {
        _vm.$set(_vm.form, "projectedDate", $$v);
      },
      expression: "form.projectedDate"
    }
  })], 1) : _vm._e(), _vm.isWorkflow ? _c('WorkOrderStepEditPanel', {
    attrs: {
      "header": "Due",
      "icon": _vm.$icons.calendar
    }
  }, [_c('AppDate', {
    staticClass: "pt-2 px-4",
    attrs: {
      "color": "documents",
      "label": ""
    },
    model: {
      value: _vm.form.projectedDate,
      callback: function ($$v) {
        _vm.$set(_vm.form, "projectedDate", $$v);
      },
      expression: "form.projectedDate"
    }
  })], 1) : _vm._e(), _vm.isRca || _vm.isWorkflow ? _c('WorkOrderStepEditPanel', {
    attrs: {
      "header": "Task assignee",
      "icon": _vm.$icons[_vm.EntityType.USER],
      "icon-color": "var(--v-users-base)"
    }
  }, [_c('div', {
    staticClass: "pt-2 px-4"
  }, [_c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [!_vm.form.assigneeContactId ? _c('Alert', {
    attrs: {
      "type": "warning"
    }
  }, [_vm._v(" " + _vm._s(_vm.$cosmicSystem) + " User / Group is still required for task approval ")]) : _vm._e()], 1), _c('AppContact', {
    attrs: {
      "inline-form": "",
      "color": "documents",
      "label": ""
    },
    model: {
      value: _vm.form.assigneeContactId,
      callback: function ($$v) {
        _vm.$set(_vm.form, "assigneeContactId", $$v);
      },
      expression: "form.assigneeContactId"
    }
  })], 1)]) : _vm._e(), _vm.isRca ? _c('WorkOrderStepEditPanel', {
    attrs: {
      "header": "Risk score at completion",
      "icon": _vm.$icons.table,
      "icon-color": "var(--v-warning-darken2)"
    }
  }, [_c('AppNumber', {
    staticClass: "pa-2 ml-3 risk-score-input",
    attrs: {
      "color": "documents",
      "min": "0",
      "max": "25"
    },
    model: {
      value: _vm.form.riskScoreAtCompletion,
      callback: function ($$v) {
        _vm.$set(_vm.form, "riskScoreAtCompletion", $$v);
      },
      expression: "form.riskScoreAtCompletion"
    }
  })], 1) : _vm._e()], 1), _c('v-flex', {
    staticClass: "mt-3",
    attrs: {
      "xs12": ""
    }
  }, [_c('ButtonSave', {
    attrs: {
      "is-saving": _vm.isSaving,
      "disabled": _vm.isSaving
    },
    on: {
      "click": _vm.onClickOfSave
    }
  }), _c('ButtonCancel', {
    on: {
      "click": _vm.cancel
    }
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };