import { __decorate, __metadata } from "tslib";
import { Component, Emit } from 'vue-property-decorator';
import { CalibrationType } from '~/db_types';
import { CalibrationTypeDisplay } from '~/nasa_ui/types/enums/hardware';
import ModalCalibrationRequirementBase from './ModalCalibrationRequirementBase';
let CalibrationRequirementModalTemplate = class CalibrationRequirementModalTemplate extends ModalCalibrationRequirementBase {
    CalibrationType = CalibrationType;
    CalibrationTypeDisplay = CalibrationTypeDisplay;
    onError(hasError) { }
    // CLICK events, override them in create/edit modal
    onClickOfCreate() { }
    onClickOfDelete() { }
    onClickOfEdit() { }
};
__decorate([
    Emit('error'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], CalibrationRequirementModalTemplate.prototype, "onError", null);
CalibrationRequirementModalTemplate = __decorate([
    Component
], CalibrationRequirementModalTemplate);
export default CalibrationRequirementModalTemplate;
