import { __decorate } from "tslib";
import { Component, Mixins } from 'vue-property-decorator';
import { ActivityType, CalibrationType } from '~/db_types';
import BaseActivityInformationForm from '~/nasa_ui/base/BaseActivityInformationForm';
import { ActivityTypeDisplay } from '~/nasa_ui/types/enums/activity';
import { CalibrationTypeDisplay } from '~/nasa_ui/types/enums/hardware';
let ActivityCalibrationForm = class ActivityCalibrationForm extends Mixins(BaseActivityInformationForm) {
    CalibrationType = CalibrationType;
    CalibrationTypeDisplay = CalibrationTypeDisplay;
    get activityTypeDisplay() {
        return ActivityTypeDisplay.has(ActivityType.CALIBRATION)
            ? ActivityTypeDisplay.get(ActivityType.CALIBRATION)
            : ActivityType.CALIBRATION;
    }
};
ActivityCalibrationForm = __decorate([
    Component
], ActivityCalibrationForm);
export default ActivityCalibrationForm;
