var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": "",
      "justify-between": ""
    }
  }, [!_vm.readOnly ? _c('v-flex', {
    staticClass: "pl-0",
    class: {
      'mb-3': _vm.$vuetify.breakpoint.smAndDown
    },
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('UserSearchPanel', {
    attrs: {
      "custom-filter": g => _vm.filterGroupOptions(g)
    },
    on: {
      "select": _vm.addGroup
    }
  })], 1) : _vm._e(), _c('v-flex', {
    staticClass: "py-0",
    class: {
      'pl-2': _vm.$vuetify.breakpoint.mdAndUp
    },
    attrs: {
      "xs12": _vm.readOnly || _vm.$vuetify.breakpoint.smAndDown,
      "md6": !_vm.readOnly && _vm.$vuetify.breakpoint.mdAndUp
    }
  }, [_c('v-card', {
    staticClass: "panel"
  }, [_c('div', {
    staticClass: "panel-options",
    class: {
      'panel-options--read-only': _vm.readOnly
    }
  }, [_c('v-slide-y-transition', [_vm.isLoadingInitialSelection ? _c('div', {
    staticClass: "pa-3 caption grey--text text--darken-2"
  }, [_vm._v(" Loading " + _vm._s(_vm.value.length) + " Users... ")]) : _vm._e(), !_vm.selected.length && !_vm.isLoadingInitialSelection ? _c('div', {
    staticClass: "pa-3 caption grey--text text--darken-2"
  }, [_c('em', [_vm._v("No Users selected")])]) : _vm._e()]), _c('v-list', {
    staticClass: "pb-0",
    class: {
      'mb-3': !_vm.readOnly
    }
  }, [_c('v-slide-y-transition', {
    attrs: {
      "group": ""
    }
  }, _vm._l(_vm.selected, function (user, i) {
    return _c('v-list-tile', {
      key: user.id,
      staticClass: "list-tile"
    }, [_c('v-list-tile-content', {
      staticClass: "body-1"
    }, [_c('v-list-tile-title', [_c('strong', [_c('small', [_vm._v(_vm._s(i + 1))]), _vm._v(".")]), _c('span', [_vm._v(" " + _vm._s(_vm.$contactDisplay(user)) + " ")])])], 1), _c('v-spacer'), !_vm.readOnly ? _c('ButtonMiniActionRemoveFromList', {
      on: {
        "click": function ($event) {
          return _vm.removeUser(user);
        }
      }
    }) : _vm._e()], 1);
  }), 1)], 1)], 1)])], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };