var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": "error",
      "hide-title-section": ""
    },
    on: {
      "modal-close": _vm.emitModalClose
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_vm.changeSet ? _c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_vm.changeSet.document && _vm.changeSet.document.initiatorContact ? _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('Alert', {
          attrs: {
            "type": "info"
          }
        }, [_vm._v(" An email will be sent to the initiator, " + _vm._s(_vm.$contactDisplay(_vm.changeSet.document.initiatorContact)) + ", upon submital for further review. ")])], 1) : _vm._e(), _c('v-flex', {
          attrs: {
            "xs12": "",
            "mb-3": ""
          }
        }, [_c('AppTable', {
          attrs: {
            "color": _vm.EntityType.CHANGE_SET,
            "headers": _vm.approvalsTableHeaders,
            "items": _vm.getApprovalTableItems(_vm.changeSet),
            "rows-per-page-items": [-1],
            "show-count": false,
            "disable-initial-sort": "",
            "hide-header": "",
            "hide-pagination": "",
            "icon": "fa fa-balance-scale"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('AppMarkdown', {
          attrs: {
            "read-only": false,
            "required": _vm.shouldRequireComment,
            "label": "Rejection comment"
          },
          model: {
            value: _vm.commentText,
            callback: function ($$v) {
              _vm.commentText = $$v;
            },
            expression: "commentText"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('AppDate', {
          attrs: {
            "color": _vm.EntityType.DOCUMENT,
            "label": "Override rejection date",
            "messages": "This will set the authorization date to noon on the date selected."
          },
          model: {
            value: _vm.authorizedDate,
            callback: function ($$v) {
              _vm.authorizedDate = $$v;
            },
            expression: "authorizedDate"
          }
        })], 1)], 1) : _vm._e()];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonGeneric', {
          attrs: {
            "disabled": _vm.shouldDisableRejectButton,
            "show-loader": _vm.isSaving,
            "color": "var(--v-error-base)",
            "icon": "fad fa-thumbs-down"
          },
          on: {
            "click": _vm.onClickOfRejectWorkOrderStep
          }
        }, [_vm._v(" " + _vm._s(_vm.rejectButtonText) + " ")]), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        })];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };