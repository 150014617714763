import { __decorate, __metadata } from "tslib";
import { Debounce } from 'lodash-decorators';
import qs from 'qs';
import { Component, Emit } from 'vue-property-decorator';
import BaseSearch from '~/nasa_ui/base/BaseSearch';
import { NAME_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import { EntityType } from '~/nasa_ui/types';
let SearchVehicle = class SearchVehicle extends BaseSearch {
    entityType = EntityType.VEHICLE;
    hint = 'Name';
    placeholder = 'search for vehicle';
    tableHeaders = [NAME_COL];
    onClickOfSearchResult(selectedItem) {
        this.reset();
        return this.isOutputModeString ? selectedItem.id : selectedItem;
    }
    async performSearch() {
        try {
            this.isLoading = true;
            const queryString = qs.stringify({
                query: this.SEARCH_QUERY,
                skip: 0,
                take: -1
            });
            // Execute query
            const response = await this.$http.get(`/${EntityType.VEHICLE}?${queryString}`);
            this.isLoading = false;
            // Set the raw response in case we need that
            this.rawSearchResponse = response.data;
            this.emitSearchPerformed();
        }
        catch (error) {
            console.log(error);
            this.$errorUtility({ err: error });
        }
    }
};
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], SearchVehicle.prototype, "onClickOfSearchResult", null);
__decorate([
    Debounce(200),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], SearchVehicle.prototype, "performSearch", null);
SearchVehicle = __decorate([
    Component
], SearchVehicle);
export default SearchVehicle;
