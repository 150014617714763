import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import ReadOnlyMixin from '../mixins/ReadOnlyMixin';
import BaseCosmic from './BaseCosmic';
let BaseTab = class BaseTab extends Mixins(BaseCosmic, ReadOnlyMixin) {
    isFetching = false;
    isSaving = false;
    id;
    nodeId;
    fetchEntity(nodeId) {
        throw new Error('Method not implemented.');
    }
    fetchEntityById(id) { }
    // todo: do weed need this method? seems weirdly specific
    onDeletionComplete() {
        this.scrollToTop();
        const path = `/`;
        setTimeout(() => this.$router.push('/'), 2000);
    }
    scrollToTop() {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }
    emitUpdate() { }
    onIdChange(id) {
        if (id) {
            this.fetchEntityById(id);
        }
    }
    onNodeIdChange(nodeId) {
        if (nodeId) {
            this.fetchEntity(nodeId);
        }
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], BaseTab.prototype, "id", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], BaseTab.prototype, "nodeId", void 0);
__decorate([
    Emit('update'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], BaseTab.prototype, "emitUpdate", null);
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], BaseTab.prototype, "onIdChange", null);
__decorate([
    Watch('nodeId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], BaseTab.prototype, "onNodeIdChange", null);
BaseTab = __decorate([
    Component
], BaseTab);
export default BaseTab;
