import { __decorate, __metadata } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import BaseButton from '~/nasa_ui/base/BaseButton';
let ButtonLock = class ButtonLock extends BaseButton {
    hideText;
    openInitially;
    showText;
    isDisabled = true;
    isLocked = true;
    get buttonText() {
        return this.isLocked ? this.showText : this.hideText;
    }
    get computedColor() {
        return this.color || 'var(--cosmic-blue-gray-base)';
    }
    get lockButtonIcon() {
        if (this.disabled) {
            return 'fas fa-ban';
        }
        return this.isDisabled ? 'fas fa-lock' : 'fas fa-unlock';
    }
    created() {
        if (this.openInitially) {
            this.isDisabled = false;
            this.isLocked = false;
        }
    }
    onClick() {
        this.isLocked = !this.isLocked;
        this.$emit('click');
    }
    reset() {
        this.isLocked = true;
        this.isDisabled = true;
    }
};
__decorate([
    Prop({
        default: 'Show',
        type: String
    }),
    __metadata("design:type", String)
], ButtonLock.prototype, "hideText", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], ButtonLock.prototype, "openInitially", void 0);
__decorate([
    Prop({
        default: 'Show',
        type: String
    }),
    __metadata("design:type", String)
], ButtonLock.prototype, "showText", void 0);
ButtonLock = __decorate([
    Component
], ButtonLock);
export default ButtonLock;
