import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import BaseButton from '~/nasa_ui/base/BaseButton';
let ButtonShareLink = class ButtonShareLink extends BaseButton {
    get computedColor() {
        return this.color || 'var(--v-primary-base)';
    }
    get computedIcon() {
        return this.icon || this.$icons.link;
    }
    get computedTextColor() {
        return this.textColor || '#fff';
    }
};
ButtonShareLink = __decorate([
    Component
], ButtonShareLink);
export default ButtonShareLink;
