import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';
import { AttachmentType } from '~/db_types';
import BaseModalWithUser from '~/nasa_ui/base/BaseModalWithUser';
import { AttachmentRepoMixin, LinkAttachmentToDocumentMutation, LinkAttachmentToItemDrawingMutation, LinkAttachmentToItemInstanceMutation, LinkAttachmentToItemMasterMutation, LinkAttachmentToWorkOrderStep } from '~/nasa_ui/DAL/attachments';
import EntityLinksMixin from '~/nasa_ui/mixins/EntityLinksMixin';
import ReadOnlyMixin from '~/nasa_ui/mixins/ReadOnlyMixin';
import { AttachmentTypeDisplay } from '~/nasa_ui/types/enums/attachments';
let ModalFileAttachmentBase = class ModalFileAttachmentBase extends Mixins(AttachmentRepoMixin, BaseModalWithUser, EntityLinksMixin, ReadOnlyMixin) {
    AttachmentType = AttachmentType;
    AttachmentTypeDisplay = AttachmentTypeDisplay;
    formData = {
        description: null,
        file: null,
        type: this.workOrderStepId ? AttachmentType.WORK_ORDER_STEP : AttachmentType.GENERAL
    };
    // NOTE: We need this prop since the mutation we call when linking an attachment to an entity sets the entire
    // array of attachment IDs. In the future if we have mutations that can ADD or DELETE a one off attachment to an entity,
    // we can remove this
    attachmentIds;
    get linkAttachmentToEntityMutation() {
        if (this.itemInstance || this.itemInstanceIds) {
            return LinkAttachmentToItemInstanceMutation;
        }
        if (this.itemMaster) {
            return LinkAttachmentToItemMasterMutation;
        }
        if (this.itemDrawing) {
            return LinkAttachmentToItemDrawingMutation;
        }
        if (this.documentId) {
            return LinkAttachmentToDocumentMutation;
        }
        if (this.workOrderStepId) {
            return LinkAttachmentToWorkOrderStep;
        }
        throw Error('Unsupported EntityType');
    }
    buildLinkAttachmentPayload(attachmentId, attachmentIds) {
        const existingAttachmentIds = attachmentIds || this.attachmentIds;
        const payload = {
            attachmentIds: attachmentId ? [attachmentId, ...existingAttachmentIds] : existingAttachmentIds
        };
        // This feels like it could be refactored at some point
        if (this.itemDrawing) {
            return { drawingNumber: this.itemDrawing.drawingNumber, ...payload };
        }
        else if (this.itemMaster) {
            return {
                asBuiltNumber: this.itemMaster.asBuiltNumber,
                drawingNumber: this.itemMaster.drawingNumber,
                side: this.itemMaster.side,
                ...payload
            };
        }
        else if (this.itemInstance) {
            return {
                itemInstanceId: this.itemInstance.id,
                ...payload
            };
        }
        else if (this.documentId) {
            return { documentId: this.documentId, ...payload };
        }
        else if (this.workOrderStepId) {
            return { workOrderStepId: this.workOrderStepId, ...payload };
        }
        // TODO: support Document Node Id?
        return null;
    }
    emitReloadAttachments() { }
    emitSaved(attachmentId) {
        return attachmentId;
    }
};
__decorate([
    Prop({
        required: false,
        default: () => [],
        type: Array
    }),
    __metadata("design:type", Array)
], ModalFileAttachmentBase.prototype, "attachmentIds", void 0);
__decorate([
    Emit('reload-attachments'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalFileAttachmentBase.prototype, "emitReloadAttachments", null);
__decorate([
    Emit('saved'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], ModalFileAttachmentBase.prototype, "emitSaved", null);
ModalFileAttachmentBase = __decorate([
    Component
], ModalFileAttachmentBase);
export default ModalFileAttachmentBase;
