var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Watch } from 'vue-property-decorator';
import colors from 'vuetify/es5/util/colors';
import BaseInput from '~/nasa_ui/base/BaseInput';
import { Maybe } from '~/nasa_ui/types';
let AppColorPicker = class AppColorPicker extends BaseInput {
    showSwatches = false;
    selectedColor = null;
    get allColors() {
        const colorNames = Object.keys(colors).slice(0, -4);
        return colorNames.map((color) => {
            return {
                ...colors[color],
                name: color
            };
        });
    }
    created() {
        this.selectedColor = this.value;
    }
    closeSwatches() {
        this.showSwatches = false;
    }
    colorSelected(color) {
        this.selectedColor = color;
        this.showSwatches = false;
    }
    onClickOfShowSwatches() {
        this.showSwatches = this.disabled ? false : true;
    }
    emitInput(color) { }
    onChangeOfValue(value) {
        if (!value) {
            this.showSwatches = true;
            return;
        }
    }
};
__decorate([
    Emit('input'),
    Watch('selectedColor'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], AppColorPicker.prototype, "emitInput", null);
__decorate([
    Watch('value', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], AppColorPicker.prototype, "onChangeOfValue", null);
AppColorPicker = __decorate([
    Component
], AppColorPicker);
export default AppColorPicker;
