var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.showBorder ? _c('AppFormWrapper', {
    staticClass: "mb-3",
    attrs: {
      "color": _vm.EntityType.ACTIVITY,
      "validate-on-load": "",
      "size": _vm.size
    }
  }, [!_vm.hideHeader ? _c('AppHeader', {
    staticClass: "mb-3"
  }, [_vm._v("Inspection")]) : _vm._e(), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    staticClass: "boolean_field_offset",
    attrs: {
      "xs5": "",
      "md8": ""
    }
  }, [_c('AppBoolean', {
    staticClass: "mt-0",
    attrs: {
      "label": "Has an Inspection been performed?",
      "trueText": "Yes",
      "falseText": "No",
      "hideUnknown": "",
      "required": ""
    },
    model: {
      value: _vm.formData.inspectionPerformed,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "inspectionPerformed", $$v);
      },
      expression: "formData.inspectionPerformed"
    }
  })], 1), _vm.formData.inspectionPerformed ? _c('v-flex', {
    attrs: {
      "xs3": "",
      "md4": ""
    }
  }, [_c('AppSelectEnum', {
    attrs: {
      "label": "Inspection result",
      "chips": false,
      "display": _vm.AnalysisResultDisplay,
      "enum": _vm.AnalysisResult,
      "color": _vm.EntityType.ACTIVITY,
      "required": _vm.formData.inspectionPerformed
    },
    model: {
      value: _vm.formData.inspectionResult,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "inspectionResult", $$v);
      },
      expression: "formData.inspectionResult"
    }
  })], 1) : _vm._e()], 1), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.shouldShowCreateDr && _vm.formData.inspectionPerformed ? _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": "",
      "mt-3": ""
    }
  }, [_c('AppFormWrapper', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "color": _vm.EntityType.DOCUMENT,
      "validate-on-load": "",
      "size": "1"
    }
  }, [_c('AppHeader', {
    staticClass: "mb-3"
  }, [_vm._v("DR creation")]), _c('v-flex', {
    staticClass: "boolean_field_offset",
    attrs: {
      "xs12": ""
    }
  }, [_c('AppBoolean', {
    attrs: {
      "label": "Is DR open against this hardware?",
      "trueText": "Yes",
      "falseText": "No",
      "hideUnknown": ""
    },
    model: {
      value: _vm.formData.openDr,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "openDr", $$v);
      },
      expression: "formData.openDr"
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.formData.openDr,
      expression: "formData.openDr"
    }],
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs6": "",
      "pr-2": "",
      "mb-3": ""
    }
  }, [_c('c-text', {
    attrs: {
      "label": "DR number",
      "required": "",
      "c-value": _vm.formData.drNumber
    },
    on: {
      "c-input": function ($event) {
        _vm.formData.drNumber = $event.detail;
      }
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs6": "",
      "pr-2": ""
    }
  }, [_c('c-text', {
    attrs: {
      "label": "DR title",
      "required": "",
      "c-value": _vm.formData.drTitle
    },
    on: {
      "c-input": function ($event) {
        _vm.formData.drTitle = $event.detail;
      }
    }
  })], 1)], 1)], 1)], 1) : _vm._e()], 1)], 1) : _c('div', {
    attrs: {
      "validate-on-load": ""
    }
  }, [!_vm.hideHeader ? _c('AppHeader', {
    staticClass: "mb-3"
  }, [_vm._v("Inspection")]) : _vm._e(), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    staticClass: "boolean_field_offset",
    attrs: {
      "xs5": "",
      "md8": ""
    }
  }, [_c('AppBoolean', {
    attrs: {
      "label": "Has an Inspection been performed?",
      "trueText": "Yes",
      "falseText": "No",
      "hideUnknown": "",
      "required": ""
    },
    model: {
      value: _vm.formData.inspectionPerformed,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "inspectionPerformed", $$v);
      },
      expression: "formData.inspectionPerformed"
    }
  })], 1), _vm.formData.inspectionPerformed ? _c('v-flex', {
    attrs: {
      "xs3": "",
      "md4": ""
    }
  }, [_c('c-select', {
    attrs: {
      "label": "Inspection result",
      "chips": false,
      "color": _vm.EntityType.ACTIVITY,
      "required": _vm.formData.inspectionPerformed
    },
    domProps: {
      "cValue": _vm.formData.inspectionResult,
      "display": _vm.AnalysisResultDisplay,
      "enum": _vm.AnalysisResult
    },
    on: {
      "c-input": function ($event) {
        _vm.formData.inspectionResult = $event.detail;
      }
    }
  })], 1) : _vm._e()], 1), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.shouldShowCreateDr && _vm.formData.inspectionPerformed ? _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": "",
      "mt-3": ""
    }
  }, [_c('AppFormWrapper', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "color": _vm.EntityType.DOCUMENT,
      "validate-on-load": "",
      "size": "1"
    }
  }, [_c('AppHeader', {
    staticClass: "mb-3"
  }, [_vm._v("DR creation")]), _c('v-flex', {
    staticClass: "boolean_field_offset",
    attrs: {
      "xs12": ""
    }
  }, [_c('AppBoolean', {
    attrs: {
      "label": "Is DR open against this hardware?",
      "trueText": "Yes",
      "falseText": "No",
      "hideUnknown": ""
    },
    model: {
      value: _vm.formData.openDr,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "openDr", $$v);
      },
      expression: "formData.openDr"
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.formData.openDr,
      expression: "formData.openDr"
    }],
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs6": "",
      "pr-2": "",
      "mb-3": ""
    }
  }, [_c('c-text', {
    attrs: {
      "label": "DR number",
      "required": "",
      "c-value": _vm.formData.drNumber
    },
    on: {
      "c-input": function ($event) {
        _vm.formData.drNumber = $event.detail;
      }
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs6": "",
      "pr-2": ""
    }
  }, [_c('c-text', {
    attrs: {
      "label": "DR title",
      "required": "",
      "c-value": _vm.formData.drTitle
    },
    on: {
      "c-input": function ($event) {
        _vm.formData.drTitle = $event.detail;
      }
    }
  })], 1)], 1)], 1)], 1) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };