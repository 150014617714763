import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Watch } from 'vue-property-decorator';
import { OrganizationType } from '~/db_types';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { OrganizationRepoMixin } from '~/nasa_ui/DAL/organization';
import { AlertType } from '~/nasa_ui/types';
import { OrganizationTypeDisplay } from '~/nasa_ui/types/enums/organization';
let ModalOrganizationCreate = class ModalOrganizationCreate extends Mixins(BaseModal, OrganizationRepoMixin) {
    OrganizationType = OrganizationType;
    OrganizationTypeDisplay = OrganizationTypeDisplay;
    formData = {
        code: '',
        subType: OrganizationType.CONTRACTOR,
        name: '',
        isCageCode: true,
        isActive: true
    };
    get hasRequiredFields() {
        return Boolean(this.formData.name && this.formData.subType && this.formData.code);
    }
    get isFormValid() {
        return this.hasRequiredFields;
    }
    get shouldDisableCreateButton() {
        return !this.isFormValid;
    }
    async onClickOfCreate() {
        try {
            const resp = await this.createOrganization({
                organization: {
                    code: this.formData.code,
                    isActive: true,
                    isCageCode: this.formData.isCageCode,
                    name: this.formData.name,
                    subType: this.formData.subType
                }
            });
            if (resp?.createOrganization?.organization) {
                this.$notification.add({
                    text: 'Organization created',
                    type: AlertType.SUCCESS
                });
                this.emitInput(resp.createOrganization.organization.code);
            }
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    emitInput(val) { }
    onNameChange() {
        this.formData.code = (this.formData.name + '')
            .split('')
            .map((char) => char.toUpperCase().replace(' ', '_'))
            .join('');
    }
};
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], ModalOrganizationCreate.prototype, "emitInput", null);
__decorate([
    Watch('formData.name'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalOrganizationCreate.prototype, "onNameChange", null);
ModalOrganizationCreate = __decorate([
    Component
], ModalOrganizationCreate);
export default ModalOrganizationCreate;
