import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CosmicChangeSetAuthorityFragment } from '~/nasa_ui/DAL/changeSet';
import { transformChangeSetAuthorities } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let BaseModalWorkOrderStepCompletion = class BaseModalWorkOrderStepCompletion extends Vue {
    changeSetFragment = gql `
    ${CosmicChangeSetAuthorityFragment}

    fragment ChangeSetOnWorkOrderStepCompletion on ChangeSet {
      id
      nodeId

      changeSetAuthorities(orderBy: ID_ASC, filter: { isHistorical: { equalTo: false } }) {
        nodes {
          ...CosmicChangeSetAuthority
        }
      }

      status {
        nextAuthority {
          ...CosmicChangeSetAuthority
        }
        nextAuthorityId
      }

      workOrderStep {
        id
        nodeId
        subType
        stepNumber
      }
    }
  `;
    shouldRequireComment;
    getApprovalTableItems(changeSet) {
        return changeSet._changeSetAuthorities;
    }
    transformWorkOrderStepChangeSet(changeSet) {
        if (!changeSet?.status) {
            return null;
        }
        const _changeSetAuthorities = transformChangeSetAuthorities(changeSet?.changeSetAuthorities.nodes || []);
        const _nextAuthority = changeSet.status.nextAuthority
            ? transformChangeSetAuthorities([changeSet.status.nextAuthority])[0]
            : null;
        return {
            _changeSetAuthorities,
            _nextAuthority,
            ...changeSet
        };
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], BaseModalWorkOrderStepCompletion.prototype, "shouldRequireComment", void 0);
BaseModalWorkOrderStepCompletion = __decorate([
    Component
], BaseModalWorkOrderStepCompletion);
export default BaseModalWorkOrderStepCompletion;
