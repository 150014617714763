import { __decorate, __metadata } from "tslib";
import { differenceInDays, getDaysInMonth } from 'date-fns';
import gql from 'graphql-tag';
import { uniq } from 'lodash';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import { CosmicContactSlimFragment } from '~/nasa_ui/DAL/contact/slim';
import CurrentUserMixin from '~/nasa_ui/mixins/CurrentUserMixin';
import { HeaderSortOptions } from '~/nasa_ui/types';
import { addDateDisplays, addUserDisplays } from '~/nasa_ui/utils/helpers/displayMappers';
let AppEmailLog = class AppEmailLog extends Mixins(BaseCosmic, CurrentUserMixin) {
    allLogs = null;
    isLoading = false;
    selectedLog = null;
    showUser;
    userId;
    overrideRowsPerPageItems;
    get rowsPerPageItems() {
        return this.overrideRowsPerPageItems
            ? this.overrideRowsPerPageItems
            : [
                100,
                {
                    text: '$vuetify.dataIterator.rowsPerPageAll',
                    value: -1
                }
            ];
    }
    get emailLogCounts() {
        return this.allLogs
            ? Object.keys(this.emailByDate).reduce((counts, logDate) => {
                if (!logDate) {
                    return counts;
                }
                const today = new Date();
                const daysInMonth = getDaysInMonth(today);
                const daysSince = differenceInDays(today, new Date(logDate));
                const loginsThatDay = this.emailByDate[logDate].map((log) => log ? log.createdByUserId : 'unknown');
                const uniqLogins = uniq(loginsThatDay);
                if (daysSince < 2) {
                    counts.day = counts.day + uniqLogins.length;
                }
                if (daysSince < 8) {
                    counts.week = counts.week + uniqLogins.length;
                }
                if (daysSince < daysInMonth) {
                    counts.month = counts.month + uniqLogins.length;
                }
                return counts;
            }, {
                day: 0,
                week: 0,
                month: 0
            })
            : null;
    }
    get emailByDate() {
        return this.allLogs
            ? this.allLogs.reduce((logins, log) => {
                if (!log) {
                    return logins;
                }
                const createdDateTime = this.$dateDisplay(log.createdDateTime) || 'unknown';
                if (!logins[createdDateTime]) {
                    logins[createdDateTime] = [];
                }
                logins[createdDateTime].push(log);
                return logins;
            }, {})
            : {};
    }
    get emailLogHeaders() {
        return [
            {
                text: 'Date',
                value: '_createdDateTime',
                noEscape: true,
                headerSortType: HeaderSortOptions.DATETIME
            },
            {
                text: 'Subject',
                value: 'subject',
                sortable: true
            },
            {
                text: 'To',
                value: 'toAddresses',
                sortable: true
            },
            {
                text: 'Sent',
                value: '_sentDateTime',
                sortable: false,
                noWrap: true
            },
            {
                text: 'Last attempt',
                value: '_lastAttemptDateTime',
                sortable: false,
                noEscape: true
            }
        ];
    }
    get mappedEmailLogs() {
        return this.allLogs
            ? this.allLogs
                .map(addDateDisplays)
                .map(addUserDisplays)
                .map((log) => {
                return Object.assign({}, log, {
                    toAddresses: log.recipientContacts.nodes.length < 4
                        ? log.recipientContacts.nodes
                            .map((contact) => `${this.$contactDisplay(contact)}(${contact.email})`)
                            .join(', ')
                        : `Sent to ${log.recipientContacts.nodes.length} people.`
                });
            })
            : [];
    }
    get paginationSettings() {
        return {
            descending: true,
            sortBy: '_createdDateTime'
        };
    }
    created() {
        this.fetchEmailLogs();
    }
    async fetchEmailLogs() {
        try {
            this.isLoading = true;
            const resp = await this.$apollo.query({
                query: gql `
          ${CosmicContactSlimFragment}

          fragment EmailNotification on Notification {
            attributes
            createdDateTime
            id
            lastAttemptDateTime
            lastFailureDateTime
            messageHtml
            messageText
            nodeId
            sentDateTime
            subject
            recipientContacts {
              totalCount
              nodes {
                ...CosmicContactSlim
              }
            }
          }

          query SelectEmailLogsForAdminsHomeView {
            notifications(first: 10000, orderBy: CREATED_DATE_TIME_DESC) {
              nodes {
                ...EmailNotification
              }
            }
          }
        `,
                fetchPolicy: 'cache-first'
            });
            this.isLoading = false;
            if (resp.data.notifications) {
                this.allLogs = resp.data.notifications.nodes;
            }
        }
        catch (error) {
            console.error(error);
        }
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: true
    }),
    __metadata("design:type", Boolean)
], AppEmailLog.prototype, "showUser", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], AppEmailLog.prototype, "userId", void 0);
__decorate([
    Prop({
        type: Array
    }),
    __metadata("design:type", Object)
], AppEmailLog.prototype, "overrideRowsPerPageItems", void 0);
AppEmailLog = __decorate([
    Component({
        components: {
            VueJsonPretty
        }
    })
], AppEmailLog);
export default AppEmailLog;
