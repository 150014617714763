import { __decorate } from "tslib";
import { Component, Mixins } from 'vue-property-decorator';
import { CosmicDocumentStatus } from '~/db_types';
import BaseCosmicDocumentTab from '~/nasa_ui/base/BaseCosmicDocumentsTab';
import BaseWorkflow from '~/nasa_ui/base/BaseWorkflow';
import PdfExportMixin from '~/nasa_ui/mixins/PdfExport';
import RelatedDocumentsMixin from '~/nasa_ui/mixins/RelatedDocumentsMixin';
let TabWorkflowInformation = class TabWorkflowInformation extends Mixins(BaseCosmicDocumentTab, BaseWorkflow, PdfExportMixin, RelatedDocumentsMixin) {
    disableStartDocumentInitializationButton = false;
    showReviseDocumentModal = false;
    showDialog = false;
    get changeSets() {
        return this.entity?.document?.changeSets.nodes || [];
    }
    // fragile
    get cloneUrl() {
        const createUrl = this.$route.path.indexOf('manage')
            ? this.$route.path.substring(0, this.$route.path.indexOf('manage')) + 'create'
            : null;
        return createUrl ? `${createUrl}?cloneFrom=${this.id}` : null;
    }
    get hasTasks() {
        return Boolean(this.entity?.workOrderSteps?.nodes?.length);
    }
    get isDocumentClosed() {
        return this.entity?.computedStatus === CosmicDocumentStatus.CLOSED;
    }
    get isWorkflowStepRejected() {
        return this.entity?.computedStatus === CosmicDocumentStatus.WORK_ORDER_STEP_REJECTED;
    }
    // called from ui
    async onClickOfReopen() {
        this.showReviseDocumentModal = true;
    }
    // called from ui
    onCloseOfReviseDocument() {
        this.showReviseDocumentModal = false;
    }
    // This is a stub only to satisfy the requirements of BaseTab
    // This component inherits BaseTab but is passed an entity as a prop
    // and doesn't need to fetch it itself
    fetchEntityById() { }
    async onClickOfExportPdf() {
        if (this.entity?.subType && this.currentUserActiveSupportContext) {
            const getLatestDocument = await this.queryForPDFExport(this.id);
            const { default: WorkflowExport } = await import('~/nasa_ui/utils/WorkflowExport');
            new WorkflowExport(getLatestDocument);
        }
    }
    async onClickOfDeleteWorkflow() {
        this.showDialog = true;
    }
    onSaveOfStartStop(isStarted) {
        this.emitRefetchEntity();
        if (isStarted) {
            const $emailButton = this.$refs.emailButton;
            if ($emailButton) {
                $emailButton.onClickOfEmailPopupActivator();
            }
        }
    }
};
TabWorkflowInformation = __decorate([
    Component
], TabWorkflowInformation);
export default TabWorkflowInformation;
