var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": "error",
      "max-height": "40vh"
    },
    on: {
      "modal-close": _vm.emitModalClose
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v("Delete " + _vm._s(_vm.documentSubTypeDisplay) + " " + _vm._s(_vm.stepDisplayText))];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('AppPageDescription', [_vm._v("All remaining "), _c('span', {
          staticClass: "text-lowercase"
        }, [_vm._v(_vm._s(_vm.stepDisplayText))]), _vm._v("s will be moved up in "), _c('span', {
          staticClass: "text-lowercase"
        }, [_vm._v(_vm._s(_vm.stepDisplayText))]), _vm._v(" order.")]), _c('AppPageDescription', {
          attrs: {
            "color": "warning"
          }
        }, [_vm._v(" Are you sure you want to delete this "), _c('span', {
          staticClass: "text-lowercase"
        }, [_vm._v(_vm._s(_vm.stepDisplayText))]), _vm._v("? ")])], 1)], 1)];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonRemove', {
          attrs: {
            "disabled": _vm.shouldDisableDeleteButton,
            "is-saving": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfDeleteWorkOrderStep
          }
        }, [_vm._v(" Delete " + _vm._s(_vm.stepDisplayText) + " ")]), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        }, [_vm._v("Cancel")])];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };