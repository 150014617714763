/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./AppViewHeader.vue?vue&type=template&id=3d63eb4e&scoped=true"
import script from "./AppViewHeader.ts?vue&type=script&lang=ts&external"
export * from "./AppViewHeader.ts?vue&type=script&lang=ts&external"
import style0 from "./AppViewHeader.vue?vue&type=style&index=0&id=3d63eb4e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d63eb4e",
  null
  
)

export default component.exports