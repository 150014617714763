/**
 * Checks two arrays of objects for matching length and order. The order of
 * objects test is determined by comparing the value of the provide key on each
 * pair of objects.
 * @param arr1 first array of objects
 * @param arr2 second array of objects
 * @param prop unique property of each object to compare
 * @returns Whether the arrays match in length and order of objects
 */
export const objectArraysMatchOrder = (arr1, arr2, prop) => {
    if (arr1.length !== arr2.length) {
        return false;
    }
    return arr1.every((obj, i) => obj[prop] === arr2[i][prop]);
};
