var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    staticStyle: {
      "flex-grow": "0"
    }
  }, [_c('label', {
    staticClass: "d-block",
    attrs: {
      "for": "file"
    }
  }, [_c('ButtonGeneric', {
    attrs: {
      "disabled": _vm.disabled,
      "icon": "fad fa-file-search"
    },
    on: {
      "click": _vm.onClickOfFileSelect
    }
  }, [_vm._v(" Choose a file to upload ")])], 1), _c('input', _vm._b({
    ref: "fileInput",
    staticClass: "file",
    attrs: {
      "disabled": _vm.disabled,
      "id": "file",
      "name": "file",
      "type": "file"
    },
    on: {
      "input": _vm.onFileChange
    }
  }, 'input', {
    ..._vm.$attrs
  }, false))]), _c('v-flex', [_c('div', {
    staticClass: "filename mt-3",
    class: {
      'font-italic': !_vm.value
    }
  }, [_vm._v(" " + _vm._s(_vm.buttonText) + " ")])]), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_vm.showMaxFileSizeExceededMessage ? _c('div', {
    staticClass: "red--text text--darken-2"
  }, [_vm._v(" " + _vm._s(_vm.maxFileSizeExceededMessage) + " ")]) : _vm._e()])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };