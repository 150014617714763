export const buildChipAsString = (options) => {
    if (!options || !options.chipText) {
        return '';
    }
    const chipClass = options.chipClass || '';
    const chipText = options.chipText || '?';
    const chipIconClass = options.chipIconClass || '';
    const iconColor = options.iconColor || options.color || 'default';
    return `
    <span class="v-chip v-chip--outline ${chipClass}">
      <div class="v-chip__content ${iconColor}--text"><i class="pr-2 ${chipIconClass}"></i> ${chipText}</div>
    </span>
  `;
};
