var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "file_attachment_list"
  }, [_c('FileAttachmentDropzone', {
    attrs: {
      "is-button": !_vm.filesList.length,
      "multiple": ""
    },
    on: {
      "drop": _vm.onFilesDropped,
      "dropzone-clicked": _vm.onClickOfDropzone
    },
    scopedSlots: _vm._u([{
      key: "dropzone-inner",
      fn: function () {
        return [_c('div', {
          staticClass: "dropzone-inner"
        }, [_c('div', {
          staticClass: "title_section"
        }, [_vm._t("title", function () {
          return [_c('AppHeader', {
            staticClass: "title",
            attrs: {
              "show-horizontal-rule": false
            }
          }, [_vm._v(_vm._s(_vm.title))])];
        }), _vm.filesList.length ? _c('ButtonGeneric', {
          attrs: {
            "color": "secondary",
            "small": "",
            "tooltip-position": "left",
            "tooltip": "Remove all attachments"
          },
          on: {
            "click": _vm.onClickOfRemoveAll
          }
        }, [_vm._v(" clear all ")]) : _vm._e()], 2), _vm.selectable && _vm.filesList.length ? _c('p', {
          staticClass: "my-2 body-1 grey--text text--darken-2"
        }, [_vm._v(" " + _vm._s(_vm.selectedFiles.length) + " files selected ")]) : _vm._e(), !_vm.filesList.length ? _c('div', {
          staticClass: "placeholder"
        }, [_c('c-icon-fa', {
          attrs: {
            "icon": "fal fa-file-upload"
          }
        }), _c('p', {
          staticClass: "text-xs-center grey--text text--darken-2"
        }, [_vm._t("placeholder_text", function () {
          return [_vm._v("Drag files here to add them as attachments.")];
        })], 2)], 1) : _vm._e(), _c('input', {
          ref: "fileInput",
          staticClass: "file_input",
          attrs: {
            "multiple": "",
            "type": "file"
          },
          on: {
            "input": _vm.onFilesSelected
          }
        }), _c('v-list', {
          staticClass: "file_list",
          attrs: {
            "dense": ""
          }
        }, _vm._l(_vm.filesList, function (file) {
          return _c('v-list-tile', {
            key: file.tempId,
            staticClass: "file_list_item v-list__tile--link pa-0",
            attrs: {
              "title": file.file.name
            },
            on: {
              "click": function ($event) {
                return _vm.onClickOfFile(file);
              }
            }
          }, [_vm.selectable ? _c('v-list-tile-avatar', [_c('c-icon-fa', {
            attrs: {
              "icon": _vm.checkboxClass(file)
            }
          })], 1) : _vm._e(), _c('v-list-tile-content', [_c('v-list-tile-title', {
            staticClass: "file_list_item__name"
          }, [_vm._v(_vm._s(file.file.name))])], 1), _c('v-list-tile-action', [_c('div', {
            staticClass: "file_actions"
          }, [!_vm.hideFilePreview ? _c('ButtonMiniActionInfo', {
            staticClass: "mr-2",
            attrs: {
              "tooltip": "View attachment"
            },
            on: {
              "click": function ($event) {
                _vm.previewFile = file.file;
              }
            }
          }) : _vm._e(), _c('ButtonMiniActionRemoveFromList', {
            attrs: {
              "icon-size": "18px",
              "tooltip": "Remove file from list"
            },
            on: {
              "click": function ($event) {
                $event.preventDefault();
                return _vm.onFileRemoved(file);
              }
            }
          })], 1)])], 1);
        }), 1), _vm.filesList.length ? _c('div', {
          staticClass: "mt-2",
          staticStyle: {
            "display": "flex",
            "justify-content": "center"
          }
        }, [_c('ButtonMiniActionCreate', {
          attrs: {
            "tooltip": "Add files",
            "tooltip-position": "top"
          },
          on: {
            "click": _vm.openFilesDialog
          }
        })], 1) : _vm._e()], 1)];
      },
      proxy: true
    }], null, true)
  }), _vm.previewFile ? _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.COMMENT,
      "content-class": `app_file_attachment_list_preview ${_vm.previewFile.type.startsWith('video/') ? 'is_video' : ''}`,
      "hide-title-section": ""
    },
    on: {
      "modal-close": function ($event) {
        _vm.previewFile = null;
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('AppFilePreview', {
          staticClass: "file_preview",
          attrs: {
            "file": _vm.previewFile,
            "height": "600px",
            "pdf-width": "100%",
            "video-height": "100%",
            "video-width": "800px"
          },
          on: {
            "log": _vm.logFileView
          }
        })];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonCancel', {
          on: {
            "click": function ($event) {
              _vm.previewFile = null;
            }
          }
        }, [_vm._v("Close")])];
      },
      proxy: true
    }], null, false, 2328779044)
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };