import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let IconNasaWorm = class IconNasaWorm extends Vue {
    color;
};
__decorate([
    Prop({
        type: String,
        default: '#ffffff'
    }),
    __metadata("design:type", String)
], IconNasaWorm.prototype, "color", void 0);
IconNasaWorm = __decorate([
    Component
], IconNasaWorm);
export default IconNasaWorm;
