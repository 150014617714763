var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { StepsOnStepsTabFragment } from '~/db_types';
import { IS_EXTERNAL_ICON_COL, SHELF_LIFE_EXP_DATE_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import ReadOnlyMixin from '~/nasa_ui/mixins/ReadOnlyMixin';
import { EntityType } from '~/nasa_ui/types/enums';
import { STANDARD_HARDWARE_COLUMNS } from '~/nasa_ui/utils/helpers/tableDefinitions';
import { transformHardwareListAssemblies } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let WorkOrderStepHardware = class WorkOrderStepHardware extends Mixins(ReadOnlyMixin) {
    EntityType = EntityType;
    tableHeaders = [
        ...STANDARD_HARDWARE_COLUMNS,
        IS_EXTERNAL_ICON_COL,
        SHELF_LIFE_EXP_DATE_COL
    ];
    document;
    step;
    get sourceDoc() {
        return this.document?.cosmicDocumentDr || this.document?.cosmicDocumentTps;
    }
    get hardwareTableItems() {
        if (!this.step?.hardwareList?.hardwareListAssemblies.nodes) {
            return [];
        }
        return transformHardwareListAssemblies(this.step?.hardwareList?.hardwareListAssemblies.nodes);
    }
};
__decorate([
    Prop({
        required: true,
        type: Object
    }),
    __metadata("design:type", Object)
], WorkOrderStepHardware.prototype, "document", void 0);
__decorate([
    Prop({
        required: true,
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof StepsOnStepsTabFragment !== "undefined" && StepsOnStepsTabFragment) === "function" ? _a : Object)
], WorkOrderStepHardware.prototype, "step", void 0);
WorkOrderStepHardware = __decorate([
    Component
], WorkOrderStepHardware);
export default WorkOrderStepHardware;
