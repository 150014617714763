var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import { ContactRepoMixin } from '~/nasa_ui/DAL/contact';
import { AlertType, Maybe } from '~/nasa_ui/types';
import { sortObjectBy } from '~/nasa_ui/utils';
let AppContact = class AppContact extends Mixins(BaseCosmic, ContactRepoMixin) {
    allContacts = [];
    isSaving = false;
    searchInput = '';
    showContactInfo = false;
    showCreateContactModal = false;
    showInlineCreateForm = false;
    disabled;
    label;
    inlineForm;
    inlineFormTitle;
    messages;
    required;
    value;
    get computedSelectedContact() {
        return this.value ? this.allContacts?.find((contact) => contact?.id === this.value) : null;
    }
    get contactSelectOptions() {
        return this.allContacts
            .map((contact) => {
            const email = contact?.email?.[0] || 'no email';
            return {
                auid: contact?.auid || '',
                displayText: this.$contactDisplay(contact),
                value: contact?.id || '',
                firstName: contact?.firstName || '',
                lastName: contact?.lastName || '',
                email
            };
        })
            .sort(sortObjectBy('displayText'));
    }
    get computedLabel() {
        return this.label || 'Contact';
    }
    get computedMessages() {
        // if required and no value, show required message
        if (this.required && this.$get(this, 'value', null) === null) {
            return ['REQUIRED'];
        }
        if (this.required && this.$get(this, 'value', undefined) === undefined) {
            return ['REQUIRED'];
        }
        if (this.required && this.$get(this, 'value', '') === '') {
            return ['REQUIRED'];
        }
        if (this.messages) {
            return this.messages;
        }
        // show messages based on rules
        return [''];
    }
    get selectionIsYourself() {
        return this.value === this.currentUser?.contactId;
    }
    onSelectedContact(val) {
        if (!val) {
            this.emitInput(null);
            return;
        }
        const selectedContact = this.contactSelectOptions.find((option) => option.value === val);
        if (selectedContact) {
            this.emitInput(selectedContact.value);
            return;
        }
        return;
    }
    async onClickOfCreateNewContactAppSelect(contactName) {
        const trimmedName = (contactName || '').trim();
        if (!trimmedName) {
            return;
        }
        if (this.inlineForm) {
            this.showInlineCreateForm = true;
        }
        else {
            this.showCreateContactModal = true;
        }
    }
    onNewContactAdded(contact) {
        this.showCreateContactModal = false;
        this.showInlineCreateForm = false;
        this.allContacts.push(contact);
        this.emitInput(contact.id);
    }
    onSearchInput(val) {
        this.searchInput = val;
    }
    created() {
        this.fetchContacts();
        this.$listen('contact_created', (_contact) => {
            this.fetchContacts();
        });
    }
    async fetchContacts() {
        const resp = await this.getAllContacts({
            error: `Couldn't fetch Contacts`
        });
        this.allContacts = resp?.data.results || [];
    }
    emitInput(val) { }
    copyEmail(contact) {
        const email = contact?.email?.[0] || '';
        navigator.clipboard.writeText(email);
        this.$notification.add({
            type: AlertType.INFO,
            text: 'Email copied to clipboard.'
        });
    }
    onClickOfYourself() {
        this.onSelectedContact(this.currentUser?.contactId);
    }
};
__decorate([
    Prop({ default: false, type: Boolean }),
    __metadata("design:type", Boolean)
], AppContact.prototype, "disabled", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_a = typeof String !== "undefined" && String) === "function" ? _a : Object)
], AppContact.prototype, "label", void 0);
__decorate([
    Prop({ default: false, type: Boolean }),
    __metadata("design:type", Boolean)
], AppContact.prototype, "inlineForm", void 0);
__decorate([
    Prop({ required: false, type: String }),
    __metadata("design:type", String)
], AppContact.prototype, "inlineFormTitle", void 0);
__decorate([
    Prop({ type: [Array] }),
    __metadata("design:type", Array)
], AppContact.prototype, "messages", void 0);
__decorate([
    Prop({ default: false, type: Boolean }),
    __metadata("design:type", Boolean)
], AppContact.prototype, "required", void 0);
__decorate([
    Prop({ default: '', type: String }),
    __metadata("design:type", typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object)
], AppContact.prototype, "value", void 0);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], AppContact.prototype, "emitInput", null);
AppContact = __decorate([
    Component
], AppContact);
export default AppContact;
