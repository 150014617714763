import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];
let AppSelectMonth = class AppSelectMonth extends Vue {
    month;
    year;
    inline;
    get monthOptions() {
        return months.map((m, i) => ({ displayText: m, value: `${i}` }));
    }
    onMonthUpdate(val) {
        return parseInt(val);
    }
    onYearUpdate(val) {
        return val;
    }
};
__decorate([
    Prop({
        required: true,
        type: Number
    }),
    __metadata("design:type", Number)
], AppSelectMonth.prototype, "month", void 0);
__decorate([
    Prop({
        required: true,
        type: Number
    }),
    __metadata("design:type", Number)
], AppSelectMonth.prototype, "year", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], AppSelectMonth.prototype, "inline", void 0);
__decorate([
    Emit('update:month'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], AppSelectMonth.prototype, "onMonthUpdate", null);
__decorate([
    Emit('update:year'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], AppSelectMonth.prototype, "onYearUpdate", null);
AppSelectMonth = __decorate([
    Component
], AppSelectMonth);
export default AppSelectMonth;
