var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('v-card', [_c('v-card-title', {
    attrs: {
      "primary-title": ""
    }
  }, [_c('AppHeader', [_vm._v("Most hit routes")])], 1), _c('v-card-text', [_c('AppPageDescription', [_vm._v("Showing all "), _c('strong', [_vm._v(_vm._s(_vm.subTypeDisplay))]), _vm._v(" grouped by path and Organization code.")]), _c('AppPageDescription', [_vm._v(" Showing orgs: "), _vm._l(_vm.selectedOrgs, function (orgCode, idx) {
    return _c('span', {
      key: orgCode
    }, [_c('strong', [_vm._v(_vm._s(orgCode))]), idx < _vm.selectedOrgs.length - 1 ? [_vm._v(",  ")] : _vm._e()], 2);
  })], 2), _vm.formData.startDate ? _c('AppPageDescription', [_vm._v(" Start date: "), _c('b', [_vm._v(_vm._s(_vm.$dateDisplay(_vm.formData.startDate)))])]) : _vm._e(), _vm.formData.endDate ? _c('AppPageDescription', [_vm._v(" End date: "), _c('b', [_vm._v(_vm._s(_vm.$dateDisplay(_vm.formData.endDate)))])]) : _vm._e(), !_vm.isLoading ? _c('Bar', {
    staticClass: "mb-3",
    attrs: {
      "options": _vm.chartOptions,
      "data": _vm.chartData,
      "height": 130
    }
  }) : _vm._e(), _c('AppTable', {
    attrs: {
      "color": _vm.computedTableColor,
      "headers": _vm.tableHeaders,
      "items": _vm.tableItems,
      "loading": _vm.isLoading,
      "dense-rows": "",
      "disable-initial-sort": "",
      "show-search-input": ""
    },
    on: {
      "updateCustomPagination": _vm.updateCurrentItems
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _c('ButtonMiniAction', {
    staticClass: "mr-2 settings-icon",
    attrs: {
      "icon": "fad fa-cog",
      "icon-color": "var(--v-primary-base)"
    },
    on: {
      "click": function ($event) {
        _vm.showSettings = true;
      }
    }
  })], 1)], 1), _vm.showSettings ? _c('AppModal', {
    attrs: {
      "modalWidth": "600px",
      "hide-title-section": ""
    },
    on: {
      "modal-close": function ($event) {
        _vm.showSettings = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('AppSelect', {
          attrs: {
            "select-options": _vm.orgSelectOptions,
            "label": "Selected Organizations",
            "multiple": "",
            "required": ""
          },
          model: {
            value: _vm.formData.selectedOrgs,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "selectedOrgs", $$v);
            },
            expression: "formData.selectedOrgs"
          }
        }), _c('AppSelect', {
          attrs: {
            "label": "Log Type",
            "selectOptions": _vm.subTypeOptions,
            "chips": false
          },
          model: {
            value: _vm.formData.subType,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "subType", $$v);
            },
            expression: "formData.subType"
          }
        }), _c('AppDate', {
          attrs: {
            "clearable": "",
            "label": "Start"
          },
          model: {
            value: _vm.formData.startDate,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "startDate", $$v);
            },
            expression: "formData.startDate"
          }
        }), _c('AppDate', {
          attrs: {
            "clearable": "",
            "label": "End"
          },
          model: {
            value: _vm.formData.endDate,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "endDate", $$v);
            },
            expression: "formData.endDate"
          }
        })];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonSave', {
          attrs: {
            "color": "primary"
          },
          on: {
            "click": _vm.updateResults
          }
        }, [_vm._v("Apply")]), _c('ButtonCancel', {
          on: {
            "click": function ($event) {
              _vm.showSettings = false;
            }
          }
        }, [_vm._v("Close")])];
      },
      proxy: true
    }], null, false, 2056631874)
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };