import { __decorate } from "tslib";
import { Component, Mixins } from 'vue-property-decorator';
import { ActivityType } from '~/db_types';
import BaseActivityInformationForm from '~/nasa_ui/base/BaseActivityInformationForm';
import { ActivityTypeDisplay, AnalysisResult, AnalysisResultDisplay } from '~/nasa_ui/types/enums/activity';
let ActivityInspectionForm = class ActivityInspectionForm extends Mixins(BaseActivityInformationForm) {
    AnalysisResultDisplay = AnalysisResultDisplay;
    AnalysisResult = AnalysisResult;
    get activityTypeDisplay() {
        return ActivityTypeDisplay.has(ActivityType.INSPECTION)
            ? ActivityTypeDisplay.get(ActivityType.INSPECTION)
            : ActivityType.INSPECTION;
    }
};
ActivityInspectionForm = __decorate([
    Component
], ActivityInspectionForm);
export default ActivityInspectionForm;
