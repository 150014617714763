var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "dashboard"
  }, [_c('div', {
    staticClass: "dashboard-item"
  }, [_c('StatsMostHitRoutes')], 1), _c('div', {
    staticClass: "dashboard-item"
  }, [_c('StatsPerOrg')], 1), _c('div', {
    staticClass: "dashboard-item"
  }, [_c('StatsPerBrowser')], 1), _c('div', {
    staticClass: "dashboard-item"
  }, [_c('StatsPerUser', {
    attrs: {
      "subType": _vm.LogType.ERROR
    }
  })], 1), _c('div', {
    staticClass: "dashboard-item"
  }, [_c('StatsPerUser', {
    attrs: {
      "subType": _vm.LogType.TELEMETRY
    }
  })], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };