var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppPageDescription', {
    attrs: {
      "color": "info"
    }
  }, [_vm._v(" Showing Permissions for the support context of "), _c('u', [_c('strong', [_vm._v(_vm._s(_vm.selectedSupportContext))])])]), _vm.canEditPermissions ? _c('AppTableSelectable', {
    attrs: {
      "color": _vm.EntityType.USER,
      "headers": _vm.permissionTableHeaders,
      "items": _vm.allUserPermissions,
      "rows-per-page-items": [-1],
      "selectable-rows": true,
      "should-disable-checkboxes": !_vm.canEditPermissions,
      "hide-pagination": "",
      "item-key": "value",
      "show-search-input": ""
    },
    model: {
      value: _vm.selectedPermissions,
      callback: function ($$v) {
        _vm.selectedPermissions = $$v;
      },
      expression: "selectedPermissions"
    }
  }) : _vm._e(), _vm.canEditPermissions ? _c('ButtonSave', {
    staticClass: "ml-0",
    on: {
      "click": _vm.onClickOfSave
    }
  }) : _vm._e(), !_vm.canEditPermissions ? _c('AppTable', {
    attrs: {
      "color": _vm.EntityType.USER,
      "headers": _vm.permissionTableHeaders,
      "items": _vm.selectedPermissions,
      "rows-per-page-items": [-1],
      "hide-pagination": "",
      "item-key": "value",
      "show-search-input": ""
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };