var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.formData ? _c('div', {
    staticClass: "wrapper"
  }, [_c('AppHeader', {
    staticClass: "mb-3"
  }, [_vm._v(_vm._s(_vm.activityTypeDisplay) + " specific information")]), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": "",
      "mt-3": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs4": ""
    }
  }, [!_vm.isReadOnly ? _c('AppSelectEnum', {
    staticClass: "checkOutTypeSelect",
    attrs: {
      "label": "Check-out type",
      "enum": _vm.CheckOutActivityHardwareType,
      "display": _vm.CheckOutActivityHardwareTypeDisplay,
      "chips": false
    },
    model: {
      value: _vm.formData.attributes.checkOutType,
      callback: function ($$v) {
        _vm.$set(_vm.formData.attributes, "checkOutType", $$v);
      },
      expression: "formData.attributes.checkOutType"
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "Check-out type"
    }
  }, [_vm._v(" " + _vm._s(_vm.formData.attributes.checkOutType ? _vm.CheckOutActivityHardwareTypeDisplay.get(_vm.formData.attributes.checkOutType) || '-' : '-') + " ")])], 1)], 1), false ? [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": "",
      "pr-4": "",
      "mb-3": ""
    }
  }, [!_vm.isReadOnly ? _c('AppBoolean', {
    attrs: {
      "hide-unknown": "",
      "label": "Fan run time > 2h?"
    },
    model: {
      value: _vm.formData.attributes.isFanRunTimeOverTwoHours,
      callback: function ($$v) {
        _vm.$set(_vm.formData.attributes, "isFanRunTimeOverTwoHours", $$v);
      },
      expression: "formData.attributes.isFanRunTimeOverTwoHours"
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "Fan run time > 2h?"
    }
  }, [_c('AppBooleanDisplay', {
    attrs: {
      "value": _vm.formData.attributes.isFanRunTimeOverTwoHours
    }
  })], 1)], 1), _c('v-flex', {
    attrs: {
      "xs6": "",
      "pr-4": "",
      "mb-3": ""
    }
  }, [!_vm.isReadOnly ? _c('AppNumber', {
    attrs: {
      "label": "SOP P"
    },
    model: {
      value: _vm.formData.attributes.secondaryOxygenPackP,
      callback: function ($$v) {
        _vm.$set(_vm.formData.attributes, "secondaryOxygenPackP", $$v);
      },
      expression: "formData.attributes.secondaryOxygenPackP"
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "SOP P"
    }
  }, [_vm._v(" " + _vm._s(_vm.formData.attributes.secondaryOxygenPackP || '-') + " ")])], 1), _c('v-flex', {
    attrs: {
      "xs6": "",
      "pr-4": ""
    }
  }, [!_vm.isReadOnly ? _c('AppNumber', {
    attrs: {
      "label": "SOP Regulation PSI",
      "hint": "XX.XX"
    },
    model: {
      value: _vm.formData.attributes.secondaryOxygenPackRegulationPressure,
      callback: function ($$v) {
        _vm.$set(_vm.formData.attributes, "secondaryOxygenPackRegulationPressure", $$v);
      },
      expression: "formData.attributes.secondaryOxygenPackRegulationPressure"
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "SOP Regulation PSI"
    }
  }, [_vm._v(" " + _vm._s(_vm.formData.attributes.secondaryOxygenPackRegulationPressure || '-') + " ")])], 1), _c('v-flex', {
    attrs: {
      "xs6": "",
      "pr-4": ""
    }
  }, [!_vm.isReadOnly ? _c('AppNumber', {
    attrs: {
      "label": "CCAA Temp"
    },
    model: {
      value: _vm.formData.attributes.commonCabinAirAssemblyTemp,
      callback: function ($$v) {
        _vm.$set(_vm.formData.attributes, "commonCabinAirAssemblyTemp", $$v);
      },
      expression: "formData.attributes.commonCabinAirAssemblyTemp"
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "CCAA Temp"
    }
  }, [_vm._v(" " + _vm._s(_vm.formData.attributes.commonCabinAirAssemblyTemp || '-') + " ")])], 1), _c('v-flex', {
    attrs: {
      "xs6": "",
      "pr-4": ""
    }
  }, [!_vm.isReadOnly ? _c('AppNumber', {
    attrs: {
      "label": "EMU Temp"
    },
    model: {
      value: _vm.formData.attributes.emuTemp,
      callback: function ($$v) {
        _vm.$set(_vm.formData.attributes, "emuTemp", $$v);
      },
      expression: "formData.attributes.emuTemp"
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "EMU Temp"
    }
  }, [_vm._v(" " + _vm._s(_vm.formData.attributes.emuTemp || '-') + " ")])], 1), _c('v-flex', {
    attrs: {
      "xs6": "",
      "pr-4": ""
    }
  }, [!_vm.isReadOnly ? _c('AppSelectEnum', {
    attrs: {
      "enum": _vm.ScuType,
      "display": _vm.ScuTypeDisplay,
      "label": "SCU Type"
    },
    model: {
      value: _vm.formData.attributes.serviceAndCoolingUnit,
      callback: function ($$v) {
        _vm.$set(_vm.formData.attributes, "serviceAndCoolingUnit", $$v);
      },
      expression: "formData.attributes.serviceAndCoolingUnit"
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "SCU Type"
    }
  }, [_vm._v(" " + _vm._s(_vm.formData.attributes.serviceAndCoolingUnit ? _vm.ScuTypeDisplay.get(_vm.formData.attributes.serviceAndCoolingUnit) || '-' : '-') + " ")])], 1), _c('v-flex', {
    attrs: {
      "xs6": "",
      "pr-4": ""
    }
  }, [!_vm.isReadOnly ? _c('AppSelectEnum', {
    attrs: {
      "enum": _vm.WaterDumpType,
      "display": _vm.WaterDumpTypeDisplay,
      "label": "Water Dump Type"
    },
    model: {
      value: _vm.formData.attributes.waterDump,
      callback: function ($$v) {
        _vm.$set(_vm.formData.attributes, "waterDump", $$v);
      },
      expression: "formData.attributes.waterDump"
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "Water Dump Type"
    }
  }, [_vm._v(" " + _vm._s(_vm.formData.attributes.waterDump ? _vm.WaterDumpTypeDisplay.get(_vm.formData.attributes.waterDump) || '-' : '-') + " ")])], 1)], 1)] : _vm._e(), false ? [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs6": "",
      "pr-4": ""
    }
  }, [!_vm.isReadOnly ? _c('AppSelectEnum', {
    attrs: {
      "enum": _vm.PgtStatus,
      "display": _vm.PgtStatusDisplay,
      "label": "PGT Status"
    },
    model: {
      value: _vm.formData.attributes.pgtStatus,
      callback: function ($$v) {
        _vm.$set(_vm.formData.attributes, "pgtStatus", $$v);
      },
      expression: "formData.attributes.pgtStatus"
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "PGT Status"
    }
  }, [_vm._v(" " + _vm._s(_vm.formData.attributes.pgtStatus ? _vm.PgtStatusDisplay.get(_vm.formData.attributes.pgtStatus) || '-' : '-') + " ")])], 1)], 1)] : _vm._e(), false ? [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs6": ""
    }
  }, [!_vm.isReadOnly ? _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs2": ""
    }
  }, [!_vm.isReadOnly ? _c('AppNumber', {
    attrs: {
      "label": "On Time",
      "hint": "minutes"
    },
    model: {
      value: _vm.formData.attributes.onTimeMinutes,
      callback: function ($$v) {
        _vm.$set(_vm.formData.attributes, "onTimeMinutes", $$v);
      },
      expression: "formData.attributes.onTimeMinutes"
    }
  }) : _vm._e()], 1), _c('v-flex', {
    attrs: {
      "xs1": "",
      "align-self-center": "",
      "display-1": "",
      "text-xs-center": ""
    }
  }, [_vm._v(":")]), _c('v-flex', {
    attrs: {
      "xs2": ""
    }
  }, [_c('AppNumber', {
    attrs: {
      "label": "On Time",
      "hint": "seconds",
      "max": "59"
    },
    model: {
      value: _vm.formData.attributes.onTimeSeconds,
      callback: function ($$v) {
        _vm.$set(_vm.formData.attributes, "onTimeSeconds", $$v);
      },
      expression: "formData.attributes.onTimeSeconds"
    }
  })], 1)], 1) : _vm._e(), _vm.isReadOnly ? _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "On Time"
    }
  }, [_vm._v(" " + _vm._s(_vm.formData.attributes.onTimeMinutes || '0') + "m:" + _vm._s(_vm.formData.attributes.onTimeSeconds || '0') + "s ")])], 1)], 1) : _vm._e()], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "pr-4": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs6": "",
      "pr-4": ""
    }
  }, [!_vm.isReadOnly ? _c('AppNumber', {
    attrs: {
      "label": "GN2 Percent",
      "max": "100",
      "required": ""
    },
    model: {
      value: _vm.formData.attributes.gn2Percent,
      callback: function ($$v) {
        _vm.$set(_vm.formData.attributes, "gn2Percent", $$v);
      },
      expression: "formData.attributes.gn2Percent"
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "GN2 Percent"
    }
  }, [_vm._v(" " + _vm._s(_vm.formData.attributes.gn2Percent || '-') + "%")])], 1), _c('v-flex', {
    attrs: {
      "xs6": "",
      "pr-4": ""
    }
  }, [!_vm.isReadOnly ? _c('AppNumber', {
    attrs: {
      "label": "PWR Percent",
      "max": "100",
      "required": ""
    },
    model: {
      value: _vm.formData.attributes.pwrPercent,
      callback: function ($$v) {
        _vm.$set(_vm.formData.attributes, "pwrPercent", $$v);
      },
      expression: "formData.attributes.pwrPercent"
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "PWR Percent"
    }
  }, [_vm._v(" " + _vm._s(_vm.formData.attributes.pwrPercent || '-') + "%")])], 1)], 1)], 1), _c('v-flex', {
    attrs: {
      "xs6": "",
      "pr-4": ""
    }
  }, [!_vm.isReadOnly ? _c('AppNumber', {
    attrs: {
      "label": "Battery Voltage"
    },
    model: {
      value: _vm.formData.attributes.batteryVolts,
      callback: function ($$v) {
        _vm.$set(_vm.formData.attributes, "batteryVolts", $$v);
      },
      expression: "formData.attributes.batteryVolts"
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "Battery Voltage"
    }
  }, [_vm._v(" " + _vm._s(_vm.formData.attributes.batteryVolts || '-') + "V")])], 1)], 1)] : _vm._e()], 2) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };