var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "mt-2"
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "align-center": "",
      "justify-space-between": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs10": _vm.hasSlot('actions'),
      "xs12": !_vm.hasSlot('actions')
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "align-center": ""
    }
  }, [_c('v-flex', {
    staticStyle: {
      "width": "auto"
    },
    attrs: {
      "shrink": ""
    }
  }, [_vm._t("icon", function () {
    return [_vm.icon ? _c('c-icon-fa', {
      staticClass: "mr-2",
      attrs: {
        "icon": _vm.icon,
        "icon-color": _vm.iconColor,
        "icon-size": "42px"
      }
    }) : _vm._e()];
  })], 2), _c('v-flex', {
    staticStyle: {
      "width": "auto"
    },
    attrs: {
      "grow": ""
    }
  }, [_vm._t("title", function () {
    return [_c('h1', {
      staticClass: "app_view_header_title display-1 black--text"
    }, [_vm._v(" " + _vm._s(_vm.title) + " ")])];
  }), _c('div', [_vm._t("sub_title")], 2)], 2)], 1)], 1), _vm.hasSlot('actions') ? _c('v-flex', {
    attrs: {
      "xs2": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "justify-end": ""
    }
  }, [_vm._t("actions")], 2)], 1) : _vm._e()], 1), _c('div', {
    staticClass: "mt-1"
  }, [_vm._t("attributes", function () {
    return [_c('div', {
      staticClass: "attributes"
    }, _vm._l(_vm.pills, function (pill) {
      return _c('AppPill', {
        key: pill.leftSide + pill.rightSide,
        staticClass: "mt-1",
        attrs: {
          "pill": pill
        }
      });
    }), 1)];
  })], 2)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };