var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('v-card', [_c('v-card-title', {
    attrs: {
      "primary-title": ""
    }
  }, [_c('AppHeader', [_vm._v(_vm._s(_vm.chartTypeDisplay) + " per User")])], 1), _c('v-card-text', [_c('AppPageDescription', {
    staticClass: "mb-1"
  }, [_vm._v(_vm._s(_vm.description))]), _vm.startDate ? _c('AppPageDescription', {
    staticClass: "mb-1"
  }, [_c('span', {
    staticClass: "date-filter-label"
  }, [_vm._v("Start date: ")]), _vm._v(" "), _c('b', [_vm._v(_vm._s(_vm.$dateDisplay(_vm.formData.startDate)))])]) : _vm._e(), _vm.endDate ? _c('AppPageDescription', [_c('span', {
    staticClass: "date-filter-label"
  }, [_vm._v("End date: ")]), _vm._v(" "), _c('b', [_vm._v(_vm._s(_vm.$dateDisplay(_vm.formData.endDate)))])]) : _vm._e(), !_vm.isFetching ? _c('Bar', {
    attrs: {
      "options": _vm.chartOptions,
      "data": _vm.chartData,
      "height": 130
    }
  }) : _vm._e(), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [!_vm.isFetching ? _c('AppTable', {
    staticClass: "mt-3",
    attrs: {
      "color": _vm.EntityType.USER,
      "customPagination": _vm.startingPagination,
      "headers": _vm.tableHeaders,
      "items": _vm.allUsersTelemetry,
      "dense-rows": "",
      "disable-initial-sort": "",
      "item-key": "id"
    },
    on: {
      "updateCustomPagination": _vm.updatePagination
    }
  }) : _vm._e()], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('ButtonMiniAction', {
    staticClass: "mr-2 settings-icon",
    attrs: {
      "icon": "fad fa-cog",
      "icon-color": "var(--v-primary-base)"
    },
    on: {
      "click": function ($event) {
        _vm.showSettings = true;
      }
    }
  })], 1)], 1), _vm.showSettings ? _c('AppModal', {
    attrs: {
      "modalWidth": "600px",
      "hide-title-section": ""
    },
    on: {
      "modal-close": function ($event) {
        _vm.showSettings = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('AppDate', {
          attrs: {
            "label": "Start"
          },
          model: {
            value: _vm.formData.startDate,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "startDate", $$v);
            },
            expression: "formData.startDate"
          }
        }), _c('AppDate', {
          attrs: {
            "label": "End"
          },
          model: {
            value: _vm.formData.endDate,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "endDate", $$v);
            },
            expression: "formData.endDate"
          }
        })];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonSave', {
          attrs: {
            "color": "primary"
          },
          on: {
            "click": _vm.updateResults
          }
        }, [_vm._v("Apply")]), _c('ButtonCancel', {
          on: {
            "click": function ($event) {
              _vm.showSettings = false;
            }
          }
        }, [_vm._v("Close")])];
      },
      proxy: true
    }], null, false, 3020871859)
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };