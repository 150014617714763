var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { ChangeSetAuthorityType } from '~/db_types';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { EntityType } from '~/nasa_ui/types';
import { IAuthorityOverride } from '~/nasa_ui/types/models/authorityOverride';
import { transformUserResponses } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let FormAddMip = class FormAddMip extends Vue {
    ChangeSetAuthorityType = ChangeSetAuthorityType;
    EntityType = EntityType;
    formData = {
        authorityType: ChangeSetAuthorityType.DEFAULT,
        groupCode: null,
        mipExplanation: null,
        mipType: null
    };
    userGroups;
    get addMipButtonLabel() {
        return this.formData.authorityType === ChangeSetAuthorityType.GOVERNMENT_MANUAL_INSPECTION_POINT
            ? 'Add GMIP'
            : 'Add MIP';
    }
    get groupSelectOptions() {
        return this.userGroups.map((group) => ({
            displayText: this.computedGroupName(group),
            value: group.code
        }));
    }
    get isFormValid() {
        return Boolean(this.formData.authorityType && this.formData.groupCode);
    }
    // called from ui
    computedGroupName(userGroup) {
        // if the user group has a name, use it
        if (userGroup?.name) {
            return userGroup.name;
        }
        else if (userGroup?.delegatesForUser) {
            // if the user group is a delegate group, return the user's full name
            const user = transformUserResponses([userGroup.delegatesForUser])[0];
            return user._fullName;
        }
        else {
            // as a fallback, return the group code
            return userGroup.code || DEFAULT_DASH;
        }
    }
    // called from ui
    onClickOfAddMip() {
        if (!this.formData.authorityType || !this.formData.groupCode || !this.isFormValid) {
            console.error('Add MIP form: Missing required fields');
            return;
        }
        const authorityOverride = {
            authorityType: this.formData.authorityType,
            groupCode: this.formData.groupCode,
            manualInspectionPointExplanation: this.formData.mipExplanation,
            manualInspectionPointType: this.formData.mipType,
            userGroup: this.userGroups.find((g) => g.code === this.formData.groupCode)
        };
        this.emitMipAdded(authorityOverride);
    }
    emitMipAdded(authorityOverride) { }
    // called from ui
    onClickOfCancel() { }
};
__decorate([
    Prop({
        type: Array,
        required: true
    }),
    __metadata("design:type", Array)
], FormAddMip.prototype, "userGroups", void 0);
__decorate([
    Emit('mip_added'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof IAuthorityOverride !== "undefined" && IAuthorityOverride) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], FormAddMip.prototype, "emitMipAdded", null);
__decorate([
    Emit('cancel'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], FormAddMip.prototype, "onClickOfCancel", null);
FormAddMip = __decorate([
    Component
], FormAddMip);
export default FormAddMip;
