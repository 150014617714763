import { __decorate, __metadata } from "tslib";
import _ from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import colors from 'vuetify/es5/util/colors';
import { BASE_ICON_COL, BASE_NUMBER_COL, BASE_TEXT_COL, NUMBER_NO_UNDERSCORE_COL, STATUS_COL, TITLE_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import { sortObjectBy } from '~/nasa_ui/utils';
import { composeTableHeader } from '~/nasa_ui/utils/helpers/composeTableHeader';
import { sortObjectNumericallyBy } from '~/nasa_ui/utils/helpers/sortObjectNumericallyBy';
import { transformRiskReportResponses } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
const statTypeOptions = [
    {
        displayText: 'Category',
        value: 'category',
        name: 'CATEGORY'
    },
    {
        displayText: 'Hardware type',
        value: 'hardwareType',
        name: 'HARDWARE_TYPE'
    },
    {
        displayText: 'Organization',
        value: 'organizationCode',
        name: 'ORGANIZATION'
    },
    {
        displayText: 'Team',
        value: 'team',
        name: 'TEAM'
    },
    {
        displayText: 'Status',
        value: 'status',
        name: 'STATUS'
    }
];
let ChartRiskStats = class ChartRiskStats extends Vue {
    currentStatOption = {};
    selectedStatType = null;
    filteredRisks = [];
    riskTableHeaders = [
        NUMBER_NO_UNDERSCORE_COL,
        composeTableHeader(STATUS_COL, {
            width: '50px',
            noWrap: false
        }),
        composeTableHeader(BASE_ICON_COL, {
            text: 'Risk<br/>score',
            value: '_riskScore'
        }),
        TITLE_COL,
        composeTableHeader(BASE_TEXT_COL, {
            noEscape: true,
            text: 'Category',
            value: '_category'
        })
    ];
    chartColor;
    risks;
    // NOTE: must be the same casing as risk property for now
    statType;
    // TODO: will need to use all colors for organizations
    get chartColors() {
        return Object.values(colors[this.chartColor])
            .slice(2)
            .filter((c, index) => index % 2);
    }
    get chartData() {
        return {
            labels: Object.keys(this.chartItems),
            datasets: [
                {
                    label: 'Total',
                    data: Object.values(this.chartItems),
                    backgroundColor: this.chartColors
                }
            ]
        };
    }
    get chartItems() {
        return _.countBy(this.risks, this.statType);
    }
    get chartOptions() {
        return {
            responsive: true,
            onClick: (event, elements) => {
                if (!elements.length) {
                    return;
                }
                const [element] = elements;
                if (!element) {
                    return;
                }
                if (this.selectedStatType && this.selectedStatType === Object.keys(this.chartItems)[element.index]) {
                    this.selectedStatType = null;
                }
                else {
                    this.selectedStatType = Object.keys(this.chartItems)[element.index];
                }
            },
            plugins: {
                datalabels: {
                    display: true,
                    backgroundColor: 'white',
                    borderRadius: 5,
                    color: 'black',
                    font: {
                        size: 14,
                        family: 'monospace',
                        weight: 'bold'
                    }
                },
                legend: {
                    display: false
                },
                riskMatrixTrendsChartBackground: false,
                title: {
                    display: false
                },
                tooltip: {
                    bodyFont: {
                        size: 14,
                        family: 'monospace'
                    }
                }
            }
        };
    }
    get chartTableHeaders() {
        return [
            composeTableHeader(BASE_TEXT_COL, {
                text: this.currentStatOption.displayText,
                value: this.currentStatOption.value
            }),
            composeTableHeader(BASE_NUMBER_COL, {
                text: 'Count',
                value: '_count'
            })
        ];
    }
    get chartTableItems() {
        // group the risks by their stat type
        const risksGroupedByStat = _.groupBy(this.risks, this.statType);
        // return array of table items using grouped risks
        return Object.keys(risksGroupedByStat)
            .map((statName) => {
            const tableItem = {
                _count: risksGroupedByStat[statName].length || 0
            };
            tableItem[this.currentStatOption.value] = statName;
            return tableItem;
        })
            .sort(sortObjectNumericallyBy('_count', true));
    }
    buildRiskUrl(risk) {
        return `/risks/manage/${risk.id}#information`;
    }
    test(item) {
        console.log(item);
    }
    setCurrentStatType() {
        this.currentStatOption = _.find(statTypeOptions, ['value', this.statType]);
    }
    setFilteredRisks() {
        this.filteredRisks = transformRiskReportResponses(this.risks.filter((risk) => risk[this.statType] === this.selectedStatType)).sort(sortObjectBy('number', true));
    }
};
__decorate([
    Prop({
        type: String,
        required: false
    }),
    __metadata("design:type", String)
], ChartRiskStats.prototype, "chartColor", void 0);
__decorate([
    Prop({
        type: Array,
        required: false
    }),
    __metadata("design:type", Array)
], ChartRiskStats.prototype, "risks", void 0);
__decorate([
    Prop({
        type: String,
        required: true
    }),
    __metadata("design:type", String)
], ChartRiskStats.prototype, "statType", void 0);
__decorate([
    Watch('statType', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ChartRiskStats.prototype, "setCurrentStatType", null);
__decorate([
    Watch('selectedStatType', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ChartRiskStats.prototype, "setFilteredRisks", null);
ChartRiskStats = __decorate([
    Component
], ChartRiskStats);
export default ChartRiskStats;
