var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "no_current_step"
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": "",
      "fill-height": ""
    }
  }, [_c('v-flex', {
    staticClass: "option",
    attrs: {
      "xs6": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "justify-end": ""
    }
  }, [_c('v-card', {
    staticClass: "my-5 mx-2 pt-3 option__card",
    on: {
      "click": function ($event) {
        _vm.showNewWorkOrderStepModal = true;
      }
    }
  }, [_c('c-icon-fa', {
    attrs: {
      "icon": _vm.$icons.create_fat
    }
  }), _c('h1', {
    staticClass: "documents--text my-2"
  }, [_vm._v(" Create "), _c('span', {
    staticClass: "text-lowercase"
  }, [_vm._v(_vm._s(_vm.stepDisplayText))])])], 1)], 1)], 1), _c('v-flex', {
    staticClass: "option",
    attrs: {
      "xs6": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "justify-start": ""
    }
  }, [_c('v-card', {
    staticClass: "my-5 mx-2 pt-3 option__card",
    on: {
      "click": function ($event) {
        _vm.showImportModal = true;
      }
    }
  }, [_c('c-icon-fa', {
    attrs: {
      "icon": "fad fa-file-excel"
    }
  }), _c('h1', {
    staticClass: "documents--text my-2"
  }, [_vm._v("Import spreadsheet")])], 1)], 1)], 1)], 1), _vm.showImportModal ? _c('ModalWorkOrderStepImportSpreadsheet', {
    attrs: {
      "document-id": _vm.documentId,
      "document-type": _vm.documentType
    },
    on: {
      "modal-close": function ($event) {
        _vm.showImportModal = false;
      },
      "created": _vm.onImportComplete
    }
  }) : _vm._e(), _vm.showNewWorkOrderStepModal ? _c('ModalWorkOrderStepCreateAndEdit', {
    attrs: {
      "document-id": _vm.documentId,
      "default-step-number": 1,
      "max-step-number": 1,
      "read-only": _vm.isReadOnly
    },
    on: {
      "modal-close": function ($event) {
        _vm.showNewWorkOrderStepModal = false;
      },
      "step-saved": _vm.onNewStepComplete
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };