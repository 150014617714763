var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-card', {
    staticClass: "panel"
  }, [_c('v-layout', {
    staticClass: "mx-0 users panel-header",
    attrs: {
      "row": ""
    }
  }, [_c('v-flex', {
    staticClass: "my-auto",
    attrs: {
      "xs2": ""
    }
  }, [_c('div', {
    staticClass: "d-flex mb-3"
  }, [_c('ButtonMiniAction', {
    attrs: {
      "icon-size": _vm.isUserSelected ? '28' : _vm.isGroupSelected ? '20' : '22',
      "icon": _vm.$icons[_vm.EntityType.USER],
      "tooltip": "Search users",
      "tooltip-position": "bottom"
    },
    on: {
      "click": function ($event) {
        _vm.searchType = 'user';
      }
    }
  })], 1), _c('div', {
    staticClass: "d-flex"
  }, [_c('ButtonMiniAction', {
    attrs: {
      "icon-size": _vm.isGroupSelected ? '28' : _vm.isUserSelected ? '20' : '22',
      "icon": _vm.$icons[_vm.EntityType.USER_GROUP],
      "tooltip": "Search user groups",
      "tooltip-position": "bottom"
    },
    on: {
      "click": function ($event) {
        _vm.searchType = 'group';
      }
    }
  })], 1)]), _c('v-flex', {
    staticClass: "my-auto",
    attrs: {
      "xs10": "",
      "md10": ""
    }
  }, [_c('c-text', {
    attrs: {
      "label": `Search ${_vm.searchType}`,
      "clearable": "",
      "color": "users lighten-4",
      "dark": "",
      "hide-details": "",
      "c-value": _vm.searchTerm
    },
    on: {
      "inlineClear": _vm.onSearchInputClear,
      "c-input": function ($event) {
        _vm.searchTerm = $event.detail;
      }
    }
  })], 1)], 1), _c('div', {
    staticClass: "panel-options"
  }, [_c('v-slide-y-transition', [_vm.showNoResultsMessage ? _c('div', {
    staticClass: "pa-3 grey--text text--darken-2"
  }, [_c('em', [_vm._v("No " + _vm._s(_vm.searchType) + "s found")])]) : _vm._e()]), _c('v-slide-y-transition', {
    attrs: {
      "group": "",
      "tag": "v-list",
      "two-line": true
    }
  }, [_vm._l(_vm.filteredGroupOptions, function (group, i) {
    return [i !== 0 ? _c('v-divider', {
      key: `${i}-divider`
    }) : _vm._e(), _c('v-list-tile', {
      key: group.code
    }, [_c('v-list-tile-action', [_c('ButtonMiniActionCreate', {
      attrs: {
        "tooltip": "Add to list",
        "tooltip-position": "bottom left"
      },
      on: {
        "click": function ($event) {
          return _vm.selectGroup(group);
        }
      }
    })], 1), _c('v-list-tile-content', {
      staticClass: "body-1"
    }, [group.delegatesForUser ? _c('v-list-tile-title', [_c('span', {
      attrs: {
        "title": _vm.$contactDisplay(group.delegatesForUser)
      }
    }, [_vm._v(" " + _vm._s(_vm.$contactDisplay(group.delegatesForUser)) + " ")])]) : _c('v-list-tile-title', [_c('span', {
      attrs: {
        "title": (group === null || group === void 0 ? void 0 : group.name) || ''
      }
    }, [_vm._v(" " + _vm._s(group.name) + " ("), _c('strong', [_c('small', [_vm._v(_vm._s(group.usersToUserGroups.totalCount))])]), _vm._v(")")])])], 1), !group.delegatesForUser ? _c('v-list-tile-action', [_c('UserGroupUsersMenu', {
      attrs: {
        "code": group.code
      }
    })], 1) : _vm._e()], 1)];
  })], 2)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };