var _a;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { DocumentType } from '~/db_types';
import BaseDelete from '~/nasa_ui/base/BaseDelete';
import { AlertType } from '~/nasa_ui/types';
import { DocumentTypeDisplay } from '~/nasa_ui/types/enums/document';
import { UserPermission } from '~/nasa_ui/types/enums/user';
let DeleteCosmicDocument = class DeleteCosmicDocument extends BaseDelete {
    AlertType = AlertType;
    includeDocumentHardwareLists = true;
    hasHardwareLists = false;
    readOnly;
    cosmicDocumentSubType;
    // TODO:  this is ugly terrible code
    get computedAdminPermission() {
        if (this.isDr) {
            return UserPermission.C_DOCS_DR_ADMIN;
        }
        if (this.isEzt) {
            return UserPermission.C_DOCS_EZT_ADMIN;
        }
        if (this.isFracas) {
            return UserPermission.C_DOCS_FRACAS_ADMIN;
        }
        if (this.isRca) {
            return UserPermission.C_DOCS_RCA_ADMIN;
        }
        if (this.isTps) {
            return UserPermission.C_DOCS_TPS_ADMIN;
        }
        if (this.isWorkflow) {
            return UserPermission.C_DOCS_WORKFLOWS_ADMIN;
        }
    }
    get isDr() {
        return this.cosmicDocumentSubType === DocumentType.C_DISCREPANCY_REPORT;
    }
    get isEzt() {
        return this.cosmicDocumentSubType === DocumentType.C_EASY_TRANSFER;
    }
    get isFracas() {
        return this.cosmicDocumentSubType === DocumentType.C_FAILURE_REPORTING_ANALYSIS_CORRECTIVE_ACTION_SYSTEM;
    }
    get isRca() {
        return this.cosmicDocumentSubType === DocumentType.C_RCA;
    }
    get isTps() {
        return this.cosmicDocumentSubType === DocumentType.C_TPS;
    }
    get isWorkflow() {
        return this.cosmicDocumentSubType === DocumentType.C_WORKFLOW;
    }
    get shouldShowDeleteButton() {
        // must have admin permissions for this document type
        return this.computedAdminPermission ? this.currentUserHasPermission(this.computedAdminPermission) : false;
    }
    get shouldDisableDeleteButton() {
        return this.isDeleting || this.readOnly;
    }
    get subTypeDisplay() {
        return DocumentTypeDisplay.get(this.cosmicDocumentSubType);
    }
    // called from ui
    onAffirmativeClicked() {
        this.deleteCosmicDocument();
    }
    async deleteCosmicDocument() {
        if (!this.id) {
            return;
        }
        try {
            this.isDeleting = true;
            const linksResponse = await this.fetchExternalLinks();
            const externalLinks = linksResponse?.data?.documentById?.links?.nodes.map((link) => link.id);
            if (externalLinks?.length) {
                const deleteAllLinks = externalLinks.map((linkId) => this.deleteLinkMutation(linkId));
                // delete all external links before deleting document
                await Promise.all(deleteAllLinks);
            }
            const resp = await this.deleteCosmicDocumentMutation();
            if (resp?.data) {
                this.$notification.add({ text: `${this.subTypeDisplay} deleted.`, type: AlertType.SUCCESS });
                this.emitDeletionComplete();
                this.$message('reload_user_alerts');
            }
        }
        catch (err) {
            this.isDeleting = false;
            this.$errorUtility({
                err,
                backupErrorText: 'Failed to delete Document.'
            });
        }
    }
    deleteCosmicDocumentMutation() {
        if (!this.id) {
            return null;
        }
        return this.$apollo.mutate({
            mutation: gql `
        mutation DeleteCosmicDocument($id: UUID!, $includeDocumentHardwareLists: Boolean!) {
          deleteCosmicDocument(
            input: { documentId: $id, includeDocumentHardwareLists: $includeDocumentHardwareLists }
          ) {
            clientMutationId
          }
        }
      `,
            variables: {
                id: this.id,
                includeDocumentHardwareLists: this.includeDocumentHardwareLists
            }
        });
    }
    deleteLinkMutation(id) {
        if (!id) {
            return null;
        }
        return this.$apollo.mutate({
            mutation: gql `
        mutation DeleteExternalLinkOnDocumentDelete($id: UUID!) {
          deleteLinkById(input: { id: $id }) {
            clientMutationId
          }
        }
      `,
            variables: {
                id
            }
        });
    }
    fetchExternalLinks() {
        if (!this.id) {
            return null;
        }
        return this.$apollo.mutate({
            mutation: gql `
        fragment linkOnDocumentDelete on Link {
          id
          nodeId
          documentId
        }
        query FetchDocumentWithExternalLinksOnDelete($documentId: UUID!) {
          documentById(id: $documentId) {
            nodeId
            id
            links {
              totalCount
              nodes {
                ...linkOnDocumentDelete
              }
            }
          }
        }
      `,
            variables: {
                documentId: this.id
            }
        });
    }
    async fetchHardwareLists() {
        if (!this.showDialog || !this.id) {
            return;
        }
        const resp = await this.$apollo.query({
            query: gql `
        query FetchHardwareListsForDocumentOnDelete($documentId: UUID!) {
          hardwareLists(filter: { documentId: { equalTo: $documentId } }) {
            totalCount
          }
        }
      `,
            variables: {
                documentId: this.id
            }
        });
        this.hasHardwareLists = resp.data.hardwareLists.totalCount > 0;
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: true
    }),
    __metadata("design:type", Boolean)
], DeleteCosmicDocument.prototype, "readOnly", void 0);
__decorate([
    Prop({
        required: true
    }),
    __metadata("design:type", typeof (_a = typeof DocumentType !== "undefined" && DocumentType) === "function" ? _a : Object)
], DeleteCosmicDocument.prototype, "cosmicDocumentSubType", void 0);
__decorate([
    Watch('showDialog', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], DeleteCosmicDocument.prototype, "fetchHardwareLists", null);
DeleteCosmicDocument = __decorate([
    Component
], DeleteCosmicDocument);
export default DeleteCosmicDocument;
