import { Mixins } from 'vue-property-decorator';
import { ChangeSetStatus, CosmicDocumentStatus } from '~/db_types';
import CurrentUserMixin from '~/nasa_ui/mixins/CurrentUserMixin';
import { DocumentTypesThatUseInitialization } from '~/nasa_ui/types';
import { isNullOrUndefined } from '~/nasa_ui/utils/helpers/isNullOrUndefined';
// Note: This is not meant to be a mixin. It's meant to be instantiated ourselves by passing in a Document and a relevant UserPermission
export class DocumentStatus extends Mixins(CurrentUserMixin) {
    _entity;
    _adminPermission = null;
    constructor(entity, permission) {
        super();
        this._entity = entity;
        this._adminPermission = permission;
    }
    get additionalUserAccesses() {
        if (!this._entity.attributes) {
            console.error(`can't determine additional user access because document.attributes could not be evaluated. You might need to add this path to your query`);
        }
        return this._entity.attributes?.additionalUserAccesses || [];
    }
    get accessControlAlertText() {
        let reason = this.isExportControlled ? '<strong>Export Controlled</strong>' : '';
        reason += this.isProprietary ? (reason ? ' and <strong>Proprietary</strong>' : '<strong>Proprietary</strong>') : '';
        return reason ? `You do not have full access to this Document because it is ${reason}.` : null;
    }
    get canBeginInitialization() {
        return !this.isReadOnly && this._entity.initializationStatus === null;
    }
    // if this is false, it means that the user shouldn't see most of the data on
    // the document. related documents, comments, and external links are
    // exceptions.
    get currentUserHasAccess() {
        if (!this.isProprietary) {
            return true;
        }
        return this.currentUserIsAnInitializationApprover || this.currentUserIsOnAdditionalAccessList;
    }
    get currentUserIsAnInitializationApprover() {
        if (isNullOrUndefined(this._entity.initializationChangeSet)) {
            console.warn("can't determine if current user is an approver because document.initializationChangeSet.changeSetAuthorities.nodes could not be evaluated. You might need to add this path to your query");
        }
        if (isNullOrUndefined(this._entity.initializationChangeSet?.changeSetAuthorities?.nodes)) {
            return false;
        }
        const authorities = this._entity.initializationChangeSet?.changeSetAuthorities?.nodes;
        const approverGroups = authorities?.map((authority) => authority?.userGroup?.code);
        return this.currentUserGroups?.some((group) => approverGroups?.includes(group.code)) || false;
    }
    get currentUserIsOnAdditionalAccessList() {
        return this.additionalUserAccesses.map((u) => u.userId).includes(this.currentUser?.id || '');
    }
    get documentUsesInitialization() {
        const type = this._entity.subType;
        if (!type) {
            console.error(`can't determine initialization because document object is missing the property for "subType". You might need to add this property to your query`);
            return false;
        }
        return DocumentTypesThatUseInitialization.includes(type);
    }
    get isReadOnly() {
        if (!this._adminPermission) {
            console.error('ADMIN_PERMISSION is not set');
            return true;
        }
        // document is read only if there is a pending initialization
        if (this.documentUsesInitialization && !this.isOpenForEdits) {
            return true;
        }
        // document shouldn't even be editable if it's export controlled or
        // proprietary and the user isn't on the additional access or aprovers
        // lists.
        if (this.isProprietary && !this.currentUserHasAccess) {
            return true;
        }
        if (this.isDocumentClosed || this.isDocumentRejected || this.isPending) {
            return true;
        }
        return !this.currentUserHasPermission(this._adminPermission) || false;
    }
    get isDocumentClosed() {
        if (!this._entity.computedStatus) {
            console.error(`can't determine closure status because document object is missing the property for "computedStatus". You might need to add this property to your query`);
            return false;
        }
        return this._entity.computedStatus === CosmicDocumentStatus.CLOSED;
    }
    get isDocumentRejected() {
        if (!('initializationStatus' in this._entity)) {
            console.error(`can't determine rejection because document object is missing the property for "initializationStatus". You might need to add this property to your query`);
            false;
        }
        return this._entity.initializationStatus === ChangeSetStatus.REJECTED;
    }
    get isExportControlled() {
        return Boolean(this._entity.isExportControlled);
    }
    get isProprietary() {
        return Boolean(this._entity.isProprietary);
    }
    get isInitialized() {
        if (!('initializationStatus' in this._entity)) {
            console.error(`can't determine initialization because document object is missing the property for "initializationStatus". You might need to add this property to your query`);
            false;
        }
        return this._entity.initializationStatus === ChangeSetStatus.APPROVED;
    }
    get initializationChangeSet() {
        return this._entity.initializationChangeSet || null;
    }
    // Doc can be edited as long as initialization status is null
    // Exception is made for WORKFLOWs where we rely on the computed property from the server
    get isOpenForEdits() {
        if (isNullOrUndefined(this._entity.isEditable)) {
            console.error(`can't determine if document can be edited because document object is missing the property for "isEditable". You might need to add this property to your query`);
            false;
        }
        return this._entity.isEditable;
    }
    // There is an initialization change set and its status is OPEN
    get isPending() {
        if (!('initializationStatus' in this._entity)) {
            console.error(`can't determine if document is pending because document object is missing the property for "initializationStatus". You might need to add this property to your query`);
            false;
        }
        return Boolean(this.initializationChangeSet?.id && this._entity.initializationStatus === ChangeSetStatus.OPEN);
    }
    get pendingInitializationChangeSetId() {
        if (this._entity.initializationStatus !== ChangeSetStatus.OPEN) {
            return null;
        }
        return this.initializationChangeSet?.id || null;
    }
}
