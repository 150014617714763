import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins } from 'vue-property-decorator';
import { CosmicDocumentStatus } from '~/db_types';
import BaseView from '~/nasa_ui/base/BaseView';
import ExportControlledOrProprietaryMixin from '~/nasa_ui/mixins/ExportControlledOrProprietaryMixin';
import { UserPermission } from '~/nasa_ui/types/enums/user';
import BaseDocumentInitialization from './BaseDocumentInitialization';
let BaseWorkflow = class BaseWorkflow extends Mixins(BaseDocumentInitialization, BaseView, ExportControlledOrProprietaryMixin) {
    disableStartDocumentInitializationButton = false;
    document = null;
    hasHistoricalApprovals = false;
    isApproving = false;
    isLoading = false;
    isRejecting = false;
    isReopening = false;
    rejectionComment = null;
    showHistorical = false;
    showRejectionModal = false;
    showReviseDocumentModal = false;
    get computedRevisionNumber() {
        return this.cosmicDocumentInfo?.computedRevisionNumber;
    }
    get changeSets() {
        return this.document?.changeSets.nodes || [];
    }
    get cosmicDocumentInfo() {
        return this.document?.cosmicDocumentWorkflow;
    }
    get createdByDisplay() {
        return this.$contactDisplay(this.cosmicDocumentInfo?.createdByUser);
    }
    get createdDateDisplay() {
        return this.$dateTimeDisplay(this.cosmicDocumentInfo?.createdDateTime);
    }
    get documentStatus() {
        return this.document?.cosmicDocumentWorkflow?.computedStatus;
    }
    // since ApprovalsTab gets an entity prop, get the id from that rather than the id prop.
    get entityId() {
        return this.entity?.id || null;
    }
    get hasRejectionComment() {
        return this.rejectionComment && this.rejectionComment.trim() !== '';
    }
    get hasWorkflowAdminPermission() {
        return this.currentUserHasAccess || this.currentUserHasPermission(UserPermission.C_DOCS_WORKFLOWS_ADMIN);
    }
    get isDocumentClosed() {
        return this.document?.cosmicDocumentWorkflow?.computedStatus === CosmicDocumentStatus.CLOSED;
    }
    get shouldShowApprovalHistoryTable() {
        return this.isInitialized && this.computedRevisionNumber && this.computedRevisionNumber > 1;
    }
    get shouldShowGeneralInfoPanel() {
        return Boolean(this.cosmicDocumentInfo);
    }
    get shouldShowInitializationStatusPanel() {
        return Boolean(this.document);
    }
    // refetches at the manage page level, which is where our `entity` prop comes from
    emitRefetchEntity() {
        this.$message('reload_change_set_status_display');
        this.$message('reload_mini');
    }
};
__decorate([
    Emit('refetch-entity'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], BaseWorkflow.prototype, "emitRefetchEntity", null);
BaseWorkflow = __decorate([
    Component
], BaseWorkflow);
export default BaseWorkflow;
