var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { EntityType } from '~/nasa_ui/types/cosmic/entityTypes';
import { HardwareListAssemblyResponseDisplay, transformHardwareListAssemblies } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
/**
 * This component either shows an Inventory level AHD
 *  or
 * shows the `external_____` properties of the modal "HardwareListAssembly"
 */
let ModalHardwareListAssemblyInfo = class ModalHardwareListAssemblyInfo extends Mixins(BaseModal, BaseCosmic) {
    hardwareListAssembly;
    get isExternal() {
        return this.hardwareListAssembly?.isExternal;
    }
    /**
     * The idea here is to differentiate a "real" Inventory from a external inventory
     */
    get computedModalWidth() {
        return this.hardwareListAssembly?.isExternal ? '50vw' : '95vw';
    }
    // For the AHD
    get inventoryNodeId() {
        const nodeId = btoa(`["${EntityType.INVENTORY}","${this.hardwareListAssembly?.inventoryId}"]`);
        return this.hardwareListAssembly?.id ? nodeId : null;
    }
    get transformedHardwareListAssembly() {
        return transformHardwareListAssemblies([this.hardwareListAssembly])[0];
    }
    emitModalClose() { }
};
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof HardwareListAssemblyResponseDisplay !== "undefined" && HardwareListAssemblyResponseDisplay) === "function" ? _a : Object)
], ModalHardwareListAssemblyInfo.prototype, "hardwareListAssembly", void 0);
__decorate([
    Emit('modal-close'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalHardwareListAssemblyInfo.prototype, "emitModalClose", null);
ModalHardwareListAssemblyInfo = __decorate([
    Component
], ModalHardwareListAssemblyInfo);
export default ModalHardwareListAssemblyInfo;
