var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.showGroupsSelector ? _c('AppCosmicUserGroups', _vm._b({
    ref: "app_cosmic_user_groups",
    attrs: {
      "disable-toolbar": _vm.showMipForm,
      "display-type": _vm.AppDynamicWrapperType.FORM_WRAPPER,
      "read-only": false,
      "required": _vm.required,
      "size": _vm.size
    },
    scopedSlots: _vm._u([{
      key: "AppHeader",
      fn: function () {
        return [_c('AppHeader', [_vm._v("Approvals")])];
      },
      proxy: true
    }, !_vm.hideMip ? {
      key: "before_remove_button",
      fn: function () {
        return [_c('div', {
          staticClass: "add_mip_button"
        }, [_c('ButtonGeneric', {
          staticClass: "ma-0 px-2",
          attrs: {
            "disabled": _vm.shouldDisableAddMipButton,
            "flat": "",
            "small": "",
            "tooltip": "Add a Manual Inspection Point"
          },
          on: {
            "click": _vm.onClickOfAddMip
          }
        }, [_vm._v(" MIP+ ")])], 1)];
      },
      proxy: true
    } : null, _vm.showMipForm ? {
      key: "results",
      fn: function () {
        return [_vm.showMipForm ? _c('FormAddMip', {
          attrs: {
            "user-groups": _vm.userGroupsForAddMipForm
          },
          on: {
            "cancel": _vm.onCancelMip,
            "mip_added": _vm.onMipAdded
          }
        }) : _vm._e()];
      },
      proxy: true
    } : null, {
      key: "group_list",
      fn: function () {
        return [_c('v-flex', {
          attrs: {
            "lg10": "",
            "xl8": ""
          }
        }, [_c('AppAuthorityOverrideList', {
          attrs: {
            "read-only": _vm.isReadOnly
          },
          model: {
            value: _vm.authorityOverrides,
            callback: function ($$v) {
              _vm.authorityOverrides = $$v;
            },
            expression: "authorityOverrides"
          }
        })], 1)];
      },
      proxy: true
    }], null, true),
    model: {
      value: _vm.userGroups,
      callback: function ($$v) {
        _vm.userGroups = $$v;
      },
      expression: "userGroups"
    }
  }, 'AppCosmicUserGroups', {
    ..._vm.$attrs,
    ..._vm.$props
  }, false)) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };