import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { sortBy } from 'lodash';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { ChangeSetStatus, DocumentType } from '~/db_types';
import BaseModalWithUser from '~/nasa_ui/base/BaseModalWithUser';
import BaseModalWorkOrderStepCompletion from '~/nasa_ui/base/BaseModalWorkOrderStepCompletion';
import { AlertType, EntityType } from '~/nasa_ui/types';
import { DocumentTypeDisplay } from '~/nasa_ui/types/enums/document';
import { StepApprovalTableHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
let ModalWorkOrderStepComplete = class ModalWorkOrderStepComplete extends Mixins(BaseModalWithUser, BaseModalWorkOrderStepCompletion) {
    AlertType = AlertType;
    EntityType = EntityType;
    document = null;
    formData = {
        authorizedDate: this.$dateDisplay(new Date()),
        comment: null,
        qualityControlStamp: null,
        stepsToApprove: []
    };
    documentId;
    changeSetIds;
    get approvableChangeSets() {
        return this.hydratedChangeSets.filter((changeSet) => {
            return this.userIsMemberOfNextAuthority(changeSet) && !this.stepIsRejected(changeSet);
        });
    }
    get approvalsTableHeaders() {
        const headers = [...StepApprovalTableHeaders].filter((header) => header.value !== '_stepNumber');
        return this.isRca || this.isWorkflow ? headers.slice(0, 5) : headers;
    }
    get documentSubTypeDisplay() {
        // dont have the brain power to figure out this typing
        //   so im mapping and removing the (COSMIC)
        return this.document?.subType ? DocumentTypeDisplay.get(this.document.subType)?.replace('(COSMIC)', '') : '';
    }
    get hasMultipleChangeSetsToComplete() {
        return this.changeSetIds.length > 1;
    }
    get hydratedChangeSets() {
        const changeSets = this.document?.changeSets.nodes
            .map(this.transformWorkOrderStepChangeSet)
            .filter((_) => _);
        return (sortBy(changeSets, (cs) => {
            return cs.workOrderStep?.stepNumber;
        }) || []);
    }
    get isFormValid() {
        if (this.shouldRequireQualityControlStamp && !this.formData.qualityControlStamp) {
            return false;
        }
        if (!this.formData.comment && this.shouldRequireComment) {
            return false;
        }
        if (!this.formData.stepsToApprove?.length) {
            return false;
        }
        if (!this.formData.authorizedDate) {
            return false;
        }
        return true;
    }
    get isRca() {
        return this.document?.subType === DocumentType.C_RCA;
    }
    get isWorkflow() {
        return this.document?.subType === DocumentType.C_WORKFLOW;
    }
    get shouldDisableSubmitButton() {
        return !this.isFormValid || this.isSaving;
    }
    get shouldRequireQualityControlStamp() {
        return this.formData.stepsToApprove.some((stepToApprove) => {
            return stepToApprove?.status?.nextAuthority?.requiresQualityControlStamp;
        });
    }
    get shouldShowQuanlityControlStamp() {
        return this.shouldRequireQualityControlStamp;
    }
    get saveButtonText() {
        const stepCount = this.formData.stepsToApprove.length;
        if (stepCount === 1) {
            const nextAuthorityName = this.formData.stepsToApprove[0]._nextAuthority._userGroupName;
            return `Complete ${this.stepDisplayText} on behalf of ${nextAuthorityName}`;
        }
        return `Complete ${stepCount} ${this.stepDisplayText}${stepCount > 1 ? 's' : ''}`;
    }
    get stepDisplayText() {
        let text = '';
        switch (this.document?.subType) {
            case DocumentType.C_WORKFLOW:
                text = 'Task';
                break;
            case DocumentType.C_RCA:
                text = 'CAP task';
                break;
            default:
                text = 'Step';
                break;
        }
        return text;
    }
    checkboxLabel(step) {
        const group = this.getNextAuthorityGroupName(step);
        return `Approve ${this.stepDisplayText} ${step?.workOrderStep?.stepNumber} as member of <strong>${group}</strong>`;
    }
    async fetchDocumentWithChangeSets(documentId, changeSetIds) {
        try {
            const resp = await this.$apollo.query({
                query: gql `
          ${this.changeSetFragment}

          query FetchDocumentOnStepCompleteModal($documentId: UUID!, $changeSetIds: [Int!]) {
            documentById(id: $documentId) {
              nodeId
              id
              subType

              changeSets(filter: { id: { in: $changeSetIds } }) {
                nodes {
                  ...ChangeSetOnWorkOrderStepCompletion
                }
              }
            }
          }
        `,
                variables: {
                    documentId,
                    changeSetIds
                }
            });
            if (resp.data.documentById) {
                this.document = resp.data.documentById;
                if (!this.hasMultipleChangeSetsToComplete && this.hydratedChangeSets.length === 1) {
                    this.formData.stepsToApprove = [this.hydratedChangeSets[0]];
                }
            }
        }
        catch (err) {
            this.$errorUtility({ err });
        }
    }
    onDocumentIdChange() {
        this.fetchDocumentWithChangeSets(this.documentId, this.changeSetIds);
    }
    getNextAuthorityGroupName(changeSet) {
        return changeSet._nextAuthority._userGroupName;
    }
    onApprovalToggle(step, selected) {
        if (selected) {
            const updated = [...this.formData.stepsToApprove, step];
            this.formData.stepsToApprove = [...new Set(updated)];
        }
        else {
            this.formData.stepsToApprove = this.formData.stepsToApprove.filter((s) => s !== step);
        }
    }
    async onClickOfCompleteWorkOrderStep() {
        this.isSaving = true;
        const resp = await this.completeStepsMutation();
        this.isSaving = false;
        if (resp) {
            this.$notification.add({
                text: `${this.stepDisplayText} approved.`,
                type: AlertType.SUCCESS
            });
            this.$emit('saved');
            this.$message('workorder-steps-updated');
            this.$message('reload_user_alerts');
            this.closeDialog();
        }
    }
    stepIsRejected(changeSet) {
        return changeSet.changeSetAuthorities.nodes.some((csa) => csa?.status === ChangeSetStatus.REJECTED);
    }
    userIsMemberOfNextAuthority(step) {
        return this.isCurrentUserInGroup(step?.status?.nextAuthority?.groupCode);
    }
    async completeStepsMutation() {
        if (!this.isFormValid) {
            this.$notification.add({
                text: 'Completion form is not valid.',
                type: AlertType.ERROR
            });
            return;
        }
        try {
            const resp = await this.$apollo.mutate({
                mutation: gql `
          mutation ApproveWorkOrderStepsOnModal(
            $authorizedDate: Date!
            $changeSetIds: [Int!]
            $comment: String
            $documentId: UUID!
            $qcStamp: String
          ) {
            approveWorkOrderSteps(
              input: {
                authorizedDate: $authorizedDate
                changeSetIds: $changeSetIds
                comment: $comment
                documentId: $documentId
                qualityControlStamp: $qcStamp
              }
            ) {
              workOrderSteps {
                id
                nodeId
              }
            }
          }
        `,
                variables: {
                    authorizedDate: this.formData.authorizedDate,
                    changeSetIds: this.formData.stepsToApprove.map((s) => s?.id),
                    comment: this.formData.comment,
                    documentId: this.documentId,
                    qcStamp: this.formData.qualityControlStamp
                }
            });
            return resp.data?.approveWorkOrderSteps;
        }
        catch (err) {
            this.$errorUtility({ err });
        }
    }
};
__decorate([
    Prop({
        type: String,
        required: true
    }),
    __metadata("design:type", String)
], ModalWorkOrderStepComplete.prototype, "documentId", void 0);
__decorate([
    Prop({
        type: Array,
        required: true
    }),
    __metadata("design:type", Array)
], ModalWorkOrderStepComplete.prototype, "changeSetIds", void 0);
__decorate([
    Watch('documentId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalWorkOrderStepComplete.prototype, "onDocumentIdChange", null);
ModalWorkOrderStepComplete = __decorate([
    Component
], ModalWorkOrderStepComplete);
export default ModalWorkOrderStepComplete;
