var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import BaseView from '~/nasa_ui/base/BaseView';
import { Maybe } from '~/nasa_ui/types';
let ManageViewLayout = class ManageViewLayout extends BaseView {
    breadcrumbs;
    entityNotFound;
    iconColor;
    fetching;
    title;
    get whatFailedToFetchDescription() {
        if (!this.breadcrumbs || !this.breadcrumbs[0]['text']) {
            return '';
        }
        const entityDescription = this.breadcrumbs[0]['text'];
        if (entityDescription.endsWith('s')) {
            return `these ${entityDescription}`;
        }
        else {
            return `this ${entityDescription}`;
        }
        return;
    }
};
__decorate([
    Prop({ type: Array }),
    __metadata("design:type", Object)
], ManageViewLayout.prototype, "breadcrumbs", void 0);
__decorate([
    Prop({ type: Boolean, default: false }),
    __metadata("design:type", Object)
], ManageViewLayout.prototype, "entityNotFound", void 0);
__decorate([
    Prop({ type: String }),
    __metadata("design:type", Object)
], ManageViewLayout.prototype, "iconColor", void 0);
__decorate([
    Prop({ type: Boolean, default: false }),
    __metadata("design:type", Object)
], ManageViewLayout.prototype, "fetching", void 0);
__decorate([
    Prop({ type: String }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], ManageViewLayout.prototype, "title", void 0);
ManageViewLayout = __decorate([
    Component
], ManageViewLayout);
export default ManageViewLayout;
