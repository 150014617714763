import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Vue, Watch } from 'vue-property-decorator';
import colors from 'vuetify/es5/util/colors';
import { LogType } from '~/nasa_ui/types/enums/log';
let StatsPerOrg = class StatsPerOrg extends Vue {
    isLoading = false;
    queryResults = [];
    selectedSubType = LogType.TELEMETRY;
    showSettings = false;
    formData = {
        endDate: null,
        startDate: null,
        subType: LogType.TELEMETRY
    };
    subTypeOptions = [
        { displayText: `Telemetry`, value: LogType.TELEMETRY },
        { displayText: 'Errors', value: LogType.ERROR }
    ];
    get chartColors() {
        const colorList = Object.values(colors)
            .flatMap((color) => [color.base, color.accent1])
            .filter((_) => _ !== undefined);
        return this.selectedSubType === `TELEMETRY` ? colorList.reverse().slice(20) : colorList;
    }
    get chartData() {
        return {
            labels: this.tableItems.map((item) => item._orgCode),
            datasets: [
                {
                    label: 'Total',
                    data: this.tableItems.map((item) => item.totalCount),
                    backgroundColor: this.chartColors
                }
            ]
        };
    }
    get chartOptions() {
        return {
            responsive: true,
            aspectRatio: 4,
            plugins: {
                legend: {
                    display: true
                }
            }
        };
    }
    get computedTableColor() {
        return this.selectedSubType === LogType.TELEMETRY ? 'primary' : 'error darken-1';
    }
    get gqlStatsPerOrgQueryVariables() {
        const dateRange = {};
        if (this.formData.startDate) {
            dateRange.start = {
                value: this.formData.startDate,
                inclusive: true
            };
        }
        if (this.formData.endDate) {
            dateRange.end = {
                value: this.formData.endDate,
                inclusive: true
            };
        }
        return {
            dateRange: Object.keys(dateRange).length ? dateRange : null,
            subType: this.selectedSubType
        };
    }
    get subTypeDisplay() {
        return this.selectedSubType === LogType.TELEMETRY
            ? `Telemetry`
            : this.selectedSubType === LogType.ERROR
                ? `Errors`
                : `?`;
    }
    get tableHeaders() {
        return [
            {
                text: 'Org code',
                value: '_orgCode'
            },
            {
                text: 'Count',
                value: '_count',
                align: 'right'
            }
        ];
    }
    get tableItems() {
        return this.queryResults
            .map((result) => {
            const _orgCode = result.organizationCode;
            const _count = this.$numberDisplay(result.totalCount);
            return {
                _orgCode,
                _count,
                ...result
            };
        })
            .sort((a, b) => (b.totalCount || 0) - (a.totalCount || 0));
    }
    async runQuery() {
        try {
            this.isLoading = true;
            const resp = await this.$apollo.query({
                query: gql `
          query StatsPerOrganization($dateRange: DatetimeRangeInput, $subType: String) {
            metricReport(groupOrganizationCode: true, subType: $subType, dateRange: $dateRange) {
              nodes {
                organizationCode
                totalCount
              }
            }
          }
        `,
                variables: { ...this.gqlStatsPerOrgQueryVariables }
            });
            if (resp.data.metricReport?.nodes) {
                this.queryResults = resp.data.metricReport.nodes;
            }
        }
        catch (err) {
            this.$errorUtility({ err });
        }
        this.isLoading = false;
    }
    updateResults() {
        this.selectedSubType = this.formData.subType;
        this.runQuery();
        this.showSettings = false;
    }
    onRouteHashChange(hash) {
        this.runQuery();
    }
};
__decorate([
    Watch('$route.hash', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], StatsPerOrg.prototype, "onRouteHashChange", null);
StatsPerOrg = __decorate([
    Component
], StatsPerOrg);
export default StatsPerOrg;
