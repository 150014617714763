var _a;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import BaseVue from '~/nasa_ui/base/BaseVue';
import { CosmicInventorySlimFragment } from '~/nasa_ui/DAL/inventory/slim';
import { ItemInstanceRepoMixin } from '~/nasa_ui/DAL/itemInstance';
import CurrentUserMixin from '~/nasa_ui/mixins/CurrentUserMixin';
import ReadOnlyMixin from '~/nasa_ui/mixins/ReadOnlyMixin';
import { Maybe } from '~/nasa_ui/types';
import { sortObjectBy } from '~/nasa_ui/utils';
import { ItemInstancesTableHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
import { transformInventories, transformItemInstances } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
import { AppCosmicWrapperOption } from '../AppCosmicUsers/AppCosmicWrapperOption';
// <AppCosmicItemInstances :readOnly="false" v-model="testing">
//   <template slot="AppPageDescription">This is where i would describe what youre doing</template>
// </AppCosmicItemInstances>
let AppCosmicItemInstances = class AppCosmicItemInstances extends Mixins(ReadOnlyMixin, CurrentUserMixin, BaseVue, ItemInstanceRepoMixin) {
    showHardwareListSearch = false;
    showItemInstanceSearch = true;
    tableHeaders = [...ItemInstancesTableHeaders];
    formData = {
        itemInstanceIds: []
    };
    storage = {
        cache: {
            ii: [],
            hl: []
        },
        toFetch: {
            ii: []
        }
    };
    displayType;
    size;
    value;
    valuesToBeRemoved;
    get computedFormWrapperSize() {
        return this.size;
    }
    get computedTableItems() {
        return this.uniqueSetOfItemInstances;
    }
    get omittedItemInstances() {
        const selectedItemIds = this.uniqueSetOfItemInstances.map((ii) => ii.id);
        const explicitlyOmittedIds = this.valuesToBeRemoved ?? [];
        return uniq([...selectedItemIds, ...explicitlyOmittedIds]);
    }
    get existingHardwareLists() {
        return this.storage.cache.hl.map((hl) => hl.id);
    }
    get hasAppPageDescription__Slot() {
        return this.hasSlot('AppPageDescription');
    }
    get hasAppPageDescription_above_results__Slot() {
        return this.hasSlot('AppPageDescription_above_results');
    }
    get isDisplayTypeExpansionPanel() {
        return this.displayType === AppCosmicWrapperOption.EXPANSION_PANEL;
    }
    get isDisplayTypeFormWrapper() {
        return this.displayType === AppCosmicWrapperOption.FORM_WRAPPER;
    }
    get isDisplayTypeNecked() {
        return this.displayType === AppCosmicWrapperOption.NECKED;
    }
    get uniqueSetOfItemInstances() {
        return uniqBy(this.storage.cache.ii, 'id').sort(sortObjectBy('drawingNumber'));
    }
    onClickOfSearchHardwareList() {
        this.showHardwareListSearch = true;
        this.showItemInstanceSearch = false;
    }
    onClickOfSearchItemInstanceIcon() {
        this.showHardwareListSearch = false;
        this.showItemInstanceSearch = true;
    }
    onClickOfCancelSearch() {
        this.showHardwareListSearch = false;
        this.showItemInstanceSearch = false;
    }
    onClickOfClearAll() {
        this.formData.itemInstanceIds = [];
        this.storage.cache.ii = [];
        this.storage.cache.hl = [];
    }
    onClickOfCurrentItemInstanceTableRow(iiToRemove) {
        if (this.isReadOnly) {
            return;
        }
        this.storage.cache.ii = this.storage.cache.ii.filter((ii) => ii.id !== iiToRemove.id);
    }
    updateWithHardwareList(hardwareList) {
        // Cache it
        this.storage.cache.hl.push(hardwareList);
        const hlAssemblies = hardwareList.hardwareListAssemblies?.nodes || [];
        const assyInventoryItemInstanceIds = hlAssemblies.map((hla) => hla?.inventory?.itemInstanceId);
        if (assyInventoryItemInstanceIds.length) {
            assyInventoryItemInstanceIds.forEach((iiId) => {
                if (!this.isItemInstanceInCacheById(iiId)) {
                    this.storage.toFetch.ii.push(iiId);
                }
            });
        }
    }
    updateWithItemInstance(ii) {
        this.formData.itemInstanceIds.push(ii.id);
        if (this.isItemInstanceInCacheById(ii.id)) {
            return;
        }
        this.storage.cache.ii.push(ii);
    }
    isItemInstanceInCacheById(itemInstanceId) {
        if (!itemInstanceId) {
            return false;
        }
        return Boolean(this.storage.cache.ii.find((ii) => ii.id === itemInstanceId));
    }
    updateFormData(itemInstances) {
        if (!itemInstances) {
            return;
        }
        itemInstances.forEach((ii) => this.updateWithItemInstance(ii));
    }
    emitInput(value) {
        return value.filter((ii) => !this.valuesToBeRemoved?.some((v) => v === ii.id));
    }
    /**
     *
     * @returns Array of transformed Inventories
     */
    async fetchCurrentLocationsForUniqueSetOfItemInstances() {
        try {
            const resp = await this.$apollo.query({
                query: gql `
          ${CosmicInventorySlimFragment}

          query GetInventoriesForItemInstances($ids: [UUID!]) {
            itemInstances(filter: { id: { in: $ids } }) {
              nodes {
                nodeId
                id

                inventories(filter: { quantity: { notEqualTo: 0 } }) {
                  nodes {
                    ...CosmicInventorySlim
                  }
                }
              }
            }
          }
        `,
                variables: {
                    ids: this.uniqueSetOfItemInstances.map((ii) => ii.id)
                }
            });
            if (resp.data.itemInstances?.nodes) {
                return resp.data.itemInstances.nodes.flatMap((ii) => {
                    return transformInventories(ii?.inventories.nodes || []);
                });
            }
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async onToFetchIiUpdate() {
        if (this.storage.toFetch.ii && this.storage.toFetch.ii.length > 0) {
            try {
                const resp = await Promise.all(this.storage.toFetch.ii.map((iiId) => {
                    return this.getItemInstanceById({
                        id: iiId
                    });
                }));
                resp.forEach((fetchedIi) => {
                    if (!this.isItemInstanceInCacheById(fetchedIi?.itemInstanceById?.id)) {
                        const transformedIi = transformItemInstances([fetchedIi.itemInstanceById])[0];
                        this.storage.cache.ii.push(transformedIi);
                    }
                    // Remove ii from toFetch
                    this.storage.toFetch.ii = this.storage.toFetch.ii.filter((uuid) => uuid !== fetchedIi?.itemInstanceById?.id);
                });
            }
            catch (err) {
                this.$errorUtility({
                    err
                });
            }
        }
    }
    onUniqueSetOfItemInstancesChange() {
        this.emitInput(this.uniqueSetOfItemInstances.map((ii) => {
            return {
                ...ii
            };
        }));
        this.fetchCurrentLocationsForUniqueSetOfItemInstances();
    }
    onValueUpdate(itemInstances) {
        this.updateFormData(itemInstances);
    }
};
__decorate([
    Prop({
        type: String,
        default: 'FORM_WRAPPER'
    }),
    __metadata("design:type", String)
], AppCosmicItemInstances.prototype, "displayType", void 0);
__decorate([
    Prop({
        type: String,
        default: '1'
    }),
    __metadata("design:type", String)
], AppCosmicItemInstances.prototype, "size", void 0);
__decorate([
    Prop({
        type: Array
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], AppCosmicItemInstances.prototype, "value", void 0);
__decorate([
    Prop({
        default: () => [],
        type: Array
    }),
    __metadata("design:type", Array)
], AppCosmicItemInstances.prototype, "valuesToBeRemoved", void 0);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], AppCosmicItemInstances.prototype, "emitInput", null);
__decorate([
    Emit('inventories'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], AppCosmicItemInstances.prototype, "fetchCurrentLocationsForUniqueSetOfItemInstances", null);
__decorate([
    Watch('storage.toFetch.ii'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], AppCosmicItemInstances.prototype, "onToFetchIiUpdate", null);
__decorate([
    Watch('uniqueSetOfItemInstances'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppCosmicItemInstances.prototype, "onUniqueSetOfItemInstancesChange", null);
__decorate([
    Watch('value', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], AppCosmicItemInstances.prototype, "onValueUpdate", null);
AppCosmicItemInstances = __decorate([
    Component
], AppCosmicItemInstances);
export default AppCosmicItemInstances;
