var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_vm.isTypeFormWrapper ? _c('AppFormWrapper', _vm._b({}, 'AppFormWrapper', {
    ..._vm.$attrs,
    ..._vm.$props
  }, false), [_vm._t("default")], 2) : _vm._e(), _vm.isTypeExpansionPanel ? _c('AppExpansionPanel', _vm._b({
    scopedSlots: _vm._u([_vm._l(_vm.$slots, function (i, name) {
      return {
        key: name,
        fn: function () {
          return [_vm._t(name)];
        },
        proxy: true
      };
    })], null, true)
  }, 'AppExpansionPanel', {
    ..._vm.$attrs,
    ..._vm.$props
  }, false), [_vm._t("default")], 2) : _vm._e(), _vm.isTypeNone ? [_vm._t("default")] : _vm._e()], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };