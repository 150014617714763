var _a, _b, _c, _d, _e, _f, _g;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { Maybe } from '~/nasa_ui/types';
export const DEFAULT_IMAGE_HEIGHT = 'auto';
export const DEFAULT_PDF_HEIGHT = '600px';
export const DEFAULT_PDF_WIDTH = '100%';
export const DEFAULT_VIDEO_HEIGHT = 'auto';
let AppFilePreview = class AppFilePreview extends Vue {
    pdfPreviewUrl = null;
    showNoPreviewMessage = false;
    videoPreviewUrl = null;
    videoPreviewType = null;
    allowClear;
    downloadUrlForLogging;
    file;
    // fallback height and width
    height;
    width;
    // image height and width
    imgHeight;
    imgWidth;
    // pdf height and width
    pdfHeight;
    pdfWidth;
    // video height and width
    videoHeight;
    videoWidth;
    get computedHeight() {
        return this.height ? this.height : 'inherit';
    }
    get computedImgHeight() {
        return this.imgHeight ? this.imgHeight : this.computedHeight;
    }
    get computedImgWidth() {
        return this.imgWidth ? this.imgWidth : this.computedWidth;
    }
    get computedPdfHeight() {
        return this.pdfHeight || this.computedHeight;
    }
    get computedPdfWidth() {
        return this.pdfWidth ? this.pdfWidth : this.computedWidth;
    }
    get computedVideoHeight() {
        return this.videoHeight || this.computedHeight;
    }
    get computedVideoWidth() {
        return this.videoWidth ? this.videoWidth : this.computedWidth;
    }
    get computedWidth() {
        return this.width ? this.width : 'inherit';
    }
    get contentType() {
        return this.file?.type || null;
    }
    get isFullHeight() {
        return (this.height === '100%' || this.imgHeight === '100%' || this.videoHeight === '100%' || this.pdfHeight === '100%');
    }
    get isImage() {
        return Boolean(this.file?.type.startsWith('image/'));
    }
    get isMicrosoftOffice() {
        return Boolean(['officedocument', 'msword', 'excel', 'powerpoint'].find((type) => this.file?.type.includes(type)));
    }
    get isPdf() {
        return Boolean(this.file?.type.includes('pdf'));
    }
    get isVideo() {
        return Boolean(this.file?.type.startsWith('video/'));
    }
    destroyed() {
        window.removeEventListener('resize', this.windowResizeHandler);
    }
    mounted() {
        // needed on mounted because $refs isn't populated until initial render
        // https://vuejs.org/guide/essentials/lifecycle.html#lifecycle-diagram
        this.updatePreviewArea();
        window.addEventListener('resize', this.windowResizeHandler);
    }
    updatePreviewArea() {
        if (!this.file) {
            return;
        }
        // Preview for imgs
        if (this.isImage) {
            const $img_preview = this.$refs['img_preview'];
            if ($img_preview) {
                $img_preview.src = URL.createObjectURL(this.file);
                $img_preview.onload = function () {
                    URL.revokeObjectURL($img_preview.src); // free memory
                };
            }
        }
        // Preview for vids
        else if (this.isVideo) {
            this.videoPreviewUrl = URL.createObjectURL(this.file);
            this.videoPreviewType = this.file.type;
            this.$nextTick().then(() => {
                const $video_preview_source = this.$refs['video_preview_source'];
                if ($video_preview_source) {
                    const $parent = this.$refs['video_preview_player'];
                    $parent.load();
                    $parent.play();
                    $video_preview_source.onload = function () {
                        URL.revokeObjectURL($video_preview_source.src); // free memory
                    };
                }
            });
        }
        // Preview for pdfs
        else if (this.isPdf) {
            // this.pdfPreviewUrl = URL.createObjectURL(this.file) + '#toolbar=0';
            this.pdfPreviewUrl = URL.createObjectURL(this.file);
            this.$nextTick().then(() => {
                const $pdf_preview = this.$refs['pdf_preview'];
                if ($pdf_preview) {
                    $pdf_preview.onload = () => {
                        if (!this.pdfPreviewUrl) {
                            return;
                        }
                        URL.revokeObjectURL(this.pdfPreviewUrl); // free memory
                    };
                }
            });
        }
        else {
            this.showNoPreviewMessage = true;
        }
    }
    windowResizeHandler() {
        if (this.isPdf && this.pdfHeight === 'dynamic') {
            this.updatePreviewArea();
        }
    }
    emitClear() { }
    onFileChange(file) {
        if (!file) {
            this.showNoPreviewMessage = true;
            return;
        }
        this.showNoPreviewMessage = false;
        this.updatePreviewArea();
    }
    // the following aren't needed and can cause an infinite loop
    // so they are intentionally omitted.
    // @Watch('computedPdfHeight')
    // @Watch('computedVideoHeight')
    onSizeChange(val) {
        this.updatePreviewArea();
    }
    logDownload() {
        return {
            fromUrl: this.$router.currentRoute?.fullPath,
            toUrl: this.downloadUrlForLogging,
            message: 'file-download'
        };
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], AppFilePreview.prototype, "allowClear", void 0);
__decorate([
    Prop({
        type: String,
        required: false
    }),
    __metadata("design:type", String)
], AppFilePreview.prototype, "downloadUrlForLogging", void 0);
__decorate([
    Prop({
        type: File
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], AppFilePreview.prototype, "file", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object)
], AppFilePreview.prototype, "height", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_c = typeof Maybe !== "undefined" && Maybe) === "function" ? _c : Object)
], AppFilePreview.prototype, "width", void 0);
__decorate([
    Prop({
        type: String,
        default: DEFAULT_IMAGE_HEIGHT
    }),
    __metadata("design:type", String)
], AppFilePreview.prototype, "imgHeight", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_d = typeof Maybe !== "undefined" && Maybe) === "function" ? _d : Object)
], AppFilePreview.prototype, "imgWidth", void 0);
__decorate([
    Prop({
        type: String,
        default: DEFAULT_PDF_HEIGHT
    }),
    __metadata("design:type", String)
], AppFilePreview.prototype, "pdfHeight", void 0);
__decorate([
    Prop({
        type: String,
        default: DEFAULT_PDF_WIDTH
    }),
    __metadata("design:type", typeof (_e = typeof Maybe !== "undefined" && Maybe) === "function" ? _e : Object)
], AppFilePreview.prototype, "pdfWidth", void 0);
__decorate([
    Prop({
        type: String,
        default: DEFAULT_VIDEO_HEIGHT
    }),
    __metadata("design:type", String)
], AppFilePreview.prototype, "videoHeight", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_f = typeof Maybe !== "undefined" && Maybe) === "function" ? _f : Object)
], AppFilePreview.prototype, "videoWidth", void 0);
__decorate([
    Emit('clear'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppFilePreview.prototype, "emitClear", null);
__decorate([
    Watch('file'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_g = typeof Maybe !== "undefined" && Maybe) === "function" ? _g : Object]),
    __metadata("design:returntype", void 0)
], AppFilePreview.prototype, "onFileChange", null);
__decorate([
    Watch('computedHeight'),
    Watch('computedImgHeight'),
    Watch('computedImgWidth'),
    Watch('computedPdfWidth'),
    Watch('computedVideoWidth'),
    Watch('computedWidth'),
    Watch('pdfRatio'),
    Watch('videoRatio'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], AppFilePreview.prototype, "onSizeChange", null);
__decorate([
    Emit('log'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppFilePreview.prototype, "logDownload", null);
AppFilePreview = __decorate([
    Component
], AppFilePreview);
export default AppFilePreview;
