import { __decorate } from "tslib";
import { Component, Mixins } from 'vue-property-decorator';
import { ActivityType } from '~/db_types';
import BaseTab from '~/nasa_ui/base/BaseTab';
import ActivityBaseForm from '~/nasa_ui/components/ActivityBaseForm/ActivityBaseForm.vue';
import ActivityCalibrationForm from '~/nasa_ui/components/ActivityCalibrationForm/ActivityCalibrationForm.vue';
import ActivityCheckoutForm from '~/nasa_ui/components/ActivityCheckoutForm/ActivityCheckoutForm.vue';
import ActivityInspectionForm from '~/nasa_ui/components/ActivityInspectionForm/ActivityInspectionForm.vue';
import { ActivityRepoMixin } from '~/nasa_ui/DAL/activity';
import { ActivityTypeDisplay } from '~/nasa_ui/types';
let TabActivityInformation = class TabActivityInformation extends Mixins(BaseTab, ActivityRepoMixin) {
    activity = null;
    get activityType() {
        return this.activity?.subType ?? null;
    }
    get activityTypeDisplay() {
        return this.activityType ? ActivityTypeDisplay.get(this.activityType) : null;
    }
    get activityFormComponentName() {
        const normalizedActivityType = this.activityTypeDisplay ? this.activityTypeDisplay.split(' ').join('') : '';
        switch (this.activityType) {
            case ActivityType.CHECK_OUT:
                return 'ActivityCheckoutForm';
            case ActivityType.CALIBRATION:
            case ActivityType.INSPECTION:
                return `Activity${normalizedActivityType}Form`;
            default:
                return null;
        }
    }
    get activityItemInstanceNodeId() {
        return this.activity?.itemInstance?.nodeId ?? null;
    }
    async fetchEntityById(id) {
        if (!this.id) {
            return;
        }
        try {
            const resp = await this.getActivityById({
                id
            });
            if (resp?.activityById) {
                this.activity = resp.activityById;
            }
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
};
TabActivityInformation = __decorate([
    Component({
        components: {
            ActivityCalibrationForm,
            ActivityCheckoutForm,
            ActivityInspectionForm,
            ActivityBaseForm
        }
    })
], TabActivityInformation);
export default TabActivityInformation;
