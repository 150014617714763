var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import { TpsDocumentType } from '~/db_types';
import { Maybe } from '~/nasa_ui/types';
let AutoTagWatchFormDataMixin = class AutoTagWatchFormDataMixin extends Vue {
    formData = {};
    addTag(tag) {
        this.formData.tags = Array.from(new Set([...(this.formData.tags || []), tag]));
    }
    removeTag(tag) {
        this.formData.tags = (this.formData.tags || []).filter((t) => t !== tag);
    }
    onMaybeStringChange(newVal, oldVal) {
        this.formData.tags = (this.formData.tags || []).filter((tag) => tag !== oldVal);
        if (newVal && !this.formData.tags.includes(newVal)) {
            this.addTag(newVal);
        }
    }
    onisExportControlledChange() {
        if (this.formData.isExportControlled) {
            this.addTag('EXPORT_CONTROLLED');
        }
        else {
            this.removeTag('EXPORT_CONTROLLED');
        }
    }
    onisProprietaryChange() {
        if (this.formData.isProprietary) {
            this.addTag('PROPRIETARY');
        }
        else {
            this.removeTag('PROPRIETARY');
        }
    }
    onisHazardousChange() {
        if (this.formData.isHazardous) {
            this.addTag('HAZARDOUS');
        }
        else {
            this.removeTag('HAZARDOUS');
        }
    }
    ontpsTypeChange() {
        if (this.formData.tpsType) {
            this.formData.tags = (this.formData.tags || []).filter((tag) => !Object.values(TpsDocumentType).includes(tag));
            this.addTag(this.formData.tpsType);
        }
    }
};
__decorate([
    Watch('formData.contractNumber'),
    Watch('formData.projectCode'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object, typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], AutoTagWatchFormDataMixin.prototype, "onMaybeStringChange", null);
__decorate([
    Watch('formData.isExportControlled'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AutoTagWatchFormDataMixin.prototype, "onisExportControlledChange", null);
__decorate([
    Watch('formData.isProprietary'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AutoTagWatchFormDataMixin.prototype, "onisProprietaryChange", null);
__decorate([
    Watch('formData.isHazardous'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AutoTagWatchFormDataMixin.prototype, "onisHazardousChange", null);
__decorate([
    Watch('formData.tpsType'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AutoTagWatchFormDataMixin.prototype, "ontpsTypeChange", null);
AutoTagWatchFormDataMixin = __decorate([
    Component
], AutoTagWatchFormDataMixin);
export default AutoTagWatchFormDataMixin;
