// Register components you would like to globally be accessible here
import Vue from 'vue';
// Example:
// import HelloWorld from "./HelloWorld.vue";
// Vue.component("HelloWorld", HelloWorld);
// Load every component from shared/components
//@ts-ignore
const req = require.context('./', true, /\.(vue)$/i);
for (const key of req.keys()) {
    const name = key.match(/\w+/)[0];
    Vue.component(name, req(key).default);
}
//@ts-ignore
const nasaUiComponents = require.context('../nasa_ui/components', true, /\.(vue)$/i);
for (const key of nasaUiComponents.keys()) {
    const name = key.match(/\w+/)[0];
    Vue.component(name, nasaUiComponents(key).default);
}
//@ts-ignore
const nasaUiButtons = require.context('../nasa_ui/components/buttons', true, /\.(vue)$/i);
for (const key of nasaUiButtons.keys()) {
    const name = key.match(/\w+/)[0];
    Vue.component(name, nasaUiButtons(key).default);
}
//@ts-ignore
const nasaUiInputs = require.context('../nasa_ui/components/inputs', true, /\.(vue)$/i);
for (const key of nasaUiInputs.keys()) {
    const name = key.match(/\w+/)[0];
    Vue.component(name, nasaUiInputs(key).default);
}
