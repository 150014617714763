var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { ActivityType, CosmicActivityFragment, Side } from '~/db_types';
import BaseVue from '~/nasa_ui/base/BaseVue';
import { DB_DEFAULT } from '~/nasa_ui/constants';
import { ActivityRepoMixin } from '~/nasa_ui/DAL/activity';
import ExpandableMixin from '~/nasa_ui/mixins/ExpandableMixin';
import { EntityType } from '~/nasa_ui/types';
import { isNullOrUndefined } from '~/nasa_ui/utils/helpers/isNullOrUndefined';
import { transformActivities } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let SummaryCardActivity = class SummaryCardActivity extends Mixins(ExpandableMixin, BaseVue, ActivityRepoMixin) {
    localActivity = null;
    isNullOrUndefined = isNullOrUndefined;
    DB_DEFAULT = DB_DEFAULT;
    Side = Side;
    activity;
    activityId;
    get activityItemInstance() {
        return this.transformedActivity?._itemInstance ?? null;
    }
    get activityItemInstanceNodeId() {
        return this.activityItemInstance?.nodeId;
    }
    get computedIcon() {
        const subTypeIcon = this.$icons[this.localActivity?.subType];
        const defaultIcon = this.$icons[EntityType.ACTIVITY];
        if (!this.localActivity?.subType || subTypeIcon === undefined) {
            return defaultIcon;
        }
        return subTypeIcon;
    }
    get hasUpdatedDate() {
        return Boolean(this.localActivity?.updatedDateTime);
    }
    get isActivityCalibrationType() {
        return Boolean(this.localActivity?.subType === ActivityType.CALIBRATION);
    }
    get isActivityCheckOutType() {
        return Boolean(this.localActivity?.subType === ActivityType.CHECK_OUT);
    }
    get isActivityInspectionType() {
        return Boolean(this.localActivity?.subType === ActivityType.INSPECTION);
    }
    get transformedActivity() {
        return this.localActivity ? transformActivities([this.localActivity])[0] : null;
    }
    async onIdUpdate() {
        if (this.activityId) {
            const resp = await this.getActivityById({
                id: this.activityId
            });
            this.localActivity = resp?.activityById ?? null;
        }
    }
    onActivityObjUpdate() {
        if (this.activity) {
            this.localActivity = { ...this.activity };
        }
    }
};
__decorate([
    Prop({
        required: false,
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof CosmicActivityFragment !== "undefined" && CosmicActivityFragment) === "function" ? _a : Object)
], SummaryCardActivity.prototype, "activity", void 0);
__decorate([
    Prop({
        required: false,
        type: String
    }),
    __metadata("design:type", Object)
], SummaryCardActivity.prototype, "activityId", void 0);
__decorate([
    Watch('activityId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], SummaryCardActivity.prototype, "onIdUpdate", null);
__decorate([
    Watch('activity', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], SummaryCardActivity.prototype, "onActivityObjUpdate", null);
SummaryCardActivity = __decorate([
    Component
], SummaryCardActivity);
export default SummaryCardActivity;
