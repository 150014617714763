var _a;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { isNullOrUndefined } from 'util';
import { Component, Emit, Prop } from 'vue-property-decorator';
import { CalibrationType, Maybe } from '~/db_types';
import BaseModalWithUser from '~/nasa_ui/base/BaseModalWithUser';
import { CosmicIntervalFragment } from '~/nasa_ui/DAL/base/interval';
import { UserPermission } from '~/nasa_ui/types/enums/user';
let ModalCalibrationRequirementBase = class ModalCalibrationRequirementBase extends BaseModalWithUser {
    fetchEntity(id) {
        throw new Error('Method not implemented.');
    }
    CalibrationType = CalibrationType;
    formData = {
        subType: null,
        number: null,
        value: null,
        description: null,
        rangeType: null,
        rangeValue: null
    };
    selectedComment = null;
    formHasErrors = false;
    isFetching = false;
    isSaving = false;
    isRemoving = false;
    existingCalibrationTypes;
    value;
    get canEditCalibrationRequirements() {
        return this.currentUserHasPermission(UserPermission.ITEM_MASTER_INVENTORY_QUOTAS_ADMIN);
    }
    get isEdit() {
        return !isNullOrUndefined(this.value?.id);
    }
    get isFormValid() {
        return this.isEdit
            ? Boolean(this.value &&
                this.value.subType &&
                this.value.value !== null &&
                this.value.value !== undefined &&
                !this.formHasErrors)
            : Boolean(this.formData &&
                this.formData.subType &&
                this.formData.value !== null &&
                this.formData.value !== undefined &&
                !this.formHasErrors);
    }
    get shouldDisableSaveButton() {
        return !this.isFormValid;
    }
    async createCalibrationRequirementMutation(input) {
        return this.$apollo.mutate({
            mutation: gql `
        ${CosmicIntervalFragment}

        mutation createCalibrationQuota($input: CreateCalibrationQuotaInput!) {
          createCalibrationQuota(input: $input) {
            calibrationQuota {
              id
              nodeId
              subType
              number
              value {
                ...CosmicInterval
              }
              description
              rangeType
              rangeValue
            }
          }
        }
      `,
            variables: {
                input
            }
        });
    }
    async createEntity(calibrationRequirementPayload) {
        if (!calibrationRequirementPayload) {
            return;
        }
        try {
            this.isSaving = true;
            const resp = await this.createCalibrationRequirementMutation(calibrationRequirementPayload);
            if (resp.errors) {
                throw resp.errors;
            }
            this.$notification.add({
                type: 'success',
                text: 'Calibration requirement created.'
            });
            this.isSaving = false;
        }
        catch (err) {
            this.$errorUtility({
                err,
                backupErrorText: 'Failed to create calibration requirement.'
            });
            this.isSaving = false;
        }
    }
    deleteEntity(id) {
        if (!this.formData || !id) {
            return;
        }
        return this.$apollo.mutate({
            mutation: gql `
        mutation deleteCalibrationQuotaById($input: DeleteCalibrationQuotaByIdInput!) {
          deleteCalibrationQuotaById(input: $input) {
            clientMutationId
          }
        }
      `,
            variables: {
                input: { id: id }
            }
        });
    }
    async editCalibrationRequirementMutation(input) {
        return this.$apollo.mutate({
            mutation: gql `
        ${CosmicIntervalFragment}

        mutation UpdateCalibrationQuotaByIdOnItemMasterCalibrationTab($input: UpdateCalibrationQuotaByIdInput!) {
          updateCalibrationQuotaById(input: $input) {
            calibrationQuota {
              id
              nodeId
              subType
              number
              value {
                ...CosmicInterval
              }
              description
              rangeType
              rangeValue
            }
          }
        }
      `,
            variables: {
                input
            }
        });
    }
    async editEntity(updateCalibrationRequirementPayload) {
        const input = {
            patch: updateCalibrationRequirementPayload,
            id: this.value?.id
        };
        try {
            this.isSaving = true;
            const resp = await this.editCalibrationRequirementMutation(input);
            if (resp.errors) {
                throw resp.errors;
            }
            this.$notification.add({
                type: 'success',
                text: 'Calibration requirement updated.'
            });
            this.isSaving = false;
        }
        catch (err) {
            this.$errorUtility({
                err,
                backupErrorText: 'Failed to update calibration requirement.'
            });
            this.isSaving = false;
        }
    }
    onError(hasErrors) {
        this.formHasErrors = hasErrors;
    }
    emitModalClose() { }
};
__decorate([
    Prop({
        type: Array
    }),
    __metadata("design:type", Array)
], ModalCalibrationRequirementBase.prototype, "existingCalibrationTypes", void 0);
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], ModalCalibrationRequirementBase.prototype, "value", void 0);
__decorate([
    Emit('modal-close'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalCalibrationRequirementBase.prototype, "emitModalClose", null);
ModalCalibrationRequirementBase = __decorate([
    Component
], ModalCalibrationRequirementBase);
export default ModalCalibrationRequirementBase;
