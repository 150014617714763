var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "container pa-1 mt-2"
  }, [_c('SearchInputContainer', {
    attrs: {
      "autofocus": _vm.autofocus,
      "darkText": "",
      "disable-search": _vm.disableSearch,
      "entity-type": _vm.EntityType.RISK,
      "hint": _vm.hint,
      "is-loading": _vm.isLoading,
      "placeholder": _vm.placeholder
    },
    on: {
      "searchButtonClicked": _vm.onClickOfSearchButton,
      "enterPressed": _vm.onClickOfSearchButton
    },
    model: {
      value: _vm.searchInput,
      callback: function ($$v) {
        _vm.searchInput = $$v;
      },
      expression: "searchInput"
    }
  }), _c('SearchFilterDisplay', {
    attrs: {
      "active": _vm.shouldShowFilters,
      "color": _vm.EntityType.RISK,
      "entity-type": _vm.EntityType.RISK,
      "chipsFromFilters": _vm.chipsFromFilters,
      "show-filter-button": _vm.showFilterButton
    },
    on: {
      "appChipCloseClicked": _vm.onClickOfRemoveFilter,
      "clearAllFilters": _vm.onClearAllFilters,
      "filterButtonClicked": _vm.onClickOfFilterIcon
    }
  }), _c('SearchFilterContainer', {
    attrs: {
      "color": _vm.EntityType.RISK,
      "show": _vm.shouldShowFilters
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs6": "",
      "pr-3": ""
    }
  }, [_c('AppSelectEnum', {
    attrs: {
      "label": "Hardware",
      "enum": _vm.RiskHardwareType,
      "display": _vm.RiskHardwareTypeDisplay,
      "sort": "",
      "multiple": ""
    },
    model: {
      value: _vm.searchFilters.hardwareType,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "hardwareType", $$v);
      },
      expression: "searchFilters.hardwareType"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs6": ""
    }
  }, [_c('AppSelectEnum', {
    attrs: {
      "label": "Catetgory",
      "enum": _vm.RiskCategory,
      "display": _vm.RiskCategoryDisplay,
      "sort": "",
      "multiple": ""
    },
    model: {
      value: _vm.searchFilters.category,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "category", $$v);
      },
      expression: "searchFilters.category"
    }
  })], 1)], 1), _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs6": "",
      "pr-3": ""
    }
  }, [_c('AppContact', {
    attrs: {
      "label": "Owner"
    },
    model: {
      value: _vm.searchFilters.ownerContactId,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "ownerContactId", $$v);
      },
      expression: "searchFilters.ownerContactId"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs6": ""
    }
  }, [_c('AppContact', {
    attrs: {
      "label": "Stakeholder"
    },
    model: {
      value: _vm.searchFilters.stakeholderContactId,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "stakeholderContactId", $$v);
      },
      expression: "searchFilters.stakeholderContactId"
    }
  })], 1)], 1), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs6": "",
      "pr-3": ""
    }
  }, [_c('AppSelectEnum', {
    attrs: {
      "label": "Closure type",
      "enum": _vm.RiskClosureType,
      "display": _vm.RiskClosureTypeDisplay,
      "sort": "",
      "multiple": ""
    },
    model: {
      value: _vm.searchFilters.closureType,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "closureType", $$v);
      },
      expression: "searchFilters.closureType"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs6": ""
    }
  }, [_c('AppSelectEnum', {
    attrs: {
      "label": "Reportability",
      "enum": _vm.RiskReportability,
      "display": _vm.RiskReportabilityDisplay,
      "multiple": ""
    },
    model: {
      value: _vm.searchFilters.showOnReports,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "showOnReports", $$v);
      },
      expression: "searchFilters.showOnReports"
    }
  })], 1)], 1), _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs3": ""
    }
  }, [_c('AppCheckbox', {
    attrs: {
      "label": "Supplier?"
    },
    model: {
      value: _vm.searchFilters.isSupplier,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "isSupplier", $$v);
      },
      expression: "searchFilters.isSupplier"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs3": ""
    }
  }, [_c('AppCheckbox', {
    attrs: {
      "label": "Include Archived?"
    },
    model: {
      value: _vm.searchFilters.includeArchived,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "includeArchived", $$v);
      },
      expression: "searchFilters.includeArchived"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs3": ""
    }
  }, [_c('AppCheckbox', {
    attrs: {
      "label": "Is Closed?"
    },
    model: {
      value: _vm.searchFilters.isClosed,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "isClosed", $$v);
      },
      expression: "searchFilters.isClosed"
    }
  })], 1)], 1)], 1), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.risks ? _c('AppTableActions', {
    attrs: {
      "color": _vm.EntityType.RISK,
      "dark": false,
      "headers": _vm.riskHeaders,
      "items": _vm.tableItems,
      "loading": _vm.isLoading,
      "dense-rows": "",
      "disable-initial-sort": "",
      "item-key": "id",
      "rows-per-page-items": [25, 50, 100],
      "show-count": "",
      "show-search-input": "",
      "sticky": "",
      "title": "Risks"
    },
    scopedSlots: _vm._u([{
      key: "_cause",
      fn: function ({
        item
      }) {
        return [_c('AppMarkdownDisplay', {
          attrs: {
            "markdown": item.cause
          }
        })];
      }
    }, {
      key: "_effect",
      fn: function ({
        item
      }) {
        return [_c('AppMarkdownDisplay', {
          attrs: {
            "markdown": item.effect
          }
        })];
      }
    }, {
      key: "_reportability",
      fn: function ({
        item
      }) {
        return [_c('AppRiskReportabilityDisplay', {
          attrs: {
            "show-on-reports": item.showOnReports
          }
        })];
      }
    }, {
      key: "_riskScore",
      fn: function ({
        item
      }) {
        return [_c('AppRiskScoreDisplay', {
          attrs: {
            "risk": item
          }
        })];
      }
    }, {
      key: "actions",
      fn: function ({
        item
      }) {
        return [_c('ButtonMiniActionManage', {
          attrs: {
            "to": `/risks/manage/${item.id}#information`
          }
        })];
      }
    }], null, false, 1937771308)
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };