var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.DOCUMENT,
      "content-class": "related_documents_create",
      "hide-title-section": ""
    },
    on: {
      "modal-close": _vm.emitModalClose
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs12": "",
            "mb-3": ""
          }
        }, [_vm.selectedDocumentsTableItems.length ? _c('v-flex', {
          attrs: {
            "xs12": "",
            "mb-3": ""
          }
        }, [_vm.duplicateDocumentNumbersError ? _c('Alert', {
          attrs: {
            "type": _vm.AlertType.ERROR
          }
        }, [_vm._v(" One or more of your selections are duplicates of an existing related Document. ")]) : _vm._e(), _c('AppTableActions', {
          attrs: {
            "color": _vm.EntityType.DOCUMENT,
            "items": _vm.selectedDocumentsTableItems,
            "headers": _vm.computedTableHeaders,
            "title": _vm.selectedDocumentsTableTitle
          },
          scopedSlots: _vm._u([{
            key: "actions",
            fn: function ({
              item
            }) {
              return [_c('ButtonMiniActionRemoveFromList', {
                on: {
                  "click": function ($event) {
                    return _vm.removeSelectedDocument(item);
                  }
                }
              })];
            }
          }], null, false, 3779570515)
        })], 1) : _vm._e(), _c('AppPageDescription', {
          attrs: {
            "color": _vm.AlertType.INFO,
            "remove-margin-left": ""
          }
        }, [_vm._v(" You can connect Documents by searching for them in " + _vm._s(_vm.currentSystemName) + " or via manual input. ")]), _c('v-btn-toggle', {
          attrs: {
            "mandatory": ""
          },
          model: {
            value: _vm.inputMode,
            callback: function ($$v) {
              _vm.inputMode = $$v;
            },
            expression: "inputMode"
          }
        }, [_c('v-btn', {
          staticClass: "white--text",
          attrs: {
            "color": _vm.EntityType.DOCUMENT
          }
        }, [_c('i', {
          staticClass: "mr-2",
          class: _vm.$icons.search
        }), _vm._v(" Document search ")]), _c('v-btn', [_c('i', {
          staticClass: "fal fa-i-cursor mr-2"
        }), _vm._v(" Manual input ")]), _c('v-btn', [_c('i', {
          staticClass: "fal fa-eye-slash mr-2"
        }), _vm._v(" Hide all ")])], 1)], 1), _c('v-slide-x-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [_vm.inputMode === 0 ? _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('SearchDocument', {
          attrs: {
            "existing-values": _vm.existingDocumentIds,
            "keep-search-results-open": "",
            "output-mode": "object"
          },
          on: {
            "input": _vm.onClickOfSearchResult
          }
        })], 1) : _vm._e()], 1), _c('v-slide-x-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [_vm.inputMode === 1 ? _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs6": ""
          }
        }, [_c('AppListString', {
          attrs: {
            "color": _vm.EntityType.DOCUMENT,
            "error-messages": _vm.duplicateDocumentNumbersError,
            "value-color": _vm.EntityType.DOCUMENT,
            "label": "Document number",
            "messages": "Enter any Document number. You may enter multiple Document numbers."
          },
          model: {
            value: _vm.manualDocumentNumbers,
            callback: function ($$v) {
              _vm.manualDocumentNumbers = $$v;
            },
            expression: "manualDocumentNumbers"
          }
        })], 1)], 1)], 1) : _vm._e()], 1), _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('AppBoolean', {
          attrs: {
            "hide-unknown": "",
            "label": "Are the above Documents Source or Reference Documents?",
            "true-text": "Source",
            "false-text": "Reference"
          },
          model: {
            value: _vm.isSourceDocument,
            callback: function ($$v) {
              _vm.isSourceDocument = $$v;
            },
            expression: "isSourceDocument"
          }
        })], 1)], 1), _vm.inputMode === 0 ? _c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }) : _vm._e(), _c('v-snackbar', {
          staticClass: "related_docs_snackbar",
          attrs: {
            "color": _vm.snackbarError ? _vm.AlertType.ERROR : _vm.AlertType.INFO,
            "timeout": 1500,
            "absolute": "",
            "bottom": "",
            "right": ""
          },
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v;
            },
            expression: "snackbar"
          }
        }, [_vm.snackbarError ? _c('span', [_vm._v("Duplicate selected Document")]) : _c('span', [_vm._v("Selected Document")])])];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonCreate', {
          attrs: {
            "disabled": _vm.shouldDisableCreateButton,
            "loading": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfCreate
          }
        }, [_vm._v(" Connect related Documents ")]), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        })];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };