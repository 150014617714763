var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('div', {
    staticClass: "d-flex row wrap"
  }, [_c('AppCheckbox', {
    attrs: {
      "color": _vm.EntityType.DOCUMENT,
      "label": `Retain ${_vm.stepsCount} ${_vm.stepLabel}?`
    },
    model: {
      value: _vm.formData.cloneSteps,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "cloneSteps", $$v);
      },
      expression: "formData.cloneSteps"
    }
  }), _vm.formData.cloneSteps && !_vm.hideHardwareAndTools ? _c('AppCheckbox', {
    attrs: {
      "color": _vm.EntityType.DOCUMENT,
      "label": "Retain step Hardware"
    },
    model: {
      value: _vm.formData.cloneHardware,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "cloneHardware", $$v);
      },
      expression: "formData.cloneHardware"
    }
  }) : _vm._e(), _vm.formData.cloneSteps && !_vm.hideHardwareAndTools ? _c('AppCheckbox', {
    attrs: {
      "color": _vm.EntityType.DOCUMENT,
      "label": "Retain step Tools"
    },
    model: {
      value: _vm.formData.cloneTools,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "cloneTools", $$v);
      },
      expression: "formData.cloneTools"
    }
  }) : _vm._e(), _vm.formData.cloneSteps ? _c('AppCheckbox', {
    attrs: {
      "color": _vm.EntityType.DOCUMENT,
      "label": "Retain step Approvals"
    },
    model: {
      value: _vm.formData.cloneApprovals,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "cloneApprovals", $$v);
      },
      expression: "formData.cloneApprovals"
    }
  }) : _vm._e(), _vm.formData.cloneSteps ? _c('AppCheckbox', {
    attrs: {
      "color": _vm.EntityType.DOCUMENT,
      "label": "Retain step Attachments"
    },
    model: {
      value: _vm.formData.cloneAttachments,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "cloneAttachments", $$v);
      },
      expression: "formData.cloneAttachments"
    }
  }) : _vm._e()], 1), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.formData.cloneSteps ? _c('WorkOrderStepsOverview', {
    attrs: {
      "document-id": _vm.documentId,
      "document-type": _vm.documentType,
      "steps": _vm.steps,
      "strike-approvals": !_vm.formData.cloneApprovals,
      "strike-attachments": !_vm.formData.cloneAttachments,
      "strike-hardware": !_vm.formData.cloneHardware,
      "strike-tools": !_vm.formData.cloneTools,
      "hide-go-to-step": ""
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };