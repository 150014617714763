var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm._m(0);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    attrs: {
      "id": "page_not_found"
    }
  }, [_c('h2', {
    staticClass: "text-xs-center"
  }, [_vm._v("Page not found")])]);
}];
export { render, staticRenderFns };