import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { CosmicAttachmentFragment as CosmicAttachmentFragmentOverride } from '~/nasa_ui/DAL/attachments';
import { CosmicUserSlimFragment } from '~/nasa_ui/DAL/user/slim';
import ReadOnlyMixin from '~/nasa_ui/mixins/ReadOnlyMixin';
import { FileAttachmentTableHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
import { transformAttachments } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let WorkOrderStepAttachments = class WorkOrderStepAttachments extends Mixins(ReadOnlyMixin) {
    attachments = [];
    documentId = null;
    documentAttachmentIds = [];
    isLoading = false;
    isSaving = false;
    shouldShowAttachmentForm = false;
    shouldShowDocumentAttachmentForm = false;
    selectedAttachment = null;
    tableHeaders = FileAttachmentTableHeaders;
    workOrderStepId;
    get attachmentIds() {
        return this.attachments.map((a) => a.id);
    }
    get computedTableItems() {
        return transformAttachments(this.attachments);
    }
    onClickOfTableRow(row) {
        if (this.isReadOnly) {
            return;
        }
        this.selectedAttachment = row;
        this.shouldShowAttachmentForm = true;
    }
    onModalClose() {
        this.shouldShowAttachmentForm = false;
        this.shouldShowDocumentAttachmentForm = false;
        this.selectedAttachment = null;
    }
    async onStepAttachmentCreated(attachmentId) {
        this.isSaving = true;
        try {
            await this.addAttachmentToDocumentMutation(attachmentId);
            return this.fetchAttachments();
        }
        catch (err) {
            this.$errorUtility({ err });
        }
        this.isSaving = false;
    }
    async fetchAttachments() {
        this.isLoading = true;
        try {
            const res = await this.getDocumentAttachments();
            this.attachments = res.data.workOrderStepById?.attachments.nodes;
            this.documentId = res.data.workOrderStepById?.document?.id;
            this.documentAttachmentIds = res.data.workOrderStepById?.document?.attachments.nodes.map((a) => a?.id) ?? [];
        }
        catch (err) {
            this.$errorUtility({ err });
        }
        this.isLoading = false;
    }
    addAttachmentToDocumentMutation(attachmentId) {
        const mutation = gql `
      mutation LinkAttachmentToDocument($input: SetDocumentAttachmentsInput!) {
        setDocumentAttachments(input: $input) {
          clientMutationId
        }
      }
    `;
        const input = {
            attachmentIds: [...this.documentAttachmentIds, attachmentId],
            documentId: this.documentId
        };
        return this.$apollo.mutate({ mutation, variables: { input } });
    }
    async getDocumentAttachments() {
        const query = gql `
      ${CosmicUserSlimFragment}
      ${CosmicAttachmentFragmentOverride}

      query GetAttachmentsForWorkOrderStepTab($id: Int!) {
        workOrderStepById(id: $id) {
          id
          nodeId
          document {
            id
            nodeId
            attachments {
              nodes {
                id
                nodeId
              }
            }
          }
          attachments {
            nodes {
              ...CosmicAttachment
            }
          }
        }
      }
    `;
        return this.$apollo.query({
            query,
            variables: { id: this.workOrderStepId }
        });
    }
};
__decorate([
    Prop({
        required: true,
        type: Number
    }),
    __metadata("design:type", Number)
], WorkOrderStepAttachments.prototype, "workOrderStepId", void 0);
__decorate([
    Watch('workOrderStepId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], WorkOrderStepAttachments.prototype, "fetchAttachments", null);
WorkOrderStepAttachments = __decorate([
    Component
], WorkOrderStepAttachments);
export default WorkOrderStepAttachments;
