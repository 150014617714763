var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_vm.isDisplayTypeFormWrapper ? _c('AppFormWrapper', {
    attrs: {
      "size": _vm.computedFormWrapperSize,
      "color": _vm.EntityType.ITEM_INSTANCE
    }
  }, [_vm.hasAppPageDescription__Slot ? _c('AppPageDescription', {
    staticClass: "mb-4",
    attrs: {
      "color": "primary",
      "remove-margin-left": ""
    }
  }, [_vm._t("AppPageDescription")], 2) : _vm._e(), !_vm.readOnly ? _c('div', {
    staticClass: "cosmic_action_container mt-1 mb-3 ml-2"
  }, [_c('ButtonMiniAction', {
    attrs: {
      "tooltip": "Add 1 Item Instance at a time",
      "tooltip-position": "right"
    },
    on: {
      "click": _vm.onClickOfSearchItemInstanceIcon
    }
  }, [[_c('c-icon-fa-stacked', [_c('c-icon-fa', {
    attrs: {
      "slot": "main",
      "icon-color": "var(--v-item_instances-base)",
      "icon": _vm.$icons[_vm.EntityType.ITEM_INSTANCE]
    },
    slot: "main"
  }), _c('div', {
    attrs: {
      "slot": "stack_right"
    },
    slot: "stack_right"
  }, [_c('c-icon-fa', {
    attrs: {
      "icon-size": "12px",
      "icon": _vm.$icons.search
    }
  })], 1)], 1)]], 2), _c('ButtonMiniAction', {
    attrs: {
      "tooltip": "Add an entire Hardware List at once",
      "tooltip-position": "right"
    },
    on: {
      "click": _vm.onClickOfSearchHardwareList
    }
  }, [_c('c-icon-fa-stacked', [_c('c-icon-fa', {
    attrs: {
      "slot": "main",
      "icon-color": "var(--v-hardware_lists-base)",
      "icon": _vm.$icons[_vm.EntityType.HARDWARE_LIST]
    },
    slot: "main"
  }), _c('div', {
    attrs: {
      "slot": "stack_right"
    },
    slot: "stack_right"
  }, [_c('c-icon-fa', {
    attrs: {
      "icon-size": "12px",
      "icon": _vm.$icons.search
    }
  })], 1)], 1)], 1), _c('ButtonMiniAction', {
    attrs: {
      "disabled": !_vm.showHardwareListSearch && !_vm.showItemInstanceSearch,
      "tooltip-position": "right",
      "tooltip": "Hide all active searches"
    },
    on: {
      "click": _vm.onClickOfCancelSearch
    }
  }, [_c('c-icon-fa-stacked', [_c('c-icon-fa', {
    attrs: {
      "slot": "main",
      "icon": _vm.$icons.search
    },
    slot: "main"
  }), _c('div', {
    attrs: {
      "slot": "stack_right"
    },
    slot: "stack_right"
  }, [_c('c-icon-fa', {
    attrs: {
      "icon-size": "12px",
      "icon": _vm.$icons.close
    }
  })], 1)], 1)], 1), _c('ButtonMiniActionRemoveFromList', {
    attrs: {
      "disabled": !_vm.value || _vm.value.length === 0,
      "tooltip": "Remove all Item Instances",
      "tooltip-position": "right"
    },
    on: {
      "click": _vm.onClickOfClearAll
    }
  })], 1) : _vm._e(), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.computedTableItems.length === 0 ? _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_vm._t("no_items", function () {
    return [_c('Alert', {
      attrs: {
        "type": "info"
      }
    }, [_c('p', [_vm._v("No Item Instances selected.")])])];
  })], 2) : _vm._e()], 1), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.computedTableItems.length && _vm.hasAppPageDescription_above_results__Slot ? _c('v-flex', {
    attrs: {
      "xs12": "",
      "pb-0": ""
    }
  }, [_c('AppPageDescription', {
    staticClass: "mt-3",
    attrs: {
      "color": _vm.EntityType.USER,
      "remove-margin-left": ""
    }
  }, [_vm._t("AppPageDescription_above_results")], 2)], 1) : _vm._e()], 1), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.computedTableItems.length ? _c('v-flex', {
    staticClass: "mb-2",
    attrs: {
      "xs12": ""
    }
  }, [_vm._t("results", function () {
    return [_c('AppTableActions', {
      attrs: {
        "dense-rows": "",
        "color": _vm.EntityType.ITEM_INSTANCE,
        "items": _vm.computedTableItems,
        "headers": _vm.tableHeaders,
        "show-header": false
      },
      scopedSlots: _vm._u([{
        key: "actions",
        fn: function ({
          item
        }) {
          return [_c('ButtonMiniActionRemoveFromList', {
            attrs: {
              "tooltip-position": "left"
            },
            on: {
              "click": function ($event) {
                return _vm.onClickOfCurrentItemInstanceTableRow(item);
              }
            }
          })];
        }
      }], null, false, 3468776484)
    })];
  }, {
    "items": _vm.computedTableItems
  })], 2) : _vm._e()], 1)], 1), !_vm.isReadOnly ? _c('div', [_c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.showItemInstanceSearch ? _c('div', [_c('AppPageDescription', {
    attrs: {
      "color": "warning",
      "remove-margin-left": ""
    }
  }, [_vm._v(" Selecting Item Instances one at a time. ")]), _c('SearchItemInstance', {
    attrs: {
      "existing-values": _vm.omittedItemInstances,
      "keep-search-results-open": "",
      "output-mode": "object",
      "selectable-rows": ""
    },
    on: {
      "input": _vm.updateWithItemInstance
    }
  })], 1) : _vm._e()]), _c('v-slide-y-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.showHardwareListSearch ? _c('div', [_c('AppPageDescription', {
    attrs: {
      "color": "warning",
      "remove-margin-left": ""
    }
  }, [_vm._v(" Every Item Instance in the selected Hardware List will be added. ")]), _c('SearchHardwareList', {
    attrs: {
      "keep-search-results-open": "",
      "output-mode": "object",
      "selectable-rows": ""
    },
    on: {
      "input": _vm.updateWithHardwareList
    }
  })], 1) : _vm._e()])], 1) : _vm._e()], 1) : _vm.isDisplayTypeExpansionPanel ? _c('AppExpansionPanel', {
    attrs: {
      "color": _vm.EntityType.ITEM_INSTANCE,
      "is-open": ""
    }
  }, [_vm.hasAppPageDescription__Slot ? _c('AppPageDescription', {
    staticClass: "mb-4",
    attrs: {
      "color": "primary",
      "remove-margin-left": ""
    }
  }, [_vm._t("AppPageDescription")], 2) : _vm._e(), !_vm.readOnly ? _c('div', {
    staticClass: "cosmic_action_container my-3 ml-1"
  }, [_c('ButtonMiniAction', {
    attrs: {
      "tooltip": "Add 1 Item Instance at a time",
      "tooltip-position": "right"
    },
    on: {
      "click": _vm.onClickOfSearchItemInstanceIcon
    }
  }, [[_c('c-icon-fa-stacked', [_c('c-icon-fa', {
    attrs: {
      "slot": "main",
      "icon-color": "var(--v-item_instances-base)",
      "icon": _vm.$icons[_vm.EntityType.ITEM_INSTANCE]
    },
    slot: "main"
  }), _c('div', {
    attrs: {
      "slot": "stack_right"
    },
    slot: "stack_right"
  }, [_c('c-icon-fa', {
    attrs: {
      "icon-size": "12px",
      "icon": _vm.$icons.search
    }
  })], 1)], 1)]], 2), _c('ButtonMiniAction', {
    attrs: {
      "tooltip": "Add an entire Hardware List at once",
      "tooltip-position": "right"
    },
    on: {
      "click": _vm.onClickOfSearchHardwareList
    }
  }, [_c('c-icon-fa-stacked', [_c('c-icon-fa', {
    attrs: {
      "slot": "main",
      "icon-color": "var(--v-hardware_lists-base)",
      "icon": _vm.$icons[_vm.EntityType.HARDWARE_LIST]
    },
    slot: "main"
  }), _c('div', {
    attrs: {
      "slot": "stack_right"
    },
    slot: "stack_right"
  }, [_c('c-icon-fa', {
    attrs: {
      "icon-size": "12px",
      "icon": _vm.$icons.search
    }
  })], 1)], 1)], 1), _c('ButtonMiniAction', {
    attrs: {
      "disabled": !_vm.showHardwareListSearch && !_vm.showItemInstanceSearch,
      "tooltip-position": "right",
      "tooltip": "Hide all active searches"
    },
    on: {
      "click": _vm.onClickOfCancelSearch
    }
  }, [_c('c-icon-fa-stacked', [_c('c-icon-fa', {
    attrs: {
      "slot": "main",
      "icon": _vm.$icons.search
    },
    slot: "main"
  }), _c('div', {
    attrs: {
      "slot": "stack_right"
    },
    slot: "stack_right"
  }, [_c('c-icon-fa', {
    attrs: {
      "icon-size": "12px",
      "icon": _vm.$icons.close
    }
  })], 1)], 1)], 1), _c('ButtonMiniActionRemoveFromList', {
    attrs: {
      "disabled": !_vm.value || _vm.value.length === 0,
      "tooltip": "Remove all Item Instances",
      "tooltip-position": "right"
    },
    on: {
      "click": _vm.onClickOfClearAll
    }
  })], 1) : _vm._e(), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.computedTableItems.length === 0 ? _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_vm._t("no_items", function () {
    return [_c('Alert', {
      attrs: {
        "type": "info"
      }
    }, [_c('p', [_vm._v("No Item Instances selected.")])])];
  })], 2) : _vm._e()], 1), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.computedTableItems.length && _vm.hasAppPageDescription_above_results__Slot ? _c('v-flex', {
    attrs: {
      "xs12": "",
      "pb-0": ""
    }
  }, [_c('AppPageDescription', {
    staticClass: "mt-3",
    attrs: {
      "color": _vm.EntityType.USER,
      "remove-margin-left": ""
    }
  }, [_vm._t("AppPageDescription_above_results")], 2)], 1) : _vm._e()], 1), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.computedTableItems.length ? _c('v-flex', {
    staticClass: "mb-2",
    attrs: {
      "xs12": ""
    }
  }, [_vm._t("results", function () {
    return [_c('AppTable', {
      attrs: {
        "color": _vm.EntityType.ITEM_INSTANCE,
        "items": _vm.computedTableItems,
        "headers": _vm.tableHeaders,
        "selectable-rows": !_vm.isReadOnly,
        "show-header": false
      },
      on: {
        "rowClicked": _vm.onClickOfCurrentItemInstanceTableRow
      }
    })];
  }, {
    "items": _vm.computedTableItems
  })], 2) : _vm._e()], 1)], 1), _c('div', [_c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.showItemInstanceSearch ? _c('div', [_c('AppPageDescription', {
    attrs: {
      "color": "warning",
      "remove-margin-left": ""
    }
  }, [_vm._v(" Selecting Item Instances one at a time. ")]), _c('SearchItemInstance', {
    attrs: {
      "existing-values": _vm.omittedItemInstances,
      "keep-search-results-open": "",
      "output-mode": "object",
      "selectable-rows": ""
    },
    on: {
      "input": _vm.updateWithItemInstance
    }
  })], 1) : _vm._e()]), _c('v-slide-y-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.showHardwareListSearch ? _c('div', [_c('AppPageDescription', {
    attrs: {
      "color": "warning",
      "remove-margin-left": ""
    }
  }, [_vm._v(" Every Item Instance in the selected Hardware List will be added. ")]), _c('SearchHardwareList', {
    attrs: {
      "keep-search-results-open": "",
      "output-mode": "object",
      "selectable-rows": ""
    },
    on: {
      "input": _vm.updateWithHardwareList
    }
  })], 1) : _vm._e()])], 1)], 1) : _vm.isDisplayTypeNecked ? _c('div', [_vm.hasAppPageDescription__Slot ? _c('AppPageDescription', {
    staticClass: "mb-4",
    attrs: {
      "color": "primary",
      "remove-margin-left": ""
    }
  }, [_vm._t("AppPageDescription")], 2) : _vm._e(), !_vm.readOnly ? _c('div', {
    staticClass: "cosmic_action_container my-3 ml-1"
  }, [_c('ButtonMiniAction', {
    attrs: {
      "tooltip": "Add 1 piece of hardware at a time",
      "tooltip-position": "right"
    },
    on: {
      "click": _vm.onClickOfSearchItemInstanceIcon
    }
  }, [[_c('c-icon-fa-stacked', [_c('c-icon-fa', {
    attrs: {
      "slot": "main",
      "icon-color": "var(--v-item_instances-base)",
      "icon": _vm.$icons[_vm.EntityType.ITEM_INSTANCE]
    },
    slot: "main"
  }), _c('div', {
    attrs: {
      "slot": "stack_right"
    },
    slot: "stack_right"
  }, [_c('c-icon-fa', {
    attrs: {
      "icon-size": "12px",
      "icon": _vm.$icons.search
    }
  })], 1)], 1)]], 2), _c('ButtonMiniAction', {
    attrs: {
      "tooltip": "Add an entire Hardware List at once",
      "tooltip-position": "right"
    },
    on: {
      "click": _vm.onClickOfSearchHardwareList
    }
  }, [_c('c-icon-fa-stacked', [_c('c-icon-fa', {
    attrs: {
      "slot": "main",
      "icon-color": "var(--v-hardware_lists-base)",
      "icon": _vm.$icons[_vm.EntityType.HARDWARE_LIST]
    },
    slot: "main"
  }), _c('div', {
    attrs: {
      "slot": "stack_right"
    },
    slot: "stack_right"
  }, [_c('c-icon-fa', {
    attrs: {
      "icon-size": "12px",
      "icon": _vm.$icons.search
    }
  })], 1)], 1)], 1), _c('ButtonMiniAction', {
    attrs: {
      "disabled": !_vm.showHardwareListSearch && !_vm.showItemInstanceSearch,
      "tooltip-position": "right",
      "tooltip": "Hide all active searches"
    },
    on: {
      "click": _vm.onClickOfCancelSearch
    }
  }, [_c('c-icon-fa-stacked', [_c('c-icon-fa', {
    attrs: {
      "slot": "main",
      "icon": _vm.$icons.search
    },
    slot: "main"
  }), _c('div', {
    attrs: {
      "slot": "stack_right"
    },
    slot: "stack_right"
  }, [_c('c-icon-fa', {
    attrs: {
      "icon-size": "12px",
      "icon": _vm.$icons.close
    }
  })], 1)], 1)], 1), _c('ButtonMiniActionRemoveFromList', {
    attrs: {
      "disabled": !_vm.value || _vm.value.length === 0,
      "tooltip": "Remove all hardware",
      "tooltip-position": "right"
    },
    on: {
      "click": _vm.onClickOfClearAll
    }
  })], 1) : _vm._e(), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.computedTableItems.length === 0 ? _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_vm._t("no_items", function () {
    return [_c('Alert', {
      attrs: {
        "type": "info"
      }
    }, [_c('p', [_vm._v("No hardware selected.")])])];
  })], 2) : _vm._e()], 1), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.computedTableItems.length && _vm.hasAppPageDescription_above_results__Slot ? _c('v-flex', {
    attrs: {
      "xs12": "",
      "pb-0": ""
    }
  }, [_c('AppPageDescription', {
    staticClass: "mt-3",
    attrs: {
      "color": _vm.EntityType.USER,
      "remove-margin-left": ""
    }
  }, [_vm._t("AppPageDescription_above_results")], 2)], 1) : _vm._e()], 1), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.computedTableItems.length ? _c('v-flex', {
    staticClass: "mb-2",
    attrs: {
      "xs12": ""
    }
  }, [_vm._t("results", function () {
    return [_c('AppTableActions', {
      attrs: {
        "dense-rows": "",
        "color": _vm.EntityType.ITEM_INSTANCE,
        "items": _vm.computedTableItems,
        "headers": _vm.tableHeaders,
        "show-header": false
      },
      scopedSlots: _vm._u([{
        key: "actions",
        fn: function ({
          item
        }) {
          return [_c('ButtonMiniActionRemoveFromList', {
            on: {
              "click": function ($event) {
                return _vm.onClickOfCurrentItemInstanceTableRow(item);
              }
            }
          })];
        }
      }], null, false, 3863136052)
    })];
  }, {
    "items": _vm.computedTableItems
  })], 2) : _vm._e()], 1)], 1), _c('div', [_c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.showItemInstanceSearch ? _c('div', [_c('AppPageDescription', {
    attrs: {
      "color": "warning",
      "remove-margin-left": ""
    }
  }, [_vm._v(" Selecting hardware one at a time. ")]), _c('SearchItemInstance', {
    attrs: {
      "existing-values": _vm.omittedItemInstances,
      "keep-search-results-open": "",
      "output-mode": "object",
      "selectable-rows": ""
    },
    on: {
      "input": _vm.updateWithItemInstance
    }
  })], 1) : _vm._e()]), _c('v-slide-y-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.showHardwareListSearch ? _c('div', [_c('AppPageDescription', {
    attrs: {
      "color": "warning",
      "remove-margin-left": ""
    }
  }, [_vm._v(" Every item in the selected Hardware List will be added. ")]), _c('SearchHardwareList', {
    attrs: {
      "keep-search-results-open": "",
      "output-mode": "object",
      "selectable-rows": ""
    },
    on: {
      "input": _vm.updateWithHardwareList
    }
  })], 1) : _vm._e()])], 1)], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };