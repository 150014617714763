import { Mixins } from 'vue-property-decorator';
import { ChangeSetStatus, CosmicDocumentStatus } from '~/db_types';
import CurrentUserMixin from '~/nasa_ui/mixins/CurrentUserMixin';
import GenericDocumentMixin from '~/nasa_ui/mixins/GenericDocumentMixin';
import { UserPermission } from '~/nasa_ui/types/enums/user';
import { isNullOrUndefined } from '~/nasa_ui/utils/helpers/isNullOrUndefined';
import { transformChangeSetAuthorities } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
// Note: This is not meant to be a mixin. It's meant to be instantiated ourselves by passing in a Document and a WorkOrderStep array
export class StepsStatus extends Mixins(CurrentUserMixin, GenericDocumentMixin) {
    _document;
    _steps;
    constructor(steps, document) {
        super();
        this._document = document;
        this._steps = steps;
    }
    get canCreateStep() {
        const editable = this._document?.isEditable;
        if (isNullOrUndefined(editable)) {
            console.error(`can't determine if new step can be created because document object does not contain a property for "isEditable". You might need to add this to your query`);
            return false;
        }
        return this._document?.isEditable;
    }
    get allStepsComplete() {
        if (isNullOrUndefined(this._document.computedStatus)) {
            console.error(`can't determine if all steps are complete because document object does not contain a property for "computedStatus". You might need to add this to your query`);
            return false;
        }
        return this._document.computedStatus === CosmicDocumentStatus.WORK_ORDER_STEPS_COMPLETE;
    }
    get canEditHardwareList() {
        return this.currentUserHasPermission(UserPermission.HARDWARE_LIST_ADMIN);
    }
    get currentUserCanApproveAnySteps() {
        return this._steps.some((step) => {
            const nextAuthority = step.changeSet?.status?.nextAuthority?.groupCode;
            const stepIsRejected = step?.changeSet?.changeSetAuthorities.nodes.some((csa) => csa?.status === ChangeSetStatus.REJECTED);
            return this.isCurrentUserInGroup(nextAuthority) && !stepIsRejected;
        });
    }
    canEditStep(step) {
        const stepIsEditable = step.isEditable;
        if (isNullOrUndefined(stepIsEditable)) {
            console.error(`can't determine if step can be edited because step object does not contain a property for "isEditable". You might need to add this to your query`);
            return false;
        }
        return stepIsEditable && this.canCreateStep;
    }
    canReopenStep(step) {
        const adminGroup = this.getCorrespondingAdminGroup(this._document.subType);
        const isAdmin = this.currentUserHasPermission(adminGroup);
        if (isNullOrUndefined(step.changeSet?.changeSetAuthorities?.nodes)) {
            console.error(`can't determine if step can be reopened because "step.changeSet.changeSetAuthorities.nodes" could not be evaluated. You might need to add this property to your query`);
            return false;
        }
        // the computed value for `isReopenable` only tells us if we can commit the reopen mutation without error
        // but we want to hide the button if there aren't any approvals otherwise you'd click it
        // and it wouldn't go away
        const hasAnyApprovals = step
            .changeSet.changeSetAuthorities.nodes.filter((a) => !a?.isHistorical)
            .some((a) => a?.status !== ChangeSetStatus.OPEN);
        return step.isReopenable && hasAnyApprovals && isAdmin;
    }
    stepCanBeApproved(step) {
        if (this._document.computedStatus !== CosmicDocumentStatus.OPEN) {
            return false;
        }
        if (step.computedStatus !== ChangeSetStatus.OPEN) {
            return false;
        }
        if (!this.currentUserHasApprovalPermission(step)) {
            return false;
        }
        return true;
    }
    currentUserHasApprovalPermission(step) {
        try {
            const nextAuthority = step.changeSet.status.nextAuthority?.groupCode;
            return this.isCurrentUserInGroup(nextAuthority);
        }
        catch (err) {
            console.error(`can't determine if user can approve step because "step.changeSet.status.nextAuthority.groupCode" could not be evaluated. You might need to add this path to your query`);
            return false;
        }
    }
    getHistoricalChangeSetAuthorities(step) {
        try {
            const csas = step.changeSet.changeSetAuthorities.nodes;
            return transformChangeSetAuthorities(csas).filter((csa) => csa.isHistorical && csa.status !== ChangeSetStatus.OPEN);
        }
        catch (error) {
            console.error(`can't get historical changeset authorities because "step.changeSet.changeSetAuthorities.nodes" could not be evaluated. You might need to add this path to your query`);
            return null;
        }
    }
    stepIsCompleted(step) {
        if (isNullOrUndefined(step.computedStatus)) {
            console.error(`can't determine if step is complete because step object does not contain a property of "computedStatus". You might need to add this property to your query`);
            return false;
        }
        return step.computedStatus === ChangeSetStatus.APPROVED;
    }
    stepIsRejected(step) {
        if (isNullOrUndefined(step.computedStatus)) {
            console.error(`can't determine if step is rejected because step object does not contain a property of "computedStatus". You might need to add this property to your query`);
            return false;
        }
        return step.computedStatus === ChangeSetStatus.REJECTED;
    }
}
