var _a;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Emit, Mixins, Watch } from 'vue-property-decorator';
import { ChangeSetStatus, ChangeSetType, DocumentType } from '~/db_types';
import BaseDocumentInitialization from '~/nasa_ui/base/BaseDocumentInitialization';
import BaseTab from '~/nasa_ui/base/BaseTab';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { CosmicChangeSetAuthoritySlimFragment } from '~/nasa_ui/DAL/changeSet/slim';
import { CosmicContactSlimFragment } from '~/nasa_ui/DAL/contact/slim';
import { CosmicDocumentSlimFragment } from '~/nasa_ui/DAL/document/slim';
import { CosmicStepSlimFragment } from '~/nasa_ui/DAL/document/WorkOrderStep';
import { UserGroupWithAssignedUsers } from '~/nasa_ui/DAL/user';
import { CosmicUserGroupSlimFragment, CosmicUserSlimFragment } from '~/nasa_ui/DAL/user/slim';
import ExportControlledOrProprietaryMixin from '~/nasa_ui/mixins/ExportControlledOrProprietaryMixin';
import { AlertType, Maybe } from '~/nasa_ui/types';
import { ApprovalHistoryTableHeaders, StepApprovalTableHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
import { transformChangeSetAuthorities } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
import { buildChangeSetStatusIcon } from '~/nasa_ui/utils/ui/buildIconAsString';
function scrollToTop() {
    window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
    });
}
let TabApprovals = class TabApprovals extends Mixins(BaseDocumentInitialization, ExportControlledOrProprietaryMixin, BaseTab) {
    ApprovalHistoryTableHeaders = ApprovalHistoryTableHeaders;
    disableStartDocumentInitializationButton = false;
    document = null;
    showHistorical = false;
    hasHistoricalApprovals = false;
    isApproving = false;
    isRejecting = false;
    isReopening = false;
    rejectionComment = null;
    showRejectionModal = false;
    showReviseDocumentModal = false;
    StepApprovalTableHeaders = StepApprovalTableHeaders;
    approvalFormData = {
        comment: null,
        qualityControlStamp: null
    };
    // get all authorities from all change sets. only count the first rejected CSA per change set.
    get allAuthorities() {
        return this.changeSets
            .reduce((authorities, changeSet) => {
            if (!changeSet.changeSetAuthorities.nodes.length) {
                return authorities;
            }
            // add a calculated revision number to each CSA
            let authoritiesOnChangeSet = changeSet.changeSetAuthorities.nodes.map((csa) => {
                return {
                    ...csa,
                    revisionNumber: changeSet.computedDocumentRevisionNumber || null,
                    stepNumber: changeSet.workOrderStep?.stepNumber || null
                };
            });
            let hasRejectedAuthority = false;
            authoritiesOnChangeSet = authoritiesOnChangeSet.filter((csa) => {
                if (hasRejectedAuthority) {
                    return false;
                }
                if (csa?.status === ChangeSetStatus.REJECTED && !csa.isHistorical) {
                    hasRejectedAuthority = true;
                }
                return true;
            });
            return authorities.concat(authoritiesOnChangeSet);
        }, [])
            .sort((a, b) => new Date(b.authorizedDate).getTime() - new Date(a.authorizedDate).getTime());
    }
    get approvalHistoryTableItems() {
        const approvalsSansSteps = transformChangeSetAuthorities(this.allAuthorities.filter((authority) => authority.subType !== ChangeSetType.WORK_ORDER_STEP));
        return approvalsSansSteps.map((authority) => {
            return {
                ...authority,
                _revisionNumber: authority.revisionNumber ? `${authority.revisionNumber}` : DEFAULT_DASH
            };
        });
    }
    get approvalsTableHeaders() {
        return;
    }
    get approvalsTableItemsCurrentRevision() {
        if (!this.computedRevisionNumber) {
            return [];
        }
        return this.approvalHistoryTableItems.filter((item) => item.revisionNumber === this.computedRevisionNumber);
    }
    get computedRevisionNumber() {
        return this.cosmicDocumentInfo?.computedRevisionNumber;
    }
    get computedLastCommentForInit() {
        return this.currentInitChangeSet?.computedLastComment?.comment;
    }
    get changeSets() {
        return this.document?.changeSets.nodes || [];
    }
    get cosmicDocumentInfo() {
        return (this.document?.cosmicDocumentDr || this.document?.cosmicDocumentEzt || this.document?.cosmicDocumentTps || null);
    }
    get createdByDisplay() {
        return this.$contactDisplay(this.cosmicDocumentInfo?.createdByUser);
    }
    get createdDateDisplay() {
        return this.$dateTimeDisplay(this.cosmicDocumentInfo?.createdDateTime);
    }
    get currentInitChangeSet() {
        const changeSet = this.changeSets.find((cs) => cs.subType === ChangeSetType.COSMIC_DOCUMENT_INITIALIZATION &&
            cs.computedDocumentRevisionNumber === this.cosmicDocumentInfo?.computedRevisionNumber);
        return changeSet || null;
    }
    get currentRevisionCreatedOnDateDisplay() {
        return this.$dateTimeDisplay(this.currentInitChangeSet?.createdDateTime);
    }
    get emailLinkToSend() {
        if (!this.usersToEmail) {
            return '';
        }
        return `mailto:${this.usersToEmail.join(';')}?subject=${this.$cosmicSystem}%3A%20A%20change%20set%20requires%20attention&body=ATTN ${this.nextAuthority?.userGroup?.name}:%0D%0A%0D%0AYou%20are%20receiving%20this%20because%20you%20are%20a%20member%20of%20the%20group%20${this.nextAuthority?.userGroup?.name}.%0D%0A%0D%0AA%20${this.$cosmicSystem}%20change%20set%20requires%20someone's%20attention.%0D%0A%0D%0A${window.location.href}%0D%0A%0D%0A`;
    }
    // since ApprovalsTab gets an entity prop, get the id from that rather than the id prop.
    get entityId() {
        return this.entity?.id || null;
    }
    get gqlApproveAuthorityInput() {
        if (!this.nextAuthority) {
            return null;
        }
        const input = {
            authorizedDate: this.$dateDisplay(new Date()),
            changeSetAuthorityId: this.nextAuthority.id,
            status: ChangeSetStatus.APPROVED
        };
        this.requiredApprovalFields.forEach((field) => (input[field] = this.approvalFormData[field]));
        return input;
    }
    get gqlRejectAuthorityInput() {
        if (!this.nextAuthority) {
            return null;
        }
        return {
            authorizedDate: this.$dateDisplay(new Date()),
            comment: this.rejectionComment,
            changeSetAuthorityId: this.nextAuthority.id,
            status: ChangeSetStatus.REJECTED
        };
    }
    get hasRejectionComment() {
        return this.rejectionComment && this.rejectionComment.trim() !== '';
    }
    get initializationButtonText() {
        return this.document?.subType === DocumentType.C_WORKFLOW ? 'Start Workflow' : 'Start Document Initialization';
    }
    get initiatorDisplay() {
        return this.$contactDisplay(this.cosmicDocumentInfo?.initiatorContact);
    }
    // represents the Change Set Authority which is next in line to approve/reject
    get nextAuthority() {
        return this.currentInitChangeSet?.status?.nextAuthority || null;
    }
    get nextAuthorityRequiresComment() {
        return Boolean(this.nextAuthority?.requiresComment);
    }
    get nextAuthorityRequiresNoData() {
        return !this.nextAuthority?.requiresComment && !this.nextAuthority?.requiresQualityControlStamp;
    }
    get nextAuthorityRequiresQualityControlStamp() {
        return Boolean(this.nextAuthority?.requiresQualityControlStamp);
    }
    get requiredApprovalFields() {
        const required = [];
        if (this.nextAuthorityRequiresComment) {
            required.push('comment');
        }
        if (this.nextAuthorityRequiresQualityControlStamp) {
            required.push('qualityControlStamp');
        }
        return required;
    }
    get shouldDisableApproveButton() {
        return (this.isApproving || this.isRejecting || this.requiredApprovalFields.some((field) => !this.approvalFormData[field]));
    }
    get shouldDisableRejectButton() {
        return this.isRejecting || this.isApproving || !this.rejectionComment;
    }
    get shouldShowApprovalForm() {
        return this.userIsInNextAuthority;
    }
    get shouldShowApprovalFormPanel() {
        return Boolean(this.nextAuthority);
    }
    get shouldShowApprovalsTablePanel() {
        return true;
    }
    get shouldShowGeneralInfoPanel() {
        return Boolean(this.cosmicDocumentInfo);
    }
    get shouldShowInitializationStatusPanel() {
        return Boolean(this.document);
    }
    get stepApprovalTableItems() {
        const stepApprovals = this.allAuthorities
            .filter((authority) => authority.subType === ChangeSetType.WORK_ORDER_STEP &&
            authority.status !== ChangeSetStatus.OPEN &&
            !authority.isHistorical)
            .map((authority) => {
            return {
                ...authority,
                _stepNumber: authority.stepNumber ? `${authority.stepNumber}` : null
            };
        });
        return transformChangeSetAuthorities(stepApprovals);
    }
    get userIsInNextAuthority() {
        return this.isCurrentUserInGroup(this.nextAuthority?.groupCode);
    }
    get userNamesTitleForEmailButton() {
        return `Send an email to:\n\n${this.userNamesToDisplay}`;
    }
    get userNamesToDisplay() {
        const selectedPendingAuthorityUserGroupUsers = (this.nextAuthority?.userGroup?.usersToUserGroups?.nodes ||
            []);
        // Sort by last name descending
        const sorted = selectedPendingAuthorityUserGroupUsers.sort((a, b) => {
            const aLastName = a.user?.lastName || '';
            const bLastName = b.user?.lastName || '';
            return ('' + aLastName).localeCompare(bLastName);
        });
        // Pluck just the names
        const nameStrings = sorted.map((userGroup) => {
            return `${userGroup.user?.firstName || ''} ${userGroup?.user?.lastName || ''}`;
        });
        // Join like this so everything lines up nicely
        return nameStrings.join('\n');
    }
    get usersToEmail() {
        return this.$flatten(this.nextAuthority?.userGroup?.usersToUserGroups?.nodes.map((userGroup) => userGroup?.user?.email));
    }
    async fetchEntityById(id) {
        this.isLoading = true;
        try {
            const resp = await this.fetchChangeSetsQuery(id);
            if (resp.data.documentById) {
                this.document = resp.data.documentById;
            }
        }
        catch (err) {
            this.$errorUtility({ err });
        }
        this.isLoading = false;
    }
    // called from ui
    onClickOfApprove() {
        this.approveNextAuthority();
    }
    // called from ui
    async onClickOfStartDocumentInitialization() {
        if (this.document?.subType === DocumentType.C_WORKFLOW) {
            // fire off changeSet immediately and approve (Fast track)
            this.disableStartDocumentInitializationButton = true;
            try {
                const resp = await this.createInitializationChangeSet();
                const changeSetAuthorityId = resp?.data?.createChangeSet?.changeSet?.status?.nextAuthority?.id;
                await this.approveNextAuthority(changeSetAuthorityId);
                this.disableStartDocumentInitializationButton = false;
            }
            catch (err) {
                this.$errorUtility({ err });
            }
        }
        else {
            this.showDocumentInitializationModal = true;
        }
    }
    // called from ui
    onClickOfReject() {
        this.showRejectionModal = true;
    }
    // called from ui
    async onClickOfReopen() {
        this.showReviseDocumentModal = true;
    }
    // called from ui
    onCloseOfReject() {
        this.showRejectionModal = false;
    }
    // called from ui
    onCloseOfReviseDocument() {
        this.showReviseDocumentModal = false;
    }
    // called from ui - perform the doc init rejection
    onConfirmRejection() {
        this.rejectNextAuthority();
    }
    // called from ui
    onHasHistoricalApprovalsChange(val) {
        this.hasHistoricalApprovals = val;
    }
    async reopenDocumentForEdits() {
        if (!this.entityId) {
            return;
        }
        try {
            this.isReopening = true;
            await this.reopenDocumentForEditsMutation(this.entityId);
            this.isReopening = false;
            this.$notification.add({ text: 'Reopened Document for edits.', type: AlertType.SUCCESS });
            this.emitRefetchEntity();
        }
        catch (err) {
            this.$errorUtility({ err });
        }
    }
    syncRejectionComment(val) {
        this.rejectionComment = val;
    }
    onChangeOfEntity(entity) {
        if (!entity?.id) {
            return;
        }
        this.fetchEntityById(entity.id);
    }
    // refetches at the manage page level, which is where our `entity` prop comes from
    emitRefetchEntity() {
        this.$message('reload_change_set_status_display');
        this.$message('reload_mini');
        this.$message('reload_user_alerts');
    }
    // approve next authority
    async approveNextAuthority(changeSetAuthorityId) {
        if (!this.gqlApproveAuthorityInput && !changeSetAuthorityId) {
            this.$notification.add({ type: AlertType.ERROR, text: 'Failed to approve Document Initialization.' });
            return;
        }
        this.isApproving = true;
        if (!this.nextAuthority || !this.gqlApproveAuthorityInput) {
            return;
        }
        const payload = changeSetAuthorityId
            ? {
                authorizedDate: this.$dateDisplay(new Date()),
                changeSetAuthorityId: changeSetAuthorityId,
                status: ChangeSetStatus.APPROVED
            }
            : this.gqlApproveAuthorityInput;
        try {
            const resp = await this.updateChangeSetAuthorityMutation(payload);
            if (resp?.data) {
                this.$notification.add({ type: AlertType.SUCCESS, text: 'Document Initialization approved.' });
                this.emitRefetchEntity();
                this.resetApprovalForm();
                scrollToTop();
            }
        }
        catch (err) {
            this.$errorUtility({ err });
        }
        this.isApproving = false;
    }
    // WORKFLOWS need to be approved after init changeset is created
    createInitializationChangeSet() {
        if (!this.entity || !this.currentUserDelegateGroup?.code) {
            return;
        }
        const initializationChangeSetPayload = {
            openedDate: this.$dateDisplay(new Date()),
            authoritiesOverride: [
                {
                    groupCode: this.currentUserDelegateGroup.code,
                    requiresComment: false,
                    requiresQualityControlStamp: false
                }
            ],
            subType: ChangeSetType.COSMIC_DOCUMENT_INITIALIZATION,
            documentId: this.entity.id
        };
        return this.$apollo.mutate({
            mutation: gql `
        mutation createWorkflowInitializationChangeSet($input: CreateChangeSetInput!) {
          createChangeSet(input: $input) {
            changeSet {
              id
              nodeId
              subType
              computedStatus
              status {
                nextAuthority {
                  nodeId
                  id
                }
              }
            }
          }
        }
      `,
            variables: {
                input: initializationChangeSetPayload
            }
        });
    }
    // fetch all the existing change sets and change set authorities
    fetchChangeSetsQuery(uuid) {
        return this.$apollo.query({
            query: gql `
        ${CosmicContactSlimFragment}
        ${CosmicUserSlimFragment}
        ${CosmicUserGroupSlimFragment}
        ${UserGroupWithAssignedUsers}
        ${CosmicChangeSetAuthoritySlimFragment}
        ${CosmicDocumentSlimFragment}
        ${CosmicStepSlimFragment}

        fragment AuthorityOnDocumentApprovalsTab on ChangeSetAuthority {
          ...CosmicChangeSetAuthoritySlim

          authorizedByUser {
            ...CosmicUserSlim
          }

          comments {
            nodes {
              comment
              id
              nodeId
              subType

              createdByUser {
                ...CosmicUserSlim
              }
            }
          }

          userGroup {
            ...CosmicUserGroupSlim
          }
        }

        fragment ChangeSetOnDocumentApprovalsTab on ChangeSet {
          computedDocumentRevisionNumber
          createdDateTime
          id
          nodeId
          subType

          computedLastComment {
            comment
          }

          changeSetAuthorities(filter: { status: { notEqualTo: OPEN } }) {
            nodes {
              ...AuthorityOnDocumentApprovalsTab
            }
          }

          status {
            status

            nextAuthority {
              ...CosmicChangeSetAuthoritySlim

              userGroup {
                ...CosmicUserGroupWithAssignedUsers
              }
            }
          }

          workOrderStep {
            ...CosmicStepSlim
          }
        }

        query GetChangeSetsOnDocumentApprovalsTab($uuid: UUID!) {
          documentById(id: $uuid) {
            ...CosmicDocumentSlim

            initiatorContact {
              ...CosmicContactSlim
            }

            cosmicDocumentDr {
              computedRevisionNumber
              computedStatus
              createdDateTime
              id
              initializationStatus
              initiatorContactId
              nodeId

              createdByUser {
                ...CosmicUserSlim
              }

              initiatorContact {
                ...CosmicContactSlim
              }
            }

            cosmicDocumentEzt {
              computedRevisionNumber
              computedStatus
              createdDateTime
              id
              initializationStatus
              initiatorContactId
              nodeId

              createdByUser {
                ...CosmicUserSlim
              }

              initiatorContact {
                ...CosmicContactSlim
              }
            }

            cosmicDocumentTps {
              computedRevisionNumber
              computedStatus
              createdDateTime
              id
              initializationStatus
              initiatorContactId
              nodeId

              createdByUser {
                ...CosmicUserSlim
              }

              initiatorContact {
                ...CosmicContactSlim
              }
            }

            cosmicDocumentWorkflow {
              overrideRevisionNumber
              computedStatus
              createdDateTime
              id
              isEditable
              initiatorContactId
              nodeId

              createdByUser {
                ...CosmicUserSlim
              }

              initiatorContact {
                ...CosmicContactSlim
              }
            }

            changeSets(orderBy: CREATED_DATE_TIME_DESC) {
              nodes {
                ...ChangeSetOnDocumentApprovalsTab
              }
            }
          }
        }
      `,
            variables: { uuid }
        });
    }
    getStatusIcon(status) {
        return buildChangeSetStatusIcon(status);
    }
    // reject next authority
    async rejectNextAuthority() {
        if (!this.gqlRejectAuthorityInput) {
            this.$notification.add({ type: AlertType.ERROR, text: 'Failed to reject Document Initialization.' });
            return;
        }
        this.isRejecting = true;
        try {
            const resp = await this.updateChangeSetAuthorityMutation(this.gqlRejectAuthorityInput);
            if (resp?.data) {
                this.$notification.add({ type: AlertType.SUCCESS, text: 'Document Initialization rejected.' });
                this.emitRefetchEntity();
                this.resetApprovalForm();
                scrollToTop();
            }
        }
        catch (err) {
            this.$errorUtility({ err });
        }
        this.isRejecting = false;
    }
    // reopen an approved or rejected document for edits
    reopenDocumentForEditsMutation(uuid) {
        return this.$apollo.mutate({
            mutation: gql `
        mutation ReopenDocumentOnApprovalsTab($uuid: UUID!) {
          reopenCosmicDocumentForEdits(input: { documentId: $uuid }) {
            clientMutationId
          }
        }
      `,
            variables: {
                uuid
            }
        });
    }
    resetApprovalForm() {
        this.approvalFormData.comment = null;
        this.approvalFormData.qualityControlStamp = null;
        const formWrapper = this.$refs.approvalFormWrapper;
        if (formWrapper) {
            formWrapper.form.resetValidation();
        }
    }
    // approve or reject the next authority
    updateChangeSetAuthorityMutation(input) {
        if (!input) {
            return null;
        }
        return this.$apollo.mutate({
            mutation: gql `
        mutation UpdateChangeSetAuthorityOnApprovalsTab($input: UpdateChangeSetAuthorityInput!) {
          updateChangeSetAuthority(input: $input) {
            changeSetAuthority {
              id
              nodeId
            }
          }
        }
      `,
            variables: {
                input
            }
        });
    }
};
__decorate([
    Watch('approvalFormData.comment', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], TabApprovals.prototype, "syncRejectionComment", null);
__decorate([
    Watch('entity', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], TabApprovals.prototype, "onChangeOfEntity", null);
__decorate([
    Emit('refetch-entity'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], TabApprovals.prototype, "emitRefetchEntity", null);
TabApprovals = __decorate([
    Component
], TabApprovals);
export default TabApprovals;
