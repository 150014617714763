import { __decorate } from "tslib";
import gql from 'graphql-tag';
import { Component, Mixins } from 'vue-property-decorator';
import LoaderMixin from '~/nasa_ui/mixins/LoaderMixin';
import { queryFactory } from '../base/mixinFactories';
import { CosmicLocationSlimFragment } from '../location/slim';
export const DocumentAssemblyInventoryFragment = gql `
  ${CosmicLocationSlimFragment}

  fragment DocumentAssemblyInventory on Inventory {
    asBuiltNumber
    drawingNumber
    id
    itemDrawingDescription
    lotNumber
    nodeId
    projectCode
    serialNumber
    side
    size
    subType

    location {
      ...CosmicLocationSlim
    }

    itemMaster {
      isTool
      nodeId
    }

    itemInstance {
      nodeId
      limitedLifeRemaining {
        shelf {
          valueDueDate
        }
      }
    }
  }
`;
export const DocumentHardwareAssemblyFragment = gql `
  ${DocumentAssemblyInventoryFragment}

  fragment DocumentHardwareAssembly on HardwareListAssembly {
    externalAsBuiltNumber
    externalBirthDate
    externalCalibrationDate
    externalCalibrationNumber
    externalContractEndingItemNumber
    externalClass
    externalDescription
    externalDrawingNumber
    externalIsTool
    externalLotNumber
    externalSerialNumber
    externalShelfLifeDate
    externalUsageLifeDate
    id
    isExternal
    quantity
    sequence

    inventory {
      ...DocumentAssemblyInventory
    }
  }
`;
export const CombinedDocumentHardwareAssemblyFragment = gql `
  ${DocumentHardwareAssemblyFragment}

  fragment CombinedDocumentHardwareAssembly on CombinedHardwareListAssemblyNode {
    isExternal
    listedOnWorkOrderSteps
    hardwareListAssemblies {
      ...DocumentHardwareAssembly
    }
  }
`;
export const GetDocumentWithAssociatedHardware = gql `
  ${CombinedDocumentHardwareAssemblyFragment}

  query GetDocumentWithAssociatedHardware($id: UUID!) {
    documentById(id: $id) {
      id
      nodeId
      subType

      cosmicDocumentDr {
        nodeId
        id
        computedStatus
        combinedHardwareList {
          nodes {
            ...CombinedDocumentHardwareAssembly
          }
        }
        combinedToolsList {
          nodes {
            ...CombinedDocumentHardwareAssembly
          }
        }
        hardwareList {
          id
          nodeId
          hardwareListAssemblies {
            nodes {
              ...DocumentHardwareAssembly
            }
          }
        }
        toolsList {
          id
          nodeId
          hardwareListAssemblies {
            nodes {
              ...DocumentHardwareAssembly
            }
          }
        }
      }

      cosmicDocumentTps {
        nodeId
        id
        combinedHardwareList {
          nodes {
            ...CombinedDocumentHardwareAssembly
          }
        }
        combinedToolsList {
          nodes {
            ...CombinedDocumentHardwareAssembly
          }
        }
        hardwareList {
          id
          nodeId
          hardwareListAssemblies {
            nodes {
              ...DocumentHardwareAssembly
            }
          }
        }

        toolsList {
          id
          nodeId
          hardwareListAssemblies {
            nodes {
              ...DocumentHardwareAssembly
            }
          }
        }
      }
    }
  }
`;
export const GetDocumentAndWorkOrderStepHardware = gql `
  ${CombinedDocumentHardwareAssemblyFragment}

  query GetDocumentAndWorkOrderStepHardware($docId: UUID!, $stepId: Int!) {
    workOrderStepById(id: $stepId) {
      id
      nodeId
      hardwareList {
        id
        nodeId
        hardwareListAssemblies {
          nodes {
            ...DocumentHardwareAssembly
          }
        }
      }
    }

    documentById(id: $docId) {
      id
      nodeId

      cosmicDocumentDr {
        nodeId
        id
        computedStatus
        combinedHardwareList {
          nodes {
            ...CombinedDocumentHardwareAssembly
          }
        }
      }

      cosmicDocumentTps {
        nodeId
        id
        computedStatus
        combinedHardwareList {
          nodes {
            ...CombinedDocumentHardwareAssembly
          }
        }
      }
    }
  }
`;
export const GetDocumentAndWorkOrderStepTools = gql `
  ${CombinedDocumentHardwareAssemblyFragment}

  query GetDocumentWithAssociatedTools($docId: UUID!, $stepId: Int!) {
    workOrderStepById(id: $stepId) {
      id
      nodeId
      toolsList {
        id
        nodeId
        hardwareListAssemblies {
          nodes {
            ...DocumentHardwareAssembly
          }
        }
      }
    }

    documentById(id: $docId) {
      id
      nodeId

      cosmicDocumentDr {
        nodeId
        id
        computedStatus
        combinedToolsList {
          nodes {
            ...CombinedDocumentHardwareAssembly
          }
        }
      }

      cosmicDocumentTps {
        nodeId
        id
        computedStatus
        combinedToolsList {
          nodes {
            ...CombinedDocumentHardwareAssembly
          }
        }
      }
    }
  }
`;
let DocumentHardwareListRepo = class DocumentHardwareListRepo extends Mixins(LoaderMixin) {
    getDocumentWithAssociatedHardware = queryFactory(this, GetDocumentWithAssociatedHardware);
    getDocumentAndWorkOrderStepHardware = queryFactory(this, GetDocumentAndWorkOrderStepHardware);
    getDocumentAndWorkOrderStepTools = queryFactory(this, GetDocumentAndWorkOrderStepTools);
};
DocumentHardwareListRepo = __decorate([
    Component
], DocumentHardwareListRepo);
export { DocumentHardwareListRepo };
