import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { RiskCategory, RiskClosureType, RiskHardwareType, RiskReportability } from '~/db_types/swagger_types';
import BaseModalWithUser from '~/nasa_ui/base/BaseModalWithUser';
import { OutputMode } from '~/nasa_ui/types';
import { RiskHardwareTypeDisplay, RiskReportabilityDisplay } from '~/nasa_ui/types/enums/risks';
import { RiskCategoryDisplay, RiskClosureTypeDisplay, WorkStatus, WorkStatusTypeDisplay } from '~/nasa_ui/types/models/risk';
import { getRiskColor, isNullOrUndefined } from '~/nasa_ui/utils';
import { transformRiskResponseWithRefs } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let ModalRiskEdit = class ModalRiskEdit extends Mixins(BaseModalWithUser) {
    consequenceIsValid = true;
    isCosmicManagedNumber = true;
    likelihoodIsValid = true;
    OutputMode = OutputMode;
    RiskCategory = RiskCategory;
    RiskCategoryDisplay = RiskCategoryDisplay;
    RiskClosureType = RiskClosureType;
    RiskClosureTypeDisplay = RiskClosureTypeDisplay;
    RiskHardwareType = RiskHardwareType;
    riskHardwareType = null;
    RiskHardwareTypeDisplay = RiskHardwareTypeDisplay;
    RiskReportability = RiskReportability;
    RiskReportabilityDisplay = RiskReportabilityDisplay;
    selectedEntity = null;
    stakeholders = [];
    WorkStatus = WorkStatus;
    WorkStatusTypeDisplay = WorkStatusTypeDisplay;
    formData = {
        category: null,
        cause: null,
        closedDate: null,
        closureType: null,
        consequence: 1,
        comment: null,
        effect: null,
        hardwareType: null,
        isArchived: false,
        isClosed: false,
        isSupplier: false,
        likelihood: 1,
        number: null,
        organizationCode: null,
        ownerContactId: null,
        showOnReports: RiskReportability.DEFAULT,
        title: null
    };
    id;
    get isFormValid() {
        const hasRequiredFields = Boolean(this.formData.cause &&
            this.formData.effect &&
            this.formData.title &&
            ((!this.isCosmicManagedNumber && this.formData.number) || this.isCosmicManagedNumber) &&
            this.formData.organizationCode &&
            this.formData.category &&
            this.formData.hardwareType &&
            this.formData.ownerContactId &&
            // require closureType when closing
            this.formData.isClosed === true
            ? this.formData.closureType
            : true);
        return this.riskScoreIsValid && hasRequiredFields;
    }
    get riskHasChanges() {
        if (!this.selectedEntity) {
            return null;
        }
        // closedDate will have time on the risk and no time on the form data
        const riskClosedDate = this.selectedEntity?.closedDate ? this.$dateDisplay(this.selectedEntity.closedDate) : null;
        const mismatch = Object.keys(this.formData)
            .filter((key) => key !== 'closedDate')
            .some((key) => this.formData[key] !== this.selectedEntity[key]);
        return mismatch || riskClosedDate !== this.formData.closedDate;
    }
    get riskScore() {
        const total = (this.formData.likelihood || 1) * (this.formData.consequence || 1);
        return this.likelihoodIsValid && this.consequenceIsValid ? total : '-';
    }
    get riskScoreClass() {
        if (!this.likelihoodIsValid || !this.consequenceIsValid) {
            return 'risk-score monospace_font';
        }
        const riskColor = this.likelihoodIsValid && this.consequenceIsValid
            ? getRiskColor(this.formData.likelihood || 1, this.formData.consequence || 1)
            : '';
        return `${riskColor}--text text--darken-2 risk-score monospace_font`;
    }
    get riskScoreIsValid() {
        return (this.likelihoodIsValid &&
            this.consequenceIsValid &&
            !isNullOrUndefined(this.formData.likelihood) &&
            !isNullOrUndefined(this.formData.consequence));
    }
    get stakeholdersHaveChanged() {
        const bothAreEmpty = this.stakeholders.length === 0 && this.selectedEntity?.stakeholders?.length === 0;
        if (bothAreEmpty) {
            return false;
        }
        else {
            const existingStakeholders = this.selectedEntity?.stakeholders?.map((stakeholder) => stakeholder.id) || [];
            return (!this.stakeholders.every((stakeholder) => existingStakeholders?.includes(stakeholder)) ||
                this.stakeholders.length !== existingStakeholders.length);
        }
    }
    async onClickOfEdit() {
        try {
            if (this.stakeholdersHaveChanged) {
                await this.$http.put(`/risks/${this.id}/stakeholders`, this.stakeholders);
            }
            if (this.riskHasChanges) {
                await this.$http.put(`/risks/${this.id}`, this.formData);
            }
            this.emitModalClose();
            this.$notification.add({
                type: this.AlertType.SUCCESS,
                text: 'Updated.'
            });
            this.emitRiskEdited();
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    onConsequenceError(hasError) {
        this.consequenceIsValid = !hasError;
    }
    onLikelihoodError(hasError) {
        this.likelihoodIsValid = !hasError;
    }
    updateCloseInformation(val) {
        if (val === false) {
            this.formData.closedDate = null;
            this.formData.closureType = null;
        }
    }
    // set formdata when we get a risk
    async onChangeOfRisk() {
        try {
            this.isFetching = true;
            const resp = await this.$http.get(`/risks/${this.id}`);
            this.selectedEntity = transformRiskResponseWithRefs([resp.data])[0];
            this.isFetching = false;
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    onSelectedEntityChange() {
        if (!this.selectedEntity) {
            return;
        }
        this.formData = {
            category: this.selectedEntity.category,
            cause: this.selectedEntity.cause,
            closedDate: this.selectedEntity.closedDate,
            closureType: this.selectedEntity.closureType,
            consequence: this.selectedEntity.consequence ? this.selectedEntity.consequence : 1,
            effect: this.selectedEntity.effect,
            hardwareType: this.selectedEntity.hardwareType,
            isArchived: this.selectedEntity.isArchived || false,
            isClosed: this.selectedEntity.isClosed || false,
            isSupplier: this.selectedEntity.isSupplier || false,
            likelihood: this.selectedEntity.likelihood || 1,
            number: this.selectedEntity.number,
            organizationCode: this.selectedEntity.organizationCode,
            ownerContactId: this.selectedEntity.ownerContactId,
            showOnReports: this.selectedEntity.showOnReports || RiskReportability.DEFAULT,
            title: this.selectedEntity.title
        };
        this.riskHardwareType = this.selectedEntity.hardwareType === RiskHardwareType.EMU ? true : false;
        this.stakeholders = this.selectedEntity.stakeholders?.map((stakeholder) => stakeholder.id || '') || [];
        if (!this.selectedEntity.number?.includes('-RISK-')) {
            this.isCosmicManagedNumber = false;
        }
    }
    onRiskHardwareTypeChange() {
        if (this.riskHardwareType) {
            this.formData.hardwareType = RiskHardwareType.EMU;
        }
        else {
            this.formData.hardwareType = RiskHardwareType.TOOLS;
        }
    }
    emitRiskEdited() { }
};
__decorate([
    Prop({
        type: String,
        required: true
    }),
    __metadata("design:type", String)
], ModalRiskEdit.prototype, "id", void 0);
__decorate([
    Watch('formData.isClosed'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], ModalRiskEdit.prototype, "updateCloseInformation", null);
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], ModalRiskEdit.prototype, "onChangeOfRisk", null);
__decorate([
    Watch('selectedEntity'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalRiskEdit.prototype, "onSelectedEntityChange", null);
__decorate([
    Watch('riskHardwareType'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalRiskEdit.prototype, "onRiskHardwareTypeChange", null);
__decorate([
    Emit('risk-edited'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalRiskEdit.prototype, "emitRiskEdited", null);
ModalRiskEdit = __decorate([
    Component
], ModalRiskEdit);
export default ModalRiskEdit;
