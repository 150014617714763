var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "app_select_organization"
  }, [_c('AppSelect', {
    staticClass: "app_select_organization__select",
    attrs: {
      "allow-create": false,
      "chips": _vm.multiple,
      "color": _vm.EntityType.ORGANIZATION,
      "disabled": _vm.disabled,
      "loading": _vm.isLoading,
      "multiple": _vm.multiple,
      "select-options": _vm.organizationSelectOptions,
      "cache-items": "",
      "label": _vm.label
    },
    on: {
      "update:search-input": _vm.onSearchInputUpdate
    },
    model: {
      value: _vm.selectedOrganizations,
      callback: function ($$v) {
        _vm.selectedOrganizations = $$v;
      },
      expression: "selectedOrganizations"
    }
  }), _c('v-slide-y-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.allowCreate && !_vm.value ? _c('ButtonCreate', {
    class: {
      bounce: _vm.shouldBounceCreateButton
    },
    attrs: {
      "tooltip": "If you don't find what you need, create it.",
      "small": ""
    },
    on: {
      "click": _vm.onClickOfCreateNewOrganization
    }
  }, [_vm._v(" Create new Org ")]) : _vm._e()], 1), _vm.showNewOrganizationModal ? _c('AppModal', {
    attrs: {
      "hide-title-section": "",
      "border-color": "var(--v-organizations-base)",
      "modal-width": "65vw"
    },
    on: {
      "modal-close": _vm.onClickOfCancel
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('v-layout', {
          attrs: {
            "row": "",
            "wrap": "",
            "mb-2": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs6": "",
            "pr-2": ""
          }
        }, [_c('AppText', {
          attrs: {
            "label": "Org code",
            "required": "",
            "chips": false,
            "replace-spaces-with-underscores": "",
            "upper-case": ""
          },
          model: {
            value: _vm.formData.code,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "code", $$v);
            },
            expression: "formData.code"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs4": ""
          }
        }, [_c('AppSelectEnum', {
          attrs: {
            "chips": false,
            "display": _vm.OrganizationTypeDisplay,
            "enum": _vm.OrganizationType,
            "label": "Org type"
          },
          model: {
            value: _vm.formData.subType,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "subType", $$v);
            },
            expression: "formData.subType"
          }
        })], 1)], 1), _c('v-layout', {
          attrs: {
            "row": "",
            "wrap": "",
            "mb-2": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs6": ""
          }
        }, [_c('AppText', {
          attrs: {
            "label": "Org name"
          },
          model: {
            value: _vm.formData.name,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "name", $$v);
            },
            expression: "formData.name"
          }
        })], 1)], 1), _c('v-slide-x-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [_vm.duplicateCheckQueryResult && _vm.duplicateCheckQueryResult.length && !_vm.userIgnoredDupe ? _c('Alert', {
          attrs: {
            "type": "warning"
          }
        }, [_c('p', [_vm._v("Possible duplicate Org called \"" + _vm._s(_vm.duplicateCheckQueryResult[0].name) + "\"")]), _c('ButtonGeneric', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              _vm.userIgnoredDupe = true;
            }
          }
        }, [_vm._v(" Ignore this and continue with \"" + _vm._s(_vm.formData.code) + "\" ")])], 1) : _vm._e()], 1)];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('ButtonCreate', {
          attrs: {
            "disabled": _vm.shouldDisableCreateFromModal
          },
          on: {
            "click": _vm.onClickOfCreateFromModal
          }
        }), _c('ButtonCancel', {
          staticClass: "ml-2",
          on: {
            "click": _vm.onClickOfCancel
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 2476114437)
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };