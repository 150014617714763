var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.computedColor,
      "hide-title-section": ""
    },
    on: {
      "modal-close": _vm.emitModalClose
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_vm.shouldShowChildrenAlert ? _c('Alert', {
          attrs: {
            "type": _vm.AlertType.WARNING
          }
        }, [_vm._v("You cannot copy from an existing Inventory because this Assembly already has children.")]) : _vm._e(), !_vm.shouldShowChildrenAlert ? _c('div', [_vm.showNoWorkingAssemblyAlert ? _c('Alert', {
          attrs: {
            "type": _vm.AlertType.WARNING
          }
        }, [_vm._v("The selected Inventory does not have a corresponding Assembly. Please select a different Inventory.")]) : _vm._e(), _c('v-fade-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [_vm.selectedInventory ? _c('div', [_c('AppHardwareDisplay', {
          staticClass: "mb-3",
          attrs: {
            "node-id": _vm.selectedInventory.nodeId
          }
        }), _vm.selectedWorkingAssemblyId ? _c('MiniAssembly', {
          attrs: {
            "id": _vm.selectedWorkingAssemblyId,
            "show-children": ""
          }
        }) : _vm._e(), _c('ButtonClearSelection', {
          staticClass: "ml-0",
          on: {
            "click": _vm.onClearOfSelectedInventory
          }
        }, [_c('span', [_vm._v("Clear Inventory selection")])])], 1) : _vm._e()]), !_vm.selectedInventory ? _c('SelectorInventory', {
          attrs: {
            "output-mode": "object",
            "single-mode": "",
            "size": "0"
          },
          model: {
            value: _vm.selectedInventory,
            callback: function ($$v) {
              _vm.selectedInventory = $$v;
            },
            expression: "selectedInventory"
          }
        }) : _vm._e()], 1) : _vm._e()];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonCreate', {
          attrs: {
            "disabled": _vm.shouldDisableCreateButton
          },
          on: {
            "click": _vm.onClickOfCreate
          }
        }, [_vm._v("Copy Event Assembly from existing Inventory")]), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        })];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };