import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Emit, Prop } from 'vue-property-decorator';
import { DocumentRelationType } from '~/db_types';
import { apolloClient } from '~/nasa_ui/apollo.config';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import { CosmicDocumentSlimFragment } from '~/nasa_ui/DAL/document/slim';
import { AlertType } from '~/nasa_ui/types';
import { DocumentTableHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
import { transformDocuments } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
export const setRelatedDocumentsMutation = (setRelatedDocumentsInput) => {
    if (!setRelatedDocumentsInput) {
        return null;
    }
    return apolloClient.mutate({
        mutation: gql `
      mutation SetRelatedDocuments($setRelatedDocumentsInput: SetRelatedDocumentsInput!) {
        setRelatedDocuments(input: $setRelatedDocumentsInput) {
          clientMutationId
        }
      }
    `,
        variables: { setRelatedDocumentsInput }
    });
};
let ModalRelatedDocumentsCreate = class ModalRelatedDocumentsCreate extends BaseCosmic {
    DocumentTableHeaders = DocumentTableHeaders;
    forDocument = null;
    inputMode = 0; // 0: document search mode; 1: manual input mode
    isSaving = false;
    isSourceDocument = false;
    manualDocumentNumbers = [];
    selectedDocuments = [];
    snackbar = false; // controls visibility
    snackbarError = false; // controls color
    existingRelatedDocuments;
    forDocumentId;
    created() {
        this.fetchDocumentInformation();
    }
    async fetchDocumentInformation() {
        const resp = await this.$apollo.query({
            query: gql `
        ${CosmicDocumentSlimFragment}

        query FetchDocumentInformationForModalRelatedDocumentsCreate($id: UUID!) {
          documentById(id: $id) {
            ...CosmicDocumentSlim
          }
        }
      `,
            variables: {
                id: this.forDocumentId
            }
        });
        this.forDocument = resp.data.documentById;
    }
    get computedDocumentRelationType() {
        return this.isSourceDocument ? DocumentRelationType.SOURCE_DOCUMENT : DocumentRelationType.REFERENCE_DOCUMENT;
    }
    get computedTableHeaders() {
        return [...DocumentTableHeaders];
    }
    // used to filter existing and selected documents out of the search results
    get existingDocumentIds() {
        return this.selectedDocuments
            .map((d) => {
            return d.id;
        })
            .concat(this.existingRelatedDocuments.map((d) => d.relatedDocument?.id || ''))
            .concat(this.forDocument?.id);
    }
    get isFormValid() {
        // must have documents to add, based on input mode
        return Boolean(!this.duplicateDocumentNumbersError &&
            ((this.inputMode === 0 && this.selectedDocuments.length) ||
                (this.inputMode === 1 && this.manualDocumentNumbers.length) ||
                (this.inputMode === 2 && (this.manualDocumentNumbers.length || this.selectedDocuments.length))));
    }
    get gqlRelatedDocumentMutationVariables() {
        if (!this.forDocumentId || !this.relatedDocumentsForMutation.length) {
            return null;
        }
        return {
            forDocumentId: this.forDocumentId,
            relatedDocuments: this.relatedDocumentsForMutation
        };
    }
    get duplicateDocumentNumbersError() {
        // check for duplicate document numbers
        const documentNumbers = this.relatedDocumentsForMutation.map((d) => d.documentNumber?.toLocaleLowerCase());
        const hasDupe = new Set(documentNumbers).size !== documentNumbers.length;
        return hasDupe ? 'Cannot duplicate Document numbers.' : null;
    }
    get relatedDocumentsForMutation() {
        let newDocuments = [];
        // get the new documents to add
        if (this.selectedDocuments.length) {
            newDocuments = newDocuments.concat(this.selectedDocuments.map((doc) => {
                return {
                    documentId: doc.id,
                    documentNumber: doc.number,
                    relationType: this.computedDocumentRelationType
                };
            }));
        }
        if (this.manualDocumentNumbers.length) {
            newDocuments = newDocuments.concat(this.manualDocumentNumbers.map((num) => {
                return {
                    documentNumber: num,
                    relationType: this.computedDocumentRelationType
                };
            }));
        }
        // get the existing related documents
        const existingRelatedDocuments = this.existingRelatedDocuments.map((doc) => {
            return {
                documentId: doc.relatedDocument?.id,
                relationType: doc.relationType,
                documentNumber: doc.documentNumber
            };
        });
        return [...newDocuments, ...existingRelatedDocuments];
    }
    // used to filter selected documents out of the search results
    get selectedDocumentsNodeIds() {
        return this.selectedDocuments.map((d) => d.nodeId);
    }
    get selectedDocumentsTableItems() {
        return transformDocuments(this.selectedDocuments);
    }
    get selectedDocumentsTableTitle() {
        return `${this.selectedDocumentsTableItems.length} selected Document(s)`;
    }
    get shouldDisableCreateButton() {
        return !this.isFormValid || this.isSaving;
    }
    // called from ui
    onClickOfCreate() {
        this.setRelatedDocuments();
    }
    // called from ui
    onClickOfSearchResult(row) {
        if (this.relatedDocumentsForMutation.some((d) => d.documentNumber?.toLowerCase() === row.number.toLowerCase())) {
            this.snackbarError = true; // set it red
        }
        else {
            this.selectedDocuments.push(row);
            this.snackbarError = false;
        }
        this.snackbar = true; // show it
    }
    // called from ui
    removeSelectedDocument(row) {
        this.selectedDocuments = this.selectedDocuments.filter((d) => d.id !== row.id);
    }
    emitModalClose() { }
    emitRefresh() { }
    async setRelatedDocuments() {
        if (!this.gqlRelatedDocumentMutationVariables) {
            return;
        }
        try {
            this.isSaving = true;
            const resp = await setRelatedDocumentsMutation(this.gqlRelatedDocumentMutationVariables);
            if (resp?.data?.setRelatedDocuments) {
                this.$notification.add({ text: 'Added related Documents.', type: AlertType.SUCCESS });
                this.emitRefresh();
                this.emitModalClose();
            }
        }
        catch (err) {
            this.$errorUtility({ err });
        }
        this.isSaving = false;
    }
};
__decorate([
    Prop({
        type: Array
    }),
    __metadata("design:type", Array)
], ModalRelatedDocumentsCreate.prototype, "existingRelatedDocuments", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], ModalRelatedDocumentsCreate.prototype, "forDocumentId", void 0);
__decorate([
    Emit('modal-close'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalRelatedDocumentsCreate.prototype, "emitModalClose", null);
__decorate([
    Emit('refresh'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalRelatedDocumentsCreate.prototype, "emitRefresh", null);
ModalRelatedDocumentsCreate = __decorate([
    Component
], ModalRelatedDocumentsCreate);
export default ModalRelatedDocumentsCreate;
