var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.shouldShowDeleteButton ? _c('div', [_c('ButtonRemove', {
    staticClass: "mx-0",
    attrs: {
      "disabled": _vm.shouldDisableDeleteButton,
      "is-removing": _vm.isDeleting
    },
    on: {
      "click": _vm.onClickOfDeleteButton
    }
  }, [_vm.$slots.default ? _vm._t("default") : _c('span', [_vm._v(" Delete Document")]), _c('template', {
    slot: "removing"
  }, [_vm._v("Deleting Document...")])], 2), _vm.showDialog ? _c('ModalConfirmationDelete', {
    on: {
      "affirmativeClicked": _vm.onAffirmativeClicked,
      "negativeClicked": _vm.onNegativeClicked
    },
    model: {
      value: _vm.showDialog,
      callback: function ($$v) {
        _vm.showDialog = $$v;
      },
      expression: "showDialog"
    }
  }, [_c('template', {
    slot: "html"
  }, [_c('MiniDocumentDynamic', {
    attrs: {
      "sub-type": _vm.cosmicDocumentSubType,
      "id": _vm.id,
      "show-manage-link": false
    }
  }), (_vm.isDr || _vm.isTps) && _vm.hasHardwareLists ? _c('AppBoolean', {
    attrs: {
      "hide-unknown": "",
      "label": "When deleting a Document COSMIC will remove any associated Hardware Lists. Is this ok?"
    },
    model: {
      value: _vm.includeDocumentHardwareLists,
      callback: function ($$v) {
        _vm.includeDocumentHardwareLists = $$v;
      },
      expression: "includeDocumentHardwareLists"
    }
  }) : _vm._e()], 1)], 2) : _vm._e()], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };