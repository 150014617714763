var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "timeline"
  }, [_c('v-timeline', {
    attrs: {
      "dense": ""
    }
  }, [_vm._l(_vm.steps, function (step, i) {
    return [_c('v-timeline-item', {
      key: step.id,
      ref: `step-${i}`,
      refInFor: true,
      staticClass: "timeline_item white--text",
      class: {
        approved_step: _vm.isStepApproved(step),
        rejected_step: _vm.isStepRejected(step)
      },
      attrs: {
        "color": _vm.isStepApproved(step) ? 'success' : _vm.isStepRejected(step) ? 'secondary' : '',
        "small": _vm.isStepApproved(step) || _vm.isStepRejected(step),
        "fill-dot": ""
      },
      scopedSlots: _vm._u([{
        key: "icon",
        fn: function () {
          return [_c('span', {
            ref: `step-number--${i}`,
            refInFor: true,
            staticClass: "monospace_font font-weight-bold step_number",
            class: {
              'white--text': _vm.isStepApproved(step) || _vm.isStepRejected(step),
              'black--text': !_vm.isStepApproved(step) && !_vm.isStepRejected(step)
            }
          }, [_vm._v(_vm._s(step.stepNumber))])];
        },
        proxy: true
      }], null, true)
    }, [_c('WorkOrderStepCard', {
      attrs: {
        "doc-id": _vm.documentId,
        "documentType": _vm.documentType,
        "hide-go-to-step": _vm.hideGoToStep,
        "is-initialized": _vm.isInitialized,
        "pending-initialization": _vm.pendingInitialization,
        "read-only": _vm.isReadOnly,
        "step": step
      },
      on: {
        "procedure-updated": _vm.emitProcedureUpdated,
        "step-deleted": _vm.emitStepDeleted,
        "step-updated": _vm.emitStepUpdated
      }
    })], 1), !_vm.isReadOnly ? _c('v-tooltip', {
      key: `tooltip${step.id}`,
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function ({
          on
        }) {
          return [_c('div', {
            staticClass: "add-step-wrapper"
          }, [!_vm.isInitialized ? _c('v-timeline-item', {
            key: `add${step.id}`,
            staticClass: "add-step-timeline-item",
            attrs: {
              "icon": _vm.$icons.create_fat,
              "color": "var(--cosmic-text)"
            },
            nativeOn: {
              "click": function ($event) {
                return _vm.openNewStepModal(i);
              }
            },
            scopedSlots: _vm._u([{
              key: "icon",
              fn: function () {
                return [_c('ButtonMiniActionCreate', _vm._g({
                  attrs: {
                    "icon-color": "var(--cosmic-text)"
                  }
                }, on))];
              },
              proxy: true
            }], null, true)
          }) : _vm._e()], 1)];
        }
      }], null, true)
    }, [_c('span', [_vm._v("Insert "), _c('span', {
      staticClass: "text-lowercase"
    }, [_vm._v(_vm._s(_vm.stepDisplayText))]), _vm._v(" after "), _c('span', {
      staticClass: "text-lowercase"
    }, [_vm._v(_vm._s(_vm.stepDisplayText))]), _vm._v(" " + _vm._s(i + 1))])]) : _vm._e()];
  })], 2), _vm.showNewWorkOrderStepModal ? _c('ModalWorkOrderStepCreateAndEdit', {
    attrs: {
      "default-step-number": _vm.stepsDefault,
      "document-id": _vm.documentId,
      "max-step-number": _vm.stepsMax,
      "read-only": _vm.isReadOnly,
      "work-order-step-to-edit-id": null
    },
    on: {
      "modal-close": _vm.onModalWorkOrderStepCreateAndEditClose,
      "step-saved": _vm.emitStepUpdated
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };