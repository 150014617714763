var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "chart-container"
  }, [_vm.risk ? _c('LineChart', {
    attrs: {
      "id": _vm.id,
      "data": _vm.chartData,
      "options": _vm.options
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };