var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Component, Prop, Watch } from 'vue-property-decorator';
import { UpdateAttachment } from '~/nasa_ui/DAL/attachments';
import { AlertType } from '~/nasa_ui/types';
import { AttachmentsDisplay } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
import ModalFileAttachmentBase from '../ModalFileAttachmentBase/ModalFileAttachmentBase';
let ModalFileAttachmentEdit = class ModalFileAttachmentEdit extends ModalFileAttachmentBase {
    isLoadingPreview = false;
    previewHref = null;
    entity;
    get isFormValid() {
        return Boolean(this.formData.description && this.formData.type);
    }
    get shouldShowPreviewButton() {
        const { contentType } = this.entity;
        const canBeViewedInBrowser = contentType?.startsWith('image/') || contentType?.includes('pdf') || contentType?.startsWith('video');
        return canBeViewedInBrowser;
    }
    get shouldDisableSaveButton() {
        return !this.isFormValid || this.isSaving || this.isReadOnly;
    }
    async onClickOfUpdate() {
        this.isSaving = true;
        try {
            await this.updateAttachmentMutation();
            this.$notification.add({ text: 'File attachment updated.', type: AlertType.SUCCESS });
            this.emitModalClose();
            this.emitSaved();
        }
        catch (err) {
            this.$errorUtility({ err });
        }
        this.isSaving = true;
    }
    async onEntityChange(entity) {
        this.formData.description = entity.description;
        this.formData.type = entity.subType;
        this.isLoadingPreview = true;
        const file = await this.getAttachmentDownload(entity);
        this.isLoadingPreview = false;
        if (!file) {
            return;
        }
        this.previewHref = URL.createObjectURL(file);
    }
    updateAttachmentMutation() {
        const variables = {
            id: this.entity.id,
            patch: {
                description: this.formData.description,
                subType: this.formData.type
            }
        };
        return this.$apollo.mutate({ mutation: UpdateAttachment, variables });
    }
};
__decorate([
    Prop({
        required: true,
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof AttachmentsDisplay !== "undefined" && AttachmentsDisplay) === "function" ? _a : Object)
], ModalFileAttachmentEdit.prototype, "entity", void 0);
__decorate([
    Watch('entity', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof AttachmentsDisplay !== "undefined" && AttachmentsDisplay) === "function" ? _b : Object]),
    __metadata("design:returntype", Promise)
], ModalFileAttachmentEdit.prototype, "onEntityChange", null);
ModalFileAttachmentEdit = __decorate([
    Component
], ModalFileAttachmentEdit);
export default ModalFileAttachmentEdit;
