/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./_commentModalTemplate.vue?vue&type=template&id=4e7e86a2&scoped=true"
import script from "./_commentModalTemplate.ts?vue&type=script&lang=ts&external"
export * from "./_commentModalTemplate.ts?vue&type=script&lang=ts&external"
import style0 from "./_commentModalTemplate.vue?vue&type=style&index=0&id=4e7e86a2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e7e86a2",
  null
  
)

export default component.exports