import { __decorate } from "tslib";
import gql from 'graphql-tag';
import { Component, Mixins } from 'vue-property-decorator';
import LoaderMixin from '~/nasa_ui/mixins/LoaderMixin';
import { CosmicAttachmentSlimFragment } from '../attachments/slim';
import { mutationFactory, queryFactory } from '../base/mixinFactories';
import { CosmicContactSlimFragment } from '../contact/slim';
import { CosmicUserGroupSlimFragment, CosmicUserSlimFragment } from '../user';
export const CosmicStepSlimFragment = gql `
  fragment CosmicStepSlim on WorkOrderStep {
    action
    assigneeContactId
    caution
    computedClosedDate
    computedStatus
    computedStartDate
    id
    isEditable
    isReopenable
    nodeId
    notes
    overrideStartDate
    projectedDate
    stepNumber
    title
    riskScoreAtCompletion
    updatedDateTime
    warning
  }
`;
const StepOverviewFragment = gql `
  ${CosmicAttachmentSlimFragment}
  ${CosmicContactSlimFragment}
  ${CosmicStepSlimFragment}
  ${CosmicUserGroupSlimFragment}
  ${CosmicUserSlimFragment}

  fragment WorkflowChangeSetAuthority on ChangeSetAuthority {
    authorizedByUserId
    authorizedDate
    computedCommentText
    groupCode
    id
    nodeId
    qualityControlStamp
    requiresComment
    requiresQualityControlStamp
    status

    authorizedByUser {
      ...CosmicUserSlim
    }

    userGroup {
      ...CosmicUserGroupSlim

      delegatesForUser {
        ...CosmicUserSlim
      }
    }
  }

  fragment StepOverview on WorkOrderStep {
    ...CosmicStepSlim

    assigneeContact {
      ...CosmicContactSlim
    }

    changeSet {
      computedStatus
      id
      nodeId

      status {
        nextAuthority {
          ...WorkflowChangeSetAuthority
        }
      }

      changeSetAuthorities(condition: { isHistorical: false }, orderBy: ID_ASC) {
        totalCount
        nodes {
          ...WorkflowChangeSetAuthority
        }
      }
    }

    attachments {
      totalCount

      nodes {
        ...CosmicAttachmentSlim
      }
    }

    hardwareList {
      id
      nodeId
      hardwareListAssemblies {
        totalCount
      }
    }
    toolsList {
      id
      nodeId
      hardwareListAssemblies {
        totalCount
      }
    }
  }
`;
export const GetWorkOrderStepsOverview = gql `
  ${StepOverviewFragment}

  query GetWorkOrderStepsOverview($id: UUID!) {
    documentById(id: $id) {
      id
      nodeId
      subType
      cosmicDocumentDr {
        id
        nodeId
        computedStatus
        initializationStatus
        isEditable
      }
      cosmicDocumentTps {
        id
        nodeId
        computedStatus
        initializationStatus
        isEditable
      }
      cosmicDocumentWorkflow {
        id
        nodeId
        computedStatus
        initializationStatus
        isEditable
      }
      cosmicDocumentRca {
        id
        nodeId
        computedStatus
        initializationStatus
        isEditable
      }

      workOrderSteps(orderBy: [STEP_NUMBER_ASC]) {
        totalCount
        nodes {
          ...StepOverview
        }
      }
    }
  }
`;
export const GetDocumentAndWorkOrderStepAttachments = gql `
  query GetDocumentAndWorkOrderStepAttachments($id: Int!) {
    workOrderStepById(id: $id) {
      id
      nodeId
      document {
        id
        nodeId
        attachments {
          nodes {
            id
            nodeId
          }
        }
      }
      attachments {
        nodes {
          id
          nodeId
          fileName
          description
          isCompleted
          contentType
          subType
          createdByUserId
          createdDateTime
          key
        }
      }
    }
  }
`;
export const CreateWorkOrderStep = gql `
  mutation CreateWorkOrderStep($step: CreateWorkOrderStepInput!) {
    createWorkOrderStep(input: $step) {
      workOrderStep {
        id
        nodeId
      }
    }
  }
`;
export const CreateWorkOrderSteps = gql `
  mutation CreateWorkOrderSteps($stepsInput: CreateWorkOrderStepsInput!) {
    createWorkOrderSteps(input: $stepsInput) {
      clientMutationId
      workOrderSteps {
        id
        nodeId
      }
    }
  }
`;
export const UpdateWorkOrderStepById = gql `
  mutation UpdateWorkOrderStepById($input: UpdateWorkOrderStepByIdInput!) {
    updateWorkOrderStepById(input: $input) {
      workOrderStep {
        id
        nodeId
      }
    }
  }
`;
export const DeleteWorkOrderStepById = gql `
  mutation DeleteWorkOrderStepById($id: Int!) {
    deleteManagedChangeSet(input: { id: $id }) {
      clientMutationId
    }
  }
`;
let WorkOrderStepRepoMixin = class WorkOrderStepRepoMixin extends Mixins(LoaderMixin) {
    getWorkOrderStepsOverview = queryFactory(this, GetWorkOrderStepsOverview);
    getDocumentAndWorkOrderStepAttachments = queryFactory(this, GetDocumentAndWorkOrderStepAttachments);
    createWorkOrderStep = mutationFactory(this, CreateWorkOrderStep);
    createWorkOrderSteps = mutationFactory(this, CreateWorkOrderSteps);
    updateWorkOrderStepById = mutationFactory(this, UpdateWorkOrderStepById);
    deleteWorkOrderStepById = mutationFactory(this, DeleteWorkOrderStepById);
};
WorkOrderStepRepoMixin = __decorate([
    Component
], WorkOrderStepRepoMixin);
export { WorkOrderStepRepoMixin };
