import { __decorate } from "tslib";
import { Component, Mixins } from 'vue-property-decorator';
import { AssemblyTemplateType, HardwareListType, Side } from '~/db_types/swagger_types';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { AlertType } from '~/nasa_ui/types';
let ModalRootMegaCreate = class ModalRootMegaCreate extends Mixins(BaseModal) {
    HardwareListType = HardwareListType;
    selectedHardwareList = null;
    filterForOnlyCriticalHardwareLists = {
        isCritical: true
    };
    formData = {
        attributes: {},
        assemblyTemplateParts: [],
        hardwareListId: null,
        installedOn: Side.NONE,
        maxQuantity: 1,
        minQuantity: 1,
        name: null,
        parentId: null,
        pbsItemId: null,
        sequence: 0,
        subType: AssemblyTemplateType.ROOT_MEGA
    };
    get shouldDisableCreateButton() {
        return !this.formData.name || !this.selectedHardwareList;
    }
    async onClickOfCreate() {
        if (!this.selectedHardwareList) {
            return;
        }
        try {
            const params = {
                ...this.formData,
                hardwareListId: this.selectedHardwareList.id
            };
            const resp = await this.$http.post('/assembly-templates', params);
            if (resp.data.id) {
                this.$notification.add({
                    type: AlertType.SUCCESS,
                    text: 'Root MEGA created.'
                });
                this.closeDialog();
            }
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
};
ModalRootMegaCreate = __decorate([
    Component
], ModalRootMegaCreate);
export default ModalRootMegaCreate;
