var _a, _b;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
import { AssemblyTemplateType } from '~/db_types/swagger_types';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { EntityType } from '~/nasa_ui/types';
let ModalAssemblyClone = class ModalAssemblyClone extends BaseModal {
    isFetching = false;
    modalWidth = '90vw';
    selectedInventory = null;
    selectedWorkingAssemblyId = null;
    selectedWorkingAssemblyUuid = null;
    showNoWorkingAssemblyAlert = false;
    // the purpose of this prop is to let the component know if it should show an
    // alert -- you can't clone from inventory if the event assembly already has
    // children.
    hasChildren;
    newName;
    rootAssemblyTemplateUuid;
    get computedColor() {
        return EntityType.ASSEMBLY;
    }
    get gqlCloneAssemblyMutationVariables() {
        if (!this.selectedWorkingAssemblyUuid || !this.rootAssemblyTemplateUuid) {
            return null;
        }
        return {
            assemblyTemplateName: this.newName || DEFAULT_DASH,
            assemblyTemplateType: AssemblyTemplateType.EVENT_ASSEMBLY,
            rootAssemblyTemplateId: this.rootAssemblyTemplateUuid,
            workingAssemblyId: this.selectedWorkingAssemblyUuid
        };
    }
    get hasClonePermission() {
        return true; // TODO: implement this
    }
    get shouldDisableCreateButton() {
        return Boolean(!this.selectedInventory) || this.showNoWorkingAssemblyAlert;
    }
    get shouldShowChildrenAlert() {
        return this.hasChildren === true;
    }
    // called from ui
    onClearOfSelectedInventory() {
        this.selectedInventory = null;
    }
    // called from ui
    async onClickOfCreate() {
        await this.cloneAssembly();
        this.emitRefreshParentEvent().emitModalClose();
    }
    emitModalClose() { }
    emitRefreshParentEvent() {
        this.$message(`reload_${this.$route.params.id}`);
        return this;
    }
    onChangeOfSelectedInventory(inventory) {
        if (!inventory?.id) {
            return;
        }
        this.fetchInventoryWorkingAssembly(inventory.id);
    }
    async cloneAssembly() {
        try {
            await this.cloneAssemblyMutation(this.gqlCloneAssemblyMutationVariables);
        }
        catch (err) {
            this.$errorUtility({ err });
        }
    }
    cloneAssemblyMutation(variables) {
        if (!variables) {
            return null;
        }
        return this.$apollo.mutate({
            mutation: gql `
        mutation CloneAssemblyOnEventAssembly(
          $workingAssemblyId: UUID!
          $assemblyTemplateType: String!
          $assemblyTemplateName: String
          $rootAssemblyTemplateId: UUID
        ) {
          createAssemblyTemplateFromWorkingAssembly(
            input: {
              workingAssemblyId: $workingAssemblyId
              assemblyTemplateType: $assemblyTemplateType
              assemblyTemplateName: $assemblyTemplateName
              rootAssemblyTemplateId: $rootAssemblyTemplateId
            }
          ) {
            assemblyTemplate {
              id
              nodeId
              childAssemblyTemplates {
                totalCount
              }
            }
          }
        }
      `,
            variables: { ...variables }
        });
    }
    async fetchInventoryWorkingAssembly(inventoryUuid) {
        if (!inventoryUuid) {
            return null;
        }
        try {
            const resp = await this.fetchInventoryWorkingAssemblyQuery(inventoryUuid);
            this.selectedWorkingAssemblyId = resp?.data.inventoryById?.workingAssembly?.id ?? null;
            this.selectedWorkingAssemblyUuid = resp?.data.inventoryById?.workingAssembly?.id ?? null;
            this.showNoWorkingAssemblyAlert = !this.selectedWorkingAssemblyUuid;
        }
        catch (err) {
            this.$errorUtility({ err });
        }
    }
    fetchInventoryWorkingAssemblyQuery(id) {
        if (!id) {
            return null;
        }
        return this.$apollo.query({
            query: gql `
        query FetchInventoryWorkingAssembly($id: UUID!) {
          inventoryById(id: $id) {
            id
            nodeId
            workingAssembly {
              id
              nodeId
            }
          }
        }
      `,
            variables: {
                id
            }
        });
    }
};
__decorate([
    Prop({
        default: null,
        type: Boolean
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], ModalAssemblyClone.prototype, "hasChildren", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], ModalAssemblyClone.prototype, "newName", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], ModalAssemblyClone.prototype, "rootAssemblyTemplateUuid", void 0);
__decorate([
    Emit('modal-close'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalAssemblyClone.prototype, "emitModalClose", null);
__decorate([
    Emit('refresh-parent'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalAssemblyClone.prototype, "emitRefreshParentEvent", null);
__decorate([
    Watch('selectedInventory'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], ModalAssemblyClone.prototype, "onChangeOfSelectedInventory", null);
ModalAssemblyClone = __decorate([
    Component
], ModalAssemblyClone);
export default ModalAssemblyClone;
