var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-form', {
    attrs: {
      "lazy-validation": ""
    },
    on: {
      "input": _vm.setIsValid
    }
  }, [_c('div', {
    staticClass: "mb-3",
    attrs: {
      "data-appdate": _vm.label || ''
    }
  }, [_vm.label ? _c('label', {
    staticClass: "mb-1"
  }, [_c('span', {
    class: {
      'error--text': _vm.hasErrorMessages
    }
  }, [_vm._v(_vm._s(_vm.label))]), _vm.informationText ? _c('v-chip', {
    attrs: {
      "color": "info",
      "text-color": "white",
      "small": ""
    }
  }, [_c('v-avatar', {
    on: {
      "mouseover": function ($event) {
        _vm.showInformationalText = true;
      },
      "mouseleave": function ($event) {
        _vm.showInformationalText = false;
      }
    }
  }, [_c('c-icon-fa', {
    attrs: {
      "icon": "fad fa-info-circle",
      "icon-size": "18px"
    }
  })], 1), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.showInformationalText ? _c('div', {
    directives: [{
      name: "safe-html",
      rawName: "v-safe-html",
      value: _vm.informationText,
      expression: "informationText"
    }]
  }) : _vm._e()])], 1) : _vm._e()], 1) : _vm._e(), _c('v-layout', {
    staticClass: "inputs mt-2 align-center",
    attrs: {
      "row": "",
      "no-wrap": ""
    }
  }, [_c('AppNumber', {
    staticClass: "hours pr-2 mb-2",
    attrs: {
      "color": _vm.color,
      "disabled": _vm.disabled,
      "error-messages": _vm.errorMessages,
      "required": _vm.required,
      "hide-details": "",
      "label": "Hours",
      "max": "23"
    },
    nativeOn: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.clearInput('hours');
      }
    },
    model: {
      value: _vm.hours,
      callback: function ($$v) {
        _vm.hours = $$v;
      },
      expression: "hours"
    }
  }), _c('AppNumber', {
    staticClass: "minutes pr-2 mb-2",
    attrs: {
      "color": _vm.color,
      "disabled": _vm.disabled,
      "error-messages": _vm.errorMessages,
      "required": _vm.required,
      "hide-details": "",
      "label": "Minutes",
      "max": "59"
    },
    nativeOn: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.clearInput('minutes');
      }
    },
    model: {
      value: _vm.minutes,
      callback: function ($$v) {
        _vm.minutes = $$v;
      },
      expression: "minutes"
    }
  }), _c('v-layout', {
    attrs: {
      "row-nowrap": ""
    }
  }, [_c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.shouldShowNoTimeEnteredHelperIcon ? _c('c-icon-fa', {
    attrs: {
      "tooltip": "No time entered",
      "icon": _vm.$icons.pipeline_null
    }
  }) : _vm._e()], 1), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.shouldShowValidTimeHelperIcon ? _c('c-icon-fa', {
    attrs: {
      "tooltip": "Valid time",
      "icon": _vm.$icons.circle_check,
      "icon-color": "var(--v-success-base)"
    }
  }) : _vm._e()], 1), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.shouldShowInvalidTimeHelperIcon ? _c('c-icon-fa', {
    attrs: {
      "icon-color": "var(--v-error-base)",
      "tooltip": "Invalid time",
      "icon": _vm.$icons.close
    }
  }) : _vm._e()], 1), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.hasErrorMessages ? _c('c-icon-fa', {
    attrs: {
      "icon-color": "var(--v-error-base)",
      "tooltip": "Invalid time",
      "icon": _vm.$icons.close
    }
  }) : _vm._e()], 1)], 1)], 1), _vm.errorMessages.length > 0 ? _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('div', {
    staticClass: "v-messages theme--light error--text"
  }, [_c('div', {
    staticClass: "v-messages__wrapper"
  }, [_c('div', {
    staticClass: "v-messages__message"
  }, [_vm._v(_vm._s(_vm.errorMessages))])])])]) : _vm.computedMessages.length > 0 ? _c('v-flex', {
    attrs: {
      "xs12": "",
      "pa0": ""
    }
  }, [_c('div', {
    staticClass: "v-messages theme--light",
    class: {
      'error--text': !_vm.isValid
    }
  }, [_c('div', {
    staticClass: "v-messages__wrapper"
  }, [_c('div', {
    staticClass: "v-messages__message"
  }, [_vm._v(_vm._s(_vm.computedMessages[0]))])])])]) : _vm._e()], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };