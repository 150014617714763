import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseModalWithUser from '~/nasa_ui/base/BaseModalWithUser';
import { AlertType } from '~/nasa_ui/types';
import { UserPermission } from '~/nasa_ui/types/enums/user';
let ModalActivityAuthority = class ModalActivityAuthority extends BaseModalWithUser {
    activity = null;
    formData = {
        authorityCode: null,
        authorityNumber: null
    };
    activityId;
    get userHasPermission() {
        return this.currentUserHasPermission(UserPermission.ACTIVITY_ADMIN);
    }
    get isFormValid() {
        return Boolean(this.formData.authorityCode && this.formData.authorityNumber);
    }
    get shouldDisableSave() {
        return !this.isFormValid;
    }
    get updatePayload() {
        if (!this.isFormValid) {
            return null;
        }
        return {
            authorityCode: this.formData.authorityCode,
            authorityNumber: this.formData.authorityNumber
        };
    }
    async fetchEntity(id) {
        if (!id) {
            return;
        }
        try {
            this.isFetching = true;
            const resp = await this.$apollo.query({
                query: gql `
          fragment ActivityAuthorizationModal on Activity {
            authorityCode
            authorityNumber
            id
            nodeId
          }

          query FetchActivityAuthorizationModalData($id: UUID!) {
            activityById(id: $id) {
              ...ActivityAuthorizationModal
            }
          }
        `,
                variables: {
                    id
                },
                fetchPolicy: 'network-only'
            });
            this.isFetching = false;
            if (resp.data.activityById) {
                this.activity = resp.data.activityById;
            }
        }
        catch (err) {
            this.isFetching = false;
            this.$errorUtility({
                err
            });
        }
    }
    createEntity(payload) {
        throw new Error('Method not implemented.');
    }
    async editEntity(payload, id) {
        if (!id || !payload) {
            return;
        }
        try {
            const resp = await this.$apollo.mutate({
                mutation: gql `
          mutation ActivityAuthorizationModal(
            $authorityComment: String!
            $hasComment: Boolean!
            $id: UUID!
            $payload: ActivityPatch!
          ) {
            updateActivityById(input: { id: $id, patch: $payload }) {
              activity {
                id
                nodeId
              }
            }

            createComment(
              input: { comment: { subType: AUTHORITY_COMMENT, activityId: $id, comment: $authorityComment } }
            ) @include(if: $hasComment) {
              comment {
                id
                nodeId
              }
            }
          }
        `,
                variables: {
                    authorityComment: this.formData.comment || '',
                    hasComment: Boolean(this.formData.comment),
                    id,
                    payload
                }
            });
            if (resp.data) {
                this.emitEntityEdited();
            }
        }
        catch (err) {
            this.$errorUtility({
                err,
                backupErrorText: 'Error: Unable to update information for Activity.'
            });
        }
    }
    deleteEntity(payload, id) {
        throw new Error('Method not implemented.');
    }
    closeDialog() {
        this.emitModalClose();
    }
    async onSave() {
        if (this.updatePayload) {
            await this.editEntity(this.updatePayload, this.activityId);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Authorization saved.'
            });
            this.emitModalClose();
        }
    }
    onActivityChange() {
        if (this.activity) {
            this.formData.authorityCode = this.activity.authorityCode;
            this.formData.authorityNumber = this.activity.authorityNumber;
        }
    }
    onActivityIdChange() {
        if (this.activityId) {
            this.fetchEntity(this.activityId);
        }
    }
};
__decorate([
    Prop({ type: String }),
    __metadata("design:type", Object)
], ModalActivityAuthority.prototype, "activityId", void 0);
__decorate([
    Watch('activity', { deep: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalActivityAuthority.prototype, "onActivityChange", null);
__decorate([
    Watch('activityId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalActivityAuthority.prototype, "onActivityIdChange", null);
ModalActivityAuthority = __decorate([
    Component
], ModalActivityAuthority);
export default ModalActivityAuthority;
