var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.COMMENT,
      "hide-title-section": "",
      "modal-width": "60vw"
    },
    on: {
      "input": _vm.emitModalClose,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.emitModalClose.apply(null, arguments);
      },
      "modal-close": _vm.emitModalClose
    }
  }, [_c('template', {
    slot: "content"
  }, [_c('v-flex', {
    attrs: {
      "xs3": "",
      "mb-3": ""
    }
  }, [_c('c-select', {
    attrs: {
      "chips": false,
      "label": "Type"
    },
    domProps: {
      "selectOptions": _vm.computedSelectOptions,
      "cValue": _vm.formData.subType
    },
    on: {
      "c-input": function ($event) {
        _vm.formData.subType = $event.detail;
      }
    }
  })], 1), _c('c-text', {
    staticClass: "mb-3",
    attrs: {
      "label": "Current revision",
      "c-value": _vm.formData.currentRevision
    },
    on: {
      "c-input": function ($event) {
        _vm.formData.currentRevision = $event.detail;
      }
    }
  }), _c('c-text', {
    staticClass: "mb-3",
    attrs: {
      "label": "Current version URL",
      "c-value": _vm.formData.currentVersionUrl
    },
    on: {
      "c-input": function ($event) {
        _vm.formData.currentVersionUrl = $event.detail;
      }
    }
  }), _c('c-text', {
    staticClass: "mb-3",
    attrs: {
      "label": "Reference number",
      "c-value": _vm.formData.referenceNumber
    },
    on: {
      "c-input": function ($event) {
        _vm.formData.referenceNumber = $event.detail;
      }
    }
  }), _c('c-text', {
    attrs: {
      "label": "Working version URL",
      "c-value": _vm.formData.workingVersionUrl
    },
    on: {
      "c-input": function ($event) {
        _vm.formData.workingVersionUrl = $event.detail;
      }
    }
  })], 1), _c('template', {
    slot: "actions"
  }, [_c('ButtonCreate', {
    staticClass: "ml-0",
    attrs: {
      "disabled": !_vm.isFormValid
    },
    on: {
      "click": _vm.onClickOfCreate
    }
  }, [_vm._v("Create link")]), _c('ButtonCancel', {
    on: {
      "click": _vm.emitModalClose
    }
  })], 1)], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };