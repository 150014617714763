var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.isFetching ? _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppLoader', {
    key: "comments-loader",
    attrs: {
      "color": _vm.EntityType.COMMENT,
      "type": "linear",
      "center": ""
    }
  })], 1) : _vm._e()], 1), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [!_vm.isFetching ? _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [!_vm.readOnly ? _c('AppCreateComment', _vm._b({
    staticClass: "mb-2",
    on: {
      "reloadComments": _vm.reloadComments
    }
  }, 'AppCreateComment', _vm.$props, false)) : _vm._e(), _vm.allComments.length > 4 ? _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": "",
      "mb-2": ""
    }
  }, [_c('AppSelectEnum', {
    attrs: {
      "chips": "",
      "enum": _vm.CommentType,
      "display": _vm.CommentTypeDisplay,
      "existing-values-to-be-removed": _vm.existingValuesToBeRemoved,
      "label": "Filter by Comment type",
      "multiple": "",
      "placeholder": "Select type"
    },
    model: {
      value: _vm.commentTypesToDisplay,
      callback: function ($$v) {
        _vm.commentTypesToDisplay = $$v;
      },
      expression: "commentTypesToDisplay"
    }
  })], 1)], 1) : _vm._e(), _vm.existingCommentTypes && _vm.existingCommentTypes.length > 1 ? _c('v-layout', {
    staticClass: "mb-2",
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppPageDescription', {
    staticClass: "ml-0 mb-0"
  }, [_vm._v("Click below to filter by a specific comment type")])], 1), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_vm._l(_vm.commentTypesToDisplay, function (type, index) {
    return _c('v-chip', {
      key: index,
      staticClass: "cursor-pointer",
      on: {
        "click": function ($event) {
          return _vm.setCommentType(type);
        }
      }
    }, [_vm._v(_vm._s(_vm.CommentTypeDisplay.get(type))), _c('strong', {
      staticClass: "pl-2 monospace_font"
    }, [_vm._v(_vm._s(_vm.commentTotalByType(type)))])]);
  }), _c('v-chip', {
    staticClass: "cursor-pointer",
    on: {
      "click": _vm.viewAllComments
    }
  }, [_vm._v("View all")])], 2)], 1) : _vm._e(), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [!_vm.isReadOnly ? _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppTableActions', {
    staticClass: "comments-table",
    attrs: {
      "color": _vm.EntityType.COMMENT,
      "headers": _vm.computedTableHeaders,
      "items": _vm.computedComments,
      "rows-per-page-items": _vm.rowsPerPageItems,
      "dark": "",
      "disable-initial-sort": "",
      "item-key": "id",
      "show-count": "",
      "show-search-input": "",
      "sticky": "",
      "title": "Comments"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function ({
        item
      }) {
        return [_c('ButtonMiniActionEdit', {
          on: {
            "click": function ($event) {
              return _vm.onClickOfEdit(item);
            }
          }
        }), _c('ButtonMiniActionDelete', {
          on: {
            "click": function ($event) {
              return _vm.onClickOfDelete(item);
            }
          }
        })];
      }
    }], null, false, 3030909643)
  })], 1) : _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppTable', {
    staticClass: "comments-table",
    attrs: {
      "color": _vm.EntityType.COMMENT,
      "headers": _vm.computedTableHeaders,
      "items": _vm.computedComments,
      "rows-per-page-items": _vm.rowsPerPageItems,
      "disable-initial-sort": "",
      "item-key": "id",
      "show-count": "",
      "show-search-input": "",
      "sticky": "",
      "title": "Comments"
    }
  })], 1)], 1)], 1) : _vm._e()], 1), _vm.showEditModal ? _c('ModalCommentEdit', _vm._b({
    attrs: {
      "id": _vm.selectedCommentId
    },
    on: {
      "modal-close": _vm.onModalCommentEditClose,
      "reloadComments": _vm.reloadComments
    }
  }, 'ModalCommentEdit', _vm.$props, false)) : _vm._e(), _vm.showDeleteConfirmation ? _c('AreYouSureDialog', {
    attrs: {
      "disabled": _vm.isRemoving || !_vm.userCanRemoveComment
    },
    on: {
      "affirmativeClicked": _vm.onDeleteConfirmed,
      "negativeClicked": _vm.onDeleteCancelled
    },
    scopedSlots: _vm._u([!_vm.userCanRemoveComment ? {
      key: "text",
      fn: function () {
        return [_vm._v("Must be owner of the comment or an admin on the related hardware.")];
      },
      proxy: true
    } : null], null, true)
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };