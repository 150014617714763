var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_vm.showModalCommentCreate ? _c('ModalCommentCreate', _vm._b({
    attrs: {
      "comment-type-override": _vm.commentTypeOverride
    },
    on: {
      "commentCreated": _vm.emitReloadComments,
      "modal-close": function ($event) {
        _vm.showModalCommentCreate = false;
      }
    }
  }, 'ModalCommentCreate', _vm.$props, false)) : _vm._e(), _c('ButtonCreate', {
    staticClass: "ml-0 mr-2",
    on: {
      "click": function ($event) {
        _vm.showModalCommentCreate = true;
      }
    }
  }, [_vm._v("Create Comment")])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };