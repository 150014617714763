import { __decorate } from "tslib";
import gql from 'graphql-tag';
import { Component } from 'vue-property-decorator';
import { DocumentType } from '~/db_types';
import BaseMini from '~/nasa_ui/base/BaseMini';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { CosmicContactSlimFragment } from '~/nasa_ui/DAL/contact/slim';
import { CosmicDocumentSlimFragment } from '~/nasa_ui/DAL/document/slim';
import { CosmicUserSlimFragment } from '~/nasa_ui/DAL/user/slim';
import { EntityType } from '~/nasa_ui/types';
import { DocumentTypeDisplay } from '~/nasa_ui/types/enums/document';
/**
 * This is a Mini component specifically for the COSMIC Document of
 *    type C_WORKFLOW
 */
let MiniWorkflow = class MiniWorkflow extends BaseMini {
    localEntity = null;
    entityType = EntityType.DOCUMENT;
    showAllStepsCompleteAlert = false;
    BASE_FETCH = gql `
    ${CosmicContactSlimFragment}
    ${CosmicUserSlimFragment}
    ${CosmicDocumentSlimFragment}

    fragment MiniWorkflowDocument on Document {
      ...CosmicDocumentSlim

      createdByUser {
        ...CosmicUserSlim
      }

      cosmicDocumentWorkflow {
        id
        nodeId
        computedStatus
        workflowLetterNumber
        workflowNotes
        workflowReceivedDate
      }

      initiatorContact {
        ...CosmicContactSlim
      }
    }

    query MiniWorkflow($id: UUID!) {
      documentById(id: $id) {
        ...MiniWorkflowDocument
      }
    }
  `;
    get createdByDisplay() {
        return this.$contactDisplay(this.localEntity?.createdByUser);
    }
    get initiatorDisplay() {
        return this.$contactDisplay(this.localEntity?.initiatorContact);
    }
    get letterNumberDisplay() {
        return this.localEntity?.cosmicDocumentWorkflow?.workflowLetterNumber || DEFAULT_DASH;
    }
    get manageLink() {
        return `/${DocumentTypeDisplay.get(DocumentType.C_WORKFLOW)}/manage/${this.id}#information`;
    }
    get number() {
        return this.localEntity?.number || '';
    }
    get receivedDateDisplay() {
        return this.$dateDisplay(this.localEntity?.cosmicDocumentWorkflow?.workflowReceivedDate);
    }
    get workflowTitle() {
        return this.localEntity?.title || null;
    }
    onFetchUpdate(data) {
        if (data.documentById) {
            this.localEntity = Object.assign({}, data.documentById);
        }
    }
    refreshData() {
        this.executeQuery();
    }
};
MiniWorkflow = __decorate([
    Component
], MiniWorkflow);
export default MiniWorkflow;
