import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Prop } from 'vue-property-decorator';
import BaseSearch from '~/nasa_ui/base/BaseSearch';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { EntityType } from '~/nasa_ui/types';
import { HardwareListTypeDisplay } from '~/nasa_ui/types/enums/hardware';
import { HardwareListTemplateTableHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
let SearchHardwareListTemplate = class SearchHardwareListTemplate extends BaseSearch {
    entityType = EntityType.HARDWARE_LIST_TEMPLATE;
    selectedHardwareListTemplate = null;
    SearchHardwareListTemplateFragment = gql `
    fragment SearchHardwareListTemplate on HardwareListTemplate {
      id
      pbsItemId
      name
      nodeId
      subType

      hardwareListTemplateSlots {
        totalCount
      }

      hardwareLists {
        totalCount
      }
    }
  `;
    SEARCH_QUERY = gql `
    ${this.SearchHardwareListTemplateFragment}

    query SearchHardwareListTemplates($filter: HardwareListTemplateFilter) {
      hardwareListTemplates(filter: $filter) {
        nodes {
          ...SearchHardwareListTemplate
        }
      }
    }
  `;
    get gqlSearchQueryVariables() {
        const base = {
            firstResults: this.userSelectedMaxResults
        };
        const query = {};
        const searchFilter = { name: { startsWithInsensitive: this.searchInput } };
        query.filter = Object.assign(searchFilter);
        const variables = Object.assign({}, base, query);
        return variables;
    }
    expandable;
    get computedSearchResults() {
        return (this.searchResults || []).map((result) => {
            return Object.assign({}, result, {
                _pbsItemId: result.hardwareListTemplate.pbsItemId || DEFAULT_DASH,
                _subType: HardwareListTypeDisplay.get(result.hardwareListTemplate.subType)
            });
        });
    }
    get searchResultsTableHeaders() {
        return [...HardwareListTemplateTableHeaders];
    }
    onClickOfHardwareListTemplate(val) {
        this.selectedHardwareListTemplate = val;
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: true
    }),
    __metadata("design:type", Boolean)
], SearchHardwareListTemplate.prototype, "expandable", void 0);
SearchHardwareListTemplate = __decorate([
    Component
], SearchHardwareListTemplate);
export default SearchHardwareListTemplate;
