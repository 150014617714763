var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('nav', {
    staticClass: "cosmic_breadcrumbs",
    attrs: {
      "aria-label": "Breadcrumb"
    }
  }, [_vm._t("breadcrumbs", function () {
    return [_c('ul', {
      staticClass: "cosmic_breadcrumb__list"
    }, [_vm._l(_vm.breadcrumbs, function (item, index) {
      return _c('li', {
        key: index,
        staticClass: "cosmic_breadcrumb__item",
        class: {
          disabled: item.disabled === undefined ? false : item.disabled
        }
      }, [_vm._t("breadcrumb", function () {
        return [!item.disabled && item.href ? _c('a', {
          staticClass: "cosmic_breadcrumb__anchor",
          attrs: {
            "href": item.href
          }
        }, [_vm._v(" " + _vm._s(item.text) + " ")]) : !item.disabled && item.to && _vm.routerInstance ? _c('a', {
          staticClass: "cosmic_breadcrumb__anchor",
          attrs: {
            "href": item.to
          },
          on: {
            "click": function ($event) {
              return _vm.onClickOfBreadcrumb($event, item);
            }
          }
        }, [_vm._v(" " + _vm._s(item.text) + " ")]) : _c('span', [_vm._v(" " + _vm._s(item.text) + " ")])];
      }, {
        "item": item
      })], 2);
    }), _vm.hasHelpUrl ? _c('li', {
      staticClass: "cosmic_breadcrumb__item_help"
    }, [_c('a', {
      staticClass: "cosmic_breadcrumb__help_anchor",
      attrs: {
        "href": _vm.helpUrl
      }
    }, [_vm._v("Help")])]) : _vm._e()], 2)];
  }, {
    "items": _vm.breadcrumbs
  })], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };