var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.formData ? _c('div', {
    staticClass: "wrapper"
  }, [_c('AppHeader', {
    staticClass: "mb-2"
  }, [_vm._v(_vm._s(_vm.activityTypeDisplay) + " specific information")]), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs4": "",
      "pr-4": ""
    }
  }, [!_vm.isReadOnly ? _c('AppSelectEnum', {
    staticClass: "inspectionResultSelect",
    attrs: {
      "chips": false,
      "display": _vm.AnalysisResultDisplay,
      "enum": _vm.AnalysisResult,
      "color": _vm.EntityType.ACTIVITY,
      "label": "Inspection result"
    },
    model: {
      value: _vm.formData.attributes.result,
      callback: function ($$v) {
        _vm.$set(_vm.formData.attributes, "result", $$v);
      },
      expression: "formData.attributes.result"
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "Inspection result"
    }
  }, [_vm._v(" " + _vm._s(_vm.formData.attributes.result ? _vm.AnalysisResultDisplay.get(_vm.formData.attributes.result) || '-' : '-') + " ")])], 1), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [!_vm.isReadOnly ? _c('AppMarkdown', {
    attrs: {
      "label": "Inspection text"
    },
    model: {
      value: _vm.formData.attributes.inspectionText,
      callback: function ($$v) {
        _vm.$set(_vm.formData.attributes, "inspectionText", $$v);
      },
      expression: "formData.attributes.inspectionText"
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "Inspection text"
    }
  }, [_c('AppMarkdownDisplay', {
    attrs: {
      "markdown": _vm.formData.attributes.inspectionText
    }
  })], 1)], 1)], 1)], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };