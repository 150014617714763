var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import { CosmicContactSlimFragment } from '~/db_types';
import BaseVue from '~/nasa_ui/base/BaseVue';
import { AlertType } from '~/nasa_ui/types';
let AppContactDisplay = class AppContactDisplay extends BaseVue {
    contact;
    copyEmail(contact) {
        const email = contact?.email[0] || '';
        navigator.clipboard.writeText(email);
        this.$notification.add({
            type: AlertType.INFO,
            text: 'Email copied to clipboard.'
        });
    }
};
__decorate([
    Prop({
        required: true,
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof CosmicContactSlimFragment !== "undefined" && CosmicContactSlimFragment) === "function" ? _a : Object)
], AppContactDisplay.prototype, "contact", void 0);
AppContactDisplay = __decorate([
    Component
], AppContactDisplay);
export default AppContactDisplay;
