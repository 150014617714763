import { __decorate, __metadata } from "tslib";
import Vue from 'vue';
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { AnalysisResult, AnalysisResultDisplay } from '~/nasa_ui/types/enums/activity';
/**
 * This component displays the hardware inspection form seen in this mockup (orange box):
 * https://appdat.jsc.nasa.gov/cosmic/cosmic_web/uploads/abafcfed7d0e25beac77e979e5307db6/purchase_request_receipt.png
 *
 * When form data changes, it emits:
 * - @input - which has all the form data
 * - @isFormValid - a boolean indicating whether the nested DR form is valid
 */
let AppHardwareInspectionForm = class AppHardwareInspectionForm extends Vue {
    AnalysisResult = AnalysisResult;
    AnalysisResultDisplay = AnalysisResultDisplay;
    formData = {
        drNumber: null,
        drTitle: null,
        inspectionPerformed: true,
        inspectionResult: AnalysisResult.PASS,
        openDr: false
    };
    hideHeader;
    showBorder;
    size;
    get didInspectionFail() {
        return this.formData.inspectionPerformed && this.formData.inspectionResult === AnalysisResult.FAIL;
    }
    get isDrFormValid() {
        return !!this.formData.drNumber && !!this.formData.drTitle;
    }
    get isInspectionFormValid() {
        if (this.didInspectionFail && this.formData.openDr) {
            return this.isDrFormValid;
        }
        return true;
    }
    get shouldShowCreateDr() {
        return this.formData.inspectionResult === AnalysisResult.FAIL;
    }
    created() {
        this.emitInput();
        this.emitIsFormValid();
    }
    emitInput() {
        return this.formData;
    }
    emitIsFormValid() {
        return this.isInspectionFormValid;
    }
    onFormDataChange() {
        this.emitInput();
        this.emitIsFormValid();
    }
    clearDrForm() {
        this.formData.openDr = false;
        this.formData.drNumber = null;
        this.formData.drTitle = null;
    }
    clearDrInputs() {
        this.formData.drNumber = null;
        this.formData.drTitle = null;
    }
};
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], AppHardwareInspectionForm.prototype, "hideHeader", void 0);
__decorate([
    Prop({
        default: true,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], AppHardwareInspectionForm.prototype, "showBorder", void 0);
__decorate([
    Prop({
        default: '2',
        type: String
    }),
    __metadata("design:type", String)
], AppHardwareInspectionForm.prototype, "size", void 0);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppHardwareInspectionForm.prototype, "emitInput", null);
__decorate([
    Emit('isFormValid'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppHardwareInspectionForm.prototype, "emitIsFormValid", null);
__decorate([
    Watch('formData', { deep: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppHardwareInspectionForm.prototype, "onFormDataChange", null);
__decorate([
    Watch('formData.inspectionResult'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppHardwareInspectionForm.prototype, "clearDrForm", null);
__decorate([
    Watch('formData.openDr'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppHardwareInspectionForm.prototype, "clearDrInputs", null);
AppHardwareInspectionForm = __decorate([
    Component
], AppHardwareInspectionForm);
export default AppHardwareInspectionForm;
