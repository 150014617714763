var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', _vm._g({
    attrs: {
      "color": _vm.EntityType.INVENTORY,
      "hide-title-section": ""
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('v-layout', [_c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('AppPageDescription', {
          staticClass: "mb-3"
        }, [_vm._v(" You may select " + _vm._s(_vm.isToolsList ? 'Tools' : 'Hardware') + " from: "), _c('v-radio-group', {
          staticClass: "ml-4 hardware_callout_note",
          model: {
            value: _vm.hardwareSelectionMode,
            callback: function ($$v) {
              _vm.hardwareSelectionMode = $$v;
            },
            expression: "hardwareSelectionMode"
          }
        }, [_c('v-radio', {
          key: 0,
          class: {
            active: _vm.hardwareSelectionMode === 0
          },
          attrs: {
            "value": 0
          }
        }, [_c('template', {
          slot: "label"
        }, [_vm._v("Pick List")])], 2), _c('v-radio', {
          key: 1,
          class: {
            active: _vm.hardwareSelectionMode === 1
          },
          attrs: {
            "value": 1
          }
        }, [_c('template', {
          slot: "label"
        }, [_vm._v(" Select a piece of " + _vm._s(_vm.currentSystemName) + " Inventory ")])], 2), _c('v-radio', {
          key: 2,
          class: {
            active: _vm.hardwareSelectionMode === 2
          },
          attrs: {
            "value": 2
          }
        }, [_c('template', {
          slot: "label"
        }, [_vm._v(" Enter a completely one-off Hardware")])], 2)], 1)], 1), _c('v-fade-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [_vm.hardwareSelectionMode === 0 ? _c('AppTableActions', {
          staticClass: "mb-3",
          attrs: {
            "color": _vm.EntityType.INVENTORY,
            "headers": _vm.computedHardwarePickListTableHeaders,
            "items": _vm.computedHardwarePickListItems,
            "show-count": "",
            "show-search-input": "",
            "title": "Previously entered Hardware"
          },
          scopedSlots: _vm._u([{
            key: "actions",
            fn: function ({
              item
            }) {
              return [_c('ButtonMiniActionCreate', {
                attrs: {
                  "tooltip": "Add this Hardware back to the list.",
                  "tooltip-position": "left"
                },
                on: {
                  "click": function ($event) {
                    return _vm.onClickOfHardwarePickListItem(item);
                  }
                }
              })];
            }
          }], null, false, 305192596)
        }) : _vm._e()], 1), _c('v-fade-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [_c('SelectorInventory', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.isHardwareSelectionModeInventory,
            expression: "isHardwareSelectionModeInventory"
          }],
          attrs: {
            "existing-values": _vm.existingCalloutHardwareValues,
            "override-view-to-manual-mode": _vm.overrideHardwareSelectorViewToManualMode,
            "filters": {
              hasQuantity: true
            },
            "hide-create-inventory": "",
            "hide-show-other-context-filter": "",
            "keep-search-results-open": "",
            "output-mode": "object",
            "selectable-rows": "",
            "size": "0"
          },
          on: {
            "item-added": function ($event) {
              return _vm.showSnackbar('Hardware added');
            },
            "viewModeChanged": _vm.onHardwareViewModeChange
          },
          model: {
            value: _vm.selectedCalloutHardware,
            callback: function ($$v) {
              _vm.selectedCalloutHardware = $$v;
            },
            expression: "selectedCalloutHardware"
          }
        })], 1), _c('v-fade-transition', [_c('AppTableActions', {
          attrs: {
            "color": _vm.EntityType.INVENTORY,
            "items": _vm.selectedCalloutHardwareTableItems,
            "headers": _vm.computedHardwarePickListTableHeaders,
            "show-search-input": "",
            "show-count": "",
            "title": "Selected Hardware"
          },
          on: {
            "rowClicked": _vm.onClickOfRemoveHardwarePickListItem
          },
          scopedSlots: _vm._u([{
            key: "actions",
            fn: function ({
              item
            }) {
              return [_c('ButtonMiniActionRemoveFromList', {
                on: {
                  "click": function ($event) {
                    return _vm.onClickOfRemoveHardwarePickListItem(item);
                  }
                }
              })];
            }
          }])
        })], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonSave', {
          on: {
            "click": _vm.onClickOfSave
          }
        }), _c('ButtonCancel', {
          on: {
            "click": _vm.closeDialog
          }
        })];
      },
      proxy: true
    }])
  }, {
    ..._vm.$listeners
  }));
};
var staticRenderFns = [];
export { render, staticRenderFns };