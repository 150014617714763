var _a;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Emit, Prop } from 'vue-property-decorator';
import BaseSearch from '~/nasa_ui/base/BaseSearch';
import { EntityType } from '~/nasa_ui/types';
import { sortObjectBy } from '~/nasa_ui/utils';
import { PhysicalInventoryAuditsTableHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
import { PhysicalInventoryAuditsDisplay, transformPhysicalInventoryAudit } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let SearchPhysicalInventoryAudit = class SearchPhysicalInventoryAudit extends BaseSearch {
    entityType = EntityType.PHYSICAL_INVENTORY_AUDIT;
    hint = 'Physical Inventory Audit type or building';
    placeholder = 'search for physical inventory audits';
    searchResultsTableHeaders = PhysicalInventoryAuditsTableHeaders;
    searchResults = null;
    SEARCH_QUERY = gql `
    fragment PiaSearch on PhysicalInventoryAudit {
      building
      closedByUserId
      closedDate
      createdByUserId
      createdDateTime
      id
      itemCount
      nodeId
      sampleSize
      status
      subType

      closedByUser {
        firstName
        id
        lastName
        nodeId
      }

      physicalInventoryAuditItems {
        totalCount
      }
    }

    query SearchPhysicalInventoryAudits($query: String) {
      searchPhysicalInventoryAudits(query: $query) {
        nodes {
          ...PiaSearch
        }
      }
    }
  `;
    disableSearch;
    get shouldDisableSearch() {
        return this.disableSearch;
    }
    get searchResultsTableItems() {
        if (!this.searchResults) {
            return [];
        }
        return transformPhysicalInventoryAudit(this.searchResults).sort(sortObjectBy('createdDateTime', true));
    }
    onClickOfSearchResult(selectedItem) {
        this.reset();
        return this.isOutputModeString ? selectedItem.nodeId : selectedItem;
    }
};
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], SearchPhysicalInventoryAudit.prototype, "disableSearch", void 0);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof PhysicalInventoryAuditsDisplay !== "undefined" && PhysicalInventoryAuditsDisplay) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], SearchPhysicalInventoryAudit.prototype, "onClickOfSearchResult", null);
SearchPhysicalInventoryAudit = __decorate([
    Component
], SearchPhysicalInventoryAudit);
export default SearchPhysicalInventoryAudit;
