var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop } from 'vue-property-decorator';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import ModalConfirmationDelete from '~/nasa_ui/components/ModalConfirmationDelete/ModalConfirmationDelete.vue';
import { AlertType } from '~/nasa_ui/types';
import { RiskResponseDisplay } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let ArchiveRisk = class ArchiveRisk extends Mixins(BaseCosmic) {
    isSaving = false;
    showDialog = false;
    risk;
    get adminPermission() {
        return null;
    }
    get isArchived() {
        return this.risk.isArchived;
    }
    get shouldDisableArchiveButton() {
        // if (!this.adminPermission) {
        //   return true;
        // }
        return this.isSaving;
    }
    // called from ui
    onAffirmativeClicked() {
        this.archiveRisk();
        this.showDialog = false;
    }
    onClickOfArchiveButton() {
        this.showDialog = true;
    }
    onNegativeClicked() {
        this.showDialog = false;
    }
    async archiveRisk() {
        if (!this.risk) {
            return;
        }
        try {
            this.isSaving = true;
            const resp = await this.$http.put(`/risks/${this.risk.id}`, {
                category: this.risk.category,
                cause: this.risk.cause,
                closedDate: this.risk.closedDate,
                closureType: this.risk.closureType,
                consequence: this.risk.consequence,
                comment: ``,
                effect: this.risk.effect,
                hardwareType: this.risk.hardwareType,
                isArchived: !this.risk.isArchived,
                isClosed: this.risk.isClosed,
                isSupplier: this.risk.isSupplier,
                likelihood: this.risk.likelihood,
                number: this.risk.number,
                organizationCode: this.risk.organizationCode,
                ownerContactId: this.risk.ownerContactId,
                showOnReports: this.risk.showOnReports,
                title: this.risk.title
            });
            if (resp) {
                this.$notification.add({
                    text: `${this.risk.isArchived ? 'Unarchived' : 'Archived'}.`,
                    type: AlertType.SUCCESS
                });
                this.$message('reload', true);
                // while waiting on the parent to reload, keep showing spinner instead of isArchived
                setTimeout(() => {
                    this.isSaving = false;
                }, 2500);
            }
        }
        catch (err) {
            this.isSaving = false;
            this.$message('reload', true);
            this.$errorUtility({
                err,
                backupErrorText: `Failed to archive risk.`
            });
        }
    }
};
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof RiskResponseDisplay !== "undefined" && RiskResponseDisplay) === "function" ? _a : Object)
], ArchiveRisk.prototype, "risk", void 0);
ArchiveRisk = __decorate([
    Component({
        components: {
            ModalConfirmationDelete
        }
    })
], ArchiveRisk);
export default ArchiveRisk;
