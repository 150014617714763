var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_vm.shouldShowConfirmRemoveAlternateDialog ? _c('AreYouSureDialog', {
    on: {
      "affirmativeClicked": _vm.onConfirmRemoveAlternate,
      "negativeClicked": function ($event) {
        _vm.shouldShowConfirmRemoveAlternateDialog = false;
      }
    }
  }, [_c('p', {
    attrs: {
      "slot": "text"
    },
    slot: "text"
  }, [_vm._v(" Are you sure you want remove this alternate? "), _c('br'), _vm._v("This cannot be undone. ")])]) : _vm._e(), _vm.shouldShowModalInventoryAlternate ? _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.ITEM_MASTER
    },
    on: {
      "modal-close": _vm.hideModal
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(_vm._s(_vm.modalTitle))];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [!_vm.selectedAlternateCopy ? _c('SearchItemMaster', {
          attrs: {
            "output-mode": "object",
            "existing-item-masters": _vm.existingValuesForItemMasterAlternateSearch
          },
          model: {
            value: _vm.selectedAlternateCopy,
            callback: function ($$v) {
              _vm.selectedAlternateCopy = $$v;
            },
            expression: "selectedAlternateCopy"
          }
        }) : _vm._e(), _vm.selectedAlternateCopy ? _c('MiniItemMaster', {
          attrs: {
            "as-built-number-for-mini": _vm.selectedAlternateCopy.asBuiltNumber,
            "drawing-number-for-mini": _vm.selectedAlternateCopy.drawingNumber,
            "inline-clear": !_vm.isEditingAlternate,
            "side-for-mini": _vm.selectedAlternateCopy.side,
            "title": "Selected Item Master"
          },
          on: {
            "inlineClear": _vm.resetData
          }
        }) : _vm._e()], 1), _vm.selectedAlternateCopy && _vm.isSelectedAlternateAlreadyInAllAlternates ? _c('v-flex', {
          attrs: {
            "xs12": "",
            "mt-3": ""
          }
        }, [_c('Alert', {
          attrs: {
            "type": _vm.AlertType.WARNING
          }
        }, [_c('p', [_c('strong', [_vm._v(_vm._s(_vm.selectedAlternateCopy.drawingNumber) + " " + _vm._s(_vm.selectedAlternateCopy.asBuiltNumber))]), _vm._v(" already exists as "), _c('strong', [_vm._v(_vm._s(_vm.selectedAlternateCopy.type))]), _vm._v(" alternate. ")])])], 1) : _vm._e(), _c('v-flex', {
          attrs: {
            "xs12": "",
            "mt-3": "",
            "mb-2": ""
          }
        }, [_c('c-radio-group', {
          attrs: {
            "disabled": !_vm.canEditAlternates
          },
          model: {
            value: _vm.selectedAlternateType,
            callback: function ($$v) {
              _vm.selectedAlternateType = $$v;
            },
            expression: "selectedAlternateType"
          }
        }, [_c('c-radio', {
          attrs: {
            "c-value": _vm.AlternateType.PREFERRED
          }
        }, [_vm._v(" Is Preferred? ")]), _c('c-radio', {
          attrs: {
            "c-value": _vm.AlternateType.ALTERNATE
          }
        }, [_vm._v(" Is Alternate? ")]), _c('c-radio', {
          attrs: {
            "c-value": _vm.AlternateType.TRAINING_PREFERRED
          }
        }, [_vm._v(" Is Training Preferred? ")]), _c('c-radio', {
          attrs: {
            "c-value": _vm.AlternateType.TRAINING_ALTERNATE
          }
        }, [_vm._v(" Is Training Alternate? ")])], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonSave', {
          staticClass: "ml-0",
          attrs: {
            "disabled": _vm.shouldDisableSaveButton,
            "isSaving": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfSaveAlternate
          }
        }, [_vm._v(_vm._s(_vm.isEditingAlternate ? 'Update' : 'Add') + " Alternate")]), _c('ButtonCancel', {
          attrs: {
            "disabled": _vm.isSaving || _vm.isRemoving
          },
          on: {
            "click": _vm.onClickOfCancelEdit
          }
        }), _c('v-spacer'), _vm.selectedAlternate ? _c('ButtonRemove', {
          attrs: {
            "disabled": _vm.isSaving || _vm.isRemoving,
            "isRemoving": _vm.isRemoving
          },
          on: {
            "click": _vm.onClickOfRemoveAlternate
          }
        }, [_vm._v("Remove Alternate")]) : _vm._e()];
      },
      proxy: true
    }], null, false, 3099340394)
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };