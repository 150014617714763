import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { DocumentType } from '~/db_types';
import CurrentUserMixin from '~/nasa_ui/mixins/CurrentUserMixin';
import ReadOnlyMixin from '~/nasa_ui/mixins/ReadOnlyMixin';
import { AlertType, EntityType } from '~/nasa_ui/types';
const DR_UPDATE_MUTATION = gql `
  mutation UpdateDrDocumentUserAccess($id: UUID!, $patch: CosmicDocumentDrPatch!) {
    updateCosmicDocumentDrById(input: { id: $id, patch: $patch }) {
      clientMutationId
    }
  }
`;
const EZT_UPDATE_MUTATION = gql `
  mutation UpdateEztDocumentUserAccess($id: UUID!, $patch: CosmicDocumentEztPatch!) {
    updateCosmicDocumentEztById(input: { id: $id, patch: $patch }) {
      clientMutationId
    }
  }
`;
const RCA_UPDATE_MUTATION = gql `
  mutation UpdateRcaDocumentUserAccess($id: UUID!, $patch: CosmicDocumentRcaPatch!) {
    updateCosmicDocumentRcaById(input: { id: $id, patch: $patch }) {
      clientMutationId
    }
  }
`;
const TPS_UPDATE_MUTATION = gql `
  mutation UpdateTpsDocumentUserAccess($id: UUID!, $patch: CosmicDocumentTpsPatch!) {
    updateCosmicDocumentTpsById(input: { id: $id, patch: $patch }) {
      clientMutationId
    }
  }
`;
const WORKFLOW_UPDATE_MUTATION = gql `
  mutation UpdateWorkflowDocumentUserAccess($id: UUID!, $patch: CosmicDocumentWorkflowPatch!) {
    updateCosmicDocumentWorkflowById(input: { id: $id, patch: $patch }) {
      clientMutationId
    }
  }
`;
let DocumentAdditionalUserAccess = class DocumentAdditionalUserAccess extends Mixins(ReadOnlyMixin, CurrentUserMixin) {
    isLoading = false;
    isOpen = false;
    isSaving = false;
    EntityType = EntityType;
    documentSubType = null;
    entity = null;
    formData = {
        isExportControlled: false,
        isProprietary: false,
        attributes: {
            additionalUserAccesses: []
        }
    };
    id;
    get mutationQueryBasedOnDocumentSubType() {
        if (!this.documentSubType) {
            return null;
        }
        return this.documentSubType === DocumentType.C_DISCREPANCY_REPORT
            ? DR_UPDATE_MUTATION
            : this.documentSubType === DocumentType.C_EASY_TRANSFER
                ? EZT_UPDATE_MUTATION
                : this.documentSubType === DocumentType.C_RCA
                    ? RCA_UPDATE_MUTATION
                    : this.documentSubType === DocumentType.C_TPS
                        ? TPS_UPDATE_MUTATION
                        : this.documentSubType === DocumentType.C_WORKFLOW
                            ? WORKFLOW_UPDATE_MUTATION
                            : null;
    }
    async onClickOfSave() {
        this.isSaving = true;
        // If the user has changed both these values to false, confirm additionalUserAccesses is empty
        if (!this.formData.isExportControlled && !this.formData.isProprietary) {
            this.formData.attributes.additionalUserAccesses = [];
        }
        try {
            this.isSaving = true;
            await this.saveData();
            this.isOpen = this.formData.isExportControlled || this.formData.isProprietary;
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Save successful.'
            });
            this.$message('reload_mini');
        }
        catch (err) {
            this.$errorUtility({ err });
        }
        this.isSaving = false;
    }
    saveData() {
        if (this.mutationQueryBasedOnDocumentSubType === null) {
            return;
        }
        const variables = {
            id: this.id,
            patch: this.formData
        };
        return this.$apollo.mutate({ mutation: this.mutationQueryBasedOnDocumentSubType, variables });
    }
    mapToFormData(entity) {
        const attributes = Object.keys(entity.attributes).length ? entity.attributes : {};
        attributes.additionalUserAccesses = attributes.additionalUserAccesses || [];
        this.formData = {
            isExportControlled: entity.isExportControlled ?? false,
            isProprietary: entity.isProprietary ?? false,
            attributes
        };
    }
    async fetchEntity() {
        this.isLoading = true;
        try {
            const { data } = await this.$apollo.query({
                query: gql `
          fragment DrDocumentUserAccess on CosmicDocumentDr {
            id
            nodeId
            isExportControlled
            isProprietary
            attributes
          }

          fragment EztDocumentUserAccess on CosmicDocumentEzt {
            id
            nodeId
            isExportControlled
            isProprietary
            attributes
          }

          fragment RcaDocumentUserAccess on CosmicDocumentRca {
            id
            nodeId
            isExportControlled
            isProprietary
            attributes
          }

          fragment TpsDocumentUserAccess on CosmicDocumentTps {
            id
            nodeId
            isExportControlled
            isProprietary
            attributes
          }

          fragment WorkflowDocumentUserAccess on CosmicDocumentWorkflow {
            id
            nodeId
            isExportControlled
            isProprietary
            attributes
          }

          query FetchDocumentUserAccess($id: UUID!) {
            cosmicDocumentDrById(id: $id) {
              ...DrDocumentUserAccess
            }

            cosmicDocumentTpsById(id: $id) {
              ...TpsDocumentUserAccess
            }

            cosmicDocumentRcaById(id: $id) {
              ...RcaDocumentUserAccess
            }

            cosmicDocumentEztById(id: $id) {
              ...EztDocumentUserAccess
            }

            cosmicDocumentWorkflowById(id: $id) {
              ...WorkflowDocumentUserAccess
            }
          }
        `,
                variables: { id: this.id }
            });
            this.entity =
                data.cosmicDocumentDrById ||
                    data.cosmicDocumentEztById ||
                    data.cosmicDocumentRcaById ||
                    data.cosmicDocumentTpsById ||
                    data.cosmicDocumentWorkflowById;
            if (data.cosmicDocumentDrById) {
                this.documentSubType = DocumentType.C_DISCREPANCY_REPORT;
            }
            else if (data.cosmicDocumentEztById) {
                this.documentSubType = DocumentType.C_EASY_TRANSFER;
            }
            else if (data.cosmicDocumentRcaById) {
                this.documentSubType = DocumentType.C_RCA;
            }
            else if (data.cosmicDocumentTpsById) {
                this.documentSubType = DocumentType.C_TPS;
            }
            else if (data.cosmicDocumentWorkflowById) {
                this.documentSubType = DocumentType.C_WORKFLOW;
            }
            if (this.entity) {
                this.mapToFormData(this.entity);
                this.formData.attributes.additionalUserAccesses.push({
                    userId: this.currentUser?.id || ''
                });
                if (this.formData.isExportControlled || this.formData.isProprietary) {
                    this.isOpen = true;
                }
            }
        }
        catch (err) {
            this.$errorUtility({ err });
        }
        this.isLoading = false;
    }
};
__decorate([
    Prop({
        required: true,
        type: String
    }),
    __metadata("design:type", Object)
], DocumentAdditionalUserAccess.prototype, "id", void 0);
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], DocumentAdditionalUserAccess.prototype, "fetchEntity", null);
DocumentAdditionalUserAccess = __decorate([
    Component
], DocumentAdditionalUserAccess);
export default DocumentAdditionalUserAccess;
