var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('CalibrationRequirementModalTemplate', {
    attrs: {
      "existing-calibration-types": _vm.existingCalibrationTypes,
      "hide-title-section": ""
    },
    on: {
      "error": _vm.onError,
      "modal-close": _vm.emitModalClose
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('ButtonSave', {
          staticClass: "mx-2",
          attrs: {
            "disabled": _vm.shouldDisableSaveButton
          },
          on: {
            "click": _vm.onClickOfEdit
          }
        }, [_vm._v(" Update Calibration requirement ")]), _c('ButtonCancel', {
          attrs: {
            "disabled": _vm.isRemoving || _vm.isSaving
          },
          on: {
            "click": _vm.emitModalClose
          }
        }), _c('v-spacer'), _vm.isEdit ? _c('ButtonRemove', {
          attrs: {
            "disabled": _vm.isSaving || _vm.isRemoving,
            "isRemoving": _vm.isRemoving
          },
          on: {
            "click": _vm.onClickOfDelete
          }
        }, [_vm._v(" Remove Calibration requirement ")]) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };