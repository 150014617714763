var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": "",
      "mb-3": ""
    }
  }, [!_vm.isReadOnly ? _c('v-flex', {
    attrs: {
      "xs12": "",
      "mb-3": ""
    }
  }, [_c('ButtonGeneric', {
    staticClass: "ml-0",
    attrs: {
      "icon": _vm.$icons['AUTHORITY']
    },
    on: {
      "click": function ($event) {
        _vm.showAuthorizationModal = true;
      }
    }
  }, [_vm.hasAuthorityData ? _c('span', [_vm._v("Edit Authorization info")]) : _c('span', [_vm._v("Add Authorization info")])])], 1) : _vm._e(), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppAuthorityDisplay', {
    attrs: {
      "authority-data": _vm.authorityData
    }
  })], 1), _vm.showAuthorizationModal ? _c('ModalActivityAuthority', {
    attrs: {
      "activity-id": _vm.id
    },
    on: {
      "modal-close": function ($event) {
        _vm.showAuthorizationModal = false;
      },
      "entityEdited": _vm.emitEntityEdited
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };