import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop } from 'vue-property-decorator';
import BaseDelete from '~/nasa_ui/base/BaseDelete';
import { AlertType, EntityType } from '~/nasa_ui/types';
import { UserPermission } from '~/nasa_ui/types/enums/user';
let DeleteContract = class DeleteContract extends Mixins(BaseDelete) {
    number;
    get shouldDisableDeleteButton() {
        return !this.currentUserHasPermission(UserPermission.CONTRACT_ADMIN) || this.isDeleting;
    }
    // called from ui
    onAffirmativeClicked() {
        this.deleteEvent();
    }
    async deleteEvent() {
        try {
            this.isDeleting = true;
            const resp = await this.$http.delete(`contracts/${encodeURIComponent(this.number)}`);
            if (resp?.status === 200) {
                this.$notification.add({
                    text: 'Contract deleted.',
                    type: AlertType.SUCCESS
                });
                this.emitDeletionComplete();
                this.$router.push(`/${EntityType.CONTRACT}/search`);
            }
        }
        catch (err) {
            this.isDeleting = false;
            this.$notification.add({
                type: AlertType.ERROR,
                text: `This Contract is in use and cannot be deleted.`
            });
            this.onNegativeClicked();
        }
    }
};
__decorate([
    Prop({
        type: String,
        required: true
    }),
    __metadata("design:type", String)
], DeleteContract.prototype, "number", void 0);
DeleteContract = __decorate([
    Component
], DeleteContract);
export default DeleteContract;
