var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';
import { CommentType, Maybe } from '~/db_types';
import BaseModalWithUser from '~/nasa_ui/base/BaseModalWithUser';
import { CommentsRepoMixin } from '~/nasa_ui/DAL/comment';
import { AlertType } from '~/nasa_ui/types';
import { isNullOrUndefined } from '~/nasa_ui/utils/helpers/isNullOrUndefined';
let ModalCommentBase = class ModalCommentBase extends Mixins(BaseModalWithUser, CommentsRepoMixin) {
    selectedComment = null;
    formData = {
        comment: '',
        subType: CommentType.GENERAL
    };
    createCommentTypes;
    editCommentTypes;
    get isFormValid() {
        if (!this.formData) {
            return false;
        }
        return this.formData.comment !== '' && this.formData.subType !== undefined && this.formData.subType !== null;
    }
    async fetchEntity(id) {
        if (!id) {
            return;
        }
        const data = await this.getCommentById({ id }, { error: 'Failed to retreive comment.' });
        if (data?.commentById) {
            this.selectedComment = data.commentById;
            this.formData = {
                comment: data.commentById.comment,
                subType: data.commentById.subType
            };
        }
    }
    async createEntity(commentPayload) {
        if (!commentPayload) {
            return;
        }
        if (this.canUseApi2(commentPayload)) {
            const body = {
                subType: commentPayload.subType,
                comment: commentPayload.comment.trim()
            };
            const queryParams = { ...commentPayload };
            // remap eventId to cosmicEventId for api2
            //@ts-ignore
            if (commentPayload.eventId) {
                //@ts-ignore
                queryParams['cosmicEventId'] = commentPayload.eventId;
                //@ts-ignore
                delete queryParams.eventId;
            }
            //@ts-ignore
            delete queryParams.subType;
            //@ts-ignore
            delete queryParams.comment;
            const resp = await this.$http.post(`/comments`, body, {
                params: queryParams
            });
            if (resp) {
                this.$notification.add({
                    type: AlertType.SUCCESS,
                    text: 'Created.'
                });
            }
        }
        else {
            const variables = { comment: commentPayload };
            await this.createComment(variables, {
                success: 'Created.',
                error: 'Failed to create comment.'
            });
        }
    }
    /**
     * if the passed object has any of the listed properties then we CANT create it with api2
     *
     * @param commentObject
     */
    canUseApi2(commentObject) {
        // This should be better typed but ¯\_(ツ)_/¯
        const api2NoNoFk = [
            'activityId',
            'changeSetAuthorityId',
            'changeSetId',
            'documentId',
            'itemDrawing',
            'itemDrawingNodeId',
            'itemMaster',
            'itemMasterNodeId',
            'itemInstance',
            'itemInstanceNodeId',
            'physicalInventoryAuditNodeId'
        ];
        return Object.keys(commentObject)
            .filter((key) => api2NoNoFk.includes(key))
            .every((key) => isNullOrUndefined(commentObject[key]));
    }
    emitModalClose() { }
};
__decorate([
    Prop({
        default() {
            return Object.values(CommentType);
        },
        type: Array
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], ModalCommentBase.prototype, "createCommentTypes", void 0);
__decorate([
    Prop({
        default() {
            return Object.values(CommentType);
        },
        type: Array
    }),
    __metadata("design:type", typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object)
], ModalCommentBase.prototype, "editCommentTypes", void 0);
__decorate([
    Emit('modal-close'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalCommentBase.prototype, "emitModalClose", null);
ModalCommentBase = __decorate([
    Component
], ModalCommentBase);
export default ModalCommentBase;
