var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { DocumentType } from '~/db_types';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import { Maybe } from '~/nasa_ui/types';
let FormDocumentCloneSteps = class FormDocumentCloneSteps extends BaseCosmic {
    formData = {
        cloneApprovals: false,
        cloneAttachments: false,
        cloneHardware: false,
        cloneSteps: false,
        cloneTools: false
    };
    documentId;
    documentType;
    steps;
    value;
    get hideHardwareAndTools() {
        return this.documentType === DocumentType.C_WORKFLOW;
    }
    get stepLabel() {
        return this.documentType === DocumentType.C_WORKFLOW ? 'Tasks' : 'Steps';
    }
    get stepsCount() {
        return this.steps.length;
    }
    created() {
        this.formData.cloneApprovals = this.value.cloneApprovals;
        this.formData.cloneAttachments = this.value.cloneAttachments;
        this.formData.cloneHardware = this.value.cloneHardware;
        this.formData.cloneTools = this.value.cloneTools;
    }
    onChangeOfFormData(formData) { }
};
__decorate([
    Prop({
        type: String,
        required: true
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], FormDocumentCloneSteps.prototype, "documentId", void 0);
__decorate([
    Prop({
        type: String,
        required: false
    }),
    __metadata("design:type", typeof (_b = typeof DocumentType !== "undefined" && DocumentType) === "function" ? _b : Object)
], FormDocumentCloneSteps.prototype, "documentType", void 0);
__decorate([
    Prop({
        type: Array,
        required: true
    }),
    __metadata("design:type", Array)
], FormDocumentCloneSteps.prototype, "steps", void 0);
__decorate([
    Prop({
        type: Object,
        required: true
    }),
    __metadata("design:type", Object)
], FormDocumentCloneSteps.prototype, "value", void 0);
__decorate([
    Emit('input'),
    Watch('formData', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], FormDocumentCloneSteps.prototype, "onChangeOfFormData", null);
FormDocumentCloneSteps = __decorate([
    Component
], FormDocumentCloneSteps);
export default FormDocumentCloneSteps;
