/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./AppFileAttachmentList.vue?vue&type=template&id=b0adb25c&scoped=true"
import script from "./AppFileAttachmentList.ts?vue&type=script&lang=ts&external"
export * from "./AppFileAttachmentList.ts?vue&type=script&lang=ts&external"
import style0 from "./AppFileAttachmentList.vue?vue&type=style&index=0&id=b0adb25c&prod&lang=scss&scoped=true"
import style1 from "./AppFileAttachmentList.vue?vue&type=style&index=1&id=b0adb25c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0adb25c",
  null
  
)

export default component.exports