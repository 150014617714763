import { __decorate, __metadata } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
let TabAdminTelemetry = class TabAdminTelemetry extends Vue {
    onShowingLogsTab(hash) {
        if (hash.includes('Telemetry')) {
            this.$message('fetch_telemetry_log');
        }
    }
};
__decorate([
    Watch('$route.hash', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], TabAdminTelemetry.prototype, "onShowingLogsTab", null);
TabAdminTelemetry = __decorate([
    Component
], TabAdminTelemetry);
export default TabAdminTelemetry;
