var _a;
import { __decorate, __metadata } from "tslib";
import Excel from 'exceljs';
import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';
import { ChangeSetType, DocumentType } from '~/db_types';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { WorkOrderStepRepoMixin } from '~/nasa_ui/DAL/document/WorkOrderStep';
import { DefaultDocumentAuthorityOverride } from '~/nasa_ui/types/enums/document';
let ModalWorkOrderStepImportSpreadsheet = class ModalWorkOrderStepImportSpreadsheet extends Mixins(BaseModal, WorkOrderStepRepoMixin) {
    isSaving = false;
    fileAccepted = false;
    showUploadButton = true;
    stepsToCreate = [];
    documentId;
    documentType;
    startingStep;
    get authorityOverrideGroupCode() {
        const groupCode = DefaultDocumentAuthorityOverride.get(this.documentType);
        if (!groupCode) {
            throw Error('Unsupported Document Type: Could not find default authority code');
        }
        return groupCode;
    }
    onFileSelected(file) {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = async () => {
            const buffer = reader.result;
            const ws = await this.loadWorksheet(buffer);
            this.stepsToCreate = this.parseWorksheet(ws);
            this.fileAccepted = true;
        };
    }
    async loadWorksheet(buffer) {
        const workbook = new Excel.Workbook();
        let wb;
        try {
            wb = await workbook.xlsx.load(buffer);
        }
        catch (err) {
            this.$errorUtility({
                err,
                backupErrorText: 'Unable to load XLS data. Please make sure you are adding a properly formatted excel file'
            });
            throw err;
        }
        try {
            const ws = wb.getWorksheet('Steps');
            if (!ws) {
                throw Error('There was an error while loading the spreadsheet data. Make sure the uploaded workbook contains a sheet named "Steps"');
            }
            return ws;
        }
        catch (err) {
            this.$errorUtility({
                err,
                backupErrorText: err.message
            });
            throw err;
        }
    }
    parseWorksheet(worksheet) {
        const headerIndex = this.findHeaderRowIndex(worksheet);
        const stepCount = worksheet.actualRowCount - headerIndex;
        const stepRows = worksheet.getRows(headerIndex + 1, stepCount);
        const steps = stepRows?.map((row) => {
            const [_, action, warning, cation, notes] = row.values;
            return {
                action: action?.toLocaleString(),
                warning: warning?.toLocaleString(),
                caution: cation?.toLocaleString(),
                notes: notes?.toString()
            };
        }) ?? [];
        try {
            const stepsWithValues = steps.filter((s) => s.action || s.caution || s.notes || s.warning);
            if (!stepsWithValues.length) {
                throw Error('No data was found in the Steps spreadsheet');
            }
            return stepsWithValues;
        }
        catch (err) {
            this.$errorUtility({
                err,
                backupErrorText: err.message
            });
            throw err;
        }
    }
    findHeaderRowIndex(worksheet) {
        try {
            let index = null;
            worksheet.eachRow(({ values }, rowNumber) => {
                if (index !== null) {
                    return;
                }
                if (values[1] === 'Action' && values[2] === 'Warning' && values[3] === 'Caution' && values[4] === 'Notes') {
                    index = rowNumber;
                }
            });
            if (index !== null) {
                return index;
            }
            else {
                throw Error('No valid header row was found');
            }
        }
        catch (err) {
            this.$errorUtility({
                err,
                backupErrorText: 'Could not find valid column headers. Column headers should be Action, Warning, Caution, and Notes'
            });
            throw err;
        }
    }
    async createSteps() {
        this.isSaving = true;
        const payload = {
            stepsInput: {
                documentId: this.documentId,
                workOrderSteps: this.stepsToCreate.map((step, index) => ({
                    ...step,
                    stepNumber: this.startingStep + index + 1,
                    subType: ChangeSetType.WORK_ORDER_STEP,
                    authoritiesOverride: [{ groupCode: this.authorityOverrideGroupCode, requiresComment: true }]
                }))
            }
        };
        const data = await this.createWorkOrderSteps(payload, {
            success: `${this.stepsToCreate.length} step${this.stepsToCreate.length === 1 ? '' : 's'} added.`
        });
        return data;
    }
};
__decorate([
    Prop({
        required: true,
        type: String
    }),
    __metadata("design:type", Object)
], ModalWorkOrderStepImportSpreadsheet.prototype, "documentId", void 0);
__decorate([
    Prop({
        required: true,
        type: String
    }),
    __metadata("design:type", typeof (_a = typeof DocumentType !== "undefined" && DocumentType) === "function" ? _a : Object)
], ModalWorkOrderStepImportSpreadsheet.prototype, "documentType", void 0);
__decorate([
    Prop({
        required: false,
        default: 1,
        type: Number
    }),
    __metadata("design:type", Number)
], ModalWorkOrderStepImportSpreadsheet.prototype, "startingStep", void 0);
__decorate([
    Emit('created'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], ModalWorkOrderStepImportSpreadsheet.prototype, "createSteps", null);
ModalWorkOrderStepImportSpreadsheet = __decorate([
    Component
], ModalWorkOrderStepImportSpreadsheet);
export default ModalWorkOrderStepImportSpreadsheet;
