import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import colors from 'vuetify/es5/util/colors';
import { UserSupportContext } from '~/application.config';
import { OrganizationRepoMixin } from '~/nasa_ui/DAL/organization';
import { LogType } from '~/nasa_ui/types/enums/log';
let StatsMostHitRoutes = class StatsMostHitRoutes extends Mixins(OrganizationRepoMixin) {
    currentItems = [];
    isLoading = false;
    organizations = [];
    queryResults = [];
    selectedOrgs = [];
    selectedSubType = LogType.TELEMETRY;
    showSettings = false;
    formData = {
        endDate: null,
        selectedOrgs: [],
        startDate: null,
        subType: LogType.TELEMETRY
    };
    subTypeOptions = [
        { displayText: `Telemetry`, value: LogType.TELEMETRY },
        { displayText: 'Errors', value: LogType.ERROR }
    ];
    get chartColors() {
        // lighten, darken, accent, base
        return Object.keys(colors)
            .map((color) => colors[color].lighten2)
            .reverse()
            .slice(4);
    }
    get chartData() {
        return {
            labels: this.currentItems?.map((item) => item._route),
            datasets: [
                {
                    label: 'Total',
                    data: this.currentItems?.map((item) => item.totalCount),
                    backgroundColor: this.chartColors
                }
            ]
        };
    }
    get chartOptions() {
        return {
            responsive: true,
            indexAxis: 'y',
            plugins: {
                legend: {
                    display: false
                }
            }
        };
    }
    get computedTableColor() {
        return this.selectedSubType === 'TELEMETRY' ? 'primary' : 'error darken-1';
    }
    get gqlMostHitRoutesQueryVariables() {
        const dateRange = {};
        if (this.formData.startDate) {
            dateRange.start = {
                value: this.formData.startDate,
                inclusive: true
            };
        }
        if (this.formData.endDate) {
            dateRange.end = {
                value: this.formData.endDate,
                inclusive: true
            };
        }
        return {
            dateRange: Object.keys(dateRange).length ? dateRange : null,
            subType: this.selectedSubType
        };
    }
    get orgSelectOptions() {
        return this.organizations.map((org) => {
            return {
                displayText: org.name,
                value: org.code
            };
        });
    }
    get subTypeDisplay() {
        return this.selectedSubType === LogType.TELEMETRY
            ? `Telemetry`
            : this.selectedSubType === LogType.ERROR
                ? `Errors`
                : `?`;
    }
    get tableHeaders() {
        return [
            {
                text: 'Route',
                value: '_route'
            },
            {
                text: 'Org code',
                value: '_orgCode'
            },
            {
                text: 'Count',
                value: '_count',
                align: 'right'
            }
        ];
    }
    get tableItems() {
        return this.queryResults
            .filter((result) => this.selectedOrgs.includes(result.organizationCode || ''))
            .map((result) => {
            const _route = result.sanitizedPath;
            const _orgCode = result.organizationCode;
            const _count = this.$numberDisplay(result.totalCount);
            return {
                _route,
                _orgCode,
                _count,
                ...result
            };
        })
            .sort((a, b) => (b.totalCount || 0) - (a.totalCount || 0));
    }
    async fetchOrganizations() {
        try {
            const resp = await this.getAllUserSupportContextOrgs({
                userSupportOrgCodes: Object.values(UserSupportContext)
            });
            this.organizations = (resp?.organizations?.nodes || []);
            // pre-select all orgs
            this.formData.selectedOrgs = this.organizations.map((org) => org.code);
            this.selectedOrgs = [...this.formData.selectedOrgs];
        }
        catch (err) {
            this.$errorUtility({ err });
        }
    }
    async runQuery() {
        try {
            this.isLoading = true;
            const resp = await this.$apollo.query({
                query: gql `
          query MostHitRoutesForStatsTab($dateRange: DatetimeRangeInput, $subType: String) {
            metricReport(groupPath: true, groupOrganizationCode: true, subType: $subType, dateRange: $dateRange) {
              nodes {
                organizationCode
                sanitizedPath
                totalCount
              }
            }
          }
        `,
                variables: { ...this.gqlMostHitRoutesQueryVariables }
            });
            if (resp.data.metricReport?.nodes) {
                this.queryResults = resp.data.metricReport.nodes;
            }
        }
        catch (err) {
            this.$errorUtility({ err });
        }
        this.isLoading = false;
    }
    updateCurrentItems(e) {
        // Doesn't work when you sort the table or filter the results :(
        const start = (e.page - 1) * e.rowsPerPage;
        const end = (e.page + 1) * e.rowsPerPage;
        this.currentItems = this.tableItems.slice(start, end);
    }
    updateResults() {
        this.selectedSubType = this.formData.subType;
        this.runQuery();
        this.selectedOrgs = [...this.formData.selectedOrgs];
        this.showSettings = false;
    }
    onRouteHashChange(hash) {
        this.fetchOrganizations();
        this.runQuery();
    }
};
__decorate([
    Watch('$route.hash', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], StatsMostHitRoutes.prototype, "onRouteHashChange", null);
StatsMostHitRoutes = __decorate([
    Component
], StatsMostHitRoutes);
export default StatsMostHitRoutes;
