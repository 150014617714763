/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./ModalRelatedDocumentsCreate.vue?vue&type=template&id=d9650ac6&scoped=true"
import script from "./ModalRelatedDocumentsCreate.ts?vue&type=script&lang=ts&external"
export * from "./ModalRelatedDocumentsCreate.ts?vue&type=script&lang=ts&external"
import style0 from "./ModalRelatedDocumentsCreate.vue?vue&type=style&index=0&id=d9650ac6&prod&lang=scss&scoped=true"
import style1 from "./ModalRelatedDocumentsCreate.vue?vue&type=style&index=1&id=d9650ac6&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d9650ac6",
  null
  
)

export default component.exports