var _a, _b;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import { CosmicItemDrawingSlimFragment } from '~/nasa_ui/DAL/itemDrawing/slim';
import { CosmicLimitedLifeQuotaFragment } from '~/nasa_ui/DAL/limitedLife';
import { EntityType } from '~/nasa_ui/types';
import { OutputMode } from '~/nasa_ui/types/enums/general';
import { ItemMastersTableHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
import { transformItemMasters } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
const imFragment = gql `
  ${CosmicLimitedLifeQuotaFragment}
  ${CosmicItemDrawingSlimFragment}

  fragment ItemMastersOnSelectorItemMaster on ItemMaster {
    asBuiltNumber
    drawingNumber
    initializationStatus
    inventoryControl
    inventoryControl
    inventoryQuantity
    nodeId
    side
    subType

    limitedLife {
      ...CosmicLimitedLifeQuota
    }

    itemDrawing {
      ...CosmicItemDrawingSlim
    }
  }
`;
let SelectorItemMaster = class SelectorItemMaster extends BaseCosmic {
    itemDrawing = null;
    ItemMastersTableHeaders = ItemMastersTableHeaders;
    selectedItemMasterNodeId = null;
    showRefreshAlert = false;
    clearable;
    hideSelectedTitle;
    itemDrawingNodeId;
    itemMasterNodeId;
    outputMode;
    size;
    get computedItemMasters() {
        if (!this.itemMasters) {
            return [];
        }
        return transformItemMasters(this.itemMasters);
    }
    get createItemMasterUrl() {
        return `/hardware/${EntityType.ITEM_MASTER}/create`;
    }
    get itemMasters() {
        if (!this.itemDrawing) {
            return [];
        }
        return this.itemDrawing.itemMasters.nodes;
    }
    get hasItemMasterSelected() {
        return this.selectedItemMasterNodeId !== null;
    }
    get rowsPerPageItems() {
        return [
            50,
            100,
            500,
            {
                text: '$vuetify.dataIterator.rowsPerPageAll',
                value: -1
            }
        ];
    }
    get shouldShowClearSelectionButton() {
        return this.clearable && this.selectedItemMasterNodeId;
    }
    get shouldShowTableSearchInput() {
        return this.computedItemMasters.length > 5;
    }
    clearSelectedItemMasterNodeId() {
        this.selectedItemMasterNodeId = null;
        this.emitInput(null);
    }
    async fetchItemMasterByNodeId(nodeId) {
        try {
            if (!nodeId) {
                this.$errorUtility({ err: 'Item Master nodeId is required.' });
                return null;
            }
            const resp = await this.$apollo.query({
                query: gql `
          ${imFragment}

          query ItemMasterOnSelectorItemMaster($nodeId: ID!) {
            itemMaster(nodeId: $nodeId) {
              ...ItemMastersOnSelectorItemMaster
            }
          }
        `,
                variables: {
                    nodeId
                }
            });
            return resp.data.itemMaster || null;
        }
        catch (err) {
            this.$errorUtility({ err });
            return null;
        }
    }
    async fetchItemMastersFromItemDrawing() {
        try {
            if (!this.itemDrawingNodeId) {
                return;
            }
            const resp = await this.$apollo.query({
                fetchPolicy: 'network-only',
                query: gql `
          ${imFragment}

          query FetchItemMastersFromItemDrawing($nodeId: ID!) {
            itemDrawing(nodeId: $nodeId) {
              itemMasters {
                nodes {
                  ...ItemMastersOnSelectorItemMaster
                }
              }
              nodeId
            }
          }
        `,
                variables: {
                    nodeId: this.itemDrawingNodeId
                }
            });
            this.itemDrawing = resp.data.itemDrawing;
        }
        catch (err) {
            this.$errorUtility({ err });
        }
    }
    onRowClick(val) {
        this.selectedItemMasterNodeId = val.nodeId;
    }
    refreshSearchQuery() {
        this.fetchItemMastersFromItemDrawing();
        this.showRefreshAlert = false;
    }
    emitInput(selectedItem) {
        if (!selectedItem) {
            return null;
        }
        this.selectedItemMasterNodeId = selectedItem.nodeId;
        if (this.outputMode === OutputMode.string) {
            return this.selectedItemMasterNodeId;
        }
        return selectedItem;
    }
    emitResults() { }
    onItemDrawingNodeIdChange() {
        if (this.itemDrawingNodeId) {
            this.fetchItemMastersFromItemDrawing();
        }
    }
    onComputedItemMastersChange(itemMasters) {
        if (itemMasters.length === 1) {
            this.selectedItemMasterNodeId = itemMasters[0].nodeId;
            this.emitInput(itemMasters[0]);
        }
    }
    async onItemMasterNodeIdChange(nodeId) {
        if (nodeId) {
            this.selectedItemMasterNodeId = nodeId;
            const itemMaster = await this.fetchItemMasterByNodeId(nodeId);
            if (itemMaster) {
                this.emitInput(itemMaster);
            }
        }
    }
};
__decorate([
    Prop({
        default: true,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], SelectorItemMaster.prototype, "clearable", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], SelectorItemMaster.prototype, "hideSelectedTitle", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], SelectorItemMaster.prototype, "itemDrawingNodeId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], SelectorItemMaster.prototype, "itemMasterNodeId", void 0);
__decorate([
    Prop({ default: OutputMode.string, type: [String, String] }),
    __metadata("design:type", Object)
], SelectorItemMaster.prototype, "outputMode", void 0);
__decorate([
    Prop({
        type: String,
        default: '2'
    }),
    __metadata("design:type", String)
], SelectorItemMaster.prototype, "size", void 0);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], SelectorItemMaster.prototype, "emitInput", null);
__decorate([
    Emit('results'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], SelectorItemMaster.prototype, "emitResults", null);
__decorate([
    Watch('itemDrawingNodeId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], SelectorItemMaster.prototype, "onItemDrawingNodeIdChange", null);
__decorate([
    Watch('computedItemMasters'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], SelectorItemMaster.prototype, "onComputedItemMastersChange", null);
__decorate([
    Watch('itemMasterNodeId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", Promise)
], SelectorItemMaster.prototype, "onItemMasterNodeIdChange", null);
SelectorItemMaster = __decorate([
    Component
], SelectorItemMaster);
export default SelectorItemMaster;
