var _a, _b, _c, _d;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { ChangeSetStatus, DocumentType, StepOverviewFragment } from '~/db_types';
import ReadOnlyMixin from '~/nasa_ui/mixins/ReadOnlyMixin';
import StepMixin from '~/nasa_ui/mixins/StepMixin';
import { EntityType } from '~/nasa_ui/types/enums';
let WorkOrderStepsOverview = class WorkOrderStepsOverview extends Mixins(ReadOnlyMixin, StepMixin) {
    EntityType = EntityType;
    DocumentType = DocumentType;
    showNewWorkOrderStepModal = false;
    stepsDefault = 0;
    currentStep;
    documentId;
    documentType;
    hideGoToStep;
    isInitialized;
    pendingInitialization;
    steps;
    onStepSelectionUpdate() {
        const $comp = this.$refs[`step-${this.currentStep}`];
        if ($comp && $comp[0]) {
            $comp[0].$el.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
        }
    }
    get stepDisplayText() {
        return this.usesTasks(this.documentType) ? 'Task' : 'Step';
    }
    get stepsTotal() {
        return this.steps.length;
    }
    get stepsMax() {
        return this.stepsTotal + 1;
    }
    onClickOfGoToStep(stepNumber) {
        return stepNumber - 1; // return index instead of step number
    }
    onModalWorkOrderStepCreateAndEditClose() {
        this.showNewWorkOrderStepModal = false;
        this.$message('reload_work_order_steps');
    }
    openNewStepModal(i) {
        this.stepsDefault = i + 2;
        this.showNewWorkOrderStepModal = true;
    }
    emitProcedureUpdated(updates) {
        return updates;
    }
    emitStepDeleted(step) {
        this.$message('workorder-steps-updated');
        return step;
    }
    emitStepUpdated(step) {
        this.$message('workorder-steps-updated');
        return step;
    }
    isStepApproved(step) {
        return step.changeSet?.computedStatus === ChangeSetStatus.APPROVED;
    }
    isStepRejected(step) {
        return step.changeSet?.computedStatus === ChangeSetStatus.REJECTED;
    }
};
__decorate([
    Prop({
        type: Number,
        default: 0
    }),
    __metadata("design:type", Number)
], WorkOrderStepsOverview.prototype, "currentStep", void 0);
__decorate([
    Prop({
        type: String,
        required: true
    }),
    __metadata("design:type", Object)
], WorkOrderStepsOverview.prototype, "documentId", void 0);
__decorate([
    Prop({
        type: String,
        required: false
    }),
    __metadata("design:type", typeof (_a = typeof DocumentType !== "undefined" && DocumentType) === "function" ? _a : Object)
], WorkOrderStepsOverview.prototype, "documentType", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], WorkOrderStepsOverview.prototype, "hideGoToStep", void 0);
__decorate([
    Prop({
        type: Boolean,
        required: false
    }),
    __metadata("design:type", Boolean)
], WorkOrderStepsOverview.prototype, "isInitialized", void 0);
__decorate([
    Prop({
        type: Boolean,
        required: false
    }),
    __metadata("design:type", Boolean)
], WorkOrderStepsOverview.prototype, "pendingInitialization", void 0);
__decorate([
    Prop({
        type: Array,
        required: true
    }),
    __metadata("design:type", Array)
], WorkOrderStepsOverview.prototype, "steps", void 0);
__decorate([
    Watch('currentStep'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], WorkOrderStepsOverview.prototype, "onStepSelectionUpdate", null);
__decorate([
    Emit('go-to-step'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], WorkOrderStepsOverview.prototype, "onClickOfGoToStep", null);
__decorate([
    Emit('procedure-updated'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof Partial !== "undefined" && Partial) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], WorkOrderStepsOverview.prototype, "emitProcedureUpdated", null);
__decorate([
    Emit('step-deleted'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_c = typeof StepOverviewFragment !== "undefined" && StepOverviewFragment) === "function" ? _c : Object]),
    __metadata("design:returntype", void 0)
], WorkOrderStepsOverview.prototype, "emitStepDeleted", null);
__decorate([
    Emit('step-updated'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_d = typeof StepOverviewFragment !== "undefined" && StepOverviewFragment) === "function" ? _d : Object]),
    __metadata("design:returntype", void 0)
], WorkOrderStepsOverview.prototype, "emitStepUpdated", null);
WorkOrderStepsOverview = __decorate([
    Component
], WorkOrderStepsOverview);
export default WorkOrderStepsOverview;
