var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('v-card', {
    staticClass: "white--text",
    attrs: {
      "color": "primary"
    }
  }, [_c('v-card-text', [_c('AppHeader', {
    staticClass: "mb-2",
    attrs: {
      "uppercase": false,
      "show-horizontal-rule": false
    }
  }, [_vm._v("User Telemetry")]), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs1": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Fetched total",
      "bold": "",
      "align": "right"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.$numberDisplay(_vm.userSelectedNumberOfRecords)))])])], 1), _c('v-flex', {
    attrs: {
      "xs1": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Total",
      "bold": "",
      "align": "right"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.$numberDisplay(_vm.totalCount)))])])], 1), _c('v-flex', {
    attrs: {
      "xs1": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Last day",
      "bold": "",
      "align": "right"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.$numberDisplay(_vm.telemetryLogCounts.day)))])])], 1), _c('v-flex', {
    attrs: {
      "xs1": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Last week",
      "bold": "",
      "align": "right"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.$numberDisplay(_vm.telemetryLogCounts.week)))])])], 1), _c('v-flex', {
    attrs: {
      "xs1": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Last month",
      "bold": "",
      "align": "right"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.$numberDisplay(_vm.telemetryLogCounts.month)))])])], 1)], 1), _c('v-card', {
    staticClass: "pa-2"
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": "",
      "align-center": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs2": "",
      "mt-4": "",
      "mr-2": ""
    }
  }, [_c('AppNumber', {
    attrs: {
      "label": "Num of records to fetch",
      "outline": ""
    },
    model: {
      value: _vm.userSelectedNumberOfRecords,
      callback: function ($$v) {
        _vm.userSelectedNumberOfRecords = $$v;
      },
      expression: "userSelectedNumberOfRecords"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs2": ""
    }
  }, [_c('ButtonGeneric', {
    attrs: {
      "icon": _vm.$icons.search,
      "small": ""
    },
    on: {
      "click": _vm.fetchTelemetryLogs
    }
  }, [_vm._v("Re-fetch")])], 1)], 1)], 1)], 1)], 1), _c('AppTableActions', {
    key: "errorLogsTable",
    attrs: {
      "headers": _vm.computedTelemetryLogHeaders,
      "items": _vm.mappedTelemetryLogs,
      "loading": _vm.isLoading,
      "rows-per-page-items": _vm.rowsPerPageItems,
      "user-defined-pagination": _vm.paginationSettings,
      "color": "primary",
      "show-search-input": ""
    },
    scopedSlots: _vm._u([{
      key: "url",
      fn: function ({
        item
      }) {
        return [_c('dl', {
          staticClass: "from_to"
        }, [_c('dt', [_vm._v("From")]), _c('dd', {
          staticClass: "pl-2"
        }, [_c('a', {
          attrs: {
            "href": item.fromUrl
          }
        }, [_vm._v(_vm._s(item.fromUrl))])]), _c('dt', [_vm._v("To")]), _c('dd', {
          staticClass: "pl-2"
        }, [_c('a', {
          attrs: {
            "href": item.toUrl
          }
        }, [_vm._v(_vm._s(item.toUrl))])])])];
      }
    }, {
      key: "browser",
      fn: function ({
        item
      }) {
        return [item.attributes && item.attributes.browser ? _c('div', {
          staticClass: "no-wrap d-flex align-center"
        }, [_c('c-icon-fa', {
          staticClass: "pr-2",
          attrs: {
            "icon": _vm.browserToIcon(item.attributes.browser.type)
          }
        }), _vm._v(" v"), _c('span', {
          staticClass: "font-weight-bold fa-lg"
        }, [_vm._v(_vm._s(item.attributes.browser.version))])], 1) : _vm._e()];
      }
    }, {
      key: "actions",
      fn: function ({
        item
      }) {
        return [_c('ButtonMiniActionInfo', {
          on: {
            "click": function ($event) {
              _vm.selectedTelemetryLog = item;
            }
          }
        })];
      }
    }]),
    model: {
      value: _vm.selectedTelemetryLog,
      callback: function ($$v) {
        _vm.selectedTelemetryLog = $$v;
      },
      expression: "selectedTelemetryLog"
    }
  })], 1), _vm.selectedTelemetryLog ? _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.COMMENT,
      "hide-title-section": ""
    },
    on: {
      "modal-close": function ($event) {
        _vm.selectedTelemetryLog = null;
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_vm.selectedTelemetryLog.createdByUser ? _c('MiniUser', {
          staticClass: "mb-2",
          attrs: {
            "id": _vm.selectedTelemetryLog.createdByUser.id,
            "read-only": false,
            "show-manage-link": ""
          }
        }) : _vm._e(), _c('v-card', {
          staticClass: "pa-3",
          attrs: {
            "dark": ""
          }
        }, [_c('vue-json-pretty', {
          attrs: {
            "dark": "",
            "path": 'res',
            "data": _vm.selectedTelemetryLog
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 4294009290)
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };