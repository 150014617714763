/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./TabWorkOrderTasks.vue?vue&type=template&id=2c627447&scoped=true"
import script from "./TabWorkOrderTasks.ts?vue&type=script&lang=ts&external"
export * from "./TabWorkOrderTasks.ts?vue&type=script&lang=ts&external"
import style0 from "./TabWorkOrderTasks.vue?vue&type=style&index=0&id=2c627447&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c627447",
  null
  
)

export default component.exports