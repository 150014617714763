import { __decorate, __metadata } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import BaseButton from '~/nasa_ui/base/BaseButton';
let ButtonClone = class ButtonClone extends BaseButton {
    get computedColor() {
        return this.color || '--cosmic-grey-lighten-4';
    }
    get computedDisabled() {
        return !this.href && !this.to ? true : this.disabled;
    }
    get computedIcon() {
        return this.icon || 'fa-duotone fa-copy';
    }
    get computedTextColor() {
        return this.textColor || '--cosmic-text';
    }
    created() {
        if (!this.href && !this.to) {
            console.error('Missing prop: must provide either "href" or "to".');
        }
    }
};
__decorate([
    Prop({
        default: '--cosmic-grey-lighten-4',
        type: String
    }),
    __metadata("design:type", String)
], ButtonClone.prototype, "color", void 0);
__decorate([
    Prop({
        default: '--cosmic-text',
        type: String
    }),
    __metadata("design:type", String)
], ButtonClone.prototype, "textColor", void 0);
ButtonClone = __decorate([
    Component
], ButtonClone);
export default ButtonClone;
