var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_vm.shouldShowForm && !_vm.selectedAttachment ? _c('ModalFileAttachmentCreate', {
    attrs: {
      "attachment-ids": _vm.attachmentIds,
      "document-id": _vm.documentId,
      "read-only": _vm.isReadOnly
    },
    on: {
      "modal-close": _vm.onModalClose,
      "saved": _vm.fetchAttachments
    }
  }) : _vm._e(), _vm.shouldShowForm && _vm.selectedAttachment ? _c('ModalFileAttachmentEdit', {
    attrs: {
      "attachment-ids": _vm.attachmentIds,
      "document-id": _vm.documentId,
      "entity": _vm.selectedAttachment,
      "read-only": _vm.isReadOnly
    },
    on: {
      "modal-close": _vm.onModalClose,
      "saved": _vm.fetchAttachments
    }
  }) : _vm._e(), !_vm.readOnly ? _c('ButtonCreate', {
    staticClass: "mb-2",
    on: {
      "click": function ($event) {
        _vm.shouldShowForm = true;
      }
    }
  }, [_c('c-icon-fa', {
    staticClass: "pr-2",
    attrs: {
      "slot": "icon",
      "icon": "fad fa-file-plus",
      "icon-color": "white"
    },
    slot: "icon"
  }), _c('span', [_vm._v("Attach file")])], 1) : _vm._e(), _c('AppTableActions', {
    staticClass: "mb-3",
    attrs: {
      "headers": _vm.tableHeaders,
      "icon": _vm.$icons.attachment,
      "items": _vm.computedTableItems,
      "color": _vm.EntityType.COMMENT,
      "hide-pagination": "",
      "title": "Attachments"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function ({
        item
      }) {
        return [_c('ButtonMiniActionInfo', {
          attrs: {
            "disabled": _vm.isReadOnly
          },
          on: {
            "click": function ($event) {
              return _vm.onClickOfTableRow(item);
            }
          }
        }), _c('ButtonMiniActionDelete', {
          attrs: {
            "disabled": _vm.isReadOnly
          },
          on: {
            "click": function ($event) {
              return _vm.onClickOfRemoveButton(item.id);
            }
          }
        })];
      }
    }])
  }), _vm.shouldShowAreYouSure ? _c('AreYouSureDialog', {
    on: {
      "affirmativeClicked": _vm.onAffirmativeClicked,
      "negativeClicked": _vm.onNegativeClicked
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };