var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-container', {
    attrs: {
      "fluid": "",
      "grid-list-lg": "",
      "pa-0": "",
      "mt-1": ""
    }
  }, [_c('AppTable', {
    staticClass: "mb-2",
    attrs: {
      "disable-initial-sort": "",
      "items": _vm.computedChangeLogTableItems,
      "headers": _vm.changeLogTableHeaders,
      "color": _vm.EntityType.CHANGE_SET,
      "show-search-input": "",
      "title": "Change log"
    },
    scopedSlots: _vm._u([{
      key: "_createdDateTimeWithBreak",
      fn: function ({
        item
      }) {
        return [_c('div', {
          staticClass: "monospace_font mb-1"
        }, [_vm._v(_vm._s(item._createdDateTime))]), _c('div', {
          staticClass: "monospace_font"
        }, [_vm._v(" ("), _c('small', [_vm._v(_vm._s(item._createdDateTimeDistanceInWordsFromToday))]), _vm._v(") ")])];
      }
    }, {
      key: "_changes",
      fn: function ({
        item
      }) {
        return [_c('div', {
          staticClass: "mt-2"
        }, [_c('AppStringDiffViewer', {
          attrs: {
            "from-value": item.fromLikelihood,
            "to-value": item.toLikelihood,
            "title": "Likelihood"
          }
        }), _c('AppStringDiffViewer', {
          attrs: {
            "from-value": item.fromConsequence,
            "to-value": item.toConsequence,
            "title": "Consequence"
          }
        }), _c('AppStringDiffViewer', {
          attrs: {
            "from-value": item.fromMitigationStatus,
            "to-value": item.toMitigationStatus,
            "title": "Mitigation status"
          }
        }), _c('AppStringDiffViewer', {
          attrs: {
            "from-value": item.fromProjectedCloseDate,
            "to-value": item.toProjectedCloseDate,
            "title": "Projected closure"
          }
        }), _c('AppStringDiffViewer', {
          attrs: {
            "from-value": item.fromStatus,
            "to-value": item.toStatus,
            "title": "Status"
          }
        })], 1), item.changeDetails && item.changeDetails.changedTo && item.changeDetails.changedFrom ? _c('div', {
          staticClass: "mt-2"
        }, [_c('vueJsonCompare', {
          attrs: {
            "new-data": item.changeDetails.changedTo,
            "old-data": item.changeDetails.changedFrom
          }
        })], 1) : _vm._e()];
      }
    }])
  }), _c('AppTable', {
    staticClass: "mb-2",
    attrs: {
      "color": "primary",
      "headers": _vm.emailHistoryTableHeaders,
      "items": _vm.computedEmailHistoryTableItems,
      "icon": _vm.$icons.mail,
      "dense-rows": "",
      "sticky": "",
      "disable-initial-sort": "",
      "title": "Email history"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };