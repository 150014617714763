import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop } from 'vue-property-decorator';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import BaseModal from '~/nasa_ui/base/BaseModal';
let ModalAssemblyTemplate = class ModalAssemblyTemplate extends Mixins(BaseModal, BaseCosmic) {
    id;
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], ModalAssemblyTemplate.prototype, "id", void 0);
ModalAssemblyTemplate = __decorate([
    Component
], ModalAssemblyTemplate);
export default ModalAssemblyTemplate;
