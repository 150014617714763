var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop } from 'vue-property-decorator';
import { DocumentRelationType, RelatedDocument } from '~/db_types';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { setRelatedDocumentsMutation } from '~/nasa_ui/DAL/document/relatedDocuments';
import { AlertType } from '~/nasa_ui/types';
let ModalRelatedDocumentEdit = class ModalRelatedDocumentEdit extends BaseModal {
    documentNumber = null;
    isSaving = false;
    isSourceDocument = false;
    existingRelatedDocuments;
    forDocumentId;
    relatedDocument;
    get duplicateDocumentNumbersError() {
        // first get all documents EXCEPT the current one
        const otherDocuments = this.existingRelatedDocuments.filter((doc) => doc.documentNumber?.toLowerCase() !== this.relatedDocument.documentNumber?.toLowerCase());
        // then check for duplicates against the entered value
        return otherDocuments.some((doc) => doc.documentNumber?.toLowerCase() === this.documentNumber?.toLowerCase())
            ? 'Cannot duplicate Document numbers.'
            : null;
    }
    get gqlRelatedDocumentMutationVariables() {
        if (!this.forDocumentId || !this.relatedDocumentsForMutation.length) {
            return null;
        }
        return {
            forDocumentId: this.forDocumentId,
            relatedDocuments: this.relatedDocumentsForMutation
        };
    }
    get isCosmicDocument() {
        return Boolean(this.relatedDocument.documentId);
    }
    get isFormValid() {
        return this.isCosmicDocument ? true : Boolean(this.documentNumber && !this.duplicateDocumentNumbersError);
    }
    get isFracasDr() {
        return this.relatedDocument.relationType === DocumentRelationType.FRACAS_DISCREPANCY_REPORT;
    }
    get modifiedDocument() {
        return {
            documentId: this.relatedDocument.documentId,
            documentNumber: this.isCosmicDocument ? this.relatedDocument.documentNumber : this.documentNumber,
            relationType: this.isSourceDocument
                ? DocumentRelationType.SOURCE_DOCUMENT
                : DocumentRelationType.REFERENCE_DOCUMENT
        };
    }
    get relatedDocumentEntity() {
        return this.relatedDocument.relatedDocument || null;
    }
    get relatedDocumentsForMutation() {
        // clone existing documents, and modify the one in question
        return this.existingRelatedDocuments.map((doc) => {
            if (this.relatedDocument.documentNumber === doc.documentNumber) {
                return this.modifiedDocument;
            }
            return {
                documentId: doc.documentId,
                documentNumber: doc.documentNumber,
                relationType: doc.relationType
            };
        });
    }
    get shouldDisableRemoveButton() {
        return this.isSaving;
    }
    get shouldDisableSaveButton() {
        return !this.isFormValid || this.isSaving || this.isFracasDr;
    }
    created() {
        this.isSourceDocument = this.relatedDocument.relationType === DocumentRelationType.SOURCE_DOCUMENT;
        if (!this.isCosmicDocument) {
            this.documentNumber = this.relatedDocument.documentNumber;
        }
    }
    async onClickOfSave() {
        if (!this.isFormValid) {
            return;
        }
        // clone existing documents, and modify the one in question
        const relatedDocuments = this.existingRelatedDocuments.map((doc) => {
            if (this.relatedDocument.documentNumber === doc.documentNumber) {
                return this.modifiedDocument;
            }
            return {
                documentId: doc.documentId,
                documentNumber: doc.documentNumber,
                relationType: doc.relationType
            };
        });
        const success = await this.setRelatedDocuments({
            forDocumentId: this.forDocumentId,
            relatedDocuments
        });
        if (success) {
            this.$notification.add({ text: 'Updated related Document.', type: AlertType.SUCCESS });
            this.emitRefresh();
            this.emitModalClose();
        }
    }
    emitModalClose() { }
    emitRefresh() { }
    async setRelatedDocuments(input) {
        if (!input) {
            return;
        }
        try {
            this.isSaving = true;
            const resp = await setRelatedDocumentsMutation(input);
            if (resp?.data?.setRelatedDocuments) {
                return true;
            }
        }
        catch (err) {
            this.$errorUtility({ err });
        }
        this.isSaving = false;
        return false;
    }
};
__decorate([
    Prop({
        type: Array
    }),
    __metadata("design:type", Array)
], ModalRelatedDocumentEdit.prototype, "existingRelatedDocuments", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], ModalRelatedDocumentEdit.prototype, "forDocumentId", void 0);
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof RelatedDocument !== "undefined" && RelatedDocument) === "function" ? _a : Object)
], ModalRelatedDocumentEdit.prototype, "relatedDocument", void 0);
__decorate([
    Emit('modal-close'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalRelatedDocumentEdit.prototype, "emitModalClose", null);
__decorate([
    Emit('refresh'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalRelatedDocumentEdit.prototype, "emitRefresh", null);
ModalRelatedDocumentEdit = __decorate([
    Component
], ModalRelatedDocumentEdit);
export default ModalRelatedDocumentEdit;
