import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { AlertType, CommentTypeDisplay, EntityType, NonEditableCommentTypes } from '~/nasa_ui/types';
import { UserPermission } from '~/nasa_ui/types/enums/user';
import ModalCommentBase from '../ModalCommentBase/ModalCommentBase';
import CommentModalTemplate from '../ModalCommentBase/_commentModalTemplate.vue';
let ModalCommentEdit = class ModalCommentEdit extends Mixins(ModalCommentBase) {
    isRemoving = false;
    isSaving = false;
    id;
    readOnly;
    get canPromote() {
        if (!this.entityTypesAssociatedWithComment || this.isCommentImmutable) {
            return false;
        }
        return this.isEitherItemMasterOrItemDrawing && !this.isItemInstance;
    }
    get entityTypesAssociatedWithComment() {
        if (!this.selectedComment) {
            return [];
        }
        const entityTypes = [];
        // not trying to be fancy here
        if (this.selectedComment.activityId) {
            entityTypes.push(EntityType.ACTIVITY);
        }
        if (this.selectedComment.changeSetAuthorityId || this.selectedComment.changeSetId) {
            entityTypes.push(EntityType.CHANGE_SET);
        }
        if (this.selectedComment.deliveryMatrixItemId) {
            entityTypes.push(EntityType.DELIVERY_MATRIX_ITEM);
        }
        if (this.selectedComment.documentId) {
            entityTypes.push(EntityType.DOCUMENT);
        }
        if (this.selectedComment.eventId) {
            entityTypes.push(EntityType.EVENT);
        }
        if (this.selectedComment.hardwareListId) {
            entityTypes.push(EntityType.HARDWARE_LIST);
        }
        if (this.selectedComment.itemInstanceId) {
            entityTypes.push(EntityType.ITEM_INSTANCE);
        }
        if (this.selectedComment.drawingNumber) {
            entityTypes.push(EntityType.ITEM_DRAWING);
        }
        if (this.selectedComment.asBuiltNumber && this.selectedComment.drawingNumber) {
            entityTypes.push(EntityType.ITEM_MASTER);
        }
        if (this.selectedComment.organizationCode) {
            entityTypes.push(EntityType.ORGANIZATION);
        }
        if (this.selectedComment.physicalInventoryAuditId || this.selectedComment.physicalInventoryAuditItemId) {
            entityTypes.push(EntityType.PHYSICAL_INVENTORY_AUDIT);
        }
        if (this.selectedComment.riskId ||
            this.selectedComment.riskMitigationId) {
            entityTypes.push(EntityType.RISK);
        }
        if (this.selectedComment.transactionId) {
            entityTypes.push(EntityType.TRANSACTION);
        }
        return entityTypes;
    }
    get getPermissionForHardware() {
        return [...this.entityTypesAssociatedWithComment]
            .map((entityType) => {
            const perm = `${entityType}_ADMIN`;
            if (Object.hasOwn(UserPermission, perm)) {
                return perm;
            }
        })
            .filter((perm) => perm);
    }
    get isCommentEmpty() {
        if (!this.selectedComment) {
            return false;
        }
        return !(this.selectedComment.comment || '').trim();
    }
    get isCommentImmutable() {
        return (this.isSubTypeDissallowed ||
            (this.selectedComment &&
                this.selectedComment.subType &&
                !this.editCommentTypes?.includes(this.selectedComment.subType)));
    }
    get isEitherItemMasterOrItemInstance() {
        return this.isItemInstance || this.isItemMaster;
    }
    get isEitherItemMasterOrItemDrawing() {
        return this.isItemDrawing || this.isItemMaster;
    }
    get isItemDrawing() {
        return this.entityTypesAssociatedWithComment.includes(EntityType.ITEM_DRAWING) || false;
    }
    get isItemInstance() {
        return this.entityTypesAssociatedWithComment.includes(EntityType.ITEM_INSTANCE) || false;
    }
    get isItemMaster() {
        return this.entityTypesAssociatedWithComment.includes(EntityType.ITEM_MASTER) || false;
    }
    get isSubTypeDissallowed() {
        return Object.values(NonEditableCommentTypes).includes(this.selectedComment?.subType);
    }
    get isUserOwnerOfComment() {
        if (this.selectedComment && this.currentUser) {
            return this.selectedComment.createdByUserId === this.currentUser.id;
        }
    }
    get subTypeDisplay() {
        return this.selectedComment?.subType ? CommentTypeDisplay.get(this.selectedComment.subType) : DEFAULT_DASH;
    }
    get updateCommentPayload() {
        if (!this.formData) {
            return {};
        }
        return {
            comment: this.formData.comment || '',
            subType: this.formData.subType
        };
    }
    async onClickOfPromote(levelToPromote) {
        try {
            this.isSaving = true;
            if (levelToPromote === EntityType.ITEM_DRAWING) {
                await this.editEntity({
                    itemInstanceId: null,
                    asBuiltNumber: null,
                    side: null,
                    drawingNumber: this.selectedComment?.itemDrawing?.drawingNumber
                }, this.id);
            }
            if (levelToPromote === EntityType.ITEM_MASTER) {
                await this.editEntity({
                    itemInstanceId: null
                }, this.id);
            }
            this.$notification.add({
                text: `Your comment was promoted.`,
                type: AlertType.SUCCESS
            });
            this.isSaving = false;
            this.emitReloadComments();
        }
        catch (err) {
            this.$errorUtility({
                err
            });
            this.isSaving = false;
        }
    }
    async onClickOfUpdate() {
        if (!this.updateCommentPayload) {
            return;
        }
        try {
            this.isSaving = true;
            await this.$http.put(`/comments/${this.id}`, this.updateCommentPayload);
            this.isSaving = false;
            this.$message('reload-comments', true);
            this.emitReloadComments();
        }
        catch (err) {
            this.isSaving = false;
            this.emitReloadComments();
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    emitReloadComments() { }
    onIdSync(val) {
        if (val) {
            this.fetchEntity(this.id);
        }
    }
    onClickOfCloseModal() { }
};
__decorate([
    Prop({
        default: '',
        type: String
    }),
    __metadata("design:type", String)
], ModalCommentEdit.prototype, "id", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true
    }),
    __metadata("design:type", Boolean)
], ModalCommentEdit.prototype, "readOnly", void 0);
__decorate([
    Emit('reloadComments'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalCommentEdit.prototype, "emitReloadComments", null);
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], ModalCommentEdit.prototype, "onIdSync", null);
__decorate([
    Emit('modal-close'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalCommentEdit.prototype, "onClickOfCloseModal", null);
ModalCommentEdit = __decorate([
    Component({
        components: {
            CommentModalTemplate
        }
    })
], ModalCommentEdit);
export default ModalCommentEdit;
