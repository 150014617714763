import gql from 'graphql-tag';
export const CosmicEmailNotificationSlimFragment = gql `
  fragment CosmicEmailNotificationSlim on Notification {
    attemptCount
    attributes
    createdByUserId
    createdDateTime
    data
    id
    lastAttemptDateTime
    lastFailureDateTime
    messageHtml
    messageText
    nodeId
    sentDateTime
    subject

    recipients {
      email
      name
    }
  }
`;
