var _a;
import { __decorate, __metadata } from "tslib";
import { differenceInDays, set } from 'date-fns';
import truncate from 'lodash/truncate';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { EntityType, Maybe } from '~/nasa_ui/types';
let AppGantt = class AppGantt extends Vue {
    months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    /**
     * If present, the component will use it with regular href links instead of
     * using path-only router links.
     */
    baseUrl;
    isLoading;
    events;
    year;
    get computedBaseUrl() {
        let baseUrl = this.baseUrl;
        if (!baseUrl) {
            return null;
        }
        if (baseUrl.at(-1) === '/') {
            baseUrl = baseUrl.slice(0, -1);
        }
        return baseUrl;
    }
    get firstDateOfSelectedYear() {
        return set(new Date(`01-01-${this.year}`), { hours: 0, minutes: 0, seconds: 0 });
    }
    get lastDateOfSelectedYear() {
        return set(new Date(`12-31-${this.year}`), { hours: 23, minutes: 59, seconds: 59 });
    }
    get noEvents() {
        return !this.events || !this.events.length;
    }
    get activeBars() {
        if (!this.events) {
            return [];
        }
        return this.events
            .filter((e) => e.id && e.event?.startDate && e.event.endDate && e.name)
            .map((e) => ({
            type: 'event',
            eventId: e.id,
            startDate: e.event?.startDate,
            endDate: e.event?.endDate,
            name: e.name,
            description: e.event?.description,
            overrideStyles: this.buildStyles(e),
            link: `/${EntityType.EVENT}/manage/${e?.id}#information`
        }));
    }
    buildStyles(item) {
        const itemStartDate = new Date(item.event?.startDate);
        const itemEndDate = item.event?.endDate ? new Date(item.event.endDate) : this.lastDateOfSelectedYear;
        if (itemEndDate < this.firstDateOfSelectedYear || itemStartDate > this.lastDateOfSelectedYear) {
            return { display: 'none' };
        }
        const styles = {};
        const startDate = this.firstDateOfSelectedYear > itemStartDate ? this.firstDateOfSelectedYear : itemStartDate;
        const endDate = itemEndDate > this.lastDateOfSelectedYear ? this.lastDateOfSelectedYear : itemEndDate;
        const startDateOffset = this.calculateOffsetFromStartOfYear(startDate);
        const endDateOffset = this.calculateOffsetFromStartOfYear(endDate);
        const width = endDateOffset - startDateOffset;
        const timespanInDays = Math.abs(differenceInDays(startDate, endDate)) + 1;
        const isShortEvent = timespanInDays <= 2;
        const shortDatePadding = isShortEvent ? 1 : 0;
        styles.width = isShortEvent ? '1rem' : `${width}%`;
        styles.left = `calc(${startDateOffset}% - ${shortDatePadding}rem)`;
        styles.transform = isShortEvent ? 'translateX(0.5rem)' : undefined;
        styles.backgroundColor = item.event?.attributes?.color;
        return styles;
    }
    calculateOffsetFromStartOfYear(date) {
        const startDateMonthIndex = date.getMonth();
        const offsetInMonths = startDateMonthIndex / 12;
        const numberOfDaysInStartingMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
        const additionalOffsetInDays = (date.getDate() - 1) / (numberOfDaysInStartingMonth - 1) / 12;
        const offset = (offsetInMonths + additionalOffsetInDays) * 100;
        return offset;
    }
    // If there is a computed url, we use a normal anchor tag href,
    // but if not it will use the vue router
    eventManageRouterLink(item) {
        if (this.computedBaseUrl) {
            return '';
        }
        else {
            return item.link;
        }
    }
    truncateLabel(label) {
        return truncate(label, { length: 25 });
    }
    emitSelectedYear(year) {
        return year;
    }
};
__decorate([
    Prop({
        type: String,
        required: false
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], AppGantt.prototype, "baseUrl", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], AppGantt.prototype, "isLoading", void 0);
__decorate([
    Prop({
        required: false,
        type: Array,
        default: () => []
    }),
    __metadata("design:type", Array)
], AppGantt.prototype, "events", void 0);
__decorate([
    Prop({
        type: Number,
        required: false,
        default: () => new Date().getFullYear()
    }),
    __metadata("design:type", Number)
], AppGantt.prototype, "year", void 0);
__decorate([
    Emit('year-change'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], AppGantt.prototype, "emitSelectedYear", null);
AppGantt = __decorate([
    Component
], AppGantt);
export default AppGantt;
