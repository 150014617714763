import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
let ExpandableMixin = class ExpandableMixin extends Vue {
    localExpanded = true;
    expanded;
    toggleExpansion(val) {
        this.localExpanded = val;
        if (this.localExpanded !== this.expanded) {
            this.emitExpandedUpdate(val);
        }
    }
    emitExpandedUpdate(val) {
        return val;
    }
    onExpandedPropUpdate(val) {
        if (val !== this.localExpanded) {
            this.localExpanded = val;
        }
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: true
    }),
    __metadata("design:type", Boolean)
], ExpandableMixin.prototype, "expanded", void 0);
__decorate([
    Emit('update:expanded'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], ExpandableMixin.prototype, "emitExpandedUpdate", null);
__decorate([
    Watch('expanded', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], ExpandableMixin.prototype, "onExpandedPropUpdate", null);
ExpandableMixin = __decorate([
    Component
], ExpandableMixin);
export default ExpandableMixin;
