var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppFormWrapper', {
    attrs: {
      "color": _vm.EntityType.HARDWARE_LIST_TEMPLATE,
      "size": _vm.size
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_vm.showRefreshAlert ? _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('Alert', {
    attrs: {
      "type": _vm.AlertType.WARNING
    }
  }, [_c('p', [_vm._v("Please refresh the search query to see any Hardware List Template that were just created.")]), _c('p', [_c('ButtonGeneric', {
    attrs: {
      "color": _vm.EntityType.HARDWARE_LIST_TEMPLATE,
      "dark": "",
      "icon": _vm.$icons.reload
    },
    on: {
      "click": _vm.refreshSearchQuery
    }
  }, [_vm._v(" Refresh search query ")])], 1)])], 1) : _vm._e(), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_vm.shouldShowHardwareListsTemplateSearch ? _c('SearchHardwareListTemplate', _vm._b({
    ref: "SearchHardwareListTemplate",
    attrs: {
      "output-mode": "object"
    },
    on: {
      "input": _vm.emitInput,
      "results": _vm.emitResults,
      "searchButtonClicked": _vm.emitSearchButtonClicked,
      "termSearched": _vm.onTermSearched
    }
  }, 'SearchHardwareListTemplate', _vm.$props, false)) : _vm._e()], 1), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_vm.shouldShowHardwareListsTemplateSearch ? _c('ButtonCreate', {
    attrs: {
      "href": _vm.createHardwareListTemplateUrl,
      "color": "success",
      "rel": "noopener",
      "target": "_blank"
    },
    on: {
      "click": function ($event) {
        _vm.showRefreshAlert = true;
      }
    }
  }, [_vm._v(" Create a new Template ")]) : _vm._e()], 1)], 1), _vm.hasSelectedTemplates ? _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_vm._l(_vm.selectedTemplateIds, function (id) {
    return _c('v-flex', {
      key: id,
      staticClass: "mb-2",
      attrs: {
        "xs12": ""
      }
    }, [_c('v-fade-transition', {
      attrs: {
        "hide-on-leave": ""
      }
    }, [_c('MiniHardwareListTemplate', {
      attrs: {
        "id": id,
        "inline-clear": "",
        "title": _vm.title
      },
      on: {
        "inlineClear": _vm.onClearOfSelectedTemplate
      }
    })], 1)], 1);
  }), _c('v-flex', [_c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.shouldShowClearSelectionButton ? _c('ButtonClearSelection', {
    staticClass: "ml-0",
    on: {
      "click": _vm.onClearOfSelectedTemplate
    }
  }) : _vm._e()], 1)], 1)], 2) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };