import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { ChangeSetStatus, DocumentType } from '~/db_types';
import BaseTab from '~/nasa_ui/base/BaseTab';
import { CosmicChangeSetAuthorityFragment } from '~/nasa_ui/DAL/changeSet';
import { CosmicContactSlimFragment } from '~/nasa_ui/DAL/contact/slim';
import { CosmicStepSlimFragment } from '~/nasa_ui/DAL/document/WorkOrderStep';
import GenericDocumentMixin from '~/nasa_ui/mixins/GenericDocumentMixin';
import PdfExportMixin from '~/nasa_ui/mixins/PdfExport';
import StepMixin from '~/nasa_ui/mixins/StepMixin';
import { AlertType, EntityType } from '~/nasa_ui/types';
import { BaseApprovalTableHeaders, StepApprovalTableHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
import { transformChangeSetAuthorities } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
import { StepsStatus } from '~/nasa_ui/utils/StepsStatus';
let TabWorkOrderTasks = class TabWorkOrderTasks extends Mixins(BaseTab, PdfExportMixin, GenericDocumentMixin, StepMixin) {
    AlertType = AlertType;
    approvalTableHeaders = [...BaseApprovalTableHeaders];
    changeSetIdsToApprove = [];
    currentScrollPositionIndex = 0;
    currentViewingStepIndex = 0;
    DocumentType = DocumentType;
    EntityType = EntityType;
    scrollHandler = null;
    scrollPositions = [];
    selectedDocument = null;
    showCompleteStepModal = false;
    showDeleteStepModal = false;
    showImportSpreadsheetModal = false;
    showMoreInfo = false;
    showNewWorkOrderStepModal = false;
    showRejectStepModal = false;
    StepApprovalTableHeaders = StepApprovalTableHeaders;
    workOrderStepToEditId = null;
    get stepStatus() {
        if (!this.selectedDocument?.workOrderSteps?.nodes) {
            return null;
        }
        const doc = this.selectedDocument;
        return new StepsStatus(this.selectedDocument.workOrderSteps.nodes, this.getSourceDoc(doc));
    }
    get activeAuthorities() {
        return this.currentViewingStep?.changeSet?.changeSetAuthorities.nodes.filter((a) => !a?.isHistorical) ?? [];
    }
    get allStepsComplete() {
        return Boolean(this.stepStatus?.allStepsComplete);
    }
    get approvalsTableHeaders() {
        const headers = [...StepApprovalTableHeaders].filter((header) => header.value !== '_stepNumber');
        return headers.slice(0, 5);
    }
    get approvalTableItems() {
        const currentApprovals = this.currentViewingStep?.changeSet?.changeSetAuthorities?.nodes?.filter((approval) => !approval?.isHistorical) ||
            [];
        return transformChangeSetAuthorities(currentApprovals);
    }
    get canEditHardwareList() {
        return Boolean(this.stepStatus?.canEditHardwareList);
    }
    get countApprovals() {
        return this.approvalTableItems.length ?? 0;
    }
    get currentHistoricalChangeSetAuthorities() {
        return this.stepStatus?.getHistoricalChangeSetAuthorities(this.currentViewingStep) || [];
    }
    get currentScrollPosition() {
        return this.scrollPositions[this.currentScrollPositionIndex];
    }
    get currentViewingStep() {
        return this.steps[this.currentViewingStepIndex];
    }
    get currentUserCanApproveStep() {
        return Boolean(this.stepStatus?.currentUserHasApprovalPermission(this.currentViewingStep) &&
            !this.currentViewingStepRejected);
    }
    get currentUserCanRejectStep() {
        return Boolean(this.stepStatus?.currentUserHasApprovalPermission(this.currentViewingStep) &&
            this.supportsStepRejection(this.selectedDocument.subType) &&
            !this.currentViewingStepRejected);
    }
    get currentUserCanApproveAnySteps() {
        return Boolean(this.stepStatus?.currentUserCanApproveAnySteps);
    }
    get currentViewingStepComplete() {
        return Boolean(this.stepStatus?.stepIsCompleted(this.currentViewingStep));
    }
    get currentViewingStepRejected() {
        return Boolean(this.stepStatus?.stepIsRejected(this.currentViewingStep));
    }
    get dueDateDisplay() {
        return this.$dateDisplay(this.currentViewingStep?.projectedDate);
    }
    get isDocumentOpenForEdits() {
        const sourceDoc = this.getSourceDoc(this.selectedDocument);
        return Boolean(sourceDoc?.isEditable);
    }
    get isDocumentApproved() {
        const sourceDoc = this.getSourceDoc(this.selectedDocument);
        return sourceDoc?.initializationStatus === ChangeSetStatus.APPROVED;
    }
    get nextAuthority() {
        return this.currentViewingStep?.changeSet?.status?.nextAuthority?.groupCode;
    }
    get stepDisplayText() {
        let text = '';
        switch (this.selectedDocument?.subType) {
            case DocumentType.C_WORKFLOW:
                text = 'Task';
                break;
            case DocumentType.C_RCA:
                text = 'CAP task';
                break;
            default:
                text = 'Step';
                break;
        }
        return text;
    }
    get steps() {
        return this.selectedDocument?.workOrderSteps.nodes || [];
    }
    get stepsDefault() {
        return this.stepsMax;
    }
    get stepsTotal() {
        return this.selectedDocument?.workOrderSteps.totalCount || 0;
    }
    get stepsMax() {
        return this.stepsTotal + 1;
    }
    get stepsStepNumberList() {
        return this.steps.map((step, index) => {
            const actionText = step?.action || 'No procedure text.';
            return {
                displayText: `${step?.stepNumber} - ${actionText}`,
                value: index
            };
        });
    }
    get shouldDisableCreateButton() {
        return !this.stepStatus?.canCreateStep;
    }
    get shouldDisableEditButton() {
        return !this.stepStatus?.canEditStep(this.currentViewingStep);
    }
    get shouldDisableNextButton() {
        return this.currentViewingStepIndex === (this.selectedDocument?.workOrderSteps.totalCount || 0) - 1;
    }
    get shouldDisablePreviousButton() {
        return this.currentViewingStepIndex === 0;
    }
    get shouldShowRejectButton() {
        return Boolean(this.currentViewingStep && this.selectedDocument && this.supportsStepRejection(this.selectedDocument.subType));
    }
    onClickOfCompleteStepButton() {
        this.showCompleteStepModal = true;
        this.changeSetIdsToApprove = [this.currentViewingStep?.changeSet?.id];
    }
    onClickOfCompleteAllSteps() {
        this.showCompleteStepModal = true;
        this.changeSetIdsToApprove = this.steps.map((step) => step?.changeSet?.id);
    }
    onClickOfDeleteStep() {
        this.showDeleteStepModal = true;
    }
    onClickOfCreateStepButton() {
        this.workOrderStepToEditId = null;
        this.showNewWorkOrderStepModal = true;
    }
    onClickOfImportSpreadsheet() {
        this.showImportSpreadsheetModal = true;
    }
    async onClickOfExportToPdfButton() {
        const getLatestDocument = await this.queryForPDFExport(this.id);
        if (getLatestDocument && this.currentUserActiveSupportContext) {
            const { default: DocumentExport } = await import('~/nasa_ui/utils/DocumentExport');
            new DocumentExport({
                type: this.selectedDocument?.subType,
                context: this.currentUserActiveSupportContext,
                data: getLatestDocument
            });
        }
    }
    onClickOfPreviousStepButton() {
        this.currentScrollPositionIndex--;
        this.currentViewingStepIndex--;
    }
    onClickOfNextStepButton() {
        this.currentScrollPositionIndex++;
        this.currentViewingStepIndex++;
    }
    onClickOfJumpStepFromOverview(stepNumber) {
        this.addParamsToLocation({ step: stepNumber.toString() });
    }
    onClickOfRejectStepButton() {
        this.showRejectStepModal = true;
    }
    async onModalWorkOrderStepCreateAndEditClose(stepId) {
        await this.reset();
        if (!stepId) {
            this.addParamsToLocation({ step: '0' });
        }
        else {
            this.addParamsToLocation({ step: this.steps.findIndex((s) => s?.id === stepId).toString() });
        }
    }
    async onImportSpreadsheetCompletion(data) {
        await this.reset();
        this.showImportSpreadsheetModal = false;
        const [firstAddedStep] = data.createWorkOrderSteps?.workOrderSteps ?? [];
        const stepId = firstAddedStep?.id;
        if (!stepId) {
            this.addParamsToLocation({ step: '0' });
            this.currentViewingStepIndex = 0;
        }
        else {
            this.addParamsToLocation({ step: this.steps.findIndex((s) => s?.id === stepId).toString() });
        }
    }
    async onWorkOrderStepDelete() {
        await this.reset();
        const isLastStep = this.currentViewingStepIndex >= this.steps.length;
        this.addParamsToLocation({
            step: isLastStep ? (this.currentViewingStepIndex - 1).toString() : this.currentViewingStepIndex.toString()
        });
    }
    onChangeOfCurrentScrollPositionIndex(index) {
        // set the scroll position if it doesn't exist yet
        if (!this.scrollPositions[index]) {
            this.scrollPositions[index] = 0;
        }
        // scroll to the appropriate position
        window.scrollTo(0, this.scrollPositions[index]);
    }
    onStepChange() {
        this.$router.replace({
            query: { step: `${this.currentViewingStepIndex + 1}` },
            hash: this.$route.hash
        });
    }
    beforeDestroy() {
        if (this.scrollHandler) {
            window.removeEventListener('scroll', this.scrollHandler);
        }
    }
    async onWorkOrderStepReject() {
        await this.reset();
    }
    created() {
        // fetch document when approvals tab changes the initialization status
        this.$listen('reload_work_order_steps', () => {
            this.fetchEntityById(this.id);
        });
        // add an event listener for updating the current scroll position
        this.scrollHandler = (event) => {
            this.scrollPositions[this.currentScrollPositionIndex] = window.scrollY;
        };
        window.addEventListener('scroll', this.scrollHandler);
    }
    async fetchEntityById(id) {
        this.isFetching = true;
        try {
            const resp = await this.$apollo.query({
                query: gql `
          ${CosmicContactSlimFragment}
          ${CosmicStepSlimFragment}
          ${CosmicChangeSetAuthorityFragment}

          fragment StepsOnWorkflowStepsTab on WorkOrderStep {
            ...CosmicStepSlim

            assigneeContact {
              ...CosmicContactSlim
            }

            changeSet {
              id
              nodeId
              computedStatus

              status {
                nextAuthority {
                  ...CosmicChangeSetAuthority
                }
              }

              changeSetAuthorities {
                nodes {
                  ...CosmicChangeSetAuthority
                }
              }
            }
          }

          query FetchWorkOrderStepsOnWorkflowForTab($id: UUID!) {
            documentById(id: $id) {
              id
              nodeId
              subType
              attachments {
                nodes {
                  id
                  nodeId
                }
              }

              cosmicDocumentWorkflow {
                id
                nodeId
                computedStatus
                initializationStatus
                isEditable
                releaseStatus
                subType
              }

              cosmicDocumentRca {
                id
                nodeId
                computedStatus
                initializationStatus
                isEditable
                releaseStatus
                subType
              }

              workOrderSteps(orderBy: [STEP_NUMBER_ASC]) {
                totalCount
                nodes {
                  ...StepsOnWorkflowStepsTab
                }
              }
            }
          }
        `,
                variables: {
                    id
                }
            });
            if (resp.data) {
                this.selectedDocument = resp.data.documentById;
                this.currentViewingStepIndex = parseInt(this.$route.query.step) - 1 || 0;
            }
        }
        catch (err) {
            this.$errorUtility({
                err
            });
        }
        this.isFetching = false;
    }
    addParamsToLocation(params) {
        history.pushState({}, '', this.$route.path +
            '?' +
            Object.keys(params)
                .map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
            })
                .join('&'));
    }
    async reopenCurrentStep() {
        const authoritiesOverride = this.currentViewingStep?.changeSet?.changeSetAuthorities.nodes
            .filter((authority) => {
            return !authority?.isHistorical;
        })
            .map((authority) => {
            return {
                groupCode: authority?.userGroup?.code,
                requiresQualityControlStamp: authority.requiresQualityControlStamp,
                requiresComment: authority?.requiresComment
            };
        });
        try {
            const resp = await this.$apollo.mutate({
                mutation: gql `
          mutation ReopenWorkOrderChangeSetOnWorkOrderTask($input: ReopenChangeSetsInput!) {
            reopenChangeSets(input: $input) {
              changeSets {
                id
                nodeId
                changeSetAuthorities {
                  nodes {
                    groupCode
                    nodeId
                  }
                }
                workOrderStep {
                  isEditable
                  nodeId
                }
              }
            }
          }
        `,
                variables: {
                    input: {
                        authoritiesOverride: authoritiesOverride,
                        changeSetIds: [this.currentViewingStep?.id],
                        reopenApproved: true
                    }
                }
            });
            this.reset();
            this.$notification.add({ text: 'Task approvals were reset.', type: AlertType.SUCCESS });
            this.$message('workorder-steps-updated');
            this.$message('reload_user_alerts');
            return resp;
        }
        catch (err) {
            this.$errorUtility({
                err
            });
        }
    }
    reset() {
        this.showCompleteStepModal = false;
        this.showDeleteStepModal = false;
        this.showRejectStepModal = false;
        this.showNewWorkOrderStepModal = false;
        this.changeSetIdsToApprove = [];
        this.$message('reload_mini');
        return this.fetchEntityById(this.id);
    }
    syncCurrentViewStepIndex() {
        if (this.$route.query?.step && parseInt(this.$route?.query?.step) - 1 != this.currentViewingStepIndex) {
            this.currentViewingStepIndex = parseInt(this.$route?.query?.step) - 1;
        }
    }
    onNodeIdChange(nodeId) {
        if (nodeId) {
            console.error('TabWorkOrderSteps doesnt use nodeId');
        }
    }
};
__decorate([
    Watch('currentScrollPositionIndex', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], TabWorkOrderTasks.prototype, "onChangeOfCurrentScrollPositionIndex", null);
__decorate([
    Watch('currentViewingStepIndex'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], TabWorkOrderTasks.prototype, "onStepChange", null);
__decorate([
    Watch('$route.query', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], TabWorkOrderTasks.prototype, "syncCurrentViewStepIndex", null);
__decorate([
    Watch('nodeId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], TabWorkOrderTasks.prototype, "onNodeIdChange", null);
TabWorkOrderTasks = __decorate([
    Component({
        components: {
            ModalWorkOrderStepImportSpreadsheet: () => import('~/nasa_ui/components/ModalWorkOrderStepImportSpreadsheet/ModalWorkOrderStepImportSpreadsheet.vue')
        }
    })
], TabWorkOrderTasks);
export default TabWorkOrderTasks;
