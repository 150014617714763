var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-layout', {
    attrs: {
      "column": ""
    }
  }, [_vm.isLoading ? _c('AppLoader', {
    attrs: {
      "center": true,
      "type": "linear"
    }
  }) : _c('div', [!_vm.isReadOnly || !_vm.isInitialized ? _c('ButtonCreate', {
    on: {
      "click": function ($event) {
        _vm.showNewStepModal = true;
      }
    }
  }, [_vm._v(" Create new " + _vm._s(_vm.taskText) + " ")]) : _vm._e(), !_vm.shouldDisableCompleteAllButton ? _c('ButtonGeneric', {
    attrs: {
      "color": "success",
      "tooltip": `Approve multiple ${_vm.taskText}s at once.`
    },
    on: {
      "click": _vm.onClickOfCompleteAllSteps
    }
  }, [_c('div', {
    staticClass: "d-flex align-center mr-2",
    attrs: {
      "slot": "icon"
    },
    slot: "icon"
  }, [_c('c-icon-fa', {
    staticClass: "mr-1",
    attrs: {
      "icon": "fad fa-thumbs-up",
      "icon-color": "var(--v-success-base)"
    }
  }), _c('div', {
    staticClass: "d-flex layout column"
  }, [_c('i', {
    staticClass: "white--text stacked_fa_icon",
    class: _vm.$icons.circle_check
  }), _c('i', {
    staticClass: "white--text stacked_fa_icon",
    class: _vm.$icons.circle_check
  }), _c('i', {
    staticClass: "white--text stacked_fa_icon",
    class: _vm.$icons.circle_check
  })])], 1), _vm._v(" Approve " + _vm._s(_vm.taskText) + "s ")]) : _vm._e(), !_vm.steps.length && !_vm.isLoading ? _c('Alert', {
    staticClass: "mt-3"
  }, [_vm._v(" This Document currently does not have any " + _vm._s(_vm.taskText) + "s. ")]) : _vm._e(), _vm.document && _vm.steps.length ? _c('WorkOrderStepsOverview', {
    attrs: {
      "current-step": _vm.stepIndex,
      "steps": _vm.steps,
      "document-id": _vm.id,
      "document-type": _vm.document.subType,
      "read-only": _vm.isReadOnly,
      "pending-initialization": false,
      "is-initialized": _vm.isInitialized,
      "hide-go-to-step": !_vm.isInitialized || _vm.allStepsComplete
    },
    on: {
      "procedure-updated": _vm.onProcedureUpdated,
      "step-deleted": _vm.fetchSteps,
      "step-updated": _vm.fetchSteps
    }
  }) : _vm._e()], 1), _vm.showNewStepModal ? _c('ModalWorkOrderStepCreateAndEdit', {
    attrs: {
      "document-id": _vm.id,
      "default-step-number": _vm.steps.length + 1,
      "max-step-number": _vm.steps.length + 1,
      "read-only": _vm.isReadOnly
    },
    on: {
      "modal-close": function ($event) {
        _vm.showNewStepModal = false;
      },
      "step-saved": _vm.fetchSteps
    }
  }) : _vm._e(), _vm.showCompleteStepModal ? _c('ModalWorkOrderStepComplete', {
    attrs: {
      "document-id": _vm.id,
      "change-set-ids": _vm.changeSetIdsToApprove,
      "should-require-comment": false
    },
    on: {
      "modal-close": function ($event) {
        _vm.showCompleteStepModal = false;
      },
      "saved": _vm.onCompleteAllStepsSubmit
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };