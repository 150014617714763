var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "max-height": "200px",
      "max-width": "200px",
      "top": "",
      "nudge-left": 250
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on
      }) {
        return [_c('ButtonMiniAction', _vm._g({
          staticClass: "item_icon info_icon",
          attrs: {
            "icon": _vm.$icons[_vm.EntityType.USER_GROUP],
            "icon-size": "20",
            "icon-color": "var(--v-users-base)",
            "tooltip": "View members",
            "tooltip-position": "left"
          },
          on: {
            "click": function ($event) {
              return _vm.onClickOfShowGroupMembers(_vm.code);
            }
          }
        }, on))];
      }
    }])
  }, [_c('v-card', {
    staticClass: "px-4 py-2",
    attrs: {
      "min-width": "200px",
      "max-width": "200px"
    }
  }, [_c('h4', [_vm._v(_vm._s(_vm.groupName))]), _c('v-divider'), _vm.isLoading ? _c('AppLoader', {
    attrs: {
      "color": _vm.EntityType.USER
    }
  }) : _c('div', {
    staticClass: "mt-2"
  }, _vm._l(_vm.displayedGroupMembers, function (member) {
    return _c('div', {
      key: member.id,
      staticClass: "py-1"
    }, [_c('span', {
      staticClass: "users--text darken-4",
      on: {
        "click": function ($event) {
          return _vm.handleClickOfUser(member);
        }
      }
    }, [_vm._v(_vm._s(_vm.$contactDisplay(member)))])]);
  }), 0)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };