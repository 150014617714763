var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
import BaseSelector from '~/nasa_ui/base/BaseSelector';
import { DEFAULT_ROWS_PER_PAGE_ITEMS } from '~/nasa_ui/constants/tablePagination';
import { OutputMode } from '~/nasa_ui/types/enums/general';
import { ItemClassesWithoutMultiple } from '~/nasa_ui/types/enums/hardware';
import { ItemInstanceInventoryTypeDisplay } from '~/nasa_ui/types/enums/item-instance';
import { InventoryTableHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
import { transformInventories } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let SelectorInventory = class SelectorInventory extends BaseSelector {
    externalQuantityHasError = false;
    InventoryTableHeaders = [...InventoryTableHeaders];
    ItemInstanceInventoryTypeDisplay = ItemInstanceInventoryTypeDisplay;
    ItemInstanceInventoryTypeWithoutMultiple = ItemClassesWithoutMultiple;
    manualInputMode = false;
    rowsPerPageItems = DEFAULT_ROWS_PER_PAGE_ITEMS;
    selectorInventoryHardwares = [];
    manualInputForm = {
        externalAsBuiltNumber: null,
        externalBirthDate: null,
        externalCalibrationDate: null,
        externalCalibrationNumber: null,
        externalContractEndingItemNumber: null,
        externalClass: null,
        externalDescription: null,
        externalDrawingNumber: null,
        externalIsTool: false,
        externalLocation: null,
        externalLotNumber: null,
        externalSerialNumber: null,
        externalShelfLifeDate: null,
        externalUsageLifeDate: null,
        isExternal: true,
        quantity: 1
    };
    defaultToExternalTool;
    disableSearch;
    hideCreateInventory;
    overrideViewToManualMode;
    value;
    get createInventoryUrl() {
        const path = !this.searchTerm || this.searchTerm.includes(' ')
            ? '/hardware/initial_receipt_condensed'
            : `/hardware/initial_receipt_condensed?name=${this.searchTerm}`;
        return path;
    }
    get hasSelectorInventoryHardwares() {
        return Boolean(this.selectorInventoryHardwares.length);
    }
    get manualInputFormHasRequiredFields() {
        return this.manualInputForm.externalIsTool
            ? Boolean(this.manualInputForm.externalDescription && this.manualInputForm.quantity)
            : Boolean(this.manualInputForm.externalDrawingNumber && this.manualInputForm.quantity);
    }
    get manualInputFormIsValid() {
        return Boolean(!this.externalQuantityHasError && this.manualInputFormHasRequiredFields);
    }
    get selectedInventoryIds() {
        return this.selectorInventoryHardwares.filter((hw) => Boolean(hw.id)).map((list) => list.id);
    }
    get shouldShowSearchInventory() {
        return !(this.hasSelectorInventoryHardwares && this.singleMode);
    }
    get transformedSearchResults() {
        return this.searchComponentResults ? transformInventories(this.searchComponentResults) : [];
    }
    clearManualInputForm() {
        this.manualInputForm = {
            externalAsBuiltNumber: null,
            externalBirthDate: null,
            externalCalibrationDate: null,
            externalCalibrationNumber: null,
            externalClass: null,
            externalContractEndingItemNumber: null,
            externalDescription: null,
            externalDrawingNumber: null,
            externalIsTool: false,
            externalLocation: null,
            externalLotNumber: null,
            externalSerialNumber: null,
            externalShelfLifeDate: null,
            externalUsageLifeDate: null,
            isExternal: true,
            quantity: 1
        };
    }
    onClickOfManualModeToggle() {
        this.manualInputMode = !this.manualInputMode;
        if (!this.manualInputMode) {
            this.clearManualInputForm();
        }
    }
    onErrorOfExternalQuantity(hasError) {
        this.externalQuantityHasError = Boolean(hasError);
    }
    onSearchInventorySelection(inv) {
        this.selectorInventoryHardwares.push(Object.assign({}, inv, { isExternal: false }));
        this.emitItemAdded();
    }
    onClickOfAddExternalHardware() {
        if (!this.manualInputFormIsValid) {
            return;
        }
        this.selectorInventoryHardwares.push({
            ...this.manualInputForm
        });
        this.clearManualInputForm();
        this.emitItemAdded();
    }
    created() {
        if (this.defaultToExternalTool) {
            this.manualInputMode = true;
            this.manualInputForm.isExternal = true;
            this.manualInputForm.externalIsTool = true;
        }
    }
    onSelectorInventoryHardwaresChange() {
        return this.emitInput(this.selectorInventoryHardwares);
    }
    onOverrideViewToManualModeChange() {
        this.manualInputMode = this.overrideViewToManualMode;
    }
    onManualInputModeChange() {
        return this.manualInputMode ? 'MANUAL' : 'INVENTORY';
    }
    onValueChange(val) {
        if (!val) {
            return;
        }
        this.selectorInventoryHardwares = this.singleMode ? [val] : val;
    }
    emitInput(selectorInventoryHardwares) {
        if (!selectorInventoryHardwares) {
            return null;
        }
        if (this.outputMode === OutputMode.string) {
            if (this.singleMode) {
                return selectorInventoryHardwares.map((inv) => {
                    return inv.id;
                })[0];
            }
            return selectorInventoryHardwares.map((inv) => {
                return inv.id;
            });
        }
        if (this.outputMode === OutputMode.object) {
            if (this.singleMode) {
                return selectorInventoryHardwares.length ? selectorInventoryHardwares[0] : null;
            }
            return selectorInventoryHardwares;
        }
    }
    emitItemAdded() { }
};
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], SelectorInventory.prototype, "defaultToExternalTool", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], SelectorInventory.prototype, "disableSearch", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], SelectorInventory.prototype, "hideCreateInventory", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], SelectorInventory.prototype, "overrideViewToManualMode", void 0);
__decorate([
    Prop({
        type: Array
    }),
    __metadata("design:type", Array)
], SelectorInventory.prototype, "value", void 0);
__decorate([
    Watch('selectorInventoryHardwares', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], SelectorInventory.prototype, "onSelectorInventoryHardwaresChange", null);
__decorate([
    Watch('overrideViewToManualMode'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], SelectorInventory.prototype, "onOverrideViewToManualModeChange", null);
__decorate([
    Watch('manualInputMode', { immediate: true }),
    Emit('viewModeChanged'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], SelectorInventory.prototype, "onManualInputModeChange", null);
__decorate([
    Watch('value'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], SelectorInventory.prototype, "onValueChange", null);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], SelectorInventory.prototype, "emitInput", null);
__decorate([
    Emit('item-added'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], SelectorInventory.prototype, "emitItemAdded", null);
SelectorInventory = __decorate([
    Component
], SelectorInventory);
export default SelectorInventory;
