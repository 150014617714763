import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
/**
 * Intended use is EITHER by passing a prop object OR utilizing the slots in conjuction with the standalone pill properties
 */
let AppPill = class AppPill extends Vue {
    pill;
    // Use the following props if youre rocking a slot
    leftSideIsIcon;
    leftSideTextColor;
    primaryColor;
    rightSideIsIcon;
    rightSideTextColor;
    secondaryColor;
    get computedBorderColor() {
        return {
            'border-color': `var(--v-${this.computedPrimaryColor}-darken2)`
        };
    }
    get computedLeftSideCssClasses() {
        const classes = [this.computedPrimaryColor, this.computedLeftSideTextColor];
        if (this.computedLeftSideIsIcon) {
            // gives the icon a set size and flexes it
            classes.push('icon');
        }
        if (this.pill?.leftSideIsMonospaced) {
            classes.push('monospace_font');
        }
        return classes;
    }
    get computedLeftSideTextColor() {
        // default to secondary color (white) if you pass nothing
        if (!this.pill && !this.leftSideTextColor) {
            return `${this.computedSecondaryColor}--text`;
        }
        if (!this.pill && this.leftSideTextColor) {
            // defaults to white
            return `${this.leftSideTextColor}${this.leftSideTextColor.endsWith('--text') ? '' : `--text`}`;
        }
        // if you pass a left color and it ends with --text we gtg
        if ((this.pill?.leftSideTextColor && this.pill.leftSideTextColor.endsWith('--text')) ||
            (this.leftSideTextColor && this.leftSideTextColor.endsWith('--text'))) {
            return this.pill?.leftSideTextColor || this.leftSideTextColor;
        }
        // if you pass a left color but forgot --text
        if (this.pill?.leftSideTextColor || this.leftSideTextColor) {
            return `${this.pill?.leftSideTextColor || this.leftSideTextColor}--text text--darken1`;
        }
        // Default to your secondary color darkend a bit
        return `${this.computedSecondaryColor}--text`;
    }
    get computedRightSideTextColor() {
        // default to primary color if you pass nothing
        if (!this.pill && !this.rightSideTextColor) {
            return `${this.computedPrimaryColor}--text`;
        }
        if (!this.pill && this.rightSideTextColor) {
            // defaults to primary
            return `${this.rightSideTextColor}${this.rightSideTextColor.endsWith('--text') ? '' : `--text`}`;
        }
        // if you pass a right side color and it ends with --text we gtg
        if ((this.pill?.rightSideTextColor && this.pill.rightSideTextColor.endsWith('--text')) ||
            (this.rightSideTextColor && this.rightSideTextColor.endsWith('--text'))) {
            return this.pill?.rightSideTextColor || this.rightSideTextColor;
        }
        // if you pass a right side color but forgot --text
        if (this.pill?.rightSideTextColor || this.rightSideTextColor) {
            return `${this.pill?.rightSideTextColor || this.rightSideTextColor}--text`;
        }
        // Default to your primary color darkend a bit
        return `${this.computedPrimaryColor}--text`;
    }
    get computedRightSideCssClasses() {
        const classes = [this.computedSecondaryColor, this.computedRightSideTextColor];
        if (this.computedRightSideIsIcon) {
            // gives the icon a set size and flexes it
            classes.push('icon');
        }
        if (this.pill?.rightSideIsMonospaced) {
            classes.push('monospace_font');
        }
        return classes;
    }
    get computedPrimaryColor() {
        return this.pill?.primaryColor || this.primaryColor;
    }
    get computedSecondaryColor() {
        return this.pill?.secondaryColor || this.secondaryColor;
    }
    get computedIcon() {
        if (!this.computedLeftSideIsIcon && !this.computedRightSideIsIcon) {
            return null;
        }
        if (this.computedLeftSideIsIcon) {
            return this.pill?.leftSide;
        }
        else if (this.computedRightSideIsIcon) {
            return this.pill?.rightSide;
        }
        return null;
    }
    get computedLeftSideIsIcon() {
        return this.pill?.leftSideIsIcon || this.leftSideIsIcon;
    }
    get computedRightSideIsIcon() {
        return this.pill?.rightSideIsIcon || this.rightSideIsIcon;
    }
};
__decorate([
    Prop({
        required: false,
        type: Object
    }),
    __metadata("design:type", Object)
], AppPill.prototype, "pill", void 0);
__decorate([
    Prop({
        type: Boolean,
        required: false,
        default: false
    }),
    __metadata("design:type", Boolean)
], AppPill.prototype, "leftSideIsIcon", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], AppPill.prototype, "leftSideTextColor", void 0);
__decorate([
    Prop({
        type: String,
        required: false,
        default: 'primary'
    }),
    __metadata("design:type", String)
], AppPill.prototype, "primaryColor", void 0);
__decorate([
    Prop({
        type: Boolean,
        required: false,
        default: false
    }),
    __metadata("design:type", Boolean)
], AppPill.prototype, "rightSideIsIcon", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], AppPill.prototype, "rightSideTextColor", void 0);
__decorate([
    Prop({
        type: String,
        required: false,
        default: 'white'
    }),
    __metadata("design:type", String)
], AppPill.prototype, "secondaryColor", void 0);
AppPill = __decorate([
    Component
], AppPill);
export default AppPill;
