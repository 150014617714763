var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.DOCUMENT,
      "hide-title-section": ""
    },
    on: {
      "modal-close": _vm.emitModalClose
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_vm.hasMultipleChangeSetsToComplete ? _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('AppPageDescription', [_vm._v(" Select " + _vm._s(_vm.stepDisplayText) + "s to approve. ")])], 1) : _vm._e(), _vm._l(_vm.approvableChangeSets, function (changeSet) {
          return _c('v-flex', {
            key: changeSet.id,
            staticClass: "mb-2",
            attrs: {
              "xs12": ""
            }
          }, [_c('v-layout', {
            attrs: {
              "row": "",
              "wrap": ""
            }
          }, [_c('v-flex', {
            attrs: {
              "xs12": "",
              "mb-3": ""
            }
          }, [_c('AppHeader', [_vm._v(" Completion of " + _vm._s(_vm.stepDisplayText) + " "), changeSet.workOrderStep ? _c('span', {
            staticClass: "monospace_font"
          }, [_vm._v(_vm._s(changeSet.workOrderStep.stepNumber))]) : _vm._e()])], 1)], 1), _c('AppTable', {
            attrs: {
              "color": _vm.EntityType.CHANGE_SET,
              "headers": _vm.approvalsTableHeaders,
              "items": _vm.getApprovalTableItems(changeSet),
              "rows-per-page-items": [-1],
              "show-count": false,
              "disable-initial-sort": "",
              "hide-header": "",
              "hide-pagination": "",
              "icon": "fa fa-balance-scale"
            }
          }), _vm.userIsMemberOfNextAuthority(changeSet) && _vm.hasMultipleChangeSetsToComplete ? _c('v-layout', {
            attrs: {
              "row": "",
              "wrap": ""
            }
          }, [_c('v-flex', {
            attrs: {
              "xs12": ""
            }
          }, [_c('AppCheckbox', {
            staticClass: "ml-2",
            attrs: {
              "value": true,
              "label": _vm.checkboxLabel(changeSet)
            },
            on: {
              "input": function ($event) {
                return _vm.onApprovalToggle(changeSet, $event);
              }
            }
          })], 1)], 1) : _vm._e()], 1);
        }), _vm.shouldShowQuanlityControlStamp ? _c('v-flex', {
          attrs: {
            "xs2": ""
          }
        }, [_c('c-text', {
          attrs: {
            "c-value": _vm.formData.qualityControlStamp,
            "required": _vm.shouldRequireQualityControlStamp,
            "label": "QA stamp"
          },
          on: {
            "c-input": function ($event) {
              _vm.formData.qualityControlStamp = $event.detail;
            }
          }
        })], 1) : _vm._e(), _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('AppMarkdown', {
          attrs: {
            "read-only": false,
            "label": "Comment",
            "required": _vm.shouldRequireComment
          },
          model: {
            value: _vm.formData.comment,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "comment", $$v);
            },
            expression: "formData.comment"
          }
        })], 1), _vm.isRca ? _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('AppDate', {
          attrs: {
            "color": _vm.EntityType.DOCUMENT,
            "label": "Closure",
            "required": ""
          },
          model: {
            value: _vm.formData.authorizedDate,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "authorizedDate", $$v);
            },
            expression: "formData.authorizedDate"
          }
        })], 1) : _vm._e()], 2)];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonGeneric', {
          attrs: {
            "disabled": _vm.shouldDisableSubmitButton,
            "color": "success",
            "is-saving": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfCompleteWorkOrderStep
          }
        }, [_c('c-icon-fa', {
          staticClass: "pr-2",
          attrs: {
            "slot": "icon",
            "icon": "fad fa-thumbs-up",
            "icon-color": "white"
          },
          slot: "icon"
        }), _c('span', [_vm._v(_vm._s(_vm.saveButtonText))])], 1), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        }, [_vm._v("Cancel")])];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };