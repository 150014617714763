var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.selectedEntity && _vm.computedSelectedEntity ? _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs4": ""
    }
  }, [_c('c-text', {
    attrs: {
      "color": _vm.EntityType.VEHICLE,
      "autofocus": "",
      "label": "Name",
      "required": "",
      "c-value": _vm.formData.name
    },
    on: {
      "c-input": function ($event) {
        _vm.formData.name = $event.detail;
      }
    }
  })], 1)], 1)], 1), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('ButtonSave', {
    attrs: {
      "disabled": !_vm.isFormValid
    },
    on: {
      "click": _vm.saveVehicle
    }
  })], 1)], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };