var _a;
import { __decorate, __metadata } from "tslib";
import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, TimeScale, Title, Tooltip } from 'chart.js';
import 'chartjs-adapter-date-fns/dist/chartjs-adapter-date-fns.esm.js';
import { MatrixController, MatrixElement } from 'chartjs-chart-matrix';
import annotationPlugin from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { addDays } from 'date-fns';
import { Line } from 'vue-chartjs';
import { mixins } from 'vue-class-component';
import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseView from '~/nasa_ui/base/BaseView';
import { RISK_COLOR_HIGH, RISK_COLOR_LOW, RISK_COLOR_MEDIUM } from '~/nasa_ui/constants/colors';
import { getRiskColor, sortObjectBy } from '~/nasa_ui/utils';
import { sortObjectNumericallyBy } from '~/nasa_ui/utils/helpers/sortObjectNumericallyBy';
import { RiskResponseWithRefsDisplay } from '~/nasa_ui/utils/helpers/transforms/risks';
Vue.component('LineChart', Line);
const trendsBackgroundColorPlugin = {
    id: 'riskMatrixTrendsChartBackground',
    beforeDraw: (chart, args, options) => {
        const ctx = chart.ctx;
        const yAxis = chart.scales['y'];
        const lowRiskColor = '#99e9b3';
        const moderateRiskColor = '#ffef99';
        const highRiskColor = '#efb2b2';
        const colors = [lowRiskColor, moderateRiskColor, highRiskColor];
        const ranges = [-2.5, 5, 15, 28]; // range is a bit larger than the y axis total to cover the whole chart
        for (let i = 0; i < ranges.length - 1; i++) {
            const start = ranges[i];
            const end = ranges[i + 1];
            const color = colors[i];
            const startY = yAxis.getPixelForValue(start);
            const endY = yAxis.getPixelForValue(end);
            ctx.save();
            ctx.fillStyle = color;
            ctx.fillRect(0, startY, chart.width, endY - startY);
            ctx.restore();
        }
    }
};
ChartJS.register(annotationPlugin, BarElement, ChartDataLabels, MatrixController, MatrixElement, Title, Tooltip, Legend, LineElement, PointElement, ArcElement, BarElement, CategoryScale, LinearScale, TimeScale);
let ChartRiskMitigations = class ChartRiskMitigations extends mixins(BaseView) {
    ChartJS = ChartJS;
    chartRef = 'trend';
    trendsBackgroundColorPlugin = trendsBackgroundColorPlugin;
    options = null;
    id;
    risk;
    chartOptions;
    get chartData() {
        if (!this.risk) {
            return {};
        }
        const initial = {
            sequence: 'I',
            projectedScore: this.risk.initialScore,
            projectedCloseDate: this.risk.createdDateTime,
            projectedConsequence: this.risk.initialConsequence,
            projectedLikelihood: this.risk.initialLikelihood,
            _initialProjectedCloseDate: this.$dateDisplay(this.risk.createdDateTime),
            _projectedRiskScore: this.risk?.initialScore,
            description: 'Initial'
        };
        let mitigations = this.risk._riskMitigations.sort(sortObjectNumericallyBy('sequence')) || [];
        // update projectedCloseDate with closed date for closed steps because we use this for the xAxisKey
        mitigations = mitigations.map((mitigation) => {
            mitigation['projectedCloseDate'] = mitigation.isCompleted ? mitigation.closedDate : mitigation.projectedCloseDate;
            mitigation['_initialProjectedCloseDate'] = this.$dateDisplay(mitigation.projectedCloseDate);
            return mitigation;
        });
        return {
            datasets: [
                {
                    data: [initial, ...mitigations],
                    fill: false,
                    pointBorderColor: 'rgba(0, 0, 0, 0)',
                    pointBackgroundColor: 'rgba(0, 0, 0, 0)',
                    pointRadius: 10,
                    pointHoverRadius: 10
                }
            ]
        };
    }
    get startOfToday() {
        const d = new Date();
        return new Date(d.getFullYear(), d.getMonth(), d.getDay(), 0, 0, 0, 0);
    }
    setOptions() {
        const today = new Date();
        // use the earliest mitigation step date or the risk creation date, whichever is earlier
        const earliestStepDate = this.chartData?.datasets?.[0].data.sort(sortObjectBy('_initialProjectedCloseDate'))[0]?.projectedCloseDate ||
            this.risk.createdDateTime;
        this.options = {
            aspectRatio: 5,
            responsive: true,
            type: 'line',
            stepped: true,
            parsing: {
                xAxisKey: 'projectedCloseDate',
                yAxisKey: 'projectedScore'
            },
            plugins: {
                annotation: {
                    annotations: {
                        todayLine: {
                            borderColor: '#7B1FA2',
                            borderWidth: 2,
                            type: 'line',
                            xMin: today,
                            xMax: today,
                            yMin: 0
                        },
                        todayLabel: {
                            type: 'label',
                            xMin: today,
                            xMax: today,
                            yMin: -1,
                            yMax: -1,
                            backgroundColor: '#7B1FA2',
                            borderRadius: 12,
                            content: 'T O D A Y',
                            color: 'white',
                            textStrokeWidth: 1,
                            textStrokeColor: 'white',
                            font: {
                                size: 10
                            },
                            tooltip: {
                                content: this.$dateDisplay(today)
                            }
                        }
                    }
                },
                riskMatrixTrendsChartBackground: true,
                datalabels: {
                    backgroundColor: (context) => {
                        const mitigation = context.dataset.data[context.dataIndex];
                        let color = getRiskColor(mitigation.projectedLikelihood, mitigation.projectedConsequence) || 'grey';
                        color =
                            color === 'green'
                                ? RISK_COLOR_LOW
                                : color === 'yellow'
                                    ? RISK_COLOR_MEDIUM
                                    : color === 'red'
                                        ? RISK_COLOR_HIGH
                                        : color;
                        return color;
                    },
                    borderRadius: 5,
                    color: 'white',
                    display: true,
                    font: {
                        size: 20,
                        family: 'monospace'
                    },
                    labels: {
                        title: {
                            font: {
                                weight: 'bold'
                            },
                            formatter: function (mitigation, context) {
                                return mitigation.sequence;
                            }
                        },
                        value: {
                            align: 'end',
                            backgroundColor: function (chart, context) {
                                return 'transparent';
                            },
                            color: '#1976D2',
                            formatter: function (mitigation, context) {
                                return mitigation.isCompleted || mitigation.sequence === 'I' ? '✓' : '';
                            },
                            font: {
                                size: '36px'
                            },
                            offset: -4,
                            padding: {
                                left: '10px'
                            },
                            textAlign: 'center'
                        }
                    }
                },
                legend: {
                    display: false
                },
                tooltip: {
                    displayColors: false,
                    bodyFont: {
                        size: 12
                    },
                    callbacks: {
                        title: function (context) {
                            const ctx = context[0];
                            const index = context[0]?.dataIndex;
                            const mitigation = index > -1 && ctx?.dataset?.data ? ctx?.dataset?.data[index] : null;
                            return `${mitigation?._initialProjectedCloseDate}`;
                        },
                        label: (context) => {
                            // this callback context is different from the title/footer callbacks
                            const index = context?.dataIndex;
                            const mitigation = index > -1 && context?.dataset?.data ? context?.dataset?.data[index] : null;
                            if (mitigation.sequence === 'I') {
                                return `Initial: ${mitigation.projectedScore}`;
                            }
                            if (mitigation.sequence === 'C') {
                                return `Current: ${mitigation.projectedScore}`;
                            }
                            return mitigation?._projectedRiskScore ? `Proj: ${mitigation?._projectedRiskScore}` : '';
                        },
                        footer: (context) => {
                            const mitigation = context[0].raw;
                            return mitigation.isCompleted || mitigation.sequence === 'I' ? 'COMPLETED' : '';
                        }
                    },
                    caretPadding: 20
                }
            },
            scales: {
                y: {
                    min: 0,
                    max: 25,
                    reverse: false,
                    type: 'linear',
                    labels: ['Low', 'Moderate', 'High'],
                    offset: true,
                    ticks: {
                        display: true,
                        font: {
                            size: 12,
                            family: 'monospace',
                            weight: 'bold'
                        }
                    },
                    grid: {
                        display: true,
                        drawBorder: true
                    }
                },
                x: {
                    type: 'time',
                    min: new Date(earliestStepDate).getTime(),
                    max: new Date(new Date(new Date(addDays(new Date(this.risk._lastMitigationStepProjectedCloseDate), 90)))).getTime(),
                    position: 'bottom',
                    offset: true,
                    time: {
                        unit: 'month',
                        round: 'day',
                        isoWeekday: 1,
                        displayFormats: {
                            week: 'MMM'
                        }
                    },
                    ticks: {
                        maxRotation: 0,
                        autoSkip: true,
                        font: {
                            size: 14,
                            family: 'monospace',
                            weight: 'bold'
                        }
                    },
                    grid: {
                        display: false,
                        drawBorder: false,
                        tickLength: 0
                    }
                }
            }
        };
    }
    created() {
        this.ChartJS.register(trendsBackgroundColorPlugin);
        this.setOptions();
    }
};
__decorate([
    Prop({
        default: 'trendChart',
        type: String
    }),
    __metadata("design:type", String)
], ChartRiskMitigations.prototype, "id", void 0);
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof RiskResponseWithRefsDisplay !== "undefined" && RiskResponseWithRefsDisplay) === "function" ? _a : Object)
], ChartRiskMitigations.prototype, "risk", void 0);
__decorate([
    Prop({
        default: () => {
            return [];
        }
    }),
    __metadata("design:type", Object)
], ChartRiskMitigations.prototype, "chartOptions", void 0);
ChartRiskMitigations = __decorate([
    Component
], ChartRiskMitigations);
export default ChartRiskMitigations;
