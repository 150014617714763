import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let ExportControlledOrProprietaryMixin = class ExportControlledOrProprietaryMixin extends Vue {
    currentUserHasAccess;
    isExportControlled;
    isProprietary;
    get accessControlAlertText() {
        let reason = this.isExportControlled ? '<strong>Export Controlled</strong>' : '';
        reason += this.isProprietary ? (reason ? ' and <strong>Proprietary</strong>' : '<strong>Proprietary</strong>') : '';
        return reason ? `You do not have full access to this Document because it is ${reason}.` : null;
    }
    get isExportControlledOrProprietary() {
        return this.isExportControlled || this.isProprietary;
    }
};
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], ExportControlledOrProprietaryMixin.prototype, "currentUserHasAccess", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], ExportControlledOrProprietaryMixin.prototype, "isExportControlled", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], ExportControlledOrProprietaryMixin.prototype, "isProprietary", void 0);
ExportControlledOrProprietaryMixin = __decorate([
    Component
], ExportControlledOrProprietaryMixin);
export default ExportControlledOrProprietaryMixin;
