import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { LogType } from '~/nasa_ui/types/enums/log';
let TabAdminStats = class TabAdminStats extends Vue {
    LogType = LogType;
};
TabAdminStats = __decorate([
    Component
], TabAdminStats);
export default TabAdminStats;
