var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "app_risk_score_display"
  }, [!_vm.hasLock ? _c('div', {
    staticClass: "app_risk_score ma-1",
    class: _vm.criticalityToDisplayBasedOnActiveScoreIndex,
    style: `height: ${_vm.size}; width: ${_vm.size}`,
    attrs: {
      "tooltip-position": _vm.tooltipPosition,
      "tooltip": `Click to view ${_vm.nextScoreType} Risk score.`
    },
    on: {
      "click": _vm.incrementActiveScoreIndex,
      "dblclick": _vm.viewRiskDetails
    }
  }, [_c('span', {
    staticClass: "score monospace_font"
  }, [_vm._v(_vm._s(_vm.riskScoreToDisplayBasedOnActiveScoreIndex))]), _c('small', {
    staticClass: "score_type monospace_font"
  }, [_vm._v(_vm._s(_vm.activeScoreType))])]) : _vm.lockToInitial ? _c('div', {
    staticClass: "app_risk_score locked ma-1",
    class: _vm.risk._initialCriticality,
    style: `height: ${_vm.size}; width: ${_vm.size}`
  }, [_c('span', {
    staticClass: "score monospace_font"
  }, [_vm._v(_vm._s(_vm.initialRiskScore))]), _c('small', {
    staticClass: "score_type monospace_font"
  }, [_vm._v("initial")])]) : _vm.lockToCurrent ? _c('div', {
    staticClass: "app_risk_score locked ma-1",
    class: _vm.risk._currentCriticality,
    style: `height: ${_vm.size}; width: ${_vm.size}`
  }, [_c('span', {
    staticClass: "score monospace_font"
  }, [_vm._v(_vm._s(_vm.currentRiskScore))]), _c('small', {
    staticClass: "score_type monospace_font"
  }, [_vm._v("current")])]) : _vm.lockToProjected ? _c('div', {
    staticClass: "app_risk_score locked ma-1",
    class: _vm.risk._projectedCriticality,
    style: `height: ${_vm.size}; width: ${_vm.size}`
  }, [_c('span', {
    staticClass: "score monospace_font"
  }, [_vm._v(_vm._s(_vm.risk._projectedRiskScore))]), _c('small', {
    staticClass: "score_type monospace_font"
  }, [_vm._v("proj")])]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };