import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { DocumentType } from '~/db_types';
import { UserPermission } from '~/nasa_ui/types/enums/user';
let GenericDocumentMixin = class GenericDocumentMixin extends Vue {
    getSourceDoc(genericDocument) {
        if (!genericDocument) {
            return null;
        }
        return (genericDocument.cosmicDocumentDr ??
            genericDocument.cosmicDocumentEzt ??
            genericDocument.cosmicDocumentFracas ??
            genericDocument.cosmicDocumentRca ??
            genericDocument.cosmicDocumentTps ??
            genericDocument.cosmicDocumentWorkflow);
    }
    getCorrespondingAdminGroup(docType) {
        switch (docType) {
            case DocumentType.C_DISCREPANCY_REPORT:
                return UserPermission.C_DOCS_DR_ADMIN;
            case DocumentType.C_EASY_TRANSFER:
                return UserPermission.C_DOCS_EZT_ADMIN;
            case DocumentType.C_FAILURE_REPORTING_ANALYSIS_CORRECTIVE_ACTION_SYSTEM:
                return UserPermission.C_DOCS_FRACAS_ADMIN;
            case DocumentType.C_RCA:
                return UserPermission.C_DOCS_RCA_ADMIN;
            case DocumentType.C_TPS:
                return UserPermission.C_DOCS_TPS_ADMIN;
            case DocumentType.C_WORKFLOW:
                return UserPermission.C_DOCS_WORKFLOWS_ADMIN;
            default:
                throw Error(`No matching User Permission for ${document}`);
        }
    }
};
GenericDocumentMixin = __decorate([
    Component
], GenericDocumentMixin);
export default GenericDocumentMixin;
