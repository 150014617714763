import { __decorate, __metadata } from "tslib";
import chunk from 'lodash/chunk';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { DocumentType } from '~/db_types';
import { WorkOrderStepRepoMixin } from '~/nasa_ui/DAL/document/WorkOrderStep';
import CurrentUserMixin from '~/nasa_ui/mixins/CurrentUserMixin';
import GenericDocumentMixin from '~/nasa_ui/mixins/GenericDocumentMixin';
import LoaderMixin from '~/nasa_ui/mixins/LoaderMixin';
import ReadOnlyMixin from '~/nasa_ui/mixins/ReadOnlyMixin';
import { DocumentStatus } from '~/nasa_ui/utils/DocumentStatus';
import { StepsStatus } from '~/nasa_ui/utils/StepsStatus';
let TabWorkOrderTasksOverview = class TabWorkOrderTasksOverview extends Mixins(GenericDocumentMixin, LoaderMixin, ReadOnlyMixin, CurrentUserMixin, WorkOrderStepRepoMixin) {
    changeSetIdsToApprove = [];
    document = null;
    showCompleteStepModal = false;
    showNewStepModal = false;
    stepIndex = 0;
    steps = [];
    id;
    get stepStatus() {
        if (!this.document?.workOrderSteps?.nodes) {
            return null;
        }
        return new StepsStatus(this.document.workOrderSteps.nodes, this.sourceDoc);
    }
    get documentStatus() {
        if (!this.sourceDoc) {
            return null;
        }
        return new DocumentStatus(this.sourceDoc, this.getCorrespondingAdminGroup(this.document.subType));
    }
    get sourceDoc() {
        return this.getSourceDoc(this.document);
    }
    get allStepsComplete() {
        return Boolean(this.stepStatus?.allStepsComplete);
    }
    get currentUserCanApproveAnySteps() {
        return Boolean(this.stepStatus?.currentUserCanApproveAnySteps);
    }
    get isInitialized() {
        return Boolean(this.documentStatus?.isInitialized);
    }
    get isRca() {
        return this.document?.subType === DocumentType.C_RCA;
    }
    get shouldDisableCompleteAllButton() {
        return !this.currentUserCanApproveAnySteps || !this.isInitialized;
    }
    get taskText() {
        return this.isRca ? 'CAP task' : 'Task';
    }
    onProcedureUpdated(updates) {
        // We could just refresh the data, but for the sake of speed
        // I'm just updating the data in-place on a successful update
        const { id, action, assigneeContact, assigneeContactId, warning, caution, notes, title, overrideStartDate, projectedDate, riskScoreAtCompletion } = updates;
        if (!id) {
            throw Error('No step ID was emitted from the procedure-updated event');
        }
        const step = this.steps?.find((s) => s?.id === id);
        step.action = action;
        step.assigneeContact = assigneeContact;
        step.assigneeContactId = assigneeContactId;
        step.caution = caution;
        step.projectedDate = projectedDate;
        step.notes = notes;
        step.overrideStartDate = overrideStartDate;
        step.riskScoreAtCompletion = riskScoreAtCompletion;
        step.title = title;
        step.warning = warning;
    }
    onClickOfCompleteAllSteps() {
        this.showCompleteStepModal = true;
        this.changeSetIdsToApprove = this.steps.map((step) => step.changeSet.id);
    }
    onCompleteAllStepsSubmit() {
        this.$message('reload_mini');
        return this.fetchSteps();
    }
    async fetchSteps() {
        this.closeModals();
        this.isLoading = true;
        const data = await this.getWorkOrderStepsOverview({ id: this.id });
        this.document = data?.documentById;
        this.setSteps(data?.documentById?.workOrderSteps.nodes);
        this.isLoading = false;
    }
    closeModals() {
        this.showCompleteStepModal = false;
        this.showNewStepModal = false;
    }
    // Maybe we want to do this differently in the future BUT...
    // While fetching the step data isn't taking very long, the render in the v-for is taking a while
    // This is a little hack to chunk the steps into groups of 5 and render them progressively
    setSteps(steps) {
        const [firstChunk, ...rest] = chunk(steps, 5);
        this.steps = firstChunk ?? [];
        rest.forEach((chunk, i) => {
            setTimeout(() => {
                this.steps = [...this.steps, ...chunk];
            }, i * 500);
        });
    }
};
__decorate([
    Prop({
        required: true,
        type: String
    }),
    __metadata("design:type", Object)
], TabWorkOrderTasksOverview.prototype, "id", void 0);
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], TabWorkOrderTasksOverview.prototype, "fetchSteps", null);
TabWorkOrderTasksOverview = __decorate([
    Component
], TabWorkOrderTasksOverview);
export default TabWorkOrderTasksOverview;
