var _a, _b, _c, _d, _e, _f;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { CommentType, Maybe } from '~/db_types';
let AppCreateComment = class AppCreateComment extends Vue {
    mini;
    activityId;
    changeSetId;
    changeSetAuthorityId;
    commentTypeOverride;
    createCommentTypes;
    deliveryMatrixItemId;
    documentId;
    entityType;
    eventId;
    hardwareListId;
    itemDrawingNodeId;
    itemMasterNodeId;
    itemInstanceNodeId;
    organizationCode;
    physicalInventoryAuditNodeId;
    riskId;
    readOnly;
    emitReloadComments() { }
    showModalCommentCreate = false;
};
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], AppCreateComment.prototype, "mini", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], AppCreateComment.prototype, "activityId", void 0);
__decorate([
    Prop({
        type: Number
    }),
    __metadata("design:type", Number)
], AppCreateComment.prototype, "changeSetId", void 0);
__decorate([
    Prop({
        type: Number
    }),
    __metadata("design:type", Object)
], AppCreateComment.prototype, "changeSetAuthorityId", void 0);
__decorate([
    Prop({
        type: String,
        default: null
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], AppCreateComment.prototype, "commentTypeOverride", void 0);
__decorate([
    Prop({
        default() {
            return Object.values(CommentType);
        },
        type: Array
    }),
    __metadata("design:type", typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object)
], AppCreateComment.prototype, "createCommentTypes", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_c = typeof Maybe !== "undefined" && Maybe) === "function" ? _c : Object)
], AppCreateComment.prototype, "deliveryMatrixItemId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_d = typeof Maybe !== "undefined" && Maybe) === "function" ? _d : Object)
], AppCreateComment.prototype, "documentId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_e = typeof Maybe !== "undefined" && Maybe) === "function" ? _e : Object)
], AppCreateComment.prototype, "entityType", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_f = typeof Maybe !== "undefined" && Maybe) === "function" ? _f : Object)
], AppCreateComment.prototype, "eventId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], AppCreateComment.prototype, "hardwareListId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], AppCreateComment.prototype, "itemDrawingNodeId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], AppCreateComment.prototype, "itemMasterNodeId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], AppCreateComment.prototype, "itemInstanceNodeId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], AppCreateComment.prototype, "organizationCode", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], AppCreateComment.prototype, "physicalInventoryAuditNodeId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], AppCreateComment.prototype, "riskId", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true
    }),
    __metadata("design:type", Boolean)
], AppCreateComment.prototype, "readOnly", void 0);
__decorate([
    Emit('reloadComments'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppCreateComment.prototype, "emitReloadComments", null);
AppCreateComment = __decorate([
    Component
], AppCreateComment);
export default AppCreateComment;
