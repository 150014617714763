var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.COMMENT,
      "hide-title-section": ""
    },
    on: {
      "modal-close": _vm.emitModalClose
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('AppFileAttachmentForm', {
          attrs: {
            "read-only": _vm.isReadOnly
          },
          model: {
            value: _vm.formData,
            callback: function ($$v) {
              _vm.formData = $$v;
            },
            expression: "formData"
          }
        })];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonCreate', {
          attrs: {
            "disabled": _vm.shouldDisableCreateButton,
            "is-saving": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfCreate
          }
        }, [_vm._v(" Upload file ")]), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        })];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };