import maxBy from 'lodash/maxBy';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { dateDisplay } from '~/nasa_ui/utils/dates/dateDisplay';
import { booleanIconDisplay } from '~/nasa_ui/utils/helpers/displays';
import { STANDARD_HARDWARE_COLUMNS } from '~/nasa_ui/utils/helpers/tableDefinitions';
import { transformHardwareItems } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
export const hardwareListTableHeaders = [
    ...STANDARD_HARDWARE_COLUMNS,
    {
        text: 'External?',
        value: '_isExternal',
        noEscape: true,
        align: 'center'
    },
    {
        text: 'Shelf Life Exp.',
        value: '_shelfLifeExp'
    }
];
export const getNextSequenceNumber = (currentAssemblyList, sequenceAddOn = 1) => {
    return (maxBy(currentAssemblyList, (a) => a.sequence)?.sequence ?? 0) + sequenceAddOn;
};
export const transformCdocsHardwareListItems = (items) => {
    return transformHardwareItems(items).map((item) => {
        const tableItem = {
            ...item
        };
        if (tableItem.isExternal) {
            tableItem._itemNumber = tableItem._itemNumber = tableItem.externalDrawingNumber ?? DEFAULT_DASH;
            tableItem._serialNumber = tableItem.externalSerialNumber ?? DEFAULT_DASH;
            tableItem._lotNumber = tableItem.externalLotNumber ?? DEFAULT_DASH;
            tableItem._description = tableItem.externalDescription ?? DEFAULT_DASH;
        }
        tableItem._shelfLifeExp = dateDisplay(tableItem.inventory?.itemInstance?.limitedLifeRemaining?.shelf?.valueDueDate || tableItem.externalShelfLifeDate);
        tableItem._isExternal = booleanIconDisplay(tableItem.isExternal);
        return tableItem;
    });
};
