var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('AppExpansionPanel', {
    staticClass: "expansion_override",
    attrs: {
      "is-open": false,
      "color": _vm.EntityType.INVENTORY
    }
  }, [_c('div', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('c-icon-fa', {
    staticClass: "pr-2",
    attrs: {
      "icon": _vm.$icons.inventories
    }
  }), _c('span', {
    staticClass: "expansion_override_title"
  }, [_vm._v("Associated Hardware ("), _c('small', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.hardwareTableItems.length))]), _vm._v(")")])], 1), _c('AppFormWrapper', {
    attrs: {
      "color": _vm.EntityType.INVENTORY,
      "top": "0",
      "right": "1",
      "left": "1",
      "bottom": "1"
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('AppTable', {
    attrs: {
      "items": _vm.hardwareTableItems,
      "headers": _vm.tableHeaders
    }
  })], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };