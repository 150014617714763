var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { CommentType, Maybe } from '~/db_types';
import { BASE_ICON_COL, COMMENT_COL, SUB_TYPE_COL, UPDATED_BY_COL, UPDATED_DATE_TIME_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import { CommentsRepoMixin } from '~/nasa_ui/DAL/comment';
import CurrentUserMixin from '~/nasa_ui/mixins/CurrentUserMixin';
import ReadOnlyMixin from '~/nasa_ui/mixins/ReadOnlyMixin';
import { AlertType, EntityType, UserPermission } from '~/nasa_ui/types';
import { CommentTypeDisplay, EditableCommentTypes } from '~/nasa_ui/types/enums/comment';
import { sortObjectBy } from '~/nasa_ui/utils';
import { composeTableHeader } from '~/nasa_ui/utils/helpers/composeTableHeader';
import { CommentTableHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
import { transformComments } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let AppComments = class AppComments extends Mixins(CurrentUserMixin, ReadOnlyMixin, CommentsRepoMixin) {
    commentsActivity = [];
    commentsDeliveryMatrixItem = [];
    commentsDocument = [];
    commentsEvent = [];
    commentsHardwareList = [];
    commentsItemDrawing = [];
    commentsItemInstance = [];
    commentsItemMaster = [];
    commentsOrganization = [];
    commentsPia = [];
    commentsRisk = [];
    CommentTableHeaders = CommentTableHeaders;
    CommentType = CommentType;
    CommentTypeDisplay = CommentTypeDisplay;
    commentTypesToDisplay = [];
    documentCommentsToDisplay = [];
    isFetching = false;
    isRemoving = false;
    selectedComment = null;
    showDeleteConfirmation = false;
    showEditModal = false;
    createCommentTypes;
    editCommentTypes;
    ignoreCommentTypes;
    activityId;
    deliveryMatrixItemId;
    documentId;
    eventId;
    hardwareListId;
    itemDrawingNodeId;
    itemMasterNodeId;
    itemInstanceNodeId;
    organizationCode;
    physicalInventoryAuditNodeId;
    riskId;
    riskMitigationId;
    tableHeaderOverrides;
    get allComments() {
        return [
            ...this.commentsActivity,
            ...this.commentsDeliveryMatrixItem,
            ...this.commentsDocument,
            ...this.commentsEvent,
            ...this.commentsHardwareList,
            ...this.commentsItemDrawing,
            ...this.commentsItemInstance,
            ...this.commentsItemMaster,
            ...this.commentsOrganization,
            ...this.commentsPia,
            ...this.commentsRisk
        ].sort(sortObjectBy('updatedDateTime', true));
    }
    get commentTypeEnum() {
        return Object.values(CommentType).filter((commentType) => this.createCommentTypes?.includes(commentType));
    }
    get computedComments() {
        if (!this.allComments) {
            return [];
        }
        const filteredComments = this.allComments.filter((comment) => {
            return this.commentTypesToDisplay?.includes(comment.subType);
        });
        return transformComments(filteredComments).sort(sortObjectBy('_updatedDateTicks', true));
    }
    get computedTableHeaders() {
        return (this.tableHeaderOverrides || [
            composeTableHeader(UPDATED_BY_COL, { width: '5%' }),
            COMMENT_COL,
            composeTableHeader(SUB_TYPE_COL, {
                text: 'Comment<br/>type',
                value: '_subType'
            }),
            composeTableHeader(BASE_ICON_COL, {
                noEscape: true,
                text: 'System<br/>type',
                value: '_entityType',
                width: '15%'
            }),
            composeTableHeader(UPDATED_DATE_TIME_COL, {
                width: '10%'
            })
        ]);
    }
    get entityTypesAssociatedWithComment() {
        if (!this.selectedComment) {
            return [];
        }
        const entityTypes = [];
        // not trying to be fancy here
        if (this.selectedComment.activityId) {
            entityTypes.push(EntityType.ACTIVITY);
        }
        if (this.selectedComment.changeSetAuthorityId || this.selectedComment.changeSetId) {
            entityTypes.push(EntityType.CHANGE_SET);
        }
        if (this.selectedComment.deliveryMatrixItemId) {
            entityTypes.push(EntityType.DELIVERY_MATRIX_ITEM);
        }
        if (this.selectedComment.documentId) {
            entityTypes.push(EntityType.DOCUMENT);
        }
        if (this.selectedComment.eventId) {
            entityTypes.push(EntityType.EVENT);
        }
        if (this.selectedComment.hardwareListId) {
            entityTypes.push(EntityType.HARDWARE_LIST);
        }
        if (this.selectedComment.itemInstanceId) {
            entityTypes.push(EntityType.ITEM_INSTANCE);
        }
        if (this.selectedComment.drawingNumber) {
            entityTypes.push(EntityType.ITEM_DRAWING);
        }
        if (this.selectedComment.asBuiltNumber && this.selectedComment.drawingNumber) {
            entityTypes.push(EntityType.ITEM_MASTER);
        }
        if (this.selectedComment.organizationCode) {
            entityTypes.push(EntityType.ORGANIZATION);
        }
        if (this.selectedComment.physicalInventoryAuditId || this.selectedComment.physicalInventoryAuditItemId) {
            entityTypes.push(EntityType.PHYSICAL_INVENTORY_AUDIT);
        }
        if (this.selectedComment.transactionId) {
            entityTypes.push(EntityType.TRANSACTION);
        }
        if (this.selectedComment.riskId) {
            entityTypes.push(EntityType.RISK);
        }
        if (this.selectedComment.riskMitigationId) {
            entityTypes.push(EntityType.RISK);
        }
        return entityTypes;
    }
    get existingCommentTypes() {
        return this.allComments?.reduce((output, comment) => {
            if (!(output.indexOf(comment?.subType) > -1)) {
                output.push(comment?.subType);
            }
            return output;
        }, []);
    }
    get existingValuesToBeRemoved() {
        return Object.values(CommentType).filter((commentType) => {
            return !this.existingCommentTypes.includes(commentType);
        });
    }
    get getPermissionForHardware() {
        return [...this.entityTypesAssociatedWithComment]
            .map((entityType) => {
            const perm = `${entityType}_ADMIN`;
            if (Object.hasOwn(UserPermission, perm)) {
                return perm;
            }
        })
            .filter((perm) => perm);
    }
    get rowsPerPageItems() {
        return [
            100,
            {
                text: '$vuetify.dataIterator.rowsPerPageAll',
                value: -1
            }
        ];
    }
    get selectedCommentId() {
        return this.selectedComment?.id;
    }
    get isUserOwnerOfComment() {
        if (this.selectedComment && this.currentUser) {
            return this.selectedComment.createdByUserId === this.currentUser.id;
        }
    }
    get userCanRemoveComment() {
        if (!this.selectedComment?.comment) {
            return true; // any user can remove empty comments
        }
        if (!this.getPermissionForHardware) {
            return false;
        }
        const hasPermission = (userPermission) => this.currentUserHasPermission(userPermission);
        return this.getPermissionForHardware.some(hasPermission) || this.isUserOwnerOfComment || false;
    }
    created() {
        this.fetchComments();
    }
    onClickOfDelete(row) {
        if (this.readOnly) {
            return;
        }
        if (row.subType === CommentType.SYSTEM) {
            this.$notification.add({
                text: 'Cannot delete SYSTEM comments.',
                type: AlertType.WARNING
            });
            return;
        }
        this.selectedComment = row;
        this.showDeleteConfirmation = true;
    }
    onClickOfEdit(row) {
        if (this.readOnly) {
            return;
        }
        if (row.subType === CommentType.SYSTEM) {
            this.$notification.add({
                text: 'Cannot edit SYSTEM comments.',
                type: AlertType.WARNING
            });
            return;
        }
        this.selectedComment = row;
        this.showEditModal = true;
    }
    onDeleteCancelled() {
        this.selectedComment = null;
        this.showDeleteConfirmation = false;
    }
    // Delete the selected comment
    async onDeleteConfirmed() {
        if (!this.selectedComment) {
            return;
        }
        try {
            this.isRemoving = true;
            const resp = await this.$http.delete(`/comments/${this.selectedComment.id}`);
            this.$notification.add({
                text: 'Deleted.',
                type: AlertType.SUCCESS
            });
            this.isRemoving = false;
            this.reloadComments();
        }
        catch (err) {
            this.isRemoving = false;
            this.reloadComments();
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async fetchCommentsForItemDrawing() {
        if (!this.itemDrawingNodeId) {
            return;
        }
        const data = await this.getItemDrawingComments({
            itemDrawingNodeId: this.itemDrawingNodeId
        });
        if (data?.itemDrawing?.comments.nodes) {
            this.commentsItemDrawing = data.itemDrawing.comments.nodes;
        }
        else {
            this.commentsItemDrawing = [];
        }
    }
    async fetchCommentsForItemMaster() {
        if (!this.itemMasterNodeId) {
            return;
        }
        const data = await this.getItemMasterComments({
            itemMasterNodeId: this.itemMasterNodeId
        });
        if (data?.itemMaster?.comments.nodes) {
            this.commentsItemMaster = data.itemMaster.comments.nodes;
        }
        else {
            this.commentsItemMaster = [];
        }
    }
    async fetchCommentsForItemInstance() {
        if (!this.itemInstanceNodeId) {
            return;
        }
        const data = await this.getItemInstanceComments({
            itemInstanceNodeId: this.itemInstanceNodeId
        });
        if (data?.itemInstance?.comments.nodes) {
            this.commentsItemInstance = data.itemInstance.comments.nodes;
        }
        else {
            this.commentsItemInstance = [];
        }
    }
    async fetchCommentsForDocumentById(documentId) {
        const data = await this.getDocumentCommentsById({
            documentId
        });
        this.commentsDocument = data?.documentById ? this.buildDocumentComments(data.documentById) : [];
    }
    async fetchCommentsForDocument() {
        if (this.documentId) {
            return this.fetchCommentsForDocumentById(this.documentId);
        }
    }
    // Uses API2
    async fetchCommentsForHardwareList() {
        if (!this.hardwareListId) {
            return;
        }
        try {
            const resp = await this.$http.get(`/comments`, {
                params: {
                    hardwareListId: this.hardwareListId,
                    take: -1
                }
            });
            if (resp.data.results) {
                this.commentsHardwareList = resp.data.results;
            }
            else {
                this.commentsHardwareList = [];
            }
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    // Uses API2
    async fetchCommentsForEvent() {
        if (!this.eventId) {
            return;
        }
        try {
            const resp = await this.$http.get(`/comments`, {
                params: {
                    cosmicEventId: this.eventId,
                    take: -1
                }
            });
            if (resp.data.results) {
                this.commentsEvent = resp.data.results;
            }
            else {
                this.commentsEvent = [];
            }
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async fetchCommentsForActivity() {
        if (!this.activityId) {
            return;
        }
        const data = await this.getActivityComments({
            activityId: this.activityId
        });
        if (data?.activityById?.comments.nodes) {
            this.commentsActivity = data.activityById.comments.nodes;
        }
        else {
            this.commentsActivity = [];
        }
    }
    // Uses API2
    async fetchCommentsForOrganization() {
        if (!this.organizationCode) {
            return;
        }
        try {
            const resp = await this.$http.get(`/comments`, {
                params: {
                    organizationCode: this.organizationCode,
                    take: -1
                }
            });
            if (resp.data.results) {
                this.commentsOrganization = resp.data.results;
            }
            else {
                this.commentsOrganization = [];
            }
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async fetchCommentsForPia() {
        if (!this.physicalInventoryAuditNodeId) {
            return;
        }
        const data = await this.getPiaComments({
            physicalInventoryAuditNodeId: this.physicalInventoryAuditNodeId
        });
        if (data?.physicalInventoryAudit?.comments.nodes) {
            this.commentsPia = data.physicalInventoryAudit.comments.nodes;
        }
        else {
            this.commentsPia = [];
        }
    }
    // Uses API2
    async fetchCommentsForDeliveryMatrixItems() {
        if (!this.deliveryMatrixItemId) {
            return;
        }
        try {
            const resp = await this.$http.get(`/comments`, {
                params: {
                    deliveryMatrixItemId: this.deliveryMatrixItemId,
                    take: -1
                }
            });
            if (resp.data.results) {
                this.commentsDeliveryMatrixItem = resp.data.results;
            }
            else {
                this.commentsDeliveryMatrixItem = [];
            }
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    // Uses API2
    async fetchCommentsForRisk() {
        if (!this.riskId) {
            return;
        }
        try {
            const resp = await this.$http.get(`/comments`, {
                params: {
                    riskId: this.riskId,
                    take: -1
                }
            });
            if (resp.data.results) {
                this.commentsRisk = resp.data.results;
            }
            else {
                this.commentsRisk = [];
            }
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    // Uses API2
    async fetchCommentsForRiskMitigationStep() {
        if (!this.riskId) {
            return;
        }
        try {
            const resp = await this.$http.get(`/comments`, {
                params: {
                    riskMitigationId: this.riskMitigationId,
                    take: -1
                }
            });
            if (resp.data.results) {
                this.commentsRisk = resp.data.results;
            }
            else {
                this.commentsRisk = [];
            }
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async fetchComments() {
        try {
            this.isFetching = true;
            // Maybe not the best but the assumption has always been you have a "pov"
            // so youll only fetch one of these at a time
            if (this.activityId) {
                await this.fetchCommentsForActivity();
            }
            if (this.deliveryMatrixItemId) {
                await this.fetchCommentsForDeliveryMatrixItems();
            }
            if (this.documentId) {
                await this.fetchCommentsForDocument();
            }
            if (this.eventId) {
                await this.fetchCommentsForEvent();
            }
            if (this.hardwareListId) {
                await this.fetchCommentsForHardwareList();
            }
            if (this.itemDrawingNodeId) {
                await this.fetchCommentsForItemDrawing();
            }
            if (this.itemMasterNodeId) {
                await this.fetchCommentsForItemMaster();
            }
            if (this.itemInstanceNodeId) {
                await this.fetchCommentsForItemInstance();
            }
            if (this.organizationCode) {
                await this.fetchCommentsForOrganization();
            }
            if (this.physicalInventoryAuditNodeId) {
                await this.fetchCommentsForPia();
            }
            if (this.riskId) {
                await this.fetchCommentsForRisk();
            }
            if (this.riskMitigationId) {
                await this.fetchCommentsForRiskMitigationStep();
            }
            this.isFetching = false;
            this.commentTypesToDisplay =
                this.existingCommentTypes.filter((comment) => {
                    return !this.ignoreCommentTypes?.includes(comment);
                }) || [];
        }
        catch (err) {
            this.$errorUtility({
                err
            });
        }
    }
    onModalCommentEditClose() {
        this.selectedComment = null;
        this.showEditModal = false;
    }
    emitComments() { }
    commentTotalByType(commentType) {
        return this.allComments.filter((comment) => comment.subType === commentType).length;
    }
    reloadComments() {
        this.selectedComment = null;
        this.showDeleteConfirmation = false;
        this.showEditModal = false;
        this.fetchComments();
    }
    setCommentType(type) {
        this.commentTypesToDisplay = [type];
    }
    viewAllComments() {
        this.commentTypesToDisplay = this.existingCommentTypes;
    }
    buildDocumentComments(document) {
        if (!document) {
            return [];
        }
        // get all the document comments
        const documentComments = document.documentComments.nodes;
        // get all the change set comments
        // first, loop through change sets
        const changeSetComments = document?.changeSets.nodes.reduce((comments, changeSet) => {
            // if change set has comments...
            if (changeSet?.changeSetComments.nodes.length) {
                // ...push the new comments to the aggregator
                comments.push(...changeSet.changeSetComments.nodes);
            }
            return comments;
        }, []);
        // get all the change set authority comments
        // first, loop through the change set authorities
        const changeSetAuthorityComments = document?.changeSetAuthorities.nodes.reduce((comments, changeSetAuthority) => {
            // if change set authority has comments...
            if (changeSetAuthority?.changeSetAuthorityComments.nodes.length) {
                // ...push the new comments to the aggregator
                comments.push(...changeSetAuthority.changeSetAuthorityComments.nodes);
            }
            return comments;
        }, []);
        // combine all the comments
        let comments = [...documentComments, ...changeSetComments, ...changeSetAuthorityComments];
        // remove duplicates
        const commentIds = [];
        comments = comments.filter((c) => {
            if (!commentIds.includes(c.id)) {
                commentIds.push(c.id);
                return true;
            }
            return false;
        });
        // sort them by date
        comments.sort(sortObjectBy('_updatedDateTicks', true));
        return comments;
    }
};
__decorate([
    Prop({
        default() {
            return Object.values(EditableCommentTypes);
        },
        type: Array
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], AppComments.prototype, "createCommentTypes", void 0);
__decorate([
    Prop({
        default() {
            return Object.values(EditableCommentTypes);
        },
        type: Array
    }),
    __metadata("design:type", typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object)
], AppComments.prototype, "editCommentTypes", void 0);
__decorate([
    Prop({
        default() {
            return [];
        },
        type: Array
    }),
    __metadata("design:type", typeof (_c = typeof Maybe !== "undefined" && Maybe) === "function" ? _c : Object)
], AppComments.prototype, "ignoreCommentTypes", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], AppComments.prototype, "activityId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_d = typeof Maybe !== "undefined" && Maybe) === "function" ? _d : Object)
], AppComments.prototype, "deliveryMatrixItemId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_e = typeof Maybe !== "undefined" && Maybe) === "function" ? _e : Object)
], AppComments.prototype, "documentId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_f = typeof Maybe !== "undefined" && Maybe) === "function" ? _f : Object)
], AppComments.prototype, "eventId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_g = typeof Maybe !== "undefined" && Maybe) === "function" ? _g : Object)
], AppComments.prototype, "hardwareListId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_h = typeof Maybe !== "undefined" && Maybe) === "function" ? _h : Object)
], AppComments.prototype, "itemDrawingNodeId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_j = typeof Maybe !== "undefined" && Maybe) === "function" ? _j : Object)
], AppComments.prototype, "itemMasterNodeId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_k = typeof Maybe !== "undefined" && Maybe) === "function" ? _k : Object)
], AppComments.prototype, "itemInstanceNodeId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_l = typeof Maybe !== "undefined" && Maybe) === "function" ? _l : Object)
], AppComments.prototype, "organizationCode", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_m = typeof Maybe !== "undefined" && Maybe) === "function" ? _m : Object)
], AppComments.prototype, "physicalInventoryAuditNodeId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_o = typeof Maybe !== "undefined" && Maybe) === "function" ? _o : Object)
], AppComments.prototype, "riskId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_p = typeof Maybe !== "undefined" && Maybe) === "function" ? _p : Object)
], AppComments.prototype, "riskMitigationId", void 0);
__decorate([
    Prop({
        type: Array,
        required: false
    }),
    __metadata("design:type", typeof (_q = typeof Maybe !== "undefined" && Maybe) === "function" ? _q : Object)
], AppComments.prototype, "tableHeaderOverrides", void 0);
__decorate([
    Emit('comments'),
    Watch('computedComments', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppComments.prototype, "emitComments", null);
AppComments = __decorate([
    Component
], AppComments);
export default AppComments;
