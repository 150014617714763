var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { AlertType } from '~/nasa_ui/types';
import { sortObjectBy } from '~/nasa_ui/utils';
import { HardwareListAssemblyResponseDisplay, transformHardwareListResponses } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let ModalHardwareListAssemblyCopyToCriticalInventory = class ModalHardwareListAssemblyCopyToCriticalInventory extends Mixins(BaseModal) {
    criticalInventoryTrackingHardwareLists = null;
    selectedCriticalInventoryHardwareListId = null;
    hardwareListAssembly;
    get hasCriticalInventoryTrackingHardwareLists() {
        return Boolean(this.criticalInventoryTrackingHardwareLists?.length);
    }
    get criticalInventorySelectOptions() {
        return this.transformedCriticalInventoryHardwareLists
            .map((hwList) => {
            return {
                displayText: `<strong>${hwList.name || 'no name'}</strong> <strong>(</strong><small>${hwList.number}</small><strong>)</strong>` || 'UNKNOWN',
                value: hwList?.id
            };
        })
            .sort(sortObjectBy('displayText'));
    }
    get computedModalWidth() {
        return this.selectedCriticalInventoryHardwareListId ? '95vw' : '60vw';
    }
    get nextLogicalSequenceNumberWithinSelectedList() {
        const lastNode = this.selectedCriticalInventoryHardwareList?.assemblies?.sort(sortObjectBy('sequence', true))[0];
        return (lastNode?.sequence ?? 0) + 10;
    }
    get selectedCriticalInventoryHardwareList() {
        return this.selectedCriticalInventoryHardwareListId
            ? this.transformedCriticalInventoryHardwareLists.find((hl) => hl.id === this.selectedCriticalInventoryHardwareListId)
            : null;
    }
    get transformedCriticalInventoryHardwareLists() {
        return this.criticalInventoryTrackingHardwareLists
            ? transformHardwareListResponses(this.criticalInventoryTrackingHardwareLists)
            : [];
    }
    async fetchCriticalInventoryTrackingHardwareLists() {
        try {
            const resp = await this.$http.get(`/hardwarelist`, {
                params: {
                    isCritical: true,
                    take: '-1'
                }
            });
            this.criticalInventoryTrackingHardwareLists =
                resp.data.results?.filter((hardwareList) => hardwareList.isCritical) || [];
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async onClickOfCopyToCriticalInventory() {
        if (!this.hardwareListAssembly || !this.selectedCriticalInventoryHardwareListId) {
            return;
        }
        try {
            const resp = await this.$http.post(`/hardwarelist/${this.selectedCriticalInventoryHardwareListId}/assemblies`, {
                sequence: this.nextLogicalSequenceNumberWithinSelectedList,
                quantity: this.hardwareListAssembly.quantity,
                isExternal: false,
                inventoryId: this.hardwareListAssembly.inventoryId // by convention
            });
            if (resp) {
                this.$notification.add({
                    type: AlertType.SUCCESS,
                    text: `Item copied to ${this.selectedCriticalInventoryHardwareList?._name} (${this.selectedCriticalInventoryHardwareList?._number})`
                });
            }
            this.emitEntityCreated();
            this.emitModalClose();
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    onHardwareListAssemblyUpdate() {
        this.fetchCriticalInventoryTrackingHardwareLists();
    }
};
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof HardwareListAssemblyResponseDisplay !== "undefined" && HardwareListAssemblyResponseDisplay) === "function" ? _a : Object)
], ModalHardwareListAssemblyCopyToCriticalInventory.prototype, "hardwareListAssembly", void 0);
__decorate([
    Watch('hardwareListAssembly', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalHardwareListAssemblyCopyToCriticalInventory.prototype, "onHardwareListAssemblyUpdate", null);
ModalHardwareListAssemblyCopyToCriticalInventory = __decorate([
    Component
], ModalHardwareListAssemblyCopyToCriticalInventory);
export default ModalHardwareListAssemblyCopyToCriticalInventory;
