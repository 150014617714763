import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { AttachmentType } from '~/db_types';
import BaseModal from '~/nasa_ui/base/BaseModal';
import ModalFileAttachmentBase from '~/nasa_ui/components/ModalFileAttachmentBase/ModalFileAttachmentBase';
import { AttachmentRepoMixin } from '~/nasa_ui/DAL/attachments';
import { AttachmentTypeDisplay } from '~/nasa_ui/types/enums/attachments';
import { FileAttachmentTableHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
import { transformAttachments } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let ModalWorkOrderStepAttachmentsManage = class ModalWorkOrderStepAttachmentsManage extends Mixins(BaseModal, AttachmentRepoMixin, ModalFileAttachmentBase) {
    AttachmentType = AttachmentType;
    AttachmentTypeDisplay = AttachmentTypeDisplay;
    attachments = [];
    avaiableDocumentAttachments = [];
    hasUpdatedAttachments = false;
    showNewAttachmentForm = false;
    showAddDocumentAttachmentTable = false;
    tableHeaders = FileAttachmentTableHeaders;
    expandedTableRow = null;
    editForm = this.formFactory();
    createForm = this.formFactory();
    selectedDocumentAttachments = [];
    stepId;
    get computedTableItems() {
        return transformAttachments(this.attachments || []);
    }
    get computedDocumentAttachmentTableItems() {
        return transformAttachments(this.avaiableDocumentAttachments || []);
    }
    created() {
        this.fetchAttachments();
    }
    goBack() {
        this.showAddDocumentAttachmentTable = false;
        this.showNewAttachmentForm = false;
        this.selectedDocumentAttachments = [];
    }
    async onClickOfUpdate() {
        await this.updateAttachment({ id: this.expandedTableRow?.id, patch: { description: this.editForm.description, subType: this.editForm.type } }, { success: 'Attachment updated' });
        this.expandedTableRow = null;
        this.hasUpdatedAttachments = true;
        return this.fetchAttachments();
    }
    async onClickOfSaveNew() {
        this.isSaving = true;
        if (!this.createForm.file || !this.createForm.description) {
            return;
        }
        const resp = await this.createAttachment(this.createForm);
        if (resp.data) {
            await this.linkAttachmentToEntity(resp.data.id);
            const ids = [...this.attachments.map((a) => a.id), resp.data.id];
            await this.setStepAttachments({ input: { attachmentIds: ids, workOrderStepId: this.stepId } }, { success: 'Attachment added' });
            await this.fetchAttachments();
            this.isSaving = false;
            this.createForm = this.formFactory();
            this.hasUpdatedAttachments = true;
            this.goBack();
        }
    }
    async onClickOfLinkAttachments() {
        const newAttachmentIds = [
            ...this.attachments.map((a) => a.id),
            ...this.selectedDocumentAttachments.map((a) => a.id)
        ];
        await this.setStepAttachments({ input: { attachmentIds: newAttachmentIds, workOrderStepId: this.stepId } }, { success: 'Attachments linked' });
        this.hasUpdatedAttachments = true;
        await this.fetchAttachments();
        this.goBack();
    }
    async onClickOfRemove() {
        const attachmentIds = this.attachments?.filter((a) => a.id !== this.expandedTableRow?.id).map((a) => a.id) ?? [];
        await this.setStepAttachments({ input: { attachmentIds, workOrderStepId: this.stepId } }, { success: 'Attachment removed.' });
        this.hasUpdatedAttachments = true;
        return this.fetchAttachments();
    }
    onModalClose() {
        if (this.hasUpdatedAttachments) {
            this.$emit('saved');
        }
        else {
            this.emitModalClose();
        }
    }
    onExpandedRowUpdate() {
        if (!this.expandedTableRow) {
            return;
        }
        this.editForm.type = this.expandedTableRow.subType;
        this.editForm.description = this.expandedTableRow.description;
    }
    async fetchAttachments() {
        this.isLoading = true;
        const data = await this.getWorkOrderStepAttachments({ id: this.stepId });
        this.attachments = (data?.workOrderStepById?.attachments.nodes || []);
        const stepAttachmentIds = this.attachments.map((a) => a.id);
        const docAttachmentsData = await this.getDocumentAttachmentsToLinkToStep({
            stepAttachmentIds,
            docId: this.documentId
        });
        this.avaiableDocumentAttachments = (docAttachmentsData?.documentById?.attachments.nodes ||
            []);
        this.isLoading = false;
    }
    formFactory() {
        return {
            type: this.stepId || this.workOrderStepId ? AttachmentType.WORK_ORDER_STEP : AttachmentType.GENERAL,
            description: '',
            file: null
        };
    }
    linkAttachmentToEntity(attachmentId) {
        const attachmentIds = this.attachments.map((attachment) => attachment.id);
        const existingIds = this.avaiableDocumentAttachments.map((a) => a.id);
        const variables = {
            input: this.buildLinkAttachmentPayload(attachmentId, [...attachmentIds, ...existingIds])
        };
        return this.$apollo.mutate({ mutation: this.linkAttachmentToEntityMutation, variables });
    }
};
__decorate([
    Prop({
        required: true,
        type: Number
    }),
    __metadata("design:type", Number)
], ModalWorkOrderStepAttachmentsManage.prototype, "stepId", void 0);
__decorate([
    Watch('expandedTableRow'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalWorkOrderStepAttachmentsManage.prototype, "onExpandedRowUpdate", null);
ModalWorkOrderStepAttachmentsManage = __decorate([
    Component
], ModalWorkOrderStepAttachmentsManage);
export default ModalWorkOrderStepAttachmentsManage;
