import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import BaseButton from '~/nasa_ui/base/BaseButton';
let ButtonExport = class ButtonExport extends BaseButton {
    get computedIcon() {
        return this.icon || 'fa-duotone fa-file-pdf';
    }
};
ButtonExport = __decorate([
    Component
], ButtonExport);
export default ButtonExport;
