import { __decorate, __metadata } from "tslib";
import vueJsonCompare from 'vue-json-compare';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import BaseTab from '~/nasa_ui/base/BaseTab';
import { BASE_TEXT_COL, CREATED_BY_COL, CREATED_DATE_TIME_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import { EmailNotificationRepoMixin } from '~/nasa_ui/DAL/email';
import { HeaderSortOptions } from '~/nasa_ui/types';
import { composeTableHeader } from '~/nasa_ui/utils/helpers/composeTableHeader';
import { addDateDisplays, addUserDisplays } from '~/nasa_ui/utils/helpers/displayMappers';
import { sortObjectNumericallyBy } from '~/nasa_ui/utils/helpers/sortObjectNumericallyBy';
import { transformEmailNotifications } from '~/nasa_ui/utils/helpers/transforms/emailNotifications';
let TabRiskChangeLog = class TabRiskChangeLog extends Mixins(BaseTab, EmailNotificationRepoMixin) {
    emailLogs = [];
    riskChangeLogs = [];
    riskId;
    changeLogTableHeaders = [
        CREATED_DATE_TIME_COL,
        CREATED_BY_COL,
        composeTableHeader(BASE_TEXT_COL, {
            text: 'Changes',
            value: '_changes',
            width: '70%'
        })
    ];
    emailHistoryTableHeaders = [
        composeTableHeader(CREATED_DATE_TIME_COL, {
            value: '_createdDateTime'
        }),
        CREATED_BY_COL,
        composeTableHeader(BASE_TEXT_COL, {
            text: 'Email subject',
            value: 'subject'
        }),
        composeTableHeader(BASE_TEXT_COL, {
            text: 'Email recipients',
            value: '_recipients',
            noEscape: true
        }),
        composeTableHeader(BASE_TEXT_COL, {
            text: 'Sent',
            value: '_sentDateTime',
            headerSortType: HeaderSortOptions.DATETIME
        }),
        composeTableHeader(BASE_TEXT_COL, {
            text: 'Last failure',
            value: '_lastFailureDateTime',
            headerSortType: HeaderSortOptions.DATETIME
        }),
        composeTableHeader(BASE_TEXT_COL, {
            text: 'Last attempt',
            value: '_lastAttemptDateTime',
            headerSortType: HeaderSortOptions.DATETIME
        })
    ];
    get computedChangeLogTableItems() {
        // filtering out empty logs
        const filteredChangeLog = this.riskChangeLogs.filter((log) => {
            return log.changeDetails?.changedTo && log.changeDetails?.changedFrom
                ? !(Object.keys(log.changeDetails?.changedTo || {}).length === 0 &&
                    Object.keys(log.changeDetails?.changedFrom || {}).length === 0)
                : true;
        });
        return this.transformRiskChangeLogResponses(filteredChangeLog).sort(sortObjectNumericallyBy('_createdDateTimeTicks', true));
    }
    get computedEmailHistoryTableItems() {
        return transformEmailNotifications([...this.emailLogs]).sort(sortObjectNumericallyBy('_createdDateTimeTicks', true));
    }
    transformRiskChangeLogResponses(riskChangeLogs) {
        return [...riskChangeLogs].map(addUserDisplays).map(addDateDisplays);
    }
    async fetchEntity() {
        if (!this.riskId) {
            this.$notification.add({
                type: this.AlertType.ERROR,
                text: 'Unable to fetch change logs'
            });
            return;
        }
        this.isFetching = true;
        try {
            const riskResponse = await this.$http.get(`/risks/${this.riskId}`);
            this.riskChangeLogs = riskResponse.data.riskChangeLogs || [];
            const emailLogResponse = await this.getAllRiskRelatedEmailNotifications();
            this.emailLogs = (emailLogResponse?.notifications?.nodes || []);
            this.isFetching = false;
        }
        catch (err) {
            this.isFetching = false;
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], TabRiskChangeLog.prototype, "riskId", void 0);
__decorate([
    Watch('riskId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], TabRiskChangeLog.prototype, "fetchEntity", null);
TabRiskChangeLog = __decorate([
    Component({
        components: {
            vueJsonCompare
        }
    })
], TabRiskChangeLog);
export default TabRiskChangeLog;
