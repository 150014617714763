var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-layout', {
    attrs: {
      "column": "",
      "no-wrap": ""
    }
  }, [!_vm.isReadOnly ? _c('div', {
    staticClass: "startDate mb-3"
  }, [_c('AppDate', {
    attrs: {
      "value": _vm.startDate,
      "label": "Start",
      "errorMessages": _vm.errorMessagesStartDate,
      "required": ""
    },
    on: {
      "input": _vm.emitStartDate,
      "error": _vm.onStartDateError
    }
  }), _c('AppCheckbox', {
    attrs: {
      "label": "Is this a one-day event?"
    },
    model: {
      value: _vm.isOneDayLocal,
      callback: function ($$v) {
        _vm.isOneDayLocal = $$v;
      },
      expression: "isOneDayLocal"
    }
  })], 1) : _c('AppBlockquote', {
    attrs: {
      "title": "Start"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.$dateDisplay(_vm.startDate)) + " ")])]), !_vm.isReadOnly ? _c('AppDate', {
    attrs: {
      "disabled": _vm.shouldDisableEndDate,
      "value": _vm.endDate,
      "label": "End",
      "required": "",
      "errorMessages": _vm.errorMessagesEndDate
    },
    on: {
      "input": _vm.emitEndDate,
      "error": _vm.onEndDateError
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "End"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.$dateDisplay(_vm.endDate)) + " ")])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };