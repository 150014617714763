var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "preview_wrapper",
    class: {
      allow_clear: _vm.allowClear,
      full_height: _vm.isFullHeight
    }
  }, [_vm._t("actions", function () {
    return [_vm.allowClear && !_vm.showNoPreviewMessage ? _c('div', [_c('v-tooltip', {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function ({
          on
        }) {
          return [_c('ButtonMiniAction', _vm._g({
            staticClass: "clear_selection_btn",
            on: {
              "click": _vm.emitClear
            }
          }, on), [[_c('c-icon-fa', {
            attrs: {
              "icon": "fad fa-times-circle",
              "icon-color": "var(--v-secondary-base)"
            }
          })]], 2)];
        }
      }], null, false, 678176820)
    }, [_c('span', [_vm._v("Clear file selection")])])], 1) : _vm._e()];
  }), _vm._t("img_preview", function () {
    return [_c('v-slide-y-transition', {
      attrs: {
        "hide-on-leave": ""
      }
    }, [_c('v-flex', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.isImage,
        expression: "isImage"
      }],
      staticClass: "img_wrapper",
      attrs: {
        "xs12": ""
      }
    }, [_c('img', {
      ref: "img_preview",
      style: {
        height: _vm.computedImgHeight,
        width: _vm.computedImgWidth
      },
      attrs: {
        "id": "img_preview"
      }
    })])], 1)];
  }), _vm._t("video_preview", function () {
    return [_c('v-slide-y-transition', {
      attrs: {
        "hide-on-leave": ""
      }
    }, [_c('v-flex', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.isVideo,
        expression: "isVideo"
      }],
      attrs: {
        "xs12": ""
      }
    }, [_vm.videoPreviewUrl && _vm.videoPreviewType ? _c('video', {
      ref: "video_preview_player",
      style: {
        width: _vm.computedVideoWidth,
        height: _vm.computedVideoHeight
      },
      attrs: {
        "controls": "",
        "id": "video_preview_player",
        "width": "640",
        "height": "480"
      }
    }, [_c('source', {
      ref: "video_preview_source",
      attrs: {
        "src": _vm.videoPreviewUrl,
        "type": _vm.videoPreviewType,
        "id": "video_preview_source"
      }
    }), _vm._v(" Your browser does not support HTML5 video. ")]) : _vm._e()])], 1)];
  }), _vm._t("pdf_preview", function () {
    return [_c('v-slide-y-transition', {
      attrs: {
        "hide-on-leave": ""
      }
    }, [_c('v-flex', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.isPdf,
        expression: "isPdf"
      }],
      staticClass: "pdf_wrapper",
      class: {
        full_height: _vm.isFullHeight
      },
      attrs: {
        "xs12": ""
      }
    }, [_vm.pdfPreviewUrl ? _c('embed', {
      ref: "pdf_preview",
      staticClass: "pdf_preview",
      style: {
        width: _vm.computedPdfWidth,
        height: _vm.computedPdfHeight
      },
      attrs: {
        "src": _vm.pdfPreviewUrl,
        "type": _vm.contentType,
        "height": "480",
        "id": "pdf_preview",
        "width": "640"
      }
    }) : _vm._e()])], 1)];
  }), _vm._t("after_previews"), _c('v-slide-y-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.showNoPreviewMessage ? _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_vm._t("no_preview", function () {
    return [_vm.isMicrosoftOffice ? _c('AppPageDescription', {
      attrs: {
        "remove-margin-left": "",
        "color": "warning"
      }
    }, [_vm._v("Microsoft Office files are currently unavailable for preview. "), _c('br'), _vm._v(" You can "), _c('a', {
      attrs: {
        "href": _vm.downloadUrlForLogging,
        "download": "",
        "title": "Download file"
      },
      on: {
        "click": _vm.logDownload
      }
    }, [_vm._v("download the file")]), _vm._v(" and open with Microsoft Office or convert to PDF for in browser viewing. ")]) : _c('AppPageDescription', {
      attrs: {
        "remove-margin-left": "",
        "color": "warning"
      }
    }, [_vm._v(" No preview available. ")]), _vm.isMicrosoftOffice ? _c('p', [_c('AppPageDescription', {
      staticClass: "mb-2",
      attrs: {
        "remove-margin-left": "",
        "color": "info"
      }
    }, [_vm._v("How to convert to PDF using Microsoft Office")]), _c('ol', {
      staticClass: "ml-2"
    }, [_c('li', [_vm._v("Select "), _c('b', [_vm._v("File")]), _vm._v(" tab")]), _c('li', [_vm._v("Select "), _c('b', [_vm._v("Save As")]), _vm._v(".")]), _c('li', [_vm._v("In the "), _c('b', [_vm._v("File Name")]), _vm._v(" box, enter a name for the file, if you haven't already.")]), _c('li', [_vm._v("In the "), _c('b', [_vm._v("Save as type")]), _vm._v(" list, select "), _c('b', [_vm._v("PDF (*.pdf)")]), _vm._v(".")]), _c('li', [_vm._v("Select "), _c('b', [_vm._v("Save")]), _vm._v(".")])])], 1) : _vm._e()];
  })], 2) : _vm._e()], 1)], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };