var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "app_mini_container"
  }, [_c('AppHeader', {
    staticClass: "mb-1",
    attrs: {
      "size": "entity-selector"
    }
  }, [_vm._t("title", function () {
    return [_vm._v(_vm._s(_vm.computedTitle))];
  })], 2), _c('div', {
    staticClass: "entity-mini-container",
    style: {
      maxHeight: _vm.maxHeight
    }
  }, [_vm._t("minis")], 2)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };