import { __decorate, __metadata } from "tslib";
import { differenceInDays, getDaysInMonth } from 'date-fns';
import gql from 'graphql-tag';
import { uniq } from 'lodash';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { CosmicUserSlimFragment } from '~/nasa_ui/DAL/user/slim';
import CurrentUserMixin from '~/nasa_ui/mixins/CurrentUserMixin';
import { HeaderSortOptions } from '~/nasa_ui/types';
import { addDateDisplays, addUserDisplays } from '~/nasa_ui/utils/helpers/displayMappers';
// this component does not have an initial fetch
// must $message `fetch_telemetry_log`
let AppTelemetryLog = class AppTelemetryLog extends Mixins(BaseCosmic, CurrentUserMixin) {
    allTelemetryLogs = null;
    isLoading = false;
    selectedTelemetryLog = null;
    showModalJson = false;
    totalCount = 0;
    userSelectedNumberOfRecords = 10000;
    showUser;
    userId;
    overrideRowsPerPageItems;
    get computedTelemetryLogHeaders() {
        if (this.showUser) {
            return this.telemetryLogHeaders;
        }
        else {
            return this.telemetryLogHeaders.filter((header) => {
                return header.value !== '_createdByUser';
            });
        }
    }
    get filter() {
        if (this.userId) {
            return { createdByUserId: { equalTo: this.userId }, subType: { equalTo: 'TELEMETRY' } };
        }
        return { subType: { equalTo: 'TELEMETRY' } };
    }
    get rowsPerPageItems() {
        return this.overrideRowsPerPageItems
            ? this.overrideRowsPerPageItems
            : [
                100,
                {
                    text: '$vuetify.dataIterator.rowsPerPageAll',
                    value: -1
                }
            ];
    }
    get telemetryLogs() {
        return this.allTelemetryLogs && this.allTelemetryLogs.nodes ? this.allTelemetryLogs.nodes : [];
    }
    get telemetryLogCounts() {
        return this.telemetryLogs
            ? Object.keys(this.telemetryByDate).reduce((counts, logDate) => {
                if (!logDate) {
                    return counts;
                }
                const today = new Date();
                const daysInMonth = getDaysInMonth(today);
                const daysSince = differenceInDays(today, new Date(logDate));
                const loginsThatDay = this.telemetryByDate[logDate].map((log) => log ? log.createdByUserId : 'unknown');
                const uniqLogins = uniq(loginsThatDay);
                if (daysSince < 2) {
                    counts.day = counts.day + uniqLogins.length;
                }
                if (daysSince < 8) {
                    counts.week = counts.week + uniqLogins.length;
                }
                if (daysSince < daysInMonth) {
                    counts.month = counts.month + uniqLogins.length;
                }
                return counts;
            }, {
                day: 0,
                week: 0,
                month: 0
            })
            : null;
    }
    get telemetryByDate() {
        return this.telemetryLogs
            ? this.telemetryLogs.reduce((logins, log) => {
                if (!log) {
                    return logins;
                }
                const createdDateTime = this.$dateDisplay(log.createdDateTime) || 'unknown';
                if (!logins[createdDateTime]) {
                    logins[createdDateTime] = [];
                }
                logins[createdDateTime].push(log);
                return logins;
            }, {})
            : {};
    }
    get telemetryLogHeaders() {
        return [
            {
                text: 'Date',
                value: '_createdDateTime',
                noEscape: true,
                headerSortType: HeaderSortOptions.DATETIME
            },
            {
                text: 'User',
                value: '_createdByUser',
                sortable: false
            },
            {
                text: 'Browser',
                value: 'browser',
                sortable: false,
                noEscape: true
            },
            {
                text: 'URL',
                value: 'url',
                sortable: false,
                noEscape: true,
                noWrap: true,
                width: '75%'
            }
        ];
    }
    get mappedTelemetryLogs() {
        return this.telemetryLogs
            ? this.telemetryLogs
                .map(addDateDisplays)
                .map(addUserDisplays)
                .map((log) => {
                const fromUrl = log && log.attributes && log.attributes.fromUrl
                    ? `${window.location.origin}${log.attributes.fromUrl}`
                    : DEFAULT_DASH;
                const toUrl = log && log.attributes && log.attributes.toUrl
                    ? `${window.location.origin}${log.attributes.toUrl}`
                    : DEFAULT_DASH;
                const hasRoutes = fromUrl !== DEFAULT_DASH && toUrl !== DEFAULT_DASH;
                return {
                    ...log,
                    browser: 'in template',
                    fromUrl,
                    hasRoutes,
                    nodeId: log?.nodeId,
                    toUrl,
                    url: `${fromUrl} ${toUrl}`
                };
            })
            : [];
    }
    get paginationSettings() {
        return {
            descending: true,
            sortBy: '_createdDateTime'
        };
    }
    browserToIcon(browser) {
        let icon = '';
        switch (browser) {
            case 'Chrome':
                icon = 'fab fa-chrome fa-2x';
                break;
            case 'Firefox':
                icon = 'fab fa-firefox fa-2x';
                break;
            case 'Safari':
                icon = 'fab fa-safari fa-2x';
                break;
            case 'Internet Explorer':
                icon = 'fab fa-internet-explorer fa-2x';
                break;
            case 'Edge':
                icon = 'fab fa-edge fa-2x';
                break;
            default:
                icon = 'fad fa-browser fa-2x';
                break;
        }
        return icon;
    }
    created() {
        this.$listen('fetch_telemetry_log', this.fetchTelemetryLogs);
        this.fetchTelemetryLogs();
        this.fetchTelemetryLogsTotalCount();
    }
    async fetchTelemetryLogs() {
        try {
            this.isLoading = true;
            const resp = await this.$apollo.query({
                query: gql `
          ${CosmicUserSlimFragment}

          fragment ErrorLogItem on ErrorLog {
            attributes
            createdByUserId
            createdDateTime
            nodeId
            organizationCode
            subType

            createdByUser {
              ...CosmicUserSlim
            }
          }

          query SelectTelemetryLogsForAdminsHomeView($filter: ErrorLogFilter, $first: Int) {
            errorLogs(filter: $filter, first: $first, orderBy: CREATED_DATE_TIME_DESC) {
              nodes {
                ...ErrorLogItem
              }
            }
          }
        `,
                fetchPolicy: 'cache-first',
                variables: {
                    filter: this.filter,
                    first: this.userSelectedNumberOfRecords
                }
            });
            this.isLoading = false;
            if (resp.data.errorLogs) {
                this.allTelemetryLogs = resp.data.errorLogs;
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async fetchTelemetryLogsTotalCount() {
        try {
            const resp = await this.$apollo.query({
                query: gql `
          query TelemetryLogsTotalCount($filter: ErrorLogFilter, $first: Int) {
            errorLogs(filter: $filter, first: $first, orderBy: CREATED_DATE_TIME_DESC) {
              totalCount
            }
          }
        `,
                fetchPolicy: 'cache-first',
                variables: {
                    filter: this.filter
                }
            });
            this.totalCount = resp?.data.errorLogs?.totalCount || 0;
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: true
    }),
    __metadata("design:type", Boolean)
], AppTelemetryLog.prototype, "showUser", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], AppTelemetryLog.prototype, "userId", void 0);
__decorate([
    Prop({
        type: Array
    }),
    __metadata("design:type", Object)
], AppTelemetryLog.prototype, "overrideRowsPerPageItems", void 0);
AppTelemetryLog = __decorate([
    Component({
        components: {
            VueJsonPretty
        }
    })
], AppTelemetryLog);
export default AppTelemetryLog;
