var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('c-card', {
    staticClass: "mb-2",
    attrs: {
      "border-color": "var(--v-users-base)"
    }
  }, [_c('v-layout', {
    staticClass: "mb-2",
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs3": "",
      "mr-2": ""
    }
  }, [_vm.shouldShowInputs ? _c('c-text', {
    attrs: {
      "c-value": _vm.userInformationFormData.firstName,
      "label": "First name"
    },
    on: {
      "c-input": function ($event) {
        _vm.userInformationFormData.firstName = $event.detail;
      }
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "First name",
      "value": _vm.userInformationFormData.firstName || '-'
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs2": "",
      "mr-2": ""
    }
  }, [_vm.shouldShowInputs ? _c('c-text', {
    attrs: {
      "c-value": _vm.userInformationFormData.middleName,
      "label": "Middle name"
    },
    on: {
      "c-input": function ($event) {
        _vm.userInformationFormData.middleName = $event.detail;
      }
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "Middle name",
      "value": _vm.userInformationFormData.middleName || '-'
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs3": ""
    }
  }, [_vm.shouldShowInputs ? _c('c-text', {
    attrs: {
      "c-value": _vm.userInformationFormData.lastName,
      "label": "Last name"
    },
    on: {
      "c-input": function ($event) {
        _vm.userInformationFormData.lastName = $event.detail;
      }
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "Last name",
      "value": _vm.userInformationFormData.lastName || '-'
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "mt-2": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs4": ""
    }
  }, [_vm.shouldShowInputs ? _c('AppListString', {
    attrs: {
      "color": _vm.EntityType.CONTACT,
      "label": "Contact emails",
      "required": ""
    },
    model: {
      value: _vm.userInformationFormData.email,
      callback: function ($$v) {
        _vm.$set(_vm.userInformationFormData, "email", $$v);
      },
      expression: "userInformationFormData.email"
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "Emails",
      "value": _vm.userInformationFormData.email || '-'
    }
  })], 1)], 1)], 1), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [!_vm.hideAuidField ? _c('v-flex', {
    attrs: {
      "xs2": "",
      "mr-2": ""
    }
  }, [_vm.shouldShowInputs ? _c('c-text', {
    attrs: {
      "c-value": _vm.userInformationFormData.auid,
      "label": "AUID"
    },
    on: {
      "c-input": function ($event) {
        _vm.userInformationFormData.auid = $event.detail;
      }
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "AUID",
      "value": _vm.userInformationFormData.auid || '-'
    }
  })], 1) : _vm._e(), !_vm.hideUupicField ? _c('v-flex', {
    attrs: {
      "xs3": ""
    }
  }, [_vm.shouldShowInputs ? _c('c-text', {
    attrs: {
      "c-value": _vm.userInformationFormData.uupic,
      "label": "UUPIC"
    },
    on: {
      "c-input": function ($event) {
        _vm.userInformationFormData.uupic = $event.detail;
      }
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "UUPIC",
      "value": _vm.userInformationFormData.uupic || '-'
    }
  })], 1) : _vm._e()], 1)], 1), _vm.shouldShowInputs ? _c('ButtonSave', {
    attrs: {
      "slot": "actions",
      "disabled": !_vm.isFormValid
    },
    on: {
      "click": _vm.onClickOfSave
    },
    slot: "actions"
  }) : _vm._e()], 1), _vm.doesSelectedUserSupportCurrentContext ? _c('c-card', {
    staticClass: "mt-2",
    attrs: {
      "border-color": "var(--v-users-base)"
    }
  }, [_c('div', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm._v(_vm._s(_vm.tabNamePrefix) + " Delegates")]), _c('AppPageDescription', {
    staticClass: "mt-3",
    attrs: {
      "color": "info",
      "remove-margin-left": ""
    }
  }, [_vm._v(" Adding delegates allows you to assign other " + _vm._s(_vm.currentSystemName) + " Users as your backup; Allowing them to sign-off on changes on your behalf if you are selected to approve singularly. This "), _c('em', [_c('strong', [_vm._v("DOES NOT")])]), _vm._v(" extend to a User Group you are a part of. ")]), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_c('ButtonCreate', {
    on: {
      "click": function ($event) {
        _vm.showDelegateModal = true;
      }
    }
  }, [_vm._v("Add a delegate")])], 1), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.delegates ? _c('AppTableActions', {
    ref: "delegates",
    staticClass: "mt-2",
    attrs: {
      "color": _vm.EntityType.USER,
      "headers": _vm.UserTableHeaders,
      "items": _vm.delegates,
      "disable-initial-sort": ""
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function ({
        item
      }) {
        return [!_vm.isSelectedDelegateYourself ? _c('ButtonMiniActionRemoveFromList', {
          on: {
            "click": function ($event) {
              return _vm.onClickOfRemoveDelegate(item);
            }
          }
        }) : _vm._e()];
      }
    }], null, false, 1759103411)
  }) : _vm._e()], 1)], 1)], 1) : _vm._e(), _c('AppExpansionPanel', {
    staticClass: "mt-2",
    attrs: {
      "color": _vm.EntityType.USER,
      "is-open": false
    },
    on: {
      "input": _vm.fetchErrorLogs
    }
  }, [_c('div', {
    staticClass: "ml-1",
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('c-icon-fa', {
    staticClass: "mr-3",
    class: _vm.$icons['ADMIN']
  }), _c('span', {
    staticClass: "title ml-1"
  }, [_vm._v("Administrative details")])], 1), _vm.user ? _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_vm.user.accountId ? _c('v-flex', {
    attrs: {
      "xs6": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Account ID"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.user.accountId) + " ")])])], 1) : _vm._e(), _c('v-flex', {
    attrs: {
      "xs6": ""
    }
  }, [_vm.user.id ? _c('AppBlockquote', {
    attrs: {
      "title": "ID"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.user.id) + " ")])]) : _vm._e()], 1), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_vm.user.createdDateTime ? _c('AppBlockquote', {
    staticClass: "pt-1",
    attrs: {
      "title": "Created on"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.$dateTimeDisplay(_vm.user.createdDateTime)) + " ")])]) : _vm._e()], 1), _c('v-flex', {
    attrs: {
      "xs4": "",
      "sm3": "",
      "md2": ""
    }
  }, [_c('AppBlockquote', {
    staticClass: "pt-1",
    attrs: {
      "title": "Role"
    }
  }, [_c('span', {
    class: {
      'error--text font-weight-bold': _vm.user.role === _vm.UserRole.DISABLED
    }
  }, [_vm._v(_vm._s(_vm.user.role))])])], 1), _c('v-flex', {
    attrs: {
      "xs4": "",
      "sm3": "",
      "md2": ""
    }
  }, [_c('AppBlockquote', {
    staticClass: "pt-1",
    attrs: {
      "align": "center"
    }
  }, [_c('div', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm._v("Pending 1"), _c('sup', [_vm._v("st")]), _vm._v(" login?")]), _c('AppBooleanDisplay', {
    attrs: {
      "value": _vm.user.isPendingFirstLogin
    }
  })], 1)], 1), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_vm.isRoleAdmin && _vm.user.role !== _vm.UserRole.USER ? _c('ButtonGeneric', {
    staticClass: "mr-2",
    attrs: {
      "icon": _vm.$icons['USER'],
      "small": ""
    },
    on: {
      "click": _vm.onClickOfDemoteUser
    }
  }, [_vm._v(" Demote to User ")]) : _vm._e(), _vm.isRoleAdmin && _vm.user.role !== _vm.UserRole.ADMIN ? _c('ButtonGeneric', {
    staticClass: "mr-2",
    attrs: {
      "icon": _vm.$icons['ADMIN'],
      "small": ""
    },
    on: {
      "click": _vm.onClickOfPromoteUserAdminRole
    }
  }, [_vm._v(" Promote to Admin ")]) : _vm._e(), _vm.isRoleAdmin && !_vm.selectedUserHasCurrentContextAccess && _vm.currentUserActiveSupportContext ? _c('ButtonGeneric', {
    staticClass: "mr-2",
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.ORGANIZATION],
      "small": ""
    },
    on: {
      "click": _vm.onClickOfGrantAccessToCurrentContext
    }
  }, [_vm._v(" Grant access to " + _vm._s(_vm.currentUserActiveSupportContext) + " ")]) : _vm._e(), _vm.isRoleAdmin && _vm.user.role !== _vm.UserRole.DISABLED ? _c('ButtonGeneric', {
    staticClass: "mr-2",
    attrs: {
      "icon": _vm.$icons['pipeline_canceled'],
      "color": "error",
      "small": ""
    },
    on: {
      "click": _vm.onClickOfDisableUser
    }
  }, [_vm._v(" Disable user ")]) : _vm._e(), _vm.shouldShowDeleteUser ? _c('ButtonRemove', {
    staticClass: "mx-0",
    attrs: {
      "is-removing": _vm.isDeleting,
      "small": ""
    },
    on: {
      "click": _vm.onClickOfDeleteUser
    }
  }) : _vm._e()], 1), _vm.isUserAdmin ? _c('v-flex', {
    attrs: {
      "xs12": "",
      "mt-3": ""
    }
  }, [_c('AppTelemetryLog', {
    attrs: {
      "show-user": false,
      "user-id": _vm.user.id,
      "override-rows-per-page-items": _vm.overrideRowsPerPageItems
    }
  })], 1) : _vm._e(), _vm.isUserAdmin ? _c('v-flex', {
    attrs: {
      "xs12": "",
      "mt-3": ""
    }
  }, [_c('AppErrorLog', {
    attrs: {
      "show-user": false,
      "user-id": _vm.user.id,
      "override-rows-per-page-items": _vm.overrideRowsPerPageItems
    }
  })], 1) : _vm._e()], 1) : _vm._e()], 1), _vm.showDeletionModal ? _c('AreYouSureDialog', {
    on: {
      "affirmativeClicked": _vm.onConfirmDeletion,
      "negativeClicked": _vm.onCloseOfDeleteUserDialog
    }
  }, [_c('p', {
    attrs: {
      "slot": "text"
    },
    slot: "text"
  }, [_vm._v("This will delete the User. This cannot be undone.")]), _c('template', {
    slot: "html"
  }, [_c('AppCheckbox', {
    attrs: {
      "color": _vm.EntityType.USER,
      "label": "Delete contact information?"
    },
    model: {
      value: _vm.shouldAlsoDeleteContact,
      callback: function ($$v) {
        _vm.shouldAlsoDeleteContact = $$v;
      },
      expression: "shouldAlsoDeleteContact"
    }
  })], 1)], 2) : _vm._e(), _vm.showDelegateModal ? _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.USER,
      "hide-title-section": ""
    },
    on: {
      "modal-close": function ($event) {
        _vm.showDelegateModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('SearchUser', {
          attrs: {
            "existing-values": [..._vm.existingDelegateIds, _vm.userId],
            "output-mode": "object",
            "keep-search-results-open": ""
          },
          model: {
            value: _vm.userForDelegate,
            callback: function ($$v) {
              _vm.userForDelegate = $$v;
            },
            expression: "userForDelegate"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonCancel', {
          on: {
            "click": function ($event) {
              _vm.showDelegateModal = false;
            }
          }
        })];
      },
      proxy: true
    }], null, false, 2468821823)
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };