var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "hide-title-section": "",
      "color": `--v-${_vm.EntityType.RISK}-base`,
      "modal-width": "60vw"
    },
    on: {
      "modal-close": _vm.emitModalClose
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('div', {
          staticClass: "pa-0"
        }, [_c('AppFormWrapper', {
          attrs: {
            "validate-on-load": "",
            "noBorder": ""
          }
        }, [_c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('AppMarkdown', {
          attrs: {
            "label": "Step description",
            "required": ""
          },
          model: {
            value: _vm.formData.description,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "description", $$v);
            },
            expression: "formData.description"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('AppMarkdown', {
          attrs: {
            "label": "Impact"
          },
          model: {
            value: _vm.formData.impact,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "impact", $$v);
            },
            expression: "formData.impact"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs6": "",
            "mb-2": ""
          }
        }, [_c('AppSelectEnum', {
          attrs: {
            "chips": false,
            "display": _vm.MitigationTeamDisplay,
            "enum": _vm.RiskMitigationTeam,
            "label": "Team",
            "required": ""
          },
          model: {
            value: _vm.formData.team,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "team", $$v);
            },
            expression: "formData.team"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs12": "",
            "mb-2": ""
          }
        }, [_c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs6": ""
          }
        }, [_c('AppDate', {
          attrs: {
            "label": "Start",
            "required": ""
          },
          model: {
            value: _vm.formData.startDate,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "startDate", $$v);
            },
            expression: "formData.startDate"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs6": ""
          }
        }, [_c('AppDate', {
          attrs: {
            "label": "Projected closure"
          },
          model: {
            value: _vm.formData.projectedCloseDate,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "projectedCloseDate", $$v);
            },
            expression: "formData.projectedCloseDate"
          }
        })], 1)], 1)], 1), _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('AppPageDescription', {
          attrs: {
            "color": "info",
            "remove-margin-left": ""
          }
        }, [_vm._v(" Enter the PROJECTED likelihood / consequence at the time of step closure. ")]), _c('v-layout', {
          staticClass: "risk-score-inputs",
          attrs: {
            "row": "",
            "no-wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs4": "",
            "pr-2": ""
          }
        }, [_c('AppNumber', {
          attrs: {
            "label": "Projected likelihood",
            "min": 1,
            "max": 5,
            "required": ""
          },
          on: {
            "error": _vm.onLikelihoodError
          },
          model: {
            value: _vm.formData.projectedLikelihood,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "projectedLikelihood", $$v);
            },
            expression: "formData.projectedLikelihood"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs4": ""
          }
        }, [_c('AppNumber', {
          attrs: {
            "label": "Projected consequence",
            "min": 1,
            "max": 5,
            "required": ""
          },
          on: {
            "error": _vm.onConsequenceError
          },
          model: {
            value: _vm.formData.projectedConsequence,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "projectedConsequence", $$v);
            },
            expression: "formData.projectedConsequence"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs4": ""
          }
        }, [_c('AppBlockquote', {
          staticClass: "text-xs-center",
          attrs: {
            "title": "Projected Risk score"
          }
        }, [_c('span', {
          class: _vm.riskScoreClass
        }, [_vm._v(_vm._s(_vm.riskScore))])])], 1)], 1)], 1), _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('AppMarkdown', {
          attrs: {
            "label": "Results / Exit criteria",
            "required": _vm.formData.isCompleted
          },
          model: {
            value: _vm.formData.exitCriteria,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "exitCriteria", $$v);
            },
            expression: "formData.exitCriteria"
          }
        })], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonCreate', {
          attrs: {
            "disabled": !_vm.isFormValid
          },
          on: {
            "click": _vm.onClickOfCreate
          }
        }), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        })];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };