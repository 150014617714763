var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "chart-container",
    style: _vm.computedStyle
  }, [_vm.chartData ? _c('CustomMatrixChartForRisks', {
    ref: "customMatrix",
    attrs: {
      "annotations": _vm.annotations,
      "data": _vm.chartData,
      "options": _vm.options,
      "plugins": [_vm.backgroundColorPlugin]
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };