var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('AppExpansionPanel', {
    attrs: {
      "color": "black",
      "popout": "",
      "isOpen": true
    }
  }, [_c('v-flex', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('span', {
    staticClass: "title"
  }, [_vm._v("Entity type icons")])]), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, _vm._l(_vm.entityTypeIcons, function (item, index) {
    var _item$textColor;
    return _c('v-flex', {
      key: index,
      attrs: {
        "xs3": ""
      }
    }, [_c('div', {
      staticClass: "icon_box",
      class: [item.entityType, (_item$textColor = item.textColor) !== null && _item$textColor !== void 0 ? _item$textColor : 'white--text'],
      attrs: {
        "title": item.icon
      },
      on: {
        "click": function ($event) {
          return _vm.onClickOfIcon(item.icon);
        }
      }
    }, [_c('c-icon-fa', {
      staticClass: "admin_icon_display",
      attrs: {
        "icon": item.icon
      }
    }), _c('div', {
      staticClass: "entity_type font-weight-bold"
    }, [_vm._v(_vm._s(item.entityType))])], 1)]);
  }), 1)], 1), _c('AppExpansionPanel', {
    attrs: {
      "color": _vm.EntityType.ACTIVITY,
      "popout": "",
      "isOpen": false
    }
  }, [_c('v-flex', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('span', {
    staticClass: "title"
  }, [_vm._v("Activity subType Icons")])]), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, _vm._l(_vm.activityTypeIcons, function (item, index) {
    return _c('v-flex', {
      key: index,
      attrs: {
        "xs3": ""
      }
    }, [_c('div', {
      staticClass: "icon_box",
      attrs: {
        "title": item.icon
      },
      on: {
        "click": function ($event) {
          return _vm.onClickOfIcon(item.icon);
        }
      }
    }, [_c('c-icon-fa', {
      staticClass: "admin_icon_display",
      attrs: {
        "icon-color": "var(--v-activities-base)",
        "icon": item.icon
      }
    }), _c('div', {
      staticClass: "entity_type sub_type font-weight-bold"
    }, [_vm._v(_vm._s(item.entityType))])], 1)]);
  }), 1)], 1), _c('AppExpansionPanel', {
    attrs: {
      "color": _vm.EntityType.DOCUMENT,
      "popout": "",
      "isOpen": false
    }
  }, [_c('v-flex', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('span', {
    staticClass: "title"
  }, [_vm._v("Document subType Icons")])]), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, _vm._l(_vm.documentTypeIcons, function (item, index) {
    return _c('v-flex', {
      key: index,
      attrs: {
        "xs3": ""
      }
    }, [_c('div', {
      staticClass: "icon_box",
      attrs: {
        "title": item.icon
      },
      on: {
        "click": function ($event) {
          return _vm.onClickOfIcon(item.icon);
        }
      }
    }, [_c('c-icon-fa', {
      staticClass: "admin_icon_display",
      attrs: {
        "icon-color": "var(--v-documents-base)",
        "icon": item.icon
      }
    }), _c('div', {
      staticClass: "entity_type sub_type text-truncate font-weight-bold"
    }, [_vm._v(_vm._s(item.entityType))])], 1)]);
  }), 1)], 1), _c('AppExpansionPanel', {
    attrs: {
      "color": "pink",
      "popout": "",
      "isOpen": true
    }
  }, [_c('v-flex', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('span', {
    staticClass: "title"
  }, [_vm._v("Other Icons")])]), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, _vm._l(_vm.otherIcons, function (item, index) {
    return _c('v-flex', {
      key: index,
      attrs: {
        "xs3": ""
      }
    }, [_c('div', {
      staticClass: "icon_box",
      attrs: {
        "title": item.icon
      },
      on: {
        "click": function ($event) {
          return _vm.onClickOfIcon(item.icon);
        }
      }
    }, [_c('c-icon-fa', {
      staticClass: "admin_icon_display",
      attrs: {
        "icon": item.icon
      }
    }), _c('div', {
      staticClass: "entity_type sub_type text-truncate font-weight-bold"
    }, [_vm._v(_vm._s(item.entityType))])], 1)]);
  }), 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };