var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm._m(0);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "text-xs-center",
    attrs: {
      "id": "no_access"
    }
  }, [_c('h3', [_vm._v("Access Denied")]), _c('p', [_vm._v("Your User account doesn’t have the appropriate permissions or role to access the page you requested.")]), _c('p', [_vm._v("Note: If you just revalidated your session "), _c('a', {
    attrs: {
      "href": "/"
    }
  }, [_vm._v("click here to return to the homepage")]), _vm._v(".")])]);
}];
export { render, staticRenderFns };