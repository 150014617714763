var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { LocationResponse } from '~/db_types/swagger_types';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import ModalConfirmationDelete from '~/nasa_ui/components/ModalConfirmationDelete/ModalConfirmationDelete.vue';
import { AlertType, EntityType } from '~/nasa_ui/types';
import { UserPermission } from '~/nasa_ui/types/enums/user';
let ArchiveLocation = class ArchiveLocation extends Mixins(BaseCosmic) {
    isSaving = false;
    showDialog = false;
    location;
    get adminPermission() {
        return UserPermission.LOCATION_ADMIN;
    }
    get isArchived() {
        return Boolean(this.location?.isArchived);
    }
    get shouldDisableArchiveButton() {
        if (!this.adminPermission) {
            return true;
        }
        return !this.currentUserHasPermission(this.adminPermission) || this.isSaving;
    }
    // called from ui
    onAffirmativeClicked() {
        this.archiveLocation();
    }
    onClickOfArchiveButton() {
        this.showDialog = true;
    }
    onClickOfUnarchiveButton() {
        this.archiveLocation();
    }
    onNegativeClicked() {
        this.showDialog = false;
    }
    async archiveLocation() {
        try {
            this.isSaving = true;
            const updateObj = Object.assign({}, this.location, {
                isArchived: !this.isArchived // flip it
            });
            const resp = await this.$http.put(`/${EntityType.LOCATION}/${this.location?.id}`, updateObj);
            if (resp) {
                this.$notification.add({
                    text: `${this.isArchived ? 'Unarchived' : 'Archived'}.`,
                    type: AlertType.SUCCESS
                });
                this.$message('reload', true);
                this.reset();
                // while waiting on the parent to reload, keep showing spinner instead of isArchived
                setTimeout(() => {
                    this.isSaving = false;
                }, 2000);
            }
        }
        catch (err) {
            this.isSaving = false;
            this.$message('reload', true);
            this.reset();
            this.$errorUtility({
                err,
                backupErrorText: `Failed to archive Location.`
            });
        }
    }
    reset() {
        this.isSaving = false;
        this.showDialog = false;
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }
};
__decorate([
    Prop({
        type: Object,
        required: true
    }),
    __metadata("design:type", typeof (_a = typeof LocationResponse !== "undefined" && LocationResponse) === "function" ? _a : Object)
], ArchiveLocation.prototype, "location", void 0);
ArchiveLocation = __decorate([
    Component({
        components: {
            ModalConfirmationDelete
        }
    })
], ArchiveLocation);
export default ArchiveLocation;
