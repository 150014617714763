import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import Log from '~/nasa_ui/modules/Log';
import { getUniqueTempId } from '~/nasa_ui/utils/helpers/getUniqueTempId';
export var FileAttachmentListCommands;
(function (FileAttachmentListCommands) {
    FileAttachmentListCommands["CLEAR_SELECTED_FILES"] = "CLEAR_SELECTED_FILES";
    FileAttachmentListCommands["SET_SELECTED_FILES"] = "SET_SELECTED_FILES";
})(FileAttachmentListCommands || (FileAttachmentListCommands = {}));
let AppFileAttachmentList = class AppFileAttachmentList extends BaseCosmic {
    filesList = [];
    selectedFiles = [];
    previewFile = null;
    hideFilePreview;
    multiple;
    selectable;
    title;
    value;
    get fileNames() {
        return this.filesList.map((file) => file.file.name);
    }
    checkboxClass(file) {
        let className = '';
        const isSelected = this.selectedFiles.some((f) => f.tempId === file.tempId);
        if (this.multiple) {
            className = isSelected ? 'fas fa-check-square' : 'far fa-square';
        }
        else {
            className = isSelected ? 'fas fa-dot-circle' : 'far fa-circle';
        }
        return className;
    }
    created() {
        this.$listen(FileAttachmentListCommands.CLEAR_SELECTED_FILES, () => (this.selectedFiles = []));
        this.$listen(FileAttachmentListCommands.SET_SELECTED_FILES, (files) => (this.selectedFiles = files));
    }
    onClickOfDropzone() {
        if (this.filesList.length) {
            return;
        }
        this.openFilesDialog();
    }
    onClickOfFile(file) {
        if (!this.selectable) {
            this.emitFileSelected(file);
            return;
        }
        // if already selected, remove it
        if (this.selectedFiles.some((f) => f.tempId === file.tempId)) {
            this.selectedFiles = this.selectedFiles.filter((f) => f.tempId !== file.tempId);
            this.emitFileDeselected(file);
            return;
        }
        // if multiple mode, push the index
        if (this.multiple) {
            this.selectedFiles.push(file);
            this.emitFileSelected(file);
            return;
        }
        this.selectedFiles = [file];
        this.emitFileSelected(file);
    }
    onFilesDropped(fileList) {
        // weirdly necessary to loop instead of using Array.from() or similar
        for (const file of fileList) {
            const fileWithTempId = {
                tempId: getUniqueTempId(this.filesList),
                file
            };
            this.filesList.push(fileWithTempId);
        }
        this.emitInput(this.filesList);
    }
    onFilesSelected(event) {
        const inputElement = event.target;
        for (const file of inputElement?.files || []) {
            const fileWithTempId = {
                tempId: getUniqueTempId(this.filesList),
                file
            };
            this.filesList.push(fileWithTempId);
        }
        // clear the input element
        inputElement.value = '';
        this.emitInput(this.filesList);
    }
    openFilesDialog() {
        const inputElement = this.$refs.fileInput;
        inputElement.click();
    }
    emitFileSelected(file) { }
    emitFileDeselected(file) { }
    emitInput(files) { }
    onChangeOfValue(value) {
        // early return if no files were changed
        if (value.length === this.filesList.length && value.every((file) => this.fileNames.includes(file.file.name))) {
            return;
        }
        this.filesList = value;
    }
    onClickOfRemoveAll() {
        this.emitInput([]);
    }
    onFileRemoved(file) {
        this.emitInput(this.filesList.filter((f, i) => f.tempId !== file.tempId));
    }
    onFileSelectionChanged(files) { }
    logFileView(data) {
        new Log().telemetry(data);
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], AppFileAttachmentList.prototype, "hideFilePreview", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], AppFileAttachmentList.prototype, "multiple", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], AppFileAttachmentList.prototype, "selectable", void 0);
__decorate([
    Prop({
        type: String,
        default: 'Attachments'
    }),
    __metadata("design:type", String)
], AppFileAttachmentList.prototype, "title", void 0);
__decorate([
    Prop({
        type: Array,
        default: []
    }),
    __metadata("design:type", Array)
], AppFileAttachmentList.prototype, "value", void 0);
__decorate([
    Emit('fileSelected'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], AppFileAttachmentList.prototype, "emitFileSelected", null);
__decorate([
    Emit('fileDeselected'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], AppFileAttachmentList.prototype, "emitFileDeselected", null);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], AppFileAttachmentList.prototype, "emitInput", null);
__decorate([
    Watch('value', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], AppFileAttachmentList.prototype, "onChangeOfValue", null);
__decorate([
    Emit('removeAll'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppFileAttachmentList.prototype, "onClickOfRemoveAll", null);
__decorate([
    Emit('fileRemoved'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], AppFileAttachmentList.prototype, "onFileRemoved", null);
__decorate([
    Emit('fileSelectionChanged'),
    Watch('selectedFiles', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], AppFileAttachmentList.prototype, "onFileSelectionChanged", null);
AppFileAttachmentList = __decorate([
    Component
], AppFileAttachmentList);
export default AppFileAttachmentList;
