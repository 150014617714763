import { __decorate } from "tslib";
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { ActivityType, DocumentType } from '~/db_types';
import { AlertType, EntityType } from '~/nasa_ui/types';
let TabAdminIcons = class TabAdminIcons extends Vue {
    entityTypesThatRequireBlackTextColor = [
        EntityType.ASSEMBLY_TRANSACTION,
        EntityType.DIGITAL_RECORD,
        EntityType.RESPONSIBLE_ORGANIZATION,
        EntityType.JF288
    ];
    get activityTypeValues() {
        return Object.values(ActivityType);
    }
    get activityTypeIcons() {
        return this.activityTypeValues
            .map((entityType) => ({
            entityType,
            icon: this.$icons[entityType] || null
        }))
            .filter((obj) => obj.icon !== null);
    }
    get documentTypeValues() {
        return Object.values(DocumentType);
    }
    get documentTypeIcons() {
        return this.documentTypeValues
            .map((entityType) => ({
            entityType,
            icon: this.$icons[entityType] || null
        }))
            .filter((obj) => obj.icon !== null);
    }
    get entityTypeValues() {
        return Object.values(EntityType);
    }
    get entityTypeIcons() {
        return this.entityTypeValues
            .map((entityType) => ({
            entityType,
            icon: this.$icons[entityType] || null,
            textColor: this.entityTypesThatRequireBlackTextColor.includes(entityType)
                ? 'black--text'
                : 'white--text'
        }))
            .filter((obj) => obj.icon !== null);
    }
    get otherIcons() {
        const entityTypeValues = [...this.entityTypeValues, ...this.activityTypeValues, ...this.documentTypeValues];
        const otherIconValues = Object.keys(this.$icons).filter((iconName) => !entityTypeValues.includes(iconName));
        return otherIconValues
            .map((entityType) => ({
            entityType,
            icon: this.$icons[entityType] || null
        }))
            .filter((obj) => obj.icon !== null);
    }
    onClickOfIcon(icon) {
        if (!icon) {
            return;
        }
        navigator.clipboard.writeText(icon).then(() => {
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Icon copied to clipboard!'
            });
        }, () => {
            this.$notification.add({
                type: AlertType.ERROR,
                text: 'Copy to clipboard failed.'
            });
        });
    }
};
TabAdminIcons = __decorate([
    Component
], TabAdminIcons);
export default TabAdminIcons;
