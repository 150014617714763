var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.selectedComment ? _c('CommentModalTemplate', {
    attrs: {
      "create-comment-types": _vm.createCommentTypes,
      "edit-comment-types": _vm.editCommentTypes,
      "id": _vm.id,
      "is-comment-immutable": _vm.isCommentImmutable,
      "read-only": _vm.readOnly
    },
    on: {
      "modal-close": _vm.emitModalClose
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_vm.isCommentEmpty ? _c('v-layout', [_c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('Alert', {
          attrs: {
            "type": _vm.AlertType.WARNING
          }
        }, [_vm._v("This Comment is empty and can be deleted by any User.")])], 1)], 1) : _vm._e(), _vm.isCommentImmutable ? _c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('Alert', {
          staticClass: "mx-2",
          attrs: {
            "type": _vm.AlertType.ERROR
          }
        }, [_vm._v("This Comment cannot be updated.")])], 1), _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('AppBlockquote', {
          staticClass: "ml-2",
          attrs: {
            "title": "Comment type",
            "value": _vm.subTypeDisplay
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('AppBlockquote', {
          staticClass: "ml-2",
          attrs: {
            "title": "Comment"
          }
        }, [_c('AppMarkdownDisplay', {
          attrs: {
            "title": "Comment",
            "markdown": _vm.selectedComment.comment
          }
        })], 1)], 1)], 1) : _vm._e()];
      },
      proxy: true
    }, {
      key: "action",
      fn: function () {
        return [!_vm.isCommentImmutable ? _c('ButtonSave', {
          staticClass: "ml-0",
          attrs: {
            "disabled": !_vm.isFormValid || _vm.isCommentImmutable
          },
          on: {
            "click": function ($event) {
              return _vm.onClickOfUpdate();
            }
          }
        }) : _vm._e(), _vm.canPromote ? _c('v-menu', {
          staticClass: "d-inline-block mr-2",
          attrs: {
            "offset-y": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function ({
              on
            }) {
              return [_c('ButtonGeneric', _vm._g({
                attrs: {
                  "color": "primary",
                  "dark": "",
                  "icon": _vm.$icons.arrow_up
                }
              }, on), [_c('span', [_vm._v("Promote Comment")])])];
            }
          }], null, false, 3819560670)
        }, [_c('v-list', {
          attrs: {
            "dark": ""
          }
        }, [_vm.selectedComment && _vm.isItemInstance ? _c('v-list-tile', {
          key: "promote_item_masters",
          on: {
            "click": function ($event) {
              return _vm.onClickOfPromote(_vm.EntityType.ITEM_MASTER);
            }
          }
        }, [_c('v-list-tile-title', [_vm._v("Promote To Item Master")])], 1) : _vm._e(), _vm.selectedComment && _vm.isEitherItemMasterOrItemInstance ? _c('v-list-tile', {
          key: "promote_item_drawings",
          on: {
            "click": function ($event) {
              return _vm.onClickOfPromote(_vm.EntityType.ITEM_DRAWING);
            }
          }
        }, [_c('v-list-tile-title', [_vm._v("Promote To Item Drawing")])], 1) : _vm._e()], 1)], 1) : _vm._e(), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        }, [_vm._v(_vm._s(_vm.isCommentImmutable ? 'Close' : 'Cancel'))])];
      },
      proxy: true
    }], null, false, 1169193696),
    model: {
      value: _vm.formData,
      callback: function ($$v) {
        _vm.formData = $$v;
      },
      expression: "formData"
    }
  }) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };