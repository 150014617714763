import { __decorate } from "tslib";
import gql from 'graphql-tag';
import { Component, Vue } from 'vue-property-decorator';
import { CosmicCommentFragment } from '~/nasa_ui/DAL/comment';
import { CosmicContactSlimFragment } from '~/nasa_ui/DAL/contact/slim';
import { CosmicRelatedDocumentFragment } from '~/nasa_ui/DAL/document/Information';
import { CosmicStepSlimFragment } from '~/nasa_ui/DAL/document/WorkOrderStep';
import { CosmicUserGroupFragment } from '~/nasa_ui/DAL/user';
import { CosmicUserSlimFragment } from '~/nasa_ui/DAL/user/slim';
let PdfExportMixin = class PdfExportMixin extends Vue {
    async queryForPDFExport(documentId) {
        try {
            if (documentId) {
                const { data } = await this.$apollo.query({
                    query: gql `
            ${CosmicUserSlimFragment}
            ${CosmicUserGroupFragment}
            ${CosmicContactSlimFragment}
            ${CosmicCommentFragment}
            ${CosmicRelatedDocumentFragment}
            ${CosmicStepSlimFragment}

            fragment HardwareListWithAssemblies on HardwareList {
              id
              nodeId
              hardwareListAssemblies {
                nodes {
                  ...HardwareListAssembly
                }
              }
            }

            fragment HardwareListAssembly on HardwareListAssembly {
              externalDrawingNumber
              externalCalibrationNumber
              externalDescription
              externalCalibrationDate
              isExternal
              externalSerialNumber
              externalClass
              externalIsTool
              externalLotNumber
              externalShelfLifeDate
              quantity
              id
              hardwareList {
                name
                id
                nodeId
              }
              inventory {
                subType
                drawingNumber
                serialNumber
                itemDrawingDescription
                lotNumber
                id
                nodeId
                itemMaster {
                  inventoryUnitType
                  inventoryMethod
                  nodeId
                }
                itemInstance {
                  id
                  nodeId
                  limitedLifeRemaining {
                    shelf {
                      valueDueDate
                    }
                  }
                }
              }
            }

            fragment CosmicDocumentStep on WorkOrderStep {
              ...CosmicStepSlim

              assigneeContact {
                ...CosmicContactSlim
              }

              hardwareList {
                ...HardwareListWithAssemblies
              }
              toolsList {
                ...HardwareListWithAssemblies
              }
              changeSet {
                ...ChangeSetWithApprovedBy
              }
            }

            fragment ChangeSetWithApprovedBy on ChangeSet {
              computedClosedDate
              id
              nodeId
              changeSetAuthorities {
                nodes {
                  authorityType
                  id
                  manualInspectionPointExplanation
                  manualInspectionPointType

                  userGroup {
                    ...CosmicUserGroup
                  }
                  authorizedByUser {
                    ...CosmicUserSlim
                  }

                  comments {
                    nodes {
                      ...CosmicComment
                    }
                  }
                }
              }
            }

            query FetchDocumentForExport($documentId: UUID!) {
              documentById(id: $documentId) {
                computedCloseDate
                computedOpenDate
                contractNumber
                description
                id
                isExportControlled
                jobNumber
                nodeId
                number
                performingOrganizationCode
                projectCode
                responsibleOrganizationCode
                subType
                title

                relatedDocuments {
                  nodes {
                    ...CosmicRelatedDocument
                  }
                }
                comments {
                  nodes {
                    ...CosmicComment
                  }
                }
                attachments {
                  nodes {
                    nodeId
                    id
                    fileName
                    description
                  }
                }
                workOrderSteps(orderBy: STEP_NUMBER_ASC) {
                  nodes {
                    ...CosmicDocumentStep
                  }
                }
                cosmicDocumentEzt {
                  computedStatus
                  nodeId
                  initializationStatus
                  isProprietary
                  initializationChangeSet {
                    ...ChangeSetWithApprovedBy
                  }

                  cosmicLineItems {
                    nodes {
                      lineItemLocation {
                        bin
                        building
                        id
                        nodeId
                        organizationCode
                        room
                        stock
                      }
                    }
                  }
                }
                cosmicDocumentDr {
                  nodeId
                  computedStatus
                  initializationStatus
                  initializationChangeSet {
                    ...ChangeSetWithApprovedBy
                  }
                  hardwareList {
                    ...HardwareListWithAssemblies
                  }
                  toolsList {
                    ...HardwareListWithAssemblies
                  }
                }
                cosmicDocumentRca {
                  additionalLocationInformation
                  awardFee
                  awardFeeWriteUpRequired
                  baseLocationOrganizationCode
                  computedStartDate
                  containment
                  description
                  id
                  initializationStatus
                  initiatedByNasa
                  initiatorContactId
                  iptAssignedGroupCode
                  nodeId
                  number
                  overrideCloseDate
                  processEscape
                  requiresNasaAwareness
                  riskFinalConsequence
                  riskFinalLikelihood
                  riskInitialConsequence
                  riskInitialLikelihood
                  riskScenario
                  rootCause
                  rootCauseCategory
                  subType
                  title
                  verification
                  document {
                    nodeId
                    comments {
                      nodes {
                        ...CosmicComment
                      }
                    }
                  }
                  initiatorContact {
                    ...CosmicContactSlim
                  }
                  initializationChangeSet {
                    computedClosedDate
                  }
                  iptAssignedGroup {
                    nodeId
                    name
                  }
                  nasaPocContact {
                    ...CosmicContactSlim
                  }
                  rcaOwnerContact {
                    ...CosmicContactSlim
                  }
                  technicalPocContact {
                    ...CosmicContactSlim
                  }
                }
                cosmicDocumentTps {
                  nodeId
                  computedStatus
                  criticality
                  hazardAnalysis
                  initializationStatus
                  isHazardous
                  initializationChangeSet {
                    ...ChangeSetWithApprovedBy
                  }
                  hardwareList {
                    ...HardwareListWithAssemblies
                  }

                  toolsList {
                    ...HardwareListWithAssemblies
                  }
                }
                cosmicDocumentWorkflow {
                  computedStatus
                  createdDateTime
                  id
                  initializationStatus
                  initiatorContactId
                  isEditable
                  nodeId
                  overrideRevisionNumber
                  workflowNotes
                  createdByUser {
                    ...CosmicUserSlim
                  }
                  initializationChangeSet {
                    ...ChangeSetWithApprovedBy
                  }
                  initiatorContact {
                    ...CosmicContactSlim
                  }
                }
              }
            }
          `,
                    variables: {
                        documentId
                    },
                    fetchPolicy: 'network-only'
                });
                return data.documentById;
            }
        }
        catch (err) {
            this.$errorUtility({ err });
        }
    }
};
PdfExportMixin = __decorate([
    Component
], PdfExportMixin);
export default PdfExportMixin;
