var _a;
import { __decorate, __metadata } from "tslib";
import { Debounce } from 'lodash-decorators';
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { UserRepoMixin } from '~/nasa_ui/DAL/user';
import CurrentUserMixin from '~/nasa_ui/mixins/CurrentUserMixin';
import LoaderMixin from '~/nasa_ui/mixins/LoaderMixin';
import { Maybe } from '~/nasa_ui/types';
import { EntityType } from '~/nasa_ui/types/cosmic/entityTypes';
let AppSelectUser = class AppSelectUser extends Mixins(LoaderMixin, UserRepoMixin, CurrentUserMixin) {
    EntityType = EntityType;
    userOptions = [];
    searchTerm = '';
    showUserInfo = false;
    showNoUsersFound = false;
    label;
    required;
    value;
    get computedSelectedUser() {
        return this.value ? this.userOptions.find((user) => user?.value === this.value) : null;
    }
    get errorMessages() {
        if (this.required && !this.value) {
            return ['REQUIRED'];
        }
        return [];
    }
    get searchHasMinimumCharacters() {
        return this.searchTerm?.length > 1;
    }
    get selectionIsYourself() {
        return this.value === this.currentUser?.id;
    }
    get shouldShowAddYourselfIcon() {
        return !this.value && !this.searchTerm;
    }
    onClickOfYourself() {
        this.userOptions = [this.mapUserToSelectOption(this.currentUser)];
        this.onSelectedUser(this.currentUser.id);
    }
    async search(q) {
        if (!q || !this.searchHasMinimumCharacters) {
            return;
        }
        this.isLoading = true;
        const data = await this.getUsers({ q });
        const userResults = data?.searchUsers?.nodes;
        this.userOptions = userResults.map((user) => this.mapUserToSelectOption(user));
        if (!this.userOptions.length) {
            this.showNoUsersFound = true;
        }
        this.isLoading = false;
    }
    async created() {
        if (!this.value) {
            return;
        }
        // populate options with single selected user
        const resp = await this.getUserById(this.value);
        const user = resp?.data;
        if (!user) {
            throw Error(`Can't populate AppSelectUser because ID provided does not match any found user`);
        }
        this.userOptions = [this.mapUserToSelectOption(user)];
    }
    async onSearchTermUpdate(newVal) {
        if (newVal && newVal !== this.value) {
            this.isLoading = true;
            await this.search(newVal);
            this.isLoading = false;
        }
    }
    onSelectedUser(val) {
        return val ?? null;
    }
    mapUserToSelectOption(user) {
        const option = {
            auid: user?.auid || '',
            displayText: this.$contactDisplay(user),
            value: user?.id,
            firstName: user?.firstName || '',
            lastName: user?.lastName || '',
            email: user.email || [],
            emailDisplay: user.email?.[0] || 'No Email'
        };
        return option;
    }
};
__decorate([
    Prop({
        default: 'User',
        type: String
    }),
    __metadata("design:type", String)
], AppSelectUser.prototype, "label", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], AppSelectUser.prototype, "required", void 0);
__decorate([
    Prop({
        type: String,
        default: ''
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], AppSelectUser.prototype, "value", void 0);
__decorate([
    Debounce(300),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", Promise)
], AppSelectUser.prototype, "search", null);
__decorate([
    Watch('searchTerm'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", Promise)
], AppSelectUser.prototype, "onSearchTermUpdate", null);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], AppSelectUser.prototype, "onSelectedUser", null);
AppSelectUser = __decorate([
    Component
], AppSelectUser);
export default AppSelectUser;
