var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('c-radio-group', _vm._b({
    attrs: {
      "data-appboolean": _vm.label,
      "data-cosmic-input": _vm.label,
      "disabled": _vm.disabled,
      "error-message": _vm.computedErrorMessage,
      "label": _vm.label,
      "message": _vm.computedMessage,
      "required": _vm.required
    },
    domProps: {
      "cValue": _vm.selectedText,
      "horizontal": _vm.horizontal
    },
    on: {
      "c-input": function ($event) {
        return _vm.emitInput($event.detail);
      }
    }
  }, 'c-radio-group', _vm.$props, false), [_vm.$scopedSlots['message'] ? _c('div', {
    attrs: {
      "slot": "message"
    },
    slot: "message"
  }, [_vm._t("message")], 2) : _vm._e(), _c('c-radio', {
    domProps: {
      "cValue": true
    }
  }, [_vm._v(" " + _vm._s(_vm.trueText) + " ")]), _c('c-radio', {
    domProps: {
      "cValue": false
    }
  }, [_vm._v(" " + _vm._s(_vm.falseText) + " ")]), _c('c-radio', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.hideUnknown,
      expression: "!hideUnknown"
    }],
    domProps: {
      "cValue": null
    }
  }, [_vm._v(" " + _vm._s(_vm.unknownText) + " ")])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };