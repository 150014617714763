var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "d-inline-block",
    on: {
      "mouseover": function ($event) {
        _vm.isDisabled = false;
      },
      "mouseleave": function ($event) {
        _vm.isLocked ? _vm.isDisabled = true : _vm.isDisabled = false;
      }
    }
  }, [_c('ButtonGeneric', {
    attrs: {
      "color": _vm.computedColor,
      "icon": _vm.lockButtonIcon,
      "disabled": _vm.isDisabled || _vm.disabled,
      "text-color": _vm.computedTextColor
    },
    on: {
      "click": _vm.onClick
    }
  }, [_vm.$slots.default ? _vm._t("default") : _c('span', [_vm._v(_vm._s(_vm.buttonText))])], 2)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };