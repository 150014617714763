var _a, _b, _c;
import { __decorate, __metadata } from "tslib";
import { Debounce } from 'lodash-decorators/debounce';
import { Component, Emit, Prop } from 'vue-property-decorator';
import { UserRole } from '~/db_types';
import BaseSearch from '~/nasa_ui/base/BaseSearch';
import { BASE_TEXT_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import { EntityType, Maybe } from '~/nasa_ui/types';
import { UserRoleDisplay } from '~/nasa_ui/types/enums/user';
import { composeTableHeader } from '~/nasa_ui/utils/helpers/composeTableHeader';
import { isNullOrUndefined } from '~/nasa_ui/utils/helpers/isNullOrUndefined';
import { UserResponseDisplay } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
/**
 * AVAILABLE PROPS:
 *  autofocus - false - should focus onto the search
 *  autosearch - false - should this fire the search query right away?
 *  filters - object containing prefilters you want applied to the search
 *  keep-search-results-open - false - after selection keep the results open
 *  existing-values - nodeId[] - this is for filtering out any already selected entities (not for normal use)
 *  locked-filters - string[] - values are keys of filters that you want to not be changed
 */
let SearchUser = class SearchUser extends BaseSearch {
    entityType = EntityType.USER;
    hint = 'name, email, auid';
    placeholder = 'search for users';
    UserRole = UserRole;
    UserRoleDisplay = UserRoleDisplay;
    userSelectedMaxResults = 500;
    rawSearchResponse = null;
    shouldDisableSearch;
    supportsCurrentContext;
    searchFilters = {
        auid: null,
        email: null,
        query: '',
        role: [UserRole.ADMIN, UserRole.USER]
    };
    get chipsFromFilters() {
        if (!this.pureSearchFilters || Object.keys(this.pureSearchFilters).length === 0) {
            return [];
        }
        let appChips = [];
        appChips = appChips.concat(this.buildAppChipForProperty('auid', 'users'), this.buildAppChipForProperty('role', 'users', UserRoleDisplay), this.buildAppChipForProperty('email', 'users'), this.buildAppChipForProperty('organizationCode', 'organizations'));
        return appChips.filter((key) => key !== undefined);
    }
    get computedTableHeaders() {
        return this.currentUserIsAdmin || this.currentUserIsSystem
            ? [
                composeTableHeader(BASE_TEXT_COL, {
                    text: 'Last name',
                    value: '_lastName'
                }),
                composeTableHeader(BASE_TEXT_COL, {
                    text: 'First name',
                    value: '_firstName'
                }),
                composeTableHeader(BASE_TEXT_COL, {
                    text: 'Supports',
                    value: '_supports'
                }),
                composeTableHeader(BASE_TEXT_COL, {
                    text: 'AUID',
                    value: '_auid'
                }),
                composeTableHeader(BASE_TEXT_COL, {
                    text: 'Email',
                    value: '_email',
                    noEscape: true
                })
            ]
            : [
                composeTableHeader(BASE_TEXT_COL, {
                    text: 'Last name',
                    value: '_lastName'
                }),
                composeTableHeader(BASE_TEXT_COL, {
                    text: 'First name',
                    value: '_firstName'
                }),
                composeTableHeader(BASE_TEXT_COL, {
                    text: 'AUID',
                    value: '_auid'
                }),
                composeTableHeader(BASE_TEXT_COL, {
                    text: 'Email',
                    value: '_email',
                    noEscape: true
                })
            ];
    }
    get queryVariables() {
        const base = {
            firstResults: this.userSelectedMaxResults,
            sameContext: !this.currentUserIsAdmin,
            supportsCurrentContext: this.supportsCurrentContext
        };
        const query = {};
        if (this.searchInput?.trim().length > 0) {
            query.query = this.searchInput;
        }
        const filters = this.pureSearchFilters;
        const variables = Object.assign({}, base, filters, query);
        return variables;
    }
    async performSearch() {
        try {
            this.isLoading = true;
            const params = {
                auid: this.queryVariables.auid || undefined,
                email: this.queryVariables.email || undefined,
                query: this.queryVariables.query || undefined,
                skip: 0,
                take: isNullOrUndefined(this.userSelectedMaxResults) ? -1 : this.userSelectedMaxResults,
                userRole: this.queryVariables.role || undefined
            };
            // axios scrubs undefined/null values from the params so we have to manually alter the url
            let url = `/users`;
            if (this.supportsCurrentContext === undefined || this.supportsCurrentContext === null) {
                url = `/users?supportsCurrentContext`;
            }
            else {
                params.supportsCurrentContext = this.supportsCurrentContext;
            }
            const resp = await this.$http.get(url, {
                params
            });
            this.isLoading = false;
            this.rawSearchResponse = resp.data.results;
            this.emitSearchPerformed();
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
    }
    onClickOfSearchResult(selectedItem) {
        this.reset();
        return this.isOutputModeString ? selectedItem.id : selectedItem;
    }
    onMetaClickOfSearchResult(selectedItem) {
        return this.isOutputModeString ? selectedItem.id : selectedItem;
    }
};
__decorate([
    Prop({
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], SearchUser.prototype, "shouldDisableSearch", void 0);
__decorate([
    Prop({
        type: Boolean || null,
        default: true
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], SearchUser.prototype, "supportsCurrentContext", void 0);
__decorate([
    Debounce(200),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], SearchUser.prototype, "performSearch", null);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof UserResponseDisplay !== "undefined" && UserResponseDisplay) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], SearchUser.prototype, "onClickOfSearchResult", null);
__decorate([
    Emit('metaRowClicked'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_c = typeof UserResponseDisplay !== "undefined" && UserResponseDisplay) === "function" ? _c : Object]),
    __metadata("design:returntype", void 0)
], SearchUser.prototype, "onMetaClickOfSearchResult", null);
SearchUser = __decorate([
    Component
], SearchUser);
export default SearchUser;
