var _a, _b, _c;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { AttachmentType } from '~/db_types';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import { AttachmentRepoMixin } from '~/nasa_ui/DAL/attachments';
import ReadOnlyMixin from '~/nasa_ui/mixins/ReadOnlyMixin';
import Log from '~/nasa_ui/modules/Log';
import { AlertType, Maybe } from '~/nasa_ui/types';
import { AttachmentTypeDisplay } from '~/nasa_ui/types/enums/attachments';
import { AttachmentsDisplay } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let AppFileAttachmentForm = class AppFileAttachmentForm extends Mixins(BaseCosmic, AttachmentRepoMixin, ReadOnlyMixin) {
    AttachmentType = AttachmentType;
    AttachmentTypeDisplay = AttachmentTypeDisplay;
    isFetching = false;
    isImage = false;
    isPdf = false;
    isVideo = false;
    showNoPreviewMessage = false;
    localValue = {
        description: '',
        file: null,
        type: AttachmentType.GENERAL
    };
    attachment;
    hideUpload;
    isEdit;
    value;
    get fileContentType() {
        return this.attachment?.contentType;
    }
    get fileKeyHref() {
        return this.attachment?._downloadKey;
    }
    get shouldShowClearFileButton() {
        return !this.shouldShowDropzone && !this.isReadOnly && !this.isEdit;
    }
    get shouldShowDropzone() {
        return !this.localValue?.file && !this.isReadOnly && !this.isFetching && !this.hideUpload;
    }
    clearSelectedFile() {
        if (!this.localValue?.file) {
            return;
        }
        this.localValue.file = null;
    }
    created() {
        this.localValue = { ...this.value };
    }
    onClickOfDropzone() {
        const fileUpload = this.$refs['file_upload'];
        fileUpload.onClickOfFileSelect();
    }
    // called from ui
    onFileDropped(file) {
        if (!this.localValue) {
            return;
        }
        this.localValue.file = file;
    }
    onFileToLarge() {
        return this.$notification.add({ text: 'Max file size exceeded', type: AlertType.ERROR });
    }
    onChange() {
        return this.localValue;
    }
    onFileChange(file) {
        this.isImage = false;
        this.isPdf = false;
        this.isVideo = false;
        this.showNoPreviewMessage = false;
        if (!file) {
            this.showNoPreviewMessage = false;
            return;
        }
        // Preview for imgs
        if (file?.type.startsWith('image/')) {
            this.isImage = true;
            const $img_preview = document.getElementById('img_preview');
            if ($img_preview) {
                $img_preview.src = URL.createObjectURL(file);
                $img_preview.onload = function () {
                    URL.revokeObjectURL($img_preview.src); // free memory
                };
            }
        }
        // Preview for vids
        else if (file?.type.startsWith('video/')) {
            this.isVideo = true;
            const $video_preview_source = document.getElementById('video_preview_source');
            if ($video_preview_source) {
                $video_preview_source.src = URL.createObjectURL(file);
                $video_preview_source.type = file.type;
                const $parent = document.getElementById('video_preview_player');
                $parent.load();
                $parent.play();
                $video_preview_source.onload = function () {
                    URL.revokeObjectURL($video_preview_source.src); // free memory
                };
            }
        }
        // Preview for pdfs
        else if (file?.type.includes('pdf')) {
            this.isPdf = true;
            const $pdf_preview = document.getElementById('pdf_preview');
            if ($pdf_preview) {
                $pdf_preview.src = URL.createObjectURL(file);
                $pdf_preview.onload = function () {
                    URL.revokeObjectURL($pdf_preview.src); // free memory
                };
            }
        }
        else {
            this.showNoPreviewMessage = true;
        }
    }
    async onFileKeyChange(attachment) {
        try {
            if (!attachment) {
                return;
            }
            this.isFetching = true;
            const file = await this.getAttachmentDownload(attachment);
            if (this.localValue) {
                this.localValue.file = file;
            }
        }
        catch (err) {
            this.$errorUtility({ err });
        }
        this.isFetching = false;
    }
    onValueChange(value) {
        if (JSON.stringify(value) === JSON.stringify(this.localValue)) {
            return;
        }
        this.localValue = { ...value };
    }
    logFileView(data) {
        new Log().telemetry(data);
    }
};
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof AttachmentsDisplay !== "undefined" && AttachmentsDisplay) === "function" ? _a : Object)
], AppFileAttachmentForm.prototype, "attachment", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], AppFileAttachmentForm.prototype, "hideUpload", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], AppFileAttachmentForm.prototype, "isEdit", void 0);
__decorate([
    Prop({
        required: true,
        type: Object
    }),
    __metadata("design:type", Object)
], AppFileAttachmentForm.prototype, "value", void 0);
__decorate([
    Watch('localValue', { deep: true }),
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppFileAttachmentForm.prototype, "onChange", null);
__decorate([
    Watch('localValue.file'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof File !== "undefined" && File) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], AppFileAttachmentForm.prototype, "onFileChange", null);
__decorate([
    Watch('attachment', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_c = typeof Maybe !== "undefined" && Maybe) === "function" ? _c : Object]),
    __metadata("design:returntype", Promise)
], AppFileAttachmentForm.prototype, "onFileKeyChange", null);
__decorate([
    Watch('value'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], AppFileAttachmentForm.prototype, "onValueChange", null);
AppFileAttachmentForm = __decorate([
    Component
], AppFileAttachmentForm);
export default AppFileAttachmentForm;
