var _a;
import { __decorate, __metadata } from "tslib";
import Vue from 'vue';
import { Component, Emit, Prop } from 'vue-property-decorator';
import { IAppAuthorityData } from '~/nasa_ui/components/AppAuthorityDisplay/AppAuthorityDisplay';
let AppActivityAuthority = class AppActivityAuthority extends Vue {
    showAuthorizationModal = false;
    authorityData;
    id;
    readOnly;
    get hasAuthorityData() {
        return Boolean(this.authorityData.authorityCode && this.authorityData.authorityNumber);
    }
    get isReadOnly() {
        return this.readOnly;
    }
    emitEntityEdited() { }
};
__decorate([
    Prop({
        type: Object,
        default: {
            authorityCode: null,
            authorityNumber: null,
            comments: null
        }
    }),
    __metadata("design:type", typeof (_a = typeof IAppAuthorityData !== "undefined" && IAppAuthorityData) === "function" ? _a : Object)
], AppActivityAuthority.prototype, "authorityData", void 0);
__decorate([
    Prop({ type: String }),
    __metadata("design:type", Object)
], AppActivityAuthority.prototype, "id", void 0);
__decorate([
    Prop({
        default: true,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], AppActivityAuthority.prototype, "readOnly", void 0);
__decorate([
    Emit('entityEdited'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppActivityAuthority.prototype, "emitEntityEdited", null);
AppActivityAuthority = __decorate([
    Component
], AppActivityAuthority);
export default AppActivityAuthority;
