var _a;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { DocumentType, HardwareListType } from '~/db_types';
import BaseTab from '~/nasa_ui/base/BaseTab';
import { NOT_APPLICABLE } from '~/nasa_ui/constants/display';
import { HardwareListRepo } from '~/nasa_ui/DAL/hardwareList';
import { AlertType, HardwareListTypeDisplay, Maybe } from '~/nasa_ui/types';
import { CosmicManagedDocumentType } from '~/nasa_ui/types/enums/document';
import { UserPermission } from '~/nasa_ui/types/enums/user';
import { isBeforeInclusive } from '~/nasa_ui/utils/dates/isBeforeInclusive';
import { asBuiltNumberDisplay, partNumberDisplay } from '~/nasa_ui/utils/helpers/displays';
import { sortObjectNumericallyBy } from '~/nasa_ui/utils/helpers/sortObjectNumericallyBy';
import { transformHardwareItems, transformHardwareListTemplates } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let TabHardwareListInformation = class TabHardwareListInformation extends Mixins(BaseTab, HardwareListRepo) {
    asBuiltNumberDisplay = asBuiltNumberDisplay;
    CosmicManagedDocumentType = CosmicManagedDocumentType;
    duplicateQueryResponse = null;
    HardwareListType = HardwareListType;
    HardwareListTypeDisplay = HardwareListTypeDisplay;
    isBeforeTodayInclusive = (date) => {
        return isBeforeInclusive(new Date(date), new Date());
    };
    isSaving = false;
    NOT_APPLICABLE = NOT_APPLICABLE;
    partNumberDisplay = partNumberDisplay;
    selectedEntity = null;
    selectedEventId = null;
    selectedHardwareItem = null;
    selectedTemplate = null;
    selectedTemplateSlot = null;
    showActualizationModal = false;
    showAssignTemplateModal = false;
    showAssignToEventModal = false;
    showCreateHardwareListItemModal = false;
    showDeleteConfirmationModal = false;
    showEditHardwareListTemplateSlotModal = false;
    showHardwareListItemEditModal = false;
    showHardwareListTemplateWithList = false;
    showHardwareListItemFillModal = false;
    UserPermission = UserPermission;
    formData = {
        addendumNumber: null,
        addendumDate: null,
        authorizer: null,
        name: null,
        number: null,
        revision: null,
        subType: null
    };
    get duplicateAlertType() {
        return this.isDuplicate ? AlertType.ERROR : AlertType.INFO;
    }
    get childrenToTheSelectedHardwareList() {
        return this.selectedEntity?.hardwareListAssemblies?.nodes || [];
    }
    get computedNextSequenceNumber() {
        if (!this.childrenToTheSelectedHardwareList.length) {
            return 0;
        }
        return ((this.childrenToTheSelectedHardwareList.sort(sortObjectNumericallyBy('sequence', true))[0]?.sequence ?? 0) + 1);
    }
    // currently assigned event, not to be confused with the one currently
    // selected by the SelectorEvent
    get eventId() {
        return this.selectedEntity?.event?.id ?? null;
    }
    get hardwareListHasEvent() {
        return Boolean(this.eventId);
    }
    get hardwareListHasTemplate() {
        return Boolean(this.hardwareListTemplateId);
    }
    get hardwareListTemplateId() {
        return this.selectedEntity?.hardwareListTemplateId ?? null;
    }
    get hasHardwareListTemplateAdminPermission() {
        return this.currentUserHasPermission(UserPermission.HARDWARE_LIST_TEMPLATE_ADMIN);
    }
    get isCosmicDocumentHardwareList() {
        const cDocumentSubTypes = [
            DocumentType.C_DISCREPANCY_REPORT,
            DocumentType.C_EASY_TRANSFER,
            DocumentType.C_FAILURE_REPORTING_ANALYSIS_CORRECTIVE_ACTION_SYSTEM,
            DocumentType.C_RCA,
            DocumentType.C_TPS,
            DocumentType.C_WORKFLOW
        ];
        return this.selectedEntity?.document?.subType
            ? cDocumentSubTypes.includes(this.selectedEntity.document.subType)
            : false;
    }
    get isDuplicate() {
        return this.duplicateQueryResponse?.totalCount === 1;
    }
    get isFormValid() {
        return Boolean(this.formData.number && this.formData.subType);
    }
    get shouldDisableAddToHardwareList() {
        return (!this.currentUserHasPermission(UserPermission.HARDWARE_LIST_ADMIN) ||
            !this.currentUserHasPermission(UserPermission.ASSEMBLY_TEMPLATE_ADMIN));
    }
    get selectedListItemInventoryNodeId() {
        return this.selectedHardwareItem ? this.findInventoryNodeIdFromItem(this.selectedHardwareItem) : null;
    }
    // because the SelectorEvent takes an array of existing values
    get selectedEventIdArray() {
        return this.selectedEventId ? [this.selectedEventId] : [];
    }
    get shouldShowEventSaveButtonWarning() {
        return Boolean(this.hardwareListHasEvent && !this.selectedEventId);
    }
    get templateItems() {
        if (!this.selectedEntity?.hardwareListTemplate?.templateItems.nodes.length) {
            return [];
        }
        return this.selectedEntity.hardwareListTemplate.templateItems.nodes;
    }
    get transformedHardwareItems() {
        return transformHardwareItems(this.childrenToTheSelectedHardwareList);
    }
    created() {
        this.$listen(`reload_${this.$route.params.id}`, () => this.fetchEntity(this.$route.params.id));
    }
    findInventoryNodeIdFromItem(item) {
        return item?.inventory?.nodeId ?? null;
    }
    onClickOfActualization(listItem) {
        // this.showActualizationModal = true;
        // this.selectedHardwareItem = listItem;
        this.$notification.add({
            type: AlertType.WARNING,
            text: 'Feature coming soon'
        });
    }
    onDeleted() {
        this.$router.push(`/hardware/lists/search`);
    }
    // called from ui
    async onClickOfAsignToEventSaveButton() {
        if (!this.selectedEntity) {
            return;
        }
        try {
            const eventId = this.selectedEventId ?? null;
            const patch = { eventId: eventId };
            const resp = await this.updateHardwareListMutation(patch);
            this.showAssignToEventModal = false;
            if (resp?.data?.updateHardwareListById) {
                const message = patch.event
                    ? 'Assigned Event to this Hardware List.'
                    : 'Removed Event from this Hardware List.';
                this.$notification.add({ text: message, type: AlertType.SUCCESS });
                this.fetchEntity(this.selectedEntity.id);
            }
        }
        catch (err) {
            this.showAssignToEventModal = false;
            this.$errorUtility({
                err,
                backupErrorText: 'Failed to assign Event to this Hardware List.'
            });
        }
    }
    async onClickOfAssignTemplateSaveButton(selectedTemplate) {
        if (!this.selectedEntity) {
            return;
        }
        const hardwareListTemplateId = selectedTemplate?.hardwareListTemplate
            ? selectedTemplate.hardwareListTemplate.id
            : null;
        try {
            const patch = { hardwareListTemplateId };
            // get from parent
            const resp = await this.updateHardwareListMutation(patch);
            if (resp?.data) {
                const message = patch.hardwareListTemplateId
                    ? 'Assigned Template to this Hardware List.'
                    : 'Removed Template from this Hardware List.';
                this.$notification.add({ text: message, type: AlertType.SUCCESS });
                // get from parent
                this.fetchEntity(this.selectedEntity.id);
                // force update hardware list mini
                this.$message('reload_mini');
            }
        }
        catch (err) {
            this.$errorUtility({
                err,
                backupErrorText: 'Failed to assign Hardware List Template.'
            });
        }
    }
    onClickOfListItemToEdit(listItem) {
        this.showHardwareListItemEditModal = true;
        this.selectedHardwareItem = listItem;
    }
    onClickOfListItemToDelete(listItem) {
        this.showDeleteConfirmationModal = true;
        this.selectedHardwareItem = listItem;
    }
    onClickOfTemplateSlotToEdit(listItem) {
        this.showEditHardwareListTemplateSlotModal = true;
        this.selectedTemplateSlot = listItem.templateItem ?? null;
    }
    onClickOfTemplateSlotToFill(listItem) {
        this.showHardwareListItemFillModal = true;
        this.selectedTemplateSlot = listItem.templateItem ?? null;
    }
    onCloseOfAssignTemplateModal() {
        this.showAssignTemplateModal = false;
        // revert the selected template to the currently attached template or null
        if (this.hardwareListHasTemplate && this.selectedEntity?.hardwareListTemplate) {
            this.selectedTemplate = transformHardwareListTemplates([this.selectedEntity.hardwareListTemplate])[0];
        }
        else {
            this.selectedTemplate = null;
        }
    }
    onEntityEditted() {
        this.$notification.add({
            type: AlertType.SUCCESS,
            text: 'Hardware item editted.'
        });
        this.reset();
    }
    // called from ui
    onSelectorEventInput(val) {
        this.selectedEventId = val?.length ? val[0] : null;
    }
    reset() {
        this.selectedEventId = null;
        this.selectedHardwareItem = null;
        this.selectedTemplate = null;
        this.selectedTemplateSlot = null;
        this.showActualizationModal = false;
        this.showAssignTemplateModal = false;
        this.showAssignToEventModal = false;
        this.showCreateHardwareListItemModal = false;
        this.showDeleteConfirmationModal = false;
        this.showEditHardwareListTemplateSlotModal = false;
        this.showHardwareListItemEditModal = false;
        this.showHardwareListItemFillModal = false;
        if (this.selectedEntity) {
            this.fetchEntity(this.selectedEntity.id);
            this.$message(`reload_${this.selectedEntity.id}`);
        }
    }
    async fetchEntity(hardwareListId) {
        if (!hardwareListId) {
            return;
        }
        try {
            this.isFetching = true;
            const resp = await this.getHardwareListsById({
                id: hardwareListId
            });
            this.isFetching = false;
            this.selectedEntity = resp?.hardwareListById;
            if (!resp?.hardwareListById?.hardwareListTemplate) {
                this.selectedTemplate = null;
            }
            else {
                this.selectedTemplate = transformHardwareListTemplates([resp.hardwareListById.hardwareListTemplate])[0];
            }
            this.selectedEventId = resp?.hardwareListById?.event?.id ?? null;
        }
        catch (err) {
            this.$errorUtility({
                err,
                backupErrorText: 'Could not fetch the Hardware List Template'
            });
            this.isFetching = false;
        }
    }
    seedFormData() {
        if (!this.selectedEntity) {
            return;
        }
        this.formData.addendumDate = this.selectedEntity.addendumDate;
        this.formData.addendumNumber = this.selectedEntity.addendumNumber;
        this.formData.authorizer = this.selectedEntity.authorizer;
        this.formData.name = this.selectedEntity.name;
        this.formData.number = this.selectedEntity.number;
        this.formData.revision = this.selectedEntity.revision;
        this.formData.subType = this.selectedEntity.subType;
    }
    async onClickOfSaveHardwareList() {
        if (!this.isFormValid) {
            return;
        }
        try {
            await this.updateHardwareListMutation(this.formData);
            this.$notification.add({
                text: 'Save successful.',
                type: AlertType.SUCCESS
            });
            this.reset();
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    updateHardwareListMutation(patch) {
        if (!this.selectedEntity || !Object.keys(patch).length) {
            return null;
        }
        return this.$apollo.mutate({
            mutation: gql `
        mutation UpdateHardwareListOnTabHardwareListInformation($id: UUID!, $patch: HardwareListPatch!) {
          updateHardwareListById(input: { id: $id, patch: $patch }) {
            clientMutationId
          }
        }
      `,
            variables: {
                id: this.selectedEntity.id,
                patch
            }
        });
    }
};
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", Promise)
], TabHardwareListInformation.prototype, "fetchEntity", null);
__decorate([
    Watch('selectedEntity'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], TabHardwareListInformation.prototype, "seedFormData", null);
TabHardwareListInformation = __decorate([
    Component
], TabHardwareListInformation);
export default TabHardwareListInformation;
