var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Maybe } from '~/nasa_ui/types';
let WorkOrderStepEditPanel = class WorkOrderStepEditPanel extends Vue {
    icon;
    iconColor;
    header;
    get boldHeader() {
        // give bold header if the input in the default slot has a value
        const [comp] = this.$slots.default;
        // checking nested elements for v-model
        const model = comp?.data?.['model'] || comp?.children?.find((element) => element.data?.['model']);
        return model?.value || model?.data?.model?.value;
    }
};
__decorate([
    Prop({
        required: false,
        type: String
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], WorkOrderStepEditPanel.prototype, "icon", void 0);
__decorate([
    Prop({
        required: false,
        type: String,
        default: 'var(--v-info-base)'
    }),
    __metadata("design:type", String)
], WorkOrderStepEditPanel.prototype, "iconColor", void 0);
__decorate([
    Prop({
        required: true,
        type: String
    }),
    __metadata("design:type", String)
], WorkOrderStepEditPanel.prototype, "header", void 0);
WorkOrderStepEditPanel = __decorate([
    Component
], WorkOrderStepEditPanel);
export default WorkOrderStepEditPanel;
