import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { HardwareListType } from '~/db_types/swagger_types';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { HardwareListTypeDisplay } from '~/nasa_ui/types';
import { sortObjectBy } from '~/nasa_ui/utils';
let ModalHardwareListCreate = class ModalHardwareListCreate extends Mixins(BaseModal, BaseCosmic) {
    enforceNamingStandard = false;
    foundDuplicateList = false;
    selectedHardwareListTemplate = null;
    eventId;
    hardwareListTemplateId;
    isCritical;
    formData = {
        addendumDate: null,
        addendumNumber: null,
        assemblies: null,
        authorizer: null,
        closeDate: null,
        documentId: null,
        eventId: null,
        hardwareListTemplateId: null,
        isCritical: false,
        name: null,
        number: '',
        openDate: null,
        revision: null,
        subType: HardwareListType.HARDWARE
    };
    get isFormValid() {
        return Boolean(this.formData.number);
    }
    get computedHardwareListNumber() {
        if (!this.formData.number) {
            return '';
        }
        return this.enforceNamingStandard ? `${this.numberPrefix}${this.formData.number}` : this.formData.number;
    }
    get createHardwareListVariables() {
        if (!this.isFormValid) {
            return null;
        }
        return {
            ...this.formData,
            number: this.computedHardwareListNumber
        };
    }
    get filteredHardwareListTypes() {
        const cDocumentsHardwareListTypes = [
            'WORK_ORDER_STEP_HARDWARE',
            'WORK_ORDER_STEP_TOOLS',
            'COSMIC_DOCUMENT_HARDWARE',
            'COSMIC_DOCUMENT_TOOLS'
        ];
        return Object.values(HardwareListType)
            .map((value) => {
            return {
                displayText: HardwareListTypeDisplay.get(value) || '',
                value: value,
                disabled: false
            };
        })
            .filter((mappedValueForAppSelect) => {
            return !Object.values(cDocumentsHardwareListTypes).includes(mappedValueForAppSelect.value);
        })
            .sort(sortObjectBy('displayText'));
    }
    get hardwareListNumberIsUniqueErrorMessage() {
        return this.foundDuplicateList ? 'Number in use.' : '';
    }
    get numberPrefix() {
        const year = new Date().getFullYear().toString().substring(2); // YY format
        return this.enforceNamingStandard ? `HL-${year}-` : '';
    }
    async onClickOfCreate() {
        if (!this.createHardwareListVariables) {
            return;
        }
        try {
            this.isSaving = true;
            const resp = await this.$http.post(`/hardwarelist`, this.createHardwareListVariables);
            if (resp) {
                this.emitEntityCreated('List created.');
                this.emitModalClose();
            }
            this.isSaving = false;
        }
        catch (err) {
            this.$errorUtility({ err });
            this.isSaving = false;
        }
    }
    onEventIdChange() {
        if (this.eventId) {
            this.formData.eventId = this.eventId;
        }
    }
    async onNumberChange() {
        if (!this.formData.number || !this.formData.subType) {
            return;
        }
        try {
            const params = {
                query: this.computedHardwareListNumber,
                subType: [this.formData.subType],
                take: 1
            };
            const resp = await this.$http.get(`/hardwarelist`, {
                params
            });
            this.foundDuplicateList = resp.data.totalCount > 0;
        }
        catch (err) {
            this.$errorUtility({
                err
            });
        }
    }
    async fetchHardwareListTemplate() {
        if (!this.hardwareListTemplateId) {
            return;
        }
        try {
            const resp = await this.$apollo.query({
                query: gql `
          query SelectHltForListCreateModal($id: UUID!) {
            hardwareListTemplateById(id: $id) {
              id
              name
              subType
            }
          }
        `,
                variables: {
                    id: this.hardwareListTemplateId
                }
            });
            this.selectedHardwareListTemplate = resp.data.hardwareListTemplateById;
        }
        catch (err) {
            this.$errorUtility({
                err
            });
        }
    }
    onIsCriticalChange() {
        this.formData.isCritical = this.isCritical;
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], ModalHardwareListCreate.prototype, "eventId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], ModalHardwareListCreate.prototype, "hardwareListTemplateId", void 0);
__decorate([
    Prop({
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], ModalHardwareListCreate.prototype, "isCritical", void 0);
__decorate([
    Watch('eventId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalHardwareListCreate.prototype, "onEventIdChange", null);
__decorate([
    Watch('formData.number', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], ModalHardwareListCreate.prototype, "onNumberChange", null);
__decorate([
    Watch('hardwareListTemplateId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], ModalHardwareListCreate.prototype, "fetchHardwareListTemplate", null);
__decorate([
    Watch('isCritical', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalHardwareListCreate.prototype, "onIsCriticalChange", null);
ModalHardwareListCreate = __decorate([
    Component
], ModalHardwareListCreate);
export default ModalHardwareListCreate;
