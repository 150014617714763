import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';
import ReadOnlyMixin from '~/nasa_ui/mixins/ReadOnlyMixin';
import { isNullOrUndefined } from '~/nasa_ui/utils';
let WorkOrderStepsActionBar = class WorkOrderStepsActionBar extends Mixins(ReadOnlyMixin) {
    value;
    steps;
    hideStepper;
    get shouldDisableNextButton() {
        return this.value === this.steps.length - 1;
    }
    get shouldDisablePreviousButton() {
        return this.value === 0;
    }
    get currentViewingStep() {
        return this.steps[this.value];
    }
    get stepsStepNumberList() {
        return this.steps.map((step, index) => {
            const actionText = step?.action || 'No procedure text.';
            return {
                displayText: `${step?.stepNumber} - <small>${actionText}</small>`,
                value: index
            };
        });
    }
    onStepSelect(step) {
        if (isNullOrUndefined(step)) {
            return;
        }
        if (step !== this.value) {
            this.$emit('input', step);
        }
    }
    onClickOfPreviousStepButton() {
        return this.value - 1;
    }
    onClickOfNextStepButton() {
        return this.value + 1;
    }
};
__decorate([
    Prop({
        type: Number,
        default: 0
    }),
    __metadata("design:type", Number)
], WorkOrderStepsActionBar.prototype, "value", void 0);
__decorate([
    Prop({
        required: true,
        type: Array
    }),
    __metadata("design:type", Array)
], WorkOrderStepsActionBar.prototype, "steps", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], WorkOrderStepsActionBar.prototype, "hideStepper", void 0);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], WorkOrderStepsActionBar.prototype, "onClickOfPreviousStepButton", null);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], WorkOrderStepsActionBar.prototype, "onClickOfNextStepButton", null);
WorkOrderStepsActionBar = __decorate([
    Component
], WorkOrderStepsActionBar);
export default WorkOrderStepsActionBar;
