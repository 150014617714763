var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [!_vm.isReadOnly ? _c('v-flex', {
    attrs: {
      "xs12": "",
      "mb-2": ""
    }
  }, [_c('ButtonCreate', {
    attrs: {
      "disabled": _vm.shouldDisableAddToListButton
    },
    on: {
      "click": _vm.onClickOfHardwareListAssemblyCreate
    }
  }, [_vm._v("Add to list")])], 1) : _vm._e(), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [!_vm.tableItems.length ? _c('Alert', {
    attrs: {
      "type": "info"
    }
  }, [_vm._v(" No hardware items yet. ")]) : _vm._e()], 1), _vm.tableItems.length ? _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppTableActions', {
    attrs: {
      "color": _vm.EntityType.HARDWARE_LIST,
      "headers": _vm.computedTableHeaders,
      "items": _vm.tableItems,
      "rows-per-page-items": [-1],
      "hide-icon": "",
      "hide-pagination": "",
      "show-count": "",
      "sticky": "",
      "title": "List items"
    },
    on: {
      "dblclick": item => _vm.onClickOfHardwareListAssemblyToEdit(item)
    },
    scopedSlots: _vm._u([{
      key: "_hardwareTypeIcon",
      fn: function ({
        item
      }) {
        return [item.isTemplate ? _c('c-icon-fa', {
          attrs: {
            "tooltip": "Templated item",
            "tooltip-position": "right",
            "icon": _vm.$icons[_vm.EntityType.HARDWARE_LIST_TEMPLATE]
          }
        }) : item.isExternal ? _c('div', {
          attrs: {
            "tooltip": `List item is a non-${_vm.currentSystemName} item`,
            "tooltip-position": "right"
          }
        }, [_c('IconNotCosmic', {
          attrs: {
            "color": "black"
          }
        })], 1) : _c('c-icon-fa', {
          attrs: {
            "icon": _vm.$icons[_vm.EntityType.INVENTORY],
            "icon-color": "var(--v-inventories-base)",
            "tooltip": `List item is ${_vm.currentSystemName} inventory`,
            "tooltip-position": "right"
          }
        })];
      }
    }, {
      key: "_quantity",
      fn: function ({
        item
      }) {
        return [item.isTemplate ? _c('div', [_c('span', {
          staticClass: "monospace_font font-weight-bold"
        }, [_vm._v(" " + _vm._s(item.templateItem.minQuantity) + " ")]), _vm._v("/"), _c('span', {
          staticClass: "monospace_font font-weight-bold"
        }, [_vm._v(" " + _vm._s(item.templateItem.maxQuantity) + " ")])]) : _c('div', [_c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(" " + _vm._s(item._quantity) + " ")])])];
      }
    }, {
      key: "_computedDrawingNumber",
      fn: function ({
        item
      }) {
        return [item.isTemplate ? _c('div', [item.templateItem.parts.length ? _c('ul', _vm._l(item.templateItem.parts, function (part, index) {
          return _c('li', {
            key: index,
            staticClass: "my-1"
          }, [part.itemMaster ? _c('span', [_vm._v(_vm._s(_vm.partNumberDisplay(part.itemMaster)))]) : part.itemDrawing ? _c('span', [_vm._v(_vm._s(_vm.partNumberDisplay(part.itemDrawing)))]) : _c('span', [_vm._v("—")])]);
        }), 0) : _c('span', [_vm._v("No hardware selected.")])]) : _c('div', {
          directives: [{
            name: "safe-html",
            rawName: "v-safe-html",
            value: item._computedDrawingNumber,
            expression: "item._computedDrawingNumber"
          }]
        })];
      }
    }, {
      key: "_computedDescription",
      fn: function ({
        item
      }) {
        return [item.isTemplate ? _c('div', [item.templateItem.parts.length ? _c('ul', _vm._l(item.templateItem.parts, function (part, index) {
          return _c('li', {
            key: index,
            staticClass: "my-1"
          }, [part.itemDrawing ? _c('span', [_vm._v(_vm._s(part.itemDrawing.description))]) : _c('span', [_vm._v("—")])]);
        }), 0) : _c('span', [_vm._v("—")])]) : _c('div', {
          directives: [{
            name: "safe-html",
            rawName: "v-safe-html",
            value: item._computedDescription,
            expression: "item._computedDescription"
          }]
        })];
      }
    }, {
      key: "_computedAsBuiltNumber",
      fn: function ({
        item
      }) {
        return [item.isTemplate ? _c('div', [item.templateItem.parts.length ? _c('ul', _vm._l(item.templateItem.parts, function (part, index) {
          return _c('li', {
            key: index,
            staticClass: "my-1"
          }, [part.itemMaster ? _c('span', {
            staticClass: "monospace_font"
          }, [_vm._v(_vm._s(_vm.asBuiltNumberDisplay(part.itemMaster.asBuiltNumber)))]) : _c('span', {
            directives: [{
              name: "safe-html",
              rawName: "v-safe-html",
              value: _vm.NOT_APPLICABLE,
              expression: "NOT_APPLICABLE"
            }]
          })]);
        }), 0) : _c('span', [_vm._v("—")])]) : _c('div', {
          directives: [{
            name: "safe-html",
            rawName: "v-safe-html",
            value: item._computedAsBuiltNumber,
            expression: "item._computedAsBuiltNumber"
          }]
        })];
      }
    }, {
      key: "_computedSerialNumber",
      fn: function ({
        item
      }) {
        return [item.isTemplate ? _c('div', {
          directives: [{
            name: "safe-html",
            rawName: "v-safe-html",
            value: _vm.NOT_APPLICABLE,
            expression: "NOT_APPLICABLE"
          }]
        }) : _c('div', {
          directives: [{
            name: "safe-html",
            rawName: "v-safe-html",
            value: item._computedSerialNumber,
            expression: "item._computedSerialNumber"
          }]
        })];
      }
    }, {
      key: "_computedLotNumber",
      fn: function ({
        item
      }) {
        return [item.isTemplate ? _c('div', {
          directives: [{
            name: "safe-html",
            rawName: "v-safe-html",
            value: _vm.NOT_APPLICABLE,
            expression: "NOT_APPLICABLE"
          }]
        }) : _c('div', {
          directives: [{
            name: "safe-html",
            rawName: "v-safe-html",
            value: item._computedLotNumber,
            expression: "item._computedLotNumber"
          }]
        })];
      }
    }, {
      key: "_computedSubType",
      fn: function ({
        item
      }) {
        return [item.isTemplate ? _c('div', {
          directives: [{
            name: "safe-html",
            rawName: "v-safe-html",
            value: _vm.NOT_APPLICABLE,
            expression: "NOT_APPLICABLE"
          }]
        }) : _c('div', {
          directives: [{
            name: "safe-html",
            rawName: "v-safe-html",
            value: item._computedSubType,
            expression: "item._computedSubType"
          }]
        })];
      }
    }, {
      key: "_computedLocation",
      fn: function ({
        item
      }) {
        return [item.isTemplate ? _c('div', {
          directives: [{
            name: "safe-html",
            rawName: "v-safe-html",
            value: _vm.NOT_APPLICABLE,
            expression: "NOT_APPLICABLE"
          }]
        }) : _c('div', {
          directives: [{
            name: "safe-html",
            rawName: "v-safe-html",
            value: item._computedLocation,
            expression: "item._computedLocation"
          }]
        })];
      }
    }, {
      key: "_computedContractEndingItemNumber",
      fn: function ({
        item
      }) {
        return [item.isTemplate ? _c('div', {
          directives: [{
            name: "safe-html",
            rawName: "v-safe-html",
            value: _vm.NOT_APPLICABLE,
            expression: "NOT_APPLICABLE"
          }]
        }) : _c('div', {
          staticClass: "monospace_font"
        }, [_vm._v(" " + _vm._s(item._computedContractEndingItemNumber) + " ")])];
      }
    }, {
      key: "_computedBirthDate",
      fn: function ({
        item
      }) {
        return [item.isTemplate ? _c('div', {
          directives: [{
            name: "safe-html",
            rawName: "v-safe-html",
            value: _vm.NOT_APPLICABLE,
            expression: "NOT_APPLICABLE"
          }]
        }) : _c('div', {
          staticClass: "monospace_font"
        }, [_vm._v(" " + _vm._s(item._computedBirthDate) + " ")])];
      }
    }, {
      key: "_computedCalibrationNumber",
      fn: function ({
        item
      }) {
        return [item.isTemplate ? _c('div', {
          directives: [{
            name: "safe-html",
            rawName: "v-safe-html",
            value: _vm.NOT_APPLICABLE,
            expression: "NOT_APPLICABLE"
          }]
        }) : _c('div', [_vm._v(" " + _vm._s(item._computedCalibrationNumber) + " ")])];
      }
    }, {
      key: "_computedCalibrationDate",
      fn: function ({
        item
      }) {
        return [item.isTemplate ? _c('div', {
          directives: [{
            name: "safe-html",
            rawName: "v-safe-html",
            value: _vm.NOT_APPLICABLE,
            expression: "NOT_APPLICABLE"
          }]
        }) : _c('div', [_vm._v(" " + _vm._s(item._computedCalibrationDate) + " ")])];
      }
    }, {
      key: "_computedUsageExpiryDate",
      fn: function ({
        item
      }) {
        return [item.isTemplate ? _c('div', {
          directives: [{
            name: "safe-html",
            rawName: "v-safe-html",
            value: _vm.NOT_APPLICABLE,
            expression: "NOT_APPLICABLE"
          }]
        }) : _vm.isBeforeTodayInclusive(item._computedUsageExpiryDate) ? _c('strong', {
          staticClass: "date_wrap error--text"
        }, [_c('c-icon-fa', {
          staticClass: "pr-2",
          attrs: {
            "icon": _vm.$icons.exclamation
          }
        }), _c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(_vm._s(item._computedUsageExpiryDate))])], 1) : _c('div', [_vm._v(" " + _vm._s(item._computedUsageExpiryDate) + " ")])];
      }
    }, {
      key: "_addendumNumber",
      fn: function ({
        item
      }) {
        return [item.isTemplate ? _c('div', {
          directives: [{
            name: "safe-html",
            rawName: "v-safe-html",
            value: _vm.NOT_APPLICABLE,
            expression: "NOT_APPLICABLE"
          }]
        }) : _c('div', [_vm._v(" " + _vm._s(item._addendumNumber) + " ")])];
      }
    }, {
      key: "_addendumDate",
      fn: function ({
        item
      }) {
        return [item.isTemplate ? _c('div', {
          directives: [{
            name: "safe-html",
            rawName: "v-safe-html",
            value: _vm.NOT_APPLICABLE,
            expression: "NOT_APPLICABLE"
          }]
        }) : _c('div', [_vm._v(" " + _vm._s(item._addendumDate) + " ")])];
      }
    }, {
      key: "_notes",
      fn: function ({
        item
      }) {
        return [item.isTemplate ? _c('div', {
          directives: [{
            name: "safe-html",
            rawName: "v-safe-html",
            value: _vm.NOT_APPLICABLE,
            expression: "NOT_APPLICABLE"
          }]
        }) : _c('div', [_vm._v(" " + _vm._s(item._notes) + " ")])];
      }
    }, {
      key: "actions",
      fn: function ({
        item
      }) {
        return [item.isExternal && !_vm.isReadOnly && !item.isTemplate && !item.inventory ? _c('ButtonMiniAction', {
          attrs: {
            "icon": _vm.$icons.ACTUALIZATION,
            "icon-color": "var(--v-success-base)",
            "tooltip-position": "left",
            "tooltip": "Actualize this into a tracked hardware item"
          },
          on: {
            "click": function ($event) {
              return _vm.onClickOfActualization(item);
            }
          }
        }) : _vm._e(), !item.isExternal && !_vm.isReadOnly && !item.isTemplate ? _c('ButtonMiniAction', {
          attrs: {
            "icon": _vm.$icons.CRITICAL_INVENTORY_TRACKING,
            "icon-color": "var(--v-success-base)",
            "icon-size": "22",
            "tooltip-position": "left",
            "tooltip": "Add hardware to Critical Inventory tracking"
          },
          on: {
            "click": function ($event) {
              return _vm.onClickOfCopyToCriticalInventoryTracking(item);
            }
          }
        }) : _vm._e(), !_vm.isReadOnly && item.isTemplate && !item.isFilled && _vm.hasHardwareListTemplateAdminPermission ? _c('ButtonMiniAction', {
          attrs: {
            "icon": "fad fa-fill-drip",
            "icon-color": "var(--v-success-base)",
            "icon-size": "22",
            "tooltip-position": "left",
            "tooltip": "Fill this template slot with inventory data"
          },
          on: {
            "click": function ($event) {
              return _vm.onClickOfTemplateSlotToFill(item);
            }
          }
        }) : _vm._e(), !item.isTemplate ? _c('ButtonMiniActionInfo', {
          on: {
            "click": function ($event) {
              return _vm.onClickOfHardwareListAssemblyViewInfo(item);
            }
          }
        }) : _vm._e(), !_vm.isReadOnly && !item.isTemplate ? _c('ButtonMiniActionEdit', {
          on: {
            "click": function ($event) {
              return _vm.onClickOfHardwareListAssemblyToEdit(item);
            }
          }
        }) : _vm._e(), !_vm.isReadOnly && item.isTemplate ? _c('ButtonMiniActionEdit', {
          on: {
            "click": function ($event) {
              return _vm.onClickOfTemplateSlotToEdit(item);
            }
          }
        }) : _vm._e(), !_vm.isReadOnly && !item.isTemplate ? _c('ButtonMiniActionDelete', {
          on: {
            "click": function ($event) {
              return _vm.onClickOfHardwareListAssemblyToDelete(item);
            }
          }
        }) : _vm._e()];
      }
    }], null, false, 535755355)
  })], 1) : _vm._e(), _vm.showActualizationModal ? _c('ModalHardwareListAssemblyActualization', {
    attrs: {
      "hardware-list-assembly": _vm.selectedHardwareListAssembly
    },
    on: {
      "hardwareActualized": _vm.onHardwareListIdChange,
      "modal-close": _vm.reset
    }
  }) : _vm._e(), _vm.showCopyHardwareListAssemblyToCriticalInventoryModal ? _c('ModalHardwareListAssemblyCopyToCriticalInventory', {
    attrs: {
      "hardware-list-assembly": _vm.selectedHardwareListAssembly
    },
    on: {
      "modal-close": _vm.reset
    }
  }) : _vm._e(), _vm.showHardwareListAssemblyInfoModal ? _c('ModalHardwareListAssemblyInfo', {
    attrs: {
      "hardware-list-assembly": _vm.selectedHardwareListAssembly
    },
    on: {
      "modal-close": _vm.reset
    }
  }) : _vm._e(), _vm.showCreateHardwareListAssemblyModal ? _c('ModalHardwareListAssemblyCreate', {
    attrs: {
      "parent-hardware-list-id": _vm.hardwareListId,
      "show-additional-metadata": _vm.showAdditionalMetadata,
      "template-items": _vm.hardwareListTemplateSlots
    },
    on: {
      "entityCreated": _vm.onHardwareListIdChange,
      "modal-close": _vm.reset
    }
  }) : _vm._e(), _vm.showHardwareListAssemblyEditModal && _vm.selectedHardwareListAssembly ? _c('ModalHardwareListAssemblyEdit', {
    key: _vm.hardwareListId,
    attrs: {
      "parent-hardware-list-id": _vm.hardwareListId,
      "hardware-list-assembly": _vm.selectedHardwareListAssembly,
      "show-additional-metadata": _vm.showAdditionalMetadata,
      "template-items": _vm.hardwareListTemplateSlots
    },
    on: {
      "entityEdited": _vm.onHardwareListIdChange,
      "modal-close": _vm.reset
    }
  }) : _vm._e(), _vm.showDeleteConfirmationModal ? _c('AreYouSureDialog', {
    attrs: {
      "modal-width": "600"
    },
    on: {
      "affirmativeClicked": _vm.deleteHardwareListAssembly,
      "negativeClicked": _vm.reset
    }
  }, [_c('p', {
    attrs: {
      "slot": "text"
    },
    slot: "text"
  }, [_c('strong', [_vm._v("WARNING:")]), _vm._v(" This will PERMANENTLY delete this item. You may add more items at a later time. ")])]) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };