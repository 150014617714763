var _a, _b;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';
import { ChangeSetStatus, ChangeSetType, DocumentType } from '~/db_types';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import { ApprovalRoutingTemplateRepoMixin } from '~/nasa_ui/DAL/approvalRoutingTemplate';
import { ChangeSetsRepoMixin } from '~/nasa_ui/DAL/changeSet';
import { DocumentLineItemsRepoMixin } from '~/nasa_ui/DAL/document/LineItems';
import GenericDocumentMixin from '~/nasa_ui/mixins/GenericDocumentMixin';
import { Maybe, UserPermission } from '~/nasa_ui/types';
let InitializationStartStop = class InitializationStartStop extends Mixins(BaseCosmic, GenericDocumentMixin, ChangeSetsRepoMixin, DocumentLineItemsRepoMixin, ApprovalRoutingTemplateRepoMixin) {
    authorityOverrideGroups = [];
    isInitializing = false;
    isLoadingAuthorityRoutingTemplateGroups = false;
    isReopening = false;
    showReopenDialog = false;
    type;
    docId;
    hideStartStopButton;
    approvalRoutingTemplateName;
    isInitialized;
    isRejected;
    isOpenForEdits;
    isClosed;
    currentUserHasAccess;
    get documentTypeDisplay() {
        return this.DocumentTypeDisplay.get(this.type);
    }
    get isMemberOfAllInitializationAuthorities() {
        return this.authorityOverrideGroups.every((g) => this.isCurrentUserInGroup(g.groupCode));
    }
    get hasAdminPermission() {
        const adminGroup = this.getCorrespondingAdminGroup(this.type);
        return this.currentUserHasPermission(adminGroup);
    }
    get hasReopenPermissions() {
        // attempting to document this here
        // https://gitlab.com/mri-technologies/cosmic/nasa/docs/-/wikis/Permissions
        // please update when this changes
        if (this.type === DocumentType.C_WORKFLOW) {
            return this.currentUserHasPermission(UserPermission.C_DOCS_WORKFLOWS_ADMIN);
        }
        else {
            return true;
        }
    }
    get hasStartStopPermissions() {
        return (this.hasAdminPermission &&
            this.currentUserHasAccess &&
            !this.isLoadingAuthorityRoutingTemplateGroups &&
            this.authorityOverrideGroups.length &&
            this.isMemberOfAllInitializationAuthorities);
    }
    get showButton() {
        // Button can be hidden by explicit rules from the prop or by default rules defined in this component
        return !this.hideStartStopButton && this.hasStartStopPermissions;
    }
    async created() {
        this.authorityOverrideGroups = await this.getAuthorityOverridesForInitialization();
    }
    async onClickOfStartDocumentInitialization() {
        this.isInitializing = true;
        try {
            const resp = await this.createInitializationChangeSet();
            const changeSetAuthorityIds = resp?.data?.createChangeSet?.changeSet?.changeSetAuthorities?.nodes.map((a) => a?.id);
            if (!changeSetAuthorityIds?.length) {
                console.warn('No changeSetAuthorityId was returned after initialization. Unable to auto-approve');
                return;
            }
            await this.approveAllAuthorities(changeSetAuthorityIds);
            this.$notification.add({ text: `Document started`, type: this.AlertType.SUCCESS });
            this.emitSaved(true);
            this.$message('reload_user_alerts');
        }
        catch (err) {
            this.$errorUtility({ err });
        }
        this.isInitializing = false;
    }
    async onClickOfConfirmReopen() {
        this.isReopening = true;
        await this.reopenDocumentForEdits({ uuid: this.docId });
        this.isReopening = false;
        this.emitSaved(false);
        this.$message('reload_user_alerts');
    }
    emitSaved(isStarted) {
        return isStarted;
    }
    async createInitializationChangeSet() {
        if (!this.docId || !this.currentUserDelegateGroup?.code) {
            return;
        }
        const initializationChangeSetPayload = {
            authoritiesOverride: this.authorityOverrideGroups,
            openedDate: this.$dateDisplay(new Date()),
            subType: ChangeSetType.COSMIC_DOCUMENT_INITIALIZATION,
            documentId: this.docId
        };
        return this.$apollo.mutate({
            mutation: gql `
        mutation createStartStopInitializationChangeSet($input: CreateChangeSetInput!) {
          createChangeSet(input: $input) {
            changeSet {
              id
              nodeId
              subType
              computedStatus
              changeSetAuthorities {
                nodes {
                  id
                  nodeId
                }
              }
            }
          }
        }
      `,
            variables: {
                input: initializationChangeSetPayload
            }
        });
    }
    async approveAllAuthorities(changeSetAuthorityIds) {
        this.isSaving = true;
        for (let i = 0; i < changeSetAuthorityIds.length; i++) {
            const changeSetAuthorityId = changeSetAuthorityIds[i];
            const payload = {
                authorizedDate: this.$dateDisplay(new Date()),
                changeSetAuthorityId: changeSetAuthorityId,
                status: ChangeSetStatus.APPROVED
            };
            await this.updateChangeSetAuthority({ input: payload });
        }
        this.isSaving = false;
    }
    async getAuthorityOverridesForInitialization() {
        this.isLoadingAuthorityRoutingTemplateGroups = true;
        if (this.approvalRoutingTemplateName) {
            const data = this.getAuthorityOverridesFromApprovalRoutingTemplate(this.approvalRoutingTemplateName);
            this.isLoadingAuthorityRoutingTemplateGroups = false;
            return data;
        }
        const defaultOverride = [
            {
                groupCode: this.currentUserDelegateGroup.code,
                requiresQualityControlStamp: false
            }
        ];
        this.isLoadingAuthorityRoutingTemplateGroups = false;
        return Promise.resolve(defaultOverride);
    }
    async getAuthorityOverridesFromApprovalRoutingTemplate(templateName) {
        const data = await this.searchApprovalRoutingTemplates({ query: templateName, firstResults: 1 });
        const [template] = data?.approvalRoutingTemplates?.nodes ?? [];
        if (!template) {
            console.error(`Attempted to fetch Approval Routing Template by the name "${templateName}" but none were found`);
        }
        const overrides = template?.groupCodes?.map((code) => ({
            groupCode: code,
            requiresComment: false,
            requiresQualityControlStamp: false
        })) ?? [];
        return overrides;
    }
};
__decorate([
    Prop({
        required: true,
        type: String
    }),
    __metadata("design:type", typeof (_a = typeof DocumentType !== "undefined" && DocumentType) === "function" ? _a : Object)
], InitializationStartStop.prototype, "type", void 0);
__decorate([
    Prop({
        required: true,
        type: String
    }),
    __metadata("design:type", Object)
], InitializationStartStop.prototype, "docId", void 0);
__decorate([
    Prop({
        required: false,
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], InitializationStartStop.prototype, "hideStartStopButton", void 0);
__decorate([
    Prop({
        required: false,
        type: String,
        default: null
    }),
    __metadata("design:type", typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object)
], InitializationStartStop.prototype, "approvalRoutingTemplateName", void 0);
__decorate([
    Prop({
        required: true,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], InitializationStartStop.prototype, "isInitialized", void 0);
__decorate([
    Prop({
        required: true,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], InitializationStartStop.prototype, "isRejected", void 0);
__decorate([
    Prop({
        required: true,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], InitializationStartStop.prototype, "isOpenForEdits", void 0);
__decorate([
    Prop({
        required: true,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], InitializationStartStop.prototype, "isClosed", void 0);
__decorate([
    Prop({
        required: true,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], InitializationStartStop.prototype, "currentUserHasAccess", void 0);
__decorate([
    Emit('saved'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], InitializationStartStop.prototype, "emitSaved", null);
InitializationStartStop = __decorate([
    Component
], InitializationStartStop);
export default InitializationStartStop;
