var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "last-comment"
  }, [_vm.lastComment ? _c('AppExpansionPanel', {
    attrs: {
      "color": "comments",
      "is-open": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('c-icon-fa', {
    staticClass: "mr-3",
    attrs: {
      "icon": "fad fa-comment-alt-lines"
    }
  }), _c('span', {
    staticClass: "title"
  }, [_vm._v("Last Comment")])], 1), _c('v-flex', {
    staticClass: "mb-3"
  }, [_c('div', {
    staticClass: "mb-3"
  }, [_c('em', {
    staticClass: "comments--text"
  }, [_vm._v(" Written by "), _vm.lastComment.createdByUser ? _c('strong', [_vm._v(_vm._s(_vm.$contactDisplay(_vm.lastComment.createdByUser.contact)))]) : _vm._e(), _vm._v(" on "), _c('strong', [_vm._v(_vm._s(_vm.$dateDisplay(_vm.lastComment.createdDateTime)))])])]), _c('AppMarkdownDisplay', {
    attrs: {
      "markdown": _vm.lastComment.comment
    }
  })], 1), _c('v-flex', [_c('ButtonGeneric', {
    attrs: {
      "color": "success",
      "icon-color": "white",
      "icon": "fas fa-reply"
    },
    on: {
      "click": function ($event) {
        return _vm.launchNewCommentModal(true);
      }
    }
  }, [_vm._v(" Reply ")]), _c('ButtonCreate', {
    on: {
      "click": function ($event) {
        return _vm.launchNewCommentModal(false);
      }
    }
  }, [_vm._v("Create new Comment")])], 1)], 1) : _vm._e(), _vm.showNewCommentModal ? _c('ModalCommentCreate', {
    attrs: {
      "comment-type-override": _vm.commentTypeOverride,
      "document-id": _vm.documentId,
      "reply-to-text": _vm.replyToText
    },
    on: {
      "modal-close": function ($event) {
        _vm.showNewCommentModal = false;
      },
      "commentCreated": _vm.fetchLastComment
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };