var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import { CommentType, Maybe } from '~/db_types';
import { CommentTypeDisplay } from '~/nasa_ui/types/enums/comment';
import { ICommentsFormData } from '../ModalCommentEdit/ModalCommentEdit';
import ModalCommentBase from './ModalCommentBase';
let CommentModalTemplate = class CommentModalTemplate extends ModalCommentBase {
    CommentType = CommentType;
    CommentTypeDisplay = CommentTypeDisplay;
    id;
    value;
    isCommentImmutable;
    readOnly;
    get createCommentOptions() {
        return this.createCommentTypes?.map((commentType) => {
            return {
                displayText: CommentTypeDisplay.get(commentType),
                value: commentType
            };
        });
    }
    get commentTypeOptions() {
        return this.$attrs.id
            ? this.editCommentTypes?.map((commentType) => {
                return {
                    displayText: CommentTypeDisplay.get(commentType),
                    value: commentType
                };
            })
            : this.createCommentOptions;
    }
    get isCommentEmpty() {
        if (!this.value) {
            return false;
        }
        return !this.value.comment;
    }
    get shouldShowCommentTypeSelect() {
        return !this.$attrs.commentTypeOverride;
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], CommentModalTemplate.prototype, "id", void 0);
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_b = typeof ICommentsFormData !== "undefined" && ICommentsFormData) === "function" ? _b : Object)
], CommentModalTemplate.prototype, "value", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], CommentModalTemplate.prototype, "isCommentImmutable", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true
    }),
    __metadata("design:type", Boolean)
], CommentModalTemplate.prototype, "readOnly", void 0);
CommentModalTemplate = __decorate([
    Component
], CommentModalTemplate);
export default CommentModalTemplate;
