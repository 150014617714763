var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-expansion-panel-content', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c('div', {
          staticClass: "panel__heading",
          class: {
            'font-weight-bold': _vm.boldHeader
          }
        }, [_c('c-icon-fa', {
          staticClass: "alert__icon",
          attrs: {
            "icon": _vm.icon,
            "icon-color": _vm.iconColor,
            "icon-size": "30px"
          }
        }), _c('span', {
          staticClass: "panel__heading-text"
        }, [_vm._v(_vm._s(_vm.header))])], 1)];
      },
      proxy: true
    }])
  }, [_c('v-card', [_vm._t("default")], 2)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };