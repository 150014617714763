import { __decorate, __metadata } from "tslib";
import { Debounce } from 'lodash-decorators/debounce';
import { Component, Mixins } from 'vue-property-decorator';
import { RiskCategory, RiskClosureType, RiskHardwareType, RiskReportability } from '~/db_types/swagger_types';
import BaseSearch from '~/nasa_ui/base/BaseSearch';
import { BASE_DATE_COL, BASE_ICON_COL, BASE_TEXT_COL, CREATED_DATE_COL, NUMBER_NO_UNDERSCORE_COL, STATUS_COL, TITLE_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import HttpMixin from '~/nasa_ui/mixins/HttpMixin';
import { EntityType, HeaderSortOptions, OutputMode } from '~/nasa_ui/types';
import { RiskHardwareTypeDisplay, RiskReportabilityDisplay } from '~/nasa_ui/types/enums/risks';
import { MitigationTeamDisplay, RiskCategoryDisplay, RiskClosureTypeDisplay } from '~/nasa_ui/types/models/risk';
import { composeTableHeader } from '~/nasa_ui/utils/helpers/composeTableHeader';
import { transformRiskResponses } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let SearchRisk = class SearchRisk extends Mixins(BaseSearch, HttpMixin) {
    entityType = EntityType.RISK;
    hint = 'Risk number, Status';
    placeholder = 'search risks';
    EntityType = EntityType;
    OutputMode = OutputMode;
    risks = null;
    RiskCategory = RiskCategory;
    RiskCategoryDisplay = RiskCategoryDisplay;
    RiskClosureType = RiskClosureType;
    RiskClosureTypeDisplay = RiskClosureTypeDisplay;
    RiskHardwareType = RiskHardwareType;
    RiskHardwareTypeDisplay = RiskHardwareTypeDisplay;
    RiskReportability = RiskReportability;
    RiskReportabilityDisplay = RiskReportabilityDisplay;
    searchFilters = {
        category: null,
        closureType: null,
        closedDateAfter: null,
        closedDateBefore: null,
        hardwareType: null,
        includeArchived: null,
        isClosed: false,
        isSupplier: null,
        mitigationTeam: null,
        modifiedAfter: null,
        organizationCode: null,
        ownerContactId: null,
        showOnReports: [],
        stakeholderContactId: null
    };
    riskHeaders = [
        NUMBER_NO_UNDERSCORE_COL,
        composeTableHeader(STATUS_COL, {
            width: '50px',
            noWrap: false
        }),
        composeTableHeader(BASE_ICON_COL, {
            text: 'Risk<br/>score',
            value: '_riskScore'
        }),
        composeTableHeader(TITLE_COL, {
            headerSortType: HeaderSortOptions.BLOCK_OF_TEXT,
            width: '325px'
        }),
        composeTableHeader(BASE_ICON_COL, {
            noEscape: true,
            text: 'Reportability',
            value: '_reportability'
        }),
        composeTableHeader(BASE_TEXT_COL, {
            noEscape: true,
            text: 'Category',
            value: '_category'
        }),
        composeTableHeader(BASE_TEXT_COL, {
            noEscape: true,
            text: 'Organization',
            value: '_organization.code'
        }),
        composeTableHeader(BASE_TEXT_COL, {
            headerSortType: HeaderSortOptions.BLOCK_OF_TEXT,
            noEscape: true,
            text: 'Cause',
            value: '_cause',
            width: '500px'
        }),
        composeTableHeader(BASE_TEXT_COL, {
            headerSortType: HeaderSortOptions.BLOCK_OF_TEXT,
            noEscape: true,
            text: 'Effect',
            value: '_effect',
            width: '500px'
        }),
        CREATED_DATE_COL,
        composeTableHeader(BASE_DATE_COL, {
            text: 'Initial ECD',
            value: '_initialProjectedCloseDate'
        })
    ];
    get chipsFromFilters() {
        if (!this.pureSearchFilters || Object.keys(this.pureSearchFilters).length === 0) {
            return [];
        }
        let appChips = [];
        appChips = appChips.concat(this.buildAppChipForProperty('closureType', 'risks', RiskClosureTypeDisplay), this.buildAppChipForProperty('hardwareType', 'risks', RiskHardwareTypeDisplay), this.buildAppChipForProperty('category', 'risks', RiskCategoryDisplay), this.buildAppChipForProperty('mitigationTeam', 'risks', MitigationTeamDisplay), this.buildAppChipForProperty('organizationCode', 'organizations'), this.buildAppChipForProperty('closedDateBefore', 'risks'), this.buildAppChipForProperty('closedDateAfter', 'risks'), this.buildAppChipForProperty('modifiedAfter', 'risks'));
        if (this.searchFilters.isSupplier) {
            appChips.push({
                text: 'Supplier',
                color: this.entityType,
                value: 'isSupplier',
                outline: true,
                close: false
            });
        }
        if (this.searchFilters.isClosed) {
            appChips.push({
                text: 'Closed',
                color: this.entityType,
                filterType: 'isClosed',
                value: 'isClosed',
                outline: true,
                close: true
            });
        }
        this.searchFilters.showOnReports?.forEach((reportability) => {
            appChips.push({
                text: `Reportability: ${RiskReportabilityDisplay.get(reportability)}`,
                color: this.entityType,
                filterType: 'showOnReports',
                value: reportability,
                outline: true,
                close: true
            });
        });
        if (this.searchFilters.ownerContactId?.length) {
            appChips.push({
                text: 'By owner',
                color: this.entityType,
                filterType: 'ownerContactId',
                value: 'ownerContactId',
                outline: true,
                close: true
            });
        }
        if (this.searchFilters.stakeholderContactId?.length) {
            appChips.push({
                text: 'By stakeholder',
                color: this.entityType,
                filterType: 'stakeholderContactId',
                value: 'stakeholderContactId',
                outline: true,
                close: true
            });
        }
        if (this.searchFilters.includeArchived) {
            appChips.push({
                text: 'Include Archived',
                color: this.entityType,
                value: 'includeArchived',
                outline: true,
                close: false
            });
        }
        return appChips.filter((key) => key !== undefined);
    }
    get disableSearch() {
        // allowing wide open searches
        return false;
    }
    get tableItems() {
        return transformRiskResponses(this.risks || []);
    }
    created() {
        this.checkQuery();
    }
    checkQuery() {
        if (this.$route.query?.category) {
            this.searchFilters.category = [this.$route.query.category];
        }
        if (this.$route.query.hasOwnProperty('autosearch')) {
            this.performSearch();
        }
    }
    async performSearch() {
        try {
            this.isLoading = true;
            const filtersPayload = Object.assign({}, this.searchFilters, {
                organizationCode: this.searchFilters.organizationCode || [],
                ownerContactId: this.searchFilters.ownerContactId || [],
                includeArchived: this.searchFilters.includeArchived || false,
                stakeholderContactId: this.searchFilters.stakeholderContactId || [],
                showOnReports: this.searchFilters.showOnReports || []
            });
            if (this.searchFilters.closureType?.length) {
                // closure type filters required isClosed to be true
                filtersPayload.isClosed = true;
            }
            // fetch all risks
            const resp = await this.$http.get('/risks', {
                params: {
                    take: -1,
                    ...filtersPayload,
                    query: this.searchInput?.trim() || ''
                }
            });
            this.risks = resp.data.results || [];
            this.isLoading = false;
        }
        catch (err) {
            this.isLoading = false;
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
};
__decorate([
    Debounce(200),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], SearchRisk.prototype, "performSearch", null);
SearchRisk = __decorate([
    Component
], SearchRisk);
export default SearchRisk;
