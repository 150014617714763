var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "container pa-1 mt-2"
  }, [_c('SearchInputContainer', {
    attrs: {
      "autofocus": _vm.autofocus,
      "entity-type": _vm.EntityType.CONTRACT,
      "hint": _vm.hint,
      "is-loading": _vm.isLoading,
      "placeholder": _vm.placeholder
    },
    on: {
      "enterPressed": _vm.onClickOfSearchButton,
      "searchButtonClicked": _vm.onClickOfSearchButton
    },
    model: {
      value: _vm.searchInput,
      callback: function ($$v) {
        _vm.searchInput = $$v;
      },
      expression: "searchInput"
    }
  }), false ? _c('SearchFilterDisplay', {
    attrs: {
      "active": _vm.shouldShowFilters,
      "color": _vm.EntityType.CONTRACT,
      "entity-type": _vm.EntityType.CONTRACT,
      "chipsFromFilters": _vm.chipsFromFilters,
      "show-filter-button": _vm.showFilterButton
    },
    on: {
      "appChipCloseClicked": _vm.onClickOfRemoveFilter,
      "clearAllFilters": _vm.onClearAllFilters,
      "filterButtonClicked": _vm.onClickOfFilterIcon
    }
  }) : _vm._e(), false ? _c('SearchFilterContainer', {
    attrs: {
      "color": _vm.EntityType.CONTRACT,
      "show": _vm.shouldShowFilters
    }
  }) : _vm._e(), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_c('AppLoader', {
    staticClass: "mt-2",
    attrs: {
      "color": _vm.EntityType.CONTRACT,
      "show": _vm.isLoading,
      "type": "linear"
    }
  })], 1), _c('v-slide-y-transition', {
    attrs: {
      "tag": "table",
      "hide-on-leave": ""
    }
  }, [_vm.shouldShowTable ? _c('AppTable', {
    attrs: {
      "color": _vm.EntityType.CONTRACT,
      "headers": _vm.computedContractTableHeaders,
      "items": _vm.searchResults,
      "show-count": false,
      "title": _vm.resultTableTitle,
      "selectable-rows": "",
      "dense-rows": ""
    },
    on: {
      "metaRowClicked": _vm.onMetaClickOfSearchResult,
      "rowClicked": _vm.onClickOfSearchResult
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };