var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('c-card', {
    staticClass: "mt-2",
    attrs: {
      "border-color": "var(--v-users-base)"
    }
  }, [_c('div', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on
      }) {
        return [_vm.formData.isExportControlled || _vm.formData.isProprietary ? _c('c-icon-fa', _vm._g({
          staticClass: "pr-2",
          attrs: {
            "icon": "fad fa-user-lock",
            "icon-color": "var(--v-secondary-base)"
          }
        }, on)) : _c('c-icon-fa', _vm._g({
          staticClass: "pr-2",
          attrs: {
            "icon": "fad fa-user-unlock",
            "icon-color": "var(--v-success-base)"
          }
        }, on))];
      }
    }])
  }, [_vm.formData.isExportControlled || _vm.formData.isProprietary ? _c('span', [_vm._v(" Access control is limited because this Document is marked either Export Controlled or Proprietary. ")]) : _c('span', [_vm._v(" No additional access control. ")])]), _c('span', [_vm._v("Access control")])], 1), _c('div', [!_vm.readOnly ? _c('AppPageDescription', {
    attrs: {
      "color": "info",
      "remove-margin-left": ""
    }
  }, [_vm._v(" Selecting "), _c('u', [_vm._v("Yes")]), _vm._v(" to "), _c('b', [_vm._v("Proprietary")]), _vm._v(" below allows you to white-list access. ")]) : _vm._e(), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs4": ""
    }
  }, [!_vm.readOnly ? _c('AppBoolean', {
    staticClass: "mt-0 pt-0",
    attrs: {
      "hide-unknown": "",
      "label": "Export controlled?"
    },
    model: {
      value: _vm.formData.isExportControlled,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "isExportControlled", $$v);
      },
      expression: "formData.isExportControlled"
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "Export controlled?",
      "center": ""
    }
  }, [_c('AppBooleanDisplay', {
    attrs: {
      "value": _vm.formData.isExportControlled
    }
  })], 1)], 1), _c('v-flex', {
    attrs: {
      "xs4": ""
    }
  }, [!_vm.readOnly ? _c('AppBoolean', {
    staticClass: "mt-0 pt-0",
    attrs: {
      "hide-unknown": "",
      "label": "Proprietary?"
    },
    model: {
      value: _vm.formData.isProprietary,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "isProprietary", $$v);
      },
      expression: "formData.isProprietary"
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "Proprietary?",
      "center": ""
    }
  }, [_c('AppBooleanDisplay', {
    attrs: {
      "value": _vm.formData.isProprietary
    }
  })], 1)], 1), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.formData.isProprietary ? _c('div', [_c('Alert', {
    staticClass: "mx-2",
    attrs: {
      "type": _vm.AlertType.WARNING
    }
  }, [_vm._v("Limiting access to this "), _c('b', [_vm._v("Proprietary")]), _vm._v(" doucment.")]), _c('AppCosmicUsers', {
    staticClass: "ma-2",
    attrs: {
      "read-only": _vm.readOnly,
      "display-type": "NECKED"
    },
    model: {
      value: _vm.formData.attributes.additionalUserAccesses,
      callback: function ($$v) {
        _vm.$set(_vm.formData.attributes, "additionalUserAccesses", $$v);
      },
      expression: "formData.attributes.additionalUserAccesses"
    }
  }, [_c('template', {
    slot: "AppHeader"
  }, [_vm._v("Additional COSMIC User Access")]), _c('template', {
    slot: "no_items"
  }, [_c('Alert', {
    attrs: {
      "type": "info"
    }
  }, [_c('p', [_vm._v("No additional access has been given at this time.")])])], 1), _c('template', {
    slot: "AppPageDescription_above_SearchUser"
  }, [_vm._v(" Selecting a COSMIC User will grant that User full access to this Document. ")]), _c('template', {
    slot: "AppPageDescription_above_results"
  }, [_vm._v(" Listed below are COSMIC Users that have full access to this Document. ")])], 2)], 1) : _vm._e()])], 1)], 1), !_vm.readOnly ? _c('ButtonSave', {
    staticClass: "ml-0",
    attrs: {
      "slot": "actions",
      "disabled": _vm.isLoading || _vm.isSaving,
      "is-saving": _vm.isSaving
    },
    on: {
      "click": _vm.onClickOfSave
    },
    slot: "actions"
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };