import { __decorate, __metadata } from "tslib";
import { Component, Watch } from 'vue-property-decorator';
import BaseTab from '~/nasa_ui/base/BaseTab';
import { AlertType } from '~/nasa_ui/types';
import { transformVehicleResponses } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let TabVehiclesInformation = class TabVehiclesInformation extends BaseTab {
    isSaving = false;
    selectedEntity = null;
    formData = {
        name: ''
    };
    get computedSelectedEntity() {
        return this.selectedEntity ? transformVehicleResponses([this.selectedEntity])[0] : null;
    }
    get hasRequiredFields() {
        return this.formData && this.formData.name;
    }
    get isFormValid() {
        return this.hasRequiredFields;
    }
    async saveVehicle() {
        if (!this.isFormValid) {
            return;
        }
        try {
            const payload = {
                name: this.formData.name
            };
            const resp = await this.$http.put(`/vehicles/${this.id}`, payload);
            if (resp.data) {
                this.selectedEntity = resp.data;
                this.$notification.add({
                    type: AlertType.SUCCESS,
                    text: 'Saved.'
                });
                this.$message('reload');
            }
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async onIdChange() {
        try {
            const resp = await this.$http.get(`/vehicles/${this.id}`);
            this.selectedEntity = resp.data;
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    onSelectedEntityChanged() {
        if (this.selectedEntity) {
            this.formData.name = this.selectedEntity.name;
        }
    }
};
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], TabVehiclesInformation.prototype, "onIdChange", null);
__decorate([
    Watch('selectedEntity'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], TabVehiclesInformation.prototype, "onSelectedEntityChanged", null);
TabVehiclesInformation = __decorate([
    Component
], TabVehiclesInformation);
export default TabVehiclesInformation;
