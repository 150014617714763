var _a, _b;
import { __decorate, __metadata } from "tslib";
import { isBefore } from 'date-fns';
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
import ReadOnlyMixin from '~/nasa_ui/mixins/ReadOnlyMixin';
import { removeTime } from '~/nasa_ui/utils/dates';
let AppEventDate = class AppEventDate extends Mixins(ReadOnlyMixin) {
    isOneDayLocal = false;
    endDateHasError = false;
    startDateHasError = false;
    // use this prop with the .sync modifier for 2-way binding
    endDate;
    // use this prop with the .sync modifier for 2-way binding
    isOneDay;
    // use this prop with the .sync modifier for 2-way binding
    startDate;
    get errorMessagesEndDate() {
        return this.isStartDateBeforeEndDate ? 'End date cannot be before Start date.' : [];
    }
    get errorMessagesStartDate() {
        return this.isStartDateBeforeEndDate ? 'Start date cannot be after End date.' : [];
    }
    get isStartDateBeforeEndDate() {
        return this.startDate && this.endDate && isBefore(removeTime(this.endDate), removeTime(this.startDate));
    }
    get shouldDisableEndDate() {
        return this.isOneDayLocal;
    }
    get shouldDisableOneDayCheckbox() {
        return !this.startDate;
    }
    created() {
        this.isOneDayLocal = this.isOneDay;
        if (this.startDate && this.startDate === this.endDate) {
            this.isOneDayLocal = true;
        }
    }
    emitEndDate(date) { }
    emitIsOneDay(isOneDay) { }
    emitStartDate(date) { }
    onChangeOfEndDate(endDate) {
        if (this.startDate && endDate === this.startDate) {
            this.isOneDayLocal = true;
            return;
        }
        this.isOneDayLocal = false;
    }
    onChangeOfIsOneDay(isOneDay) {
        if (isOneDay !== this.isOneDayLocal) {
            this.isOneDayLocal = isOneDay;
        }
    }
    onChangeOfIsOneDayLocal(isOneDay) {
        this.emitIsOneDay(isOneDay);
        if (isOneDay) {
            this.emitEndDate(this.startDate ?? null);
        }
    }
    onChangeOfStartDate(startDate) {
        if (!startDate) {
            this.isOneDayLocal = false;
            return;
        }
        if (startDate && startDate === this.endDate) {
            this.isOneDayLocal = true;
            return;
        }
        this.isOneDayLocal = false;
    }
    emitError() {
        return Boolean(this.startDateHasError || this.endDateHasError);
    }
    onEndDateError(hasError) {
        this.startDateHasError = hasError;
    }
    onStartDateError(hasError) {
        this.endDateHasError = hasError;
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], AppEventDate.prototype, "endDate", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], AppEventDate.prototype, "isOneDay", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object)
], AppEventDate.prototype, "startDate", void 0);
__decorate([
    Emit('update:endDate'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], AppEventDate.prototype, "emitEndDate", null);
__decorate([
    Emit('update:isOneDay'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], AppEventDate.prototype, "emitIsOneDay", null);
__decorate([
    Emit('update:startDate'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], AppEventDate.prototype, "emitStartDate", null);
__decorate([
    Watch('endDate', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], AppEventDate.prototype, "onChangeOfEndDate", null);
__decorate([
    Watch('isOneDay', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], AppEventDate.prototype, "onChangeOfIsOneDay", null);
__decorate([
    Watch('isOneDayLocal', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], AppEventDate.prototype, "onChangeOfIsOneDayLocal", null);
__decorate([
    Watch('startDate', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], AppEventDate.prototype, "onChangeOfStartDate", null);
__decorate([
    Emit('error'),
    Watch('startDateHasError'),
    Watch('endDateHasError'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppEventDate.prototype, "emitError", null);
AppEventDate = __decorate([
    Component
], AppEventDate);
export default AppEventDate;
