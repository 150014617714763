var _a, _b, _c;
import { __decorate, __metadata } from "tslib";
import { Debounce } from 'lodash-decorators/debounce';
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
import BaseView from '~/nasa_ui/base/BaseView';
import { AppDynamicWrapperType } from '~/nasa_ui/components/AppDynamicWrapper/AppDynamicWrapper';
import { ApprovalRoutingTemplateRepoMixin } from '~/nasa_ui/DAL/approvalRoutingTemplate';
import { AlertType, EntityType } from '~/nasa_ui/types';
import { UserGroupTableHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
let FormApprovalRoutingTemplateCreate = class FormApprovalRoutingTemplateCreate extends Mixins(BaseView, ApprovalRoutingTemplateRepoMixin) {
    AlertType = AlertType;
    EntityType = EntityType;
    isSaving = false;
    nameMatch = null;
    showSnackbar = false;
    snackbarLabel = '';
    UserGroupTableHeaders = UserGroupTableHeaders;
    formData = {
        description: null,
        selectedGroups: [],
        name: null
    };
    displayType;
    userGroups;
    wrapperSize;
    get existingUserGroups() {
        return this.formData.selectedGroups.map((group) => group.nodeId);
    }
    get gqlCreateApprovalRoutingTemplateVariables() {
        if (!this.formData.name || !this.formData.selectedGroups.length) {
            return null;
        }
        return {
            input: {
                description: this.formData.description,
                groupCodes: this.selectedGroupCodes,
                name: this.formData.name
            }
        };
    }
    get hasRequiredFields() {
        return Boolean(this.formData.name && this.formData.selectedGroups.length);
    }
    get isFormValid() {
        return this.hasRequiredFields;
    }
    get nameFieldMessage() {
        return this.nameMatch ? `${this.nameMatch} already exists.` : null;
    }
    get selectedGroupCodes() {
        return this.formData.selectedGroups.map((group) => group.code);
    }
    created() {
        if (this.userGroups?.length) {
            this.formData.selectedGroups = [...this.userGroups];
        }
    }
    onClickOfSearchUserGroupRow(userGroup) {
        this.snackbarLabel = userGroup.name || 'User Group';
        this.showSnackbar = true;
        this.formData.selectedGroups.push(userGroup);
    }
    onChangeOfValidForm(isValid) { }
    onChangeOfMutationVariables(variables) { }
    async onChangeOfNameField(name) {
        if (!name) {
            return;
        }
        const variables = {
            firstResults: 500,
            query: name
        };
        const artResults = (await this.searchApprovalRoutingTemplates(variables))?.approvalRoutingTemplates?.nodes;
        if (artResults?.some((art) => art?.name.toLowerCase() == name.toLowerCase())) {
            this.nameMatch = name;
        }
        else {
            this.nameMatch = null;
        }
    }
};
__decorate([
    Prop({
        type: String,
        default: AppDynamicWrapperType.FORM_WRAPPER
    }),
    __metadata("design:type", typeof (_a = typeof AppDynamicWrapperType !== "undefined" && AppDynamicWrapperType) === "function" ? _a : Object)
], FormApprovalRoutingTemplateCreate.prototype, "displayType", void 0);
__decorate([
    Prop({
        type: Array
    }),
    __metadata("design:type", typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object)
], FormApprovalRoutingTemplateCreate.prototype, "userGroups", void 0);
__decorate([
    Prop({
        type: String,
        default: '2'
    }),
    __metadata("design:type", String)
], FormApprovalRoutingTemplateCreate.prototype, "wrapperSize", void 0);
__decorate([
    Emit('is_form_valid'),
    Watch('isFormValid', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], FormApprovalRoutingTemplateCreate.prototype, "onChangeOfValidForm", null);
__decorate([
    Emit('mutation_variables'),
    Watch('gqlCreateApprovalRoutingTemplateVariables', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_c = typeof Maybe !== "undefined" && Maybe) === "function" ? _c : Object]),
    __metadata("design:returntype", void 0)
], FormApprovalRoutingTemplateCreate.prototype, "onChangeOfMutationVariables", null);
__decorate([
    Debounce(500),
    Watch('formData.name'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", Promise)
], FormApprovalRoutingTemplateCreate.prototype, "onChangeOfNameField", null);
FormApprovalRoutingTemplateCreate = __decorate([
    Component
], FormApprovalRoutingTemplateCreate);
export default FormApprovalRoutingTemplateCreate;
