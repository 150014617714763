var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import VueRouter from 'vue-router';
import { AlertType, INotification } from '~/nasa_ui/types';
import { CosmicIcons } from '~/nasa_ui/utils/ui/CosmicIcons';
let ReportScaffold = class ReportScaffold extends Vue {
    icons = CosmicIcons;
    breadcrumbs;
    description;
    disableExportButton;
    disableRunReport;
    isRunningReport;
    queryAlert;
    routerInstance;
    showExportButton;
    showSlownessAlert;
    showResults;
    title;
    get computedQueryAlert() {
        return (this.queryAlert ?? {
            type: AlertType.WARNING,
            text: `This report could take up to 2 minutes to run depending on the input.`
        });
    }
    get shouldShowBreadcrumbs() {
        return Boolean(this.breadcrumbs);
    }
    // called from ui
    onClickOfBackToFilters() { }
    // called from ui
    onClickOfExportPdf() { }
    // called from ui
    onClickOfRunReport() { }
    // called from ui
    onClickOfShareLink() { }
};
__decorate([
    Prop({
        type: Array
    }),
    __metadata("design:type", Array)
], ReportScaffold.prototype, "breadcrumbs", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], ReportScaffold.prototype, "description", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], ReportScaffold.prototype, "disableExportButton", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], ReportScaffold.prototype, "disableRunReport", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], ReportScaffold.prototype, "isRunningReport", void 0);
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof INotification !== "undefined" && INotification) === "function" ? _a : Object)
], ReportScaffold.prototype, "queryAlert", void 0);
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_b = typeof VueRouter !== "undefined" && VueRouter) === "function" ? _b : Object)
], ReportScaffold.prototype, "routerInstance", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], ReportScaffold.prototype, "showExportButton", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true
    }),
    __metadata("design:type", Boolean)
], ReportScaffold.prototype, "showSlownessAlert", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], ReportScaffold.prototype, "showResults", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], ReportScaffold.prototype, "title", void 0);
__decorate([
    Emit('backToFilters'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ReportScaffold.prototype, "onClickOfBackToFilters", null);
__decorate([
    Emit('exportPdf'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ReportScaffold.prototype, "onClickOfExportPdf", null);
__decorate([
    Emit('runReport'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ReportScaffold.prototype, "onClickOfRunReport", null);
__decorate([
    Emit('shareLink'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ReportScaffold.prototype, "onClickOfShareLink", null);
ReportScaffold = __decorate([
    Component
], ReportScaffold);
export default ReportScaffold;
