import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { ChangeSetStatus } from '~/db_types';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { CosmicUserGroupSlimFragment } from '~/nasa_ui/DAL/user/slim';
import { ChangeSetStatusDisplay } from '~/nasa_ui/types/enums/change-set';
export const csaRequirementsDisplay = {
    requiresQualityControlStamp: 'QC Stamp',
    requiresComment: 'Comment'
};
let AppChangeSetStatusDisplay = class AppChangeSetStatusDisplay extends Vue {
    changeSet = null;
    ChangeSetStatus = ChangeSetStatus;
    showHistorical;
    id;
    unordered;
    get changeSetAuthorities() {
        return this.changeSet?.changeSetAuthorities.nodes ?? [];
    }
    get filteredPipelineDisplay() {
        if (!this.showHistorical) {
            return this.pipelineDisplay.filter((pipeLineItem) => {
                return !pipeLineItem.isHistorical;
            });
        }
        else {
            return this.pipelineDisplay;
        }
    }
    get hasHistoricalApprovals() {
        return Boolean(this.pipelineDisplay.find((pipeline) => pipeline?.isHistorical));
    }
    get pipelineDisplay() {
        if (this.changeSet &&
            this.changeSet.computedStatus === ChangeSetStatus.APPROVED &&
            this.changeSetAuthorities.length === 0) {
            return [
                {
                    autoApproved: true,
                    status: 'approved',
                    icon: 'pipeline_approved',
                    statusDisplay: ChangeSetStatusDisplay.get(ChangeSetStatus.APPROVED) || DEFAULT_DASH
                }
            ];
        }
        return this.changeSetAuthorities.map((csAuth) => {
            const status = csAuth?.status ? csAuth.status : ChangeSetStatus.APPROVED;
            const requirements = Object.keys(csaRequirementsDisplay)
                .filter((key) => Boolean(csAuth?.[key]))
                .map((key) => csaRequirementsDisplay[key] ?? '');
            return {
                autoApproved: false,
                groupName: csAuth?.userGroup?.name ?? '',
                icon: `pipeline_${status.toLowerCase()}`,
                isHistorical: csAuth?.isHistorical,
                requirements,
                status,
                statusDisplay: ChangeSetStatusDisplay.get(csAuth?.status) || DEFAULT_DASH
            };
        });
    }
    created() {
        this.$listen('reload_change_set_status_display', () => this.fetchChangeSet(this.id));
    }
    // called from ui
    getAuthorityPipelineClass(csAuth) {
        return `${csAuth.status.toLowerCase() || ''}`;
    }
    // called from ui
    shouldShowApprovalRequirements(csAuth) {
        return Boolean(csAuth.requirements && csAuth.status === ChangeSetStatus.OPEN);
    }
    emitHasHistoricalApprovals() { }
    onChangeOfId(id) {
        // query for the change set
        this.fetchChangeSet(id);
    }
    async fetchChangeSet(id) {
        if (!id) {
            return;
        }
        try {
            const resp = await this.fetchChangeSetQuery(id);
            if (resp?.data.changeSetById) {
                this.changeSet = resp.data.changeSetById;
            }
        }
        catch (err) {
            this.$errorUtility({ err });
        }
    }
    fetchChangeSetQuery(id) {
        if (!id) {
            return null;
        }
        return this.$apollo.query({
            query: gql `
        ${CosmicUserGroupSlimFragment}

        fragment ChangeSetAuthorityOnChangeSetStatusDisplay on ChangeSetAuthority {
          nodeId
          id
          isHistorical
          status
          requiresQualityControlStamp
          requiresComment

          userGroup {
            ...CosmicUserGroupSlim
          }
        }

        query GetChangeSetStatusDisplayData($id: Int!) {
          changeSetById(id: $id) {
            computedStatus
            id
            nodeId

            changeSetAuthorities {
              nodes {
                ...ChangeSetAuthorityOnChangeSetStatusDisplay
              }
            }
          }
        }
      `,
            variables: {
                id
            }
        });
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], AppChangeSetStatusDisplay.prototype, "showHistorical", void 0);
__decorate([
    Prop({ type: Number }),
    __metadata("design:type", Object)
], AppChangeSetStatusDisplay.prototype, "id", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], AppChangeSetStatusDisplay.prototype, "unordered", void 0);
__decorate([
    Emit('hasHistoricalApprovals'),
    Watch('hasHistoricalApprovals', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppChangeSetStatusDisplay.prototype, "emitHasHistoricalApprovals", null);
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], AppChangeSetStatusDisplay.prototype, "onChangeOfId", null);
AppChangeSetStatusDisplay = __decorate([
    Component
], AppChangeSetStatusDisplay);
export default AppChangeSetStatusDisplay;
