var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-tabs', _vm._g(_vm._b({
    staticClass: "manage-tabs",
    attrs: {
      "slider-color": _vm.sliderColor,
      "data-color": _vm.sliderColor
    }
  }, 'v-tabs', {
    ..._vm.$attrs
  }, false), {
    ..._vm.$listeners
  }), [_vm._t("default")], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };