var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-card', {
    staticClass: "pa-2"
  }, [_c('AppHeader', [_vm._v(_vm._s(_vm.currentStatOption.displayText))]), _c('v-layout', {
    attrs: {
      "align-center": "",
      "justify-center": "",
      "column": "",
      "no-wrap": ""
    }
  }, [_c('Doughnut', {
    staticClass: "my-3 doughnut-chart",
    attrs: {
      "options": _vm.chartOptions,
      "data": _vm.chartData
    }
  }), _c('v-flex', [_c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [!_vm.selectedStatType ? _c('div', [_c('AppTable', {
    attrs: {
      "color": _vm.EntityType.RISK,
      "headers": _vm.chartTableHeaders,
      "items": _vm.chartTableItems,
      "rows-per-page-items": [-1],
      "dense": "",
      "disable-initial-sort": "",
      "hide-pagination": "",
      "sticky": "",
      "selectable-rows": ""
    },
    on: {
      "input": item => _vm.selectedStatType = item[_vm.statType]
    }
  })], 1) : _vm._e()])], 1), _c('v-flex', [_c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.selectedStatType ? _c('div', [_c('ButtonCancel', {
    staticClass: "ml-0",
    on: {
      "click": function ($event) {
        _vm.selectedStatType = null;
      }
    }
  }, [_vm._v("Back")]), _c('AppTableActions', {
    attrs: {
      "color": _vm.EntityType.RISK,
      "dark": false,
      "headers": _vm.riskTableHeaders,
      "items": _vm.filteredRisks,
      "show-search-input": false,
      "dense-rows": "",
      "disable-initial-sort": "",
      "hide-pagination": "",
      "hide-title": "",
      "item-key": "id",
      "sticky": ""
    },
    scopedSlots: _vm._u([{
      key: "_riskScore",
      fn: function ({
        item
      }) {
        return [_c('AppRiskScoreDisplay', {
          attrs: {
            "risk": item
          }
        })];
      }
    }, {
      key: "actions",
      fn: function ({
        item
      }) {
        return [_c('ButtonMiniActionManage', {
          attrs: {
            "to": _vm.buildRiskUrl(item)
          }
        })];
      }
    }], null, false, 437472877)
  })], 1) : _vm._e()])], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };