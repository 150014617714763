var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "hide-title-section": "",
      "modal-width": "70vw",
      "color": `--v-${_vm.EntityType.RISK}-base`
    },
    on: {
      "modal-close": _vm.emitModalClose
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs8": "",
            "mb-3": ""
          }
        }, [_c('c-text', {
          attrs: {
            "label": "Title",
            "required": ""
          },
          on: {
            "c-input": function ($event) {
              _vm.formData.title = $event.detail;
            }
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('AppPageDescription', [_vm._v("Let COSMIC manage the risk number for you?")]), _c('AppBoolean', {
          attrs: {
            "falseText": "No, I want to enter my own number.",
            "hide-unknown": "",
            "horizontal": ""
          },
          model: {
            value: _vm.isCosmicManagedNumber,
            callback: function ($$v) {
              _vm.isCosmicManagedNumber = $$v;
            },
            expression: "isCosmicManagedNumber"
          }
        }), _c('v-slide-x-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [!_vm.isCosmicManagedNumber ? _c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs6": ""
          }
        }, [_c('c-text', {
          attrs: {
            "label": "Number override",
            "required": ""
          },
          on: {
            "c-input": function ($event) {
              _vm.formData.numberOverride = $event.detail;
            }
          }
        })], 1)], 1) : _vm._e()], 1)], 1), _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('AppMarkdown', {
          attrs: {
            "label": "Cause",
            "required": ""
          },
          model: {
            value: _vm.formData.cause,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "cause", $$v);
            },
            expression: "formData.cause"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('AppMarkdown', {
          attrs: {
            "label": "Effect",
            "required": ""
          },
          model: {
            value: _vm.formData.effect,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "effect", $$v);
            },
            expression: "formData.effect"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('AppMarkdown', {
          attrs: {
            "label": "Background and fallback"
          },
          model: {
            value: _vm.formData.backgroundInformation,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "backgroundInformation", $$v);
            },
            expression: "formData.backgroundInformation"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs12": "",
            "mb-3": ""
          }
        }, [_c('AppFormWrapper', {
          attrs: {
            "required": !_vm.formData.organizationCode,
            "color": _vm.EntityType.ORGANIZATION,
            "size": "1"
          }
        }, [!_vm.formData.organizationCode ? _c('SearchOrganization', {
          attrs: {
            "output-mode": _vm.OutputMode.string,
            "label": "Organization"
          },
          model: {
            value: _vm.formData.organizationCode,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "organizationCode", $$v);
            },
            expression: "formData.organizationCode"
          }
        }) : _c('MiniOrganization', {
          attrs: {
            "code": _vm.formData.organizationCode,
            "read-only": false,
            "inline-clear": ""
          },
          on: {
            "inlineClear": function ($event) {
              _vm.formData.organizationCode = null;
            }
          }
        })], 1)], 1), _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('v-layout', {
          attrs: {
            "row": "",
            "no-wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs3": ""
          }
        }, [_c('AppSelectEnum', {
          staticClass: "select-input",
          attrs: {
            "chips": false,
            "display": _vm.RiskCategoryDisplay,
            "enum": _vm.RiskCategory,
            "color": "primary",
            "label": "Category",
            "required": ""
          },
          model: {
            value: _vm.formData.category,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "category", $$v);
            },
            expression: "formData.category"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs2": "",
            "offset-xs1": ""
          }
        }, [_c('AppCheckbox', {
          staticClass: "checkbox-input",
          attrs: {
            "label": "Supplier?",
            "color": "primary"
          },
          model: {
            value: _vm.formData.isSupplier,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "isSupplier", $$v);
            },
            expression: "formData.isSupplier"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs3": ""
          }
        }, [_c('AppSelectEnum', {
          staticClass: "select-input",
          attrs: {
            "chips": false,
            "display": _vm.RiskHardwareTypeDisplay,
            "enum": _vm.RiskHardwareType,
            "color": "primary",
            "label": "EMU / Tools?",
            "required": ""
          },
          model: {
            value: _vm.formData.hardwareType,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "hardwareType", $$v);
            },
            expression: "formData.hardwareType"
          }
        })], 1)], 1)], 1), _c('v-flex', {
          attrs: {
            "xs6": ""
          }
        }, [_c('AppContact', {
          attrs: {
            "label": "Owner",
            "required": ""
          },
          model: {
            value: _vm.formData.ownerContactId,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "ownerContactId", $$v);
            },
            expression: "formData.ownerContactId"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('v-layout', {
          staticClass: "risk_score__inputs",
          attrs: {
            "row": "",
            "no-wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs2": ""
          }
        }, [_c('AppNumber', {
          staticClass: "mr-2",
          attrs: {
            "label": "Initial likelihood",
            "min": 1,
            "max": 5,
            "required": ""
          },
          on: {
            "error": _vm.onLikelihoodError
          },
          model: {
            value: _vm.formData.likelihood,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "likelihood", $$v);
            },
            expression: "formData.likelihood"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs2": ""
          }
        }, [_c('AppNumber', {
          staticClass: "mr-2",
          attrs: {
            "label": "Initial consequence",
            "min": 1,
            "max": 5,
            "required": ""
          },
          on: {
            "error": _vm.onConsequenceError
          },
          model: {
            value: _vm.formData.consequence,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "consequence", $$v);
            },
            expression: "formData.consequence"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs3": ""
          }
        }, [_c('AppBlockquote', {
          staticClass: "text-xs-center",
          attrs: {
            "title": "Initial Risk score"
          }
        }, [_c('span', {
          staticClass: "risk_score__score monospace_font",
          class: _vm.riskScoreClass
        }, [_vm._v(_vm._s(_vm.riskScore))])])], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonCreate', {
          attrs: {
            "disabled": !_vm.isFormValid
          },
          on: {
            "click": _vm.onClickOfCreate
          }
        }), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        })];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };