var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', _vm._g({
    attrs: {
      "color": _vm.EntityType.DOCUMENT
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v("Import steps from Excel")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c('v-fade-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [_vm.fileAccepted ? _c('div', [_c('div', {
          staticClass: "title mb-4"
        }, [_c('Alert', {
          attrs: {
            "type": "success"
          }
        }, [_c('p', {
          staticClass: "title"
        }, [_vm._v("File accepted.")])])], 1), _c('AppPageDescription', {
          attrs: {
            "remove-margin-left": ""
          }
        }, [_vm._v(" Clicking "), _c('strong', [_vm._v("IMPORT")]), _vm._v(" below will "), _c('i', {
          staticClass: "pr-1"
        }, [_vm._v("append")]), _c('span', {
          staticClass: "success--text font-weight-bold monospace_font"
        }, [_vm._v(_vm._s(_vm.stepsToCreate.length))]), _vm._v(" " + _vm._s(_vm.stepsToCreate.length === 1 ? 'step' : 'steps') + " to this Document ")])], 1) : _vm._e()]), _c('v-fade-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [!_vm.fileAccepted ? _c('div', [_c('Alert', [_vm._v(" You can download the Work Order Step spreadsheet template "), _c('a', {
          attrs: {
            "href": "/assets/StepsSample.xlsx",
            "target": "_blank"
          }
        }, [_vm._v("here")])]), _c('AppPageDescription', [_vm._v(" Import the Excel file to begin ")]), _vm.showUploadButton ? _c('AppFileUpload', {
          attrs: {
            "accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          },
          on: {
            "input": _vm.onFileSelected
          }
        }) : _vm._e()], 1) : _vm._e()])];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_vm.fileAccepted ? _c('ButtonCreate', {
          attrs: {
            "loading": _vm.isSaving,
            "disabled": _vm.isSaving
          },
          on: {
            "click": _vm.createSteps
          }
        }, [_vm._v(" Import " + _vm._s(_vm.stepsToCreate.length) + " " + _vm._s(_vm.stepsToCreate.length === 1 ? 'step' : 'steps') + " ")]) : _vm._e(), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        }, [_vm._v("Cancel")])];
      },
      proxy: true
    }])
  }, {
    ..._vm.$listeners
  }));
};
var staticRenderFns = [];
export { render, staticRenderFns };