var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { ChangeSetStatus, DocumentType } from '~/db_types';
import StepMixin from '~/nasa_ui/mixins/StepMixin';
import { transformChangeSetAuthorities } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let StepDetailsApprovals = class StepDetailsApprovals extends Mixins(StepMixin) {
    docType;
    activeAuthorities;
    get hasAnyApprovalsOrRejections() {
        return this.activeAuthorities.some((a) => a?.status !== ChangeSetStatus.OPEN);
    }
    get remainingAuthorities() {
        return transformChangeSetAuthorities(this.activeAuthorities.filter((a) => a?.status === ChangeSetStatus.OPEN));
    }
    get approvalRejectionHeaderText() {
        return this.supportsStepRejection(this.docType) ? 'Approvals/Rejections:' : 'Approvals:';
    }
};
__decorate([
    Prop({
        required: true,
        type: String
    }),
    __metadata("design:type", typeof (_a = typeof DocumentType !== "undefined" && DocumentType) === "function" ? _a : Object)
], StepDetailsApprovals.prototype, "docType", void 0);
__decorate([
    Prop({
        required: true,
        type: Array
    }),
    __metadata("design:type", Array)
], StepDetailsApprovals.prototype, "activeAuthorities", void 0);
StepDetailsApprovals = __decorate([
    Component
], StepDetailsApprovals);
export default StepDetailsApprovals;
