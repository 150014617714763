var _a;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';
import { ActivityType, ItemInstanceInventoryType, Side } from '~/db_types';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { DB_DEFAULT } from '~/nasa_ui/constants';
import { ItemMasterRepoMixin } from '~/nasa_ui/DAL/itemMaster';
import { HandDominanceDisplay, ItemInstanceInventoryTypeDisplay } from '~/nasa_ui/types';
import { HardwareListAssemblyResponseDisplay } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let ModalHardwareListAssemblyActualization = class ModalHardwareListAssemblyActualization extends Mixins(BaseModal, ItemMasterRepoMixin) {
    ItemInstanceInventoryType = ItemInstanceInventoryType;
    ItemInstanceInventoryTypeDisplay = ItemInstanceInventoryTypeDisplay;
    selectedSide = Side.NONE;
    Side = Side;
    HandDominanceDisplay = HandDominanceDisplay;
    selectedLocation = null;
    hardwareListAssembly;
    get canCreateHardware() {
        return !this.isMissingRequiredFields;
    }
    get gqlCreateHardwareInput() {
        const asBuiltNumber = this.hardwareListAssembly?.externalAsBuiltNumber || DB_DEFAULT;
        const drawingNumber = this.hardwareListAssembly?.externalDrawingNumber;
        const serialNumber = this.hardwareListAssembly?.externalSerialNumber;
        const itemClass = (this.hardwareListAssembly?.externalClass || ItemInstanceInventoryType.UNKNOWN);
        if (!asBuiltNumber || !drawingNumber || !itemClass || !this.selectedLocation) {
            return null;
        }
        return {
            asBuiltNumber,
            drawingNumber,
            serialNumber,
            side: Side.NONE,
            itemClass,
            inspectionPerformed: true,
            locationId: this.selectedLocation,
            receiptDate: this.$dateDisplay(new Date()),
            quantityReceived: this.hardwareListAssembly.quantity
        };
    }
    get isMissingRequiredFields() {
        return false;
    }
    async createHardware() {
        try {
            if (!this.gqlCreateHardwareInput) {
                return;
            }
            this.isSaving = true;
            // Does this match an existing item master?
            const itemMasterQueryResponse = await this.$http.get(`/hardware/item-masters`, {
                params: {
                    drawingNumber: this.gqlCreateHardwareInput.drawingNumber,
                    asBuiltNumber: this.gqlCreateHardwareInput.asBuiltNumber,
                    side: this.gqlCreateHardwareInput.side
                }
            });
            if ((itemMasterQueryResponse.data.results || []).length === 0) {
                // We need to make a new ItemMaster first
                const itemMasterCreateResponse = await this.$apollo.mutate({
                    mutation: gql `
            mutation createItemMasterHLAActualization($input: CreateItemMasterInput!) {
              createItemMaster(input: $input) {
                itemMaster {
                  nodeId
                }

                itemDrawing {
                  nodeId
                }
              }
            }
          `,
                    variables: {
                        input: {
                            itemDrawingDescription: this.hardwareListAssembly.externalDescription || 'no description provided',
                            itemMaster: {
                                drawingNumber: this.gqlCreateHardwareInput.drawingNumber,
                                asBuiltNumber: this.gqlCreateHardwareInput.asBuiltNumber,
                                side: this.gqlCreateHardwareInput.side,
                                isTool: this.hardwareListAssembly.externalIsTool,
                                sizes: []
                            },
                            limitedLife: {
                                shelf: {
                                    overrideValueDate: this.hardwareListAssembly.externalShelfLifeDate
                                },
                                usage: {
                                    overrideValueDate: this.hardwareListAssembly.externalUsageLifeDate
                                }
                            }
                        }
                    }
                });
            }
            const resp = await this.$apollo.mutate({
                mutation: gql `
          mutation CreateInitialReceiptOnActualizeHardwareModal($input: CreateInitialReceiptInput!) {
            createInitialReceipt(input: $input) {
              itemInstance {
                id
                nodeId
              }
              itemMaster {
                asBuiltNumber
                drawingNumber
                nodeId
                side
              }
            }
          }
        `,
                variables: {
                    input: this.gqlCreateHardwareInput
                }
            });
            if (resp.data?.createInitialReceipt?.itemInstance && resp.data.createInitialReceipt.itemMaster) {
                const newItemInstanceNodeId = resp.data.createInitialReceipt?.itemInstance.nodeId;
                const newItemInstanceUuid = resp.data.createInitialReceipt?.itemInstance.id;
                // if we have a CEI number, update the item master
                if (this.hardwareListAssembly?.externalContractEndingItemNumber) {
                    await this.$apollo.mutate({
                        mutation: gql `
              mutation UpdateItemMasterOnActualizeHardwareModal(
                $input: UpdateItemMasterByDrawingNumberAndAsBuiltNumberAndSideInput!
              ) {
                updateItemMasterByDrawingNumberAndAsBuiltNumberAndSide(input: $input) {
                  clientMutationId
                }
              }
            `,
                        variables: {
                            input: {
                                asBuiltNumber: resp.data.createInitialReceipt.itemMaster.asBuiltNumber,
                                drawingNumber: resp.data.createInitialReceipt.itemMaster.drawingNumber,
                                side: resp.data.createInitialReceipt.itemMaster.side,
                                patch: {
                                    contractEndingItemNumber: this.hardwareListAssembly?.externalContractEndingItemNumber
                                }
                            }
                        }
                    });
                }
                // If there is a birthdate... update it
                if (this.hardwareListAssembly.externalBirthDate) {
                    const birthDateUpdateResponse = await this.$apollo.mutate({
                        mutation: gql `
              mutation saveItemInstanceDateInformation($nodeId: ID!, $patch: ItemInstancePatch!) {
                updateItemInstance(input: { nodeId: $nodeId, patch: $patch }) {
                  itemInstance {
                    nodeId
                  }
                }
              }
            `,
                        variables: {
                            nodeId: newItemInstanceNodeId,
                            patch: {
                                birthDate: this.hardwareListAssembly.externalBirthDate
                            }
                        }
                    });
                }
                // If there is a Calibration date/number create an activity for it
                if (this.hardwareListAssembly.externalCalibrationDate || this.hardwareListAssembly.externalCalibrationNumber) {
                    const activityCreationResponse = await this.$apollo.mutate({
                        mutation: gql `
              mutation createActivityDuringActualization($activity: CreateActivityInput!) {
                createActivity(input: $activity) {
                  activity {
                    id
                  }
                }
              }
            `,
                        variables: {
                            activity: {
                                authorityNumber: this.hardwareListAssembly.externalCalibrationNumber || '',
                                endDateTime: this.hardwareListAssembly.externalCalibrationDate || null,
                                isManned: false,
                                itemInstanceId: newItemInstanceUuid,
                                name: `Calibration logging during actualization process`,
                                startDateTime: this.hardwareListAssembly.externalCalibrationDate || null,
                                subType: ActivityType.CALIBRATION
                            }
                        }
                    });
                }
                // Update the HLA with the new inventoryId
                if (this.gqlCreateHardwareInput.locationId) {
                    const findInventoryResp = await this.$apollo.query({
                        query: gql `
              query FindInventoryJustActualized($itemInstanceId: UUID!, $locationId: UUID!) {
                inventories(
                  filter: {
                    itemInstanceId: { equalTo: $itemInstanceId }
                    locationId: { equalTo: $locationId }
                    quantity: { greaterThan: 0 }
                  }
                  orderBy: [UPDATED_DATE_TIME_DESC, CREATED_DATE_TIME_DESC]
                ) {
                  nodes {
                    id
                    nodeId
                  }
                }
              }
            `,
                        variables: {
                            itemInstanceId: newItemInstanceUuid,
                            locationId: this.gqlCreateHardwareInput.locationId
                        }
                    });
                    if (findInventoryResp.data.inventories?.nodes.length === 1) {
                        const newlyCreatedInventory = findInventoryResp.data.inventories.nodes[0];
                        if (newlyCreatedInventory) {
                            // Update the hardwareListAssembly
                            const hardwareListAssemblyUpdateResponse = await this.$http.put(`/hardwarelist/assemblies/${this.hardwareListAssembly.id}`, {
                                externalAsBuiltNumber: null,
                                externalBirthDate: null,
                                externalCalibrationDate: null,
                                externalCalibrationNumber: null,
                                externalClass: null,
                                externalContractEndingItemNumber: null,
                                externalDescription: null,
                                externalDrawingNumber: null,
                                externalIsTool: false,
                                externalLocation: null,
                                externalLotNumber: null,
                                externalSerialNumber: null,
                                externalShelfLifeDate: null,
                                externalUsageLifeDate: null,
                                inventoryId: newlyCreatedInventory?.id,
                                isExternal: false,
                                quantity: this.hardwareListAssembly.quantity,
                                sequence: this.hardwareListAssembly.sequence
                            });
                            this.emitHardwareActualized(newlyCreatedInventory.id);
                        }
                    }
                }
                this.$notification.add({
                    type: this.AlertType.SUCCESS,
                    text: 'Hardware actualized.'
                });
                this.emitEntityCreated();
                this.emitModalClose();
            }
        }
        catch (err) {
            this.$notification.add({
                type: this.AlertType.ERROR,
                text: 'Failed to actualize hardware.'
            });
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
        this.isSaving = false;
    }
    onClickOfCreate() {
        this.createHardware();
    }
    emitHardwareActualized(id) { }
};
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof HardwareListAssemblyResponseDisplay !== "undefined" && HardwareListAssemblyResponseDisplay) === "function" ? _a : Object)
], ModalHardwareListAssemblyActualization.prototype, "hardwareListAssembly", void 0);
__decorate([
    Emit('hardwareActualized'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], ModalHardwareListAssemblyActualization.prototype, "emitHardwareActualized", null);
ModalHardwareListAssemblyActualization = __decorate([
    Component
], ModalHardwareListAssemblyActualization);
export default ModalHardwareListAssemblyActualization;
