var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.ORGANIZATION,
      "hide-title-section": ""
    },
    on: {
      "modal-close": _vm.emitModalClose
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs6": ""
          }
        }, [_c('AppPageDescription', {
          attrs: {
            "color": "info",
            "remove-margin-left": ""
          }
        }, [_vm._v(" This is the human readable display name. ")]), _c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs9": ""
          }
        }, [_c('c-text', {
          attrs: {
            "c-value": _vm.formData.name,
            "label": "Name",
            "required": "",
            "autofocus": ""
          },
          on: {
            "c-input": function ($event) {
              _vm.formData.name = $event.detail;
            }
          }
        })], 1)], 1)], 1), _c('v-flex', {
          attrs: {
            "xs6": ""
          }
        }, [_c('AppPageDescription', {
          attrs: {
            "color": "info",
            "remove-margin-left": ""
          }
        }, [_vm._v("This is the \"machine\" code.")]), _c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs9": ""
          }
        }, [_c('c-text', {
          attrs: {
            "c-value": _vm.formData.code,
            "label": "Code",
            "required": "",
            "upper-case": ""
          },
          on: {
            "c-input": function ($event) {
              _vm.formData.code = $event.detail;
            }
          }
        })], 1)], 1)], 1), _c('v-flex', {
          attrs: {
            "xs4": ""
          }
        }, [_c('AppSelectEnum', {
          attrs: {
            "chips": false,
            "color": _vm.EntityType.ORGANIZATION,
            "enum": _vm.OrganizationType,
            "display": _vm.OrganizationTypeDisplay,
            "label": "Type",
            "required": ""
          },
          model: {
            value: _vm.formData.subType,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "subType", $$v);
            },
            expression: "formData.subType"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('AppBoolean', {
          attrs: {
            "label": "Is the Code entered a Cage Code?",
            "hide-unknown": ""
          },
          model: {
            value: _vm.formData.isCageCode,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "isCageCode", $$v);
            },
            expression: "formData.isCageCode"
          }
        })], 1)], 1)];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonCreate', {
          attrs: {
            "disabled": _vm.shouldDisableCreateButton
          },
          on: {
            "click": _vm.onClickOfCreate
          }
        }, [_vm._v(" Create Organization ")]), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        })];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };