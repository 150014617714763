import { __decorate } from "tslib";
import gql from 'graphql-tag';
import { Component, Mixins } from 'vue-property-decorator';
import BaseDelete from '~/nasa_ui/base/BaseDelete';
import { AlertType } from '~/nasa_ui/types';
import { UserPermission } from '~/nasa_ui/types/enums/user';
let DeleteActivity = class DeleteActivity extends Mixins(BaseDelete) {
    get gqlDeleteActivityMutationVariables() {
        if (!this.id) {
            return null;
        }
        return {
            id: this.id
        };
    }
    get shouldDisableDeleteButton() {
        return !this.currentUserHasPermission(UserPermission.ACTIVITY_ADMIN) || this.isDeleting;
    }
    // called from ui
    onAffirmativeClicked() {
        this.deleteActivity();
    }
    async deleteActivity() {
        if (!this.gqlDeleteActivityMutationVariables) {
            return;
        }
        try {
            this.isDeleting = true;
            const resp = await this.deleteActivityMutation();
            if (resp?.data) {
                this.$notification.add({ text: 'Activity deleted.', type: AlertType.SUCCESS });
                this.emitDeletionComplete();
            }
        }
        catch (err) {
            this.isDeleting = false;
            this.$errorUtility({
                err,
                backupErrorText: 'Failed to delete Activity.'
            });
        }
    }
    deleteActivityMutation() {
        if (!this.gqlDeleteActivityMutationVariables) {
            return null;
        }
        return this.$apollo.mutate({
            mutation: gql `
        mutation DeleteActivityById($id: UUID!) {
          deleteActivityById(input: { id: $id }) {
            clientMutationId
          }
        }
      `,
            variables: { ...this.gqlDeleteActivityMutationVariables }
        });
    }
};
DeleteActivity = __decorate([
    Component
], DeleteActivity);
export default DeleteActivity;
