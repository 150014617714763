var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { ChangeSetStatus } from '~/db_types';
import GenericDocumentMixin from '~/nasa_ui/mixins/GenericDocumentMixin';
import ReadOnlyMixin from '~/nasa_ui/mixins/ReadOnlyMixin';
import { Maybe } from '~/nasa_ui/types';
import { EntityType } from '~/nasa_ui/types/enums';
import { DocumentStatus } from '~/nasa_ui/utils/DocumentStatus';
let BaseDocumentInitialization = class BaseDocumentInitialization extends Mixins(ReadOnlyMixin, GenericDocumentMixin) {
    EntityType = EntityType;
    isLoading = false;
    showDocumentInitializationModal = false;
    entity;
    get permissions() {
        if (!this.entity) {
            return null;
        }
        const adminType = this.entity.subType ? this.getCorrespondingAdminGroup(this.entity.subType) : null;
        return new DocumentStatus(this.entity, adminType);
    }
    get completedSteps() {
        return this.workOrderSteps.filter((step) => step?.computedStatus === ChangeSetStatus.APPROVED).length;
    }
    get initializationChangeSet() {
        return this.permissions?.initializationChangeSet;
    }
    get isInitialized() {
        return Boolean(this.permissions?.isInitialized);
    }
    get isOpenForEdits() {
        return Boolean(this.permissions?.isOpenForEdits);
    }
    get isPending() {
        return this.permissions?.isPending;
    }
    get isRejected() {
        return this.permissions?.isDocumentRejected;
    }
    get linkToApprovals() {
        return `${this.$route.path}#approvals`;
    }
    get pendingInitializationChangeSetId() {
        return this.permissions?.pendingInitializationChangeSetId;
    }
    get shouldShowInitializationButton() {
        return this.permissions?.canBeginInitialization;
    }
    get totalSteps() {
        return this.workOrderSteps.length;
    }
    get workOrderSteps() {
        return this.entity?.workOrderSteps?.nodes || [];
    }
};
__decorate([
    Prop({
        type: Object,
        default: () => ({})
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], BaseDocumentInitialization.prototype, "entity", void 0);
BaseDocumentInitialization = __decorate([
    Component
], BaseDocumentInitialization);
export default BaseDocumentInitialization;
