var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.comments.length ? _c('div', {
    staticClass: "mb-3"
  }, [_vm._l(_vm.comments, function (comment) {
    return _c('div', {
      key: comment.id,
      staticClass: "steps-comment mb-3"
    }, [_c('v-layout', {
      attrs: {
        "row": "",
        "justify-space-between": ""
      }
    }, [_c('v-flex', {
      staticClass: "comments--text"
    }, [_c('em', [comment.status === _vm.ChangeSetStatus.APPROVED ? _c('span', {
      staticClass: "success--text"
    }, [_c('c-icon-fa', {
      staticClass: "mr-1 subheading",
      attrs: {
        "icon": "fad fa-thumbs-up"
      }
    }), _vm._v(" Approved ")], 1) : _vm._e(), comment.status === _vm.ChangeSetStatus.REJECTED ? _c('span', {
      staticClass: "error--text"
    }, [_c('c-icon-fa', {
      staticClass: "mr-1 subheading",
      attrs: {
        "icon": "fad fa-thumbs-down"
      }
    }), _vm._v(" Rejected ")], 1) : _vm._e(), _vm._v(" by "), _c('strong', [_vm._v(_vm._s(comment.authorizedBy))])]), _c('span', {
      staticClass: "d-block caption ml-4"
    }, [_vm._v(_vm._s(comment.authorizedDate))]), comment.qualityControlStamp ? _c('p', [_c('strong', [_vm._v(_vm._s(comment.qualityControlStamp))])]) : _vm._e()]), comment.text ? _c('v-flex', [_c('span', {
      staticClass: "d-block text-xs-right comment-toggle comments--text",
      on: {
        "click": function ($event) {
          comment.shown = !comment.shown;
        }
      }
    }, [_vm._v(" " + _vm._s(comment.shown ? 'Hide' : 'Show') + " Comments "), comment.shown ? _c('c-icon-fa', {
      staticClass: "caption",
      attrs: {
        "icon": _vm.$icons.chevron_up
      }
    }) : _c('c-icon-fa', {
      staticClass: "caption",
      attrs: {
        "icon": _vm.$icons.chevron_down
      }
    })], 1)]) : _vm._e()], 1), _c('v-expand-transition', [_c('section', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: comment.shown,
        expression: "comment.shown"
      }],
      staticClass: "comment-text"
    }, [_c('AppFormWrapper', {
      staticClass: "mt-1",
      attrs: {
        "color": _vm.EntityType.COMMENT,
        "size": "1"
      }
    }, [_c('AppMarkdownDisplay', {
      attrs: {
        "markdown": comment.text
      }
    })], 1)], 1)])], 1);
  }), _c('v-divider')], 2) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };