var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { RiskReportability } from '~/db_types/swagger_types';
import BaseVue from '~/nasa_ui/base/BaseVue';
let AppRiskReportabilityDisplay = class AppRiskReportabilityDisplay extends Mixins(BaseVue) {
    RiskReportability = RiskReportability;
    showOnReports;
    get riskReportabilityIsAlways() {
        return this.showOnReports === RiskReportability.ALWAYS;
    }
    get riskReportabilityIsDefault() {
        return this.showOnReports === RiskReportability.DEFAULT;
    }
    get riskReportabilityIsNever() {
        return this.showOnReports === RiskReportability.NEVER;
    }
};
__decorate([
    Prop({
        type: String,
        required: true
    }),
    __metadata("design:type", typeof (_a = typeof RiskReportability !== "undefined" && RiskReportability) === "function" ? _a : Object)
], AppRiskReportabilityDisplay.prototype, "showOnReports", void 0);
AppRiskReportabilityDisplay = __decorate([
    Component
], AppRiskReportabilityDisplay);
export default AppRiskReportabilityDisplay;
