var _a, _b, _c;
import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { HardwareList, Maybe } from '~/db_types';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { EntityType } from '~/nasa_ui/types';
let ModalHardwareListTemplateAssign = class ModalHardwareListTemplateAssign extends Mixins(BaseModal) {
    selectedTemplate = null;
    hardwareList;
    hardwareListHasTemplate;
    existingSelectedTemplate;
    get appModalColor() {
        return EntityType.HARDWARE_LIST_TEMPLATE;
    }
    // because the SelectorHardwareListTemplate takes an array of existing values
    get selectedTemplateArray() {
        return this.existingSelectedTemplate ? [this.existingSelectedTemplate] : [];
    }
    get shouldDisableAssignButton() {
        return this.existingSelectedTemplate?.id === this.selectedTemplate?.id;
    }
    get shouldShowTemplateSaveButtonWarning() {
        return Boolean(this.hardwareListHasTemplate && !this.selectedTemplate);
    }
    // called from ui
    onSelectorHardwareListTemplateInput(val) {
        this.selectedTemplate = val?.length ? val[0] : null;
    }
    // called from ui
    async onClickOfAssignTemplateSaveButton() {
        this.$emit('assign-hardware-list-template', this.selectedTemplate);
        return this.closeDialog();
    }
    setSelectedTemplateToExisting() {
        if (this.existingSelectedTemplate) {
            this.selectedTemplate = this.existingSelectedTemplate;
        }
    }
};
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof HardwareList !== "undefined" && HardwareList) === "function" ? _a : Object)
], ModalHardwareListTemplateAssign.prototype, "hardwareList", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", typeof (_b = typeof Boolean !== "undefined" && Boolean) === "function" ? _b : Object)
], ModalHardwareListTemplateAssign.prototype, "hardwareListHasTemplate", void 0);
__decorate([
    Prop({
        type: Object,
        default: {}
    }),
    __metadata("design:type", typeof (_c = typeof Maybe !== "undefined" && Maybe) === "function" ? _c : Object)
], ModalHardwareListTemplateAssign.prototype, "existingSelectedTemplate", void 0);
__decorate([
    Watch('existingSelectedTemplate', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalHardwareListTemplateAssign.prototype, "setSelectedTemplateToExisting", null);
ModalHardwareListTemplateAssign = __decorate([
    Component
], ModalHardwareListTemplateAssign);
export default ModalHardwareListTemplateAssign;
