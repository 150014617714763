import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { UserSupportContext } from '~/application.config';
import BaseTab from '~/nasa_ui/base/BaseTab';
import { OrganizationRepoMixin } from '~/nasa_ui/DAL/organization';
import { UserRepoMixin } from '~/nasa_ui/DAL/user';
import HttpMixin from '~/nasa_ui/mixins/HttpMixin';
let TabUserSupportContext = class TabUserSupportContext extends Mixins(BaseTab, HttpMixin, OrganizationRepoMixin, UserRepoMixin) {
    assignedSupportContexts = [];
    availableSupportContext = [];
    isFetchingOrganizations = false;
    selectedSupportContext = null;
    user = null;
    get isEditingYourself() {
        return this.userId === this.$route.params.id;
    }
    get isRoleAdmin() {
        return this.currentUserIsAdmin;
    }
    get supportContextsPlayload() {
        // modify existing supports on the user, maintaing existing support permissions
        const supports = this.assignedSupportContexts.map((support) => {
            const currentSupport = this.user?.supports?.find((_support) => support.id === _support.organizationCode);
            return {
                permissions: currentSupport ? currentSupport.permissions : {},
                contextOrganizationCode: support.value
            };
        });
        return supports;
    }
    get supportContextTableHeaders() {
        return [
            {
                text: 'Name',
                value: 'value'
            }
        ];
    }
    get userId() {
        return this.currentUser?.id;
    }
    async fetchEntity(id) {
        const resp = await this.$http.get(`/users/${id}`);
        this.user = resp.data;
        const supports = this.user?.supports || [];
        // CJE: only allowed to update based on user's current context
        this.selectedSupportContext = this.currentUserActiveSupportContext;
        // set the already assigned supportContexts to the available support context table
        this.assignedSupportContexts = supports.map((support) => {
            return {
                id: support ? support.organizationCode : '',
                value: support ? support.organizationCode : ''
            };
        });
    }
    async fetchOrganizations() {
        try {
            this.isFetchingOrganizations = true;
            const resp = await this.getAllUserSupportContextOrgs({
                userSupportOrgCodes: Object.values(UserSupportContext)
            });
            this.availableSupportContext = (resp?.organizations?.nodes || []).map((org) => {
                return {
                    id: org?.code || '',
                    value: org?.code || ''
                };
            });
            this.isFetchingOrganizations = false;
        }
        catch (err) {
            this.$errorUtility({ err });
        }
    }
    async mutateSupportContext() {
        if (!this.user?.id) {
            throw new Error('No user id');
        }
        const resp = await this.httpPut(`/users/${this.user.id}/support-contexts`, {
            supportContexts: this.supportContextsPlayload
        });
        return resp?.data;
    }
    async onClickOfSave() {
        try {
            await this.mutateSupportContext();
            this.$message('reload', true);
            this.$notification.add({
                text: 'User support context updated.',
                type: this.AlertType.SUCCESS
            });
            this.$emit('complete');
            if (this.isEditingYourself) {
                this.$notification.add({
                    text: 'Reinitializing your support context in 5 seconds.',
                    type: this.AlertType.SUCCESS
                });
                // reload page
                setTimeout(() => {
                    location.reload();
                }, 5000);
            }
        }
        catch (error) {
            this.$errorUtility({ err: error });
            this.$notification.add({
                text: 'An error happened during the save.',
                type: this.AlertType.ERROR
            });
        }
    }
    onIdChange(id) {
        if (id) {
            this.fetchEntity(id);
            this.fetchOrganizations();
        }
    }
};
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], TabUserSupportContext.prototype, "onIdChange", null);
TabUserSupportContext = __decorate([
    Component
], TabUserSupportContext);
export default TabUserSupportContext;
