/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./TabRiskChangeLog.vue?vue&type=template&id=0d81ee15&scoped=true"
import script from "./TabRiskChangeLog.ts?vue&type=script&lang=ts&external"
export * from "./TabRiskChangeLog.ts?vue&type=script&lang=ts&external"
import style0 from "./TabRiskChangeLog.vue?vue&type=style&index=0&id=0d81ee15&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d81ee15",
  null
  
)

export default component.exports