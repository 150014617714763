var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-layout', {
    staticClass: "px-2 pt-2 pb-0",
    attrs: {
      "row": "",
      "wrap": "",
      "align-center": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs4": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "First Name",
      "value": _vm.contact.firstName
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs4": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Last Name",
      "value": _vm.contact.lastName
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs4": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "AUID",
      "value": _vm.contact.auid,
      "upper-case": ""
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "color": _vm.EntityType.USER,
      "value": _vm.contact.email.join(', ')
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('span', [_vm._v("Emails")]), _c('c-icon-fa', {
          staticClass: "pl-2 copyEmail",
          attrs: {
            "icon": "fad fa-copy",
            "icon-size": "10px",
            "tooltip": "Copy email."
          },
          on: {
            "click": function ($event) {
              return _vm.copyEmail(_vm.contact);
            }
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };