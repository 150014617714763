var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('ButtonGeneric', _vm._g(_vm._b({
    attrs: {
      "color": _vm.computedColor,
      "text-color": _vm.computedTextColor
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function () {
        return [_vm._t("icon", function () {
          return [_c('c-icon-fa', {
            staticClass: "mr-2 fa-rotate-90",
            attrs: {
              "icon": _vm.computedIcon,
              "icon-color": "#fff"
            }
          })];
        })];
      },
      proxy: true
    }], null, true)
  }, 'ButtonGeneric', {
    ..._vm.$attrs,
    ..._vm.$props
  }, false), {
    ..._vm.$listeners,
    click: _vm.emitClick
  }), [_c('span', {
    attrs: {
      "should-show": _vm.shouldShow
    }
  }, [_vm.$slots.default ? _vm._t("default") : _vm._t("default", function () {
    return [_vm._v("Toggle")];
  })], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };