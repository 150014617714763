var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.formData ? _c('div', {
    staticClass: "wrapper"
  }, [_c('AppHeader', {
    staticClass: "mb-3"
  }, [_vm._v(_vm._s(_vm.activityTypeDisplay) + " specific information")]), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs6": "",
      "pr-4": ""
    }
  }, [!_vm.isReadOnly ? _c('AppSelectEnum', {
    attrs: {
      "chips": false,
      "display": _vm.CalibrationTypeDisplay,
      "enum": _vm.CalibrationType,
      "label": "Calibration type",
      "required": ""
    },
    model: {
      value: _vm.formData.calibrationType,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "calibrationType", $$v);
      },
      expression: "formData.calibrationType"
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "Calibration type"
    }
  }, [_vm._v(" " + _vm._s(_vm.formData.attributes.calibrationType ? _vm.CalibrationTypeDisplay.get(_vm.formData.attributes.calibrationType) || '-' : '-') + " ")])], 1)], 1)], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };