import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import { AS_BUILT_COL, BASE_DATE_COL, BASE_ICON_COL, BASE_NUMBER_COL, BASE_TEXT_COL, CLASS_COL, DESCRIPTION_COL, DRAWING_NUMBER_COL, LOT_COL, QUANTITY_COL, SERIAL_COL, SIDE_COL, SIZE_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import ReadOnlyMixin from '~/nasa_ui/mixins/ReadOnlyMixin';
import { AlertType } from '~/nasa_ui/types';
import { isBeforeInclusive } from '~/nasa_ui/utils/dates/isBeforeInclusive';
import { composeTableHeader } from '~/nasa_ui/utils/helpers/composeTableHeader';
import { sortObjectNumericallyBy } from '~/nasa_ui/utils/helpers/sortObjectNumericallyBy';
import { transformHardwareListAssemblyResponses } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let AppHardwareListAssemblies = class AppHardwareListAssemblies extends Mixins(BaseCosmic, ReadOnlyMixin) {
    hardwareList = null;
    isSaving = false;
    selectedHardwareListAssembly = null;
    showActualizationModal = false;
    showCopyHardwareListAssemblyToCriticalInventoryModal = false;
    showCreateHardwareListAssemblyModal = false;
    showDeleteConfirmationModal = false;
    showEditHardwareListTemplateSlotModal = false;
    showHardwareListAssemblyEditModal = false;
    showHardwareListAssemblyInfoModal = false;
    hardwareListId;
    overrideTableHeaders;
    shouldDisableAddToListButton;
    showAdditionalMetadata;
    // All of these are defined within the table template itself
    hardwareListAssemblyTableHeaders = [
        composeTableHeader(BASE_ICON_COL, {
            text: 'Hw type',
            value: '_hardwareTypeIcon'
        }),
        composeTableHeader(QUANTITY_COL, {
            noWrap: true,
            width: '100px'
        }),
        composeTableHeader(DRAWING_NUMBER_COL, {
            text: `Drawing<br/>number`,
            value: '_computedDrawingNumber'
        }),
        composeTableHeader(AS_BUILT_COL, {
            value: '_computedAsBuiltNumber'
        }),
        composeTableHeader(DESCRIPTION_COL, {
            noWrap: true,
            value: '_computedDescription'
        }),
        composeTableHeader(SIDE_COL, {
            value: '_computedSide'
        }),
        composeTableHeader(SIZE_COL, {
            value: '_computedSize'
        }),
        composeTableHeader(SERIAL_COL, {
            value: '_computedSerialNumber'
        }),
        composeTableHeader(LOT_COL, {
            value: '_computedLotNumber'
        }),
        composeTableHeader(CLASS_COL, {
            value: '_computedSubType'
        }),
        composeTableHeader(BASE_NUMBER_COL, {
            text: 'CEI',
            value: '_computedContractEndingItemNumber'
        }),
        composeTableHeader(BASE_TEXT_COL, {
            noEscape: true,
            text: 'Location',
            value: '_computedLocation'
        }),
        composeTableHeader(BASE_DATE_COL, {
            text: 'Birth',
            value: '_computedBirthDate'
        }),
        composeTableHeader(BASE_TEXT_COL, {
            text: 'Calibration<br/>number',
            value: '_computedCalibrationNumber'
        }),
        composeTableHeader(BASE_DATE_COL, {
            text: 'Calibration',
            value: '_computedCalibrationDate'
        }),
        composeTableHeader(BASE_DATE_COL, {
            text: 'Usage<br/>expiry',
            value: '_computedUsageExpiryDate'
        }),
        composeTableHeader(BASE_NUMBER_COL, {
            text: 'Addendum<br/>number',
            value: '_addendumNumber'
        }),
        composeTableHeader(BASE_DATE_COL, {
            text: 'Addendum',
            value: '_addendumDate'
        }),
        composeTableHeader(BASE_TEXT_COL, {
            text: 'Notes',
            value: '_notes'
        })
    ];
    isBeforeTodayInclusive = (date) => {
        return isBeforeInclusive(new Date(date), new Date());
    };
    /**
     * Use the override if provided otherwise use the standard columns
     */
    get computedTableHeaders() {
        return this.overrideTableHeaders || this.hardwareListAssemblyTableHeaders;
    }
    get hardwareListAssemblies() {
        return this.hardwareList?.assemblies || [];
    }
    get hardwareListHasTemplate() {
        return Boolean(this.hardwareList?.hardwareListTemplateId);
    }
    get hardwareListTemplateSlots() {
        return [];
        // return this.hardwareList?.hardwareListTemplate?.templateItems.nodes || [];
    }
    get hardwareListTemplateSlotsUnfilled() {
        return [];
        // return this.hardwareListTemplateSlots.filter((slot) => {
        //   return !this.transformedHardwareListAssemblies.map((item) => item.sequence).includes(slot?.sequence);
        // });
    }
    get tableItems() {
        return this.transformedHardwareListAssemblies.sort(sortObjectNumericallyBy('sequence'));
    }
    get transformedHardwareListAssemblies() {
        return transformHardwareListAssemblyResponses(this.hardwareListAssemblies);
    }
    async deleteHardwareListAssembly() {
        if (!this.selectedHardwareListAssembly) {
            return;
        }
        try {
            this.isSaving = true;
            const resp = await this.$http.delete(`/hardwarelist/assemblies/${this.selectedHardwareListAssembly.id}`);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: `Deleted.`
            });
            this.reset();
            // Refetch
            this.onHardwareListIdChange();
        }
        catch (err) {
            this.isSaving = false;
            this.$errorUtility({
                err
            });
        }
    }
    // Actualize the HLA
    onClickOfActualization(hla) {
        this.showActualizationModal = true;
        this.selectedHardwareListAssembly = hla;
    }
    onClickOfCopyToCriticalInventoryTracking(hla) {
        this.showCopyHardwareListAssemblyToCriticalInventoryModal = true;
        this.selectedHardwareListAssembly = hla;
    }
    onClickOfHardwareListAssemblyCreate() {
        this.showCreateHardwareListAssemblyModal = true;
    }
    onClickOfHardwareListAssemblyViewInfo(hla) {
        this.showHardwareListAssemblyInfoModal = true;
        this.selectedHardwareListAssembly = hla;
    }
    onClickOfHardwareListAssemblyToEdit(hla) {
        this.showHardwareListAssemblyEditModal = true;
        this.selectedHardwareListAssembly = hla;
    }
    onClickOfHardwareListAssemblyToDelete(hla) {
        this.showDeleteConfirmationModal = true;
        this.selectedHardwareListAssembly = hla;
    }
    // onClickOfTemplateSlotToFill(listItem: IHardwareListTableItem) {
    //   this.showHardwareListItemFillModal = true;
    //   this.selectedTemplateSlot = listItem.templateItem ?? null;
    // }
    // onClickOfTemplateSlotToEdit(listItem: IHardwareListTableItem) {
    //   this.showEditHardwareListTemplateSlotModal = true;
    //   this.selectedTemplateSlot = listItem.templateItem ?? null;
    // }
    reset() {
        this.showActualizationModal = false;
        this.showCopyHardwareListAssemblyToCriticalInventoryModal = false;
        this.showCreateHardwareListAssemblyModal = false;
        this.showDeleteConfirmationModal = false;
        this.showEditHardwareListTemplateSlotModal = false;
        this.showHardwareListAssemblyEditModal = false;
        this.showHardwareListAssemblyInfoModal = false;
    }
    async onHardwareListIdChange() {
        if (!this.hardwareListId) {
            return;
        }
        try {
            const resp = await this.$http.get(`/hardwarelist/${this.hardwareListId}`);
            this.hardwareList = resp.data;
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    layerInTemplate() {
        if (!this.hardwareListHasTemplate) {
            return this.transformedHardwareListAssemblies;
        }
        return [
        // ...this.transformedHardwareListAssemblies(
        //   this.hardwareListTemplateSlotsUnfilled.map((templateItem) => {
        //     return {
        //       sequence: templateItem!.sequence,
        //       isTemplate: true,
        //       templateItem,
        //       rowClass: 'is_template'
        //     };
        //   })
        // ),
        // ...this.transformedHardwareListAssemblies
        ]
            .map((hardwareItem) => {
            // if (hardwareItem.isTemplate) {
            //   return hardwareItem;
            // }
            // const matchingTemplateSlot = this.hardwareListTemplateSlots.find(
            //   (templateSlot) => templateSlot?.sequence === hardwareItem.sequence
            // );
            // if (matchingTemplateSlot) {
            //   hardwareItem.hasMatchingTemplate = true;
            //   hardwareItem.rowClass = 'template_match';
            // }
            // return hardwareItem;
        })
            .sort(sortObjectNumericallyBy('sequence'));
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], AppHardwareListAssemblies.prototype, "hardwareListId", void 0);
__decorate([
    Prop({
        type: Array
    }),
    __metadata("design:type", Array)
], AppHardwareListAssemblies.prototype, "overrideTableHeaders", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], AppHardwareListAssemblies.prototype, "shouldDisableAddToListButton", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], AppHardwareListAssemblies.prototype, "showAdditionalMetadata", void 0);
__decorate([
    Watch('hardwareListId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], AppHardwareListAssemblies.prototype, "onHardwareListIdChange", null);
AppHardwareListAssemblies = __decorate([
    Component
], AppHardwareListAssemblies);
export default AppHardwareListAssemblies;
