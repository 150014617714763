var _a;
import { __decorate, __metadata } from "tslib";
import uniqBy from 'lodash/uniqBy';
import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
import UserSearchPanel from '~/nasa_ui/components/UserSearchPanel/UserSearchPanel.vue';
import { UserRepoMixin } from '~/nasa_ui/DAL/user';
let AppUserTransfer = class AppUserTransfer extends Mixins(UserRepoMixin) {
    isLoadingInitialSelection = false;
    selected = [];
    value; // User IDs
    readOnly;
    get selectedIds() {
        return this.selected.map((u) => u.id);
    }
    async created() {
        if (!this.value?.length) {
            return;
        }
        this.isLoadingInitialSelection = true;
        const data = await this.getSlimUsersById({ userIds: this.value });
        this.selected = data?.users?.nodes ?? [];
        this.isLoadingInitialSelection = false;
    }
    async addGroup(group) {
        this.isLoading = true;
        const resp = await this.httpGet(`/user-groups/${group.code}`);
        const users = resp.data?.users || [];
        const newUsersList = [...this.selected, ...users];
        this.selected = uniqBy(newUsersList, 'id');
        this.emitInput(this.selectedIds);
        this.isLoading = false;
    }
    removeUser(user) {
        this.selected = this.selected.filter((u) => u.id !== user.id);
        this.emitInput(this.selectedIds);
    }
    emitInput(userIds) {
        return userIds;
    }
    filterGroupOptions(group) {
        return this.selected.every((user) => {
            return group.delegateGroupForUserId !== user.id;
        });
    }
};
__decorate([
    Prop({ type: Array }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], AppUserTransfer.prototype, "value", void 0);
__decorate([
    Prop({
        required: false,
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], AppUserTransfer.prototype, "readOnly", void 0);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], AppUserTransfer.prototype, "emitInput", null);
AppUserTransfer = __decorate([
    Component({
        components: { UserSearchPanel }
    })
], AppUserTransfer);
export default AppUserTransfer;
