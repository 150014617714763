var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [!_vm.isReadOnly ? _c('div', {
    staticClass: "dropzone",
    class: {
      hidden: _vm.shouldHideDropzone
    },
    on: {
      "click": _vm.onClickOfDropzone,
      "dragleave": _vm.onDragleaveDropzone,
      "dragover": function ($event) {
        $event.preventDefault();
      },
      "drop": function ($event) {
        $event.preventDefault();
        return _vm.onDrop.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "dropzone-inner"
  }, [_c('c-icon-fa', {
    attrs: {
      "icon": "fal fa-file-upload"
    }
  }), _c('p', [_vm._v("Drop a file anywhere to add as an attachment.")])], 1)]) : _vm._e(), _vm.shouldShowAttachmentModal ? _c('ModalFileAttachmentCreate', {
    attrs: {
      "attachment-ids": _vm.existingAttachmentIds,
      "document-id": _vm.documentId,
      "item-drawing": _vm.itemDrawing,
      "item-master": _vm.itemMaster,
      "item-instance": _vm.itemInstance,
      "pre-selected-file": _vm.selectedFile,
      "read-only": _vm.isReadOnly
    },
    on: {
      "modal-close": _vm.onModalClose,
      "saved": _vm.onFileSaved
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };