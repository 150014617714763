var _a;
import { __decorate, __metadata } from "tslib";
import { Debounce } from 'lodash-decorators';
import qs from 'qs';
import { Component, Emit } from 'vue-property-decorator';
import { ContractResponse } from '~/db_types/swagger_types';
import BaseSearch from '~/nasa_ui/base/BaseSearch';
import { EntityType } from '~/nasa_ui/types';
import { ContractTableHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
let SearchContract = class SearchContract extends BaseSearch {
    entityType = EntityType.CONTRACT;
    hint = 'Name, Number';
    placeholder = 'search for contracts';
    computedContractTableHeaders = [...ContractTableHeaders];
    onClickOfSearchResult(selectedItem) {
        this.reset();
        return this.isOutputModeString ? selectedItem.number : selectedItem;
    }
    async performSearch() {
        try {
            this.isLoading = true;
            const queryString = qs.stringify({
                query: this.SEARCH_QUERY,
                ...this.gqlSearchQueryVariables,
                skip: 0,
                take: -1
            });
            // Execute query
            const response = await this.$http.get(`/contracts?${queryString}`);
            this.isLoading = false;
            // Set the raw response in case we need that
            this.rawSearchResponse = response.data;
            this.emitSearchPerformed();
        }
        catch (error) {
            console.log(error);
            this.$errorUtility({ err: error });
        }
    }
};
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof ContractResponse !== "undefined" && ContractResponse) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], SearchContract.prototype, "onClickOfSearchResult", null);
__decorate([
    Debounce(200),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], SearchContract.prototype, "performSearch", null);
SearchContract = __decorate([
    Component
], SearchContract);
export default SearchContract;
