var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { OutageDisplay, OutageReason, OutageReasonDisplay } from '~/nasa_ui/components/TabAdminOutage/TabAdminOutage';
import { centralToUtcTime } from '~/nasa_ui/utils/dates';
let ModalOutage = class ModalOutage extends BaseModal {
    localTitle = 'Outages';
    OutageReason = OutageReason;
    OutageReasonDisplay = OutageReasonDisplay;
    formData = {
        startDate: null,
        endDate: null,
        endTime: null,
        isActive: true,
        reason: null,
        startTime: null,
        userFacingMessage: null
    };
    outage;
    get areDatesValid() {
        if (this.formData.startDate && this.formData.endDate) {
            return this.formData.endDate >= this.formData.startDate;
        }
        else {
            return false;
        }
    }
    get areTimesValid() {
        if (this.formData.startTime && this.formData.endTime) {
            return this.formData.endTime >= this.formData.startTime;
        }
        else {
            return false;
        }
    }
    get computedTitle() {
        return this.localTitle || this.title;
    }
    get outageQueryParams() {
        if (!this.isFormValid || !this.formData.reason) {
            return null;
        }
        const activeStartDate = centralToUtcTime(`${this.formData.startDate}T${this.formData.startTime}`);
        const activeEndDate = centralToUtcTime(`${this.formData.endDate}T${this.formData.endTime}`);
        return {
            activeStartDateTime: activeStartDate.toISOString(),
            activeEndDateTime: activeEndDate.toISOString(),
            isDisabled: !this.formData.isActive,
            reason: this.formData.reason,
            userFacingMessage: this.formData.userFacingMessage
        };
    }
    get isEditing() {
        return Boolean(this.outage);
    }
    get isFormValid() {
        return Boolean(this.formData.reason && this.formData.userFacingMessage && this.areDatesValid && this.areTimesValid);
    }
    get saveButtonLabel() {
        return this.isEditing ? 'Update Outage' : 'Create Outage';
    }
    get shouldDisableSaveButton() {
        return !this.isFormValid || this.isSaving;
    }
    // idk why we have to override this but the close event errors otherwise
    closeDialog() {
        this.emitModalClose();
    }
    async createEntity(payload) {
        if (!payload) {
            return null;
        }
        try {
            this.isSaving = true;
            const resp = await this.$http.post('/outages', {
                ...payload
            });
            this.isSaving = false;
            if (resp) {
                this.emitEntityCreated();
                this.closeDialog();
                this.$notification.add({
                    text: 'Outage created.',
                    type: this.AlertType.SUCCESS
                });
            }
        }
        catch (err) {
            this.$errorUtility({ err });
        }
    }
    async editEntity(payload, id) {
        if (!id || !payload) {
            return null;
        }
        try {
            this.isSaving = true;
            const outage = await this.$http.put(`/outages/${id}`, {
                ...payload
            });
            if (outage) {
                this.emitEntityEdited();
                this.closeDialog();
                localStorage.removeItem(`hasUserSeenOutageError${outage.id}`);
                this.$notification.add({
                    text: 'Outage updated.',
                    type: this.AlertType.SUCCESS
                });
            }
            this.isSaving = false;
        }
        catch (err) {
            this.$errorUtility({ err });
        }
    }
    // called from ui
    onClickOfSaveButton() {
        if (this.isEditing) {
            this.editEntity(this.outageQueryParams, this.outage.id);
            return;
        }
        this.createEntity(this.outageQueryParams);
    }
    // when receiving an outage, pre-populate the form
    onChangeOfOutage(outage) {
        if (outage === null) {
            this.localTitle = 'Create Outage';
            return;
        }
        this.localTitle = 'Edit Outage';
        this.formData = {
            startDate: this.$dateDisplay(this.outage?.activeStartDateTime) ?? null,
            startTime: this.$timeDisplay(this.outage?.activeStartDateTime) ?? null,
            endDate: this.$dateDisplay(this.outage?.activeEndDateTime) ?? null,
            endTime: this.$timeDisplay(this.outage?.activeEndDateTime) ?? null,
            isActive: !this.outage?.isDisabled,
            reason: this.outage?.reason ?? null,
            userFacingMessage: this.outage?.userFacingMessage ?? null
        };
    }
};
__decorate([
    Prop({
        default: null,
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof OutageDisplay !== "undefined" && OutageDisplay) === "function" ? _a : Object)
], ModalOutage.prototype, "outage", void 0);
__decorate([
    Watch('outage', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof OutageDisplay !== "undefined" && OutageDisplay) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], ModalOutage.prototype, "onChangeOfOutage", null);
ModalOutage = __decorate([
    Component
], ModalOutage);
export default ModalOutage;
