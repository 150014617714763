var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "container pa-1 mt-2"
  }, [_c('SearchInputContainer', {
    attrs: {
      "autofocus": _vm.autofocus,
      "entity-type": _vm.EntityType.LOCATION,
      "hint": _vm.hint,
      "is-loading": _vm.isLoading,
      "placeholder": _vm.placeholder
    },
    on: {
      "searchButtonClicked": _vm.onClickOfSearchButton,
      "enterPressed": _vm.onClickOfSearchButton
    },
    model: {
      value: _vm.searchInput,
      callback: function ($$v) {
        _vm.searchInput = $$v;
      },
      expression: "searchInput"
    }
  }), _c('SearchFilterDisplay', {
    attrs: {
      "active": _vm.shouldShowFilters,
      "color": _vm.EntityType.LOCATION,
      "entity-type": _vm.EntityType.LOCATION,
      "chipsFromFilters": _vm.chipsFromFilters,
      "show-filter-button": _vm.showFilterButton
    },
    on: {
      "appChipCloseClicked": _vm.onClickOfRemoveFilter,
      "clearAllFilters": _vm.onClearAllFilters,
      "filterButtonClicked": _vm.onClickOfFilterIcon
    }
  }), _c('SearchFilterContainer', {
    attrs: {
      "color": _vm.EntityType.LOCATION,
      "show": _vm.shouldShowFilters
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs6": "",
      "pr-3": "",
      "mb-2": ""
    }
  }, [_c('AppListString', {
    attrs: {
      "color": _vm.EntityType.CONTRACT,
      "label": "Contract",
      "clearable": "",
      "disabled": _vm.isFilterLocked('contractNumber')
    },
    model: {
      value: _vm.searchFilters.contractNumber,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "contractNumber", $$v);
      },
      expression: "searchFilters.contractNumber"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs6": "",
      "mb-2": ""
    }
  }, [_c('AppListString', {
    attrs: {
      "color": _vm.EntityType.ORGANIZATION,
      "label": "Organization Code",
      "clearable": "",
      "disabled": _vm.isFilterLocked('organizationCode')
    },
    model: {
      value: _vm.searchFilters.organizationCode,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "organizationCode", $$v);
      },
      expression: "searchFilters.organizationCode"
    }
  })], 1)], 1), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": "",
      "sm6": "",
      "md3": "",
      "pr-2": "",
      "mb-2": ""
    }
  }, [_c('AppSelectBuilding', {
    attrs: {
      "disabled": _vm.isFilterLocked('building'),
      "multiple": ""
    },
    model: {
      value: _vm.searchFilters.building,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "building", $$v);
      },
      expression: "searchFilters.building"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "sm6": "",
      "md3": "",
      "pr-2": "",
      "mb-2": ""
    }
  }, [_c('AppSelectRoom', {
    attrs: {
      "disabled": _vm.isFilterLocked('room'),
      "multiple": ""
    },
    model: {
      value: _vm.searchFilters.room,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "room", $$v);
      },
      expression: "searchFilters.room"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "sm6": "",
      "md3": "",
      "pr-2": "",
      "mb-2": ""
    }
  }, [_c('AppSelectStock', {
    attrs: {
      "disabled": _vm.isFilterLocked('stock'),
      "multiple": ""
    },
    model: {
      value: _vm.searchFilters.stock,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "stock", $$v);
      },
      expression: "searchFilters.stock"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "sm6": "",
      "md3": "",
      "pr-2": "",
      "mb-2": ""
    }
  }, [_c('AppSelectBin', {
    attrs: {
      "disabled": _vm.isFilterLocked('bin'),
      "multiple": ""
    },
    model: {
      value: _vm.searchFilters.bin,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "bin", $$v);
      },
      expression: "searchFilters.bin"
    }
  })], 1)], 1), _c('v-layout', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showMaxResults,
      expression: "showMaxResults"
    }]
  }, [_c('v-flex', {
    attrs: {
      "xs3": "",
      "mb-2": ""
    }
  }, [_c('AppNumber', {
    attrs: {
      "color": _vm.EntityType.LOCATION,
      "label": "Max results",
      "wholeNumber": "",
      "min": 0
    },
    model: {
      value: _vm.userSelectedMaxResults,
      callback: function ($$v) {
        _vm.userSelectedMaxResults = $$v;
      },
      expression: "userSelectedMaxResults"
    }
  })], 1)], 1), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": "",
      "mb-3": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs3": ""
    }
  }, [_c('div', {
    staticClass: "checkbox_wrap pa-1"
  }, [_c('AppCheckbox', {
    attrs: {
      "color": _vm.EntityType.CONTRACT,
      "label": "Managed by a Contract?",
      "remove-margin-top": "",
      "tooltip": "Checking this would return any Location that has been assigned to a Contract.",
      "tooltip-position": "right"
    },
    model: {
      value: _vm.searchFilters.isManaged,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "isManaged", $$v);
      },
      expression: "searchFilters.isManaged"
    }
  })], 1)]), _c('v-flex', {
    attrs: {
      "xs3": ""
    }
  }, [_c('div', {
    staticClass: "checkbox_wrap pa-1"
  }, [_c('AppCheckbox', {
    attrs: {
      "color": _vm.EntityType.INVENTORY,
      "label": "Has Inventory?",
      "remove-margin-top": "",
      "tooltip": "Checking this would return any Location that currently has non-zero Inventory records."
    },
    model: {
      value: _vm.searchFilters.hasInventory,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "hasInventory", $$v);
      },
      expression: "searchFilters.hasInventory"
    }
  })], 1)]), _c('v-flex', {
    attrs: {
      "xs3": ""
    }
  }, [_c('div', {
    staticClass: "checkbox_wrap pa-1"
  }, [_c('AppCheckbox', {
    attrs: {
      "color": _vm.EntityType.LOCATION,
      "label": "Include archived Locations?",
      "remove-margin-top": "",
      "tooltip": "Checking this would return any Location has been archived (removed from selection)."
    },
    model: {
      value: _vm.searchFilters.includeArchived,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "includeArchived", $$v);
      },
      expression: "searchFilters.includeArchived"
    }
  })], 1)])], 1), _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "sm6": "",
      "md4": ""
    }
  }, [_c('AppSelectEnum', {
    attrs: {
      "chips": false,
      "color": _vm.EntityType.LOCATION,
      "disabled": _vm.isFilterLocked('locationContractOption'),
      "display": _vm.LocationContractOptionsDisplay,
      "enum": _vm.LocationContractOptions,
      "label": "Location Contract option"
    },
    model: {
      value: _vm.locationContractOptionLocal,
      callback: function ($$v) {
        _vm.locationContractOptionLocal = $$v;
      },
      expression: "locationContractOptionLocal"
    }
  })], 1)], 1)], 1), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_c('AppLoader', {
    staticClass: "mb-3",
    attrs: {
      "color": _vm.EntityType.LOCATION,
      "show": _vm.isLoading,
      "type": "linear"
    }
  })], 1), _c('v-slide-y-transition', {
    attrs: {
      "tag": "table",
      "hide-on-leave": ""
    }
  }, [_vm.shouldShowTable ? _c('AppTable', {
    attrs: {
      "color": _vm.EntityType.LOCATION,
      "headers": _vm.tableHeaders,
      "items": _vm.formattedLocations,
      "dense-rows": "",
      "disable-initial-sort": "",
      "item-key": "id",
      "selectable-rows": "",
      "sticky": "",
      "title": "Locations"
    },
    on: {
      "metaRowClicked": _vm.onMetaClickOfSearchResult,
      "rowClicked": _vm.onClickOfSearchResult
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };