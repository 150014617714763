var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', _vm._g({
    attrs: {
      "color": _vm.EntityType.USER,
      "hide-title-section": ""
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('SelectorAuthorityOverride', {
          attrs: {
            "hideMip": _vm.hideMip,
            "read-only": false,
            "hide-save-as-art-button": "",
            "size": "0"
          },
          on: {
            "show-mip-form": function ($event) {
              _vm.isShowingMipForm = $event;
            }
          },
          model: {
            value: _vm.authoritiesOverrideFormData,
            callback: function ($$v) {
              _vm.authoritiesOverrideFormData = $$v;
            },
            expression: "authoritiesOverrideFormData"
          }
        })];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonSave', {
          attrs: {
            "disabled": _vm.isShowingMipForm || !_vm.authoritiesOverrideFormData.length || _vm.isSaving,
            "loading": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfSave
          }
        }, [_vm._v(" Update step approvals ")]), _c('ButtonCancel', {
          on: {
            "click": _vm.closeDialog
          }
        })];
      },
      proxy: true
    }])
  }, {
    ..._vm.$listeners
  }));
};
var staticRenderFns = [];
export { render, staticRenderFns };