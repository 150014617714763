/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./TabVehiclesInformation.vue?vue&type=template&id=683cb94c"
import script from "./TabVehiclesInformation.ts?vue&type=script&lang=ts&external"
export * from "./TabVehiclesInformation.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports