var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { AttachmentRepoMixin } from '~/nasa_ui/DAL/attachments';
import { AlertType, Maybe } from '~/nasa_ui/types';
import ModalFileAttachmentBase from '../ModalFileAttachmentBase/ModalFileAttachmentBase';
let ModalFileAttachmentCreate = class ModalFileAttachmentCreate extends Mixins(ModalFileAttachmentBase, AttachmentRepoMixin) {
    preSelectedFile;
    get fileName() {
        return this.formData.file?.name;
    }
    get fileSize() {
        return this.formData.file?.size;
    }
    get isFormValid() {
        return Boolean(this.formData.description && this.formData.type && this.formData.file);
    }
    get shouldDisableCreateButton() {
        return !this.isFormValid || this.isSaving || this.isReadOnly;
    }
    created() {
        this.$message('force-hide-file-dropzone', true);
    }
    destroyed() {
        this.$message('force-hide-file-dropzone', false);
    }
    // Creating an attachment has 2 steps
    // 1) Create the Attachment entity with the metadata
    // 2) Link the attachment to the entity
    async onClickOfCreate() {
        const { file, description } = this.formData;
        if (!file || !description || !this.currentUserActiveSupportContext) {
            return;
        }
        this.isSaving = true;
        try {
            // create attachment using api v2
            const resp = await this.createAttachment(this.formData);
            const attachmentId = resp.data?.id;
            if (attachmentId) {
                if (this.itemInstanceIds) {
                    const linkItemInstances = this.itemInstanceIds.map((itemInstanceId) => this.linkAttachmentToItemInstanceById(attachmentId, itemInstanceId));
                    await Promise.all(linkItemInstances);
                    const totalAttachments = this.itemInstanceIds.length.toLocaleString('en-US');
                    this.$notification.add({
                        type: AlertType.SUCCESS,
                        text: `Attachment created and associated to ${totalAttachments} item instances.`
                    });
                }
                else if (this.deliveryMatrixItemId) {
                    await this.$http.put(`/delivery-matrix-item/${this.deliveryMatrixItemId}/attachments`, [attachmentId]);
                    this.$notification.add({
                        type: AlertType.SUCCESS,
                        text: `Attachment created and associated to this Delivery Matrix item.`
                    });
                }
                else {
                    await this.linkAttachmentToEntity(attachmentId);
                    this.$notification.add({
                        type: AlertType.SUCCESS,
                        text: `Attachment created.`
                    });
                }
                this.emitModalClose();
                this.emitSaved(attachmentId);
            }
        }
        catch (err) {
            this.$errorUtility({ err });
        }
        this.isSaving = false;
    }
    onChangeOfPreSelectedFile(file) {
        if (file) {
            this.formData.file = file;
        }
    }
    async linkAttachmentToItemInstanceById(attachmentId, itemInstanceId) {
        const variables = {
            input: {
                itemInstanceId,
                attachmentIds: [attachmentId]
            }
        };
        return this.$apollo.mutate({ mutation: this.linkAttachmentToEntityMutation, variables });
    }
    linkAttachmentToEntity(attachmentId) {
        const variables = {
            input: this.isFormValid ? this.buildLinkAttachmentPayload(attachmentId) : null
        };
        return this.$apollo.mutate({ mutation: this.linkAttachmentToEntityMutation, variables });
    }
};
__decorate([
    Prop({
        type: File
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], ModalFileAttachmentCreate.prototype, "preSelectedFile", void 0);
__decorate([
    Watch('preSelectedFile', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], ModalFileAttachmentCreate.prototype, "onChangeOfPreSelectedFile", null);
ModalFileAttachmentCreate = __decorate([
    Component
], ModalFileAttachmentCreate);
export default ModalFileAttachmentCreate;
