import { __decorate } from "tslib";
import { Component, Mixins } from 'vue-property-decorator';
import { ActivityType } from '~/db_types';
import BaseActivityInformationForm from '~/nasa_ui/base/BaseActivityInformationForm';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { ActivityTypeDisplay, CheckOutActivityHardwareType, CheckOutActivityHardwareTypeDisplay, PgtStatus, PgtStatusDisplay, ScuType, ScuTypeDisplay, WaterDumpType, WaterDumpTypeDisplay } from '~/nasa_ui/types/enums/activity';
let ActivityCheckoutForm = class ActivityCheckoutForm extends Mixins(BaseActivityInformationForm) {
    CheckOutActivityHardwareType = CheckOutActivityHardwareType;
    CheckOutActivityHardwareTypeDisplay = CheckOutActivityHardwareTypeDisplay;
    PgtStatus = PgtStatus;
    PgtStatusDisplay = PgtStatusDisplay;
    ScuType = ScuType;
    ScuTypeDisplay = ScuTypeDisplay;
    WaterDumpType = WaterDumpType;
    WaterDumpTypeDisplay = WaterDumpTypeDisplay;
    get activityTypeDisplay() {
        return ActivityTypeDisplay.has(ActivityType.CHECK_OUT)
            ? ActivityTypeDisplay.get(ActivityType.CHECK_OUT)
            : ActivityType.CHECK_OUT;
    }
    get hardwareType() {
        if (!this.formData) {
            return null;
        }
        return this.formData.attributes.hardwareType;
    }
    get hardwareTypeDisplay() {
        return this.hardwareType
            ? CheckOutActivityHardwareTypeDisplay.get(this.hardwareType) || DEFAULT_DASH
            : DEFAULT_DASH;
    }
    get shouldShowEmuHardwareType() {
        return this.hardwareType === CheckOutActivityHardwareType.EMU;
    }
    get shouldShowEmuAnnualHardwareType() {
        return this.hardwareType === CheckOutActivityHardwareType.EMU_ANNUAL;
    }
    get shouldShowPgtHardwareType() {
        return this.hardwareType === CheckOutActivityHardwareType.PGT;
    }
    get shouldShowSaferHardwareType() {
        return this.hardwareType === CheckOutActivityHardwareType.SAFER;
    }
    get shouldShowSaferTmHardwareType() {
        return this.hardwareType === CheckOutActivityHardwareType.SAFER_TM;
    }
    get shouldShowScofHardwareType() {
        return this.hardwareType === CheckOutActivityHardwareType.SCOF;
    }
    get shouldShowSopHardwareType() {
        return this.hardwareType === CheckOutActivityHardwareType.SOP;
    }
};
ActivityCheckoutForm = __decorate([
    Component
], ActivityCheckoutForm);
export default ActivityCheckoutForm;
