var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import BaseInput from '~/nasa_ui/base/BaseInput';
import { Maybe } from '~/nasa_ui/types';
export var OverrideTogglePosition;
(function (OverrideTogglePosition) {
    OverrideTogglePosition["BELOW"] = "BELOW";
    OverrideTogglePosition["LEFT"] = "LEFT";
    OverrideTogglePosition["RIGHT"] = "RIGHT";
    OverrideTogglePosition["ABOVE"] = "ABOVE";
})(OverrideTogglePosition || (OverrideTogglePosition = {}));
let AppOverride = class AppOverride extends BaseInput {
    overrideToggle = false;
    overrideValue = null;
    defaultClass;
    forceOverride;
    overrideClass;
    toggleClass;
    togglePosition;
    type;
    checkboxLabel;
    get computedCheckboxClass() {
        let className = '';
        if (this.togglePosition === OverrideTogglePosition.ABOVE || this.togglePosition === OverrideTogglePosition.LEFT) {
            className += 'order_before';
        }
        else {
            className += 'order_after';
        }
        if (this.toggleClass) {
            return `${className} ${this.toggleClass}`;
        }
        className += ' xs12';
        if (this.togglePosition === OverrideTogglePosition.LEFT || this.togglePosition === OverrideTogglePosition.RIGHT) {
            className += ' sm6 md5 lg4 xl3';
        }
        return className;
    }
    get computedLabel() {
        return this.label || 'Override Value';
    }
    get computedSlotClass() {
        if (this.defaultClass) {
            return this.defaultClass;
        }
        let className = 'xs12';
        if (this.togglePosition === OverrideTogglePosition.LEFT || this.togglePosition === OverrideTogglePosition.RIGHT) {
            className += ' sm6 md5 lg4 xl3';
        }
        return className;
    }
    get shouldShowOverrideField() {
        return this.forceOverride || this.overrideToggle;
    }
    get shouldShowOverrideToggle() {
        return !this.forceOverride;
    }
    created() {
        this.overrideValue = this.value;
        this.overrideToggle = Boolean(this.overrideValue);
    }
    onChangeOfForceOverride(val) {
        if (val) {
            this.overrideToggle = val;
        }
    }
    onChangeOfOverrideField(val) {
        // clear the override if we're not using it
        if (!val) {
            this.overrideValue = null;
            this.emitInput(this.overrideValue);
        }
    }
    onOverrideToggle() {
        return this.overrideToggle;
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], AppOverride.prototype, "defaultClass", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], AppOverride.prototype, "forceOverride", void 0);
__decorate([
    Prop({
        type: String,
        default: 'xs12 sm6 md5 lg4 xl3'
    }),
    __metadata("design:type", String)
], AppOverride.prototype, "overrideClass", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], AppOverride.prototype, "toggleClass", void 0);
__decorate([
    Prop({
        type: String,
        default: OverrideTogglePosition.ABOVE
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], AppOverride.prototype, "togglePosition", void 0);
__decorate([
    Prop({
        type: String,
        default: 'text'
    }),
    __metadata("design:type", String)
], AppOverride.prototype, "type", void 0);
__decorate([
    Prop({
        type: String,
        default: 'Override?'
    }),
    __metadata("design:type", String)
], AppOverride.prototype, "checkboxLabel", void 0);
__decorate([
    Watch('forceOverride', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], AppOverride.prototype, "onChangeOfForceOverride", null);
__decorate([
    Watch('shouldShowOverrideField'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], AppOverride.prototype, "onChangeOfOverrideField", null);
__decorate([
    Watch('overrideToggle'),
    Emit('toggle'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppOverride.prototype, "onOverrideToggle", null);
AppOverride = __decorate([
    Component
], AppOverride);
export default AppOverride;
