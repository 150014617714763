var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "gantt",
    class: _vm.noEvents ? 'no-events' : ''
  }, [_c('div', {
    staticClass: "year-selection"
  }, [_c('ButtonMiniAction', {
    staticClass: "year-selection__prev",
    attrs: {
      "icon": _vm.$icons.chevron_down
    },
    on: {
      "click": function ($event) {
        return _vm.emitSelectedYear(_vm.year - 1);
      }
    }
  }), _c('div', {
    staticClass: "mx-3 monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.year) + " ")]), _c('div', [_c('ButtonMiniAction', {
    staticClass: "year-selection__next",
    attrs: {
      "icon": _vm.$icons.chevron_up
    },
    on: {
      "click": function ($event) {
        return _vm.emitSelectedYear(_vm.year + 1);
      }
    }
  })], 1)], 1), _c('div', {
    staticClass: "title events--text text--lighten-2 mt-2 indicator-title"
  }, [_vm._v("Viewing all Events")]), _c('div', {
    staticClass: "loader"
  }, [_vm.isLoading ? _c('AppLoader', {
    staticClass: "ma-0",
    attrs: {
      "type": "linear"
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "chart"
  }, [_c('div', {
    staticClass: "chart-labels"
  }, _vm._l(_vm.activeBars, function (item) {
    return _c('div', {
      key: item.eventId,
      staticClass: "bar-name pl-1 row text-truncate"
    }, [_vm.computedBaseUrl ? _c('a', {
      attrs: {
        "href": `${_vm.computedBaseUrl}/${_vm.EntityType.EVENT}/manage/${item.eventId}#information`,
        "title": item.name
      }
    }, [_vm._v(" " + _vm._s(_vm.truncateLabel(item.name)) + " ")]) : _c('router-link', {
      attrs: {
        "to": item.link,
        "title": item.name
      }
    }, [_vm._v(_vm._s(_vm.truncateLabel(item.name)))]), item.startDate !== item.endDate ? _c('div', {
      staticClass: "mt-1"
    }, [_c('span', {
      staticClass: "monospace_font",
      staticStyle: {
        "letter-spacing": "-1px"
      }
    }, [_vm._v(_vm._s(_vm.$dateDisplay(item.startDate)))]), _vm._v(" until "), _c('span', {
      staticClass: "monospace_font",
      staticStyle: {
        "letter-spacing": "-1px"
      }
    }, [_vm._v(_vm._s(_vm.$dateDisplay(item.endDate)))])]) : _c('div', {
      staticClass: "mt-1"
    }, [_c('span', {
      staticClass: "monospace_font"
    }, [_vm._v(" " + _vm._s(_vm.$dateDisplay(item.startDate)) + " ")])])], 1);
  }), 0), _c('v-layout', {
    staticClass: "chart-area-scrollable"
  }, [_c('v-flex', {
    staticClass: "chart-area",
    attrs: {
      "xs12": ""
    }
  }, [_c('div', {
    staticClass: "timeline-labels"
  }, _vm._l(_vm.months, function (month) {
    return _c('div', {
      key: month,
      staticClass: "timeline-labels-col events white--text"
    }, [_vm._v(" " + _vm._s(month) + " ")]);
  }), 0), _vm._l(_vm.activeBars, function (item) {
    return _c('div', {
      key: item.eventId + item.milestoneId,
      staticClass: "bar-plot row"
    }, [_c('v-menu', {
      staticClass: "foo",
      attrs: {
        "max-width": 400,
        "min-width": 200,
        "bottom": "",
        "offset-y": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function ({
          on
        }) {
          return [_c('div', _vm._g({
            staticClass: "bar-background",
            style: item.overrideStyles
          }, on))];
        }
      }], null, true)
    }, [_c('v-card', {
      staticClass: "item-popover"
    }, [_c('v-card-text', {
      staticClass: "events white--text body-2"
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c('v-card-text', [item.description ? _c('div', {
      staticClass: "mb-4"
    }, [_c('div', {
      staticClass: "caption"
    }, [_vm._v(_vm._s(item.description))])]) : _vm._e(), _c('div', [_c('span', {
      staticClass: "popover-label"
    }, [_vm._v("Start:")]), _c('strong', {
      staticClass: "monospace_font"
    }, [_vm._v(_vm._s(_vm.$dateDisplay(item.startDate)))])]), _c('div', {
      staticClass: "mt-2"
    }, [_c('span', {
      staticClass: "popover-label"
    }, [_vm._v("End:")]), _c('strong', {
      staticClass: "monospace_font"
    }, [_vm._v(_vm._s(_vm.$dateDisplay(item.endDate)))])])]), _c('v-card-actions', [_c('ButtonGeneric', {
      attrs: {
        "small": "",
        "autofocus": "",
        "color": _vm.EntityType.EVENT,
        "href": _vm.computedBaseUrl && `${_vm.computedBaseUrl}/${_vm.EntityType.EVENT}/manage/${item.eventId}#information`,
        "to": _vm.eventManageRouterLink(item)
      }
    }, [_vm._v(" Manage Event ")])], 1)], 1)], 1), _vm._l(12, function (i) {
      return _c('div', {
        key: i,
        staticClass: "month-cell row"
      }, _vm._l(4, function (q) {
        return _c('div', {
          key: q,
          staticClass: "month-quarter row"
        });
      }), 0);
    })], 2);
  })], 2)], 1)], 1), !_vm.isLoading && _vm.events && !_vm.events.length ? _c('v-layout', {
    staticClass: "mt-2 no-events-message",
    attrs: {
      "justify-center": ""
    }
  }, [_c('h4', [_vm._v("No Events")])]) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };