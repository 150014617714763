var _a, _b, _c, _d;
import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { AttachmentRepoMixin } from '~/nasa_ui/DAL/attachments';
import EntityLinksMixin from '~/nasa_ui/mixins/EntityLinksMixin';
import ReadOnlyMixin from '~/nasa_ui/mixins/ReadOnlyMixin';
import { Maybe } from '~/nasa_ui/types';
let FileAttachmentDropzoneFullScreen = class FileAttachmentDropzoneFullScreen extends Mixins(AttachmentRepoMixin, EntityLinksMixin, ReadOnlyMixin) {
    didDragStartOnPage = false;
    existingAttachmentIds = [];
    forceHide = false;
    forceHideHandler = null;
    isHidden = true;
    selectedFile = null;
    showAttachmentModal = false;
    get shouldHideDropzone() {
        return this.forceHide || this.isHidden;
    }
    get shouldShowAttachmentModal() {
        return !this.isReadOnly && this.showAttachmentModal;
    }
    destroyed() {
        document.querySelector('body')?.removeEventListener('dragstart', this.onDragstart);
        document.querySelector('body')?.removeEventListener('dragover', this.onDragover);
        document.querySelector('body')?.removeEventListener('dragend', this.onDragend);
        if (this.forceHideHandler) {
            this.$stopListening('force-hide-file-dropzone', this.forceHideHandler);
        }
    }
    mounted() {
        document.querySelector('body')?.addEventListener('dragstart', this.onDragstart);
        document.querySelector('body')?.addEventListener('dragover', this.onDragover);
        document.querySelector('body')?.addEventListener('dragend', this.onDragend);
        this.forceHideHandler = this.$listen('force-hide-file-dropzone', this.setForceHide);
    }
    onClickOfDropzone(event) {
        // failsafe in case the dropzone gets trapped in existence meeseeks-style
        this.resetDragEventState();
    }
    // reset the drag start flag, and hide the dropzone
    onDragend(event) {
        this.resetDragEventState();
    }
    onDragleaveDropzone(event) {
        this.resetDragEventState();
    }
    // if a drag event was initiated from the page, we're gonna ignore those dragovers
    onDragover(event) {
        // check for existing modal and prevent showing the dropzone if modal is visible
        if (this.didDragStartOnPage || Boolean(document.querySelectorAll('.v-dialog--active').length)) {
            return;
        }
        this.isHidden = false;
    }
    onDragstart(event) {
        this.didDragStartOnPage = true;
    }
    onDrop(event) {
        const file = event.dataTransfer?.files.item(0);
        if (!file) {
            return;
        }
        this.selectedFile = file;
        this.showAttachmentModal = true;
        this.resetDragEventState();
    }
    onFileSaved() {
        this.$message('attachment-saved');
    }
    onModalClose() {
        this.showAttachmentModal = false;
        this.selectedFile = null;
        this.resetDragEventState();
    }
    resetDragEventState() {
        this.didDragStartOnPage = false;
        this.isHidden = true;
    }
    async onChangeOfDocumentId(documentId) {
        if (!documentId) {
            return;
        }
        try {
            const resp = await this.getDocumentAttachments({
                docId: documentId
            });
            if (resp?.documentById?.attachments) {
                this.existingAttachmentIds = this.getAttachmentIds(resp.documentById.attachments.nodes);
            }
        }
        catch (err) {
            this.$errorUtility({ err });
        }
    }
    onChangeOfForceHide(forceHide) {
        this.resetDragEventState();
    }
    async onChangeOfItemDrawing(itemDrawing) {
        if (!itemDrawing?.drawingNumber) {
            return;
        }
        try {
            const resp = await this.getItemDrawingAttachments({
                drawingNumber: itemDrawing.drawingNumber
            });
            if (resp?.itemDrawingByDrawingNumber?.attachments) {
                this.existingAttachmentIds = this.getAttachmentIds(resp.itemDrawingByDrawingNumber.attachments.nodes);
            }
        }
        catch (err) {
            this.$errorUtility({ err });
        }
    }
    async onChangeOfItemInstance(itemInstance) {
        if (!itemInstance?.id) {
            return;
        }
        try {
            const resp = await this.getItemInstanceAttachments({ id: itemInstance.id });
            if (resp?.itemInstanceById?.attachments) {
                this.existingAttachmentIds = this.getAttachmentIds(resp.itemInstanceById.attachments.nodes);
            }
        }
        catch (err) {
            this.$errorUtility({ err });
        }
    }
    async onChangeOfItemMaster(itemMaster) {
        if (!itemMaster?.asBuiltNumber || !itemMaster.drawingNumber || !itemMaster.side) {
            return;
        }
        const { asBuiltNumber, drawingNumber, side } = itemMaster;
        try {
            const resp = await this.getItemMasterAttachments({
                asBuilt: asBuiltNumber,
                drawingNumber,
                side
            });
            if (resp?.itemMasterByDrawingNumberAndAsBuiltNumberAndSide?.attachments) {
                this.existingAttachmentIds = this.getAttachmentIds(resp.itemMasterByDrawingNumberAndAsBuiltNumberAndSide.attachments.nodes);
            }
        }
        catch (err) {
            this.$errorUtility({ err });
        }
    }
    getAttachmentIds(attachments) {
        return attachments.map((attachment) => attachment?.id || null).filter((_) => _);
    }
    setForceHide(forceHide) {
        this.forceHide = forceHide;
    }
};
__decorate([
    Watch('documentId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", Promise)
], FileAttachmentDropzoneFullScreen.prototype, "onChangeOfDocumentId", null);
__decorate([
    Watch('forceHide', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], FileAttachmentDropzoneFullScreen.prototype, "onChangeOfForceHide", null);
__decorate([
    Watch('itemDrawing', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object]),
    __metadata("design:returntype", Promise)
], FileAttachmentDropzoneFullScreen.prototype, "onChangeOfItemDrawing", null);
__decorate([
    Watch('itemInstance', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_c = typeof Maybe !== "undefined" && Maybe) === "function" ? _c : Object]),
    __metadata("design:returntype", Promise)
], FileAttachmentDropzoneFullScreen.prototype, "onChangeOfItemInstance", null);
__decorate([
    Watch('itemMaster', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_d = typeof Maybe !== "undefined" && Maybe) === "function" ? _d : Object]),
    __metadata("design:returntype", Promise)
], FileAttachmentDropzoneFullScreen.prototype, "onChangeOfItemMaster", null);
FileAttachmentDropzoneFullScreen = __decorate([
    Component
], FileAttachmentDropzoneFullScreen);
export default FileAttachmentDropzoneFullScreen;
