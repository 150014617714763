var _a, _b, _c;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import BaseVue from '~/nasa_ui/base/BaseVue';
import { Maybe } from '~/nasa_ui/types';
let AppFileUpload = class AppFileUpload extends BaseVue {
    showMaxFileSizeExceededMessage = false;
    isImage = false;
    isPdf = false;
    isVideo = false;
    disabled;
    // This currently only supports single files
    // although can be updated to support multiple if needed
    value;
    truncateLength;
    mbMax;
    get buttonText() {
        if (!this.value?.name) {
            return 'No file chosen';
        }
        if (this.value.name.length > this.truncateLength + 3) {
            return this.value.name.slice(0, this.truncateLength) + '...';
        }
        return this.value.name;
    }
    get maxFileSizeExceededMessage() {
        return `Max file size is ${this.mbMax}MB`;
    }
    // ferry the button click event down to the file input element
    onClickOfFileSelect() {
        this.$refs['fileInput']?.click();
    }
    onChangeOfValue(value) {
        if (!value) {
            this.$refs['fileInput'].value = '';
        }
    }
    async onFileChange(event) {
        this.showMaxFileSizeExceededMessage = false;
        const el = event.target;
        const file = el.files ? el.files[0] : null;
        const sizeInMegaBytes = (file?.size ?? 0) / 1024 / 1024;
        if (sizeInMegaBytes > this.mbMax) {
            this.showMaxFileSizeExceededMessage = true;
            this.fileTooLarge();
            return null;
        }
        return file;
    }
    fileTooLarge() { }
};
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], AppFileUpload.prototype, "disabled", void 0);
__decorate([
    Prop({
        type: File,
        default: null
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], AppFileUpload.prototype, "value", void 0);
__decorate([
    Prop({
        type: Number,
        default: 75
    }),
    __metadata("design:type", Number)
], AppFileUpload.prototype, "truncateLength", void 0);
__decorate([
    Prop({
        type: Number,
        default: 500
    }),
    __metadata("design:type", Number)
], AppFileUpload.prototype, "mbMax", void 0);
__decorate([
    Watch('value'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], AppFileUpload.prototype, "onChangeOfValue", null);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_c = typeof Event !== "undefined" && Event) === "function" ? _c : Object]),
    __metadata("design:returntype", Promise)
], AppFileUpload.prototype, "onFileChange", null);
__decorate([
    Emit('file-too-large'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppFileUpload.prototype, "fileTooLarge", null);
AppFileUpload = __decorate([
    Component
], AppFileUpload);
export default AppFileUpload;
