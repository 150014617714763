import { __decorate } from "tslib";
import gql from 'graphql-tag';
import { Component, Mixins } from 'vue-property-decorator';
import LoaderMixin from '~/nasa_ui/mixins/LoaderMixin';
import { mutationFactory, queryFactory } from '../base/mixinFactories';
import { CosmicContactSlimFragment } from '../contact/slim';
import { CosmicUserSlimFragment } from '../user/slim';
import { CosmicEmailNotificationSlimFragment } from './slim';
export const CosmicEmailNotificationFragment = gql `
  ${CosmicContactSlimFragment}
  ${CosmicEmailNotificationSlimFragment}
  ${CosmicUserSlimFragment}

  fragment CosmicEmailNotification on Notification {
    ...CosmicEmailNotificationSlim

    createdByUser {
      ...CosmicUserSlim
    }

    recipientContacts {
      nodes {
        ...CosmicContactSlim
      }
    }
  }
`;
export const CreateEmailNotification = gql `
  ${CosmicEmailNotificationFragment}

  mutation CreateEmailNotification($emailDetails: CreateNotificationInput!) {
    createNotification(input: $emailDetails) {
      notification {
        ...CosmicEmailNotification
      }
    }
  }
`;
export const GetAllDocumentRelatedEmailNotifications = gql `
  ${CosmicEmailNotificationFragment}

  query GetAllDocumentRelatedEmailNotifications {
    notifications(filter: { attributes: { containsKey: "documentId" } }) {
      nodes {
        ...CosmicEmailNotification
      }
    }
  }
`;
export const GetAllRiskRelatedEmailNotifications = gql `
  ${CosmicEmailNotificationFragment}

  query GetAllRiskRelatedEmailNotifications {
    notifications(filter: { attributes: { containsKey: "riskId" } }) {
      nodes {
        ...CosmicEmailNotification
      }
    }
  }
`;
let EmailNotificationRepoMixin = class EmailNotificationRepoMixin extends Mixins(LoaderMixin) {
    getAllDocumentRelatedEmailNotifications = queryFactory(this, GetAllDocumentRelatedEmailNotifications);
    getAllRiskRelatedEmailNotifications = queryFactory(this, GetAllRiskRelatedEmailNotifications);
    createEmailNotification = mutationFactory(this, CreateEmailNotification);
};
EmailNotificationRepoMixin = __decorate([
    Component
], EmailNotificationRepoMixin);
export { EmailNotificationRepoMixin };
