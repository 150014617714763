var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import AutocompleteMixin from '~/nasa_ui/mixins/AutocompleteMixin';
import LoaderMixin from '~/nasa_ui/mixins/LoaderMixin';
import { Maybe } from '~/nasa_ui/types';
import { sortObjectBy } from '~/nasa_ui/utils';
let AppSelectVehicle = class AppSelectVehicle extends Mixins(LoaderMixin, AutocompleteMixin) {
    options = [];
    label;
    get computedLabel() {
        return this.label ? this.label : this.multiple ? 'Vehicle names' : 'Vehicle name';
    }
    async created() {
        try {
            this.isLoading = true;
            const resp = await this.$http.get(`/vehicles`, {
                params: {
                    take: -1
                }
            });
            this.options = (resp.data.results || [])
                .map((veh) => ({
                displayText: veh.name || DEFAULT_DASH,
                value: veh.id
            }))
                .sort(sortObjectBy('displayText'));
            this.isLoading = false;
        }
        catch (err) {
            this.isLoading = false;
            this.$errorUtility({ err, backupErrorText: 'Failed to fetch all contracts.' });
        }
    }
};
__decorate([
    Prop({
        required: false,
        type: String
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], AppSelectVehicle.prototype, "label", void 0);
AppSelectVehicle = __decorate([
    Component
], AppSelectVehicle);
export default AppSelectVehicle;
