import gql from 'graphql-tag';
import { apolloClient } from '~/nasa_ui/apollo.config';
export const setRelatedDocumentsMutation = (setRelatedDocumentsInput) => {
    if (!setRelatedDocumentsInput) {
        return null;
    }
    return apolloClient.mutate({
        mutation: gql `
      mutation SetRelatedDocuments($setRelatedDocumentsInput: SetRelatedDocumentsInput!) {
        setRelatedDocuments(input: $setRelatedDocumentsInput) {
          clientMutationId
        }
      }
    `,
        variables: { setRelatedDocumentsInput }
    });
};
