import { __decorate } from "tslib";
import gql from 'graphql-tag';
import { Component } from 'vue-property-decorator';
import { ChangeSetStatus, ChangeSetType } from '~/db_types';
import BaseSearch from '~/nasa_ui/base/BaseSearch';
import { CosmicChangeSetSlimFragment, CosmicChangeSetStatusSlimFragment } from '~/nasa_ui/DAL/changeSet/slim';
import { CosmicDocumentSlimFragment } from '~/nasa_ui/DAL/document/slim';
import { CosmicItemDrawingSlimFragment } from '~/nasa_ui/DAL/itemDrawing/slim';
import { CosmicItemInstanceSlimFragment } from '~/nasa_ui/DAL/itemInstance/slim';
import { CosmicItemMasterSlimFragment } from '~/nasa_ui/DAL/itemMaster/slim';
import { CosmicUserSlimFragment } from '~/nasa_ui/DAL/user/slim';
import { EntityType } from '~/nasa_ui/types';
import { ChangeSetStatusDisplay, ChangeSetTypeDisplay } from '~/nasa_ui/types/enums/change-set';
import { ChangeSetTableHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
let SearchChangeSet = class SearchChangeSet extends BaseSearch {
    entityType = EntityType.CHANGE_SET;
    hint = 'By Hardware: Drawing number, Description';
    placeholder = 'search for change sets';
    ChangeSetTableHeaders = ChangeSetTableHeaders;
    ChangeSetType = ChangeSetType;
    ChangeSetTypeDisplay = ChangeSetTypeDisplay;
    ChangeSetStatus = ChangeSetStatus;
    ChangeSetStatusDisplay = ChangeSetStatusDisplay;
    searchFilters = {
        asBuiltNumber: null,
        drawingNumber: null,
        query: '',
        status: null,
        subType: null
    };
    SEARCH_QUERY = gql `
    ${CosmicChangeSetSlimFragment}
    ${CosmicChangeSetStatusSlimFragment}
    ${CosmicDocumentSlimFragment}
    ${CosmicItemDrawingSlimFragment}
    ${CosmicItemMasterSlimFragment}
    ${CosmicItemInstanceSlimFragment}
    ${CosmicUserSlimFragment}

    fragment SearchChangeSet on ChangeSet {
      ...CosmicChangeSetSlim

      createdByUser {
        ...CosmicUserSlim
      }

      document {
        ...CosmicDocumentSlim
      }

      itemDrawing {
        ...CosmicItemDrawingSlim
      }

      itemMaster {
        ...CosmicItemMasterSlim
      }

      itemInstance {
        ...CosmicItemInstanceSlim
      }

      status {
        ...CosmicChangeSetStatusSlim
      }
    }

    query changeSetSearch(
      $asBuiltNumber: [String]
      $drawingNumber: [String]
      $firstResults: Int
      $query: String
      $status: [ChangeSetStatus]
      $subType: [String]
    ) {
      searchChangeSets(
        asBuiltNumber: $asBuiltNumber
        drawingNumber: $drawingNumber
        first: $firstResults
        query: $query
        status: $status
        subType: $subType
      ) {
        nodes {
          ...SearchChangeSet
        }
      }
    }
  `;
    get chipsFromFilters() {
        if (!this.pureSearchFilters || Object.keys(this.pureSearchFilters).length === 0) {
            return [];
        }
        let appChips = [];
        appChips = appChips.concat(this.buildAppChipForProperty('subType', 'change_sets', ChangeSetTypeDisplay), this.buildAppChipForProperty('drawingNumber', 'item_drawings'), this.buildAppChipForProperty('asBuiltNumber', 'item_masters'), this.buildAppChipForProperty('status', 'default'));
        return appChips.filter((key) => key !== undefined);
    }
};
SearchChangeSet = __decorate([
    Component
], SearchChangeSet);
export default SearchChangeSet;
