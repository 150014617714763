import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop } from 'vue-property-decorator';
import ModalConfirmationDelete from '~/nasa_ui/components/ModalConfirmationDelete/ModalConfirmationDelete.vue';
import BaseCosmic from './BaseCosmic';
let BaseDelete = class BaseDelete extends BaseCosmic {
    isDeleting = false;
    requiredPermission = null;
    showDialog = false;
    nodeId;
    id;
    paneWidth;
    get shouldDisableDeleteButton() {
        if (!this.requiredPermission) {
            return this.isDeleting;
        }
        return !this.currentUserHasPermission(this.requiredPermission) || this.isDeleting;
    }
    onAffirmativeClicked() {
        // perform deletion
        throw new Error('The onAffirmativeClicked method of BaseDelete must be implemented.');
    }
    onClickOfDeleteButton() {
        this.showDialog = true;
    }
    onNegativeClicked() {
        this.showDialog = false;
    }
    emitDeletionComplete() { }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], BaseDelete.prototype, "nodeId", void 0);
__decorate([
    Prop({
        type: [String, Number]
    }),
    __metadata("design:type", Object)
], BaseDelete.prototype, "id", void 0);
__decorate([
    Prop({
        type: String,
        default: '90vw'
    }),
    __metadata("design:type", String)
], BaseDelete.prototype, "paneWidth", void 0);
__decorate([
    Emit('deletionComplete'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], BaseDelete.prototype, "emitDeletionComplete", null);
BaseDelete = __decorate([
    Component({
        components: {
            ModalConfirmationDelete
        }
    })
], BaseDelete);
export default BaseDelete;
