var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Emit } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
import BaseSelector from '~/nasa_ui/base/BaseSelector';
import { EntityType } from '~/nasa_ui/types';
import { OutputMode } from '~/nasa_ui/types/enums/general';
let SelectorHardwareListTemplate = class SelectorHardwareListTemplate extends BaseSelector {
    EntityType = EntityType;
    selectedTemplates = [];
    get createHardwareListTemplateUrl() {
        return `/hardware/list_templates/create`;
    }
    get hasSelectedTemplates() {
        return Boolean(this.selectedTemplates.length);
    }
    get selectedTemplateIds() {
        return this.outputMode === OutputMode.string
            ? this.selectedTemplates
            : this.selectedTemplates.map((_) => _.id);
    }
    get shouldShowClearSelectionButton() {
        return this.clearable && this.hasSelectedTemplates;
    }
    get shouldShowHardwareListsTemplateSearch() {
        if (!this.singleMode) {
            return true;
        }
        return !this.hasSelectedTemplates;
    }
    created() {
        this.selectedTemplates = [...this.existingValues];
    }
    refreshSearchQuery() {
        const SearchHardwareListTemplate = this.$refs.SearchHardwareListTemplate;
        if (SearchHardwareListTemplate) {
            SearchHardwareListTemplate.onClickOfSearchButton();
        }
        this.showRefreshAlert = false;
    }
    emitInput(selectedTemplate) {
        if (!selectedTemplate) {
            return null;
        }
        else if (this.outputMode === OutputMode.string &&
            !this.selectedTemplates?.includes(selectedTemplate)) {
            this.selectedTemplates.push(selectedTemplate);
        }
        else if (this.outputMode === OutputMode.object &&
            !this.selectedTemplates?.includes(selectedTemplate)) {
            this.selectedTemplates.push(selectedTemplate);
        }
        return this.selectedTemplates;
    }
    onClearOfSelectedTemplate() {
        this.selectedTemplates = [];
        return null;
    }
};
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], SelectorHardwareListTemplate.prototype, "emitInput", null);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], SelectorHardwareListTemplate.prototype, "onClearOfSelectedTemplate", null);
SelectorHardwareListTemplate = __decorate([
    Component
], SelectorHardwareListTemplate);
export default SelectorHardwareListTemplate;
