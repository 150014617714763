import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { DocumentType } from '~/db_types';
import BaseModalWithUser from '~/nasa_ui/base/BaseModalWithUser';
import { WorkOrderStepRepoMixin } from '~/nasa_ui/DAL/document/WorkOrderStep';
import { AlertType } from '~/nasa_ui/types';
import { DocumentTypeDisplay } from '~/nasa_ui/types/enums/document';
let ModalWorkOrderStepDelete = class ModalWorkOrderStepDelete extends Mixins(BaseModalWithUser, WorkOrderStepRepoMixin) {
    changeSet = null;
    changeSetId;
    get documentSubTypeDisplay() {
        // dont have the brain power to figure out this typing
        //   so im mapping and removing the (COSMIC)
        return this.changeSet?.document?.subType
            ? DocumentTypeDisplay.get(this.changeSet?.document?.subType)?.replace('(COSMIC)', '')
            : '';
    }
    get isFormValid() {
        return true;
    }
    get shouldDisableDeleteButton() {
        return !this.isFormValid;
    }
    get stepDisplayText() {
        return this.changeSet?.document?.subType === DocumentType.C_WORKFLOW ? 'Task' : 'Step';
    }
    async onClickOfDeleteWorkOrderStep() {
        const resp = await this.deleteWorkOrderStepById({
            id: this.changeSetId
        });
        if (resp) {
            this.$notification.add({
                text: 'Step deleted.',
                type: AlertType.SUCCESS
            });
        }
    }
    async fetchChangeSet(changeSetId) {
        try {
            const resp = await this.$apollo.query({
                query: gql `
          query FetchChangeSetOnDeleteModal($changeSetId: Int!) {
            changeSetById(id: $changeSetId) {
              nodeId
              id
              subType

              changeSetAuthorities {
                nodes {
                  authorityType
                  id
                  manualInspectionPointExplanation
                  manualInspectionPointType
                  nodeId
                  requiresQualityControlStamp
                  subType
                }
              }

              document {
                id
                nodeId
                subType
              }

              workOrderStep {
                id
                nodeId
                subType
                stepNumber
              }
            }
          }
        `,
                variables: {
                    changeSetId
                }
            });
            if (resp.data.changeSetById) {
                this.changeSet = resp.data.changeSetById;
            }
        }
        catch (err) {
            this.$errorUtility({ err });
        }
    }
    onChangeSetIdChange() {
        this.fetchChangeSet(this.changeSetId);
    }
};
__decorate([
    Prop({
        type: Number,
        required: true
    }),
    __metadata("design:type", Number)
], ModalWorkOrderStepDelete.prototype, "changeSetId", void 0);
__decorate([
    Emit('step-deleted'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], ModalWorkOrderStepDelete.prototype, "onClickOfDeleteWorkOrderStep", null);
__decorate([
    Watch('changeSetId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalWorkOrderStepDelete.prototype, "onChangeSetIdChange", null);
ModalWorkOrderStepDelete = __decorate([
    Component
], ModalWorkOrderStepDelete);
export default ModalWorkOrderStepDelete;
