var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "container pa-1 mt-2"
  }, [_c('SearchInputContainer', {
    attrs: {
      "autofocus": _vm.autofocus,
      "entity-type": _vm.EntityType.ITEM_MASTER,
      "hint": _vm.hint,
      "is-loading": _vm.isLoading,
      "placeholder": _vm.placeholder
    },
    on: {
      "searchButtonClicked": _vm.onClickOfSearchButton,
      "enterPressed": _vm.onClickOfSearchButton
    },
    model: {
      value: _vm.searchInput,
      callback: function ($$v) {
        _vm.searchInput = $$v;
      },
      expression: "searchInput"
    }
  }), _c('SearchFilterDisplay', {
    attrs: {
      "active": _vm.shouldShowFilters,
      "color": _vm.EntityType.ITEM_MASTER,
      "entity-type": _vm.EntityType.ITEM_MASTER,
      "chipsFromFilters": _vm.chipsFromFilters,
      "show-filter-button": _vm.showFilterButton
    },
    on: {
      "appChipCloseClicked": _vm.onClickOfRemoveFilter,
      "clearAllFilters": _vm.onClearAllFilters,
      "filterButtonClicked": _vm.onClickOfFilterIcon
    }
  }), _c('SearchFilterContainer', {
    attrs: {
      "color": _vm.EntityType.ITEM_MASTER,
      "show": _vm.shouldShowFilters
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "mb-3": "",
      "xs6": "",
      "pr-3": ""
    }
  }, [_c('AppText', {
    attrs: {
      "label": "Drawing number"
    },
    model: {
      value: _vm.searchFilters.drawingNumber,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "drawingNumber", $$v);
      },
      expression: "searchFilters.drawingNumber"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "mb-3": "",
      "xs6": ""
    }
  }, [_c('AppText', {
    attrs: {
      "label": "AsBuilt"
    },
    model: {
      value: _vm.searchFilters.asBuiltNumber,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "asBuiltNumber", $$v);
      },
      expression: "searchFilters.asBuiltNumber"
    }
  })], 1)], 1), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "mb-3": "",
      "xs6": "",
      "pr-3": ""
    }
  }, [_c('AppSelectEnum', {
    attrs: {
      "label": "Equipment group",
      "enum": _vm.ItemMasterType,
      "display": _vm.EquipmentGroupDisplay,
      "sort": "",
      "multiple": "",
      "disabled": _vm.isFilterLocked('equipmentGroup') || _vm.isFilterLocked('subType')
    },
    model: {
      value: _vm.searchFilters.subType,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "subType", $$v);
      },
      expression: "searchFilters.subType"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "mb-3": "",
      "xs6": ""
    }
  }, [_c('AppSelectEnum', {
    attrs: {
      "disabled": _vm.isFilterLocked('propertyClass'),
      "display": _vm.ItemMasterPropertyClassDisplay,
      "enum": _vm.ItemMasterPropertyClass,
      "label": "Property class",
      "multiple": "",
      "sort": ""
    },
    model: {
      value: _vm.searchFilters.propertyClass,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "propertyClass", $$v);
      },
      expression: "searchFilters.propertyClass"
    }
  })], 1)], 1), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "mb-3": "",
      "xs6": "",
      "pr-3": ""
    }
  }, [_c('AppSelectEnum', {
    attrs: {
      "label": "Inventory control",
      "enum": _vm.InventoryControl,
      "display": _vm.InventoryControlDisplay,
      "sort": "",
      "multiple": "",
      "disabled": _vm.isFilterLocked('inventoryControl')
    },
    model: {
      value: _vm.searchFilters.inventoryControl,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "inventoryControl", $$v);
      },
      expression: "searchFilters.inventoryControl"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "mb-3": "",
      "xs6": "",
      "pr-3": ""
    }
  }, [_c('AppSelect', {
    attrs: {
      "label": "Approved asDesigned",
      "selectOptions": _vm.yesNoOptions,
      "disabled": _vm.isFilterLocked('isApprovedAsDesign'),
      "clearable": ""
    },
    model: {
      value: _vm.searchFilters.isApprovedAsDesign,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "isApprovedAsDesign", $$v);
      },
      expression: "searchFilters.isApprovedAsDesign"
    }
  })], 1)], 1), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "mb-3": "",
      "xs6": "",
      "pr-3": ""
    }
  }, [_c('AppSelectEnum', {
    attrs: {
      "label": "Initialization status",
      "enum": _vm.ChangeSetStatus,
      "display": _vm.ChangeSetStatusDisplay,
      "sort": "",
      "multiple": "",
      "disabled": _vm.isFilterLocked('initializationStatus')
    },
    model: {
      value: _vm.searchFilters.initializationStatus,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "initializationStatus", $$v);
      },
      expression: "searchFilters.initializationStatus"
    }
  })], 1)], 1), _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs3": "",
      "mr-4": ""
    }
  }, [_c('AppNumber', {
    attrs: {
      "label": "Max results",
      "wholeNumber": "",
      "max": 1000,
      "min": 0
    },
    model: {
      value: _vm.userSelectedMaxResults,
      callback: function ($$v) {
        _vm.userSelectedMaxResults = $$v;
      },
      expression: "userSelectedMaxResults"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs3": ""
    }
  }, [_c('AppCheckbox', {
    attrs: {
      "color": _vm.EntityType.ITEM_MASTER,
      "label": "In Inventory"
    },
    model: {
      value: _vm.inInventoryLocal,
      callback: function ($$v) {
        _vm.inInventoryLocal = $$v;
      },
      expression: "inInventoryLocal"
    }
  })], 1)], 1)], 1), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_c('AppLoader', {
    staticClass: "ma-0",
    attrs: {
      "color": _vm.EntityType.ITEM_MASTER,
      "show": _vm.isLoading,
      "type": "linear"
    }
  })], 1), _c('v-slide-y-transition', {
    attrs: {
      "tag": "table",
      "hide-on-leave": ""
    }
  }, [_vm.shouldShowTable ? _c('AppTable', {
    key: "searchResults",
    attrs: {
      "color": _vm.EntityType.ITEM_MASTER,
      "headers": _vm.tableHeaders,
      "items": _vm.searchResults,
      "rows-per-page-items": _vm.defaultHardwareTablePagination,
      "show-count": false,
      "title": _vm.resultTableTitle,
      "dense-rows": "",
      "disable-initial-sort": "",
      "selectable-rows": "",
      "sticky": ""
    },
    on: {
      "metaRowClicked": _vm.onMetaClickOfSearchResult,
      "rowClicked": _vm.onClickOfSearchResult
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };