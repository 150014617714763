var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop } from 'vue-property-decorator';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
let FileAttachmentDropzone = class FileAttachmentDropzone extends BaseCosmic {
    dragOver = false;
    isButton;
    label;
    multiple;
    onDragLeave(event) {
        this.dragOver = false;
    }
    onDragOver(event) {
        this.dragOver = true;
    }
    onDrop(event) {
        this.dragOver = false;
        const files = event.dataTransfer?.files;
        if (!files) {
            return;
        }
        if (this.multiple) {
            this.emitDrop(files);
            return;
        }
        this.emitDrop(files[0]);
    }
    emitDrop(files) { }
    emitDropzoneClicked() { }
};
__decorate([
    Prop({
        type: Boolean,
        default: true
    }),
    __metadata("design:type", Boolean)
], FileAttachmentDropzone.prototype, "isButton", void 0);
__decorate([
    Prop({
        type: String,
        default: 'Click or drag a file here to add it as an attachment.'
    }),
    __metadata("design:type", String)
], FileAttachmentDropzone.prototype, "label", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], FileAttachmentDropzone.prototype, "multiple", void 0);
__decorate([
    Emit('drop'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], FileAttachmentDropzone.prototype, "emitDrop", null);
__decorate([
    Emit('dropzone-clicked'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], FileAttachmentDropzone.prototype, "emitDropzoneClicked", null);
FileAttachmentDropzone = __decorate([
    Component
], FileAttachmentDropzone);
export default FileAttachmentDropzone;
