var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.isLoading ? _c('AppLoader', {
    attrs: {
      "type": "linear",
      "color": _vm.EntityType.DOCUMENT
    }
  }) : _c('div', [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [!_vm.isReadOnly ? _c('c-text', {
    attrs: {
      "color": _vm.EntityType.DOCUMENT,
      "label": "Title",
      "required": "",
      "c-value": _vm.formData.title
    },
    on: {
      "c-input": function ($event) {
        _vm.formData.title = $event.detail;
      }
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "Title"
    }
  }, [_vm._v(_vm._s(_vm.formData.title))])], 1), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [!_vm.isReadOnly ? _c('AppMarkdown', {
    attrs: {
      "label": "Description"
    },
    model: {
      value: _vm.formData.description,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "description", $$v);
      },
      expression: "formData.description"
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "Description"
    }
  }, [_c('AppMarkdownDisplay', {
    attrs: {
      "markdown": _vm.formData.description
    }
  })], 1)], 1)], 1), _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "sm6": ""
    }
  }, [!_vm.isReadOnly ? _c('AppContact', {
    attrs: {
      "label": "Initiator",
      "required": ""
    },
    model: {
      value: _vm.formData.initiatorContactId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "initiatorContactId", $$v);
      },
      expression: "formData.initiatorContactId"
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "Initiator"
    }
  }, [_vm._v(_vm._s(_vm.contactDisplay))])], 1)], 1), _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs12": "",
      "sm6": ""
    }
  }, [!_vm.isReadOnly ? _c('AppDate', {
    attrs: {
      "label": "Due"
    },
    model: {
      value: _vm.formData.dueDate,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "dueDate", $$v);
      },
      expression: "formData.dueDate"
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "Due"
    }
  }, [_vm._v(_vm._s(_vm.$dateDisplay(_vm.formData.dueDate)))])], 1)], 1), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppPageDescription', {
    staticClass: "mb-2",
    attrs: {
      "remove-margin-left": ""
    }
  }, [_vm._v(" Utilize \"Tags\" for help when searching later. ")])], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md10": ""
    }
  }, [_c('TagsInput', {
    attrs: {
      "document-id": _vm.id
    },
    model: {
      value: _vm.formData.tags,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "tags", $$v);
      },
      expression: "formData.tags"
    }
  })], 1)], 1)], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('AppDynamicWrapper', {
    staticClass: "pr-1",
    attrs: {
      "color": _vm.EntityType.DOCUMENT,
      "color-modifier": "",
      "display-type": _vm.AppDynamicWrapperType.FORM_WRAPPER,
      "size": "1"
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": "",
      "pb-0": "",
      "pl-0": ""
    }
  }, [_c('AppPageDescription', {
    attrs: {
      "remove-margin-left": "",
      "color": _vm.EntityType.DOCUMENT
    }
  }, [_vm._v("Data Center fields")])], 1), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [!_vm.isReadOnly ? _c('c-text', {
    attrs: {
      "color": "var(--v-documents-base)",
      "label": "Letter number",
      "c-value": _vm.formData.workflowLetterNumber,
      "monospaced": ""
    },
    on: {
      "c-input": function ($event) {
        _vm.formData.workflowLetterNumber = $event.detail;
      }
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "Letter number"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.formData.workflowLetterNumber || '-') + " ")])]), !_vm.isReadOnly ? _c('c-text', {
    attrs: {
      "color": _vm.EntityType.DOCUMENT,
      "label": "Non-COSMIC Document number",
      "c-value": _vm.formData.workflowDocumentNumber,
      "monospaced": ""
    },
    on: {
      "c-input": function ($event) {
        _vm.formData.workflowDocumentNumber = $event.detail;
      }
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "Non-COSMIC Document number"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.formData.workflowDocumentNumber || '-') + " ")])]), !_vm.readOnly ? _c('AppDate', {
    attrs: {
      "color": _vm.EntityType.DOCUMENT,
      "label": "Received"
    },
    model: {
      value: _vm.formData.workflowReceivedDate,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "workflowReceivedDate", $$v);
      },
      expression: "formData.workflowReceivedDate"
    }
  }) : _c('AppBlockquote', {
    attrs: {
      "title": "Received"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.$dateDisplay(_vm.formData.workflowReceivedDate)) + " ")])])], 1)], 1)], 1)], 1)], 1), _c('v-layout', {
    attrs: {
      "row": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [!_vm.isReadOnly ? _c('ButtonSave', {
    staticClass: "ml-0",
    attrs: {
      "disabled": _vm.isLoading || !_vm.isFormValid,
      "is-saving": _vm.isSaving
    },
    on: {
      "click": _vm.onClickOfSave
    }
  }) : _vm._e()], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };