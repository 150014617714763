var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "container fluid pa-0"
  }, [!_vm.selectedDocument ? _c('AppLoader', {
    attrs: {
      "type": "linear"
    }
  }) : _vm._e(), _vm.isReadOnly && !_vm.steps.length && !_vm.isFetching ? _c('Alert', {
    attrs: {
      "type": _vm.AlertType.WARNING
    }
  }, [_vm._v(" Document is in "), _c('b', [_vm._v("read-only")]), _vm._v(" mode and no steps have been created. ")]) : _vm._e(), _vm.selectedDocument && _vm.currentViewingStep ? _c('v-layout', {
    staticClass: "cosmic_step_action_container",
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    staticClass: "action_container_1",
    attrs: {
      "xs5": ""
    }
  }, [_c('ButtonGeneric', {
    attrs: {
      "icon": _vm.$icons.arrow_left,
      "to": "#tasks_overview",
      "small": ""
    }
  }, [_vm._v("Overview")])], 1), _c('v-flex', {
    staticClass: "action_container_2",
    attrs: {
      "xs2": ""
    }
  }, [_c('ButtonMiniAction', {
    attrs: {
      "disabled": _vm.shouldDisablePreviousButton,
      "icon-size": "28px",
      "icon": "fad fa-caret-circle-left",
      "icon-color": "var(--cosmic-text)",
      "tooltip": "Previous task"
    },
    on: {
      "click": _vm.onClickOfPreviousStepButton
    }
  }), _c('div', {
    staticClass: "step_number"
  }, [_c('div', [_c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on
      }) {
        return [_c('v-slide-y-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [_c('span', _vm._g({}, on), [_vm._v(_vm._s(_vm.currentViewingStep.stepNumber))])])];
      }
    }], null, false, 3028458669)
  }, [_c('span', [_vm._v("Currently viewing task " + _vm._s(_vm.currentViewingStep.stepNumber) + ".")])])], 1)]), _c('ButtonMiniAction', {
    attrs: {
      "disabled": _vm.shouldDisableNextButton,
      "icon-size": "28px",
      "icon": "fad fa-caret-circle-right",
      "tooltip": "Next task",
      "icon-color": "var(--cosmic-text)"
    },
    on: {
      "click": _vm.onClickOfNextStepButton
    }
  })], 1), _c('v-flex', {
    staticClass: "action_container_3",
    attrs: {
      "xs5": ""
    }
  }, [_vm.stepsStepNumberList.length ? _c('div', [_c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on
      }) {
        return [_c('div', _vm._g({
          staticClass: "jump_to_step"
        }, on), [_c('AppSelect', {
          staticClass: "just_to_step_select",
          attrs: {
            "chips": false,
            "clearable": false,
            "select-options": _vm.stepsStepNumberList,
            "width": "500px",
            "label": "Jump to task",
            "value": _vm.currentViewingStepIndex
          },
          on: {
            "input": val => _vm.currentViewingStepIndex = val || 0
          },
          scopedSlots: _vm._u([{
            key: "select-option",
            fn: function ({
              option
            }) {
              var _vm$steps$option$valu, _vm$steps$option$valu2;
              return [_vm._v(" " + _vm._s((_vm$steps$option$valu = _vm.steps[option.value]) === null || _vm$steps$option$valu === void 0 ? void 0 : _vm$steps$option$valu.stepNumber) + " - "), _c('small', [_vm._v(_vm._s(((_vm$steps$option$valu2 = _vm.steps[option.value]) === null || _vm$steps$option$valu2 === void 0 ? void 0 : _vm$steps$option$valu2.action) || 'No procedure text.'))])];
            }
          }], null, true)
        })], 1)];
      }
    }], null, false, 1973803730)
  }, [_c('span', [_vm._v("Jump to task")])])], 1) : _vm._e()])], 1) : _vm._e(), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_c('div', {
    staticClass: "steps_container"
  }, [_c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.shouldDisableCreateButton && !_vm.steps.length && !_vm.isFetching ? _c('div', [_c('Alert', {
    attrs: {
      "type": _vm.AlertType.INFO
    }
  }, [_c('p', [_vm._v("This Document has no tasks.")])])], 1) : _vm._e()]), _c('v-slide-y-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.currentViewingStep ? _c('div', {
    key: "step",
    staticClass: "step"
  }, [_c('v-slide-y-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.currentViewingStepComplete ? _c('div', {
    staticClass: "step_completed"
  }, [_c('c-icon-fa', {
    staticClass: "mr-2",
    attrs: {
      "icon": _vm.$icons.pipeline_complete,
      "icon-color": "white"
    }
  }), _c('span', [_vm._v("Task complete.")])], 1) : _vm._e()]), _c('v-slide-y-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.currentViewingStepRejected ? _c('div', {
    staticClass: "step_rejected"
  }, [_c('c-icon-fa', {
    staticClass: "mr-2",
    attrs: {
      "icon": _vm.$icons.pipeline_rejected,
      "icon-color": "white"
    }
  }), _c('span', [_vm._v("Task rejected.")])], 1) : _vm._e()]), _c('div', {
    staticClass: "step_guts",
    class: {
      completed: _vm.currentViewingStepComplete,
      rejected: _vm.currentViewingStepRejected
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_vm.currentViewingStep.assigneeContact ? _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": "",
      "justify-end": ""
    }
  }, [_c('v-flex', {
    staticStyle: {
      "text-align": "right"
    },
    attrs: {
      "xs12": ""
    }
  }, [_c('v-chip', {
    staticClass: "font-weight-bold",
    attrs: {
      "close": false,
      "color": _vm.EntityType.USER,
      "outline": false,
      "tooltip": `${_vm.stepDisplayText} assignee`,
      "disabled": "",
      "text-color": "white"
    }
  }, [_c('c-icon-fa', {
    staticClass: "mr-2",
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.USER],
      "icon-size": "18px",
      "icon-color": "white"
    }
  }), _vm._v(" " + _vm._s(_vm.$contactDisplay(_vm.currentViewingStep.assigneeContact)) + " ")], 1)], 1)], 1)], 1) : _vm._e(), _vm.selectedDocument && _vm.selectedDocument.subType === _vm.DocumentType.C_RCA ? _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('RcaTaskDetailsSummary', {
    attrs: {
      "task": _vm.currentViewingStep
    }
  })], 1) : _c('v-flex', {
    staticStyle: {
      "position": "relative"
    },
    attrs: {
      "xs12": ""
    }
  }, [_c('c-card', {
    attrs: {
      "border-color": "var(--v-documents-base)"
    }
  }, [_c('AppHeader', {
    staticClass: "mb-2"
  }, [_vm._v(" " + _vm._s(_vm.stepDisplayText) + " "), _c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.currentViewingStep.stepNumber))]), _vm.isDocumentApproved ? _c('div', {
    staticClass: "approve_reject_container"
  }, [_vm.currentUserCanApproveStep ? _c('ButtonGeneric', {
    attrs: {
      "icon": "fad fa-thumbs-up",
      "color": "success"
    },
    on: {
      "click": _vm.onClickOfCompleteStepButton
    }
  }, [_vm._v(" Complete ")]) : _vm._e(), _vm.currentUserCanApproveStep && _vm.shouldShowRejectButton ? _c('ButtonGeneric', {
    attrs: {
      "icon": "fad fa-thumbs-down",
      "color": "error"
    },
    on: {
      "click": _vm.onClickOfRejectStepButton
    }
  }, [_vm._v(" Reject ")]) : _vm._e()], 1) : _vm._e()]), _c('AppMarkdownDisplay', {
    attrs: {
      "markdown": _vm.currentViewingStep.action,
      "maxHeight": "auto"
    }
  })], 1)], 1)], 1), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": "",
      "mt-2": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('c-card', {
    attrs: {
      "border-color": "grey"
    }
  }, [_c('div', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('c-icon-fa', {
    staticClass: "mr-2",
    attrs: {
      "slot": "icon",
      "icon": _vm.$icons.attachment,
      "icon-color": "var(--cosmic-text)"
    },
    slot: "icon"
  }), _c('span', {
    staticClass: "expansion_override_title"
  }, [_vm._v("Attachments")])], 1), _c('WorkOrderStepAttachments', {
    attrs: {
      "work-order-step-id": _vm.currentViewingStep.id,
      "read-only": !_vm.isDocumentOpenForEdits
    }
  })], 1)], 1)], 1), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": "",
      "mt-2": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('c-card', {
    attrs: {
      "border-color": "var(--v-change_sets-base)"
    }
  }, [_c('div', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('c-icon-fa', {
    staticClass: "mr-2",
    attrs: {
      "slot": "icon",
      "icon": "fa-regular fa-balance-scale",
      "icon-color": "var(--cosmic-text)"
    },
    slot: "icon"
  }), _c('span', {
    staticClass: "expansion_override_title"
  }, [_vm._v(" Approvals ( "), _c('strong', [_vm._v(_vm._s(_vm.$numberDisplay(_vm.countApprovals)))]), _vm._v(" )")])], 1), _c('AppTable', {
    attrs: {
      "color": _vm.EntityType.CHANGE_SET,
      "headers": _vm.approvalTableHeaders,
      "items": _vm.approvalTableItems,
      "rows-per-page-items": [-1],
      "show-count": false,
      "show-header": false,
      "dense-rows": "",
      "disable-initial-sort": "",
      "hide-pagination": "",
      "sticky": ""
    }
  })], 1)], 1)], 1), _vm.currentHistoricalChangeSetAuthorities ? _c('v-layout', {
    attrs: {
      "mt-2": "",
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    staticClass: "text-xs-right",
    attrs: {
      "xs12": "",
      "mb-2": ""
    }
  }, [_c('ButtonGeneric', {
    attrs: {
      "tooltip": "More info",
      "tooltip-position": "left",
      "icon": "fa-regular fa-balance-scale",
      "color": "var(--v-change_sets-base)"
    },
    on: {
      "click": function ($event) {
        _vm.showMoreInfo = !_vm.showMoreInfo;
      }
    }
  }, [_vm._v(" View historical approvals ")])], 1), _c('v-slide-x-transition', [_vm.showMoreInfo ? _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppTable', {
    attrs: {
      "color": _vm.EntityType.CHANGE_SET,
      "headers": _vm.approvalsTableHeaders,
      "items": _vm.currentHistoricalChangeSetAuthorities,
      "dense-rows": "",
      "disable-initial-sort": "",
      "icon": "fa-regular fa-balance-scale",
      "sticky": "",
      "title": "Historical approvals"
    }
  })], 1) : _vm._e()], 1)], 1) : _vm._e()], 1)], 1) : _c('div', {
    staticClass: "no_current_step"
  }, [_vm.selectedDocument && !_vm.isReadOnly && !_vm.shouldDisableCreateButton ? _c('WorkOrderStepsGettingStarted', {
    attrs: {
      "document-id": _vm.id,
      "document-type": _vm.selectedDocument.subType,
      "read-only": _vm.isReadOnly
    },
    on: {
      "created": _vm.onModalWorkOrderStepCreateAndEditClose
    }
  }) : _vm._e()], 1)])], 1)]), _vm.showNewWorkOrderStepModal ? _c('ModalWorkOrderStepCreateAndEdit', {
    attrs: {
      "document-id": _vm.id,
      "default-step-number": _vm.stepsDefault,
      "max-step-number": _vm.stepsMax,
      "read-only": _vm.isReadOnly,
      "work-order-step-to-edit-id": _vm.workOrderStepToEditId
    },
    on: {
      "modal-close": function ($event) {
        _vm.showNewWorkOrderStepModal = false;
      },
      "step-saved": _vm.onModalWorkOrderStepCreateAndEditClose
    }
  }) : _vm._e(), _vm.showCompleteStepModal ? _c('ModalWorkOrderStepComplete', {
    attrs: {
      "document-id": _vm.id,
      "change-set-ids": _vm.changeSetIdsToApprove,
      "should-require-comment": false
    },
    on: {
      "modal-close": function ($event) {
        _vm.showCompleteStepModal = false;
      }
    }
  }) : _vm._e(), _vm.showRejectStepModal && _vm.currentViewingStep ? _c('ModalWorkOrderStepReject', {
    attrs: {
      "change-set-id": _vm.currentViewingStep.id,
      "should-require-comment": false
    },
    on: {
      "modal-close": function ($event) {
        _vm.showRejectStepModal = false;
      },
      "step-rejected": _vm.onWorkOrderStepReject
    }
  }) : _vm._e(), _vm.showDeleteStepModal && _vm.currentViewingStep ? _c('ModalWorkOrderStepDelete', {
    attrs: {
      "change-set-id": _vm.currentViewingStep.id
    },
    on: {
      "modal-close": function ($event) {
        _vm.showDeleteStepModal = false;
      },
      "step-deleted": _vm.onWorkOrderStepDelete
    }
  }) : _vm._e(), _vm.showImportSpreadsheetModal && _vm.selectedDocument ? _c('ModalWorkOrderStepImportSpreadsheet', {
    attrs: {
      "document-id": _vm.id,
      "document-type": _vm.selectedDocument.subType,
      "starting-step": _vm.stepsDefault
    },
    on: {
      "modal-close": function ($event) {
        _vm.showImportSpreadsheetModal = false;
      },
      "created": _vm.onImportSpreadsheetCompletion
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };