var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('v-card', {
    class: {
      card_maximized: 'isCardMaximized'
    }
  }, [_vm.transformedWorkOrderStep ? _c('v-card-title', {
    staticClass: "px-2 py-1 steps_overview_title",
    class: {
      approved: _vm.isStepApproved,
      error: _vm.isStepRejected,
      rejected: _vm.isStepRejected,
      success: _vm.isStepApproved
    },
    on: {
      "dblclick": function ($event) {
        $event.stopPropagation();
        return _vm.onTitleDblClick.apply(null, arguments);
      }
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": "",
      "align-center": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs6": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": "",
      "align-center": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs4": ""
    }
  }, [_c('h2', {
    staticClass: "font-weight-regular",
    class: {
      'white--text': _vm.isStepApproved || _vm.isStepRejected
    }
  }, [_vm._v(" " + _vm._s(_vm.stepDisplayText) + " "), _c('span', {
    staticClass: "monospace_font pr-2"
  }, [_vm._v(_vm._s(_vm.step.stepNumber))])])]), _vm.isStepApproved || _vm.isStepRejected ? _c('v-flex', {
    staticClass: "white--text completion_message",
    attrs: {
      "xs8": ""
    }
  }, [_c('span', {
    staticClass: "completion_message__slug"
  }, [_vm._v(_vm._s(_vm.isStepApproved ? 'approved' : 'rejected') + " on")]), _c('span', {
    staticClass: "monospace_font font-weight-bold"
  }, [_vm._v(_vm._s(_vm.transformedWorkOrderStep._computedClosedDate))])]) : _vm._e()], 1)], 1), _c('v-flex', {
    attrs: {
      "xs6": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "no-wrap": "",
      "justify-end": "",
      "align-center": "",
      "dates-and-actions": ""
    }
  }, [_vm.shouldShowDueDate ? _c('div', {
    staticClass: "text-xs-right",
    on: {
      "dblclick": _vm.onDueDateDblClick
    }
  }, [_vm.isBeforeTodayInclusive(_vm.step.projectedDate) ? _c('strong', {
    staticClass: "date_wrap error--text"
  }, [_c('c-icon-fa', {
    staticClass: "pr-2",
    attrs: {
      "icon": _vm.$icons.exclamation
    }
  }), _c('span', [_vm._v(_vm._s(_vm.documentType === _vm.DocumentType.C_RCA ? 'Estimated completion ' : 'Due '))]), _c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.$dateDisplay(_vm.step.projectedDate)))])], 1) : _vm.step.projectedDate ? _c('div', [_c('span', [_vm._v(_vm._s(_vm.documentType === _vm.DocumentType.C_RCA ? 'Estimated completion ' : 'Due '))]), _c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.$dateDisplay(_vm.step.projectedDate)))])]) : _vm._e()]) : _vm._e(), _vm.isStepApproved || _vm.isStepRejected ? _c('div', [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": "",
      "justify-end": ""
    }
  }, [_vm.isCardMaximized ? _c('ButtonMiniAction', {
    attrs: {
      "icon-color": "#fff",
      "icon-size": "18",
      "icon": "fas fa-window-minimize",
      "tooltip": "Minimize card"
    },
    on: {
      "click": function ($event) {
        _vm.isCardMaximized = false;
      }
    }
  }) : _vm._e(), !_vm.isCardMaximized ? _c('ButtonMiniAction', {
    attrs: {
      "icon-color": "#fff",
      "icon-size": "18",
      "icon": "fas fa-window-maximize",
      "tooltip": "Maximize card"
    },
    on: {
      "click": function ($event) {
        _vm.isCardMaximized = true;
      }
    }
  }) : _vm._e()], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "ml-2"
  }, [_c('c-menu', {
    attrs: {
      "align-right": ""
    }
  }, [_c('ButtonMiniActionEllipsis', {
    attrs: {
      "slot": "activator",
      "icon-color": "var(--cosmic-text)"
    },
    slot: "activator"
  }), _c('c-menu-item', {
    on: {
      "c-click": function ($event) {
        _vm.showWorkOrderStepModal = true;
      }
    }
  }, [_vm._v("Edit details")]), !_vm.hideHardwareAndTools ? _c('c-menu-item', {
    on: {
      "c-click": function ($event) {
        _vm.showHardwareModal = true;
      }
    }
  }, [_vm._v("Edit hardware")]) : _vm._e(), !_vm.hideHardwareAndTools ? _c('c-menu-item', {
    on: {
      "c-click": function ($event) {
        _vm.showToolsModal = true;
      }
    }
  }, [_vm._v("Edit tools")]) : _vm._e(), _c('c-menu-item', {
    on: {
      "c-click": function ($event) {
        _vm.showApprovalsModal = true;
      }
    }
  }, [_vm._v("Edit approvals")]), _c('c-menu-item', {
    on: {
      "c-click": function ($event) {
        _vm.showAttachmentsModal = true;
      }
    }
  }, [_vm._v("Edit attachments")]), _c('hr'), _c('c-menu-item', {
    attrs: {
      "color": "var(--v-error-base)"
    },
    on: {
      "c-click": function ($event) {
        _vm.showDeleteConfirmation = true;
      }
    }
  }, [_vm._v("Delete " + _vm._s(_vm.stepDisplayText))])], 1)], 1)])], 1)], 1), false ? _c('c-icon-fa', {
    staticClass: "pr-2",
    attrs: {
      "icon": _vm.$icons.exclamation,
      "icon-color": "white",
      "tooltip": `${_vm.stepDisplayText} rejected.`
    }
  }) : _vm._e(), false ? _c('v-tooltip', {
    staticClass: "text-xs-right mip-tooltip",
    attrs: {
      "color": "white"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on
      }) {
        return [_c('span', _vm._g({}, on), [_c('AppChip', {
          staticClass: "mip-indicator",
          attrs: {
            "chips": [{
              text: 'MIP',
              color: 'white',
              outline: true
            }]
          }
        })], 1)];
      }
    }], null, false, 265588579)
  }, [_c('v-layout', {
    staticClass: "mip-tooltip",
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs4": ""
    }
  }, [_c('AppBlockquote', {
    staticClass: "black--text mip-flex-item",
    attrs: {
      "title": "Requires a MIP?",
      "center": ""
    }
  }, [_c('AppBooleanDisplay', {
    staticClass: "d-inline-block",
    attrs: {
      "value": _vm.step.requiresManualInspectionPoint
    }
  })], 1)], 1), _c('v-flex', {
    attrs: {
      "xs4": ""
    }
  }, [_c('AppBlockquote', {
    staticClass: "black--text mip-flex-item",
    attrs: {
      "title": "GMIP?",
      "center": ""
    }
  }, [_c('AppBooleanDisplay', {
    staticClass: "d-inline-block",
    attrs: {
      "value": _vm.step.isGovernmentManualInspectionPoint
    }
  })], 1)], 1), _c('v-flex', {
    attrs: {
      "xs4": ""
    }
  }, [_c('AppBlockquote', {
    staticClass: "black--text mip-flex-item",
    attrs: {
      "title": "MIP Type",
      "center": "",
      "value": _vm.step.manualInspectionPointType
    }
  })], 1), _c('v-divider'), _vm.step.manualInspectionPointExplanation ? _c('v-flex', {
    attrs: {
      "xs12": "",
      "mt-2": ""
    }
  }, [_c('AppMarkdownDisplay', {
    attrs: {
      "markdown": _vm.step.manualInspectionPointExplanation
    }
  })], 1) : _vm._e()], 1)], 1) : _vm._e()], 1) : _vm._e(), _c('v-slide-y-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.isCardMaximized ? _c('div', {
    staticClass: "card_body"
  }, [_c('v-layout', {
    staticClass: "pl-2 pt-0",
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_vm.step.assigneeContact ? _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": "",
      "justify-end": ""
    }
  }, [_c('v-flex', {
    staticStyle: {
      "text-align": "right"
    },
    attrs: {
      "xs12": ""
    }
  }, [_c('v-chip', {
    staticClass: "font-weight-bold",
    attrs: {
      "close": false,
      "color": _vm.EntityType.USER,
      "outline": false,
      "tooltip": `${_vm.stepDisplayText} assignee`,
      "disabled": "",
      "text-color": "white"
    }
  }, [_c('c-icon-fa', {
    staticClass: "pr-2",
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.USER],
      "icon-size": "18px"
    }
  }), _vm._v(" " + _vm._s(_vm.$contactDisplay(_vm.step.assigneeContact)) + " ")], 1)], 1)], 1)], 1) : _vm._e(), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('div', {
    staticClass: "alerts"
  }, [_vm.step.warning ? _c('Alert', {
    attrs: {
      "type": "error"
    }
  }, [_c('AppMarkdownDisplay', {
    staticClass: "action-text",
    attrs: {
      "markdown": _vm.step.warning
    }
  })], 1) : _vm._e(), _vm.step.caution ? _c('Alert', {
    attrs: {
      "type": "warning"
    }
  }, [_c('AppMarkdownDisplay', {
    staticClass: "action-text",
    attrs: {
      "markdown": _vm.step.caution
    }
  })], 1) : _vm._e(), _vm.step.notes ? _c('Alert', {
    attrs: {
      "type": "info"
    }
  }, [_c('AppMarkdownDisplay', {
    staticClass: "action-text",
    attrs: {
      "markdown": _vm.step.notes
    }
  })], 1) : _vm._e()], 1), _vm.documentType === _vm.DocumentType.C_RCA ? _c('div', {
    staticClass: "mt-2"
  }, [_c('RcaTaskDetailsSummary', {
    attrs: {
      "task": _vm.step
    }
  })], 1) : _c('div', [_vm.step.action ? _c('AppMarkdownDisplay', {
    staticClass: "action-text procedure-text mb-3",
    attrs: {
      "markdown": _vm.step.action
    }
  }) : _vm._e()], 1)]), _vm.countHardwareNonTools ? _c('v-flex', {
    staticClass: "pr-2",
    attrs: {
      "xs12": ""
    }
  }, [_c('AppExpansionPanel', {
    attrs: {
      "color": _vm.EntityType.INVENTORY,
      "is-open": false,
      "dense": ""
    }
  }, [_c('template', {
    slot: "title"
  }, [_vm._v("Hardware ( "), _c('strong', [_vm._v(_vm._s(_vm.$numberDisplay(_vm.countHardwareNonTools)))]), _vm._v(" ) ")])], 2)], 1) : _vm._e(), _vm.countTools ? _c('v-flex', {
    staticClass: "pr-2",
    attrs: {
      "xs12": ""
    }
  }, [_c('AppExpansionPanel', {
    attrs: {
      "color": _vm.EntityType.INVENTORY,
      "is-open": false,
      "dense": ""
    }
  }, [_c('template', {
    slot: "title"
  }, [_vm._v("Tools ( "), _c('strong', [_vm._v(_vm._s(_vm.$numberDisplay(_vm.countTools)))]), _vm._v(" ) ")])], 2)], 1) : _vm._e(), _vm.countApprovals ? _c('v-flex', {
    staticClass: "pr-2",
    attrs: {
      "xs12": ""
    }
  }, [_c('AppExpansionPanel', {
    attrs: {
      "color": _vm.EntityType.CHANGE_SET,
      "fullWidthContent": true,
      "is-open": true,
      "dense": ""
    }
  }, [_c('template', {
    slot: "title"
  }, [_vm._v(" Approvals ( "), _c('strong', [_vm._v(_vm._s(_vm.$numberDisplay(_vm.countApprovals)))]), _vm._v(" ) ")]), _c('AppTable', {
    attrs: {
      "color": _vm.EntityType.CHANGE_SET,
      "headers": _vm.approvalTableHeaders,
      "items": _vm.approvalTableItems,
      "rows-per-page-items": [-1],
      "show-count": false,
      "show-header": false,
      "dense-rows": "",
      "disable-initial-sort": "",
      "hide-pagination": "",
      "sticky": ""
    }
  })], 2)], 1) : _vm._e(), _vm.countAttachments ? _c('v-flex', {
    staticClass: "pr-2",
    attrs: {
      "xs12": ""
    }
  }, [_c('AppExpansionPanel', {
    attrs: {
      "color": _vm.EntityType.COMMENT,
      "is-open": false,
      "dense": ""
    }
  }, [_c('template', {
    slot: "title"
  }, [_vm._v("Attachments ( "), _c('strong', [_vm._v(_vm._s(_vm.$numberDisplay(_vm.countAttachments)))]), _vm._v(" ) ")]), _c('AppTable', {
    attrs: {
      "color": _vm.EntityType.COMMENT,
      "headers": _vm.attachmentTableHeaders,
      "items": _vm.attachmentTableItems,
      "rows-per-page-items": [-1],
      "show-count": false,
      "show-header": false,
      "dense-rows": "",
      "disable-initial-sort": "",
      "hide-pagination": "",
      "sticky": ""
    }
  })], 2)], 1) : _vm._e(), _c('ButtonGeneric', {
    staticClass: "mb-2",
    attrs: {
      "color": "primary",
      "icon": "fa-duotone fa-copy",
      "small": ""
    },
    on: {
      "click": function ($event) {
        _vm.showWorkOrderStepModal = true, _vm.isDuplication = true;
      }
    }
  }, [_vm._v(" Duplicate Task ")])], 1), _c('v-card-actions', {
    staticClass: "work_order_actions"
  }, [_vm.isInitialized ? _c('ButtonGeneric', {
    attrs: {
      "color": "primary",
      "icon": "fa-duotone fa-location",
      "small": ""
    },
    on: {
      "click": _vm.onClickOfGoToStep
    }
  }, [_vm._v(" View single " + _vm._s(_vm.stepDisplayText) + " ")]) : _vm._e(), _c('v-spacer'), _vm.approvalsCanBeReset ? _c('div', [_c('ButtonGeneric', {
    attrs: {
      "color": "var(--v-warning-darken3)",
      "icon": "fad fa-undo",
      "small": "",
      "text-color": "white--text"
    },
    on: {
      "click": function ($event) {
        _vm.showReopenConfirmation = true;
      }
    }
  }, [_c('span', [_vm._v("Reset approvals")])])], 1) : _vm._e()], 1)], 1) : _vm._e()])], 1), _vm.showWorkOrderStepModal ? _c('ModalWorkOrderStepCreateAndEdit', {
    attrs: {
      "document-id": _vm.docId,
      "max-step-number": _vm.step.stepNumber + 1,
      "work-order-step-to-edit-id": _vm.step.id,
      "isDuplication": _vm.isDuplication
    },
    on: {
      "modal-close": function ($event) {
        _vm.showWorkOrderStepModal = false;
      },
      "step-saved": _vm.onStepSave
    }
  }) : _vm._e(), _vm.showDeleteConfirmation ? _c('AreYouSureDialog', {
    attrs: {
      "title": `Delete ${_vm.stepDisplayText} ${_vm.step.stepNumber}?`
    },
    on: {
      "negativeClicked": function ($event) {
        _vm.showDeleteConfirmation = false;
      },
      "affirmativeClicked": _vm.deleteStep
    }
  }, [_c('p', {
    attrs: {
      "slot": "text"
    },
    slot: "text"
  }, [_c('strong', [_vm._v("WARNING:")]), _vm._v(" Are you sure you'd like to delete " + _vm._s(_vm.stepDisplayText) + " " + _vm._s(_vm.step.stepNumber) + " from this document? ")])]) : _vm._e(), _vm.showReopenConfirmation ? _c('AreYouSureDialog', {
    attrs: {
      "title": `Reset approvals on ${_vm.stepDisplayText} ${_vm.step.stepNumber}?`
    },
    on: {
      "negativeClicked": function ($event) {
        _vm.showReopenConfirmation = false;
      },
      "affirmativeClicked": _vm.reopenStep
    }
  }, [_c('p', {
    attrs: {
      "slot": "text"
    },
    slot: "text"
  }, [_c('strong', [_vm._v("WARNING:")]), _vm._v(" Are you sure you'd like to reset the approvals for " + _vm._s(_vm.stepDisplayText) + " " + _vm._s(_vm.step.stepNumber) + "? ")])]) : _vm._e(), _vm.showHardwareModal ? _c('ModalWorkOrderStepHardwareManage', {
    attrs: {
      "doc-id": _vm.docId,
      "step-id": _vm.step.id
    },
    on: {
      "modal-close": function ($event) {
        _vm.showHardwareModal = false;
      },
      "saved": _vm.resetAndEmitStepUpdated
    }
  }) : _vm._e(), _vm.showToolsModal ? _c('ModalWorkOrderStepHardwareManage', {
    attrs: {
      "is-tools-list": "",
      "doc-id": _vm.docId,
      "step-id": _vm.step.id
    },
    on: {
      "modal-close": function ($event) {
        _vm.showToolsModal = false;
      },
      "saved": _vm.resetAndEmitStepUpdated
    }
  }) : _vm._e(), _vm.showApprovalsModal ? _c('ModalWorkOrderStepApprovalManage', {
    attrs: {
      "doc-id": _vm.docId,
      "hideMip": _vm.shouldHideMip,
      "step-id": _vm.step.id
    },
    on: {
      "modal-close": function ($event) {
        _vm.showApprovalsModal = false;
      },
      "saved": _vm.resetAndEmitStepUpdated
    }
  }) : _vm._e(), _vm.showAttachmentsModal ? _c('ModalWorkOrderStepAttachmentsManage', {
    attrs: {
      "document-id": _vm.docId,
      "read-only": _vm.isReadOnly,
      "step-id": _vm.step.id,
      "work-order-step-id": _vm.step.id
    },
    on: {
      "modal-close": function ($event) {
        _vm.showAttachmentsModal = false;
      },
      "saved": _vm.resetAndEmitStepUpdated
    }
  }) : _vm._e(), _vm.showStepApprovalModal && _vm.step.changeSet ? _c('ModalWorkOrderStepComplete', {
    attrs: {
      "document-id": _vm.docId,
      "change-set-ids": [_vm.step.changeSet.id],
      "should-require-comment": false
    },
    on: {
      "modal-close": function ($event) {
        _vm.showStepApprovalModal = false;
      },
      "saved": _vm.resetAndEmitStepUpdated
    }
  }) : _vm._e(), _vm.showStepRejectionModal && _vm.step.changeSet ? _c('ModalWorkOrderStepReject', {
    attrs: {
      "change-set-id": _vm.step.changeSet.id,
      "should-require-comment": false
    },
    on: {
      "modal-close": function ($event) {
        _vm.showStepRejectionModal = false;
      },
      "step-rejected": _vm.resetAndEmitStepUpdated
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };