import { __decorate, __metadata } from "tslib";
import { differenceInDays, getDaysInMonth } from 'date-fns';
import gql from 'graphql-tag';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { CosmicUserSlimFragment } from '~/nasa_ui/DAL/user/slim';
import { HeaderSortOptions } from '~/nasa_ui/types';
import { addDateDisplays, addUserDisplays } from '~/nasa_ui/utils/helpers/displayMappers';
Vue.filter('formatNumber', (value) => {
    return value.toLocaleString('en-US');
});
// this component does not have an initial fetch
// must $message `fetch_error_log`
let AppErrorLog = class AppErrorLog extends Vue {
    allErrorLogs = null;
    isLoading = false;
    selectedErrorLog = null;
    showUser;
    userId;
    overrideRowsPerPageItems;
    get computedErrorLogHeaders() {
        if (this.showUser) {
            return this.errorLogHeaders;
        }
        else {
            return this.errorLogHeaders.filter((header) => {
                return header.value !== '_createdByUser';
            });
        }
    }
    get errorLogs() {
        return this.allErrorLogs && this.allErrorLogs.nodes ? this.allErrorLogs.nodes : [];
    }
    get errorLogCounts() {
        return this.errorLogs
            ? this.errorLogs.reduce((counts, log) => {
                if (!log) {
                    return counts;
                }
                const today = new Date();
                const daysInMonth = getDaysInMonth(today);
                const daysSince = differenceInDays(today, new Date(log.createdDateTime));
                if (daysSince < 2) {
                    counts.day++;
                }
                if (daysSince < 8) {
                    counts.week++;
                }
                if (daysSince < daysInMonth) {
                    counts.month++;
                }
                return counts;
            }, {
                day: 0,
                week: 0,
                month: 0
            })
            : null;
    }
    get errorLogHeaders() {
        return [
            {
                text: 'Date',
                value: '_createdDateTime',
                noEscape: true,
                headerSortType: HeaderSortOptions.DATETIME
            },
            {
                text: 'User',
                value: '_createdByUser',
                sortable: false
            },
            {
                text: 'Browser',
                value: 'browser',
                sortable: false,
                noEscape: true
            },
            {
                text: 'Message',
                noWrap: true,
                value: 'messageTrimmed',
                sortable: false
            },
            {
                text: 'URL',
                value: 'url',
                sortable: false,
                noEscape: true
            }
        ];
    }
    get filter() {
        if (this.userId) {
            return { createdByUserId: { equalTo: this.userId }, subType: { equalTo: 'ERROR' } };
        }
        return { subType: { equalTo: 'ERROR' } };
    }
    get mappedErrorLogs() {
        return this.errorLogs
            ? this.errorLogs
                .map(addDateDisplays)
                .map(addUserDisplays)
                .map((log) => {
                const fromUrl = log && log.attributes && log.attributes.fromUrl ? `${log.attributes.fromUrl}` : DEFAULT_DASH;
                return {
                    ...log,
                    browser: 'in template',
                    fromUrl,
                    message: log && log.attributes && log.attributes.err ? log.attributes.err.message : DEFAULT_DASH,
                    nodeId: log?.nodeId,
                    url: 'in template',
                    messageTrimmed: log?.attributes?.err?.message
                        ? `${log.attributes.err.message.substring(0, 100)} ...`
                        : DEFAULT_DASH
                };
            })
            : [];
    }
    get paginationSettings() {
        return {
            descending: true,
            sortBy: '_createdDateTime'
        };
    }
    get rowsPerPageItems() {
        return this.overrideRowsPerPageItems
            ? this.overrideRowsPerPageItems
            : [
                100,
                {
                    text: '$vuetify.dataIterator.rowsPerPageAll',
                    value: -1
                }
            ];
    }
    browserToIcon(browser) {
        let icon = '';
        switch (browser) {
            case 'Chrome':
                icon = 'fab fa-chrome fa-2x';
                break;
            case 'Firefox':
                icon = 'fab fa-firefox fa-2x';
                break;
            case 'Safari':
                icon = 'fab fa-safari fa-2x';
                break;
            case 'Internet Explorer':
                icon = 'fab fa-internet-explorer fa-2x';
                break;
            case 'Edge':
                icon = 'fab fa-edge fa-2x';
                break;
            default:
                icon = 'fad fa-browser';
                break;
        }
        return icon;
    }
    created() {
        this.$listen('fetch_error_log', this.fetchErrorLogs);
    }
    async fetchErrorLogs() {
        try {
            this.isLoading = true;
            const resp = await this.$apollo.query({
                query: gql `
          ${CosmicUserSlimFragment}

          query SelectErrorLogsForAdminsHomeView($filter: ErrorLogFilter) {
            errorLogs(filter: $filter, orderBy: CREATED_DATE_TIME_DESC) {
              nodes {
                attributes
                createdByUserId
                createdDateTime
                nodeId
                organizationCode
                subType

                createdByUser {
                  ...CosmicUserSlim
                }
              }
            }
          }
        `,
                fetchPolicy: 'cache-first',
                variables: {
                    filter: this.filter
                }
            });
            this.isLoading = false;
            if (resp && resp.data && resp.data.errorLogs) {
                this.allErrorLogs = resp.data.errorLogs;
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    onChildRowClick(val) {
        this.selectedErrorLog = val ? this.errorLogs.find((log) => log?.nodeId === val.nodeId) : null;
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: true
    }),
    __metadata("design:type", Boolean)
], AppErrorLog.prototype, "showUser", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], AppErrorLog.prototype, "userId", void 0);
__decorate([
    Prop({
        type: Array
    }),
    __metadata("design:type", Object)
], AppErrorLog.prototype, "overrideRowsPerPageItems", void 0);
AppErrorLog = __decorate([
    Component({
        components: {
            VueJsonPretty
        }
    })
], AppErrorLog);
export default AppErrorLog;
