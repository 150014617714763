var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_vm.isDisplayTypeFormWrapper ? _c('AppFormWrapper', {
    attrs: {
      "size": _vm.computedFormWrapperSize,
      "color": _vm.EntityType.USER
    }
  }, [_vm._t("messages"), _vm.hasAppHeader__Slot ? _c('AppHeader', [_vm._t("AppHeader")], 2) : _vm._e(), !_vm.readOnly ? _c('div', {
    staticClass: "cosmic_user_access_action_container my-3"
  }, [_c('div', [_c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on
      }) {
        return [_c('ButtonMiniAction', _vm._g({
          on: {
            "click": _vm.onClickOfAddUser
          }
        }, on), [[_c('i', {
          staticClass: "users--text",
          class: _vm.$icons[_vm.EntityType.USER]
        }), _c('i', {
          staticClass: "search_users_icon",
          class: _vm.$icons['search']
        })]], 2)];
      }
    }], null, false, 223699612)
  }, [_c('span', [_vm._v("Add 1 User at a time")])])], 1), _c('div', [_c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on
      }) {
        return [_c('ButtonMiniAction', _vm._g({
          on: {
            "click": _vm.onClickOfAddUserGroup
          }
        }, on), [[_c('i', {
          staticClass: "users--text",
          class: _vm.$icons[_vm.EntityType.USER_GROUP]
        }), _c('i', {
          staticClass: "search_user_group_icon",
          class: _vm.$icons['search']
        })]], 2)];
      }
    }], null, false, 2042670704)
  }, [_c('span', [_vm._v("Add an entire User Group at once")])])], 1), _c('div', [_c('v-tooltip', {
    attrs: {
      "bottom": "",
      "disabled": _vm.listContainsYourself
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on
      }) {
        return [_c('ButtonMiniAction', _vm._g({
          attrs: {
            "disabled": _vm.listContainsYourself
          },
          on: {
            "click": _vm.onClickOfAddYourself
          }
        }, on), [[_c('i', {
          staticClass: "fa fa-user users--text"
        }), _c('i', {
          staticClass: "search_user_group_icon",
          class: _vm.$icons['plus']
        })]], 2)];
      }
    }], null, false, 2886410579)
  }, [_c('span', [_vm._v("Add yourself")])])], 1), _c('div', [_c('v-tooltip', {
    attrs: {
      "bottom": "",
      "disabled": !_vm.showUserGroupSearch && !_vm.showUserSearch
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on
      }) {
        return [_c('ButtonMiniAction', _vm._g({
          attrs: {
            "disabled": !_vm.showUserGroupSearch && !_vm.showUserSearch,
            "icon-color": "black--text"
          },
          on: {
            "click": _vm.onClickOfCancelAdd
          }
        }, on), [[_c('c-icon-fa', {
          attrs: {
            "icon": _vm.$icons.search
          }
        }), _c('i', {
          staticClass: "search_close_icon",
          class: _vm.$icons.close
        })]], 2)];
      }
    }], null, false, 3603549607)
  }, [_c('span', [_vm._v("Hide all active searches")])])], 1), _c('div', [_c('ButtonMiniActionRemoveFromList', {
    attrs: {
      "disabled": !_vm.value || _vm.value.length === 0,
      "tooltip": "Remove all Users"
    },
    on: {
      "click": _vm.onClickOfClearAll
    }
  })], 1)]) : _vm._e(), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.computedTableItems.length === 0 ? _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_vm._t("no_items", function () {
    return [_c('Alert', {
      attrs: {
        "type": "info"
      }
    }, [_c('p', [_vm._v("No Users selected.")])])];
  })], 2) : _vm._e()], 1), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.computedTableItems.length && _vm.hasAppPageDescription_above_results__Slot ? _c('v-flex', {
    attrs: {
      "xs12": "",
      "pb-0": ""
    }
  }, [_c('AppPageDescription', {
    staticClass: "mt-3",
    attrs: {
      "color": _vm.EntityType.USER,
      "remove-margin-left": ""
    }
  }, [_vm._t("AppPageDescription_above_results")], 2)], 1) : _vm._e()], 1), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.computedTableItems.length ? _c('div', {
    staticClass: "mb-2"
  }, [_vm._t("results", function () {
    return [_c('AppTableActions', {
      staticClass: "selected_users_table",
      attrs: {
        "color": _vm.EntityType.USER,
        "items": _vm.computedTableItems,
        "headers": _vm.tableHeaders,
        "selectable-rows": !_vm.isReadOnly,
        "show-header": false
      },
      scopedSlots: _vm._u([{
        key: "actions",
        fn: function ({
          item
        }) {
          return [_c('ButtonMiniActionRemoveFromList', {
            on: {
              "click": function ($event) {
                return _vm.onClickOfCurrentUsersTableRow(item);
              }
            }
          })];
        }
      }], null, false, 1018108554)
    })];
  }, {
    "items": _vm.computedTableItems
  })], 2) : _vm._e()])], 1), _c('div', [_vm.hasAppPageDescription_above_SearchUser__Slot && (_vm.showUserGroupSearch || _vm.showUserSearch) ? _c('AppPageDescription', {
    staticClass: "mt-3",
    attrs: {
      "color": "info"
    }
  }, [_vm._t("AppPageDescription_above_SearchUser")], 2) : _vm._e(), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.showUserSearch ? _c('SearchUser', {
    attrs: {
      "existing-values": _vm.existingUsers,
      "keep-search-results-open": "",
      "output-mode": "object",
      "selectable-rows": ""
    },
    on: {
      "input": _vm.updateWithUser
    }
  }) : _vm._e()], 1), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.showUserGroupSearch ? _c('div', [_c('AppPageDescription', {
    attrs: {
      "color": "warning"
    }
  }, [_vm._v(" Every member of the selected User Group will be added. ")]), _c('SearchUserGroup', {
    attrs: {
      "keep-search-results-open": "",
      "output-mode": "object",
      "selectable-rows": ""
    },
    on: {
      "input": _vm.updateWithUserGroup
    }
  })], 1) : _vm._e()])], 1)], 2) : _vm.isDisplayTypeExpansionPanel ? _c('AppExpansionPanel', {
    attrs: {
      "color": _vm.EntityType.USER,
      "is-open": ""
    }
  }, [_c('div', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm._t("AppExpansionPanel__title")], 2), !_vm.readOnly ? _c('div', {
    staticClass: "cosmic_user_access_action_container mt-3"
  }, [_c('div', [_c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on
      }) {
        return [_c('ButtonMiniAction', _vm._g({
          on: {
            "click": _vm.onClickOfAddUser
          }
        }, on), [[_c('i', {
          staticClass: "users--text",
          class: _vm.$icons[_vm.EntityType.USER]
        }), _c('i', {
          staticClass: "search_users_icon",
          class: _vm.$icons['search']
        })]], 2)];
      }
    }], null, false, 223699612)
  }, [_c('span', [_vm._v("Add 1 User at a time")])])], 1), _c('div', [_c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on
      }) {
        return [_c('ButtonMiniAction', _vm._g({
          on: {
            "click": _vm.onClickOfAddUserGroup
          }
        }, on), [[_c('i', {
          staticClass: "users--text",
          class: _vm.$icons[_vm.EntityType.USER_GROUP]
        }), _c('i', {
          staticClass: "search_user_group_icon",
          class: _vm.$icons['search']
        })]], 2)];
      }
    }], null, false, 2042670704)
  }, [_c('span', [_vm._v("Add an entire User Group at once")])])], 1), _c('div', [_c('v-tooltip', {
    attrs: {
      "bottom": "",
      "disabled": !_vm.showUserGroupSearch && !_vm.showUserSearch
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on
      }) {
        return [_c('ButtonMiniAction', _vm._g({
          attrs: {
            "disabled": !_vm.showUserGroupSearch && !_vm.showUserSearch,
            "icon-color": "black--text"
          },
          on: {
            "click": _vm.onClickOfCancelAdd
          }
        }, on), [[_c('c-icon-fa', {
          attrs: {
            "icon": _vm.$icons.search
          }
        }), _c('i', {
          staticClass: "search_close_icon",
          class: _vm.$icons.close
        })]], 2)];
      }
    }], null, false, 3603549607)
  }, [_c('span', [_vm._v("Hide all active searches")])])], 1), _c('div', [_c('ButtonMiniActionRemoveFromList', {
    attrs: {
      "disabled": !_vm.value || _vm.value.length === 0,
      "tooltip": "Remove all Users"
    },
    on: {
      "click": _vm.onClickOfClearAll
    }
  })], 1)]) : _vm._e(), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.computedTableItems.length === 0 ? _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_vm._t("no_items", function () {
    return [_c('Alert', {
      attrs: {
        "type": "info"
      }
    }, [_c('p', [_vm._v("No Users selected.")])])];
  })], 2) : _vm._e()], 1), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.computedTableItems.length && _vm.hasAppPageDescription_above_results__Slot ? _c('v-flex', {
    attrs: {
      "xs12": "",
      "pb-0": ""
    }
  }, [_c('AppPageDescription', {
    staticClass: "mt-3",
    attrs: {
      "color": _vm.EntityType.USER,
      "remove-margin-left": ""
    }
  }, [_vm._t("AppPageDescription_above_results")], 2)], 1) : _vm._e()], 1), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.computedTableItems.length ? _c('div', [_vm._t("results", function () {
    return [_c('v-flex', {
      attrs: {
        "xs8": "",
        "pt-0": ""
      }
    }, [_c('AppTable', {
      attrs: {
        "items": _vm.computedTableItems,
        "headers": _vm.tableHeaders,
        "color": _vm.EntityType.USER,
        "selectable-rows": !_vm.isReadOnly,
        "show-search-input": ""
      },
      on: {
        "rowClicked": _vm.onClickOfCurrentUsersTableRow
      }
    }, [_c('template', {
      slot: "title"
    }, [_vm._v(" Selected Users ("), _c('small', {
      staticClass: "monospace_font"
    }, [_vm._v(_vm._s(_vm.computedTableItems.length))]), _vm._v(") ")])], 2)], 1)];
  }, {
    "items": _vm.computedTableItems
  })], 2) : _vm._e()])], 1), _c('div', [_vm.hasAppPageDescription_above_SearchUser__Slot && (_vm.showUserGroupSearch || _vm.showUserSearch) ? _c('AppPageDescription', {
    staticClass: "mt-3",
    attrs: {
      "color": "info"
    }
  }, [_vm._t("AppPageDescription_above_SearchUser")], 2) : _vm._e(), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.showUserSearch ? _c('SearchUser', {
    attrs: {
      "existing-values": _vm.existingUsers,
      "keep-search-results-open": "",
      "output-mode": "object",
      "selectable-rows": ""
    },
    on: {
      "input": _vm.updateWithUser
    }
  }) : _vm._e()], 1), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.showUserGroupSearch ? _c('div', [_c('AppPageDescription', {
    attrs: {
      "color": "warning"
    }
  }, [_vm._v(" Every member of the selected User Group will be added. ")]), _c('SearchUserGroup', {
    attrs: {
      "keep-search-results-open": "",
      "output-mode": "object",
      "selectable-rows": ""
    },
    on: {
      "input": _vm.updateWithUserGroup
    }
  })], 1) : _vm._e()])], 1)], 1) : _vm.isDisplayTypeNecked ? _c('div', [_c('AppHeader', [_vm._t("AppHeader")], 2), !_vm.readOnly ? _c('div', {
    staticClass: "cosmic_user_access_action_container mt-2"
  }, [_c('div', [_c('ButtonMiniAction', {
    attrs: {
      "tooltip": "Add 1 User at a time",
      "tooltip-position": "right"
    },
    on: {
      "click": _vm.onClickOfAddUser
    }
  }, [[_c('i', {
    staticClass: "users--text",
    class: _vm.$icons[_vm.EntityType.USER]
  }), _c('i', {
    staticClass: "search_users_icon",
    class: _vm.$icons['search']
  })]], 2)], 1), _c('div', [_c('ButtonMiniAction', {
    attrs: {
      "tooltip": "Add an entire User Group at once",
      "tooltip-position": "right"
    },
    on: {
      "click": _vm.onClickOfAddUserGroup
    }
  }, [[_c('i', {
    staticClass: "users--text",
    class: _vm.$icons[_vm.EntityType.USER_GROUP]
  }), _c('i', {
    staticClass: "search_user_group_icon",
    class: _vm.$icons['search']
  })]], 2)], 1), _c('div', [_c('ButtonMiniAction', {
    attrs: {
      "disabled": !_vm.showUserGroupSearch && !_vm.showUserSearch,
      "icon-color": "black--text",
      "tooltip": "Hide all active searches",
      "tooltip-position": "right"
    },
    on: {
      "click": _vm.onClickOfCancelAdd
    }
  }, [[_c('c-icon-fa', {
    attrs: {
      "icon": _vm.$icons.search
    }
  }), _c('i', {
    staticClass: "search_close_icon",
    class: _vm.$icons.close
  })]], 2)], 1), _c('div', [_c('ButtonMiniActionRemoveFromList', {
    attrs: {
      "disabled": !_vm.value || _vm.value.length === 0,
      "tooltip": "Remove all Users",
      "tooltip-position": "right"
    },
    on: {
      "click": _vm.onClickOfClearAll
    }
  })], 1)]) : _vm._e(), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.computedTableItems.length === 0 ? _c('v-flex', {
    staticClass: "mt-2",
    attrs: {
      "xs12": ""
    }
  }, [_vm._t("no_items", function () {
    return [_c('Alert', {
      attrs: {
        "type": "info"
      }
    }, [_c('p', [_vm._v("No Users selected.")])])];
  })], 2) : _vm._e()], 1), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.computedTableItems.length && _vm.hasAppPageDescription_above_results__Slot ? _c('v-flex', {
    attrs: {
      "xs12": "",
      "pb-0": ""
    }
  }, [_c('AppPageDescription', {
    staticClass: "mt-2",
    attrs: {
      "color": _vm.EntityType.USER,
      "remove-margin-left": ""
    }
  }, [_vm._t("AppPageDescription_above_results")], 2)], 1) : _vm._e()], 1), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.computedTableItems.length ? _c('div', [_vm._t("results", function () {
    return [_c('v-flex', {
      attrs: {
        "xs4": "",
        "mt-2": ""
      }
    }, [_c('AppTableActions', {
      attrs: {
        "items": _vm.computedTableItems,
        "headers": _vm.tableHeaders,
        "color": _vm.EntityType.USER,
        "show-header": false,
        "dense-rows": "",
        "hide-pagination": ""
      },
      scopedSlots: _vm._u([{
        key: "actions",
        fn: function ({
          item
        }) {
          return [_c('ButtonMiniActionRemoveFromList', {
            attrs: {
              "disabled": _vm.isReadOnly
            },
            on: {
              "click": function ($event) {
                return _vm.onClickOfCurrentUsersTableRow(item);
              }
            }
          })];
        }
      }], null, false, 265538476)
    })], 1)];
  }, {
    "items": _vm.computedTableItems
  })], 2) : _vm._e()])], 1), _c('div', {
    staticClass: "mt-2"
  }, [_vm.hasAppPageDescription_above_SearchUser__Slot && (_vm.showUserGroupSearch || _vm.showUserSearch) ? _c('AppPageDescription', {
    attrs: {
      "color": "info"
    }
  }, [_vm._t("AppPageDescription_above_SearchUser")], 2) : _vm._e(), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.showUserSearch ? _c('SearchUser', {
    attrs: {
      "existing-values": _vm.existingUsers,
      "keep-search-results-open": "",
      "output-mode": "object",
      "selectable-rows": ""
    },
    on: {
      "input": _vm.updateWithUser
    }
  }) : _vm._e()], 1), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.showUserGroupSearch ? _c('div', [_c('AppPageDescription', {
    attrs: {
      "color": "warning"
    }
  }, [_vm._v(" Every member of the selected User Group will be added. ")]), _c('SearchUserGroup', {
    attrs: {
      "keep-search-results-open": "",
      "output-mode": "object"
    },
    on: {
      "input": _vm.updateWithUserGroup
    }
  })], 1) : _vm._e()])], 1)], 1) : _vm._e(), _c('v-snackbar', {
    staticClass: "user_added_snackbar",
    attrs: {
      "timeout": 2000,
      "bottom": "",
      "color": "info",
      "right": ""
    },
    model: {
      value: _vm.snackbar,
      callback: function ($$v) {
        _vm.snackbar = $$v;
      },
      expression: "snackbar"
    }
  }, [_c('div', {
    staticClass: "text-xs-center"
  }, [_c('c-icon-fa', {
    staticClass: "pr-2",
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.USER],
      "icon-color": "white"
    }
  }), _c('span', [_vm._v(_vm._s(_vm.snackbarMessage))])], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };