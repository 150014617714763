var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppFormWrapper', {
    attrs: {
      "no-border": _vm.hideBorder,
      "color": _vm.EntityType.ORGANIZATION,
      "top": _vm.top,
      "bottom": _vm.bottom,
      "right": _vm.right,
      "left": _vm.left
    }
  }, [!_vm.selectedOrganizationCode ? _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_vm.showRefreshAlert ? _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('Alert', {
    attrs: {
      "type": _vm.AlertType.WARNING
    }
  }, [_c('p', [_vm._v("Please refresh the search query to see any Organizations that were just created.")]), _c('p', [_c('ButtonGeneric', {
    attrs: {
      "color": _vm.EntityType.ORGANIZATION,
      "dark": "",
      "icon": _vm.$icons.reload
    },
    on: {
      "click": _vm.refreshSearchQuery
    }
  }, [_vm._v(" Refresh search query ")])], 1)])], 1) : _vm._e(), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs9": ""
    }
  }, [_c('SearchOrganization', _vm._b({
    ref: "SearchOrganization",
    on: {
      "input": _vm.emitInput,
      "searchButtonClicked": _vm.emitSearchButtonClicked,
      "results": _vm.emitResults
    }
  }, 'SearchOrganization', _vm.$props, false))], 1), _c('v-flex', {
    attrs: {
      "xs3": "",
      "mt-5": "",
      "text-xs-center": ""
    }
  }, [_c('ButtonCreate', {
    attrs: {
      "href": _vm.createOrganizationUrl,
      "target": "_blank",
      "rel": "noopener"
    },
    on: {
      "click": function ($event) {
        _vm.showRefreshAlert = true;
      }
    }
  }, [_vm._v(" Create Organization ")])], 1)], 1)], 1) : _vm._e(), _vm.selectedOrganizationCode ? _c('v-layout', [_c('v-flex', [_c('MiniOrganization', {
    attrs: {
      "code": _vm.selectedOrganizationCode || '',
      "inline-clear": _vm.shouldShowClearSelectionButton,
      "read-only": !_vm.shouldShowClearSelectionButton,
      "title": "Selected Organization"
    },
    on: {
      "inlineClear": function ($event) {
        _vm.selectedOrganizationCode = null;
      }
    }
  })], 1)], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };