var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('CalibrationRequirementModalTemplate', {
    attrs: {
      "existing-calibration-types": _vm.existingCalibrationTypes,
      "hide-title-section": ""
    },
    on: {
      "error": _vm.onError,
      "modal-close": _vm.emitModalClose
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('ButtonCreate', {
          staticClass: "mx-2",
          attrs: {
            "disabled": _vm.shouldDisableSaveButton
          },
          on: {
            "click": _vm.onClickOfCreate
          }
        }, [_vm._v("Create Calibration requirement")]), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.formData,
      callback: function ($$v) {
        _vm.formData = $$v;
      },
      expression: "formData"
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };