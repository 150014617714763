var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.id ? _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.ASSEMBLY_TEMPLATE,
      "hide-title-section": "",
      "modal-width": "95vw"
    },
    on: {
      "modal-close": _vm.emitModalClose
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('MiniAssemblyTemplate', {
          staticClass: "mb-3",
          attrs: {
            "id": _vm.id,
            "show-children": ""
          }
        })];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        }, [_vm._v("Close")])];
      },
      proxy: true
    }], null, false, 1496114905)
  }) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };