var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.HARDWARE_LIST,
      "modal-width": _vm.computedModalWidth,
      "hide-title-section": ""
    },
    on: {
      "modal-close": _vm.emitModalClose
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [!_vm.isExternal ? _c('AppHardwareDisplay', {
          attrs: {
            "node-id": _vm.inventoryNodeId,
            "hide-border-bottom": ""
          }
        }) : _c('div', {
          staticClass: "display_wrapper"
        }, [_c('v-layout', {
          attrs: {
            "row": "",
            "wrap": "",
            "mb-2": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('AppPageDescription', {
          attrs: {
            "color": "info"
          }
        }, [_vm._v(" This Hardware is listed as external to " + _vm._s(_vm.currentSystemName) + " ")])], 1)], 1), _c('v-layout', {
          attrs: {
            "row": "",
            "wrap": "",
            "mb-2": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs3": ""
          }
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Drawing number"
          }
        }, [_c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(" " + _vm._s(_vm.transformedHardwareListAssembly._computedDrawingNumber) + " ")])])], 1), _c('v-flex', {
          attrs: {
            "xs2": ""
          }
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "AsBuilt"
          }
        }, [_c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(" " + _vm._s(_vm.transformedHardwareListAssembly._computedAsBuiltNumber) + " ")])])], 1), _c('v-flex', {
          attrs: {
            "xs6": ""
          }
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Description"
          }
        }, [_vm._v(_vm._s(_vm.transformedHardwareListAssembly._computedDescription))])], 1), _c('v-flex', {
          attrs: {
            "xs2": ""
          }
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Serial"
          }
        }, [_c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(" " + _vm._s(_vm.transformedHardwareListAssembly._computedSerialNumber) + " ")])])], 1), _c('v-flex', {
          attrs: {
            "xs2": ""
          }
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Lot"
          }
        }, [_c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(" " + _vm._s(_vm.transformedHardwareListAssembly._computedLotNumber) + " ")])])], 1), _c('v-flex', {
          attrs: {
            "xs1": ""
          }
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Class"
          }
        }, [_c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(" " + _vm._s(_vm.transformedHardwareListAssembly._computedSubType) + " ")])])], 1), _c('v-flex', {
          attrs: {
            "xs2": ""
          }
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "CEI number"
          }
        }, [_vm._v(_vm._s(_vm.transformedHardwareListAssembly._computedContractEndingItemNumber))])], 1)], 1), _c('v-layout', {
          attrs: {
            "row": "",
            "wrap": "",
            "mb-2": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs3": ""
          }
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Birth"
          }
        }, [_c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(" " + _vm._s(_vm.transformedHardwareListAssembly._computedBirthDate) + " ")])])], 1), _c('v-flex', {
          attrs: {
            "xs3": ""
          }
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Calibration"
          }
        }, [_c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(" " + _vm._s(_vm.transformedHardwareListAssembly._computedCalibrationDate) + " ")])])], 1), _c('v-flex', {
          attrs: {
            "xs3": ""
          }
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Usage life expiry"
          }
        }, [_c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(" " + _vm._s(_vm.transformedHardwareListAssembly._computedUsageExpiryDate) + " ")])])], 1), _c('v-flex', {
          attrs: {
            "xs3": ""
          }
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Shelf life expiry"
          }
        }, [_c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(" " + _vm._s(_vm.transformedHardwareListAssembly._computedShelfLifeExpiryDate) + " ")])])], 1)], 1), _c('v-layout', {
          attrs: {
            "row": "",
            "wrap": "",
            "mb-2": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs3": ""
          }
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Location"
          }
        }, [_vm._v(_vm._s(_vm.transformedHardwareListAssembly._computedLocation))])], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        }, [_vm._v("Close")])];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };