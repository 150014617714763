var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('ButtonRemove', {
    staticClass: "mx-0",
    attrs: {
      "disabled": _vm.shouldDisableDeleteButton,
      "is-removing": _vm.isDeleting
    },
    on: {
      "click": _vm.onClickOfDeleteButton
    }
  }, [_vm._v(" Delete Activity "), _c('template', {
    slot: "removing"
  }, [_vm._v("Deleting Activity...")])], 2), _vm.showDialog ? _c('ModalConfirmationDelete', {
    on: {
      "affirmativeClicked": _vm.onAffirmativeClicked,
      "negativeClicked": _vm.onNegativeClicked
    },
    model: {
      value: _vm.showDialog,
      callback: function ($$v) {
        _vm.showDialog = $$v;
      },
      expression: "showDialog"
    }
  }, [_c('template', {
    slot: "html"
  }, [_vm.id ? _c('MiniActivity', {
    attrs: {
      "id": _vm.id
    }
  }) : _vm._e()], 1)], 2) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };