var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppFormWrapper', {
    attrs: {
      "size": "0",
      "validate-on-load": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [!_vm.hideUpload ? _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppFileUpload', {
    ref: "file_upload",
    staticClass: "mb-4",
    attrs: {
      "disabled": _vm.isReadOnly,
      "mb-max": 500
    },
    on: {
      "file-too-large": _vm.onFileToLarge
    },
    model: {
      value: _vm.localValue.file,
      callback: function ($$v) {
        _vm.$set(_vm.localValue, "file", $$v);
      },
      expression: "localValue.file"
    }
  })], 1) : _vm._e(), _c('v-flex', {
    attrs: {
      "xs4": "",
      "pr-2": ""
    }
  }, [_c('AppSelectEnum', {
    attrs: {
      "chips": false,
      "disabled": _vm.isReadOnly,
      "display": _vm.AttachmentTypeDisplay,
      "enum": _vm.AttachmentType,
      "label": "Type",
      "sort": ""
    },
    model: {
      value: _vm.localValue.type,
      callback: function ($$v) {
        _vm.$set(_vm.localValue, "type", $$v);
      },
      expression: "localValue.type"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs8": ""
    }
  }, [_c('c-text', {
    attrs: {
      "disabled": _vm.isReadOnly,
      "label": "Description",
      "c-value": _vm.localValue.description,
      "required": ""
    },
    on: {
      "c-input": function ($event) {
        _vm.localValue.description = $event.detail;
      }
    }
  })], 1), _c('v-flex', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.shouldShowDropzone,
      expression: "shouldShowDropzone"
    }],
    attrs: {
      "xs12": ""
    }
  }, [_c('FileAttachmentDropzone', {
    on: {
      "drop": _vm.onFileDropped,
      "dropzone-clicked": _vm.onClickOfDropzone
    }
  })], 1)], 1), _vm.localValue.file ? _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_vm._t("extra_form_content", null, {
    "file": _vm.localValue
  })], 2) : _vm._e(), _vm.isFetching ? _c('AppLoader', {
    attrs: {
      "type": "linear"
    }
  }) : _vm._e(), _vm.localValue.file ? _c('AppFilePreview', {
    staticClass: "mt-3",
    attrs: {
      "allow-clear": !_vm.attachment,
      "file": _vm.localValue.file,
      "height": "600px",
      "pdf-width": "100%",
      "video-width": "800px",
      "width": "75%"
    },
    on: {
      "clear": _vm.clearSelectedFile,
      "log": _vm.logFileView
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };