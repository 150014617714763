var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "container fluid grid-list-lg px-2"
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('v-radio-group', {
    staticClass: "mt-0",
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_c('AppPageDescription', {
          attrs: {
            "color": _vm.EntityType.USER_GROUP,
            "remove-margin-left": ""
          }
        }, [_vm._v("What kind of MIP is this?")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.formData.authorityType,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "authorityType", $$v);
      },
      expression: "formData.authorityType"
    }
  }, [_c('v-radio', {
    attrs: {
      "color": _vm.EntityType.USER_GROUP,
      "value": _vm.ChangeSetAuthorityType.MANUAL_INSPECTION_POINT,
      "label": "Manual Insection Point (MIP)"
    }
  }), _c('v-radio', {
    attrs: {
      "color": _vm.EntityType.USER_GROUP,
      "value": _vm.ChangeSetAuthorityType.GOVERNMENT_MANUAL_INSPECTION_POINT,
      "label": "Government Manual Insection Point (GMIP)"
    }
  })], 1)], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('AppPageDescription', {
    attrs: {
      "color": _vm.EntityType.USER_GROUP,
      "remove-margin-left": ""
    }
  }, [_vm._v("To which Approval are you adding this MIP?")]), _c('AppSelect', {
    attrs: {
      "chips": false,
      "select-options": _vm.groupSelectOptions,
      "label": "Select Approval"
    },
    model: {
      value: _vm.formData.groupCode,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "groupCode", $$v);
      },
      expression: "formData.groupCode"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('c-text', {
    attrs: {
      "color": _vm.EntityType.USER_GROUP,
      "label": "MIP Type",
      "c-value": _vm.formData.mipType
    },
    on: {
      "c-input": _vm.formData.mipType
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('c-text', {
    attrs: {
      "color": _vm.EntityType.USER_GROUP,
      "label": "MIP Explanation",
      "c-value": _vm.formData.mipExplanation
    },
    on: {
      "c-input": _vm.formData.mipExplanation
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('ButtonCreate', {
    attrs: {
      "disabled": !_vm.isFormValid
    },
    on: {
      "click": _vm.onClickOfAddMip
    }
  }, [_vm._v(_vm._s(_vm.addMipButtonLabel))]), _c('ButtonCancel', {
    on: {
      "click": _vm.onClickOfCancel
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };