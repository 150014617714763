var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-container', {
    attrs: {
      "fluid": "",
      "grid-list-lg": "",
      "pa-0": "",
      "mt-1": ""
    }
  }, [_vm.isLoading ? _c('AppLoader', {
    attrs: {
      "color": _vm.EntityType.CHANGE_SET,
      "type": "linear"
    }
  }) : _vm._e(), _vm.document && _vm.entity ? _c('InitializationStartStop', {
    attrs: {
      "current-user-has-access": _vm.currentUserHasAccess,
      "doc-id": _vm.entity.id,
      "hide-start-stop-button": !_vm.hasTasks,
      "is-closed": _vm.isDocumentClosed,
      "is-initialized": _vm.isInitialized,
      "is-open-for-edits": _vm.isOpenForEdits,
      "is-rejected": _vm.isRejected,
      "type": _vm.DocumentType.C_WORKFLOW
    },
    on: {
      "saved": _vm.emitRefetchEntity
    },
    scopedSlots: _vm._u([{
      key: "message-append",
      fn: function () {
        return [_c('ul', {
          staticClass: "startStopRequirements"
        }, [!_vm.hasTasks ? _c('li', [_vm._v("At least "), _c('a', {
          attrs: {
            "href": "#tasks_overview"
          }
        }, [_vm._v("1 Task")]), _vm._v(" is required to start.")]) : _vm._e()])];
      },
      proxy: true
    }], null, false, 4099998019)
  }) : _vm._e(), _c('c-card', {
    staticClass: "mb-2",
    attrs: {
      "border-color": "var(--v-documents-base)"
    }
  }, [_c('div', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('c-icon-fa', {
    staticClass: "pr-2",
    attrs: {
      "icon": "fad fa-info-circle",
      "icon-color": "var(--cosmic-text)"
    }
  }), _c('span', [_vm._v("General information")])], 1), _vm.transformedWorkflow ? _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md3": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "value": _vm.transformedWorkflow._createdByUser,
      "title": "Creator"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md3": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "value": _vm.transformedWorkflow._computedStatus,
      "title": "Computed status"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md3": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Computed revision"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedWorkflow.computedRevisionNumber) + " ")])])], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md3": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Created"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedWorkflow._createdDateTime) + " ")])])], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md3": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "value": _vm.transformedWorkflow._initiatorContact,
      "title": "Initiator"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md3": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Editable"
    }
  }, [_c('AppBooleanDisplay', {
    attrs: {
      "value": _vm.transformedWorkflow.isEditable
    }
  })], 1)], 1)], 1) : _vm._e()], 1), _c('AppTable', {
    staticClass: "mb-2",
    attrs: {
      "color": _vm.EntityType.CHANGE_SET,
      "headers": _vm.taskApprovalTableHeaders,
      "items": _vm.taskApprovalTableItems,
      "rows-per-page-items": [-1],
      "show-count": false,
      "dense-rows": "",
      "hide-pagination": "",
      "icon": "fa-regular fa-balance-scale",
      "sticky": "",
      "title": "Task level approvals"
    },
    scopedSlots: _vm._u([{
      key: "__findGroupName",
      fn: function ({
        item
      }) {
        return [_vm._v(" " + _vm._s(_vm.findDisplayNameFromDelegateGroup(item)) + " ")];
      }
    }])
  }), _c('AppTable', {
    staticClass: "mb-2",
    attrs: {
      "color": _vm.EntityType.CHANGE_SET,
      "headers": _vm.approvalsTableHeaders,
      "icon": _vm.$icons[_vm.DocumentType.C_WORKFLOW],
      "items": _vm.approvalHistoryTableItems,
      "rows-per-page-items": [-1],
      "show-count": false,
      "disable-initial-sort": "",
      "dense-rows": "",
      "hide-pagination": "",
      "sticky": "",
      "title": "Workflow level approvals"
    },
    scopedSlots: _vm._u([{
      key: "__findGroupName",
      fn: function ({
        item
      }) {
        return [_vm._v(" " + _vm._s(_vm.findDisplayNameFromDelegateGroup(item)) + " ")];
      }
    }])
  }), _c('AppTable', {
    staticClass: "mb-4",
    attrs: {
      "color": "primary",
      "headers": _vm.emailHistoryTableHeaders,
      "items": _vm.emailHistoryTableItems,
      "icon": _vm.$icons.mail,
      "rows-per-page-items": [-1],
      "show-count": false,
      "dense-rows": "",
      "hide-pagination": "",
      "sticky": "",
      "title": "Email history"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };