import { __decorate, __metadata } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import BaseButton from '~/nasa_ui/base/BaseButton';
let ButtonRunReport = class ButtonRunReport extends BaseButton {
    isSearching;
    get computedColor() {
        return this.color || 'var(--v-success-base)';
    }
    get computedIcon() {
        return this.icon || this.$icons.search;
    }
    get computedTextColor() {
        return this.textColor || '#fff';
    }
};
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], ButtonRunReport.prototype, "isSearching", void 0);
ButtonRunReport = __decorate([
    Component
], ButtonRunReport);
export default ButtonRunReport;
