import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import BaseTab from '~/nasa_ui/base/BaseTab';
let BaseCosmicDocumentTab = class BaseCosmicDocumentTab extends BaseTab {
    onDeletionComplete() {
        this.scrollToTop();
        const path = `/search`;
        setTimeout(() => this.$router.push(path), 2000);
    }
};
BaseCosmicDocumentTab = __decorate([
    Component
], BaseCosmicDocumentTab);
export default BaseCosmicDocumentTab;
