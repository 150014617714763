var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-layout', {
    staticClass: "app_override",
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    class: _vm.computedCheckboxClass
  }, [_c('AppCheckbox', {
    attrs: {
      "disabled": !_vm.shouldShowOverrideToggle,
      "label": _vm.checkboxLabel
    },
    model: {
      value: _vm.overrideToggle,
      callback: function ($$v) {
        _vm.overrideToggle = $$v;
      },
      expression: "overrideToggle"
    }
  })], 1), _vm.shouldShowOverrideField ? _c('v-flex', {
    class: _vm.overrideClass
  }, [!_vm.$slots.input ? _c('c-text', {
    attrs: {
      "disabled": _vm.disabled,
      "label": _vm.computedLabel,
      "required": _vm.required,
      "rules": _vm.rules,
      "type": _vm.type,
      "c-value": _vm.overrideValue
    },
    on: {
      "change": _vm.emitInput,
      "input": _vm.emitInput,
      "c-input": function ($event) {
        _vm.overrideValue = $event.detail;
      }
    }
  }) : _vm._t("input")], 2) : _vm._e(), !_vm.shouldShowOverrideField ? _c('v-flex', {
    class: _vm.computedSlotClass
  }, [_vm._t("default")], 2) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };