import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import BaseDelete from '~/nasa_ui/base/BaseDelete';
import { AlertType } from '~/nasa_ui/types';
import { UserPermission } from '~/nasa_ui/types/enums/user';
let DeleteEvent = class DeleteEvent extends Mixins(BaseDelete) {
    showManageLink;
    get gqlDeleteEventMutationVariables() {
        if (!this.id) {
            return null;
        }
        return {
            id: this.id
        };
    }
    get shouldDisableDeleteButton() {
        return !this.currentUserHasPermission(UserPermission.EVENT_ADMIN) || this.isDeleting;
    }
    // called from ui
    onAffirmativeClicked() {
        this.deleteEvent();
    }
    async deleteEvent() {
        if (!this.gqlDeleteEventMutationVariables) {
            return;
        }
        try {
            this.isDeleting = true;
            const resp = await this.deleteEventMutation();
            if (resp?.data) {
                this.$notification.add({ text: 'Event deleted.', type: AlertType.SUCCESS });
                this.emitDeletionComplete();
            }
        }
        catch (err) {
            this.isDeleting = false;
            this.$errorUtility({
                err,
                backupErrorText: 'Failed to delete Event.'
            });
        }
    }
    deleteEventMutation() {
        if (!this.gqlDeleteEventMutationVariables) {
            return null;
        }
        return this.$apollo.mutate({
            mutation: gql `
        mutation DeleteEventById($id: UUID!) {
          deleteEventById(input: { id: $id }) {
            clientMutationId
          }
        }
      `,
            variables: { ...this.gqlDeleteEventMutationVariables }
        });
    }
};
__decorate([
    Prop({
        default: true,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], DeleteEvent.prototype, "showManageLink", void 0);
DeleteEvent = __decorate([
    Component
], DeleteEvent);
export default DeleteEvent;
