import { __decorate, __metadata } from "tslib";
import { Component, Emit } from 'vue-property-decorator';
import BaseButton from '~/nasa_ui/base/BaseButton';
let ButtonToggle = class ButtonToggle extends BaseButton {
    shouldShow = false;
    get computedColor() {
        return this.color || 'var(--v-primary-base)';
    }
    get computedIcon() {
        return this.icon || this.shouldShow ? this.$icons.angle_left : this.$icons.angle_right;
    }
    get computedTextColor() {
        return this.textColor || '#fff';
    }
    emitClick() {
        this.shouldShow = !this.shouldShow;
    }
};
__decorate([
    Emit('click'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ButtonToggle.prototype, "emitClick", null);
ButtonToggle = __decorate([
    Component
], ButtonToggle);
export default ButtonToggle;
