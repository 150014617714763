import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
let AppStringDiffViewer = class AppStringDiffViewer extends Vue {
    DEFAULT_DASH = DEFAULT_DASH;
    fromValue;
    title;
    toValue;
};
__decorate([
    Prop({
        type: [String, Number]
    }),
    __metadata("design:type", Object)
], AppStringDiffViewer.prototype, "fromValue", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], AppStringDiffViewer.prototype, "title", void 0);
__decorate([
    Prop({
        type: [String, Number]
    }),
    __metadata("design:type", Object)
], AppStringDiffViewer.prototype, "toValue", void 0);
AppStringDiffViewer = __decorate([
    Component
], AppStringDiffViewer);
export default AppStringDiffViewer;
