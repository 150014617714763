var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('radial-progress-bar', {
    attrs: {
      "innerStrokeColor": _vm.backgroundColor,
      "diameter": 150,
      "startColor": _vm.computedStopColor,
      "stopColor": _vm.computedStartColor,
      "completed-steps": _vm.completed,
      "innerStrokeWidth": 12,
      "stroke-width": 6,
      "total-steps": _vm.total
    }
  }, [_vm.$slots.default ? _vm._t("default") : [_c('h1', {
    staticClass: "documents--text display-1 ma-0 pa-0 font-weight-bold monospace_font",
    attrs: {
      "tooltip": `Total: ${_vm.total} 
Complete: ${_vm.completed}`
    }
  }, [_vm._v(" " + _vm._s(_vm.completedPercentage) + " ")])]], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };