var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('v-layout', {
    attrs: {
      "row": "",
      "no-wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs6": ""
    }
  }, [_c('AppTableSelectable', {
    attrs: {
      "headers": _vm.supportContextTableHeaders,
      "items": _vm.availableSupportContext,
      "loading": _vm.isFetchingOrganizations,
      "rows-per-page-items": [-1],
      "color": _vm.EntityType.ORGANIZATION,
      "hide-pagination": "",
      "itemKey": "value",
      "title": "Available support contexts"
    },
    model: {
      value: _vm.assignedSupportContexts,
      callback: function ($$v) {
        _vm.assignedSupportContexts = $$v;
      },
      expression: "assignedSupportContexts"
    }
  })], 1)], 1), _c('div', {
    staticClass: "mt-2"
  }, [_c('ButtonSave', {
    staticClass: "ml-0",
    attrs: {
      "color": "success"
    },
    on: {
      "click": _vm.onClickOfSave
    }
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };