var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', _vm._g({}, _vm.$listeners), [_c('div', {
    staticClass: "app_color_picker_label mb-1"
  }, [_vm._t("default", function () {
    return [_c('label', {
      attrs: {
        "for": "app_color_picker"
      }
    }, [_vm._v(_vm._s(_vm.label))])];
  })], 2), _c('v-scale-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [!_vm.showSwatches ? _c('div', {
    staticClass: "colorActivator",
    class: {
      disabled: _vm.disabled
    },
    style: `background-color: ${_vm.selectedColor || '#000000'}`,
    attrs: {
      "disabled": _vm.disabled
    },
    on: {
      "click": _vm.onClickOfShowSwatches
    }
  }) : _vm._e()]), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.showSwatches ? _c('div', {
    staticClass: "swatches"
  }, _vm._l(_vm.allColors, function (color, index) {
    return _c('ul', {
      key: index
    }, [_c('li', {
      style: `background-color: ${color.darken4}`,
      on: {
        "click": function ($event) {
          return _vm.colorSelected(color.darken4);
        }
      }
    }), _c('li', {
      style: `background-color: ${color.darken2}`,
      on: {
        "click": function ($event) {
          return _vm.colorSelected(color.darken2);
        }
      }
    }), _c('li', {
      style: `background-color: ${color.base}`,
      on: {
        "click": function ($event) {
          return _vm.colorSelected(color.base);
        }
      }
    }), _c('li', {
      style: `background-color: ${color.lighten2}`,
      on: {
        "click": function ($event) {
          return _vm.colorSelected(color.lighten2);
        }
      }
    }), _c('li', {
      style: `background-color: ${color.lighten4}`,
      on: {
        "click": function ($event) {
          return _vm.colorSelected(color.lighten4);
        }
      }
    })]);
  }), 0) : _vm._e()])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };